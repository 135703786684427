import BaseRow from 'components/atoms/Row';
import styled from 'styled-components';
import {
  flexbox,
  FlexboxProps,
  layout,
  LayoutProps,
  space,
  SpaceProps,
} from 'styled-system';

export const Row = styled(BaseRow)<SpaceProps & LayoutProps & FlexboxProps>`
  display: flex;
  flex-flow: wrap;
  justify-content: space-between;
  padding: 0 1.5rem;
  align-items: baseline;
  ${space}
  ${layout}
  ${flexbox}
`;

export const Col = styled.div<SpaceProps & LayoutProps>`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  ${space};
  ${layout};
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 0 1.5rem;
  width: 100%;
  margin-top: 1rem;
`;
