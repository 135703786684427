import React, { useCallback } from 'react';

import {
  ShipmentOngoingIcon,
  ShipmentCompletedIcon,
  Box,
  Text,
  Stack,
  styled,
  useTheme,
} from '@livingpackets/design-system-react-next';
import Row from 'components/atoms/Row';
import { Col } from 'components/molecules/PartnerForm';
import useIntlFormatTimestamp from 'hooks/useIntlFormatTimestamp';
import useShipmentAnonymousDetail from 'hooks/useShipmentAnonymousDetail';
import {
  IShipmentAnonymousTimelineEvent,
  ShipmentContractStateEnum,
} from 'models/shipment';
import { useTranslation } from 'react-i18next';

const CurrentStatusContainer = styled(Box)`
  border-top: 1px solid ${({ theme }) => theme.palette.custom.neutral.black[8]};
  border-left: 1px solid ${({ theme }) => theme.palette.custom.neutral.black[8]};
  border-right: 1px solid
    ${({ theme }) => theme.palette.custom.neutral.black[8]};
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 23px;
  padding-right: 25px;
  border-top-left-radius: ${({ theme }) => theme.borderRadius.lg};
  border-top-right-radius: ${({ theme }) => theme.borderRadius.lg};
  background-color: ${({ theme }) => theme.palette.custom.neutral.white.pure};
  z-index: 1;
`;

const StateEventContainer = styled('div')`
  width: 48px;
  height: 48px;
  border-radius: 14px;
  background-color: ${({ theme }) => theme.palette.custom.primary[10]};
  text-align: center;
  line-height: 60px;
`;

const ShipmentAnonymousDetailCurrentStatus = ({
  timelineEvents,
  contractState,
}: {
  timelineEvents: IShipmentAnonymousTimelineEvent[];
  contractState: ShipmentContractStateEnum;
}) => {
  const { t } = useTranslation('shipments');
  const theme = useTheme();

  const formatTimestamp = useIntlFormatTimestamp();
  const { isShipmentAnonymousTimelineItemAlert } = useShipmentAnonymousDetail();

  const getLastShipmentEventDateElement = useCallback(
    (timelineDataItems: IShipmentAnonymousTimelineEvent[]) => {
      let lastEventDate = null;

      const timelineDataItemsReversed = timelineDataItems.slice().reverse();

      timelineDataItemsReversed.forEach(
        (event: IShipmentAnonymousTimelineEvent) => {
          if (!isShipmentAnonymousTimelineItemAlert(event)) {
            lastEventDate = event.opened_at;
          }
        }
      );

      if (lastEventDate) {
        return (
          <Text
            variant="bodyTextS"
            color="custom.neutral.black.50"
            data-testid="date-delivery-statut"
          >
            {formatTimestamp(lastEventDate, {
              day: 'numeric',
              month: 'long',
              year: undefined,
              hour: '2-digit',
              minute: '2-digit',
            })}
          </Text>
        );
      } else {
        return;
      }
    },
    [formatTimestamp, isShipmentAnonymousTimelineItemAlert]
  );

  return (
    <CurrentStatusContainer>
      <Row justifyContent="space-between">
        <Col>
          <Stack direction="row">
            <StateEventContainer>
              {contractState !== ShipmentContractStateEnum.ARRIVED && (
                <ShipmentOngoingIcon color={theme.colors.primary[100]} />
              )}
              {contractState === ShipmentContractStateEnum.ARRIVED && (
                <ShipmentCompletedIcon color={theme.colors.primary[100]} />
              )}
            </StateEventContainer>
            <Stack
              marginLeft="16px !important"
              height="100%"
              justifyContent="center"
            >
              {getLastShipmentEventDateElement(timelineEvents)}
              <Text
                variant="bodyTextS"
                color="custom.neutral.black.90"
                data-testid="delivery-statut"
              >
                {contractState !== ShipmentContractStateEnum.ARRIVED &&
                  t('detail.contractState.deployed')}
                {contractState === ShipmentContractStateEnum.ARRIVED &&
                  t('detail.contractState.completed')}
              </Text>
            </Stack>
          </Stack>
        </Col>
      </Row>
    </CurrentStatusContainer>
  );
};

export default ShipmentAnonymousDetailCurrentStatus;
