import { useCallback } from 'react';

import {
  UseFetchResource,
  useFetchResource,
  UseFetchResourceOptions,
} from '@livingpackets/shared-components';
import { IBoxContract } from 'models/boxContract';

import {
  GetBoxContractsParams,
  PaginatedResponseBoxContracts,
  useGetBoxContracts,
} from './useGetBoxContracts';

export function useBoxContracts(
  params: GetBoxContractsParams,
  { enabled = true }: UseFetchResourceOptions = {}
): UseFetchResource<PaginatedResponseBoxContracts<IBoxContract>> {
  const getBoxContracts = useGetBoxContracts();

  const fetchFunction = useCallback(
    () => getBoxContracts(params),
    [getBoxContracts, params]
  );

  return useFetchResource(fetchFunction, { enabled });
}
