import { usePartnership } from 'features/account';
import { useContacts } from 'features/addressBook';

const useDefaultAddresses = () => {
  const { data: partnership } = usePartnership();

  const { data: defaultSenderContactResp } = useContacts({
    config: { enabled: !!partnership?.default_sender_address_id },
    search: partnership?.default_sender_address_id,
  });

  const { data: defaultRecipientContactResp } = useContacts({
    config: { enabled: !!partnership?.default_recipient_address_id },
    search: partnership?.default_recipient_address_id,
  });

  let defaultSenderContact;
  let defaultSenderAddress;

  if (defaultSenderContactResp?.count === 1) {
    defaultSenderContact = defaultSenderContactResp.items[0];

    defaultSenderAddress = defaultSenderContact?.addresses.find(
      address => address.id === partnership?.default_sender_address_id
    );
  }

  let defaultRecipientContact;
  let defaultRecipientAddress;

  if (defaultRecipientContactResp?.count === 1) {
    defaultRecipientContact = defaultRecipientContactResp.items[0];

    defaultRecipientAddress = defaultRecipientContact?.addresses.find(
      address => address.id === partnership?.default_recipient_address_id
    );
  }

  return {
    defaultSenderAddressId: partnership?.default_sender_address_id,
    defaultRecipientAddressId: partnership?.default_recipient_address_id,
    defaultSenderContact,
    defaultRecipientContact,
    defaultSenderAddress,
    defaultRecipientAddress,
  };
};

export default useDefaultAddresses;
