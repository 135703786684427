import { useImperativeRequestHook } from '@livingpackets/shared-components';
import { APIServices, TAPIService } from 'configs';
import useAppState, {
  anonymousTokenSelector,
} from 'stores/appState/useAppState';

const useImperativeAnonymousRequest = (service: TAPIService) => {
  const token = useAppState(anonymousTokenSelector);

  return useImperativeRequestHook({ url: APIServices[service], token: token });
};

export default useImperativeAnonymousRequest;
