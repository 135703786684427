import React from 'react';

import { useTranslation } from 'react-i18next';

import MessageHub from './MessageHub';

const MessageHubIntl = () => {
  const { t } = useTranslation();

  return <MessageHub t={t as (msg: string) => string} />;
};

export default MessageHubIntl;
