import React, { useCallback, useState } from 'react';

import {
  Stack,
  Text,
  styled,
  Box,
} from '@livingpackets/design-system-react-next';
import Row from 'components/atoms/Row';
import ShipmentNameBlock from 'components/atoms/shipment/ShipmentNameBlock';
import { DetailsSpan } from 'components/atoms/ShipmentAtoms';
import ExportShipmentLogs from 'components/molecules/ExportShipmentLogs';
import ShipmentAddressCard from 'components/molecules/ShipmentAddressCard';
import ShipmentDetailCurrentStatus from 'components/molecules/shipmentAnonymousDetail/ShipmentDetailCurrentStatus';
import ShipmentEventsTimeline from 'components/molecules/ShipmentEventsTimeline';
import ShipmentMap from 'components/molecules/ShipmentMap';
import ShipmentProduct from 'components/organisms/ShipmentProduct';
import { Offer } from 'features/account';
import { ShareTrackingLink } from 'features/shipments';
import { eventsReducer } from 'helpers/shipmentEventsHelpers';
import useProductDetail from 'hooks/useProductDetail';
import useShipmentDetail from 'hooks/useShipmentDetail';
import isNumber from 'lodash/isNumber';
import {
  IShipment,
  IShipmentTimelineResponse,
  ITrackingHistoryResponse,
  ShipmentContractStateEnum,
} from 'models/shipment';
import { useTranslation } from 'react-i18next';

interface IShipmentTracking {
  shipment: IShipment;
  offerData: Offer;
  trackingData: ITrackingHistoryResponse;
  timelineData: IShipmentTimelineResponse;
  displayTrackingLink: boolean;
  onShipmentMapZoomClick: () => void;
}

const MapWrapper = styled(Stack)`
  margin-top: 20px;
`;

const Container = styled(Stack)`
  flex-direction: row;
  margin-bottom: 2rem;
`;

const LogoWrapper = styled('img')`
  height: 40px;
  width: 40px;
`;

const ShipmentTracking = ({
  shipment,
  offerData,
  trackingData,
  timelineData,
  displayTrackingLink = true,
  onShipmentMapZoomClick,
}: IShipmentTracking) => {
  const { t } = useTranslation('shipments');

  const { setCurrentTrackingData } = useShipmentDetail();
  const { getCarrierLogo } = useShipmentDetail();
  const { getProductType } = useProductDetail();

  const showMap =
    isNumber(trackingData.lastKnownLocation?.latitude) &&
    isNumber(trackingData.lastKnownLocation?.longitude);

  const logo = getCarrierLogo(shipment.carrier_code);

  const { showMore } = eventsReducer(true, timelineData.items || []);

  const [displayShipmentNameForm, setDisplayShipmentNameForm] =
    useState<boolean>(false);

  // Handle information block click

  const handleShipmentNameClick = useCallback(() => {
    setDisplayShipmentNameForm(true);
  }, [setDisplayShipmentNameForm]);

  return (
    <>
      {displayTrackingLink && (
        <ShareTrackingLink
          shipmentId={shipment.shipment_id}
          shipment={shipment}
        />
      )}
      <Container>
        <Box width="40%" paddingRight="33px">
          <DetailsSpan>{t('detail.carrier.carrierTrackingNumber')}</DetailsSpan>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="flex-end"
          >
            <Stack direction="row" mt=".5rem" alignItems="center" gap="1rem">
              {logo && <LogoWrapper src={logo} alt="carrier logo" />}
              {shipment.carrier_tracking_code && (
                <Text
                  variant="titleL"
                  color="custom.neutral.black.100"
                  data-testid="carrier-tracking-code"
                  sx={{ wordBreak: 'break-all' }}
                >
                  {shipment.carrier_tracking_code}
                </Text>
              )}
            </Stack>
          </Stack>
          {shipment.shipment_name && (
            <Box>
              {shipment && (
                <ShipmentNameBlock
                  shipment={shipment}
                  displayShipmentNameForm={displayShipmentNameForm}
                  onBlockClick={handleShipmentNameClick}
                  data-testid="shipment-name"
                />
              )}
            </Box>
          )}
          <Box marginTop="20px">
            <Text variant="titleXS" color="custom.neutral.black.50">
              {t(
                `detail.boxInformation.title.${getProductType(
                  shipment.product.article_code
                )}`
              )}
            </Text>
          </Box>
          <ShipmentProduct
            shipment={shipment}
            offerData={offerData}
            productArticleCode={shipment.product.article_code}
            productLpui={shipment.product.lp_ui}
          />
          <ShipmentAddressCard shipment={shipment} mt="20px" />
        </Box>
        <Box width="60%">
          {showMap && (
            <>
              <DetailsSpan>{t('detail.deliveryStatus.title')}</DetailsSpan>
              <MapWrapper data-testid="mapWrapper">
                {timelineData.count !== 0 && (
                  <ShipmentDetailCurrentStatus
                    timelineEvents={timelineData.items}
                    contractState={
                      shipment.contract_state as ShipmentContractStateEnum
                    }
                  />
                )}
                <ShipmentMap
                  trackingData={trackingData}
                  shipment={shipment}
                  displayMapFullScreenModal={() => {
                    setCurrentTrackingData(trackingData);
                    onShipmentMapZoomClick();
                  }}
                />
              </MapWrapper>
            </>
          )}
          <ShipmentEventsTimeline
            showMore={showMore}
            timelineEvents={timelineData.items}
          />
          {shipment.contract_state === 'ARRIVED' && (
            <Row justifyContent="flex-end" mt="1.5rem">
              <ExportShipmentLogs shipment={shipment} />
            </Row>
          )}
        </Box>
      </Container>
    </>
  );
};

export default ShipmentTracking;
