export interface ITrackingCode {
  createdAt: number;
  email: string;
  id: string;
  revokedAt: number;
  role: number;
  shipmentId: string;
  updatedAt: number;
  accountId: string;
  lang: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
}

export interface IShareLinkForm {
  role: TrackingRoleType;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  lang: 'EN' | 'FR' | 'DE';
}

export enum TrackingRoleType {
  additionalTracker = '1',
  recipient = '4',
  sender = '3',
}
