import React from 'react';

import {
  DeleteIcon,
  Text,
  Stack,
  Box,
  ButtonV2 as Button,
} from '@livingpackets/design-system-react-next';
import { IPartnerAccount } from 'models/user';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import Modal from '../../atoms/Modal';
import NameCard from '../../atoms/NameCard';

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
`;

const PartnerMemberWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 8px 12px;
  background-color: ${({ theme }) => theme.colors.red[10]};
  border-radius: 12px;
  align-items: center;
`;

const PartnerMemberName = styled(Text)`
  color: ${({ theme }) => theme.colors.black[100]};
  font-weight: bold;
`;

const PartnerMemberEmail = styled(Text)`
  color: ${({ theme }) => theme.colors.black[100]};
  font-weight: bold;
`;

const PartnerMemberRole = styled(Text)`
  color: ${({ theme }) => theme.colors.black[100]};
  font-weight: bold;
`;

const PartnerMemberEntry = ({
  partnerMember,
}: {
  partnerMember: IPartnerAccount;
}) => (
  <PartnerMemberWrapper>
    <Stack direction="row" alignItems="center">
      <Box display="flex" alignItems="center">
        <NameCard
          firstName={partnerMember.first_name}
          lastName={partnerMember.last_name}
          email={partnerMember.email}
        />
        <PartnerMemberName variant="bodyTextS" marginLeft="12px">
          {partnerMember.first_name + ' ' + partnerMember.last_name}
        </PartnerMemberName>
      </Box>
      <Box display="block" marginRight="auto" marginLeft="auto !important">
        <PartnerMemberEmail variant="bodyTextS">
          {partnerMember.email}
        </PartnerMemberEmail>
      </Box>
      <Box>
        <PartnerMemberRole variant="bodyTextS">
          {partnerMember.role.name}
        </PartnerMemberRole>
      </Box>
    </Stack>
  </PartnerMemberWrapper>
);

const DeletePartnerMemberModal = ({
  open,
  onSubmit,
  onCancel,
  entry,
  loading,
}: {
  open: boolean;
  onSubmit: () => void;
  onCancel: () => void;
  entry: IPartnerAccount;
  loading: boolean;
}) => {
  const { t } = useTranslation('userManagement');

  return (
    <Modal open={open} handleClose={onCancel}>
      <>
        <Text variant="titleM">{t('modal.deletePartnerMember.title')}</Text>
        {entry && <PartnerMemberEntry partnerMember={entry} />}
        <Text variant="bodyTextS" mt="1.5625rem">
          {t('modal.deletePartnerMember.warning')}
        </Text>
        <ButtonWrapper>
          <Button variant="secondary" onClick={onCancel}>
            {t('modal.deletePartnerMember.button.dismiss')}
          </Button>
          <Button
            color="critical"
            icon={DeleteIcon}
            onClick={onSubmit}
            disabled={loading}
            data-testid="delete-partner-member-button"
          >
            {t('modal.deletePartnerMember.button.submit')}
          </Button>
        </ButtonWrapper>
      </>
    </Modal>
  );
};

export default DeletePartnerMemberModal;
