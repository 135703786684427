import React from 'react';

import { PATHS } from 'configs';
import { AccountSelectionDisplayMode } from 'enums/layout';
import { NavRouteContextEnum } from 'enums/navRouteContextEnum';
import { UserBusinessTypeEnum } from 'enums/UserBusinessTypeEnum';
import useCustomer from 'hooks/useCustomer';
import useNavRouteMatch from 'hooks/useNavRouteMatch';
import { IPartner } from 'models/partner';
import { IUser } from 'models/user';
import useAppState, { userSelector } from 'stores/appState/useAppState';

import PartnerButton from './PartnerButton';

const getCopy = (appUser: IUser | null) => {
  if (appUser?.firstName && appUser?.lastName) {
    return appUser.firstName + ' ' + appUser.lastName;
  }

  return 'Personal Account';
};

const LPAccountLink = ({ currentPartner }: { currentPartner?: IPartner }) => {
  const appUser = useAppState(userSelector);
  const { context } = useNavRouteMatch();
  const { getAccountTypeText } = useCustomer();

  const isActive = context === NavRouteContextEnum.LP_ACCOUNT;

  return (
    <PartnerButton
      isActive={isActive}
      accountName={getCopy(appUser)}
      typeName={getAccountTypeText(currentPartner)}
      to={PATHS.ACCOUNT_ROOT}
      canBeSelected={appUser?.businessType !== UserBusinessTypeEnum.PERSONAL}
      displayMode={AccountSelectionDisplayMode.BIG}
    />
  );
};

export default LPAccountLink;
