import { useMemo } from 'react';

import axios, { AxiosInstance } from 'axios';
import { APIServices, TAPIService } from 'configs/app';
import useAppState, { tokenSelector } from 'stores/appState/useAppState';

export const ApiClients = Object.entries(APIServices).reduce(
  (acc, [key, url]) => {
    acc[key as TAPIService] = (token: string) =>
      axios.create({
        baseURL: url,
        headers: { Authorization: `Bearer ${token}` },
      });

    return acc;
  },
  {} as Record<TAPIService, (token: string) => AxiosInstance>
);

export function useApiClient(key: TAPIService): AxiosInstance {
  const token = useAppState(tokenSelector);

  return useMemo(() => ApiClients[key](token), [key, token]);
}
