import React from 'react';

import { PATHS } from 'configs';
import { AccountSelectionDisplayMode } from 'enums/layout';
import { NavRouteContextEnum } from 'enums/navRouteContextEnum';
import useCustomer from 'hooks/useCustomer';
import useNavRouteMatch from 'hooks/useNavRouteMatch';
import { generatePath } from 'react-router-dom';
import { IPartnerEntry } from 'stores/useMyPartnersStore';

import PartnerButton from './PartnerButton';

const PartnerLink = ({
  partner,
  canBeSelected = true,
  displayMode,
}: {
  partner: IPartnerEntry;
  canBeSelected?: boolean;
  displayMode: AccountSelectionDisplayMode;
}) => {
  const { context, partnerId } = useNavRouteMatch();
  const isActive =
    context === NavRouteContextEnum.PARTNER && partnerId === partner.id;
  const { getAccountTypeText } = useCustomer();

  return (
    <PartnerButton
      isActive={isActive}
      accountName={partner.name}
      typeName={getAccountTypeText(partner)}
      to={generatePath(PATHS.PARTNER_ROOT, { partnerId: partner.id })}
      canBeSelected={canBeSelected}
      displayMode={displayMode}
      partner={partner}
    />
  );
};

export default PartnerLink;
