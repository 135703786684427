import { AddressTypes } from 'features/account';
import i18next from 'i18next';
import * as yup from 'yup';

import { email, phoneNumber } from './common';

export const address = () =>
  yup.object().shape({
    company: yup.string().when('addressTypes', {
      is: (val: string[]) => !val.includes(AddressTypes.MAIN),
      then: () =>
        yup.string().required(i18next.t('forms:identification_name.required')),
    }),
    street: yup.string().required(i18next.t('forms:address.required')),
    street2: yup.string(),
    building: yup.string(),
    postalCode: yup.string().required(i18next.t('forms:postalCode.required')),
    city: yup.string().required(i18next.t('forms:city.required')),
    countryCode: yup.string().required(i18next.t('forms:countryCode.required')),
    phoneNumber: phoneNumber(),
    email: email(),
  });
