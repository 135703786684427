import { styled, Text } from '@livingpackets/design-system-react-next';
import { space, SpaceProps, typography, TypographyProps } from 'styled-system';

export const DetailsCard = styled('div')<
  SpaceProps & TypographyProps & { disabled?: boolean }
>`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  border-radius: 1.25rem;
  background: ${({ theme, disabled }) =>
    disabled ? theme.colors.white : theme.colors.black[2]};
  border-style: solid;
  border-color: ${({ theme }) => theme.colors.black[10]};
  border-width: ${({ disabled }) => (disabled ? '.0625rem' : '0')};
  opacity: ${({ disabled }) => (disabled ? '0.5' : '1')};
  ${space}
  ${typography}
`;

export const DetailsSpan = styled(Text)``;

DetailsSpan.defaultProps = {
  variant: 'titleXS',
  color: 'custom.neutral.black.50',
};
