import React from 'react';

import {
  DeleteIcon,
  Text,
  ButtonV2 as Button,
} from '@livingpackets/design-system-react-next';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import Modal from '../../atoms/Modal';

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
`;

const DeleteShipmentReturnModal = ({
  open,
  onSubmit,
  onCancel,
}: {
  open: boolean;
  onSubmit: () => void;
  onCancel: () => void;
}) => {
  const { t } = useTranslation('shipments');

  return (
    <Modal open={open} handleClose={onCancel}>
      <>
        <Text variant="titleM">{t('modal.deleteShipmentReturn.title')}</Text>
        <Text variant="bodyTextS" mt="1.5625rem">
          {t('modal.deleteShipmentReturn.warning')}
        </Text>
        <ButtonWrapper>
          <Button
            variant="secondary"
            onClick={onCancel}
            data-testid="delete-shipment-return-button-cancel"
          >
            {t('modal.deleteShipmentReturn.button.dismiss')}
          </Button>
          <Button
            color="critical"
            icon={DeleteIcon}
            onClick={onSubmit}
            data-testid="delete-shipment-return-button-submit"
          >
            {t('modal.deleteShipmentReturn.button.submit')}
          </Button>
        </ButtonWrapper>
      </>
    </Modal>
  );
};

export default DeleteShipmentReturnModal;
