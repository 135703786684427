import styled from 'styled-components';
import { space, SpaceProps } from 'styled-system';

const DateTagSkeleton = styled.div<SpaceProps>`
  width: 2.62rem;
  height: 3.68rem;
  background-color: ${({ theme }) => theme.colors.black[4]};
  border-radius: 7px;
  ${space};
`;

export default DateTagSkeleton;
