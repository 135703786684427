import React, { useCallback, useEffect, useState } from 'react';

import { Button } from '@livingpackets/design-system-react';
import { Box, Text, styled } from '@livingpackets/design-system-react-next';
import { PATHS } from 'configs';
import useFiguralInsurance from 'hooks/useFiguralInsurance';
import usePartnerRouting from 'hooks/usePartnerRouting';
import useToastMessages from 'hooks/useToastMessages';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate, useLocation } from 'react-router-dom';
import useMyPartnersStore, {
  activePartnerSelector,
  IPartnerEntry,
  setActivePartnerSelector,
} from 'stores/useMyPartnersStore';

const FiguralActivationText = styled(Text)`
  color: ${({ theme }) => theme.palette.custom.neutral.black[100]};
  white-space: break-spaces;
  text-align: center;
`;

const PartnerInsuranceActivationSuccess = () => {
  const navigate = useNavigate();
  const { t } = useTranslation(['partnerSettings']);

  usePartnerRouting();
  const activePartner: IPartnerEntry = useMyPartnersStore(
    activePartnerSelector
  );
  const setActivePartner = useMyPartnersStore(setActivePartnerSelector);
  const { error: toastError } = useToastMessages();

  const { loading, sendFiguralCredentials } = useFiguralInsurance();

  const [isFiguralCredentialsAlreadySend, setIsFiguralCredentialsAlreadySend] =
    useState<boolean>(false);

  const useQuery = () => new URLSearchParams(useLocation().search);
  const query = useQuery();

  const code = query.get('code');
  const clientId = query.get('client_id');
  const clientSecret = query.get('client_secret');

  const backToSettingsPage = useCallback(() => {
    navigate(
      generatePath(PATHS.SETTINGS_PARTNER_PARTNERSHIP, {
        partnerId: activePartner.id,
      })
    );
  }, [navigate, activePartner.id]);

  useEffect(() => {
    if (activePartner.id && !isFiguralCredentialsAlreadySend) {
      if (activePartner.secursusEnabled) {
        toastError('messages:figuralCredentialsAlreadySendError.message', {
          wide: true,
        });
        backToSettingsPage();
      }

      if (code && clientId && clientSecret && activePartner.id) {
        sendFiguralCredentials(activePartner.id, {
          code: code,
          client_id: clientId,
          client_secret: clientSecret,
          redirect_uri:
            window.location.origin +
            generatePath(PATHS.SETTINGS.INSURANCE_ACTIVATION_SUCCESS, {
              partnerId: activePartner.id,
            }),
        })
          .then(() => {
            setIsFiguralCredentialsAlreadySend(true);
            setActivePartner({ ...activePartner, secursusEnabled: true });
          })
          .catch(() => {
            toastError('messages:sendFiguralCredentialsError.message', {
              wide: true,
            });
            backToSettingsPage();
          })
          .finally(() => {
            setIsFiguralCredentialsAlreadySend(true);
          });
      }
    }
  }, [
    code,
    clientId,
    clientSecret,
    sendFiguralCredentials,
    activePartner,
    backToSettingsPage,
    toastError,
    setActivePartner,
    isFiguralCredentialsAlreadySend,
  ]);

  return (
    <Box
      display="flex"
      height="100vh"
      alignItems="center"
      justifyContent="center"
      style={{ backgroundColor: '#F2F4F5' }}
    >
      <Box
        borderRadius="lg"
        sx={{
          padding: '45px 32px 40px 32px',
          minWidth: '438px',
          backgroundColor: 'white',
        }}
      >
        <FiguralActivationText variant="titleM" mb="1rem">
          {t('partnerSettings:figuralActivationSuccess.title')}
        </FiguralActivationText>
        <FiguralActivationText variant="bodyTextM" mb="1.25rem">
          {t('partnerSettings:figuralActivationSuccess.text')}
        </FiguralActivationText>
        <Button
          action="primary"
          onClick={() => backToSettingsPage()}
          style={{ width: '100%', height: '48px' }}
          isDisabled={loading}
        >
          {t('partnerSettings:figuralActivationSuccess.button')}
        </Button>
      </Box>
    </Box>
  );
};
export default PartnerInsuranceActivationSuccess;
