import React, { useState } from 'react';

import {
  Grid,
  Paper,
  PlayIcon,
  Text,
} from '@livingpackets/design-system-react-next';
import { Onboarding as OnboardingModule } from 'features/onboarding';
import { useTranslation } from 'react-i18next';

export const Onboarding = () => {
  const { t } = useTranslation(['account']);

  const [showOnboarding, setShowOnboarding] = useState(false);

  return (
    <>
      <Paper
        sx={{
          ':hover': {
            cursor: 'pointer',
          },
        }}
        onClick={() => setShowOnboarding(true)}
      >
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Grid container direction="column">
              <Grid item>
                <Text variant="titleM">
                  {t('account:personal.help.onboarding.title')}
                </Text>
              </Grid>
              <Grid item>
                <Text variant="bodyTextS">
                  {t('account:personal.help.onboarding.subtitle')}
                </Text>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Text
              variant="titleM"
              color="primary.main"
              sx={{
                display: 'flex',
                gap: '0.5rem',
                alignItems: 'center',
              }}
              data-testid="run-onboarding-button"
            >
              {t('account:personal.help.onboarding.actionButton')} <PlayIcon />
            </Text>
          </Grid>
        </Grid>
      </Paper>
      {showOnboarding && (
        <OnboardingModule onClose={() => setShowOnboarding(false)} />
      )}
    </>
  );
};
