import React from 'react';

import { Button, Text } from '@livingpackets/design-system-react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { LayoutProps } from 'styled-system';

import imageName from '../../assets/card_B_R.png';

const BoxDiv = styled.div<LayoutProps>`
  display: flex;
  position: relative;
  background-color: #00bf6f;
  border-radius: 20px;
  width: 35%;
  height: 15.9rem;
  margin-left: 2rem;
`;

const CoverDiv = styled.div<LayoutProps>`
  position: absolute;
  left: 6.5rem;
  top: 5rem;
  padding-right: 1rem;
`;

const BuyAndRentBox = () => {
  const { t } = useTranslation(['general', 'contribution', 'buyAndRent']);
  let url = t('general:websiteUrl.url');

  return (
    <BoxDiv>
      <img alt="box" src={imageName} style={{ borderRadius: '20px' }} />
      <CoverDiv>
        <Text
          color="white"
          style={{
            fontSize: '1.3rem',
            lineHeight: '1.5rem',
          }}
        >
          {t('buyAndRent:buyNRent.websiteLink.buyBoxes')}
        </Text>
        <Button
          style={{
            marginTop: '1rem',
            width: '150px',
            minWidth: '150px',
          }}
          action="secondary"
          onClick={() => window.open(url, '_blank', 'noopener noreferrer')}
        >
          {t('buyAndRent:buyNRent.websiteLink.learnMore')}
        </Button>
      </CoverDiv>
    </BoxDiv>
  );
};

export default BuyAndRentBox;
