import React, { useCallback, useState } from 'react';

import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
} from '@livingpackets/design-system-react';
import { Stack, Text } from '@livingpackets/design-system-react-next';
import SearchContactAddressForm from 'components/molecules/Form/SearchContactAddressForm';
import { IContactAddress, ISearchContactAddress } from 'models/contactAddress';
import { useTranslation } from 'react-i18next';

import Modal, { IModalWrapperType } from '../../atoms/Modal';

interface IViewSelectContact {
  visible: boolean;
  onAddressSelect: (address: IContactAddress) => void;
  onContactSelect?: (contact: ISearchContactAddress) => void;
  onDismiss: () => void;
  cardHeaderSubTitleText: string;
  cardHeaderValidButtonText: string;
}

const SearchContactAddressModal = ({
  visible,
  onAddressSelect,
  onContactSelect,
  onDismiss,
  cardHeaderSubTitleText,
  cardHeaderValidButtonText,
}: IViewSelectContact) => {
  const { t } = useTranslation('contactAddress');
  const [addressSelected, setAddressSelected] = useState<IContactAddress>();
  const [contactSelected, setContactSelected] =
    useState<ISearchContactAddress>();

  const handleDismiss = useCallback(async () => {
    onDismiss();
  }, [onDismiss]);

  const handleContactSelectClick = useCallback(() => {
    if (addressSelected) {
      onAddressSelect(addressSelected);
    }

    if (onContactSelect && contactSelected) {
      onContactSelect(contactSelected);
    }

    setContactSelected(undefined);
    setAddressSelected(undefined);
  }, [
    onAddressSelect,
    addressSelected,
    contactSelected,
    onContactSelect,
    setContactSelected,
    setAddressSelected,
  ]);

  const handleContactSelect = useCallback((contact: ISearchContactAddress) => {
    setContactSelected(contact);
  }, []);

  const handleAddressSelect = useCallback((address: IContactAddress) => {
    setAddressSelected(address);
  }, []);

  return (
    <Modal
      open={visible}
      handleClose={onDismiss}
      wrapperType={IModalWrapperType.extraLarge}
    >
      <Card width="auto">
        <CardHeader backgroundColor="black.100" color="white" padding={5}>
          <Text variant="bodyTextXS">
            {t('modal.searchContactAddress.breadcrumb')}
          </Text>
          <Text variant="titleXS" mt="1.375rem">
            {t('modal.searchContactAddress.title')}
          </Text>
          <Text variant="titleXL">{cardHeaderSubTitleText}</Text>
        </CardHeader>
        <CardBody paddingLeft="32px" paddingTop="36px">
          <Stack spacing={5}>
            <SearchContactAddressForm
              onAddressSelect={handleAddressSelect}
              onContactSelect={handleContactSelect}
              visible={visible}
              onDismiss={onDismiss}
            />
          </Stack>
        </CardBody>
        <CardFooter>
          <Stack direction="row" justifyContent="space-between" mt="1rem">
            <Button
              id="dismissBtn"
              action="secondary"
              onClick={handleDismiss}
              style={{ minWidth: 'initial' }}
              data-testid="search-contact-address-modal-dismiss"
            >
              {t('modal.searchContactAddress.button.dismiss')}
            </Button>
            <Button
              id="submitBtn"
              disabled={!addressSelected}
              onClick={handleContactSelectClick}
              action="primary"
              style={{ minWidth: 'initial' }}
              data-testid="search-contact-address-modal-submit"
            >
              {cardHeaderValidButtonText}
            </Button>
          </Stack>
        </CardFooter>
      </Card>
    </Modal>
  );
};

export default SearchContactAddressModal;
