import React, { useCallback } from 'react';

import { Button } from '@livingpackets/design-system-react';
import { Stack } from '@livingpackets/design-system-react-next';
import Input from 'components/atoms/Input';
import { AuthenticationView } from 'components/containers/AuthenticationView';
import { PATHS } from 'configs';
import { UserBusinessTypeEnum } from 'enums/UserBusinessTypeEnum';
import useCustomer from 'hooks/useCustomer';
import useFormTemplate from 'hooks/useFormTemplate';
import { PaginatedResponse, useGetPartnerList } from 'hooks/useGetPartnerList';
import useLpVillageService from 'hooks/useLpVillageService';
import useToastMessages from 'hooks/useToastMessages';
import get from 'lodash/get';
import {
  INITIAL_STATE_PARTNERSHIP_INFORMATION_FORM,
  IPartnershipInformationForm,
} from 'models/partnershipInformation';
import { IUser } from 'models/user';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { partnershipInformationSchema } from 'schemas/partnershipInformationSchema';
import useAppState, {
  dispatch,
  userSelector,
} from 'stores/appState/useAppState';
import useMyPartnersStore, {
  setActivePartnerSelector,
  setPartnersSelector,
} from 'stores/useMyPartnersStore';

const AddPartnershipInformationPage = () => {
  const { state } = useLocation();
  const { accountType } = state || {};

  const navigate = useNavigate();
  const { t } = useTranslation(['authentication', 'messages']);

  const appUser = useAppState(userSelector);
  const setActivePartner = useMyPartnersStore(setActivePartnerSelector);
  const setPartners = useMyPartnersStore(setPartnersSelector);
  const { getBusinessTypeFromAccountType } = useCustomer();

  const { selectPartnerType, isPartnerAvailable } = useLpVillageService();
  const getPartnerPartners = useGetPartnerList();

  const { error: errorToast } = useToastMessages();

  const {
    errors,
    register,
    formState: { touchedFields, isValid },
    getValues,
  } = useFormTemplate<IPartnershipInformationForm>({
    resolver: partnershipInformationSchema,
    defaultValues: INITIAL_STATE_PARTNERSHIP_INFORMATION_FORM,
  });

  const submitHandler = useCallback(
    (e: any) => {
      e.preventDefault();

      if (!accountType) {
        navigate({
          pathname: PATHS.AUTHENTICATION.ACCOUNT_TYPE_CHOICE,
        });

        return;
      }

      const formValues = getValues();

      isPartnerAvailable(formValues.companyName).then(({ success, data }) => {
        if (!data.available) {
          errorToast('messages:companyNameDuplicated.message', { wide: true });

          return;
        }

        selectPartnerType(
          getBusinessTypeFromAccountType(accountType),
          formValues.companyName
        ).then(({ success, data }) => {
          getPartnerPartners({
            orderColumn: 'createdAt',
            orderDirection: 'ASC',
          }).then((response: PaginatedResponse<any>) => {
            if (response.total !== 0) {
              setPartners(response.items);
              setActivePartner(response.items[0]);
            }

            dispatch({
              type: 'SET_USER',
              payload: {
                ...appUser,
                ...{
                  businessType: UserBusinessTypeEnum.BUSINESS,
                },
              } as IUser,
            });

            navigate(PATHS.AUTHENTICATION.ACCOUNT_CREATED_SUCCESS, {
              state: { accountType: accountType },
            });
          });
        });
      });
    },
    [
      accountType,
      appUser,
      errorToast,
      getBusinessTypeFromAccountType,
      getPartnerPartners,
      getValues,
      navigate,
      isPartnerAvailable,
      selectPartnerType,
      setActivePartner,
      setPartners,
    ]
  );

  return (
    <AuthenticationView
      title={t('authentication:addPartnershipInformation.title')}
    >
      <Stack
        component="form"
        onSubmit={submitHandler}
        data-testid="add-partnership-information-key-container"
      >
        <Input
          name="companyName"
          label={
            get(touchedFields, 'companyName', false)
              ? t(
                  'authentication:addPartnershipInformation.form.companyName.placeholder'
                )
              : undefined
          }
          placeholder={t(
            'authentication:addPartnershipInformation.form.companyName.placeholder'
          )}
          error={errors.companyName}
          isTouched={get(touchedFields, 'companyName', false)}
          register={register}
          width="100%"
        />
        <Button
          id="submitBtn"
          action="primary"
          type="submit"
          isDisabled={!isValid}
          style={{ width: '100%', marginBottom: '16px' }}
        >
          {t('authentication:addPartnershipInformation.buttons.confirm')}
        </Button>
      </Stack>
    </AuthenticationView>
  );
};

export default AddPartnershipInformationPage;
