import React from 'react';

import MemberForm from 'components/molecules/Form/MemberForm';
import { IMemberForm } from 'models/user';

interface IMemberType {
  onValuesChanged: (values: IMemberForm) => void;
  isFormValidChanged: (isFormValid: boolean) => void;
}

const PartnerMemberCreateForm = ({
  onValuesChanged,
  isFormValidChanged,
}: IMemberType) => (
  <MemberForm
    defaultValues={undefined}
    onValuesChanged={onValuesChanged}
    isFormValidChanged={isFormValidChanged}
  />
);

export default PartnerMemberCreateForm;
