import React from 'react';

import {
  LockOffIcon,
  LockOnIcon,
} from '@livingpackets/design-system-react-next';

interface ILockingStateIcon {
  lockState: boolean;
}

const LockingStateIcon = ({ lockState }: ILockingStateIcon) => (
  <>{lockState ? <LockOnIcon /> : <LockOffIcon />}</>
);

export default LockingStateIcon;
