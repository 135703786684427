import React, { useState } from 'react';

import { Box } from '@livingpackets/design-system-react';
import {
  AccountInformationIcon,
  UserIcon,
} from '@livingpackets/design-system-react-next';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import ProfileInformationBankAccount from './ProfileInformationBankAccount';
import ProfileInformationBillingAddress from './ProfileInformationBillingAddress';
import useAccountInformationTab, {
  AccountInformationTabEnum,
} from '../../hooks/useAccountInformationTab';
import NavTab from '../atoms/NavTab';
import NavTabs from '../molecules/NavTabs';
import ProfileSettings from '../molecules/ProfileSettings';

const AccountInformationContainer = styled(Box)`
  display: grid;
  grid-auto-flow: row;
  grid-row-gap: 32px;
`;

const AccountInformationWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 20px;
  padding: 24px 32px;
`;

const ProfileInformation = () => {
  const { t } = useTranslation(['accountInformation', 'messages']);

  const { getAccountInformationTabCurrent } = useAccountInformationTab();

  const [currentNavTab, setCurrentNavTab] = useState<AccountInformationTabEnum>(
    getAccountInformationTabCurrent()
  );

  const [forceDisplayBillingAddressForm, setForceDisplayBillingAddressForm] =
    useState<number>(0);

  return (
    <>
      <NavTabs>
        <NavTab
          selected={currentNavTab === AccountInformationTabEnum.account}
          icon={<AccountInformationIcon />}
          onClick={() => setCurrentNavTab(AccountInformationTabEnum.account)}
          testId="accountInformation-navtab-account"
        >
          {t('accountInformation:tabs.account')}
        </NavTab>
        <NavTab
          selected={currentNavTab === AccountInformationTabEnum.financial}
          icon={<UserIcon />}
          onClick={() => setCurrentNavTab(AccountInformationTabEnum.financial)}
          testId="accountInformation-navtab-financial"
        >
          {t('accountInformation:tabs.financial')}
        </NavTab>
      </NavTabs>

      {currentNavTab === AccountInformationTabEnum.account && (
        <AccountInformationContainer>
          <ProfileSettings />
        </AccountInformationContainer>
      )}

      {currentNavTab === AccountInformationTabEnum.financial && (
        <AccountInformationContainer>
          <AccountInformationWrapper>
            <ProfileInformationBankAccount
              triggerBillingAddressCheck={() =>
                setForceDisplayBillingAddressForm(
                  forceDisplayBillingAddressForm + 1
                )
              }
            />
          </AccountInformationWrapper>
          <AccountInformationWrapper>
            <ProfileInformationBillingAddress
              forceDisplayBillingAddressForm={forceDisplayBillingAddressForm}
            />
          </AccountInformationWrapper>
        </AccountInformationContainer>
      )}
    </>
  );
};
export default ProfileInformation;
