import React from 'react';

import {
  Text,
  ButtonV2 as Button,
  styled,
} from '@livingpackets/design-system-react-next';
import { useTranslation } from 'react-i18next';

import Modal from '../../atoms/Modal';

const ButtonWrapper = styled('div')`
  display: flex;
  justify-content: space-between;
  margin-top: 32px;
`;

const ChangeBankAccountModal = ({
  open,
  onChangeClick,
  onCancelClick,
}: {
  open: boolean;
  onChangeClick: () => void;
  onCancelClick: () => void;
}) => {
  const { t } = useTranslation('accountInformation');

  return (
    <Modal open={open} handleClose={onCancelClick}>
      <Text variant="titleM">{t('modal.changeBankAccount.title')}</Text>
      <Text variant="bodyTextS" mt="1.5625rem">
        {t('modal.changeBankAccount.warning')}
      </Text>
      <ButtonWrapper>
        <Button variant="secondary" onClick={onCancelClick}>
          {t('modal.changeBankAccount.button.cancel')}
        </Button>
        <Button
          onClick={onChangeClick}
          data-testid="modal.changeBankAccount.button.change"
        >
          {t('modal.changeBankAccount.button.change')}
        </Button>
      </ButtonWrapper>
    </Modal>
  );
};

export default ChangeBankAccountModal;
