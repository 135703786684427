import React from 'react';

import {
  DeleteIcon,
  Text,
  ButtonV2 as Button,
  styled,
} from '@livingpackets/design-system-react-next';
import { useTranslation } from 'react-i18next';

import Modal from '../../atoms/Modal';

const ButtonWrapper = styled('div')`
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
`;

const DeleteShipmentModal = ({
  open,
  onSubmit,
  onCancel,
  loading,
}: {
  open: boolean;
  onSubmit: () => void;
  onCancel: () => void;
  loading: boolean;
}) => {
  const { t } = useTranslation('shipments');

  return (
    <Modal open={open} handleClose={onCancel}>
      <>
        <Text variant="titleM">{t('modal.deleteShipment.title')}</Text>
        <Text variant="bodyTextS" mt="1.5625rem">
          {t('modal.deleteShipment.warning')}
        </Text>
        <ButtonWrapper>
          <Button variant="secondary" onClick={onCancel}>
            {t('modal.deleteShipment.button.dismiss')}
          </Button>
          <Button
            color="critical"
            icon={DeleteIcon}
            onClick={onSubmit}
            disabled={loading}
            data-testid="delete-shipment-button"
          >
            {t('modal.deleteShipment.button.submit')}
          </Button>
        </ButtonWrapper>
      </>
    </Modal>
  );
};

export default DeleteShipmentModal;
