import React from 'react';

import {
  PrivateAccountIcon,
  PartnershipIcon,
  Text,
  useTheme,
} from '@livingpackets/design-system-react-next';
import { PartnerBusinessTypeEnum } from 'enums/accountTypeEnum';
import { UserBusinessTypeEnum } from 'enums/UserBusinessTypeEnum';
import useCustomer from 'hooks/useCustomer';
import { IPartner } from 'models/partner';
import useAppState, { userSelector } from 'stores/appState/useAppState';
import { IPartnerEntry } from 'stores/useMyPartnersStore';

const AccountTypeIcon = ({
  partner,
}: {
  partner?: IPartner | IPartnerEntry;
}) => {
  const theme = useTheme();

  const currentUser = useAppState(userSelector);
  const { getPartnerNameInitial } = useCustomer();

  if (!currentUser) {
    return <></>;
  }

  if (currentUser.businessType === UserBusinessTypeEnum.PERSONAL) {
    return (
      <PrivateAccountIcon
        color={theme.palette.custom.neutral.white.pure}
        size="25px"
      />
    );
  }

  if (currentUser.businessType === UserBusinessTypeEnum.BUSINESS && partner) {
    const { businessInformation } = partner;

    if (businessInformation.type === PartnerBusinessTypeEnum.PRO) {
      return (
        <PartnershipIcon
          color={theme.palette.custom.neutral.white.pure}
          size="25px"
        />
      );
    }

    return (
      <Text variant="titleM" color="custom.neutral.black.2">
        {getPartnerNameInitial(partner.name)}
      </Text>
    );
  }

  return <></>;
};

export default AccountTypeIcon;
