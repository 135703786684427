import { useCallback, useState } from 'react';

import {
  ShipmentAnonymousTimelineThresholdTypeEnum,
  ShipmentTimelineEventTypeEnum,
} from 'enums/ShipmentTimelineEnum';
import useImperativeAnonymousRequest from 'hooks/useImperativeAnonymousRequest';
import useToastMessages from 'hooks/useToastMessages';
import {
  IShipment,
  IShipmentAnonymousTimelineEvent,
  IShipmentAnonymousTimelineEventValue,
  IShipmentGeoPath,
} from 'models/shipment';
import { create } from 'zustand';

interface IShipmentStore {
  shipment?: IShipment;
  setShipment: (shipment: IShipment) => void;
}

export const useShipmentStore = create<IShipmentStore>(set => ({
  setShipment: (shipment: IShipment) => set({ shipment }),
}));

const useShipmentAnonymousDetail = () => {
  const [
    { loadingShipmentAnonymousDetail },
    makeRequestShipmentAnonymousDetail,
  ] = useImperativeAnonymousRequest('shipmentV3');

  const { error: toastError } = useToastMessages();

  const [currentAnonymousTrackingData, setCurrentAnonymousTrackingData] =
    useState<IShipmentGeoPath | undefined>(undefined);

  const getCurrentAnonymousTrackingData = useCallback(
    () => currentAnonymousTrackingData,
    [currentAnonymousTrackingData]
  );

  const getShipmentAnonymousDetail = useCallback(
    async (shipmentId: string) => {
      const { error, data } = await makeRequestShipmentAnonymousDetail({
        path: `/me/shipments/${shipmentId}/details`,
        method: 'GET',
      });

      if (error) {
        toastError('messages:getShipmentAnonymousDetailError');

        return { success: false, shipmentData: null };
      }

      if (data.shipment.created_at) {
        data.shipment.created_at =
          new Date(data.shipment.created_at).getTime() / 1000;
      }

      if (data.product_state.last_update) {
        data.product_state.last_update =
          new Date(data.product_state.last_update).getTime() / 1000;
      }

      data.timeline.events = data.timeline.events.map(
        (event: IShipmentAnonymousTimelineEvent) => {
          // Force remove close information for non stop alert
          if (
            event.event_type ===
              ShipmentTimelineEventTypeEnum.ALERT_BATTERY_LEVEL ||
            event.event_type ===
              ShipmentTimelineEventTypeEnum.ALERT_BOX_VIOLATION ||
            event.event_type === ShipmentTimelineEventTypeEnum.ALERT_SHOCK
          ) {
            event.closed_at = undefined;
          } else if (event.closed_at === 0 || event.closed_at === null) {
            event.closed_at = undefined;
          }

          // For the moment, the shock alert is in fact a fall alert
          if (event.event_type === ShipmentTimelineEventTypeEnum.ALERT_SHOCK) {
            event.event_type = ShipmentTimelineEventTypeEnum.ALERT_FALL;
          }

          if (event.opened_at) {
            event.opened_at = new Date(event.opened_at).getTime() / 1000;
          }

          if (event.closed_at) {
            event.closed_at = new Date(event.closed_at).getTime() / 1000;
          }

          return event;
        }
      );

      return { success: true, shipmentData: data };
    },
    [makeRequestShipmentAnonymousDetail, toastError]
  );

  const isShipmentAnonymousTimelineItemAlert = useCallback(
    (
      shipmentAnonymousTimelineEvent: IShipmentAnonymousTimelineEvent
    ): boolean =>
      [
        ShipmentTimelineEventTypeEnum.ALERT_TEMPERATURE_EXTERNAL,
        ShipmentTimelineEventTypeEnum.ALERT_TEMPERATURE_INTERNAL,
        ShipmentTimelineEventTypeEnum.ALERT_SHOCK,
        ShipmentTimelineEventTypeEnum.ALERT_FALL,
        ShipmentTimelineEventTypeEnum.ALERT_PRESSURE,
        ShipmentTimelineEventTypeEnum.ALERT_HUMIDITY_EXTERNAL,
        ShipmentTimelineEventTypeEnum.ALERT_HUMIDITY_INTERNAL,
        ShipmentTimelineEventTypeEnum.ALERT_BOX_VIOLATION,
        ShipmentTimelineEventTypeEnum.ALERT_BATTERY_LEVEL,
      ].includes(shipmentAnonymousTimelineEvent.event_type),
    []
  );

  const getAlertThresholdAnonymous = useCallback(
    (event: IShipmentAnonymousTimelineEvent): number | undefined => {
      if (
        event.event_type ===
        ShipmentTimelineEventTypeEnum.ALERT_TEMPERATURE_INTERNAL
      ) {
        return event.temperature_internal.threshold;
      }

      if (
        event.event_type ===
        ShipmentTimelineEventTypeEnum.ALERT_TEMPERATURE_EXTERNAL
      ) {
        return event.temperature_external.threshold;
      }

      if (
        event.event_type ===
        ShipmentTimelineEventTypeEnum.ALERT_HUMIDITY_INTERNAL
      ) {
        return event.humidity_internal.threshold;
      }

      if (
        event.event_type ===
        ShipmentTimelineEventTypeEnum.ALERT_HUMIDITY_EXTERNAL
      ) {
        return event.humidity_external.threshold;
      }

      if (
        event.event_type === ShipmentTimelineEventTypeEnum.ALERT_SHOCK ||
        event.event_type === ShipmentTimelineEventTypeEnum.ALERT_FALL
      ) {
        return Math.floor(event.shock.threshold * 100);
      }

      if (event.event_type === ShipmentTimelineEventTypeEnum.ALERT_PRESSURE) {
        return event.pressure.threshold;
      }

      if (
        event.event_type === ShipmentTimelineEventTypeEnum.ALERT_BATTERY_LEVEL
      ) {
        return event.battery_level.threshold;
      }

      return;
    },
    []
  );

  const getAlertThresholdTypeAnonymous = useCallback(
    (
      event: IShipmentAnonymousTimelineEvent
    ): ShipmentAnonymousTimelineThresholdTypeEnum => {
      if (
        event.event_type ===
        ShipmentTimelineEventTypeEnum.ALERT_TEMPERATURE_INTERNAL
      ) {
        return event.temperature_internal.threshold_type;
      }

      if (
        event.event_type ===
        ShipmentTimelineEventTypeEnum.ALERT_TEMPERATURE_EXTERNAL
      ) {
        return event.temperature_external.threshold_type;
      }

      if (
        event.event_type ===
        ShipmentTimelineEventTypeEnum.ALERT_HUMIDITY_INTERNAL
      ) {
        return event.humidity_internal.threshold_type;
      }

      if (
        event.event_type ===
        ShipmentTimelineEventTypeEnum.ALERT_HUMIDITY_EXTERNAL
      ) {
        return event.humidity_external.threshold_type;
      }

      if (
        event.event_type === ShipmentTimelineEventTypeEnum.ALERT_SHOCK ||
        event.event_type === ShipmentTimelineEventTypeEnum.ALERT_FALL
      ) {
        return event.shock.threshold_type;
      }

      if (event.event_type === ShipmentTimelineEventTypeEnum.ALERT_PRESSURE) {
        return event.pressure.threshold_type;
      }

      return ShipmentAnonymousTimelineThresholdTypeEnum.MIN;
    },
    []
  );

  const getAlertThresholdLimitText = useCallback(
    (
      baseTransKey: string,
      thresholdType: ShipmentAnonymousTimelineThresholdTypeEnum
    ): string =>
      thresholdType === ShipmentAnonymousTimelineThresholdTypeEnum.MAX
        ? baseTransKey + '.thresholdLimitMax'
        : baseTransKey + '.thresholdLimitMin',
    []
  );

  const getAlertValuesList = useCallback(
    (
      event: IShipmentAnonymousTimelineEvent
    ): IShipmentAnonymousTimelineEventValue[] => {
      if (
        event.event_type ===
        ShipmentTimelineEventTypeEnum.ALERT_HUMIDITY_INTERNAL
      ) {
        return event.humidity_internal.values;
      }

      if (
        event.event_type ===
        ShipmentTimelineEventTypeEnum.ALERT_HUMIDITY_EXTERNAL
      ) {
        return event.humidity_external.values;
      }

      if (event.event_type === ShipmentTimelineEventTypeEnum.ALERT_PRESSURE) {
        return event.pressure.values;
      }

      if (
        event.event_type ===
        ShipmentTimelineEventTypeEnum.ALERT_TEMPERATURE_INTERNAL
      ) {
        return event.temperature_internal.values;
      }

      if (
        event.event_type ===
        ShipmentTimelineEventTypeEnum.ALERT_TEMPERATURE_EXTERNAL
      ) {
        return event.temperature_external.values;
      }

      return [];
    },
    []
  );

  const getAlertReachedValue = useCallback(
    (event: IShipmentAnonymousTimelineEvent): number => {
      if (
        event.event_type ===
        ShipmentTimelineEventTypeEnum.ALERT_HUMIDITY_INTERNAL
      ) {
        return event.humidity_internal.threshold_type ===
          ShipmentAnonymousTimelineThresholdTypeEnum.MAX
          ? event.humidity_internal.max
          : event.humidity_internal.min;
      }

      if (
        event.event_type ===
        ShipmentTimelineEventTypeEnum.ALERT_HUMIDITY_EXTERNAL
      ) {
        return event.humidity_external.threshold_type ===
          ShipmentAnonymousTimelineThresholdTypeEnum.MAX
          ? event.humidity_external.max
          : event.humidity_external.min;
      }

      if (event.event_type === ShipmentTimelineEventTypeEnum.ALERT_PRESSURE) {
        return event.pressure.threshold_type ===
          ShipmentAnonymousTimelineThresholdTypeEnum.MAX
          ? event.pressure.max
          : event.pressure.min;
      }

      if (
        event.event_type ===
        ShipmentTimelineEventTypeEnum.ALERT_TEMPERATURE_INTERNAL
      ) {
        return event.temperature_internal.threshold_type ===
          ShipmentAnonymousTimelineThresholdTypeEnum.MAX
          ? event.temperature_internal.max
          : event.temperature_internal.min;
      }

      if (
        event.event_type ===
        ShipmentTimelineEventTypeEnum.ALERT_TEMPERATURE_EXTERNAL
      ) {
        return event.temperature_external.threshold_type ===
          ShipmentAnonymousTimelineThresholdTypeEnum.MAX
          ? event.temperature_external.max
          : event.temperature_external.min;
      }

      if (
        event.event_type === ShipmentTimelineEventTypeEnum.ALERT_BATTERY_LEVEL
      ) {
        return event.battery_level.threshold_type ===
          ShipmentAnonymousTimelineThresholdTypeEnum.MAX
          ? Math.floor(event.battery_level.max)
          : Math.floor(event.battery_level.min);
      }

      if (event.event_type === ShipmentTimelineEventTypeEnum.ALERT_FALL) {
        return Math.floor(event.shock.value * 100);
      }

      return 0;
    },
    []
  );

  const getEventCurrentValue = useCallback(
    (event: IShipmentAnonymousTimelineEvent): number => {
      const currentValue = getAlertValuesList(event)[0].value;

      if (
        event.event_type === ShipmentTimelineEventTypeEnum.ALERT_BATTERY_LEVEL
      ) {
        return Math.floor(currentValue);
      }

      return currentValue;
    },
    [getAlertValuesList]
  );

  const isEventClosed = useCallback(
    (event: IShipmentAnonymousTimelineEvent): boolean =>
      event.closed_at !== undefined,
    []
  );

  const isEventWithGauge = useCallback(
    (event: IShipmentAnonymousTimelineEvent): boolean =>
      [
        ShipmentTimelineEventTypeEnum.ALERT_TEMPERATURE_EXTERNAL,
        ShipmentTimelineEventTypeEnum.ALERT_TEMPERATURE_INTERNAL,
        ShipmentTimelineEventTypeEnum.ALERT_PRESSURE,
        ShipmentTimelineEventTypeEnum.ALERT_HUMIDITY_EXTERNAL,
        ShipmentTimelineEventTypeEnum.ALERT_HUMIDITY_INTERNAL,
        ShipmentTimelineEventTypeEnum.ALERT_SHOCK,
        ShipmentTimelineEventTypeEnum.ALERT_FALL,
        ShipmentTimelineEventTypeEnum.ALERT_BATTERY_LEVEL,
      ].includes(event.event_type),
    []
  );

  return {
    currentAnonymousTrackingData,
    setCurrentAnonymousTrackingData,
    getCurrentAnonymousTrackingData,
    getShipmentAnonymousDetail,
    loadingShipmentAnonymousDetail,
    isShipmentAnonymousTimelineItemAlert,
    getAlertThresholdLimitText,
    getAlertReachedValue,
    getEventCurrentValue,
    isEventClosed,
    isEventWithGauge,
    getAlertThresholdAnonymous,
    getAlertThresholdTypeAnonymous,
  };
};

export default useShipmentAnonymousDetail;
