import React from 'react';

import { Button } from '@livingpackets/design-system-react';
import { Box, Stack, styled } from '@livingpackets/design-system-react-next';
import Input from 'components/atoms/Input';
import useFormTemplate from 'hooks/useFormTemplate';
import get from 'lodash/get';
import { CarrierInfo } from 'models/carriers';
import { IOcrLabel, ShipmentParcelData } from 'models/shipment';
import { FieldValues, SubmitHandler } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { shipmentParcelSchema } from 'schemas/shipmentSchema';

const ButtonsWrapper = styled('div')`
  display: flex;
  justify-content: flex-end;
`;

export interface ShipmentParcelFormProps {
  label: IOcrLabel;
  carrierInfo: CarrierInfo;
  onSubmit: (shipmentParcelData: ShipmentParcelData) => void | Promise<void>;
}

const ShipmentParcelForm = ({
  label,
  carrierInfo,
  onSubmit,
}: ShipmentParcelFormProps) => {
  const { t } = useTranslation('shipments');

  const {
    errors,
    register,
    handleSubmit,
    formState: { touchedFields, isValid },
  } = useFormTemplate<ShipmentParcelData>({
    resolver: shipmentParcelSchema,
    defaultValues: {
      weightInGrams: label.weightInGrams,
      carrierTrackingCode: label.carrierTrackingCode,
      referenceNumber: label.referenceNumber,
    },
  });

  const onSubmitForm: SubmitHandler<FieldValues> = (data: FieldValues) => {
    onSubmit({
      weightInGrams: data.weightInGrams,
      carrierTrackingCode: data.carrierTrackingCode,
      referenceNumber: data.referenceNumber,
    });
  };

  return (
    <Box component="form" onSubmit={handleSubmit(onSubmitForm)}>
      <Stack direction="row" marginTop="1rem">
        <Stack sx={{ width: '100%', marginRight: '20px' }}>
          <Input
            required
            name="carrierTrackingCode"
            label={
              get(touchedFields, 'carrierTrackingCode', false)
                ? t('form.importInformation.fields.carrierTrackingCode.label')
                : undefined
            }
            placeholder={t(
              'form.importInformation.fields.carrierTrackingCode.placeholder'
            )}
            error={get(errors, 'carrierTrackingCode', false)}
            isTouched={get(touchedFields, 'carrierTrackingCode', false)}
            register={register}
            width="100%"
          />
          <Input
            disabled
            name="carrierCode"
            label={
              get(touchedFields, 'carrierCode', false)
                ? t('form.importInformation.fields.carrierCode.label')
                : undefined
            }
            placeholder={t(
              'form.importInformation.fields.carrierCode.placeholder'
            )}
            value={carrierInfo.code.toLowerCase()}
            width="100%"
            register={register}
          />
        </Stack>
        <Stack sx={{ width: '100%', marginLeft: '20px' }}>
          <Input
            required
            name="referenceNumber"
            label={
              get(touchedFields, 'referenceNumber', false)
                ? t('form.importInformation.fields.referenceNumber.label')
                : undefined
            }
            placeholder={t(
              'form.importInformation.fields.referenceNumber.placeholder'
            )}
            error={get(errors, 'referenceNumber', false)}
            isTouched={get(touchedFields, 'referenceNumber', false)}
            register={register}
            width="100%"
          />
          <Input
            type="number"
            name="weightInGrams"
            label={
              get(touchedFields, 'weightInGrams', false)
                ? t('form.importInformation.fields.weightInGrams.label')
                : undefined
            }
            placeholder={t(
              'form.importInformation.fields.weightInGrams.placeholder'
            )}
            error={get(errors, 'weightInGrams', false)}
            isTouched={get(touchedFields, 'weightInGrams', false)}
            register={register}
            width="100%"
            onKeyPress={(event: any) => {
              if (!/\d/.test(event.key)) {
                event.preventDefault();
              }
            }}
          />
        </Stack>
      </Stack>
      <ButtonsWrapper>
        <Button
          id="submitBtn"
          data-testid="import-shipment-parcel-details-confirm"
          action="primary"
          type="submit"
          isDisabled={!isValid}
          style={{ width: '192px' }}
        >
          {t('form.importInformation.button.confirm')}
        </Button>
      </ButtonsWrapper>
    </Box>
  );
};

export default ShipmentParcelForm;
