import React, { useState } from 'react';

import {
  Box,
  ChevronDownIcon,
  DownloadDocumentIcon,
  Text,
  styled,
  useTheme,
} from '@livingpackets/design-system-react-next';
import Divider from 'components/atoms/Divider';
import BoxContractDetailCard from 'components/molecules/BoxContractDetailCard';
import { useMonetaryAmountConverter } from 'helpers/currencyConverter';
import CamelCase from 'lodash/camelCase';
import { IBoxContract } from 'models/boxContract';
import { useTranslation } from 'react-i18next';

import useIntlFormatTimestamp from '../../hooks/useIntlFormatTimestamp';
import Row from '../atoms/Row';

const Wrapper = styled('div')`
  display: flex;
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.palette.custom.neutral.white.pure};
  border-radius: 14px;
  align-items: center;
`;
const DetailedWrapper = styled('div')<{ show?: boolean }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: ${props => (props.show ? 18 : 6)}rem;
  background-color: ${({ theme }) => theme.palette.custom.neutral.white.pure};
  border-radius: 14px;
  margin-bottom: 0.5rem;
  padding: 1rem 3rem 1rem 3rem;
`;
const ArrowWrapper = styled('div')`
  cursor: pointer;
`;
const ComingSoonDiv = styled('div')`
  position: absolute;
  left: -2.5rem;
  bottom: 2rem;
  width: 14px;
  height: 17px;
  rotate: 224deg;
  box-shadow: 0 -8px 20px rgba(0, 0, 0, 0.2);
  z-index: 1;
`;
const BoxContractListItem = ({
  boxContract,
  isPartner,
}: {
  boxContract: IBoxContract;
  isPartner?: boolean;
}) => {
  const theme = useTheme();
  const { t } = useTranslation('buyAndRent');

  const convertMoney = useMonetaryAmountConverter();

  const [show, setShow] = useState(false);
  const [isShown, setIsShown] = useState(false);

  const intlFormatTimestamp = useIntlFormatTimestamp();
  const mouseEnter = () => {
    setIsShown(true);
  };
  const mouseLeave = () => {
    setIsShown(false);
  };

  return (
    <>
      <Box
        sx={{
          position: 'relative',
          display: 'flex',
          width: '100%',
        }}
      >
        <DetailedWrapper show={show}>
          <Wrapper>
            <Row width="12%">
              <Text variant="titleXS">
                {intlFormatTimestamp(boxContract.paymentReceivedAt, {
                  day: 'numeric',
                  month: 'short',
                  year: 'numeric',
                  hour: undefined,
                  minute: undefined,
                })}
              </Text>
            </Row>
            <Divider
              marginRight="2.5rem"
              marginLeft="0.5rem"
              direction="vertical"
              borderColor={theme.palette.custom.neutral.black[10]}
              height="3.25rem"
            />
            <Row width="23%">
              <Text variant="titleXS">
                {boxContract.paymentMethod
                  ? t(
                      `buyNRent.paymentMethod.${CamelCase(
                        boxContract.paymentMethod
                      )}` as unknown as TemplateStringsArray
                    )
                  : ''}
              </Text>
            </Row>
            <Row width="19%">
              <Text variant="titleXS">
                {boxContract.numOfBox === 1
                  ? t('buyNRent.boxes.singular')
                  : t('buyNRent.boxes.plural', {
                      boxNumber: boxContract.numOfBox,
                    })}
              </Text>
            </Row>
            <Row width="24%">
              <Text variant="titleXS">
                {convertMoney(boxContract.totalAmountInCents / 100, {
                  precision: 2,
                })}
              </Text>
            </Row>
            <Row width="8%">
              <Box sx={{ position: 'relative', maxHeight: '1rem' }}>
                <DownloadDocumentIcon
                  color={theme.palette.custom.primary[100]}
                  style={{
                    position: 'absolute',
                    right: '0.1rem',
                    bottom: '0rem',
                    opacity: 0.5,
                  }}
                />
              </Box>
              <Text
                variant="titleXS"
                sx={{
                  color: '#00BF6F',
                  opacity: 0.5,
                }}
              >
                {t('buyNRent.contracts.buy')}
              </Text>
            </Row>
            <Row width="8%">
              <Box sx={{ position: 'relative', maxHeight: '1rem' }}>
                <DownloadDocumentIcon
                  color={theme.palette.custom.primary[100]}
                  onMouseEnter={mouseEnter}
                  onMouseLeave={mouseLeave}
                  style={{
                    position: 'absolute',
                    bottom: '0rem',
                    right: '0.1rem',
                    opacity: 0.5,
                  }}
                />
              </Box>
              <Text
                variant="titleXS"
                style={{
                  color: '#00BF6F',
                  opacity: 0.5,
                }}
              >
                {t('buyNRent.contracts.rent')}
              </Text>
            </Row>
            <Row>
              <ArrowWrapper onClick={() => setShow(!show)}>
                <ChevronDownIcon
                  color={theme.palette.custom.neutral.black[50]}
                  style={{
                    transform: !show ? 'rotate(180deg)' : 'rotate(0deg)',
                    transition: '500ms',
                  }}
                />
              </ArrowWrapper>
            </Row>
            <Row style={{ position: 'relative', top: '2.5rem' }}>
              {isShown && (
                <>
                  <ComingSoonDiv></ComingSoonDiv>
                  <Box
                    sx={{
                      position: 'absolute',
                      left: '-2.1rem',
                      width: '12rem',
                      backgroundColor: '#FFFFFF',
                      height: '6.3rem',
                      bottom: '-0.65rem',
                      borderRadius: '14px',
                      padding: '1rem',
                      zIndex: 1,
                      boxShadow: '0px 8px 20px rgba(0, 0, 0, -0.15)',
                      border: 'none',
                    }}
                  >
                    <Text variant="bodyTextS">
                      {t('buyNRent.contracts.pdfContractText')}
                    </Text>
                    <Text
                      color="custom.primary.100"
                      style={{ marginBottom: '1rem' }}
                      variant="bodyTextS"
                    >
                      {t('buyNRent.contracts.cxTeam')}
                    </Text>
                  </Box>
                </>
              )}
            </Row>
          </Wrapper>
          {show && (
            <BoxContractDetailCard
              boxContract={boxContract}
              isPartner={isPartner}
            />
          )}
        </DetailedWrapper>
      </Box>
      <Divider direction="horizontal" />
    </>
  );
};

export default BoxContractListItem;
