import { useCallback } from 'react';

import useImperativeRequest from 'hooks/useImperativeRequest';
import { TokenRefreshRequest, TokenResponse } from 'models/public';

const usePublic = () => {
  const [{ loading }, makeRequest] = useImperativeRequest('shipment');

  const postSignup = useCallback(async () => {
    const { error, data } = await makeRequest({
      path: 'api/v2/auth/signup',
      method: 'POST',
    });
    if (error) {
      return { success: false, tokenResponse: null };
    }

    return { success: true, tokenResponse: data as TokenResponse };
  }, [makeRequest]);

  const postRefreshToken = useCallback(
    async (tokenRefreshRequest: TokenRefreshRequest) => {
      const { error, data } = await makeRequest({
        path: 'api/v2/auth/token',
        method: 'POST',
        body: tokenRefreshRequest,
      });
      if (error) {
        return { success: false, tokenResponse: null };
      }

      return { success: true, tokenResponse: data as TokenResponse };
    },
    [makeRequest]
  );

  return {
    loading,
    postSignup,
    postRefreshToken,
  };
};

export default usePublic;
