import { ContactAddressType } from 'models/contactAddress';
import { Control, DeepMap } from 'react-hook-form';

// @TODO: Fix the "type" declaration because it is not compatible with the ILPVillageAddress interface when override
export interface IBaseAddress {
  type?: TVillageAddressType | ContactAddressType;
  company: string;
  firstName: string;
  lastName: string;
  houseNumber?: string;
  street: string;
  street2?: string;
  postalCode: string;
  city: string;
  state?: string;
  countryCode: string;
  country_code?: string;
}

export interface ILPAccountAddress extends IBaseAddress {
  displayName: string;
  id: string;
  versionHash: string;
  accountId: string;
  creatorId: string;
  isPrimary: boolean;
  types: ('shipping' | 'billing' | 'unknown')[];
  position:
    | {
        latitude: number;
        longitude: number;
      }
    | {};
  createdAt: number;
  updatedAt: number;
}

export interface IContributionAddress
  extends Omit<ILPAccountAddress, 'accountId'> {
  userId: string;
}

export type TVillageAddressType = 'registration' | 'billing' | 'sender';
export type TStakeholder = 'shop' | 'partner';

export interface ILPVillageAddress
  extends Omit<ILPAccountAddress, 'isPrimary' | 'types' | 'accountId'> {
  type: TVillageAddressType | ContactAddressType;
  stakeholder: TStakeholder;
}

export interface IAddressForm {
  errors: DeepMap<any, true>;
  touched: DeepMap<any, true>;
  register: any;
  control: Control;
  prefix?: string;
  isDisabled?: boolean;
}

export interface IShipmentAddress extends IBaseAddress {
  displayName: string;
  originalAddressId: string;
  createdBy: string;
  id: string;
  position: {
    latitude: number;
    longitude: number;
  } | null;
  createdAt: number;
  updatedAt: number;
  versionHash: string;
}

export const BASE_ADDRESS = {
  type: ContactAddressType.professional,
  company: '',
  firstName: '',
  lastName: '',
  houseNumber: '',
  street: '',
  street2: '',
  postalCode: '',
  city: '',
  state: '',
  countryCode: '',
  id: '',
  versionHash: '',
  creatorId: '',
  position: {},
  createdAt: 0,
  updatedAt: 0,
};

export const CREATE_SHIPMENT_ADDRESS_DEFAULT = {
  type: ContactAddressType.professional,
  firstName: '',
  lastName: '',
  company: '',
  email: '',
  street: '',
  city: '',
  postalCode: '',
  countryCode: '',
};
export const ADD_BILLING_ADDRESS_DEFAULT = {
  city: '',
  company: '',
  countryCode: '',
  displayName: '',
  firstName: '',
  houseNumber: '',
  lastName: '',
  postalCode: '',
  state: '',
  street: '',
  street2: '',
  type: 'billing' as TVillageAddressType,
};

export const INITIAL_STATE_PARTNER_R_ADDRESS: ILPVillageAddress = {
  ...BASE_ADDRESS,
  displayName: '',
  type: 'registration' as TVillageAddressType,
  stakeholder: 'partner' as TStakeholder,
};

export const INITIAL_STATE_PARTNER_B_ADDRESS: ILPVillageAddress = {
  ...BASE_ADDRESS,
  displayName: '',
  type: 'billing' as TVillageAddressType,
  stakeholder: 'partner' as TStakeholder,
};

export const INITIAL_STATE_PARTNER_S_ADDRESS: ILPVillageAddress = {
  ...BASE_ADDRESS,
  displayName: '',
  type: 'sender' as TVillageAddressType,
  stakeholder: 'partner' as TStakeholder,
};

export const INITIAL_STATE_LPACCOUNT_ADDRESS: ILPAccountAddress = {
  ...BASE_ADDRESS,
  displayName: '',
  id: '',
  versionHash: '',
  accountId: '',
  creatorId: '',
  isPrimary: true,
  types: [],
  position: {},
  createdAt: 0,
  updatedAt: 0,
};
