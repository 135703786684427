import React, { useCallback, useMemo } from 'react';

import { useAuth0 } from '@auth0/auth0-react';
import { Button } from '@livingpackets/design-system-react';
import {
  ExitIcon,
  Text,
  Box,
  styled,
  useTheme,
  Stack,
} from '@livingpackets/design-system-react-next';
import BoxImg from 'assets/theBoxPng.png';
import Card from 'components/atoms/Card';
import PBig from 'components/atoms/PBig';
import Row from 'components/atoms/Row';
import SecuredByAuth0 from 'components/molecules/SecuredByAuth0';
import { PATHS } from 'configs';
import { getLocationLanguage } from 'helpers/tosHelpers';
import useAcceptTos from 'hooks/useAcceptTos';
import useToastMessages from 'hooks/useToastMessages';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import useAppState, {
  dispatch,
  tosSelector,
} from 'stores/appState/useAppState';

const Container = styled(Row)`
  width: 100%;
  min-height: 100vh;
  justify-content: center;
  align-items: center;
  overflow: auto;
  background: ${({ theme }) => theme.palette.custom.neutral.black[3]};
`;

const StyledCard = styled(Card)`
  padding: 2.375rem 1rem;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 2rem 2rem;
  overflow-y: unset;

  @media (min-width: ${({ theme }) => theme.mediaBreakpoints.sm}) {
    width: 28rem;
    margin: 0;
    padding: 2.375rem 2rem;
  }
`;

const StyledDivider = styled('div')`
  height: 0;
  border: solid 1px ${({ theme }) => theme.palette.custom.neutral.black[3]};
  border-radius: 20px;
  width: 100%;
  margin-top: 2rem;
`;

const StyledLink = styled('a')`
  cursor: pointer;
  color: ${({ theme }) => theme.palette.custom.primary[100]};
  text-decoration: none;
`;

const StyledImg = styled('img')`
  height: 3rem;
`;

const AcceptTos = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const {
    t,
    i18n: { language },
  } = useTranslation(['general', 'messages']);
  const { logout } = useAuth0();

  const { success: successToast } = useToastMessages();
  const tosState = useAppState(tosSelector);
  const { acceptTos, loading } = useAcceptTos();

  const handleAccept = useCallback(async () => {
    const { success } = await acceptTos(tosState.currentTosPaaS?.id || '');
    const { success: successFinancial } = await acceptTos(
      tosState.currentTosFinancial?.id || ''
    );
    if (success && successFinancial) {
      dispatch({
        type: 'SET_TOS',
        payload: {
          state: 'UNKNOWN',
          currentTosPaaS: null,
          upcomingTosPaaS: null,
          currentTosFinancial: null,
          upcomingTosFinancial: null,
          firstTime: false,
        },
      });
      successToast('messages:acceptedTos.message', { persist: true });
    }
  }, [tosState, acceptTos, successToast]);

  const handleLogout = useCallback(() => {
    logout({
      returnTo: window.location.origin,
    });
  }, [logout]);

  const tosLinkPaaS = useMemo(() => {
    if (!tosState.currentTosPaaS?.tosLocation) {
      return '';
    }

    return getLocationLanguage(language, tosState.currentTosPaaS.tosLocation);
  }, [language, tosState]);

  const tosLinkFinancial = useMemo(() => {
    if (!tosState.currentTosFinancial?.tosLocation) {
      return '';
    }

    return getLocationLanguage(
      language,
      tosState.currentTosFinancial.tosLocation
    );
  }, [language, tosState]);

  const copy = useMemo(
    () =>
      tosState.firstTime
        ? 'general:tos.firstTimeTosCopy'
        : 'general:tos.tosCopy',
    [tosState.firstTime]
  );

  if (
    tosState.currentTosPaaS?.accepted &&
    tosState.currentTosFinancial?.accepted
  ) {
    navigate(PATHS.ROOT);
  }

  return (
    <Container>
      <StyledCard>
        <StyledImg src={BoxImg} />
        <Text variant="titleM" my={4}>
          {t('general:tos.tosHeader')}
        </Text>
        <PBig my="1rem" textAlign="center">
          <Trans t={t} i18nKey={copy} />
          <Row flexDirection="column" alignItems="flex-start" mt="1rem">
            <li style={{ color: theme.palette.custom.primary[100] }}>
              <Trans
                t={t}
                i18nKey="general:tos.linkTosPaas"
                components={{
                  tosLinkPaaS: (
                    <StyledLink
                      href={tosLinkPaaS}
                      target="_blank"
                      rel="noopener noreferrer"
                      data-testid="tos-s3-link"
                    />
                  ),
                }}
              />
            </li>
            <li style={{ color: theme.palette.custom.primary[100] }}>
              <Trans
                t={t}
                i18nKey="general:tos.linkTosFinancial"
                components={{
                  tosLinkFinancial: (
                    <StyledLink
                      href={tosLinkFinancial}
                      target="_blank"
                      rel="noopener noreferrer"
                      data-testid="tos-s3-link"
                    />
                  ),
                }}
              />
            </li>
          </Row>
        </PBig>
        <Text
          variant="bodyTextS"
          color="custom.neutral.black.50"
          style={{ textAlign: 'center' }}
        >
          {t('general:tos.acceptCaption')}
        </Text>
        <Box width="15rem">
          <Button
            action="primary"
            onClick={handleAccept}
            isLoading={loading}
            data-testid="accept-cta"
          >
            {t('general:tos.acceptCta')}
          </Button>
        </Box>
        <Stack
          direction="row"
          mt="1rem"
          justifyContent="center"
          alignItems="center"
        >
          <Text
            variant="titleXS"
            color="custom.primary.100"
            onClick={handleLogout}
            data-testid="logout"
            sx={{ cursor: 'pointer' }}
          >
            {t('general:logout')}
          </Text>
          <ExitIcon size="1.8rem" color={theme.palette.custom.primary[100]} />
        </Stack>
        <StyledDivider />
        <Row mt="1.5rem">
          <SecuredByAuth0 />
        </Row>
      </StyledCard>
    </Container>
  );
};

export default AcceptTos;
