import { useCallback } from 'react';

import { PaginationParams } from '@livingpackets/shared-components';
import { AxiosResponse } from 'axios';
import { ShipmentDirection } from 'components/organisms/PartnerShipmentsTable';
import useLpVillageService from 'hooks/useLpVillageService';
import {
  IShipment,
  ShipmentContractState,
  ShipmentContractStateEnum,
} from 'models/shipment';

import { useApiClient } from './useApiClient';
import usePartnerShipmentsStore from '../stores/usePartnerShipmentsStore';

export interface PaginatedResponse<Type> {
  items: Type[];
  count: number;
  total: number;
}

export interface GetPartnerShipmentsParams extends Partial<PaginationParams> {
  partnerId: string;
  direction?: ShipmentDirection;
  contractState?: ShipmentContractState;
  isArchived?: boolean;
  shipmentName?: string;
  recipient?: string;
  sender?: string;
  search?: string;
  carrier?: string;
  deployedAfter?: string;
  deployedBefore?: string;
}

type GetPartnerShipments = (
  params: GetPartnerShipmentsParams
) => Promise<PaginatedResponse<IShipment>>;

export function useGetPartnerShipments(): GetPartnerShipments {
  const shipment = useApiClient('shipment');
  const { getPartnerParcel } = useLpVillageService();
  const { dispatch } = usePartnerShipmentsStore();
  const getPartnerShipments = useCallback(
    ({
      page = 1,
      pageSize = 100,
      orderColumn = 'updatedAt',
      orderDirection = 'DESC',
      partnerId,
      direction,
      contractState,
      isArchived,
      shipmentName,
      recipient,
      sender,
      search,
      carrier,
      deployedAfter,
      deployedBefore,
    }: GetPartnerShipmentsParams): Promise<PaginatedResponse<IShipment>> =>
      shipment
        .get<PaginatedResponse<IShipment>>('/api/v2/shipments', {
          params: {
            offset: (page - 1) * pageSize,
            pageSize,
            sortBy: orderColumn,
            order: orderDirection,
            partnerId,
            direction,
            contractState,
            isArchived,
            shipmentName,
            recipient,
            sender,
            search,
            carrier,
            deployedAfter,
            deployedBefore,
          },
        })
        .then(async (response: AxiosResponse<PaginatedResponse<IShipment>>) => {
          response.data.items = await Promise.all(
            response.data.items.map(async (shipment: IShipment) => {
              if (
                shipment.parcelRequestId &&
                shipment.contract_state !== ShipmentContractStateEnum.PURCHASED
              ) {
                const response = await getPartnerParcel(
                  shipment.parcelRequestId
                );

                if (response.success) {
                  shipment.partnerParcelData = response.data;
                }
              }

              if (
                shipment.returnShipment &&
                shipment.returnShipment.parcelRequestId &&
                shipment.returnShipment.contract_state !==
                  ShipmentContractStateEnum.PURCHASED
              ) {
                const response = await getPartnerParcel(
                  shipment.returnShipment.parcelRequestId
                );

                if (response.success) {
                  shipment.returnShipment.partnerParcelData = response.data;
                }
              }

              if (
                shipment.originalShipment &&
                shipment.originalShipment.parcelRequestId &&
                shipment.originalShipment.contract_state !==
                  ShipmentContractStateEnum.PURCHASED
              ) {
                const response = await getPartnerParcel(
                  shipment.originalShipment.parcelRequestId
                );

                if (response.success) {
                  shipment.originalShipment.partnerParcelData = response.data;
                }
              }

              return shipment;
            })
          );
          if (response.data.count === 0 && page > 1) {
            return getPartnerShipments({
              partnerId,
              direction,
              contractState,
              isArchived,
              shipmentName,
              recipient,
              sender,
              search,
              carrier,
              page: page - 1,
              pageSize,
            });
          }

          dispatch({
            type: 'updateRowData',
            args: {
              rowData: response.data.items,
            },
          });
          dispatch({
            type: 'updateCount',
            args: {
              count: response.data.count,
            },
          });
          dispatch({
            type: 'updateTotal',
            args: {
              total: response.data.total,
            },
          });
          dispatch({
            type: 'updatePagination',
            args: {
              offset: (page - 1) * pageSize,
              pageSize: pageSize,
            },
          });

          return response.data;
        })
        .catch(
          () =>
            ({
              items: [],
              count: 0,
              total: 0,
            } as PaginatedResponse<IShipment>)
        ),
    [shipment, getPartnerParcel, dispatch]
  );

  return getPartnerShipments;
}
