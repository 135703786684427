import React from 'react';

import PartnerMemberManagementAdd from 'components/molecules/userManagement/PartnerMemberManagementAdd';
import PartnerMemberManagementEdit from 'components/molecules/userManagement/PartnerMemberManagementEdit';
import PartnerUserManagementOverview from 'components/molecules/userManagement/PartnerMemberManagementOverview';
import { ROUTE_PATHS } from 'configs';
import { Route, Routes } from 'react-router-dom';

const PartnerUserManagement = () => (
  <Routes>
    <Route index element={<PartnerUserManagementOverview />} />
    <Route
      path={ROUTE_PATHS.PARTNER.CHILDREN.USER_MANAGEMENT.CHILDREN.CREATE.ROOT}
      element={<PartnerMemberManagementAdd />}
    />
    <Route path={ROUTE_PATHS.PARTNER.CHILDREN.USER_MANAGEMENT.CHILDREN.ID.ROOT}>
      <Route
        path={
          ROUTE_PATHS.PARTNER.CHILDREN.USER_MANAGEMENT.CHILDREN.ID.CHILDREN.EDIT
            .ROOT
        }
        element={<PartnerMemberManagementEdit />}
      />
    </Route>
  </Routes>
);

export default PartnerUserManagement;
