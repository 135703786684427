import React from 'react';

import { Text, styled } from '@livingpackets/design-system-react-next';
import { layout, LayoutProps } from 'styled-system';

export interface ITab extends LayoutProps {
  children: React.ReactNode;
  selected: boolean;
  onClick: any;
  testId?: string;
}

const StyledTab = styled('div')<LayoutProps & Pick<ITab, 'selected'>>`
  display: flex;
  justify-content: space-around;
  align-items: center;
  color: ${({ selected, theme }) =>
    selected
      ? theme.palette.custom.primary[100]
      : theme.palette.custom.neutral.black[50]};
  ${layout};
  cursor: pointer;
  position: relative;
  margin-right: 25px;
  transition: all 500ms ease;

  &:before {
    content: '';
    width: 100%;
    height: 2px;
    display: block;
    bottom: 0;
    position: absolute;
    background-color: ${({ theme }) => theme.palette.custom.primary[100]};
    border-radius: 50px;
    opacity: 0;
    transition: all 500ms ease;
  }

  ${props =>
    props.selected &&
    `
      &:before {
        opacity: 1;
      }
  `};
`;

const Tab = ({
  height = '38px',
  selected,
  onClick,
  children,
  testId,
}: ITab) => (
  <StyledTab
    height={height}
    selected={selected}
    onClick={onClick}
    data-testid={testId}
  >
    <Text variant="titleXS">{children}</Text>
  </StyledTab>
);

export default Tab;
