import { useCallback } from 'react';

import {
  useFetchResource,
  UseFetchResource,
  UseFetchResourceOptions,
} from '@livingpackets/shared-components';
import { IContribution } from 'models/contribution';

import {
  GetMeContributionsParams,
  PaginatedResponseGetMeContributions,
  useGetMeContributions,
} from './useGetMeContributions';

const useMeContributions = (
  params: GetMeContributionsParams,
  { enabled = true }: UseFetchResourceOptions = {}
): UseFetchResource<PaginatedResponseGetMeContributions<IContribution>> => {
  const getMeContributions = useGetMeContributions();

  const fetchFunction = useCallback(
    () => getMeContributions(params),
    [getMeContributions, params]
  );

  return useFetchResource(fetchFunction, { enabled });
};

export default useMeContributions;
