import { SocialMediaArticle } from 'models/socialMediaArticle';
import { create } from 'zustand';

export interface ISocialMediaStore {
  smArticles: SocialMediaArticle[];
  setSmArticles: (shipments: SocialMediaArticle[]) => void;
}

export const smArticleSelector = (state: ISocialMediaStore) => state.smArticles;
export const setSmArticleSelector = (state: ISocialMediaStore) =>
  state.setSmArticles;

const useSocialMediaArticlesStore = create<ISocialMediaStore>(set => ({
  smArticles: [],
  setSmArticles: smArticles => set(() => ({ smArticles: smArticles })),
}));

export default useSocialMediaArticlesStore;
