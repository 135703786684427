import { ComponentProps } from 'react';

import styled from 'styled-components';
import {
  ColorPropsOverride,
  SpaceProps,
  TypographyProps,
  color,
  space,
  typography,
  LayoutProps,
  layout,
  BorderProps,
  border,
} from 'styled-system';

export type BoxProps = ComponentProps<typeof Box>;

/** `div` wrapper for easy styling with styled-system. */
export const Box = styled.div<
  ColorPropsOverride & TypographyProps & SpaceProps & LayoutProps & BorderProps
>`
  ${color}
  ${space}
  ${typography}
  ${layout}
  ${border}
`;
