import React, { useCallback, useEffect } from 'react';

import { Box, Stack } from '@livingpackets/design-system-react-next';
import Input from 'components/atoms/Input';
import CountrySelector from 'components/organisms/CountrySelector';
import useFormTemplate from 'hooks/useFormTemplate';
import { set } from 'lodash';
import get from 'lodash/get';
import {
  IBillingAddressForm,
  INITIAL_STATE_BILLING_ADDRESS_FORM,
} from 'models/billingAddress';
import { useTranslation } from 'react-i18next';
import { billingAddressSchema } from 'schemas/billingAddressSchema';

interface IBillingAddressType {
  defaultValues?: IBillingAddressForm;
  onValuesChanged: (values: IBillingAddressForm) => void;
  isFormValidChanged: (isFormValid: boolean) => void;
  prefix?: string;
  isDismissTriggered?: boolean;
}

const BillingAddressForm = ({
  defaultValues,
  onValuesChanged,
  isFormValidChanged,
}: IBillingAddressType) => {
  const { t } = useTranslation('accountInformation');

  const {
    errors,
    register,
    formState: { touchedFields, isValid },
    control,
    getValues,
    trigger,
  } = useFormTemplate<IBillingAddressForm>({
    resolver: billingAddressSchema,
    defaultValues: defaultValues
      ? defaultValues
      : INITIAL_STATE_BILLING_ADDRESS_FORM,
  });

  useEffect(() => {
    if (defaultValues) {
      // Manually set the fields as touched
      set(touchedFields, `firstName`, true);
      set(touchedFields, `lastName`, true);
      set(touchedFields, `companyName`, true);
      set(touchedFields, `city`, true);
      set(touchedFields, `address`, true);
      set(touchedFields, `zipCode`, true);
      set(touchedFields, `countryCode`, true);

      trigger();
    }
  }, [defaultValues, touchedFields, trigger]);

  useEffect(() => {
    isFormValidChanged(isValid);
  }, [isValid, isFormValidChanged]);

  const handleInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onValuesChanged(getValues() as IBillingAddressForm);
    },
    [getValues, onValuesChanged]
  );

  return (
    <>
      <Stack direction="row">
        <Input
          name="firstName"
          label={
            get(touchedFields, 'firstName', false)
              ? t('form.billingAddress.fields.firstName.placeholder')
              : undefined
          }
          placeholder={t('form.billingAddress.fields.firstName.placeholder')}
          error={get(errors, 'firstName', false)}
          isTouched={get(touchedFields, 'firstName', false)}
          register={register}
          width="100%"
          marginRight="8px"
          onChange={handleInputChange}
        />
        <Input
          name="lastName"
          label={
            get(touchedFields, 'lastName', false)
              ? t('form.billingAddress.fields.lastName.placeholder')
              : undefined
          }
          placeholder={t('form.billingAddress.fields.lastName.placeholder')}
          error={get(errors, 'lastName', false)}
          isTouched={get(touchedFields, 'lastName', false)}
          register={register}
          width="100%"
          marginLeft="8px"
          onChange={handleInputChange}
        />
      </Stack>
      <Stack direction="row">
        <Input
          name="companyName"
          label={t('form.billingAddress.fields.companyName.label')}
          placeholder={t('form.billingAddress.fields.companyName.placeholder')}
          error={get(errors, 'companyName', false)}
          isTouched={get(touchedFields, 'companyName', false)}
          register={register}
          width="100%"
          marginRight="8px"
          onChange={handleInputChange}
        />
        <Input
          name="city"
          label={
            get(touchedFields, 'city', false)
              ? t('form.billingAddress.fields.city.placeholder')
              : undefined
          }
          placeholder={t('form.billingAddress.fields.city.placeholder')}
          error={get(errors, 'city', false)}
          isTouched={get(touchedFields, 'city', false)}
          register={register}
          width="100%"
          marginLeft="8px"
          onChange={handleInputChange}
        />
      </Stack>
      <Stack direction="row">
        <Input
          name="address"
          label={
            get(touchedFields, 'address', false)
              ? t('form.billingAddress.fields.address.placeholder')
              : undefined
          }
          placeholder={t('form.billingAddress.fields.address.placeholder')}
          error={get(errors, 'address', false)}
          isTouched={get(touchedFields, 'address', false)}
          register={register}
          width="100%"
          marginRight="8px"
          onChange={handleInputChange}
        />
        <Input
          name="zipCode"
          label={
            get(touchedFields, 'zipCode', false)
              ? t('form.billingAddress.fields.zipCode.placeholder')
              : undefined
          }
          placeholder={t('form.billingAddress.fields.zipCode.placeholder')}
          error={get(errors, 'zipCode', false)}
          isTouched={get(touchedFields, 'zipCode', false)}
          register={register}
          width="100%"
          marginLeft="8px"
          onChange={handleInputChange}
        />
      </Stack>
      <Box width="50%" paddingRight="8px !important">
        <CountrySelector
          id="countryCode"
          fieldName="countryCode"
          width="100%"
          control={control}
          register={register}
          onChange={handleInputChange}
        />
      </Box>
    </>
  );
};

export default BillingAddressForm;
