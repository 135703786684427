import { useCallback } from 'react';

import { PATHS } from 'configs';
import { useNavigate } from 'react-router-dom';

export const useRedirectToRetrieveKeyPage = () => {
  const navigate = useNavigate();

  const redirectToRetrieveKeyPage = useCallback(() => {
    navigate(PATHS.AUTHENTICATION.RETRIEVE_DELIVERY_KEY, {
      state: { displayDeliveryKeyExpiredWarningOnPageLoad: true },
    });
  }, [navigate]);

  return redirectToRetrieveKeyPage;
};
