import { PARTNER_PREFIX_ROOT } from 'components/containers/PartnerRouter';
import { NavigateOptions, useNavigate } from 'react-router-dom';
import useMyPartnersStore, {
  activePartnerSelector,
} from 'stores/useMyPartnersStore';

const useNavigatePartner = () => {
  const currentPartner = useMyPartnersStore(activePartnerSelector);
  const navigate = useNavigate();

  return (path: string, options?: NavigateOptions) =>
    navigate(PARTNER_PREFIX_ROOT + '/' + currentPartner.id + path, options);
};

export default useNavigatePartner;
