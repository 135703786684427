import React from 'react';

import { Text, styled, Stack } from '@livingpackets/design-system-react-next';
import livingPacketsLogo from 'assets/living-packets-logo.png';
import livingPacketsLogo2x from 'assets/living-packets-logo@2x.png';
import livingPacketsLogo3x from 'assets/living-packets-logo@3x.png';

import Card from '../atoms/Card';

export enum DescriptionTextFontStyleEnum {
  BOLD = 'titleM',
  REGULAR = 'bodyTextL',
}

export enum LpLogoSizeEnum {
  BIG = 'big',
  REGULAR = 'regular',
}

const Wrapper = styled('div')`
  background: ${({ theme }) => theme.colors.black[3]};
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  text-align: center;
  padding: 10px;

  @media (max-width: ${({ theme }) => theme.mediaBreakpoints.sm}) {
    background: ${({ theme }) => theme.colors.white};
  }
`;

const Container = styled(Card)`
  display: inline-block;
  width: auto;
  padding: 40px 32px 32px;
  overflow-y: initial;

  @media (max-width: ${({ theme }) => theme.mediaBreakpoints.sm}) {
    border-radius: 0;
  }
`;

const LPLogo = styled('img', {
  shouldForwardProp: prop => prop !== 'lpLogoSize',
})<{ lpLogoSize: LpLogoSizeEnum }>`
  height: ${({ lpLogoSize }) =>
    lpLogoSize === LpLogoSizeEnum.BIG ? '36px' : '22px'};
`;

const TextTitle = styled(Text)`
  white-space: break-spaces;
  margin-bottom: 15px;
`;

const TextDescription = styled(Text, {
  shouldForwardProp: prop => prop !== 'descriptionOneLine',
})<{ descriptionOneLine: boolean }>`
  text-align: center;
  white-space: ${({ descriptionOneLine }) =>
    descriptionOneLine ? 'nowrap' : 'break-spaces'};
`;

export function AuthenticationView({
  title,
  description,
  descriptionFontStyle = DescriptionTextFontStyleEnum.BOLD,
  descriptionOneLine = false,
  lpLogoSize = LpLogoSizeEnum.REGULAR,
  children,
}: {
  title?: string;
  description?: string;
  descriptionFontStyle?: DescriptionTextFontStyleEnum;
  descriptionOneLine?: boolean;
  lpLogoSize?: LpLogoSizeEnum;
  children: React.ReactNode;
}): JSX.Element {
  return (
    <Wrapper>
      <Container>
        <LPLogo
          alt="The Box by LivingPackets"
          lpLogoSize={lpLogoSize}
          src={livingPacketsLogo}
          srcSet={`${livingPacketsLogo2x} 2x, ${livingPacketsLogo3x} 3x`}
        />
        <Stack mb="1.25rem" alignItems="center">
          {title && (
            <TextTitle variant="titleXL" mt={6}>
              {title}
            </TextTitle>
          )}
          {description && (
            <TextDescription
              variant={descriptionFontStyle}
              descriptionOneLine={descriptionOneLine}
            >
              {description}
            </TextDescription>
          )}
        </Stack>
        {children}
      </Container>
    </Wrapper>
  );
}
