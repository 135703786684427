import React from 'react';

import { Box } from '@livingpackets/design-system-react';
import ImportShipmentStepTextBlock from 'components/atoms/importShipment/ImportShipmentStepTextBlock';
import { Carriers } from 'configs/carriers';
import { normalizeKey } from 'helpers/i18n';
import { isEmpty } from 'lodash/fp';
import CARRIERS_LIST from 'models/carriers';
import { useTranslation } from 'react-i18next';
import useMyPartnersStore, {
  activePartnerSelector,
} from 'stores/useMyPartnersStore';
import styled from 'styled-components';

import CarrierTile from '../atoms/CarrierTile';

interface ICarrierImgSelector {
  selected: Carriers | undefined;
  setSelected: (carrier?: Carriers) => void;
  onClickNext: () => void;
}

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.black[2]};
  border-radius: 20px;
  padding: 32px 40px;
`;

const TileWrapper = styled.div`
  display: grid;
  gap: 33px 28px;
  grid-template-columns: repeat(auto-fit, minmax(186px, 1fr));
  grid-template-rows: auto;
  width: 100%;
`;

const CarrierImgSelector = ({
  selected,
  setSelected,
  onClickNext,
}: ICarrierImgSelector) => {
  const { t } = useTranslation(['shipments', 'general']);

  const { carriers } = useMyPartnersStore(activePartnerSelector);

  // Filter to avoid any non exising carrier
  let displayedCarriers = CARRIERS_LIST.filter(carrier =>
    carriers?.includes(carrier.code)
  );

  // Skip step if only one carrier
  if (displayedCarriers?.length === 1) {
    setSelected(displayedCarriers[0].code);
    onClickNext();

    return null;
    // If we have no carrier, we display all the available carriers
  } else if (isEmpty(displayedCarriers)) {
    displayedCarriers = CARRIERS_LIST;
  }

  return (
    <Wrapper>
      <Box marginBottom="39px">
        <ImportShipmentStepTextBlock
          number="1"
          title={t('shipments:importLabel.header.steps.carrierSelector.title')}
          subTitle={t(
            'shipments:importLabel.header.steps.carrierSelector.subTitle'
          )}
        />
      </Box>
      <TileWrapper>
        {displayedCarriers.map(carrier => (
          <CarrierTile
            key={carrier.code}
            carrier={carrier.code}
            carrierLabel={t(
              normalizeKey(`shipments:${carrier.code.toLocaleLowerCase()}`)
            )}
            onClick={(carrier: Carriers) => {
              setSelected(carrier);
              onClickNext();
            }}
            logoPath={carrier.logoPath}
            selected={selected === carrier.code}
          />
        ))}
      </TileWrapper>
    </Wrapper>
  );
};

export default CarrierImgSelector;
