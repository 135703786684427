import React from 'react';

import {
  AccountInformationIcon,
  BuyRentIcon,
  ContributionIcon,
  HelpIcon,
  HomeIcon,
  // NotificationIcon,
  ShowroomIcon,
} from '@livingpackets/design-system-react-next';
import PrivateRoute from 'components/containers/PrivateRoute';
import LPAccountHome from 'components/views/lpAccount/LPAccountHome';
import LPAccountProfile from 'components/views/lpAccount/LPAccountProfile';
import LPAccountShowroom from 'components/views/lpAccount/LPAccountShowroom';
import { PATHS, ROUTE_PATHS } from 'configs';
import { IRouteEntry } from 'models/routing';
import { Navigate, Route, Routes } from 'react-router-dom';
import useContributionsStore, {
  contributionsSelector,
} from 'stores/useContributionsStore';

import LPAccountBuyAndRent from '../views/lpAccount/LPAccountBuyAndRent';
import LPAccountInformation from '../views/lpAccount/LPAccountInformation';

export const LP_ACCOUNT_ROUTES: IRouteEntry[] = [
  {
    labelI18nKey: 'subnav.home',
    icon: HomeIcon,
    path: PATHS.ACCOUNT.DASHBOARD,
    routePath: ROUTE_PATHS.ACCOUNT.CHILDREN.DASHBOARD.ROOT,
    component: <LPAccountHome />,
    testId: 'side-nav-lp-account-routes-home',
  },
  {
    labelI18nKey: 'subnav.contributions',
    icon: ContributionIcon,
    path: PATHS.ACCOUNT.PROFILE,
    routePath: ROUTE_PATHS.ACCOUNT.CHILDREN.PROFILE.ROOT,
    component: <LPAccountProfile />,
    testId: 'side-nav-lp-account-routes-contributions',
  },
  {
    labelI18nKey: 'subnav.buynRent',
    icon: BuyRentIcon,
    path: PATHS.ACCOUNT.BUY_RENT,
    routePath: ROUTE_PATHS.ACCOUNT.CHILDREN.BUY_RENT.ROOT,
    component: <LPAccountBuyAndRent />,
    testId: 'side-nav-lp-account-routes-buynRent',
  },
  {
    labelI18nKey: 'subnav.accountInformation',
    icon: AccountInformationIcon,
    path: PATHS.ACCOUNT.INFORMATION,
    routePath: ROUTE_PATHS.ACCOUNT.CHILDREN.INFORMATION.ROOT,
    component: <LPAccountInformation />,
    testId: 'side-nav-lp-account-routes-accountInformation',
  },
  {
    labelI18nKey: 'subnav.showrooms',
    icon: ShowroomIcon,
    path: PATHS.ACCOUNT.SHOWROOMS,
    routePath: ROUTE_PATHS.ACCOUNT.CHILDREN.SHOWROOMS.ROOT,
    component: <LPAccountShowroom />,
    testId: 'side-nav-lp-account-routes-showrooms',
  },
  {
    labelI18nKey: 'subnav.help',
    icon: HelpIcon,
    path: PATHS.ACCOUNT.HELP,
    routePath: ROUTE_PATHS.ACCOUNT.CHILDREN.HELP.ROOT,
    testId: 'side-nav-lp-account-routes-help',
  },
];

const LPAccountRouter = () => {
  const contributions = useContributionsStore(contributionsSelector);

  return (
    <Routes>
      <Route index={true} element={<Navigate to="dashboard" />} />
      {LP_ACCOUNT_ROUTES.filter(routes => {
        if (routes.routePath === 'profile') {
          return contributions.length > 0;
        }

        return true;
      }).map(route => (
        <Route
          key={route.path}
          index={route.index}
          path={!route.index ? route.routePath : undefined}
          element={<PrivateRoute component={route.component} />}
        />
      ))}
      <Route path="*" element={<Navigate to="dashboard" />} />
    </Routes>
  );
};

export default LPAccountRouter;
