import i18next from 'i18next';
import { PartnerTypeEnum } from 'stores/useMyPartnersStore';
import * as yup from 'yup';

export const memberSchema = () =>
  yup.object().shape({
    firstName: yup.string().when('partnerType', {
      is: (value: any) => value === PartnerTypeEnum.INTERNAL,
      then: () =>
        yup
          .string()
          .required(
            i18next.t('userManagement:form.firstName.validation.required')
          ),
    }),
    lastName: yup.string().when('partnerType', {
      is: (value: any) => value === PartnerTypeEnum.INTERNAL,
      then: () =>
        yup
          .string()
          .required(
            i18next.t('userManagement:form.lastName.validation.required')
          ),
    }),
    email: yup
      .string()
      .required(i18next.t('userManagement:form.email.validation.required'))
      .email(i18next.t('userManagement:form.email.validation.email')),
    roleId: yup
      .string()
      .required(i18next.t('userManagement:form.role.validation.required')),
    partnerType: yup.string(),
  });

export const partnerAccountSearchSchema = () =>
  yup.object().shape({
    searchTerm: yup.string(),
  });
