import { useCallback } from 'react';

import { captureError } from 'helpers/errorTracing';
import useToastMessages from 'hooks/useToastMessages';
import { ToastSettings } from 'stores/useToasts';

interface IErrorOptions {
  addToast?: boolean;
  toastSettings?: ToastSettings;
  trackError?: boolean;
  //toastMessage is either the message itself,
  // or a function which parses the error and returns the message
  toastMessage?: string | ((error: any) => string);
}

const DEFAULT_OPTIONS = {
  addToast: false,
  trackError: true,
};

export const useError = () => {
  const { error: addErrorMsg } = useToastMessages();

  return useCallback(
    (
      error: Error,
      {
        addToast = DEFAULT_OPTIONS.addToast,
        toastSettings,
        trackError = DEFAULT_OPTIONS.trackError,
        toastMessage,
      }: IErrorOptions = DEFAULT_OPTIONS
    ) => {
      if (addToast && toastMessage) {
        if (typeof toastMessage === 'string') {
          addErrorMsg(toastMessage, toastSettings);
        } else {
          addErrorMsg(toastMessage(error), toastSettings);
        }
      }
      if (trackError) {
        captureError(error);
      }
    },
    [addErrorMsg]
  );
};

export const useIntlError = () => {
  const addError = useError();

  return useCallback(
    (
      error: Error,
      {
        addToast = DEFAULT_OPTIONS.addToast,
        toastSettings,
        trackError = DEFAULT_OPTIONS.trackError,
        toastMessage,
      }: IErrorOptions = DEFAULT_OPTIONS
    ) => {
      if (!toastMessage) {
        return addError(error, { addToast, trackError, toastSettings });
      }

      return addError(error, {
        addToast,
        toastSettings,
        trackError,
        toastMessage,
      });
    },
    [addError]
  );
};
