import React from 'react';

import styled, { keyframes } from 'styled-components';

const defaults = {
  ovBg: 'rgba(0, 0, 0, 0.7)',
};

interface IFixedLoader {
  loader: JSX.Element;
  ovBg?: string;
}

const scaleOutKS = keyframes`
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1.0);
  }
`;

const Overlay = styled.div<any>`
  position: fixed;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  overflow: hidden;
  transition: background-color 500ms ease-in-out;
  pointer-events: initial;
  opacity: 1;
  background-color: ${({ ovBg = defaults.ovBg }) => ovBg};
  z-index: 100;
`;

const Fixed = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const Anim = styled.div`
  animation: ${scaleOutKS} 0.5s ease-out;
`;

const FixedLoader: React.FC<IFixedLoader & any> = ({
  loader,
  ovBg,
  ...rest
}) => (
  <Overlay ovBg={ovBg}>
    <Fixed {...rest}>
      <Anim>{loader}</Anim>
    </Fixed>
  </Overlay>
);

export default FixedLoader;
