import { useEffect } from 'react';

import { loadCrisp } from 'helpers/tracking';
import { isMobile } from 'react-device-detect';
import ReactGA from 'react-ga';

import history from '../helpers/history';

history.listen(location => {
  ReactGA.set({ page: location.location.pathname });
  ReactGA.pageview(location.location.pathname);
});

const useTracking = () => {
  useEffect(() => {
    // Display crisp only Desktop
    if (!isMobile) {
      loadCrisp();
    }
  }, []);
};

export default useTracking;
