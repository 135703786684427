import React, { useEffect, useState } from 'react';

import {
  Box,
  ButtonV2 as Button,
  Stepper,
  Stack,
  useTheme,
} from '@livingpackets/design-system-react-next';
import popupPair from 'assets/lotties/popup-pair.json';
import wakeUp from 'assets/lotties/WakeUp.json';
import { useTranslation } from 'react-i18next';

import BLEInstruction from './BLEInstruction';

const DEFAULT_INTERVAL = 5000;
const LAST_STEP_INTERVAL = 8000;

const BLEModal = ({ handleClickScan }: { handleClickScan: any }) => {
  const [currentPosition, setCurrentPosition] = useState<number>(0);

  const theme = useTheme();

  const { t } = useTranslation('shipments');

  const bleInstructionSteps = [
    {
      position: 1,
      title: t('anonymous.mobile.wakeup.title'),
      description: t('anonymous.mobile.wakeup.description'),
      ressource: wakeUp,
    },
    {
      position: 2,
      title: t('anonymous.mobile.pairing.title'),
      description: t('anonymous.mobile.pairing.description'),
      ressource: popupPair,
    },
    {
      title: t('anonymous.mobile.ready.title'),
      description: t('anonymous.mobile.ready.description'),
    },
  ];

  const BLE_INSTRUCTION_LENGTH = bleInstructionSteps.length - 1;

  useEffect(() => {
    const interval = setInterval(
      () => {
        if (currentPosition < BLE_INSTRUCTION_LENGTH) {
          setCurrentPosition(currentPosition + 1);
        } else {
          setCurrentPosition(0);
        }
      },
      currentPosition === BLE_INSTRUCTION_LENGTH
        ? LAST_STEP_INTERVAL
        : DEFAULT_INTERVAL
    );

    return () => clearInterval(interval);
  }, [BLE_INSTRUCTION_LENGTH, currentPosition]);

  return (
    <Box
      sx={{
        height: '100vh',
        width: '100%',
        paddingTop: '40%',
        paddingBottom: '1.125rem',
        position: 'relative',
        overflowY: 'auto',
        ':before': {
          content: '""',
          height: '50%',
          top: '-8%',
          width: '150%',
          left: '-24%',
          right: 0,
          backgroundColor: theme.palette.custom.neutral.black[8],
          position: 'absolute',
          borderRadius: '50%',
          zIndex: -1,
        },
      }}
    >
      <Box
        display="flex"
        flexDirection="column"
        gap="1rem"
        alignItems="center"
        minHeight="31.625rem"
      >
        <BLEInstruction stepPosition={currentPosition} />

        <Stepper
          steps={bleInstructionSteps.length}
          activeStep={currentPosition}
          position="static"
          sx={{
            justifyContent: 'center',
            background: 'red',
            maxWidth: '37.5rem',
          }}
        />
      </Box>
      <Stack
        sx={{
          backgroundColor: theme.palette.custom.neutral.white.pure,
          position: 'fixed',
          bottom: 0,
          width: '100%',
          padding: '1rem',
        }}
      >
        <Button
          variant="primary"
          fullWidth={true}
          {...{
            ...(currentPosition === BLE_INSTRUCTION_LENGTH && {
              sx: {
                position: 'relative',
                '&:before': {
                  position: 'absolute',
                  content: '""',
                  width: '103%',
                  height: '123%',
                  border: `.0625rem ${theme.palette.custom.primary[100]} solid`,
                  animation: 'blinkBorder 1.1s infinite ',
                  borderRadius: 'inherit',
                },
                '&:after': {
                  position: 'absolute',
                  content: '""',
                  width: '106%',
                  height: '146%',
                  border: ` .0625rem ${theme.palette.custom.primary[100]} solid`,
                  animation: 'blinkBorder2 1.2s infinite ',
                  borderRadius: 'inherit',
                },

                '@keyframes blinkBorder': {
                  '0%': { border: 'none ', padding: '0', opacity: 0 },
                  '100%': {
                    border: `.0625rem solid ${theme.palette.custom.primary[100]}`,
                    opacity: 1,
                  },
                },
                '@keyframes blinkBorder2': {
                  '0%': { border: 'none ', padding: '0', opacity: 0 },
                  '100%': {
                    border: `.0625rem solid ${theme.palette.custom.primary[100]}`,
                    opacity: 1,
                  },
                },
              },
            }),
          }}
          onClick={handleClickScan}
        >
          {t('anonymous.mobile.cta.btnScanDevice')}
        </Button>
      </Stack>
    </Box>
  );
};

export default BLEModal;
