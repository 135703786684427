import React from 'react';

import { CopyIcon } from '@livingpackets/design-system-react-next';
import { useTranslation } from 'react-i18next';
import useClipboard from 'react-use-clipboard';
import styled, { useTheme } from 'styled-components';

interface ICopyToClipboard {
  data: string;
}

const Wrapper = styled.button`
  cursor: pointer;
  background: none;
  border: none;
  font: inherit;
  display: flex;
`;

const CopyToClipboard = ({ data }: ICopyToClipboard) => {
  const { t } = useTranslation('general');
  const theme = useTheme();
  const [isCopied, setCopied] = useClipboard(data, {
    successDuration: 1000,
  });

  return (
    <Wrapper aria-label={t('copyToClipboard')} onClick={setCopied}>
      <CopyIcon
        color={isCopied ? theme.colors.black[30] : theme.colors.primary[100]}
        style={{ transition: '500ms' }}
        size="19px"
      />
    </Wrapper>
  );
};

export default CopyToClipboard;
