export interface Co2Savings {
  co2_savings: {
    global_savings: number;
  };
}

export const INITIAL_CO2Savings: Co2Savings = {
  co2_savings: {
    global_savings: 0,
  },
};
