import { useCallback } from 'react';

import { useIntlError } from 'hooks/useError';
import useImperativeRequest from 'hooks/useImperativeRequest';

const useAcceptTos = () => {
  const [{ loading }, makeRequest] = useImperativeRequest('tos');
  const addError = useIntlError();

  const acceptTos = useCallback(
    async (id: string) => {
      const { error } = await makeRequest({
        path: `api/v1/tos/${id}/accept`,
        method: 'post',
      });
      if (error) {
        addError(error, {
          addToast: true,
          toastMessage: 'messages:somethingWentWrong.message',
          toastSettings: {
            wide: true,
          },
        });

        return { success: false };
      }

      return { success: true };
    },
    [addError, makeRequest]
  );

  return { acceptTos, loading };
};

export default useAcceptTos;
