import React from 'react';

import {
  ButtonV2,
  Chip,
  EditIcon,
  Paper,
  Stack,
  Text,
} from '@livingpackets/design-system-react-next';
import { useCountryCodes } from '@livingpackets/shared-components';
import { normalizeKey } from 'helpers/i18n';
import { useTranslation } from 'react-i18next';

const ReadOnlyAddress = ({ showForm, isSender, values }: any) => {
  const { t, i18n } = useTranslation('shipments');

  const [, getCountryNameFromCode] = useCountryCodes({
    includeTranslations: true,
    language: i18n.language,
  });

  return (
    <Paper sx={{ backgroundColor: 'custom.neutral.black.2' }}>
      <Stack>
        <Stack direction="row" justifyContent="space-between">
          <Chip
            label={t(
              normalizeKey(
                `form.${isSender ? 'sender' : 'recipient'}.defaultChip`
              )
            )}
          />
          <ButtonV2 icon={EditIcon} size="small" onClick={showForm} />
        </Stack>

        <Stack>
          <Text variant="titleXS">
            {values.firstName} {values.lastName}
          </Text>
          <Text variant="bodyTextM">{values.street}</Text>
          <Text variant="bodyTextM">
            {values.postalCode} {values.city}
          </Text>
          <Text variant="bodyTextM">
            {getCountryNameFromCode(values.countryCode)}
          </Text>
        </Stack>
      </Stack>
    </Paper>
  );
};

export default ReadOnlyAddress;
