import React from 'react';

import { Box } from '@livingpackets/design-system-react-next';
import Lottie from 'react-lottie';

export const DeployReturnTab = ({ ressource }: { ressource: any }) => (
  <Box
    sx={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }}
  >
    <Box
      sx={{
        position: 'relative',
        paddingTop: '.625rem',
        paddingBottom: '2.5rem',
        overflow: 'visible',
      }}
    >
      {/* Lottie */}
      <Lottie
        options={{
          loop: true,
          autoplay: true,
          animationData: ressource,

          rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
          },
        }}
      />
    </Box>
  </Box>
);
