import React from 'react';

import { Text } from '@livingpackets/design-system-react-next';
import { useTranslation } from 'react-i18next';

import ProfileInformation from '../../organisms/ProfileInformation';

const LPAccountInformation = () => {
  const { t } = useTranslation('accountInformation');

  return (
    <>
      <div style={{ width: '100%', marginBottom: '2rem' }}>
        <Text variant="titleXL">{t('title')}</Text>
      </div>
      <ProfileInformation />
    </>
  );
};

export default LPAccountInformation;
