import React from 'react';

import {
  AddIcon,
  Box,
  ButtonV2 as Button,
  Checkbox,
  Chip,
  Grid,
  Stack,
  Text,
  Theme,
  useTheme,
} from '@livingpackets/design-system-react-next';
import { useCountryCodes } from '@livingpackets/shared-components';
import NameCard from 'components/atoms/NameCard';
import { useUpdatePartnership } from 'features/account';
import {
  useContacts,
  useCreateAddress,
  useCreateContact,
} from 'features/addressBook';
import { normalizeKey } from 'helpers/i18n';
import useToastMessages from 'hooks/useToastMessages';
import { ContactAddressType } from 'models/contactAddress';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

const ContactItem = ({
  getCountryNameFromCode,
  contact,
  theme,
}: {
  getCountryNameFromCode: Function;
  contact: any;
  theme: Theme;
}) => (
  <Stack
    direction="row"
    alignItems="center"
    gap=".75rem"
    bgcolor="custom.neutral.white.pure"
    p="14px 10px"
    sx={{
      border: `1px solid ${theme.palette.custom.neutral.black[10]}`,
      borderRadius: '10px',
    }}
  >
    <NameCard
      firstName={contact.firstName}
      lastName={contact.lastName}
      email={contact.email}
    />
    <Grid container direction="row" alignItems="center" spacing="1rem">
      <Grid item mobile={3}>
        <Text variant="titleXS" sx={{ wordBreak: 'break-all' }}>
          {contact.firstName}
        </Text>
      </Grid>
      <Grid item mobile={3}>
        <Text variant="titleXS" sx={{ wordBreak: 'break-all' }}>
          {contact.lastName}
        </Text>
      </Grid>
      <Grid item mobile={3}>
        <Text variant="bodyTextXS" sx={{ wordBreak: 'break-all' }}>
          {contact.email}
        </Text>
      </Grid>
      <Grid item mobile={3}>
        <Stack>
          <Text variant="bodyTextXS" sx={{ wordBreak: 'break-all' }}>
            {contact.addresses[0].street},
          </Text>
          <Text variant="bodyTextXS" sx={{ wordBreak: 'break-all' }}>
            {contact.addresses[0].postalCode} {contact.addresses[0].city}
            {', '}
            {getCountryNameFromCode(contact.addresses[0].countryCode)}
          </Text>
        </Stack>
      </Grid>
    </Grid>
  </Stack>
);

type Props = {
  open: boolean;
  setOpen: (value: boolean) => void;
  isSender: boolean;
  contact: any;
  onSave: (isChecked: boolean) => void;
  onDismiss: () => void;
};

const SaveThisAddressModal = ({
  open,
  setOpen,
  isSender,
  contact,
  onSave,
  onDismiss,
}: Props) => {
  const theme = useTheme();

  const { t, i18n } = useTranslation(['general', 'shipments']);
  const { partnerId } = useParams();

  const [, getCountryNameFromCode] = useCountryCodes({
    includeTranslations: true,
    language: i18n.language,
  });

  const type = isSender ? 'sender' : 'recipient';

  const { error: toastError, success: toastSuccess } = useToastMessages();

  // Queries
  const { data } = useContacts({
    config: { enabled: open },
    search: contact.email,
    strictEqual: true,
  });

  // Mutations
  const { mutateAsync: createContact, isPending: createContactInProgress } =
    useCreateContact();
  const { mutateAsync: createAddress, isPending: createAddressInProgress } =
    useCreateAddress();
  const {
    mutateAsync: updatePartnership,
    isPending: updatePartnershipInProgress,
  } = useUpdatePartnership({
    showSuccessToast: false,
  });

  const [isChecked, setIsChecked] = React.useState(false);

  const explanation1 = () => {
    let text;

    if (!data || data.count === 0) {
      text = t(
        normalizeKey(
          `shipments:form.modal.descriptionNoContact${
            contact.type === ContactAddressType.professional
              ? 'Professional'
              : 'Personal'
          }`
        )
      );
    } else {
      text = t(normalizeKey(`shipments:form.modal.descriptionExistingContact`));
    }

    return (
      <Grid item mobile={12}>
        <Text variant="bodyTextL" color="custom.neutral.black.100">
          {text}
        </Text>
      </Grid>
    );
  };

  const explanation2 = () => {
    if (!data || data.count === 0) return null;

    // Make one object per address
    const formattedContacts: any = [];

    data.items.forEach(contact => {
      contact.addresses.forEach(address => {
        formattedContacts.push({
          ...contact,
          addresses: [address],
        });
      });
    });

    return (
      <Grid item mobile={12}>
        <Stack p="14px" bgcolor="custom.neutral.black.2" gap="10px">
          {formattedContacts.slice(0, 3).map((contact: any) => (
            <ContactItem
              getCountryNameFromCode={getCountryNameFromCode}
              key={contact.id}
              contact={contact}
              theme={theme}
            />
          ))}
          {formattedContacts.length > 3 && (
            <Box width="fit-content" alignSelf="center">
              <Chip
                label={t('shipments:form.modal.moreExistingContact', {
                  count: formattedContacts.length - 3,
                })}
                state="idle"
                type="tags"
              />
            </Box>
          )}
        </Stack>
      </Grid>
    );
  };

  /**
   * 1. Create the contact
   * 2. Create the address
   * 3. Update the partnership with default sender/recipient (if checked)
   */
  const handleSave = async () => {
    // POST new address
    try {
      // Create contact
      const newContact = await createContact({
        firstName: contact.firstName,
        lastName: contact.lastName,
        email: contact.email,
        phoneNumber: contact.phoneNumber,
      });

      // Create address
      const newAddress = await createAddress({
        contactId: newContact.id,
        values: {
          type: contact.type,
          city: contact.city,
          company: contact.company,
          countryCode: contact.countryCode,
          street: contact.street,
          postalCode: contact.postalCode,
          firstName: contact.firstName,
          lastName: contact.lastName,
          email: contact.email,
          phoneNumber: contact.phoneNumber,
        },
      });

      // POST new contact
      if (isChecked) {
        let patchedData = {};
        if (isSender) {
          patchedData = { default_sender_address_id: newAddress.id };
        } else {
          patchedData = { default_recipient_address_id: newAddress.id };
        }

        await updatePartnership({
          values: patchedData,
          partnershipId: partnerId!,
        });
      }

      toastSuccess('messages:contactCreateSuccess.message');
      setOpen(false);
      onSave(isChecked);
    } catch (error) {
      setOpen(false);
      onDismiss();
      toastError('messages:contactCreateError.message');
    }
  };

  return (
    <Grid container direction="column" gap="2rem">
      <Grid item mobile={12}>
        <Grid container direction="column" gap=".25rem">
          <Text variant="titleM">
            {t(normalizeKey(`shipments:form.modal.title`))}
          </Text>
        </Grid>
      </Grid>
      {explanation1()}
      <Grid item mobile={12}>
        <Stack>
          <Checkbox
            label={t(
              normalizeKey(`shipments:form.${type}.modal.saveAsDefault`)
            )}
            checked={isChecked}
            onChange={event => setIsChecked(event.target.checked)}
          />
          <Text variant="bodyTextM" color="custom.neutral.black.50">
            {t(
              normalizeKey(
                `shipments:form.${type}.modal.saveAsDefaultDescription`
              )
            )}
          </Text>
        </Stack>
      </Grid>
      {explanation2()}

      <Grid container justifyContent="space-between" gap="1rem">
        <Button
          variant="secondary"
          onClick={() => {
            setOpen(false);
            onDismiss();
          }}
        >
          {t('general:Cancel')}
        </Button>
        <Button
          data-testid="save-address-confirm-button"
          disabled={
            createContactInProgress ||
            createAddressInProgress ||
            updatePartnershipInProgress
          }
          variant="primary"
          icon={AddIcon}
          onClick={handleSave}
        >
          {t('general:save')}
        </Button>
      </Grid>
    </Grid>
  );
};

export default SaveThisAddressModal;
