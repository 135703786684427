import React, { useCallback, useMemo } from 'react';

import Dropdown from 'components/atoms/Dropdown';
import Input from 'components/atoms/Input';
import useCountryCodesWrapper from 'hooks/useCountryCodeWrapper';
import get from 'lodash/get';
import { Control, RegisterOptions, useController } from 'react-hook-form';
import { UseFormRegister } from 'react-hook-form/dist/types/form';
import { useTranslation } from 'react-i18next';
import { LayoutProps } from 'styled-system';

interface ICountrySelector extends LayoutProps {
  id?: string;
  control: Control;
  rules?: RegisterOptions;
  width?: string;
  prefix?: string;
  isDisabled?: boolean;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  fieldName?: string;
  register?: UseFormRegister<any>;
}

const CountrySelector = ({
  id,
  control,
  rules,
  prefix,
  width = '17.5rem',
  isDisabled = false,
  onChange,
  fieldName,
  register,
}: ICountrySelector) => {
  const name = useMemo(() => {
    const baseName = fieldName ? fieldName : 'countryCode';

    if (prefix) {
      return `${prefix}${baseName}`;
    }

    return baseName;
  }, [prefix, fieldName]);

  const { field, fieldState } = useController({
    control,
    name,
    rules,
    defaultValue: '',
  });

  const handleOnChange = useCallback(
    (option: any) => {
      field.onChange(option.value);
      field.onBlur();

      if (onChange) {
        onChange(option);
      }
    },
    [field, onChange]
  );

  const {
    t,
    i18n: { language },
  } = useTranslation('address');
  const [countryCodes, getCountryNameFromCode] = useCountryCodesWrapper();

  const formattedValue = useMemo(
    () => ({
      label: getCountryNameFromCode(field.value || ''),
      value: field.value || '',
    }),
    [getCountryNameFromCode, field.value]
  );

  const getOptionLabel = useCallback(
    (option: any) =>
      language === 'en'
        ? option['name']
        : get(option, `translations.${language}`, option['name']),
    [language]
  );

  const sortedCountries = useMemo(() => {
    if (countryCodes?.data?.length > 0) {
      switch (language) {
        case 'en':
          return countryCodes.data.map((c: { alpha2Code: any; name: any }) => ({
            value: c.alpha2Code,
            label: c.name,
          }));
        case 'de':
          return countryCodes.data
            .slice()
            .sort((a: any, b: any) =>
              get(a, 'translations.de', 'name').localeCompare(
                get(b, 'translations.de', 'name'),
                'de',
                { ignorePunctuation: true }
              )
            )
            .map((c: any) => ({
              value: c.alpha2Code,
              label: getOptionLabel(c),
            }));
        case 'fr':
          return countryCodes.data
            .slice()
            .sort((a: any, b: any) =>
              get(a, 'translations.fr', 'name').localeCompare(
                get(b, 'translations.fr', 'name'),
                'fr',
                { ignorePunctuation: true }
              )
            )
            .map((c: any) => ({
              value: c.alpha2Code,
              label: getOptionLabel(c),
            }));
        default:
          return countryCodes.data.map((c: { alpha2Code: any; name: any }) => ({
            value: c.alpha2Code,
            label: c.name,
          }));
      }
    }

    return [];
  }, [countryCodes, language, getOptionLabel]);

  if (isDisabled) {
    return (
      <Input
        id={id}
        name="country"
        label={t('country')}
        width={width}
        value={formattedValue.label}
        disabled={true}
        register={register}
      />
    );
  }

  return (
    <Dropdown
      id={id}
      options={sortedCountries}
      label={t('country')}
      value={formattedValue}
      onChange={handleOnChange}
      name={name}
      width={width}
      isSearchable={true}
      error={fieldState.error}
    />
  );
};

export default CountrySelector;
