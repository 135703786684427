import React from 'react';

import { Text, styled } from '@livingpackets/design-system-react-next';
import { layout, LayoutProps, space, SpaceProps } from 'styled-system';

interface INameCard extends SpaceProps, LayoutProps {
  firstName: string;
  lastName: string;
  email: string;
}

const Badge = styled('div')<SpaceProps & LayoutProps>`
  background: ${({ theme }) => theme.palette.custom.neutral.black[100]};
  height: 2rem;
  width: 2rem;
  min-width: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.438rem;
  color: ${({ theme }) => theme.palette.custom.neutral.white.pure};
  text-transform: uppercase;
  ${space}
  ${layout}
`;

const getCopy = ({
  firstName,
  lastName,
  email,
}: {
  firstName: string;
  lastName: string;
  email: string;
}) => {
  if (firstName && lastName) {
    return firstName[0] + lastName[0];
  }
  if (email.length > 1) {
    return email[0] + email[1];
  }

  return '';
};

const NameCard = ({ firstName, lastName, email, ...rest }: INameCard) => (
  <Badge {...rest} data-testid="name-badge">
    <Text variant="titleS" color="custom.neutral.white.pure">
      {getCopy({ firstName, lastName, email })}
    </Text>
  </Badge>
);

export default NameCard;
