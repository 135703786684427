import React, { useCallback } from 'react';

import { Text, Theme } from '@livingpackets/design-system-react-next';
import { useTranslation } from 'react-i18next';

interface IBatteryInfoIcon {
  batteryLevel: number | undefined;
  variant?: keyof Theme['fontStyles'] | undefined;
}

const BatteryInfoText = ({
  batteryLevel,
  variant = 'bodyTextS',
}: IBatteryInfoIcon) => {
  const { t } = useTranslation('products');

  const getBatteryLevelText = useCallback(
    (batteryLevel: number | undefined) => {
      if (!batteryLevel) {
        return;
      } else {
        const batteryLevelText = t('common.batteryLevel', {
          batteryLevel: batteryLevel,
        });

        if (batteryLevel <= 33) {
          return (
            <Text variant={variant} color="red.100">
              {batteryLevelText}
            </Text>
          );
        } else if (batteryLevel <= 66) {
          return (
            <Text variant={variant} color="custom.neutral.black.100">
              {batteryLevelText}
            </Text>
          );
        } else if (batteryLevel > 66) {
          return (
            <Text variant={variant} color="primary.100">
              {batteryLevelText}
            </Text>
          );
        }
      }
    },
    [t, variant]
  );

  return <>{getBatteryLevelText(batteryLevel)}</>;
};

export default BatteryInfoText;
