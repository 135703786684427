interface IAuth {
  domain: string;
  redirectUri: string;
  clientId: string;
  audience: string;
  scope: string;
}

export const auth: IAuth = {
  domain: process.env.REACT_APP_AUTH_DOMAIN!,
  redirectUri: process.env.REACT_APP_AUTH_CALLBACK_URL!,
  clientId: process.env.REACT_APP_AUTH_CLIENT_ID!,
  audience: process.env.REACT_APP_AUTH_AUDIENCE!,
  scope:
    'openid profile email create:checkout create:myorders read:me read:myorders update:me read:box-contracts',
};

export const AUTH0_EMAIL_VERIFICATION_ERROR_MESSAGES = {
  REUSED: 'This URL can be used only once',
  EXPIRED: 'Access expired.',
  INVALID: 'User account does not exist or verification code is invalid.',
  ALREADY_VERIFIED: 'This account is already verified.',
  SUCCESS: 'Your email was verified. You can continue using the application.',
};
