import i18next from 'i18next';
import parsePhoneNumber from 'libphonenumber-js';
import * as yup from 'yup';

export const firstName = yup.string();
export const lastName = yup.string();

export const email = ({ required = false } = {}) => {
  const schema = yup
    .string()
    .email(i18next.t('forms:email.error'))
    // (^$) empty string or email
    .matches(/^$|@[^.]*\./, i18next.t('forms:email.error'));

  if (required) {
    schema.required(i18next.t('forms:email.required'));
  }

  return schema;
};

/**
 * Phone Number validator for yup based on libphonenumber-js
 * Use the [E.164](https://en.wikipedia.org/wiki/E.164) standard
 */
export const phoneNumber = ({ required = false } = {}) => {
  const validator = yup
    .string()
    .test(
      'test-phone-number',
      i18next.t('profileSettings:phoneNumberFormatError'),
      function (value?: string) {
        if (!value) return true;

        try {
          const phoneNumber = parsePhoneNumber(value);

          if (!phoneNumber) return false;

          if (phoneNumber.format('E.164') !== value) return false;

          return phoneNumber.isValid();
        } catch (error) {
          return false;
        }
      }
    );

  if (required) {
    return validator.required(
      i18next.t('contactAddress:form.phoneNumber.validation.required')
    );
  }

  return validator;
};

export const language = yup.string();

export const countryCode = ({ isRequired = false } = {}) => {
  const schema = yup
    .string()
    .matches(/^[A-Z]{2}$/, i18next.t('forms:countryCode.error'));

  if (isRequired) {
    schema.required(i18next.t('forms:countryCode.required'));
  }

  return schema;
};
