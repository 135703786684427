export interface IBillingAddressForm {
  firstName: string;
  lastName: string;
  companyName?: string;
  city: string;
  address: string;
  zipCode: string;
  countryCode: string;
}

export const INITIAL_STATE_BILLING_ADDRESS_FORM: IBillingAddressForm = {
  firstName: '',
  lastName: '',
  companyName: '',
  city: '',
  address: '',
  zipCode: '',
  countryCode: '',
};
