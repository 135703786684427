import { useCallback, useEffect, useState } from 'react';

import useToastMessages from 'hooks/useToastMessages';
import { useParams } from 'react-router-dom';
import useMyPartnersStore, {
  IPartnerEntry,
  partnersSelector,
  setActivePartnerSelector,
} from 'stores/useMyPartnersStore';

const getPartner = (partnerId: string, partnerEntries: IPartnerEntry[]) =>
  partnerEntries.find(x => x.id === partnerId);

const usePartnerRouting = () => {
  const partners = useMyPartnersStore(partnersSelector);
  const setActivePartner = useMyPartnersStore(setActivePartnerSelector);
  const { partnerId } = useParams<{ partnerId: string }>();
  const [redirect, setRedirect] = useState(false);
  const { error } = useToastMessages();

  const onInvalid = useCallback(() => {
    error('messages:partnershipError.message', { persist: true });
    setRedirect(true);
  }, [error]);

  useEffect(() => {
    // if no partner id in route
    // or user has no roles on partners,
    // user should not have access
    if (!partnerId || !partners) {
      onInvalid();

      return;
    }

    const activePartner = getPartner(partnerId, partners);
    // if the partner id in the route is not one of the user's partners,
    // then user should also not have access
    if (!activePartner) {
      onInvalid();

      return;
    }
    setActivePartner(activePartner);
  }, [onInvalid, partnerId, partners, setActivePartner]);

  return redirect;
};

export default usePartnerRouting;
