import React, { useCallback, useEffect, useState } from 'react';

import {
  PrivateAccountIcon,
  ProfessionalIcon,
  useTheme,
  Box,
  Text,
  Stack,
} from '@livingpackets/design-system-react-next';
import AccountTypeChoiceContainer from 'components/atoms/AccountTypeChoiceContainer';
import {
  AuthenticationView,
  DescriptionTextFontStyleEnum,
} from 'components/containers/AuthenticationView';
import { PATHS } from 'configs';
import { AccountTypeDisplayMode } from 'enums/accountTypeDisplayMode';
import { AccountTypeEnum } from 'enums/accountTypeEnum';
import { UserBusinessTypeEnum } from 'enums/UserBusinessTypeEnum';
import useCustomer from 'hooks/useCustomer';
import { PaginatedResponse, useGetPartnerList } from 'hooks/useGetPartnerList';
import useLpVillageService from 'hooks/useLpVillageService';
import { AccountTypeInformation } from 'models/accountTypeInformation';
import { IUser } from 'models/user';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import useAppState, {
  dispatch,
  userSelector,
} from 'stores/appState/useAppState';
import useMyPartnersStore, {
  setActivePartnerSelector,
  setPartnersSelector,
} from 'stores/useMyPartnersStore';

const AccountTypeChoice = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { t } = useTranslation('authentication');

  const appUser = useAppState(userSelector);
  const setActivePartner = useMyPartnersStore(setActivePartnerSelector);
  const setPartners = useMyPartnersStore(setPartnersSelector);
  const { getBusinessTypeFromAccountType } = useCustomer();

  const { selectPartnerType } = useLpVillageService();
  const getPartnerPartners = useGetPartnerList();

  const [accountTypeList, setAccountTypeList] = useState<
    AccountTypeInformation[]
  >([]);

  const [isDisabledUserRedirect, setIsDisabledUserRedirect] =
    useState<boolean>(false);

  const redirectToAccountSuccessCreationPage = useCallback(
    (accountType: AccountTypeEnum) => {
      setIsDisabledUserRedirect(true);

      if (accountType === AccountTypeEnum.PERSONAL_ACCOUNT) {
        selectPartnerType(getBusinessTypeFromAccountType(accountType)).then(
          ({ success, data }) => {
            getPartnerPartners({
              orderColumn: 'createdAt',
              orderDirection: 'ASC',
            }).then((response: PaginatedResponse<any>) => {
              if (response.total !== 0) {
                setPartners(response.items);
                setActivePartner(response.items[0]);
              }

              dispatch({
                type: 'SET_USER',
                payload: {
                  ...appUser,
                  ...{
                    businessType: UserBusinessTypeEnum.PERSONAL,
                  },
                } as IUser,
              });

              navigate(PATHS.AUTHENTICATION.ACCOUNT_CREATED_SUCCESS, {
                state: { accountType: accountType },
              });
            });
          }
        );
      } else {
        navigate(PATHS.AUTHENTICATION.ADD_PARTNERSHIP_INFORMATION, {
          state: { accountType: accountType },
        });
      }
    },
    [
      selectPartnerType,
      getBusinessTypeFromAccountType,
      getPartnerPartners,
      appUser,
      navigate,
      setPartners,
      setActivePartner,
    ]
  );

  useEffect(() => {
    if (!accountTypeList.length) {
      setAccountTypeList([
        {
          displayMode: AccountTypeDisplayMode.LIGHT,
          icon: (
            <PrivateAccountIcon color={theme.palette.custom.primary[100]} />
          ),
          title: 'authentication:accountTypeChoice:personalAccount:title',
          description:
            'authentication:accountTypeChoice:personalAccount:description',
          information: [
            {
              isActivated: true,
              text: 'authentication:accountTypeChoice:options:profitSharingExperience',
            },
            {
              isActivated: true,
              text: 'authentication:accountTypeChoice:options:shippingManagement',
            },
            {
              isActivated: true,
              text: 'authentication:accountTypeChoice:options:carrierManagement',
            },
            {
              isActivated: true,
              text: 'authentication:accountTypeChoice:options:customerCare',
            },
            {
              isActivated: false,
              text: 'authentication:accountTypeChoice:options:fleetManagement',
            },
            {
              isActivated: false,
              text: 'authentication:accountTypeChoice:options:privateFleetExperience',
            },
          ],
          accountType: AccountTypeEnum.PERSONAL_ACCOUNT,
          buttonText:
            'authentication:accountTypeChoice:personalAccount:buttonText',
          buttonTestId: 'personal-account-choose-button',
        },
        {
          displayMode: AccountTypeDisplayMode.DARK,
          icon: <ProfessionalIcon color={theme.colors.primary[100]} />,
          title: 'authentication:accountTypeChoice:businessAccount:title',
          description:
            'authentication:accountTypeChoice:businessAccount:description',
          information: [
            {
              isActivated: true,
              text: 'authentication:accountTypeChoice:options:businessSettings',
            },
            {
              isActivated: true,
              text: 'authentication:accountTypeChoice:options:shippingManagement',
            },
            {
              isActivated: true,
              text: 'authentication:accountTypeChoice:options:carrierManagement',
            },
            {
              isActivated: true,
              text: 'authentication:accountTypeChoice:options:dedicatedCustomerTeam',
            },
            {
              isActivated: true,
              text: 'authentication:accountTypeChoice:options:fleetManagement',
            },
            {
              isActivated: true,
              text: 'authentication:accountTypeChoice:options:privateFleetExperience',
              helpText:
                'authentication:accountTypeChoice:options:privateFleetExperienceHelpText',
            },
          ],
          accountType: AccountTypeEnum.BUSINESS_ACCOUNT,
          buttonText:
            'authentication:accountTypeChoice:businessAccount:buttonText',
          buttonTestId: 'business-account-choose-button',
        },
      ]);
    }
  }, [accountTypeList, t, theme]);

  useEffect(() => {
    if (
      appUser &&
      appUser.businessType !== UserBusinessTypeEnum.UNKNOWN &&
      !isDisabledUserRedirect
    ) {
      navigate(PATHS.ACCOUNT_ROOT);
    }
  }, [appUser, navigate, isDisabledUserRedirect]);

  return (
    <AuthenticationView
      title={t('accountTypeChoice.title')}
      description={t('accountTypeChoice.description')}
      descriptionFontStyle={DescriptionTextFontStyleEnum.REGULAR}
    >
      <Stack paddingTop="17px">
        <Stack direction="row" marginLeft="32px" marginRight="32px">
          {accountTypeList.map(
            (accountType: AccountTypeInformation, index: number) => (
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  marginLeft:
                    accountType.accountType === AccountTypeEnum.BUSINESS_ACCOUNT
                      ? '16px'
                      : '0px',
                  marginRight:
                    accountType.accountType === AccountTypeEnum.PERSONAL_ACCOUNT
                      ? '16px'
                      : '0px',
                }}
                key={accountType.accountType}
              >
                <Stack>
                  <AccountTypeChoiceContainer
                    accountTypeInformation={accountType}
                    onAccountTypeChoose={(accountType: AccountTypeEnum) =>
                      redirectToAccountSuccessCreationPage(accountType)
                    }
                  />
                  {accountType.accountType ===
                    AccountTypeEnum.BUSINESS_ACCOUNT && (
                    <Box display="flex" justifyContent="center">
                      <Text
                        variant="titleS"
                        color="custom.primary.100"
                        sx={{
                          marginTop: '16px',
                          cursor: 'pointer',
                          textDecoration: 'underline',
                          textDecorationColor:
                            theme.palette.custom.primary[100],
                        }}
                        onClick={() =>
                          redirectToAccountSuccessCreationPage(
                            AccountTypeEnum.TRIAL_ACCOUNT
                          )
                        }
                      >
                        {t('accountTypeChoice.trialAccount.buttonText')}
                      </Text>
                    </Box>
                  )}
                </Stack>
              </div>
            )
          )}
        </Stack>
      </Stack>
    </AuthenticationView>
  );
};

export default AccountTypeChoice;
