import React from 'react';

import { Box, Text } from '@livingpackets/design-system-react-next';

const RemoteControlLoader = ({
  title,
  message,
}: {
  title: string;
  message: string;
}) => (
  <Box
    display="flex"
    flexDirection="column"
    justifyContent="center"
    alignItems="center"
    marginBottom="1.5rem"
    width="100%"
  >
    <Box display="flex" style={{ gap: '.62rem' }}>
      <Text variant="titleL" sx={{ paddingBottom: '.625rem' }}>
        {title}
      </Text>
    </Box>

    <Text variant="bodyTextM" color="primary" sx={{ margin: '0 15%' }}>
      {message}
    </Text>
  </Box>
);

export default RemoteControlLoader;
