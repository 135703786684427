import get from 'lodash/get';
import styled, { ThemeProps } from 'styled-components';
import { css, keyframes } from 'styled-components';

const defaults = {
  colors: {
    darkbrand: '#25b676',
  },
};

const scaleOutKS = keyframes`
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1.0);
    opacity: 0;
  }
`;

const scaleOutAnimation = css`
  animation: ${scaleOutKS} 1s infinite ease-in-out;
`;

const ScaleOut = styled.div<ThemeProps<any>>`
  width: 4rem;
  height: 4rem;
  margin: 0 auto;
  background-color: ${({ theme }) =>
    get(theme, 'colors.darkbrand', defaults.colors.darkbrand)};
  border-radius: 100%;
  ${scaleOutAnimation};
`;

export default ScaleOut;
