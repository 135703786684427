//REMOVE AFTER UPDATE @livingpackets/design-system-react-next
import React from 'react';

import { Box, useTheme } from '@livingpackets/design-system-react-next';

type PhoneCode = `+${string}`;
export interface CountryDataProps {
  translations: {
    fr: string;
    de: string;
  };
  name: string;
  phoneCode: PhoneCode;
  alpha2Code: string;
}

export const CountryCircle = ({
  disabled,
  country,
  size,
  style,
}: {
  disabled?: boolean;
  country: Pick<CountryDataProps, 'alpha2Code' | 'name'>;
  size?: string;
  style?: React.CSSProperties;
}) => {
  const theme = useTheme();

  const getCountryFlagFromAlpha2Code = (alpha2Code: string) =>
    `https://hatscripts.github.io/circle-flags/flags/${alpha2Code.toLowerCase()}.svg`;

  return (
    <Box display="flex">
      <img
        // Keep px !
        width={size || '20px'}
        height={size || '20px'}
        style={{
          margin: '0.1875rem',
          borderRadius: '0.625rem',
          border: '1px solid',
          borderColor: theme.palette.custom.neutral.black[20],
          maxWidth: 'none',
          opacity: disabled ? 0.5 : 1,
          ...style,
        }}
        loading="lazy"
        src={getCountryFlagFromAlpha2Code(country.alpha2Code)}
        alt={`country flag for ${country.name}`}
      />
    </Box>
  );
};
