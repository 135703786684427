export interface IBox {
  id: string;
  lpUI: string;
  articleDescription: string;
  alias: string;
  firmwareVersion: string;
  batteryLevel: number;
  lockState: boolean;
  closingState: string;
  numberOfAlerts: number;
  lastKnowPosition: {
    lat: number;
    lon: number;
    timestamp: number;
  };
  inShipment: boolean;
  inShipmentSince: number;
  lastShipmentId: string;
  contractState: string;
  lastContractStateChange: number;
  holderName: string;
  partnershipNumberOfShipments: number;
  partnershipSince: number;
  screenDisplayed: string;
  createdAt: number;
  updatedAt: number;
}

export interface IBoxesSearchForm {
  searchTerm: string;
  selectedShop: string;
}

export const INITIAL_STATE_BOXES_SEARCH_FORM: IBoxesSearchForm = {
  searchTerm: '',
  selectedShop: '',
};
