import { ShipmentContractStateEnum } from 'models/shipment';

/**
 * @param shipment `any` type because to much types of shipment
 * @returns {boolean} true if shipment is in cancelled state
 */
const isCancelledState = (shipment: any) =>
  (shipment?.contract_state || shipment?.contractState) ===
  ShipmentContractStateEnum.CANCELLED;

export default isCancelledState;
