// TODO : Faire un enum

export const COVER_STATE: string[] = [
  'Error',
  'Closed 32l left',
  'Closed 32l right',
  'Closed 32l full',
  'Closed 2l left',
  'Closed 2l right',
  'Closed 2l full',
  'Opened',
  'Unknown',
];

export const LOCK_STATE: string[] = [
  'Error',
  'Locked',
  'Unlocked',
  'Undefined',
  'Unknown',
];
