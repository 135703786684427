import React from 'react';

import {
  TooltipIcon,
  Text,
  useTheme,
} from '@livingpackets/design-system-react-next';
import Tooltip from 'components/atoms/Tooltip';
import { Trans, useTranslation } from 'react-i18next';

interface IWeightContent {
  title: string;
  content: string;
}

const WeightContent = ({ title, content }: IWeightContent) => {
  const { t } = useTranslation('shipments');

  return (
    <>
      <Text variant="titleS">
        <Trans t={t}>{title}</Trans>
      </Text>
      <Text variant="bodyTextS" mt={1}>
        <Trans t={t}>{content}</Trans>
      </Text>
    </>
  );
};

const WeightTooltip = ({ children }: { children: React.ReactNode }) => {
  const theme = useTheme();

  return (
    <Tooltip
      width="300px"
      padding="1.5rem"
      cardOffset={{ left: 120 }}
      position="bottom"
      content={children}
    >
      <div>
        <TooltipIcon
          color={theme.palette.custom.neutral.black[50]}
          size="13px"
        />
      </div>
    </Tooltip>
  );
};

export const VerifiedWeightTooltip = () => (
  <WeightTooltip>
    <WeightContent
      title="weightTooltip.verifiedTitle"
      content="weightTooltip.verifiedContent"
    />
  </WeightTooltip>
);

export const ProvidedWeightTooltip = () => (
  <WeightTooltip>
    <WeightContent
      title="weightTooltip.providedTitle"
      content="weightTooltip.providedContent"
    />
  </WeightTooltip>
);
