import React, { useState } from 'react';

import {
  Box,
  Card,
  CardBody,
  CardHeader,
} from '@livingpackets/design-system-react';
import {
  ArrowLeftIcon,
  ButtonV2 as Button,
  Stack,
  Text,
  styled,
} from '@livingpackets/design-system-react-next';
import SmallTabNavigation from 'components/molecules/SmallTabNavigation';
import ImportShipmentFlow from 'components/organisms/ImportShipmentFlow';
import { PATHS } from 'configs';
import { ImportShipmentStepEnum } from 'enums/ImportShipmentEnum';
import { useTranslation } from 'react-i18next';
import { generatePath, Link } from 'react-router-dom';
import useMyPartnersStore, {
  activePartnerSelector,
} from 'stores/useMyPartnersStore';

const CardImportShipment = styled(Card)`
  width: 956px;

  @media (max-width: ${({ theme }) => theme.mediaBreakpoints.md}) {
    width: auto;
  }
`;

const ImportShipmentFlowContainer = styled(Box)`
  height: auto;
`;

const CardBodyImportShipment = styled(CardBody)`
  padding: 27px 24px 24px;
`;

const SmallTabNavigationContainer = styled(Box)`
  padding-bottom: 10px;
  padding-left: 32px;
`;

export interface DisabledTabsType {
  isCarrierTabDisabled: boolean;
  isLabelTabDisabled: boolean;
  isShipmentDetailsTabDisabled: boolean;
  isAddReturnTabDisabled: boolean;
  isInsuranceTabDisabled: boolean;
  isSummaryTabDisabled: boolean;
}

const ImportShipment = () => {
  const { t } = useTranslation('shipments');

  const activePartner = useMyPartnersStore(activePartnerSelector);

  const [currentImportShipmentStep, setCurrentImportShipmentStep] =
    useState<ImportShipmentStepEnum>(
      ImportShipmentStepEnum.CarrierSelectionStep
    );
  const [disabledTabs, setDisabledTabs] = useState({
    isCarrierTabDisabled: false,
    isLabelTabDisabled: true,
    isShipmentDetailsTabDisabled: true,
    isAddReturnTabDisabled: true,
    isInsuranceTabDisabled: true,
    isSummaryTabDisabled: true,
  });

  return (
    <CardImportShipment width="sidePanel">
      <React.Fragment key=".0">
        <CardHeader
          backgroundColor="black.100"
          color="white"
          padding={5}
          style={{ paddingTop: '16px', paddingBottom: '14px' }}
        >
          <Stack direction="row" alignItems="center" spacing=".75rem">
            <Link
              to={generatePath(PATHS.SHIPMENT_LABELS_ROOT, {
                partnerId: activePartner.id,
              })}
            >
              <Box>
                <Button
                  aria-label={t('backToOverview')}
                  variant="tertiary"
                  icon={ArrowLeftIcon}
                />
              </Box>
            </Link>
            <Text
              variant="bodyTextXS"
              ml=".5rem"
              color="custom.neutral.black.50"
            >
              {t('importShipment.breadcrumb')}
            </Text>
          </Stack>
          <Text variant="titleXL" pt="2rem">
            {t('importShipment.title')}
          </Text>
        </CardHeader>
        <CardBodyImportShipment>
          <SmallTabNavigationContainer>
            <SmallTabNavigation
              withSeparator
              activeKey={currentImportShipmentStep}
              setActiveKey={setCurrentImportShipmentStep}
              options={[
                {
                  key: ImportShipmentStepEnum.CarrierSelectionStep,
                  label: t('importLabel.breadcrumb.carrier'),
                  isDisabled: disabledTabs.isCarrierTabDisabled,
                },
                {
                  key: ImportShipmentStepEnum.LabelImportStep,
                  label: t('importLabel.breadcrumb.label'),
                  isDisabled: disabledTabs.isLabelTabDisabled,
                },
                {
                  key: ImportShipmentStepEnum.ShipmentDetailsStep,
                  label: t('importLabel.breadcrumb.shippingDetails'),
                  isDisabled: disabledTabs.isShipmentDetailsTabDisabled,
                },
                {
                  key: ImportShipmentStepEnum.ReturnShipmentCreationStep,
                  label: t('importLabel.breadcrumb.addReturn'),
                  isDisabled: disabledTabs.isAddReturnTabDisabled,
                },
                {
                  key: ImportShipmentStepEnum.InsuranceStep,
                  label: t('importLabel.breadcrumb.insurance'),
                  isDisabled: disabledTabs.isInsuranceTabDisabled,
                },
                {
                  key: ImportShipmentStepEnum.ShipmentSummaryStep,
                  label: t('importLabel.breadcrumb.summary'),
                  isDisabled: disabledTabs.isSummaryTabDisabled,
                },
              ]}
            />
          </SmallTabNavigationContainer>
          <ImportShipmentFlowContainer>
            <ImportShipmentFlow
              currentImportShipmentStep={currentImportShipmentStep}
              setCurrentImportShipmentStep={setCurrentImportShipmentStep}
              setDisabledTabs={setDisabledTabs}
            />
          </ImportShipmentFlowContainer>
        </CardBodyImportShipment>
      </React.Fragment>
    </CardImportShipment>
  );
};

export default ImportShipment;
