import { IBaseAddress } from 'models/address';

export interface IBoxContract {
  acceptedTosVersion: string;
  accountId: string;
  paymentReceivedAt: number;
  address: IBoxContractAddress;
  boxContractOffer: IBoxContractOffer;
  brokerId: string;
  contractLocation: string;
  createdAt: number;
  id: string;
  numOfBox: number;
  partnerId: string;
  paymentDate: number;
  paymentMethod: string;
  paymentProcessor: string;
  paymentStatus: string;
  paymentUniqueId: string;
  shopId: string;
  state: string;
  totalAmountInCents: number;
  updatedAt: number;
  versionHash: string;
}

interface IBoxContractOffer {
  activeAt: string;
  createdAt: string;
  createdBy: string;
  deactivatedAt: string;
  durationInMonth: 0;
  firstTrancheInEuro: number;
  id: string;
  immediateProvisionInEuro: number;
  label: string;
  offerType: string;
  totalPriceInEuro: number;
  totalProvisionInEuro: number;
  updatedAt: string;
}

interface IBoxContractAddress extends IBaseAddress {
  accountId: string;
  createdAt: number;
  creatorId: string;
  id: string;
  isArchived: true;
  isPrimary: true;
  position: {
    latitude: number;
    longitude: number;
  };
  types: string[];
  updatedAt: number;
  versionHash: string;
}

export interface IRent {
  available: string;
  available_at: string;
  contract_count: number;
  paid_out: string;
  product_count: number;
  shipment_count: number;
  activated_at: string;
  total: string;
}
export const INITIAL_RENT: IRent = {
  available: '',
  available_at: '',
  contract_count: 0,
  paid_out: '',
  product_count: 0,
  shipment_count: 0,
  activated_at: '',
  total: '',
};
