import React, { ReactNode } from 'react';

import {
  Text,
  styled,
  Stack,
  Box,
} from '@livingpackets/design-system-react-next';
import popupPair from 'assets/lotties/popup-pair.json';
import wakeUp from 'assets/lotties/WakeUp.json';
import { useTranslation } from 'react-i18next';
import Lottie from 'react-lottie';

const StepIndicator = styled('div')`
  background-color: #000;
  border-radius: 50%;
  text-align: center;
  color: #fff;
  height: 42px;
  width: 42px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

type Instruction = {
  title: string;
  description: string;
  visualRessource?: ReactNode | any;
};

const BLEInstruction = ({ stepPosition }: { stepPosition: number }) => {
  const { t } = useTranslation('shipments');
  const bleInstructionSteps: Instruction[] = [
    {
      title: t('anonymous.mobile.wakeup.title'),
      description: t('anonymous.mobile.wakeup.description'),
      visualRessource: wakeUp,
    },
    {
      title: t('anonymous.mobile.pairing.title'),
      description: t('anonymous.mobile.pairing.description'),
      visualRessource: popupPair,
    },
    {
      title: t('anonymous.mobile.ready.title'),
      description: t('anonymous.mobile.ready.description'),
    },
  ];

  return (
    <Stack height="100%" width="100%" alignItems="center">
      {bleInstructionSteps.map(
        (bleInstruction, index) =>
          stepPosition === index && (
            <Box key={`step-ble-${index}`}>
              {stepPosition < bleInstructionSteps.length - 1 ? (
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  gap=".5rem"
                >
                  <StepIndicator>
                    <Text variant="titleXL">{stepPosition + 1}</Text>
                  </StepIndicator>
                  <Text variant="titleXL"> {bleInstruction.title}</Text>
                </Box>
              ) : null}
              {bleInstruction.visualRessource ? (
                <Lottie
                  options={{
                    loop: true,
                    autoplay: true,
                    animationData: bleInstruction.visualRessource,
                    rendererSettings: {
                      preserveAspectRatio: 'xMidYMid slice',
                    },
                  }}
                  style={{
                    height: 'auto',
                    width: 'auto',
                  }}
                />
              ) : (
                <Stack
                  display="flex"
                  textAlign="center"
                  minHeight="14rem"
                  justifyContent="flex-end"
                  paddingBottom="1.5rem"
                >
                  <Text variant="titleXL"> {bleInstruction.title}</Text>
                </Stack>
              )}
              <Text
                variant="bodyTextL"
                sx={{
                  textAlign: 'center',
                  paddingLeft: '4.125rem',
                  paddingRight: '4.125rem',
                }}
              >
                {bleInstruction.description}
              </Text>
            </Box>
          )
      )}
    </Stack>
  );
};

export default BLEInstruction;
