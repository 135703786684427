import * as yup from 'yup';

import { countryCode, email, phoneNumber } from './common';

export const accountPartnershipInformation = () =>
  yup.object().shape({
    partner_id_card: yup.object().shape({
      country_code: countryCode({ isRequired: true }),
      vat_number: yup.string(),
      company_id: yup.string(),
      email: email(),
    }),
    phone_number: phoneNumber(),
    website: yup.string(),
  });
