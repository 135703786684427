import useImperativeAnonymousRequest from 'hooks/useImperativeAnonymousRequest';
export const usePostCreateCommand = () => {
  // Switch call V2 to V3
  const [, makeRequest] = useImperativeAnonymousRequest('shipment');

  const generateCommand = async (
    boxId: string,
    shipmentId: string,
    command: string
  ): Promise<ArrayBuffer | undefined> => {
    const { data } = await makeRequest({
      path: `api/v2/products/${boxId}/commands/${command}`,
      method: 'POST',
      body: { shipmentId },
      responseType: 'arraybuffer',
    });

    if (data) {
      return data;
    }
  };

  return { generateCommand };
};
