import React, { useCallback, useEffect, useMemo, useState } from 'react';

import {
  Box,
  ProblemIcon,
  SavedIcon,
  Text,
  styled,
} from '@livingpackets/design-system-react-next';
import Dots from 'components/atoms/DotsAnimated';
import { UseFormRegister } from 'react-hook-form/dist/types/form';
import { useTheme } from 'styled-components';
import { layout, LayoutProps, space, SpaceProps } from 'styled-system';

interface IInput
  extends SpaceProps,
    React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  value?: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  error?: any;
  onFocus?: React.EventHandler<any>;
  placeholder?: string;
  icon?: React.ReactNode;
  width?: string;
  height?: string;
  isTouched?: boolean;
  register?: UseFormRegister<any>;
  labelOnlyWithValue?: boolean;
  calcHeight?: boolean;
  displayErrorMessage?: boolean;
  displayChooseIcon?: boolean;
  prefix?: any;
  prefixSpacing?: string;
  name?: string;
  required?: boolean;
}

export const Wrapper = styled('div')<LayoutProps & SpaceProps>`
  display: flex;
  flex-direction: column;
  position: relative;
  ${layout}
  ${space}
`;

const LabelWrapper = styled('div')`
  position: absolute;
  padding-left: 0.8125rem;
  top: 0.4rem;
  pointer-events: none;
  z-index: 1;
`;

export const Label = ({ label }: Pick<IInput, 'label'>) =>
  label ? (
    <LabelWrapper>
      <Text variant="titleXS" color="custom.neutral.black.50">
        {label}
      </Text>
    </LabelWrapper>
  ) : (
    <></>
  );

const ErrorMessageWrapper = styled('div')`
  padding-left: 0.8125rem;
`;

export const ErrorMessage = ({ errorMessage }: { errorMessage: string }) => (
  <ErrorMessageWrapper>
    <Text
      color="red.100"
      variant="bodyTextXS"
      data-testid="fieldError"
      title={errorMessage}
      style={{ textAlign: 'left' }}
    >
      {errorMessage}
    </Text>
  </ErrorMessageWrapper>
);

export const Container = styled('div')`
  display: flex;
  align-items: center;
  position: relative;
`;

const StyledInputContainer = styled(Box)`
  display: flex;
  align-items: center;
  width: 100%;
`;

const StyledInput = styled('input')<IInput>`
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  padding-left: ${({ prefix, prefixSpacing }) =>
    prefix ? prefixSpacing || '50px' : '0.75rem'};
  padding-top: ${({ label }) => (label ? '1.1rem' : 0)};
  border-radius: 10px;
  font-size: 15px;
  outline: 0;
  box-sizing: border-box;
  border: solid 1px
    ${({ theme, error }) =>
      !error ? theme.colors.black[10] : theme.colors.red[100]};

  &:focus {
    border: solid 1px ${({ theme }) => theme.colors.primary[100]};
  }

  &::placeholder {
    color: ${({ theme }) => theme.colors.black[30]};
  }
`;

const IconWrapper = styled('div')<{
  label?: string;
  left: string;
  error: boolean;
}>`
  display: flex;
  width: fit-content;
  justify-content: flex-end;
  position: relative;
  bottom: ${({ error }) => (error ? '2.5rem' : '1.25rem')};
  left: ${({ left }) => `calc(${left} - 1.2rem)`};
`;

const chooseIcon = (
  focus: boolean,
  error: boolean,
  width: string,
  theme: any,
  label?: string,
  isTouched?: boolean,
  displayChooseIcon = true
) => {
  if (!displayChooseIcon) {
    return;
  }

  const icon = focus ? 'dots' : error ? 'error' : isTouched ? 'ok' : 'none';

  switch (icon) {
    case 'dots':
      return <Dots />;
    case 'error':
      return (
        <IconWrapper label={label} left={width} error={error}>
          <ProblemIcon color={theme.colors.red[100]} />
        </IconWrapper>
      );
    case 'ok':
      return (
        <IconWrapper label={label} left={width} error={error}>
          <SavedIcon color={theme.colors.primary[100]} />
        </IconWrapper>
      );
    case 'none':
      return;
  }
};

const Input = ({
  error = false,
  width = '17.5rem',
  height = '3.125rem',
  placeholder,
  onChange,
  value,
  label,
  isTouched,
  name,
  labelOnlyWithValue = false,
  calcHeight = true,
  displayErrorMessage = true,
  displayChooseIcon = true,
  prefix,
  prefixSpacing,
  register,
  required,
  ...rest
}: IInput) => {
  const theme = useTheme();

  const [noIcon, removeIcon] = useState<boolean>(false);
  const [focus, setFocus] = useState(false);
  const handleFocus = useCallback(() => {
    setFocus(true);
  }, []);

  const handleBlur = useCallback(() => {
    setFocus(false);
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (!focus && !error) {
        removeIcon(true);
      }
    }, 1500);
    removeIcon(false);

    return () => clearTimeout(timer);
  }, [focus, error]);

  const icon = useMemo(
    () =>
      chooseIcon(
        focus,
        error,
        width,
        theme,
        label,
        isTouched,
        displayChooseIcon
      ),
    [focus, error, width, theme, label, isTouched, displayChooseIcon]
  );

  const withLabel = useMemo(() => {
    if (labelOnlyWithValue && !value) {
      return undefined;
    }

    if (label) {
      return `${label}${required ? ' *' : ''}`;
    }

    return label;
  }, [label, labelOnlyWithValue, value, required]);

  return (
    <Wrapper
      width={width}
      height={calcHeight ? `calc(${height} + 1.25rem)` : height}
      {...rest}
    >
      <Container>
        <Label label={withLabel} />
        <StyledInputContainer>
          {prefix}
          {register && name && (
            <StyledInput
              width={width}
              height={height}
              placeholder={`${placeholder || ''}${required ? ' *' : ''}`}
              value={value}
              onFocus={handleFocus}
              label={withLabel}
              error={error}
              prefix={prefix}
              prefixSpacing={prefixSpacing}
              {...rest}
              {...register(name, {
                onChange: event => {
                  if (onChange) {
                    onChange(event);
                  }
                },
                onBlur: event => {
                  handleBlur();
                },
              })}
            />
          )}
        </StyledInputContainer>
      </Container>
      {error && !focus && displayErrorMessage && (
        <ErrorMessage errorMessage={error.message || 'Error message'} />
      )}
      {!noIcon && icon}
    </Wrapper>
  );
};

Input.displayName = 'Input';
export default Input;
