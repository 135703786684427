import { theme } from '@livingpackets/design-system-react';
import { Carriers, ENABLED_CARRIERS } from 'configs/carriers';

import chronopostTile from '../assets/chronopost-tile.png';
import chronopostLogo from '../assets/chronopost.png';
import colissimoTile from '../assets/colissimo-tile.png';
import colissimoLogo from '../assets/colissimo.png';
import dhlTile from '../assets/dhl-tile.png';
import dhlLogo from '../assets/dhl.png';
import fedexTile from '../assets/fedex-tile.png';
import fedexLogo from '../assets/fedex.png';
import miistTile from '../assets/miist-tile.png';
import miistLogo from '../assets/miist.png';
import otherCarrierLogoBig from '../assets/other-carrier-logo-big.png';
import otherCarrierLogo from '../assets/other-carrier-logo.png';
import tntTile from '../assets/tnt-tile.png';
import tntLogo from '../assets/tnt.png';
import upsTile from '../assets/ups-tile.png';
import upsLogo from '../assets/ups.png';

export interface CarrierInfo {
  logoPath: string;
  logo: string;
  code: Carriers;
  textColor: string;
}

// Used in shipments creation
const CARRIER_CONFIGURATIONS: Record<Carriers, CarrierInfo> = {
  [Carriers.ups]: {
    logoPath: `url(${upsTile})`,
    logo: upsLogo,
    code: Carriers.ups,
    textColor: theme.colors.white,
  },
  [Carriers.fedex]: {
    logoPath: `url(${fedexTile})`,
    logo: fedexLogo,
    code: Carriers.fedex,
    textColor: theme.colors.black[100],
  },
  [Carriers.tnt]: {
    logoPath: `url(${tntTile})`,
    logo: tntLogo,
    code: Carriers.tnt,
    textColor: theme.colors.white,
  },
  [Carriers.chronopost]: {
    logoPath: `url(${chronopostTile})`,
    logo: chronopostLogo,
    code: Carriers.chronopost,
    textColor: theme.colors.black[100],
  },
  [Carriers.colissimo]: {
    logoPath: `url(${colissimoTile})`,
    logo: colissimoLogo,
    code: Carriers.colissimo,
    textColor: theme.colors.white,
  },
  [Carriers.dhl]: {
    logoPath: `url(${dhlTile})`,
    logo: dhlLogo,
    code: Carriers.dhl,
    textColor: theme.colors.black[100],
  },
  [Carriers.miist]: {
    logoPath: `url(${miistTile})`,
    logo: miistLogo,
    code: Carriers.miist,
    textColor: theme.colors.white,
  },
  [Carriers.other]: {
    logoPath: `url(${otherCarrierLogoBig})`,
    logo: otherCarrierLogo,
    code: Carriers.other,
    textColor: theme.colors.white,
  },
};

const CARRIERS_LIST = ENABLED_CARRIERS.map(
  carrierCode => CARRIER_CONFIGURATIONS[carrierCode]
);

export default CARRIERS_LIST;
