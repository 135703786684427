import { useCallback } from 'react';

import { Carriers } from 'configs/carriers';
import useImperativeRequest from 'hooks/useImperativeRequest';
import useToastMessages from 'hooks/useToastMessages';
import { IFiguralCredentialsPost } from 'models/figuralCredentials';
import { IFiguralInsurance } from 'models/figuralInsurance';
import { IOcrLabel, IShipment } from 'models/shipment';
import useMyPartnersStore, {
  activePartnerSelector,
} from 'stores/useMyPartnersStore';

const useFiguralInsurance = () => {
  const [{ loading }, makeRequest] = useImperativeRequest('lpVillageV3');
  const { error: toastError } = useToastMessages();
  const activePartner = useMyPartnersStore(activePartnerSelector);

  const sendFiguralCredentials = useCallback(
    async (partnerId: string, values: IFiguralCredentialsPost) => {
      const { error, data } = await makeRequest({
        path: `me/partners/${partnerId}/secursus_credentials`,
        method: 'POST',
        body: values,
      });

      if (error) {
        toastError('messages:sendFiguralCredentialsError.message', {
          wide: true,
        });

        return { success: false, data: null };
      }

      return { success: true, data: data };
    },
    [makeRequest, toastError]
  );

  const disableFiguralForPartner = useCallback(
    async (partnerId: string) => {
      const { error, data } = await makeRequest({
        path: `me/partners/${partnerId}/secursus/disable`,
        method: 'PUT',
      });

      if (error) {
        toastError('messages:disableFiguralForPartnerError.message', {
          wide: true,
        });

        return { success: false, data: null };
      }

      return { success: true, data: data };
    },
    [makeRequest, toastError]
  );

  const getFiguralInsuranceDataFromLabel = useCallback(
    (label: IOcrLabel, carrierCode?: Carriers): IFiguralInsurance => ({
      departureCountryCode: label.senderAddress.countryCode,
      arrivalCountryCode: label.recipientAddress.countryCode,
      note: '',
      sender: {
        company: activePartner.name,
        firstName: label.senderAddress.firstName,
        lastName: label.senderAddress.lastName,
        email: label.senderAddress.email,
        address: label.senderAddress.street ? label.senderAddress.street : '',
        city: label.senderAddress.city,
        zipCode: label.senderAddress.postalCode,
        countryCode: label.senderAddress.countryCode,
      },
      isGoodsReturnInsured: false,
      carrierName: carrierCode ? carrierCode : '',
      trackingNumber: label.carrierTrackingCode,
      isConfirmed: false,
    }),
    [activePartner]
  );

  const getFiguralInsuranceDataFromShipment = useCallback(
    (shipment: IShipment): IFiguralInsurance => ({
      departureCountryCode: shipment.sender.address.countryCode,
      arrivalCountryCode: shipment.recipient.address.countryCode,
      note: '',
      sender: {
        company: activePartner.name,
        firstName: shipment.sender.address.firstName,
        lastName: shipment.sender.address.lastName,
        email: shipment.sender.notificationEmail,
        address: shipment.sender.address.street
          ? shipment.sender.address.street
          : '',
        city: shipment.sender.address.city,
        zipCode: shipment.sender.address.postalCode,
        countryCode: shipment.sender.address.countryCode,
      },
      isGoodsReturnInsured: false,
      carrierName: shipment.carrier_code,
      trackingNumber: shipment.carrier_tracking_code,
      shipmentId: shipment.id ? shipment.id : undefined,
      isConfirmed: false,
    }),
    [activePartner]
  );

  return {
    loading,
    sendFiguralCredentials,
    disableFiguralForPartner,
    getFiguralInsuranceDataFromLabel,
    getFiguralInsuranceDataFromShipment,
  };
};

export default useFiguralInsurance;
