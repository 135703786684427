import React, { useState } from 'react';

import FirstPage from './FirstPage';
import SecondPage from './SecondPage';

type Props = {
  onClose?: () => void;
};

export const Onboarding = ({ onClose = () => {} }: Props) => {
  const [stepsOnboarding, setStepsOnboarding] = useState<number>(0);

  return stepsOnboarding === 0 ? (
    <FirstPage
      setStepsOnboarding={setStepsOnboarding}
      closeOnboarding={onClose}
    />
  ) : stepsOnboarding === 1 ? (
    <SecondPage
      setStepsOnboarding={setStepsOnboarding}
      closeOnboarding={onClose}
    />
  ) : null;
};
