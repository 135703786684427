import React from 'react';

import {
  Container,
  ErrorMessage,
  Label,
  Wrapper,
} from 'components/atoms/Input';
import get from 'lodash/get';
import Select from 'react-select';
import { DefaultTheme, withTheme } from 'styled-components';
import { LayoutProps, SpaceProps } from 'styled-system';

interface IDropdown extends SpaceProps, LayoutProps {
  id?: string;
  value: { value: string | number; label: string } | null;
  options: any[];
  onChange?: (e: any) => void;
  label?: string;
  name?: string;
  placeholder?: React.ReactNode | string;
  width?: string;
  height?: string;
  theme: DefaultTheme;
  fontSize?: string;
  fontFamily?: string;
  textColor?: string;
  isSearchable?: boolean;
  error?: any;
}

const getCustomStyles = (
  theme: DefaultTheme,
  width: string,
  height: string,
  label?: string,
  fontSize?: string,
  fontFamily?: string,
  textColor?: string
) => ({
  container: (provided: any, state: any) => ({
    ...provided,
    width,
    height,
    backgroundColor: theme.colors.white,
    borderRadius: '10px',
    cursor: 'pointer',
    position: 'relative',
  }),
  control: (_: any, state: any) => ({
    display: 'flex',
    width,
    height,
    border: `solid 1px ${
      state.isFocused ? theme.colors.primary[100] : theme.colors.black[10]
    }`,
    borderRadius: '10px',
  }),
  singleValue: (provided: any, state: any) => ({
    ...provided,
    top: label ? '70%' : '50%',
    marginLeft: '0.25rem',
    fontSize: fontSize ? fontSize : '15px',
    fontWeight: fontFamily === 'CircularMedium' ? 500 : 'inherit',
    color: textColor ? textColor : 'inherit',
    marginTop: '1rem',
  }),
  input: (provided: any, state: any) => ({
    ...provided,
    position: 'absolute',
    bottom: label ? '0%' : '25%',
    fontSize: fontSize ? fontSize : '15px',
  }),
  placeholder: (provided: any, state: any) => ({
    ...provided,
    fontSize: fontSize ? fontSize : '15px',
    fontWeight: fontFamily === 'CircularMedium' ? 500 : 'inherit',
    color: textColor ? textColor : 'inherit',
  }),

  dropdownIndicator: (provided: any, state: any) => ({
    ...provided,
    transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : 'none',
    transition: 'transform .5s ease',
    color: theme.colors.black[20],
    ':hover': {},
  }),
  menu: (provided: any, state: any) => ({
    ...provided,
    width,
    top: '50%',
    paddingTop: '1rem',
    boxShadow: 'none',
    border: `solid 1px ${theme.colors.black[30]}`,
    borderRadius: '10px',
    zIndex: -1,
  }),
  menuList: (provided: any, state: any) => ({
    ...provided,
    maxHeight: '9.5rem',
    marginBottom: '0.2rem',
    marginRight: '0.2rem',
    fontSize: fontSize ? fontSize : '15px',
    color: textColor ? textColor : 'inherit',
    fontWeight: fontFamily === 'CircularMedium' ? 500 : 'inherit',
  }),
  option: (provided: any, state: any) => ({
    display: 'flex',
    alignItems: 'center',
    margin: '0 .375rem',
    padding: '0 .375rem',
    borderRadius: '10px',
    height: '2.375rem',
    ':hover': {
      backgroundColor: theme.colors.primary[5],
    },
  }),
  indicatorSeparator: (provided: any, state: any) => ({
    display: 'none',
  }),
});

const Dropdown = ({
  id,
  options,
  width = '17.5rem',
  height = '3.125rem',
  onChange,
  label,
  placeholder,
  value,
  name,
  theme,
  isSearchable = false,
  error = undefined,
  ...rest
}: IDropdown) => {
  const customStyles = getCustomStyles(
    theme,
    width,
    height,
    label,
    rest.fontSize,
    rest.fontFamily,
    rest.textColor
  );

  return (
    <Wrapper width={width} height={height} {...rest}>
      <Container style={{ zIndex: 3 }}>
        {value && label && <Label label={label} />}
        <Select
          id={id}
          options={options}
          value={value}
          name={name}
          onChange={onChange}
          placeholder={placeholder}
          styles={customStyles}
          isSearchable={isSearchable}
        />
      </Container>
      {error && (
        <ErrorMessage
          errorMessage={get(error, 'message', '') || 'Field error'}
        />
      )}
    </Wrapper>
  );
};
export default withTheme(Dropdown);
