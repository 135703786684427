import { useMutation } from '@tanstack/react-query';
import useImperativeRequest from 'hooks/useImperativeRequest';
import useToastMessages from 'hooks/useToastMessages';
import { MutationConfig, queryClient } from 'lib/react-query';
import { PreferredLanguage } from 'models/user';
import { filterValues } from 'utils/filterEditableValues';

import { getMeQueryKey } from './getMe';
import { User } from '../../types/user';

export type FormValues = {
  first_name: string;
  last_name: string;
  phone_number: string;
  preferred_language: PreferredLanguage;
};

export const formKeys = [
  'first_name',
  'last_name',
  'phone_number',
  'preferred_language',
];

export const updateMe =
  ({
    initialValues,
    makeRequest,
  }: {
    initialValues: Partial<User>;
    makeRequest: any;
  }) =>
  async (values: Partial<User>): Promise<User> => {
    const filteredValues = filterValues({ initialValues, values, formKeys });

    const { data, error } = await makeRequest({
      path: `/me`,
      method: 'patch',
      body: filteredValues,
    });

    if (!data || error) {
      throw error;
    }

    return data;
  };

type UseUpdateMeOptions = {
  initialValues: Partial<User>;
  config?: MutationConfig<ReturnType<typeof updateMe>>;
};

export const useUpdateMe = ({ initialValues, config }: UseUpdateMeOptions) => {
  const [, makeRequest] = useImperativeRequest('lpAccountV3');
  const { error: toastError, success: toastSuccess } = useToastMessages();

  return useMutation({
    ...config,
    onSuccess: data => {
      toastSuccess('messages:updateProfileSuccess.message');
      queryClient.setQueryData(getMeQueryKey, data);
    },
    onError: () => {
      toastError('messages:updateProfileError.message');
    },
    mutationFn: updateMe({
      initialValues,
      makeRequest,
    }),
  });
};
