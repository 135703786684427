import i18next from 'i18next';
import { ContactAddressType } from 'models/contactAddress';
import * as yup from 'yup';

import { phoneNumber } from './common';

export const shipmentLabelAddressSchema = () =>
  yup.object().shape({
    type: yup.string(),
    firstName: yup
      .string()
      .required(
        i18next.t('shipments:form.label.firstName.validation.required')
      ),
    lastName: yup
      .string()
      .required(i18next.t('shipments:form.label.lastName.validation.required')),
    company: yup.string().when('type', {
      is: (value: any) => value === ContactAddressType.professional,
      then: (schema: any) =>
        yup
          .string()
          .min(
            1,
            i18next.t('shipments:form.label.companyName.validation.required')
          )
          .required(
            i18next.t('shipments:form.label.companyName.validation.required')
          ),
    }),
    email: yup
      .string()
      .required(i18next.t('shipments:form.label.email.validation.required'))
      .email(i18next.t('shipments:form.label.email.validation.email')),
    street: yup
      .string()
      .required(i18next.t('shipments:form.label.street.validation.required')),
    city: yup
      .string()
      .required(i18next.t('shipments:form.label.city.validation.required')),
    postalCode: yup
      .string()
      .required(
        i18next.t('shipments:form.label.postalCode.validation.required')
      ),
    countryCode: yup
      .string()
      .required(
        i18next.t('shipments:form.label.countryCode.validation.required')
      ),
  });

export const shipmentAddressSenderInformationSchema = () =>
  yup.object().shape({
    senderAddress: shipmentLabelAddressSchema(),
  });

export const shipmentAddressRecipientInformationSchema = () =>
  yup.object().shape({
    recipientAddress: shipmentLabelAddressSchema(),
  });

export const shipmentAddressInformationSchema =
  ({ smsEnabled = false }: { isSender?: boolean; smsEnabled?: boolean } = {}) =>
  () =>
    yup.object().shape({
      senderAddress: shipmentLabelAddressSchema(),
      recipientAddress: shipmentLabelAddressSchema(),
      ...(smsEnabled && {
        senderPhoneNumber: phoneNumber(),
        recipientPhoneNumber: phoneNumber(),
      }),
    });

export const shipmentParcelSchema = () =>
  yup.object().shape({
    weightInGrams: yup
      .number()
      .typeError(i18next.t('general:weightFieldError'))
      .positive(i18next.t('general:weightFieldError'))
      .transform((val, orig) => (orig === '' ? undefined : val)),
    carrierTrackingCode: yup.string().required(i18next.t('general:fieldError')),
    referenceNumber: yup.string().required(i18next.t('general:fieldError')),
  });

// Date and time filter for shipments.

// dd/mm/yyyy
const otherDateFormat =
  // eslint-disable-next-line no-useless-escape
  /^(((0[1-9]|[12][0-9]|3[01])[\/](0[13578]|1[02])[\/](\d{4}))|((0[1-9]|[12][0-9]|30)[\/](0[469]|11)[\/](\d{4}))|((0[1-9]|1[0-9]|2[0-8])[\/]02[\/](\d{4}))|((29)[\/]02[\/](\d{4})))$/;
// yyyy/mm/dd
const germanDateFormat =
  // eslint-disable-next-line no-useless-escape
  /^(\d{4}[\/]?((((0[13578])|(1[02]))[\/]?(([0-2][0-9])|(3[01])))|(((0[469])|(11))[-/\s]?(([0-2][0-9])|(30)))|(02[-/\s]?[0-2][0-9])))$/;

// hh:mm am or hh:mm pm
// const hours12Format = /^(1[0-2]|0?[0-9]):([0-5][0-9]) ([ap][m])$/;
// hh:mm
const hours24Format = /^([0-1]?[0-9]|[2][0-3]):([0-5][0-9])(:[0-5][0-9])?$/;

// Constrains the date field in the date filter window
const dateRegex = (date?: string) => {
  if (!date) {
    return false;
  }

  switch (i18next.language) {
    case 'de':
      return germanDateFormat.test(date);
    default:
      return otherDateFormat.test(date);
  }
};

// Constrains the time field in the date filter window
const timeRegex = (time?: string) => {
  if (!time) {
    return false;
  }

  return hours24Format.test(time);
};

export const shipmentSearchSchema = () =>
  yup.object().shape({
    shipmentData: yup.string(),
    date: yup
      .string()
      .test(
        'date',
        i18next.t('shipments:dateTimeFilter.wrongFormat'),
        dateRegex
      )
      .required(i18next.t('general:fieldError')),
    startTime: yup
      .string()
      .required(i18next.t('general:fieldError'))
      .test(
        'startTime',
        i18next.t('shipments:dateTimeFilter.wrongFormat'),
        timeRegex
      )
      .test(
        'startTimeInferiorEndTime',
        i18next.t('shipments:dateTimeFilter.errorStartTimeAfterEndTime'),
        (startTime, context) => {
          const { endTime } = context.parent;

          if (!endTime) return true;

          return startTime <= endTime;
        }
      ),
    endTime: yup
      .string()
      .required(i18next.t('general:fieldError'))
      .test(
        'endTime',
        i18next.t('shipments:dateTimeFilter.wrongFormat'),
        timeRegex
      )
      .test(
        'endTimeSuperiorStartTime',
        i18next.t('shipments:dateTimeFilter.errorEndTimeAfterStartTime'),
        (endTime, context) => {
          const { startTime } = context.parent;

          if (!startTime) return true;

          return endTime >= startTime;
        }
      ),
  });

export const shipmentNameSchema = () =>
  yup.object().shape({
    name: yup
      .string()
      .required(i18next.t('shipments:form.shipmentName.validation.required')),
  });
