import React, { useCallback, useState } from 'react';

import {
  MarkerIcon,
  ShowroomIcon,
} from '@livingpackets/design-system-react-next';
import { useTranslation } from 'react-i18next';
import styled, { useTheme } from 'styled-components';

import InfoPointNantes from '../../assets/img/showrooms/infoPoint_nantes.png';
import InfoPointVertou from '../../assets/img/showrooms/infoPoint_vertou.png';
import ShowroomParis from '../../assets/img/showrooms/showroom_paris.png';
import LPPointCard from '../molecules/LPPointCard';
import ShowroomMap from '../molecules/ShowroomMap';

const ShowroomWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;
const CardsWrapper = styled.div`
  height: 44.4rem;
  width: 31%;
  overflow-y: auto;
  padding-right: 0.5rem;

  ::-webkit-scrollbar-thumb {
    width: 0.25rem;
    border-radius: 0.125rem;
    background-color: ${({ theme }) => theme.colors.black[10]};
  }

  ::-webkit-scrollbar {
    width: 0.25rem;
    border-radius: 0.125rem;
    background-color: ${({ theme }) => theme.colors.black[4]};
  }
`;
export const defaultCenter = {
  lat: 48.2276,
  lng: 0.8,
};
const heights = [13.5, 13, 13];
export const centers = [
  { lat: 48.86789, lng: 2.34763 },
  { lat: 47.21604, lng: -1.55318 },
  { lat: 47.17239, lng: -1.47059 },
];
const defaultZoom = 7.2;
const ProfileShowroom = () => {
  const { t } = useTranslation('showroom');
  const { colors } = useTheme();
  const [openCardIndex, setOpenCardIndex] = useState(-1);
  const [center, setCenter] = useState(defaultCenter);
  const [zoom, setZoom] = useState(defaultZoom);
  const showrooms = [
    {
      image: ShowroomParis,
      city: t('showrooms.cities.paris'),
      street: t('showrooms.streets.paris'),
      postalCode: t('showrooms.postalCodes.paris'),
      place: t('showrooms.places.showroom'),
      openingHours: t('showrooms.openingHours.paris'),
      country: t('showrooms.countries.france'),
      phoneNumber: t('showrooms.phoneNumbers.paris'),
      icon: <ShowroomIcon color={colors.primary[100]} />,
    },
    {
      image: InfoPointNantes,
      houseName: t('showrooms.houseNames.nantes'),
      city: t('showrooms.cities.nantes'),
      street: t('showrooms.streets.nantes'),
      postalCode: t('showrooms.postalCodes.nantes'),
      place: t('showrooms.places.informationPoint'),
      openingHours: t('showrooms.openingHours.nantes'),
      country: t('showrooms.countries.france'),
      icon: <MarkerIcon color={colors.primary[100]} />,
    },
    {
      image: InfoPointVertou,
      houseName: t('showrooms.houseNames.vertou'),
      city: t('showrooms.cities.vertou'),
      street: t('showrooms.streets.vertou'),
      postalCode: t('showrooms.postalCodes.vertou'),
      place: t('showrooms.places.informationPoint'),
      openingHours: t('showrooms.openingHours.vertou'),
      country: t('showrooms.countries.france'),
      icon: <MarkerIcon color={colors.primary[100]} />,
    },
  ];
  const handleCardClick = useCallback(
    (index: number) => {
      if (openCardIndex === index) {
        setOpenCardIndex(-1);
        setCenter(defaultCenter);
        setZoom(defaultZoom);
      } else {
        setOpenCardIndex(index);
        setCenter(centers[index]);
        setZoom(19);
      }
    },
    [openCardIndex]
  );
  const handleBackButtonClick = () => {
    setCenter(defaultCenter);
    setZoom(defaultZoom);
    setOpenCardIndex(-1);
  };

  return (
    <ShowroomWrapper>
      <ShowroomMap
        center={center}
        zoom={zoom}
        onClick={handleBackButtonClick}
        onMarkerClick={handleCardClick}
      />
      <CardsWrapper>
        {showrooms.map((showroom, index) => (
          <LPPointCard
            height={heights[index]}
            key={index}
            {...showroom}
            isOpen={openCardIndex === index}
            onClick={() => handleCardClick(index)}
          />
        ))}
      </CardsWrapper>
    </ShowroomWrapper>
  );
};
export default ProfileShowroom;
