import React, { useState } from 'react';

import {
  Box,
  ChevronRightIcon,
  SavIcon,
  Stack,
  Text,
  ChatIcon,
  PhoneIcon,
  Grid,
} from '@livingpackets/design-system-react-next';
import LouisVuittonLogo from 'assets/louis-vuitton.svg';
import { ContactUsModal } from 'features/account';
import { normalizeKey } from 'helpers/i18n';
import useIsMobile from 'hooks/useIsMobile';
import { useTranslation } from 'react-i18next';

export const Support = ({ partnerId }: { partnerId: string | undefined }) => {
  const { t } = useTranslation(['shipments']);

  const [openContactModal, setOpenContactModal] = useState(false);
  const { isMobile } = useIsMobile();

  const isSpecificPartner = [
    process.env.REACT_APP_LOUIS_VUITTON_PARTNER_ID,
    process.env.REACT_APP_LOUIS_VUITTON_COMPANY_PARTNER_ID,
  ].includes(partnerId);

  const partnerKey = isSpecificPartner ? 'louisVuitton' : 'LP';

  const supportList: {
    [key: string]: {
      name: string;
      brand: string;
      faqLabel: string;
      faqLink: string;
      phoneNumber: string;
    }[];
  } = {
    LP: [
      // TODO : Fake data For LP because it's hard coded
      {
        name: 'Linving Packets France',
        brand: '',
        faqLabel: 'Livraison | Louis Vuitton FAQ',
        faqLink: 'https://fr.louisvuitton.com/fra-fr/vos-questions/livraison',
        phoneNumber: '(+33) 9 77 40 40 77',
      },
      {
        name: 'Linving Packets Allemange',
        brand: '',
        faqLabel: 'Livraison | Louis Vuitton FAQ',
        faqLink: 'https://fr.louisvuitton.com/fra-fr/vos-questions/livraison',
        phoneNumber: '(+33) 9 77 40 40 77',
      },
      {
        name: 'Linving Packets Suisse',
        brand: '',
        faqLabel: 'Livraison | Louis Vuitton FAQ',
        faqLink: 'https://fr.louisvuitton.com/fra-fr/vos-questions/livraison',
        phoneNumber: '(+33) 9 77 40 40 77',
      },
    ],
    louisVuitton: [
      {
        name: 'Louis Vuitton',
        brand: LouisVuittonLogo,
        faqLabel: 'Livraison | Louis Vuitton FAQ',
        faqLink: 'https://fr.louisvuitton.com/fra-fr/vos-questions/livraison',
        phoneNumber: '(+33) 9 77 40 40 77',
      },
    ],
  };

  return (
    <Box
      bgcolor="custom.neutral.white.pure"
      py={isMobile ? '1rem' : '1.5rem'}
      pl="1rem"
      pr={isMobile ? '10px' : '1rem'}
      mx={isMobile ? '1rem' : '0'}
      display="flex"
      flexDirection="row"
      borderRadius={isMobile ? '.625rem' : '1.25rem'}
      sx={{
        ...(isMobile && { boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.05)' }),
      }}
    >
      {(supportList as any)[partnerKey].length > 1 ? (
        <>
          <Grid container>
            <Grid item>
              <SavIcon style={{ margin: '.25rem' }} />
            </Grid>
            <Grid item flex="1">
              <Stack direction="column" pl="1rem" pr=".5rem" gap=".25rem">
                <Text variant="titleS">
                  {t('shipments:anonymous.mobile.helperQuestion')}
                </Text>
                <Text variant="titleS" color="primary">
                  {t('shipments:anonymous.mobile.helperContact')}
                </Text>
              </Stack>
            </Grid>
            <Grid
              item
              display="flex"
              justifyContent="end"
              alignItems="center"
              color="custom.neutral.black.50"
            >
              <ChevronRightIcon onClick={() => setOpenContactModal(true)} />
            </Grid>
          </Grid>
          <ContactUsModal
            open={openContactModal}
            setOpen={setOpenContactModal}
          />
        </>
      ) : (
        <>
          <Stack
            alignItems="normal"
            p=".25rem"
            sx={{
              display: { mobile: 'block', tablet: 'none', desktop: 'none' },
            }}
          >
            <SavIcon />
          </Stack>
          <Box width="100%">
            <img
              src={(supportList as any)[partnerKey][0].brand}
              alt={supportList[partnerKey][0].name}
              style={{
                paddingRight: '.5rem',
                paddingLeft: '.5rem',
                marginBottom: '.875rem',
                ...(isMobile ? { paddingTop: '4px' } : {}),
              }}
            />
            <Stack
              direction={isMobile ? 'column' : 'row'}
              gap={isMobile ? '.5rem' : '2.125rem'}
            >
              <Stack direction="row" gap=".5rem" alignItems="center">
                <PhoneIcon />
                <Text
                  variant="bodyTextM"
                  sx={{ textDecoration: 'underline' }}
                  color="custom.neutral.black.100"
                  component="a"
                  href={`tel:${(
                    t(
                      normalizeKey(supportList[partnerKey][0].phoneNumber)
                    ) as string
                  ).replace(/\s/g, '')}`}
                >
                  {supportList[partnerKey][0].phoneNumber}
                </Text>
              </Stack>

              <Stack direction="row" gap=".5rem" alignItems="center">
                <ChatIcon />
                <Text
                  variant="bodyTextM"
                  sx={{ textDecoration: 'underline' }}
                  color="custom.neutral.black.100"
                  component="a"
                  href="#"
                  onClick={() =>
                    window.open(supportList[partnerKey][0].faqLink, '_blank')
                  }
                >
                  {supportList[partnerKey][0].faqLabel}
                </Text>
              </Stack>
            </Stack>
          </Box>
          <Stack
            justifyContent="center"
            p=".25rem"
            sx={{
              display: { mobile: 'none', tablet: 'flex', desktop: 'flex' },
            }}
          >
            <SavIcon />
          </Stack>
        </>
      )}
    </Box>
  );
};
