import { useCallback } from 'react';

import { PaginationParams } from '@livingpackets/shared-components';
import { AxiosResponse } from 'axios/index';
import { ProductModel } from 'models/product';

import { useApiClient } from './useApiClient';

export interface PaginatedResponse<Type> {
  product_states: Type[];
  total: number;
}

export interface PaginatedResponseProduct<Type> {
  items: Type[];
  count: number;
  total: number;
}

export interface GetPartnerProductsParams extends Partial<PaginationParams> {
  partnerId: string;
  search?: string;
}

type GetPartnerProducts = (
  params: GetPartnerProductsParams
) => Promise<PaginatedResponseProduct<ProductModel>>;

export function useGetPartnerProducts(): GetPartnerProducts {
  const device = useApiClient('deviceV3');

  return useCallback(
    ({
      page = 1,
      pageSize = 100,
      orderColumn = 'updatedAt',
      orderDirection = 'DESC',
      partnerId,
      search,
    }: GetPartnerProductsParams) =>
      device
        .get<PaginatedResponse<ProductModel>>(
          `/me/partners/${partnerId}/products/-/state`,
          {
            params: {
              'list_request.page_size': pageSize,
              'list_request.page_offset': (page - 1) * pageSize,
              'list_request.order_by': orderColumn + ' ' + orderDirection,
              'list_request.filter': search,
            },
          }
        )
        .then((response: AxiosResponse) => {
          if (!('product_states' in response.data)) {
            response.data.product_states = [];
          }

          if (!('total' in response.data)) {
            response.data.total = 0;
          }

          response.data.product_states = response.data.product_states.map(
            (product: ProductModel) => {
              if (product.contract_state_updated_at) {
                product.contract_state_updated_at =
                  new Date(product.contract_state_updated_at).getTime() / 1000;
              }

              if (product.location_updated_at) {
                product.location_updated_at =
                  new Date(product.location_updated_at).getTime() / 1000;
              }

              if (product.partner_since) {
                product.partner_since =
                  new Date(product.partner_since).getTime() / 1000;
              }

              return product;
            }
          );

          return {
            items: response.data.product_states,
            count: response.data.product_states.length,
            total: response.data.total,
          };
        }),
    [device]
  );
}
