import { useMutation } from '@tanstack/react-query';
import { Address } from 'features/account';
import useImperativeRequest from 'hooks/useImperativeRequest';
import { MutationConfig } from 'lib/react-query';
import { useParams } from 'react-router-dom';

const createAddress =
  ({
    partnershipId,
    makeRequest,
  }: {
    partnershipId?: string;
    makeRequest: any;
  }) =>
  async ({
    contactId,
    values,
  }: {
    contactId: string;
    values: Partial<Address>;
  }): Promise<Address> => {
    const { data, error } = await makeRequest({
      path: `api/v1/me/partners/${partnershipId}/contacts/${contactId}/addresses`,
      method: 'post',
      body: values,
    });

    if (!data || error) {
      throw error;
    }

    return data;
  };

type UseCreateAddressOptions = {
  partnershipId?: string;
  config?: MutationConfig<ReturnType<typeof createAddress>>;
};

export const useCreateAddress = ({
  partnershipId,
  config,
}: UseCreateAddressOptions = {}) => {
  const { partnerId } = useParams();

  let id = partnershipId;
  if (!id) {
    id = partnerId;
  }

  const [, makeRequest] = useImperativeRequest('lpVillage');

  return useMutation({
    ...config,
    mutationFn: createAddress({ partnershipId: id, makeRequest }),
  });
};
