import React, { useCallback, useEffect, useMemo, useState } from 'react';

import {
  ProblemIcon,
  SavedIcon,
  Text,
  Theme,
  styled,
  useTheme,
} from '@livingpackets/design-system-react-next';
import Dots from 'components/atoms/DotsAnimated';
import get from 'lodash/get';
import { UseFormRegister } from 'react-hook-form/dist/types/form';
import { useTranslation } from 'react-i18next';
import { layout, LayoutProps, space, SpaceProps } from 'styled-system';

interface ITextArea
  extends SpaceProps,
    React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  label?: string;
  value?: string;
  onChange?: React.ChangeEventHandler<EventTarget>;
  error?: any;
  onFocus?: React.EventHandler<any>;
  placeholder?: string;
  icon?: React.ReactNode;
  width?: string;
  height?: string;
  isTouched?: boolean;
  register?: UseFormRegister<any>;
  ref?: any;
  characterLimit?: number;
  isDirty?: boolean;
}

const Wrapper = styled('div')<LayoutProps & SpaceProps & any>`
  display: flex;
  flex-direction: column;
  position: relative;
  ${layout}
  ${space}
`;

const LabelWrapper = styled('div')`
  position: absolute;
  padding: 0.4rem 0.8125rem 0.1rem 0.7125rem;
  margin-left: 0.1rem;
  margin-top: 0.1rem;
  width: 92%;
  top: 0;
  border-radius: 10px 0;
  pointer-events: none;
  background-color: ${({ theme }) => theme.palette.custom.neutral.white.pure};
  z-index: 1;
`;

const Label = ({ label }: Pick<ITextArea, 'label'>) =>
  label ? (
    <LabelWrapper>
      <Text variant="bodyTextS" color="custom.neutral.black.100">
        {label}
      </Text>
    </LabelWrapper>
  ) : (
    <></>
  );

const ErrorMessageWrapper = styled('div')`
  padding-left: 0.8125rem;
`;

const ErrorMessage = ({ errorMessage }: { errorMessage: string }) => (
  <ErrorMessageWrapper>
    <Text variant="bodyTextXS" color="custom.error.red.100">
      {errorMessage}
    </Text>
  </ErrorMessageWrapper>
);

const Container = styled('div')`
  display: flex;
  align-items: center;
  position: relative;
`;

const CharacterCount = styled('div')`
  position: absolute;
  left: 1px;
  bottom: 1px;
  font-size: 10px;
  color: ${({ theme }) => theme.palette.custom.neutral.black[100]};
  width: calc(100% - 0.125rem);
  height: 1.313rem;
  padding-top: 0.125rem;
  padding-left: 0.75rem;
  background-color: #ffffff;
  border-radius: 0 0 10px 10px;
`;

const StyledTextArea = styled('textarea')<ITextArea>`
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  padding: ${({ label }) => (label ? '1.5rem' : '0.5rem')} 1.3rem 1.5rem 0.75rem;
  border-radius: 10px;
  font-size: 15px;
  outline: 0;
  box-sizing: border-box;
  border: solid 1px ${({ theme }) => theme.palette.custom.neutral.black[10]};
  font-weight: 450;
  overflow-y: auto;
  resize: none;

  &:focus {
    border: solid 1px ${({ theme }) => theme.palette.custom.primary[100]};
  }

  &::placeholder {
    color: ${({ theme }) => theme.palette.custom.neutral.black[30]};
  }
`;

const IconWrapper = styled('div')<{
  label?: string;
  left: string;
  error: boolean;
}>`
  display: flex;
  width: fit-content;
  justify-content: flex-end;
  position: relative;
  bottom: ${({ error }) => (error ? '2.5rem' : '1.25rem')};
  left: ${({ left }) => `calc(${left} - 1.2rem)`};
`;

const chooseIcon = (
  focus: boolean,
  error: boolean,
  width: string,
  theme: Theme,
  value?: string,
  label?: string,
  isTouched?: boolean
) => {
  const icon = focus ? 'dots' : error ? 'error' : isTouched ? 'ok' : 'none';

  switch (icon) {
    case 'dots':
      return <Dots textArea />;
    case 'error':
      return (
        <IconWrapper label={label} left={width} error={error}>
          <ProblemIcon color={theme.palette.custom.error.red[100]} />
        </IconWrapper>
      );
    case 'ok':
      return (
        <IconWrapper label={label} left={width} error={error}>
          <SavedIcon color={theme.palette.custom.primary[100]} />
        </IconWrapper>
      );
    case 'none':
      return;
  }
};
const TextArea = ({
  error = false,
  width = '17.5rem',
  height = '9.5rem',
  placeholder,
  onChange,
  value,
  label,
  isTouched,
  name,
  characterLimit,
  isDirty,
  register,
  ...rest
}: ITextArea) => {
  const theme = useTheme();
  const { t } = useTranslation('general');

  const [noIcon, removeIcon] = useState<boolean>(false);
  const [focus, setFocus] = useState(false);

  const [characterCount, setCharacterCount] = useState<number>(0);

  const handleFocus = useCallback(() => {
    setFocus(true);
  }, []);
  const handleBlur = useCallback(() => {
    setFocus(false);
  }, []);

  const handleChange = (e: any) => {
    if (!characterLimit <= e.target.value.length) {
      setCharacterCount(e.target.value.length);
      onChange && onChange(e);

      return;
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      if (!focus && !error) {
        removeIcon(true);
      }
    }, 1500);
    removeIcon(false);

    return () => clearTimeout(timer);
  }, [focus, error]);

  const icon = useMemo(
    () => chooseIcon(focus, error, width, theme, value, label, isTouched),
    [focus, error, width, theme, value, label, isTouched]
  );

  return (
    <Wrapper width={width} height={`calc(${height} + 1.25rem)`} {...rest}>
      <Container>
        <Label label={label} />
        {register && name && (
          <StyledTextArea
            width={width}
            height={height}
            placeholder={placeholder}
            value={value}
            onFocus={handleFocus}
            label={label}
            maxLength={characterLimit}
            {...rest}
            {...register(name, {
              onChange: event => {
                if (handleChange) {
                  handleChange(event);
                }
              },
              onBlur: event => {
                handleBlur();
              },
            })}
          />
        )}
        {characterLimit && isDirty && (
          <CharacterCount>
            {t('textAreaCharacterCount', {
              characterCount: characterCount,
              characterLimit: characterLimit,
            })}
          </CharacterCount>
        )}
      </Container>
      {error && !focus && (
        <ErrorMessage
          errorMessage={get(error, 'message', '') || 'Error message'}
        />
      )}
      {!noIcon && icon}
    </Wrapper>
  );
};

TextArea.displayName = 'TextArea';

export default TextArea;
