import { TLoading, TLoadingElement } from 'stores/appState/useAppState';

const reduceLoadingState = (loadingState: Array<TLoadingElement>) =>
  loadingState.some(element => element.state);
//
// const reduceLoadingState = (loadingState: Array<TLoadingElement>) =>
//   loadingState.reduce((prev, curr) => {
//     return curr.state ? true : prev;
//   }, false);

const setLoadingAction = (
  loadingState: TLoading,
  newElement: TLoadingElement
): TLoading => {
  if (loadingState[0].find(el => el.key === newElement.key)) {
    const newState = loadingState[0].map(el => {
      if (el.key === newElement.key) {
        return { ...el, state: newElement.state };
      }

      return el;
    });

    return [newState, reduceLoadingState(newState)];
  }

  return [
    loadingState[0].concat(newElement),
    reduceLoadingState(loadingState[0].concat(newElement)),
  ];
};

export default setLoadingAction;
