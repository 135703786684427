import { useCallback } from 'react';

import useImperativeRequest from 'hooks/useImperativeRequest';
import useToastMessages from 'hooks/useToastMessages';
import { isEmpty } from 'lodash/fp';
import {
  CreateShipmentPartnerImportOcRequest,
  IShipmentOcr,
  IShipmentToImport,
} from 'models/shipment';
import useMyPartnersStore, {
  activePartnerSelector,
} from 'stores/useMyPartnersStore';

const useCreateShipment = () => {
  const [{ loading }, makeRequest] = useImperativeRequest('shipment');
  const [, makeRequestOcr] = useImperativeRequest('shipment');
  const { error: toastError, success: toastSuccess } = useToastMessages();
  const activePartner = useMyPartnersStore(activePartnerSelector);

  const importShipment = useCallback(
    async (shipment: any) => {
      const { data, error } = await makeRequest({
        path: 'api/v2/shipments/import',
        method: 'post',
        body: shipment,
      });

      if (error) {
        toastError('messages:createShipError.message');

        return { success: false };
      }

      toastSuccess('messages:createShipSuccess.message');

      return { success: true, data };
    },
    [makeRequest, toastSuccess, toastError]
  );

  const createOcrShipment = useCallback(
    async (shipment: any) => {
      const { data, error } = await makeRequest({
        path: `api/v2/partners/${activePartner.id}/shipments/import`,
        method: 'post',
        body: shipment,
      });

      if (error) {
        toastError('messages:createShipError.message');

        return { success: false };
      }

      toastSuccess('messages:createShipSuccess.message', { wide: true });

      return { success: true, data };
    },
    [makeRequest, toastSuccess, toastError, activePartner.id]
  );

  const createOcrRequest = useCallback(
    async ({
      document,
      carrierCode,
      pageNumber,
      returnPageNumber,
      isThermal,
    }: IShipmentOcr) => {
      let bodyFormData = new FormData();
      bodyFormData.append('document', document || '');
      bodyFormData.append('carrierCode', carrierCode || '');

      if (pageNumber) {
        bodyFormData.append('pageNumber', pageNumber.toString());
      }

      if (returnPageNumber) {
        bodyFormData.append('returnPageNumber', returnPageNumber.toString());
      }

      if (isThermal) {
        bodyFormData.append('isThermal', isThermal ? 'true' : 'false');
      }

      const { data, error } = await makeRequestOcr({
        path: 'api/v2/shipments/ocr',
        method: 'post',
        headers: { 'content-type': 'multipart/form-data' },
        body: bodyFormData,
      });

      if (error) return { success: false };

      return { success: true, data: data };
    },
    [makeRequestOcr]
  );

  const pollOcrRequest = useCallback(
    async (ocrId: string) => {
      const { data, error } = await makeRequestOcr({
        path: `api/v2/shipments/ocr/${ocrId}`,
        method: 'get',
      });

      if (error) {
        toastError('messages:importShipError.message');

        return { success: false };
      }

      return { success: true, data: data };
    },
    [makeRequestOcr, toastError]
  );

  const getShipmentPartnerImport = useCallback(
    (shipment: IShipmentToImport): CreateShipmentPartnerImportOcRequest => ({
      originalShipment: {
        weightInGrams: shipment.originalShipment.weightInGrams,
        carrierCode: shipment.originalShipment.carrierCode,
        carrierTrackingCode: shipment.originalShipment.carrierTrackingCode,
        ocrId: shipment.originalShipment.ocrId,
        manualCrop: shipment.originalShipment.manualCrop,
        manualCropId: shipment.originalShipment.manualCropId,
        type: shipment.originalShipment.type,
        notificationEmailSender:
          shipment.originalShipment.notificationEmailSender,
        notificationEmailRecipient:
          shipment.originalShipment.notificationEmailRecipient,
        referenceNumber: shipment.originalShipment.referenceNumber,
        parcelRequestId: shipment.originalShipment.parcelRequestId
          ? shipment.originalShipment.parcelRequestId
          : undefined,
        senderAddress: {
          company: shipment.originalShipment.senderAddress.company,
          firstName: shipment.originalShipment.senderAddress.firstName,
          lastName: shipment.originalShipment.senderAddress.lastName,
          houseNumber: '',
          street: shipment.originalShipment.senderAddress.street,
          street2: '',
          city: shipment.originalShipment.senderAddress.city,
          postalCode: shipment.originalShipment.senderAddress.postalCode,
          countryCode: shipment.originalShipment.senderAddress.countryCode,
        },
        recipientAddress: {
          company: shipment.originalShipment.recipientAddress.company,
          firstName: shipment.originalShipment.recipientAddress.firstName,
          lastName: shipment.originalShipment.recipientAddress.lastName,
          houseNumber: '',
          street: shipment.originalShipment.recipientAddress.street,
          street2: '',
          city: shipment.originalShipment.recipientAddress.city,
          postalCode: shipment.originalShipment.recipientAddress.postalCode,
          countryCode: shipment.originalShipment.recipientAddress.countryCode,
        },
        ...(!isEmpty(shipment.originalShipment.senderPhoneNumber) && {
          senderPhoneNumber: shipment.originalShipment.senderPhoneNumber,
        }),
        ...(!isEmpty(shipment.originalShipment.recipientPhoneNumber) && {
          recipientPhoneNumber: shipment.originalShipment.recipientPhoneNumber,
        }),
      },
      returnShipment: shipment.returnShipment.referenceNumber
        ? {
            weightInGrams: shipment.returnShipment.weightInGrams,
            carrierCode: shipment.returnShipment.carrierCode,
            carrierTrackingCode: shipment.returnShipment.carrierTrackingCode,
            ocrId: shipment.returnShipment.ocrId,
            manualCrop: shipment.returnShipment.manualCrop,
            manualCropId: shipment.returnShipment.manualCropId,
            type: shipment.returnShipment.type,
            notificationEmailSender:
              shipment.returnShipment.notificationEmailSender,
            notificationEmailRecipient:
              shipment.returnShipment.notificationEmailRecipient,
            referenceNumber: shipment.returnShipment.referenceNumber,
            parcelRequestId: shipment.originalShipment.parcelRequestId
              ? shipment.returnShipment.parcelRequestId
              : undefined,
            senderAddress: {
              company: shipment.returnShipment.senderAddress.company,
              firstName: shipment.returnShipment.senderAddress.firstName,
              lastName: shipment.returnShipment.senderAddress.lastName,
              houseNumber: '',
              street: shipment.returnShipment.senderAddress.street,
              street2: '',
              city: shipment.returnShipment.senderAddress.city,
              postalCode: shipment.returnShipment.senderAddress.postalCode,
              countryCode: shipment.returnShipment.senderAddress.countryCode,
            },
            recipientAddress: {
              company: shipment.returnShipment.recipientAddress.company,
              firstName: shipment.returnShipment.recipientAddress.firstName,
              lastName: shipment.returnShipment.recipientAddress.lastName,
              houseNumber: '',
              street: shipment.returnShipment.recipientAddress.street,
              street2: '',
              city: shipment.returnShipment.recipientAddress.city,
              postalCode: shipment.returnShipment.recipientAddress.postalCode,
              countryCode: shipment.returnShipment.recipientAddress.countryCode,
            },
            ...(!isEmpty(shipment.returnShipment.senderPhoneNumber) && {
              senderPhoneNumber: shipment.returnShipment.senderPhoneNumber,
            }),
            ...(!isEmpty(shipment.returnShipment.recipientPhoneNumber) && {
              recipientPhoneNumber:
                shipment.returnShipment.recipientPhoneNumber,
            }),
          }
        : undefined,
    }),
    []
  );

  return {
    loading,
    importShipment,
    createOcrRequest,
    pollOcrRequest,
    createOcrShipment,
    getShipmentPartnerImport,
  };
};

export default useCreateShipment;
