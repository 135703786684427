import React from 'react';

import {
  ArrowRightIcon,
  Box,
  ButtonV2 as Button,
  Grid,
  Stack,
  Text,
} from '@livingpackets/design-system-react-next';
import BoxStackPadlock from 'assets/box-stack-with-padlock.png';
import { MoreActionsMenu } from 'features/account';
import useSendResetPasswordEmail from 'hooks/useSendResetPasswordEmail';
import { useTranslation } from 'react-i18next';

const ResetPasswordForm = () => {
  const { t } = useTranslation('profileSettings');

  const { sendResetPasswordEmail, loading } = useSendResetPasswordEmail();

  return (
    <Box
      position="relative"
      maxWidth="18rem"
      p={0}
      height="auto"
      borderRadius="1.25rem"
      bgcolor="custom.neutral.white.pure"
    >
      <Box sx={{ position: 'absolute', right: '1rem', top: '1rem' }}>
        <MoreActionsMenu />
      </Box>
      <img src={BoxStackPadlock} alt="" style={{ marginTop: '-1px' }} />
      <Stack p="2rem" pt="1rem" gap="1.5rem">
        <Text variant="titleM" mb="1.5rem">
          {t('resetPasswordHeadline')}
        </Text>
        <Grid container alignItems="center">
          <Grid item mobile={6}>
            <Text variant="bodyTextS">{t('password')}</Text>
          </Grid>
          <Text variant="bodyTextS">{t('passwordHiddenPart')}</Text>
          <Grid item mobile={6}></Grid>
        </Grid>
        <Button
          fullWidth
          onClick={sendResetPasswordEmail}
          disabled={loading}
          data-testid="reset-pw-button"
          textVariant="titleS"
          icon={ArrowRightIcon}
        >
          {t('resetLink')}
        </Button>
        <Text variant="bodyTextXS" color="custom.neutral.black.50">
          {t('changePassword')}
        </Text>
      </Stack>
    </Box>
  );
};

export default ResetPasswordForm;
