import { PATHS } from 'configs';
import { UserBusinessTypeEnum } from 'enums/UserBusinessTypeEnum';
import useNavigatePartner from 'hooks/useNavigatePartner';
import { find } from 'lodash/fp';
import { generatePath, useNavigate } from 'react-router-dom';
import { useSpring, config } from 'react-spring';
import useAppState, { userSelector } from 'stores/appState/useAppState';
import useLayoutStore, {
  openNavSelector,
  setOpenNavSelector,
} from 'stores/useLayoutStore';
import useMyPartnersStore, {
  activePartnerSelector,
} from 'stores/useMyPartnersStore';

const useHeader = () => {
  const navigate = useNavigate();

  const openNav = useLayoutStore(openNavSelector);
  const setOpenNav = useLayoutStore(setOpenNavSelector);
  const currentUser = useAppState(userSelector);
  const currentPartner = useMyPartnersStore(activePartnerSelector);
  const accountType = currentUser?.businessType;

  const navigatePartner = useNavigatePartner();

  const canUpdatePartner = !!find(
    { name: 'update:partner' },
    currentPartner.role.scopes
  );

  const redirectToSettingsPage = () => {
    if (!accountType || !currentPartner) {
      return;
    }

    setOpenNav(false);

    if (accountType === UserBusinessTypeEnum.PERSONAL) {
      return navigate(generatePath(PATHS.ACCOUNT.INFORMATION));
    }

    if (canUpdatePartner) {
      // Redirect to partner settings tab
      return navigatePartner('/account/partnership/information');
    }

    // Redirect to personal information tab
    return navigatePartner('/account/personal');
  };

  const animatedEditIconStyles = useSpring({
    config: config.gentle,
    from: { opacity: 0, translate: '-6.25rem' },
    to: {
      opacity: openNav ? 1 : 0,
      translate: openNav ? '0' : '-6.25rem',
    },
  });

  return { redirectToSettingsPage, animatedEditIconStyles };
};

export default useHeader;
