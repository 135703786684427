import React from 'react';

import {
  SenderAddressIcon,
  RecipientAddressIcon,
  styled,
  Stack,
} from '@livingpackets/design-system-react-next';
import { DetailsCard, DetailsSpan } from 'components/atoms/ShipmentAtoms';
// @ts-ignore
import { FlexDirectionProperty } from 'csstype';
import isCancelledState from 'helpers/isCancelledState';
import { IShipment } from 'models/shipment';
import { useTranslation } from 'react-i18next';
import { flexbox, FlexboxProps, space, SpaceProps } from 'styled-system';

import { Address, AddressName } from './Address';

const Wrapper = styled('div')<SpaceProps>`
  display: flex;
  flex-direction: column;
  ${space}
`;

interface IShipmentAddress {
  shipment: IShipment;
  titleTransKey?: string;
  blockDirection?: FlexDirectionProperty;
}

const Row = styled('div')<FlexboxProps & SpaceProps>`
  display: flex;
  ${flexbox}
  ${space}
`;

const IconCard = styled('div')`
  height: 45px;
  width: 45px;
  min-width: 45px;
  border-radius: ${({ theme }) => theme.borderRadius.xs};
  background: ${({ theme }) => theme.colors.black[4]};
  margin-right: 16px;
  text-align: center;
  line-height: 60px;
`;

const ShipmentAddressCard = ({
  shipment,
  titleTransKey,
  blockDirection = 'column',
  ...rest
}: IShipmentAddress & SpaceProps) => {
  const { t } = useTranslation('shipments');

  const isCancelled = isCancelledState(shipment);

  return (
    <Wrapper {...rest}>
      <DetailsSpan>
        {titleTransKey
          ? t(titleTransKey as unknown as TemplateStringsArray)
          : t('detail.address.title')}
      </DetailsSpan>
      <DetailsCard
        padding="20px"
        mt="12px"
        fontSize="12px"
        lineHeight="19px"
        style={{
          borderRadius: '10px',
          flexDirection: blockDirection,
          justifyContent: 'space-between',
          gap: blockDirection === 'column' ? 0 : '2.5rem',
        }}
        disabled={isCancelled}
      >
        <Row data-testid="shipment-sender-adress">
          <IconCard>
            <SenderAddressIcon />
          </IconCard>
          <Stack>
            <DetailsSpan>{t('detail.address.sender')}</DetailsSpan>
            <AddressName address={shipment.sender.address} />
            <Address address={shipment.sender.address} inline />
          </Stack>
        </Row>
        <Row
          mt={blockDirection === 'column' ? '20px' : 0}
          data-testid="shipment-recipient-adress"
        >
          <IconCard>
            <RecipientAddressIcon />
          </IconCard>
          <Stack>
            <DetailsSpan>{t('detail.address.recipient')}</DetailsSpan>
            <AddressName address={shipment.recipient.address} />
            <Address address={shipment.recipient.address} inline />
          </Stack>
        </Row>
      </DetailsCard>
    </Wrapper>
  );
};

export default ShipmentAddressCard;
