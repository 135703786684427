import React from 'react';

import { Text, Box, styled } from '@livingpackets/design-system-react-next';
import { useTranslation } from 'react-i18next';

interface IContractStateInfoContainer {
  contractState: string;
}

const ContractStateInfoContainerWrapper = styled(Box, {
  shouldForwardProp: prop => prop !== 'isInShipment',
})<{
  isInShipment: boolean;
}>`
  height: 28px;
  width: 103px;
  border-radius: ${({ theme }) => theme.borderRadius.xs};
  background: ${({ theme, isInShipment }) =>
    isInShipment
      ? theme.palette.custom.primary[10]
      : theme.palette.custom.neutral.black[3]};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ContractStateInfoContainer = ({
  contractState,
}: IContractStateInfoContainer) => {
  const { t } = useTranslation('products');

  return (
    <ContractStateInfoContainerWrapper
      isInShipment={contractState === 'DEPLOYED'}
    >
      <Text
        variant="bodyTextXS"
        color={
          contractState === 'DEPLOYED'
            ? 'custom.primary.100'
            : 'custom.neutral.black.50'
        }
      >
        {contractState === 'DEPLOYED'
          ? t('common.contractState.deployed')
          : t('common.contractState.idle')}
      </Text>
    </ContractStateInfoContainerWrapper>
  );
};

export default ContractStateInfoContainer;
