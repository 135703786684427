import { useQuery } from '@tanstack/react-query';
import useImperativeRequest from 'hooks/useImperativeRequest';
import { ExtractFnReturnType, QueryConfig } from 'lib/react-query';

export const getMeDeleteAccountAvailable = async ({
  makeRequest,
}: {
  makeRequest: any;
}): Promise<boolean> => {
  const { data, error } = await makeRequest({
    path: '/me/account',
  });

  if (error || !data) {
    throw new Error(error);
  }

  return data.available;
};

type QueryFnType = typeof getMeDeleteAccountAvailable;

/**
 * Query the API to know if the user can delete his account
 */
export const useMeDeleteAccountAvailable = (
  config?: QueryConfig<QueryFnType>
) => {
  const [, makeRequest] = useImperativeRequest('lpAccountV3');

  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: ['get-me-delete-account-available'],
    queryFn: () => getMeDeleteAccountAvailable({ makeRequest }),
  });
};
