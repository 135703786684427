import { useCallback, useState } from 'react';

import axios from 'axios';
import {
  IOcrLabel,
  IOcrRequest,
  OcrResult,
  OcrResultSuccess,
} from 'models/shipment';

import useCreateShipment from './useCreateShipment';
import useInterval from './useInterval';

type InitiateOcr = (
  carrierCode: string,
  file: File,
  isThermal?: boolean,
  pageNumber?: number
) => Promise<void>;
type ResetOcrState = () => void;

interface UseOcrOptions {
  onComplete?: (label: IOcrLabel, ocrResult: OcrResultSuccess) => void;
  onError?: () => void;
  onStart?: (ocr: IOcrRequest) => void;
}

export function useOcr({ onComplete, onStart, onError }: UseOcrOptions): {
  result: OcrResult;
  start: InitiateOcr;
  reset: ResetOcrState;
} {
  const [ocr, setOcr] = useState<IOcrRequest | null>(null);
  const [ocrResult, setOcrResult] = useState<OcrResult>({
    status: 'IDLE',
  });
  const ocrRequestReady = !!ocr?.ocrRequestId;

  const { createOcrRequest, pollOcrRequest } = useCreateShipment();

  const delay =
    ((ocrRequestReady && ocrResult.status !== 'COMPLETED') ||
      (ocrResult && ocrResult.status === 'PENDING')) &&
    ocrResult.status !== 'ERROR'
      ? 1000
      : 0;

  const pollOcr = useCallback(async () => {
    if (ocr === null) return;

    const { success, data } = await pollOcrRequest(ocr.ocrRequestId);
    if (!success) {
      onError && onError();

      return;
    }

    const result: OcrResult = data[Object.keys(data)[0]];
    setOcrResult(data[Object.keys(data)[0]]);

    if (result.status === 'ERROR') {
      onError && onError();
    }

    if (result.status !== 'COMPLETED') return;

    axios.get(result.s3ExtractedLabelUrl).then(({ data }) => {
      const { sender, recipient, reference, carrierTrackingId, ...rest } = data;
      onComplete &&
        onComplete(
          {
            ...rest,
            senderAddress: sender,
            recipientAddress: recipient,
            referenceNumber: reference,
            carrierTrackingCode: carrierTrackingId,
          },
          result
        );
    });
  }, [ocr, onComplete, pollOcrRequest, onError]);
  useInterval(pollOcr, delay);

  const startOcr: InitiateOcr = useCallback(
    async (carrierCode, file, isThermal?: boolean, pageNumber?: number) => {
      const { success, data: ocr } = await createOcrRequest({
        document: file,
        carrierCode,
        pageNumber,
        isThermal,
      });

      if (!success) {
        onError && onError();

        return;
      }

      setOcr(ocr);
      onStart && onStart(ocr);
    },
    [createOcrRequest, onStart, onError]
  );

  const resetOcrState: ResetOcrState = () => {
    setOcr(null);
    setOcrResult({ status: 'IDLE' });
  };

  return { start: startOcr, result: ocrResult, reset: resetOcrState };
}
