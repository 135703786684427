import styled from 'styled-components';
import { flexbox, FlexboxProps } from 'styled-system';

//Use to wrap components of views that are not full width
const NonFullWidthWrapper = styled.div<FlexboxProps>`
  display: flex;
  min-width: 56.25rem;
  width: 56.25rem;
  ${flexbox};
`;

export default NonFullWidthWrapper;
