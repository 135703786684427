import { useMemo } from 'react';

import { LpVillageScopes } from 'configs';
import useMyPartnersStore, {
  activePartnerSelector,
} from 'stores/useMyPartnersStore';

const useHasUserScopes = (scopes: LpVillageScopes[]) => {
  const { role } = useMyPartnersStore(activePartnerSelector);
  const scopesOnPartner = useMemo(() => role.scopes.map(s => s.name), [role]);

  return scopes.every((scope: LpVillageScopes) =>
    scopesOnPartner.includes(scope)
  );
};

export default useHasUserScopes;
