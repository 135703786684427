import { useAuth0 } from '@auth0/auth0-react';
import { useMutation } from '@tanstack/react-query';
import {
  SEARCH_PARAMS_DELETE_ACCOUNT,
  SEARCH_PARAMS_KEY,
} from 'components/RedirectToLanding';
import useImperativeRequest from 'hooks/useImperativeRequest';

const deleteAccount = async (makeRequest: any): Promise<void> => {
  const { error } = await makeRequest({
    path: '/me/account',
    method: 'delete',
  });

  if (error) {
    throw new Error(error);
  }
};

export const useDeleteAccount = () => {
  const { logout } = useAuth0();
  const [, makeRequest] = useImperativeRequest('lpAccountV3');

  return useMutation({
    onSuccess: () => {
      logout({
        returnTo: `${
          window.location.origin
        }?${SEARCH_PARAMS_KEY}=${encodeURIComponent(
          SEARCH_PARAMS_DELETE_ACCOUNT
        )}`,
      });
    },
    mutationFn: () => deleteAccount(makeRequest),
  });
};
