import React from 'react';

import { useAuth0 } from '@auth0/auth0-react';
import {
  AccountIcon,
  NavigationMenu as Menu,
} from '@livingpackets/design-system-react-next';
import { PATHS } from 'configs';
import { useTranslation } from 'react-i18next';

const LoginButton = ({ open }: { open: boolean }) => {
  const { t, i18n } = useTranslation('navigation');
  const { loginWithRedirect } = useAuth0();

  const handleLogin = () => {
    loginWithRedirect({
      redirectUri: window.location.origin + PATHS.ROOT,
      screen_hint: 'login',
      ui_locales: i18n.language,
    });
  };

  return (
    <Menu.Item
      icon={AccountIcon}
      open={open}
      onClick={handleLogin}
      data-testid="side-nav-login"
    >
      {t('login')}
    </Menu.Item>
  );
};

export default LoginButton;
