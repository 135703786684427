import { useTranslation } from 'react-i18next';

import { usePostCreateCommand } from '../api/usePostCreateCommand';
import { useUpdateContractState } from '../api/useUpdateContractState';
import { boxControlDecode } from '../utils/boxControl';
import { boxInfoDecode } from '../utils/boxInfo';

const GROUP_BOX_INFO_ID = '00000401-0000-1000-8000-00805f9b34fb';
const SERVICE_ID = '00000000-0000-1000-8000-00805f9b34fb';
const GROUP_BOX_CONTROL_ID = '00000301-0000-1000-8000-00805f9b34fb';

enum BOX_FILTER_NAME {
  STM32WB = 'STM32WB',
  BOX = 'BOX',
}

export const useBoxConnector = () => {
  const { generateCommand } = usePostCreateCommand();
  const { updateContractState } = useUpdateContractState();

  const { t } = useTranslation('shipments');

  const sendData = async (characteristic: any, data: ArrayBuffer) => {
    try {
      await characteristic?.writeValueWithResponse(data);
    } catch (error) {
      console.error('Error send data', error);
    }
  };

  const launchCommand: any = async (
    device: any,
    command: ArrayBuffer,
    onDataReceive: (value: any) => void
  ) => {
    const service = await device.gatt.getPrimaryService(SERVICE_ID);

    if (!service) {
      return { error: true, data: null };
    }

    const boxCmdControlCharacteristic = await service.getCharacteristic(
      GROUP_BOX_CONTROL_ID
    );

    boxCmdControlCharacteristic.addEventListener(
      'characteristicvaluechanged',
      async (event: Event) => {
        const { value } = event.target as BluetoothRemoteGATTCharacteristic;

        await boxCmdControlCharacteristic.stopNotifications();

        const val = boxControlDecode(value);

        onDataReceive(val);
      }
    );

    await boxCmdControlCharacteristic.startNotifications();

    await sendData(boxCmdControlCharacteristic, command);
  };

  const getCommand = async (boxId: string, shipmentId: string) => {
    const bleCommand = await generateCommand(
      boxId,
      shipmentId,
      'received' //'COMMAND_TYPE_RECEIVED (V3)'
    );

    if (!bleCommand) {
      return {
        command: null,
        error: true,
      };
    }

    return { command: bleCommand };
  };

  const pacthContractState: any = async (shipmentId: string, boxId: string) => {
    const response = await updateContractState(
      shipmentId,
      'COMMAND_TYPE_RECEIVED',
      'CONTRACT_STATE_ARRIVED',
      boxId
    );

    if (!response) {
      return false;
    }

    // TODO : CHECK response for best manage
    return true;
  };

  const getBoxInfo = async (device: any, shipmentId: string) => {
    if (!device.gatt) {
      return { error: true, message: t('anonymous.error.deviceDisconnected') };
    }

    const service = await device.gatt.getPrimaryService(SERVICE_ID);

    if (service) {
      const boxInfoCharacteristic = await service.getCharacteristic(
        GROUP_BOX_INFO_ID
      );
      if (boxInfoCharacteristic) {
        const writeData = new Uint8Array([0x01]);
        await boxInfoCharacteristic.writeValue(writeData);

        const readCharacteristic = await boxInfoCharacteristic.readValue();
        if (readCharacteristic) {
          return boxInfoDecode(readCharacteristic);
        } else {
          //TODO ERROR READ CHARACTERISTIC BOX INFO
        }
      } else {
        // Error characteristic
      }
    } else {
      // error Service
    }
  };

  const connectToDevice = async (
    device: BluetoothDevice,
    timeout: number = 5000
  ) => {
    try {
      if (!device) return false;

      const gattServer = device.gatt?.connect();

      //  Create promise for resolve limit waitting connection
      const timeoutPromise = new Promise((resolve, reject) => {
        setTimeout(() => {
          reject(new Error('Timeout exceeded'));
        }, timeout);
      });

      // run promise
      const result = await Promise.race([gattServer, timeoutPromise]);

      if (result) {
        return result;
      }
    } catch (error: any) {
      console.error(error);
    }
  };

  const disconnectDevice = async (device: any) => {
    try {
      // Verify if device is connected
      if (device.gatt.connected) {
        // Disconnect device
        await device.gatt.disconnect();
      }
    } catch (error) {
      console.error('Disconnection not work :', error);
    }
  };

  const scanForDevice: any = async () => {
    try {
      const device: BluetoothDevice = await (
        navigator as any
      ).bluetooth.requestDevice({
        filters: [
          { namePrefix: BOX_FILTER_NAME.STM32WB },
          { namePrefix: BOX_FILTER_NAME.BOX },
        ],
        optionalServices: [SERVICE_ID],
      });

      return device;
    } catch (error: any) {
      if (error.name === 'NotFoundError') return { isCancel: true };
    }
  };

  return {
    getBoxInfo,
    scanForDevice,
    connectToDevice,
    disconnectDevice,
    getCommand,
    launchCommand,
    pacthContractState,
  };
};
