import React, { useCallback, useEffect, useState } from 'react';

import { Button, Tag } from '@livingpackets/design-system-react';
import { TagStateType } from '@livingpackets/design-system-react/dist/components/Tag/Tag';
import {
  ArrowRightIcon,
  Box,
  CheckOutlineIcon,
  ChevronDoubleRightIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  CloseIcon,
  DownloadDocumentIcon,
  TimeIcon,
  Text,
  styled,
  useTheme,
  Stack,
} from '@livingpackets/design-system-react-next';
import { PaginationParams } from '@livingpackets/shared-components';
import Divider from 'components/atoms/Divider';
import ReactTableTable, { TColumn } from 'components/molecules/ReactTableTable';
import { IContribution } from 'models/contribution';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Row } from 'react-table';

import TooltipCertificate from './tooltip/TooltipCertificate';
import TooltipModifyBankInformation from './tooltip/TooltipModifyBankInformation';
import imageName from '../../assets/Box_crop.png';
import { PATHS } from '../../configs';
import {
  RewardStatusEnum,
  RewardTransactionMethodEnum,
  StateEnum,
} from '../../enums/contributionEnum';
import { useMonetaryAmountConverter } from '../../helpers/currencyConverter';
import useIntlDistanceTimestamp from '../../hooks/useIntlDistanceTimestamp';
import useIntlFormatTimestamp from '../../hooks/useIntlFormatTimestamp';
import useUpdateMeContributionRewardStatus from '../../hooks/useUpdateMeContributionRewardStatus';
import ContributionErrorToFix from '../atoms/contributions/ContributionErrorToFix';
import ContributionRewardAvailable from '../atoms/contributions/ContributionRewardAvailable';
import ContributionRewardInProgress from '../atoms/contributions/ContributionRewardInProgress';
import ContributionWarningToComplete from '../atoms/contributions/ContributionWarningToComplete';
import ContributionWarningUpdateBankInformation from '../atoms/contributions/ContributionWarningUpdateBankInformation';
import DateTagSkeleton from '../atoms/loadingSkeletons/DateTagSkeleton';
import { TableDesignTypeEnum } from '../atoms/TableComponents';

const WebsiteDiv = styled('div')`
  background-color: #00bf6f;
  display: flex;
  align-items: center;
  width: 100%;
  height: 9rem;
  border-radius: 20px;
  margin-top: 1rem;
`;

const ImageDiv = styled('div')`
  width: 30%;
  height: 72%;
  margin-left: 2rem;
  background-image: url(${imageName});
  background-size: contain;
  background-repeat: no-repeat;
  align-self: flex-end;
`;

const ContributionDetailInfo = styled('div')`
  background-color: ${({ theme }) => theme.colors.black[2]};
  border-radius: 12px;
  padding: 20px;
  margin: 24px;
`;

const TagReward = styled('div')<{ isDisabled?: boolean }>`
  width: 44px;
  height: 18px;
  background-color: ${({ theme, isDisabled }) =>
    !isDisabled ? theme.colors.black[100] : theme.colors.black[30]};
  border-radius: 40px;
  padding: 2px 15px 2px 15px;
  white-space: nowrap;
`;

const REACT_APP_CONTRIBUTIONS_NUMBER =
  process.env.REACT_APP_CONTRIBUTIONS_NUMBER;

interface IPartnerContributionsTable {
  contributions: IContribution[];
  accountInformation: any;
  activeContribution?: IContribution;
  onContributionSelect: (contribution: IContribution) => void;
  pagination: PaginationParams;
  onPaginationChange: (params: Partial<PaginationParams>) => void;
  onContributionUpdate: (
    contributionId: string,
    rewardStatus: RewardStatusEnum
  ) => void;
}

const ENUM_CONTRIBUTION_STATE = {
  CONTRIBUTION_STATE_ACTIVE: <CheckOutlineIcon size="24px" />,
  CONTRIBUTION_STATE_UNSPECIFIED: <TimeIcon size="24px" />,
  CONTRIBUTION_STATE_REFUNDED: <CloseIcon size="24px" />,
};
const ENUM_CONTRIBUTION_COLOR_STATE = {
  CONTRIBUTION_STATE_ACTIVE: 'default' as const,
  CONTRIBUTION_STATE_UNSPECIFIED: 'pending' as const,
  CONTRIBUTION_STATE_REFUNDED: 'idle' as const,
};

const ContributionTable = ({
  contributions,
  accountInformation,
  activeContribution,
  onContributionSelect,
  pagination,
  onPaginationChange,
  onContributionUpdate,
}: IPartnerContributionsTable) => {
  const { t } = useTranslation(['contribution', 'general']);
  const theme = useTheme();
  const navigate = useNavigate();

  const intlFormatTimestamp = useIntlFormatTimestamp();
  const convertMoney = useMonetaryAmountConverter();
  const intlDistanceTimestamp = useIntlDistanceTimestamp();

  const { updateContributionRewardStatus } =
    useUpdateMeContributionRewardStatus();

  let url = t('general:websiteUrl.url');

  const [
    displayContributionWarningToComplete,
    setDisplayContributionWarningToComplete,
  ] = useState<boolean>(false);
  const [displayContributionErrorToFix, setDisplayContributionErrorToFix] =
    useState<boolean>(false);

  useEffect(() => {
    const contributionWarningToComplete = contributions.find(
      (contribution: IContribution) =>
        contribution.reward_status === RewardStatusEnum.AVAILABLE &&
        accountInformation?.is_completed === false
    );
    const contributionErrorToFix = contributions.find(
      (contribution: IContribution) =>
        contribution.reward_status === RewardStatusEnum.IN_FAILURE_TO_PAY
    );

    if (contributionWarningToComplete) {
      setDisplayContributionWarningToComplete(true);
    }

    if (contributionErrorToFix) {
      setDisplayContributionErrorToFix(true);
    }
  }, [contributions, accountInformation]);

  const getRewardStatusTagState = useCallback(
    (rewardStatus: RewardStatusEnum): TagStateType => {
      switch (rewardStatus) {
        case RewardStatusEnum.NOT_AVAILABLE:
          return 'idle';
        case RewardStatusEnum.AVAILABLE:
          return accountInformation?.is_completed ? 'default' : 'warning';
        case RewardStatusEnum.IN_PROGRESS:
          return 'pending';
        case RewardStatusEnum.IN_FAILURE_TO_PAY:
          return accountInformation?.is_completed ? 'warning' : 'error';
        default:
          return 'default';
      }
    },
    [accountInformation]
  );

  const getRewardStatusText = useCallback(
    (
      rewardStatus: RewardStatusEnum,
      state: StateEnum,
      isCompleted?: boolean
    ): string => {
      let textRewardStatus = `contribution:reward_status.${rewardStatus}`;

      if (state === StateEnum.REFUNDED) {
        textRewardStatus = `contribution:state.CONTRIBUTION_STATE_NOT_AVAILABLE`;
      }

      // The transaction is not ready because bank account or billing address are missing
      if (
        isCompleted === false &&
        rewardStatus === RewardStatusEnum.AVAILABLE
      ) {
        textRewardStatus = textRewardStatus + '_TO_COMPLETE';
      }

      // The payment is failed but the user change the bank account
      if (
        isCompleted === true &&
        rewardStatus === RewardStatusEnum.IN_FAILURE_TO_PAY
      ) {
        textRewardStatus = textRewardStatus + '_START_OVER';
      }

      return String(
        t(textRewardStatus as unknown as TemplateStringsArray)
      ).toUpperCase();
    },
    [t]
  );

  const handleConfirmPayout = useCallback(
    (contributionId: string) => {
      updateContributionRewardStatus(
        contributionId,
        RewardStatusEnum.IN_PROGRESS
      ).then(({ success, data }: { success: boolean; data?: any }) => {
        onContributionUpdate(contributionId, data.reward_status);
      });
    },
    [updateContributionRewardStatus, onContributionUpdate]
  );

  const columns: TColumn<IContribution>[] = [
    {
      Header: t(
        'contribution:contributionsTab.contributionsList.header.contributionDate'
      ),
      accessor: 'contributed_at',
      headerEmphasis: false,
      Cell: ({ row }: { row: Row<IContribution> }) => (
        <Text
          variant="titleXS"
          sx={{
            marginLeft: '15px',
            color:
              row.original.state === StateEnum.REFUNDED
                ? theme.colors.black[50]
                : '',
          }}
        >
          {intlFormatTimestamp(row.original.contributed_at, {
            day: 'numeric',
            month: 'short',
            year: 'numeric',
            hour: undefined,
            minute: undefined,
          })}
        </Text>
      ),
      sortable: false,
      skeleton: (
        <Stack direction="row">
          <DateTagSkeleton />
        </Stack>
      ),
      dataTestId: 'contributions-header-contributedDate',
    },
    {
      Header: t(
        'contribution:contributionsTab.contributionsList.header.contributionState'
      ),
      accessor: 'state',
      displayHasText: false,
      Cell: ({ row }) =>
        row.original.state && (
          <Tag
            state={ENUM_CONTRIBUTION_COLOR_STATE[row.original.state]}
            text=""
            icon={
              <Box
                display="flex"
                justifyContent="center"
                width="48px"
                height="28px"
              >
                {ENUM_CONTRIBUTION_STATE[row.original.state]}
              </Box>
            }
          />
        ),
      dataTestId: 'contributions-header-rewardState',
    },
    {
      Header: t(
        'contribution:contributionsTab.contributionsList.header.initialAmount'
      ),
      accessor: 'payment_amount',
      Cell: ({ row }: { row: Row<IContribution> }) => (
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Text
            variant="titleS"
            style={{
              color:
                row.original.state === StateEnum.REFUNDED
                  ? theme.colors.black[50]
                  : '',
            }}
          >
            {convertMoney(row.original.payment_amount)}
          </Text>
          <ChevronDoubleRightIcon
            color={
              row.original.state === StateEnum.REFUNDED
                ? theme.colors.black[50]
                : ''
            }
            style={{ marginRight: '15px' }}
          />
        </Stack>
      ),
      dataTestId: 'contributions-header-paymentAmount',
    },
    {
      Header: t(
        'contribution:contributionsTab.contributionsList.header.rewardDate'
      ),
      accessor: 'rewarded_at',
      Cell: ({ row }: { row: Row<IContribution> }) => (
        <Text
          variant="titleS"
          style={{
            color:
              row.original.state === StateEnum.REFUNDED
                ? theme.colors.black[50]
                : '',
          }}
        >
          {row.original.reward_status === RewardStatusEnum.PAID_OUT
            ? intlFormatTimestamp(row.original?.rewarded_at, {
                day: 'numeric',
                month: 'short',
                year: 'numeric',
                hour: undefined,
                minute: undefined,
              })
            : row.original.reward_estimated_date
            ? t(
                'contribution:contributionsTab.contributionsList.body.rewardEstimatedDate',
                {
                  year: intlFormatTimestamp(
                    row.original.reward_estimated_date,
                    {
                      day: undefined,
                      month: undefined,
                      year: 'numeric',
                      hour: undefined,
                      minute: undefined,
                    }
                  ),
                }
              )
            : '-'}
        </Text>
      ),
      sortable: false,
      dataTestId: 'contributions-header-rewardDate',
    },
    {
      Header: t(
        'contribution:contributionsTab.contributionsList.header.rewardState'
      ),
      accessor: 'reward_status',
      Cell: ({ row }: { row: Row<IContribution> }) => (
        <>
          <Box
            style={{ minWidth: '100px', justifyContent: 'center' }}
            data-tooltip-id={
              row.original.reward_status === RewardStatusEnum.IN_FAILURE_TO_PAY
                ? 'modify-bank-information-tooltip'
                : undefined
            }
          >
            <Tag
              state={getRewardStatusTagState(row.original.reward_status)}
              text={getRewardStatusText(
                row.original.reward_status,
                row.original.state,
                accountInformation?.is_completed
              )}
            />
          </Box>
          {row.original.reward_status ===
            RewardStatusEnum.IN_FAILURE_TO_PAY && (
            <TooltipModifyBankInformation />
          )}
        </>
      ),
      dataTestId: 'contributions-header-rewardState',
    },
    {
      Header: t(
        'contribution:contributionsTab.contributionsList.header.rewardAmount'
      ),
      accessor: 'reward_amount',
      Cell: ({ row }: { row: Row<IContribution> }) => (
        <Stack direction="row" alignItems="center" gap="0.5rem">
          <TagReward isDisabled={row.original.state === StateEnum.REFUNDED}>
            <Text variant="titleXS" color="white">
              {t(
                'contribution:contributionsTab.contributionsList.info.rewardDate',
                {
                  rewardRate: row.original.reward_rate,
                }
              )}
            </Text>
          </TagReward>
          <Text variant="titleXS">
            {row.original.reward_amount
              ? convertMoney(row.original.reward_amount)
              : ''}
          </Text>
        </Stack>
      ),
      dataTestId: 'contributions-header-rewardAmount',
    },
    {
      Header: '',
      id: 'createdAtDivider',
      Cell: () => (
        <Divider
          marginRight="10px"
          direction="vertical"
          borderColor={theme.colors.black[10]}
          height="52px"
          width="1px"
        />
      ),
      width: '25px',
    },
    {
      Header: t(
        'contribution:contributionsTab.contributionsList.header.certificate'
      ),
      Cell: ({ row }: { row: any }) => (
        <Stack direction="row" justifyContent="space-between" marginTop="5px">
          <Box marginLeft="18px">
            {row.original.reward_status === RewardStatusEnum.PAID_OUT && (
              <>
                <Box data-tooltip-id="certificate-tooltip">
                  <DownloadDocumentIcon color={theme.colors.primary[100]} />
                </Box>
                <TooltipCertificate />
              </>
            )}
          </Box>

          {row.original.state === StateEnum.ACTIVE && (
            <span {...row.getToggleRowExpandedProps()}>
              {row.isExpanded ? <ChevronUpIcon /> : <ChevronDownIcon />}
            </span>
          )}
        </Stack>
      ),
      width: '100px',
      dataTestId: 'contributions-header-certificate',
    },
    {
      Header: '',
      accessor: 'payment_id',
    },
    {
      Header: '',
      accessor: 'type',
    },
    {
      Header: '',
      accessor: 'reward_rate',
    },
    {
      Header: '',
      accessor: 'reward_estimated_date',
    },
    {
      Header: '',
      accessor: 'payment_method',
    },
    {
      Header: '',
      accessor: 'reward_receipt_id',
    },
    {
      Header: '',
      accessor: 'id',
    },
    {
      Header: '',
      accessor: 'reward_transaction_method',
    },
  ];

  const renderRowSubComponent = React.useCallback(
    ({ row }: { row: any }) => (
      <>
        <ContributionDetailInfo>
          <Stack
            direction="row"
            justifyContent="space-between"
            sx={{ gap: '60px' }}
          >
            <Stack>
              <Text variant="titleXS" color="custom.neutral.black.50">
                {t(
                  'contribution:contributionsTab.contributionsList.detail.type'
                )}
              </Text>
              <Text variant="bodyTextS" color="custom.neutral.black.100">
                {t(
                  `contribution:type.${row.values.type}` as unknown as TemplateStringsArray
                )}
              </Text>
            </Stack>
            <Stack>
              <Stack
                direction="row"
                justifyContent="space-between"
                style={{ flex: 'auto', gap: '25px' }}
              >
                <Stack style={{ flex: '1 1 0' }}>
                  <Text
                    variant="titleXS"
                    color="custom.neutral.black.50"
                    style={{ whiteSpace: 'nowrap' }}
                  >
                    {t(
                      'contribution:contributionsTab.contributionsList.detail.paymentId'
                    )}
                  </Text>
                  <Text variant="bodyTextS" color="custom.neutral.black.100">
                    {row.values.payment_id}
                  </Text>
                </Stack>
                <Stack style={{ flex: '1 1 0' }}>
                  <Text
                    variant="titleXS"
                    color="custom.neutral.black.50"
                    style={{ whiteSpace: 'nowrap' }}
                  >
                    {t(
                      'contribution:contributionsTab.contributionsList.detail.paymentMethod'
                    )}
                  </Text>
                  <Text variant="bodyTextS" color="custom.neutral.black.100">
                    {t(
                      `contribution:payment_method.${row.values.payment_method}` as unknown as TemplateStringsArray
                    )}
                  </Text>
                </Stack>
                <Stack style={{ flex: '1 1 0' }}>
                  <Text
                    variant="titleXS"
                    color="custom.neutral.black.50"
                    style={{ whiteSpace: 'nowrap' }}
                  >
                    {t(
                      'contribution:contributionsTab.contributionsList.detail.contract'
                    )}
                  </Text>
                  <Stack style={{ minWidth: '375px' }}>
                    <Text variant="bodyTextS" color="custom.neutral.black.100">
                      {t(
                        'contribution:contributionsTab.contributionsList.detail.contractInfo',
                        {
                          rewardRate: row.values.reward_rate,
                          rewardEstimatedYear: intlDistanceTimestamp(
                            row.values.reward_estimated_date,
                            row.values.contributed_at
                          ),
                        }
                      )}
                    </Text>
                    <Text variant="bodyTextS" color="primary.120">
                      {t(
                        'contribution:contributionsTab.contributionsList.detail.contractLink'
                      )}
                    </Text>
                  </Stack>
                </Stack>
              </Stack>
              <Stack
                direction="row"
                justifyContent="space-between"
                style={{ flex: 'auto', gap: '25px' }}
                marginTop="20px !important"
              >
                {row.values.reward_receipt_id && (
                  <Stack style={{ flex: '1 1 0' }}>
                    <Text variant="titleXS" color="custom.neutral.black.50">
                      {t(
                        'contribution:contributionsTab.contributionsList.detail.rewardId'
                      )}
                    </Text>
                    <Text variant="bodyTextS" color="custom.neutral.black.100">
                      {row.values.reward_receipt_id}
                    </Text>
                  </Stack>
                )}
                {row.values.reward_transaction_method &&
                  ![
                    RewardTransactionMethodEnum.TRANSACTION_METHOD_TYPE_UNSPECIFIED,
                  ].includes(row.values.reward_transaction_method) && (
                    <Stack style={{ flex: '1 1 0' }}>
                      <Text variant="titleXS" color="custom.neutral.black.50">
                        {t(
                          'contribution:contributionsTab.contributionsList.detail.rewardMethod'
                        )}
                      </Text>
                      <Text
                        variant="bodyTextS"
                        color="custom.neutral.black.100"
                      >
                        {t(
                          `contribution:reward_transaction_method.${row.values.reward_transaction_method}` as unknown as TemplateStringsArray
                        )}
                      </Text>
                    </Stack>
                  )}
                {row.values.rewarded_at && (
                  <Stack style={{ flex: '1 1 0', minWidth: '375px' }}>
                    <Text variant="titleXS" color="custom.neutral.black.50">
                      {t(
                        'contribution:contributionsTab.contributionsList.detail.rewardDatePayment'
                      )}
                    </Text>
                    <Text variant="bodyTextS" color="custom.neutral.black.100">
                      {intlFormatTimestamp(row.values.rewarded_at, {
                        day: 'numeric',
                        month: 'short',
                        year: 'numeric',
                        hour: undefined,
                        minute: undefined,
                      })}
                    </Text>
                  </Stack>
                )}
              </Stack>
            </Stack>
          </Stack>
        </ContributionDetailInfo>
        {row.values.reward_status === RewardStatusEnum.IN_PROGRESS && (
          <Box margin="0 24px 24px 24px">
            <ContributionRewardInProgress />
          </Box>
        )}

        {(row.values.reward_status === RewardStatusEnum.AVAILABLE ||
          row.values.reward_status === RewardStatusEnum.IN_FAILURE_TO_PAY) && (
          <ContributionRewardAvailable
            onClickConfirmPayout={() => handleConfirmPayout(row.values.id)}
            isButtonDisabled={accountInformation?.is_completed === false}
            isFailed={
              row.values.reward_status === RewardStatusEnum.IN_FAILURE_TO_PAY
            }
          />
        )}
      </>
    ),
    [
      t,
      intlDistanceTimestamp,
      intlFormatTimestamp,
      handleConfirmPayout,
      accountInformation,
    ]
  );

  const handleSortingChange = useCallback(
    ({ order, sortBy }: { sortBy: string; order: 'ASC' | 'DESC' }): void =>
      onPaginationChange({ orderColumn: sortBy, orderDirection: order }),
    [onPaginationChange]
  );

  const handleRedirectToAccountInformationPage = useCallback((): void => {
    navigate(PATHS.ACCOUNT.INFORMATION);
  }, [navigate]);

  return (
    <>
      <Stack style={{ rowGap: '10px' }}>
        {displayContributionWarningToComplete &&
        displayContributionErrorToFix ? (
          <ContributionWarningUpdateBankInformation
            onClickEdtAccountInformation={() =>
              handleRedirectToAccountInformationPage()
            }
          />
        ) : displayContributionWarningToComplete ? (
          <ContributionWarningToComplete
            onClickEdtAccountInformation={() =>
              handleRedirectToAccountInformationPage()
            }
          />
        ) : (
          displayContributionErrorToFix && (
            <ContributionErrorToFix
              isWarningMode={accountInformation?.is_completed}
              onClickEdtAccountInformation={() =>
                handleRedirectToAccountInformationPage()
              }
            />
          )
        )}
      </Stack>
      <Box minHeight="100px" marginTop="40px">
        <ReactTableTable<IContribution>
          onRowClick={onContributionSelect}
          columns={columns}
          activeRowId={activeContribution?.payment_id}
          data={contributions}
          sorting={{
            sortBy: pagination.orderColumn,
            order: pagination.orderDirection,
          }}
          onSortingChange={handleSortingChange}
          tableDesignType={TableDesignTypeEnum.contributionListTable}
          renderRowSubComponent={renderRowSubComponent}
          hiddenColumns={[
            'payment_id',
            'type',
            'reward_rate',
            'reward_estimated_date',
            'payment_method',
            'reward_receipt_id',
            'id',
            'reward_transaction_method',
          ]}
        />
      </Box>
      {contributions.length < Number(REACT_APP_CONTRIBUTIONS_NUMBER) && (
        <WebsiteDiv>
          <ImageDiv />
          <Text
            variant="titleM"
            color="white"
            style={{
              marginRight: '4rem',
              width: '28%',
              lineHeight: '1.5rem',
            }}
          >
            {t('contribution:contributionsTab.websiteLink.discover')}
          </Text>
          <Button
            state="default"
            action="secondary"
            icon={<ArrowRightIcon />}
            onClick={() => window.open(url, '_blank', 'noopener noreferrer')}
            style={{
              padding: '1.5rem 1rem 1.5rem 1rem',
              borderRadius: '14px',
            }}
          >
            <Text color="primary.100">
              {t('contribution:contributionsTab.websiteLink.redirectToWebsite')}
            </Text>
          </Button>
        </WebsiteDiv>
      )}
    </>
  );
};

export default ContributionTable;
