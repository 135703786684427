import React, { useCallback, useState } from 'react';

import { DropdownMenu } from '@livingpackets/design-system-react';
import {
  EmptyState,
  AddIcon,
  UserProfileIcon,
  ButtonV2,
  Text,
  ArrowIcon,
} from '@livingpackets/design-system-react-next';
import { Box } from 'components/atoms/Box';
import AddAddressToContactModal from 'components/molecules/modals/AddAddressToContactModal';
import { PATHS } from 'configs';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate } from 'react-router-dom';
import useMyPartnersStore, {
  activePartnerSelector,
} from 'stores/useMyPartnersStore';
import styled from 'styled-components';

import useGetPartnerContacts from '../../../hooks/useGetPartnerContacts';
import Row from '../../atoms/Row';
import PartnerContactsTable from '../../organisms/PartnerContactsTable';
import Title from '../Title';

const { CONTACT_ADDRESS } = PATHS;

const StyledRow = styled(Row)`
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 1.5rem;
`;

const PartnerContactAddressOverview = () => {
  const navigate = useNavigate();

  const activePartner = useMyPartnersStore(activePartnerSelector);

  const [isSearchMustBeCleared, setIsSearchMustBeCleared] = useState<number>(0);

  const { loading, onSortingChange, onPaginationChange, total, searchTerm } =
    useGetPartnerContacts();

  const [showAddAddressToContactModal, setShowAddAddressToContactModal] =
    useState<boolean>(false);

  const { t } = useTranslation(['general', 'contactAddress']);

  const redirectToCreateContactPage = useCallback(() => {
    navigate(
      generatePath(PATHS.CONTACT_ADDRESS.CREATE, {
        partnerId: activePartner.id,
      })
    );
  }, [navigate, activePartner]);

  const handleSelectContact = useCallback(
    (contactId: string) => {
      let contactAddressEditUrl = generatePath(CONTACT_ADDRESS.EDIT, {
        partnerId: activePartner.id,
        contactId: contactId,
      });
      navigate(contactAddressEditUrl, {
        state: { displayAddAddressFormOnPageLoad: true },
      });
    },
    [navigate, activePartner]
  );

  return (
    <>
      <Title pageName={t('contactAddress:pageListTitle')} />
      <StyledRow>
        {(total !== 0 || searchTerm) && (
          <Box style={{ zIndex: 10 }}>
            <DropdownMenu label={t('contactAddress:list.dropdown.addAddress')}>
              <React.Fragment key=".0">
                <DropdownMenu.Item
                  icon={<AddIcon size="1.5rem" />}
                  onClick={redirectToCreateContactPage}
                >
                  {t('contactAddress:list.dropdown.newContact')}
                </DropdownMenu.Item>
                <DropdownMenu.Item
                  icon={<UserProfileIcon size="1.5rem" />}
                  onClick={() => setShowAddAddressToContactModal(true)}
                >
                  {t('contactAddress:list.dropdown.existingContact')}
                </DropdownMenu.Item>
              </React.Fragment>
            </DropdownMenu>
          </Box>
        )}
      </StyledRow>
      <PartnerContactsTable
        loading={loading}
        onSortingChange={onSortingChange}
        onPaginationChange={onPaginationChange}
        actionIsSearchMustBeCleared={isSearchMustBeCleared}
      />
      {total === 0 && (
        <Box>
          {!searchTerm ? (
            <EmptyState type="Addresses">
              <Text variant="titleM" color="custom.neutral.black.50">
                {t('contactAddress:list.emptyState.emptyMessage')}
              </Text>
              <Text variant="bodyTextL" color="custom.neutral.black.50">
                {t('contactAddress:list.emptyState.emptySubMessage')}
              </Text>
              <ButtonV2
                icon={ArrowIcon}
                sx={{ marginTop: '1.625rem' }}
                data-testid="partner-contact-address-redirect-create-contact-button"
                onClick={() => redirectToCreateContactPage()}
              >
                {t('contactAddress:list.emptyState.cta')}
              </ButtonV2>
            </EmptyState>
          ) : (
            <EmptyState type="Addresses">
              <Text variant="titleM" color="custom.neutral.black.50">
                {t('contactAddress:list.noResult.emptyMessage')}
              </Text>
              <Text variant="bodyTextL" color="custom.neutral.black.50">
                {t('contactAddress:list.noResult.emptySubMessage')}
              </Text>
              <ButtonV2
                icon={ArrowIcon}
                sx={{ marginTop: '1.625rem' }}
                data-testid="partner-contact-address-clear-search-button"
                onClick={() =>
                  setIsSearchMustBeCleared(isSearchMustBeCleared + 1)
                }
              >
                {t('contactAddress:list.noResult.cta')}
              </ButtonV2>
            </EmptyState>
          )}
        </Box>
      )}
      <AddAddressToContactModal
        visible={showAddAddressToContactModal}
        onContactSelect={handleSelectContact}
        onDismiss={() => setShowAddAddressToContactModal(false)}
      />
    </>
  );
};

export default PartnerContactAddressOverview;
