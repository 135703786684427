import React, {
  useState,
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useEffect,
} from 'react';

import {
  CalendarIcon,
  NewTextField as TextField,
  Text,
  NewTooltip as Tooltip,
  styled,
  Stack,
  ArrowRightIcon,
  TimeIcon,
  Checkbox,
  ButtonV2 as Button,
  CloseIcon,
} from '@livingpackets/design-system-react-next';
import i18next from 'i18next';
import {
  Control,
  Controller,
  FieldValues,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';

const CustomCalendarIcon = styled(CalendarIcon)`
  width: 1.5rem;
  height: 1.5rem;
`;

const ArrowRightIconContainer = styled(Stack)`
  width: 1.5rem;
  height: 1.5rem;
  margin-top: auto;
  margin-top: 0.875rem;
`;

const TimeIconContainer = styled(Stack)`
  width: 1.5rem;
  height: 1.5rem;
  margin-top: 0.875rem;
`;

const CustomTooltip = styled(Tooltip)`
  &.MuiTooltip-popper {
    .MuiTooltip-tooltip {
      max-width: 23.25rem;
      min-height: 18.125rem;
      padding: 0.875rem 1.5rem 0.875rem 1.5rem;
      border-radius: 0.875rem;
    }
  }
`;

interface DateTimeFilterProps {
  control: Control<FieldValues>;
  setValue: UseFormSetValue<FieldValues>;
  onSubmit: any;
  onCancel: () => void;
  isValid: boolean;
  watch: UseFormWatch<FieldValues>;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  trigger: any;
}

export const DateTimeFilter = ({
  control,
  setValue,
  onSubmit,
  onCancel,
  isValid,
  watch,
  open,
  setOpen,
  trigger,
}: DateTimeFilterProps) => {
  const { t } = useTranslation('shipments');
  const { language } = i18next;

  const { date, startTime, endTime } = watch();
  const hasFilters = date || startTime || endTime;

  // Hold `AllDay` checkbox state
  const [allDay, setAllDay] = useState<boolean>(
    '00:00' === startTime && '23:59' === endTime
  );

  // Fill the startTime and endTime inputs with beginning and end time of the day
  // whenever the `AllDay` checkbox is checked
  useEffect(() => {
    if (allDay) {
      setValue('startTime', '00:00', {
        shouldValidate: true,
      });
      setValue('endTime', '23:59', {
        shouldValidate: true,
      });
    }
  }, [allDay, language, setValue]);

  // Disables the `AllDay` checkbox when the startTime and endTime inputs are changed
  const listenerOnChangeInputs = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    onChange: any
  ) => {
    onChange(e);
    setAllDay(false);
  };

  const handleCancel = () => {
    onCancel();
    setAllDay(false);
    setOpen(false);
  };

  return (
    <CustomTooltip
      placement="bottom-end"
      open={open}
      title={
        <>
          <Text
            variant="titleS"
            color="custom.neutral.black.50"
            marginBottom="1.5rem"
          >
            {t('dateTimeFilter.dates')}
          </Text>
          <Stack gap=".875rem" marginBottom=".25rem">
            <Stack direction="row" alignItems="center" gap=".5rem">
              <CustomCalendarIcon />
              <Controller
                name="date"
                control={control}
                render={({ field, fieldState }) => (
                  <TextField
                    required
                    label={t('dateTimeFilter.day')}
                    placeholder={t('dateTimeFilter.placeholderDate')}
                    error={fieldState.invalid}
                    helperText={fieldState.error?.message}
                    {...field}
                  />
                )}
              />
            </Stack>
            <Stack direction="row" alignItems="flex-start" gap=".5rem">
              <TimeIconContainer>
                <TimeIcon style={{ width: '1.5rem', height: '1.5rem' }} />
              </TimeIconContainer>
              <Controller
                name="startTime"
                control={control}
                render={({
                  field: { onBlur, onChange, ...rest },
                  fieldState,
                }) => (
                  <TextField
                    required
                    label={t('dateTimeFilter.from')}
                    placeholder="hh:mm"
                    error={fieldState.invalid}
                    helperText={fieldState.error?.message}
                    onChange={e => {
                      listenerOnChangeInputs(e, onChange);
                    }}
                    onBlur={() => {
                      onBlur();
                      // Trigger the validation of endTime (endTime > startTime)
                      if (endTime) trigger('endTime');
                    }}
                    {...rest}
                  />
                )}
              />
              <ArrowRightIconContainer>
                <ArrowRightIcon />
              </ArrowRightIconContainer>
              <Controller
                name="endTime"
                control={control}
                render={({
                  field: { onBlur, onChange, ...rest },
                  fieldState,
                }) => (
                  <TextField
                    required
                    label={t('dateTimeFilter.to')}
                    placeholder="hh:mm"
                    error={fieldState.invalid}
                    helperText={fieldState.error?.message}
                    onChange={e => {
                      listenerOnChangeInputs(e, onChange);
                    }}
                    onBlur={() => {
                      onBlur();
                      // Trigger the validation of startTime (startTime < endTime)
                      if (startTime) trigger('startTime');
                    }}
                    {...rest}
                  />
                )}
              />
            </Stack>
          </Stack>
          <Stack>
            <Checkbox
              label={t('dateTimeFilter.allDay')}
              checked={allDay}
              onChange={e => setAllDay(e.target.checked)}
            />
          </Stack>
          <Stack direction="row" justifyContent="flex-end" gap=".875rem">
            <Button variant="tertiary" onClick={handleCancel}>
              {t('dateTimeFilter.cancel')}
            </Button>
            <Button disabled={!isValid} onClick={onSubmit}>
              {t('dateTimeFilter.apply')}
            </Button>
          </Stack>
        </>
      }
    >
      <Stack
        flexDirection="row"
        alignItems="center"
        justify-content="space-between"
        gap=".5rem"
        padding={hasFilters ? '.5rem 0.875rem' : '.5rem 1.5rem'}
        width="100%"
        height="2.25rem"
        borderRadius=".5rem"
        border=".0625rem solid"
        borderColor={isValid ? 'custom.primary.140' : 'custom.neutral.black.8'}
        boxShadow={!open ? '0 .25rem 1rem 0 #00000014' : 'none'}
        color={open ? 'custom.neutral.black.50' : 'custom.neutral.black.100'}
        sx={{
          cursor: 'pointer',
          backgroundColor: open
            ? 'custom.neutral.black.4'
            : 'custom.neutral.white.pure',
        }}
        onClick={() => setOpen((prev: any) => !prev)}
      >
        <CalendarIcon size="1.25rem" />
        <Text variant="titleS"> {t('dateTimeFilter.dates')}</Text>
        {hasFilters && (
          <CloseIcon
            size=".875rem"
            onClick={e => {
              e.stopPropagation();
              handleCancel();
            }}
          />
        )}
      </Stack>
    </CustomTooltip>
  );
};
