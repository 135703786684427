import React, { useCallback, useState } from 'react';

const MouseTooltip = ({
  children,
  visible = true,
  offsetX = 0,
  offsetY = 0,
}: {
  children: React.ReactElement | React.ReactElement[] | string;
  visible: boolean;
  offsetX?: number;
  offsetY?: number;
}) => {
  const [xPosition, setXPosition] = useState<number>(0);
  const [yPosition, setYPosition] = useState<number>(0);
  const [mouseMoved, setMouseMoved] = useState<boolean>(false);
  const [listenerActive, setListenerActive] = useState<boolean>(false);

  const getTooltipPosition = useCallback((event: any) => {
    setXPosition(event.clientX);
    setYPosition(event.clientY);
    setMouseMoved(true);
  }, []);

  const addListener = useCallback(() => {
    window.addEventListener('mousemove', getTooltipPosition);
    setListenerActive(true);
  }, [getTooltipPosition]);

  const removeListener = useCallback(() => {
    window.removeEventListener('mousemove', getTooltipPosition);
    setListenerActive(false);
  }, [getTooltipPosition]);

  const updateListener = useCallback(() => {
    if (!listenerActive && visible) {
      addListener();
    }

    if (listenerActive && !visible) {
      removeListener();
    }
  }, [addListener, listenerActive, removeListener, visible]);

  React.useEffect(() => {
    updateListener();
  }, [updateListener]);

  return (
    <div
      style={{
        display: visible && mouseMoved ? 'block' : 'none',
        position: 'fixed',
        top: yPosition + offsetY,
        left: xPosition + offsetX,
        zIndex: 2,
      }}
    >
      {children}
    </div>
  );
};

export default MouseTooltip;
