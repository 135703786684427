import React, { useEffect, useRef, useState } from 'react';

import { Button } from '@livingpackets/design-system-react';
import { CropIcon, Stack, Text } from '@livingpackets/design-system-react-next';
import { getImageOrientation } from 'helpers/pdfHelper';
import useToastMessages from 'hooks/useToastMessages';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const WrapperImgContainer = styled.div<{
  displayInTabletImg: boolean;
  imageOrientation: string;
}>`
  ${props =>
    !props.displayInTabletImg
      ? `
    position: relative;
    height: inherit;
`
      : props.imageOrientation === 'landscape'
      ? `
        width: 100%;
        height: 100%;
`
      : `
  width: 216px;
  height: 288px;
  margin-left: auto;
  margin-right: auto;
`};
`;

const WrapperImg = styled.img<{
  displayInTabletImg: boolean;
  imageOrientation: string;
}>`
  object-fit: contain;
  width: ${({ displayInTabletImg }) =>
    displayInTabletImg ? '100%' : '22.5rem'};
  height: 100%;
  align-self: center;
  display: block;
  margin-left: ${({ displayInTabletImg }) =>
    displayInTabletImg ? 'initial' : 'auto'};
  margin-right: ${({ displayInTabletImg }) =>
    displayInTabletImg ? 'initial' : 'auto'};

  ${props =>
    props.imageOrientation === 'portrait' &&
    `
      transform: rotate(-90deg);
      margin-top: -36px;
    `};
`;

const CropOverlay = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0;
  transition: opacity 100ms ease-out;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 27%;
  margin-right: 27%;

  &:hover {
    transition: opacity 100ms ease-in;
    opacity: 1;
  }
`;

interface ShipmentLabelImageProps {
  labelUrl: string;
  enableManualCrop?: boolean;
  onManualCropStarted: () => void;
  displayInTabletImg?: boolean;
}

const ShipmentLabelImage = ({
  labelUrl,
  enableManualCrop = false,
  onManualCropStarted,
  displayInTabletImg = false,
}: ShipmentLabelImageProps) => {
  const { t } = useTranslation('shipments');
  const imageRef = useRef<HTMLImageElement>(null);
  const { info } = useToastMessages();
  useEffect(() => {
    if (!enableManualCrop) return;
  }, [enableManualCrop, info, t]);

  const [imageOrientation, setImageOrientation] = useState<string>();

  useEffect(() => {
    getImageOrientation(labelUrl)
      .then((imageOrientation: string) => {
        setImageOrientation(imageOrientation);
      })
      .catch(error => {
        setImageOrientation('portrait');
      });
  }, [labelUrl]);

  return imageOrientation ? (
    <WrapperImgContainer
      displayInTabletImg={displayInTabletImg}
      imageOrientation={imageOrientation}
    >
      <WrapperImg
        alt=""
        ref={imageRef}
        data-testid="label-image"
        src={labelUrl}
        displayInTabletImg={displayInTabletImg}
        imageOrientation={imageOrientation}
      />
      {enableManualCrop && (
        <CropOverlay>
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={3}
            onClick={onManualCropStarted}
            style={{ cursor: 'pointer' }}
            height="100%"
          >
            <Button
              aria-labelledby="crop-description"
              action="primary"
              isIconOnly={true}
              icon={<CropIcon />}
            />
            <Stack id="crop-description">
              <Text variant="titleS">{t('manualCrop.button.title')}</Text>
              <Text variant="bodyTextS">
                {t('manualCrop.button.description')}
              </Text>
            </Stack>
          </Stack>
        </CropOverlay>
      )}
    </WrapperImgContainer>
  ) : (
    <></>
  );
};

export default ShipmentLabelImage;
