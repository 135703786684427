import React from 'react';

import { Text } from '@livingpackets/design-system-react-next';
import Row from 'components/atoms/Row';
import { useTranslation } from 'react-i18next';

import useSocialMediaArticles from '../../../hooks/useSocialMediaArticles';
import useSocialMediaArticlesStore, {
  smArticleSelector,
} from '../../../stores/useSocialMediaArticlesStore';
import SocialMediaCard from '../../molecules/dashboard/SocialMediaCard';

const SocialMediaCardList = () => {
  const { t } = useTranslation('dashboard');
  const { loading } = useSocialMediaArticles();
  const smArticles = useSocialMediaArticlesStore(smArticleSelector);

  return (
    <Row marginTop="3rem" width="53%" flexDirection="column">
      <Row
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '95%',
        }}
      >
        <Text variant="titleM" mb="1rem">
          {t('socialMediaList.title')}
        </Text>
        <Text color="custom.neutral.black.50" mb="1rem">
          {t('socialMediaList.published')}
        </Text>
      </Row>
      {!loading &&
        smArticles.map((article, key) => (
          <SocialMediaCard key={key} article={article} />
        ))}
    </Row>
  );
};
export default SocialMediaCardList;
