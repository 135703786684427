import React, { Suspense } from 'react';

import { Layout } from '@livingpackets/design-system-react-next';
import useAppState, {
  anonymousTokenSelector,
  userSelector,
} from 'stores/appState/useAppState';

import MobileHeader from './components/MobileHeader';
import NavigationMenu from './components/sidebar/NavigationMenu';

const AppLayout = ({ children }: { children: React.ReactNode }) => {
  const appUser = useAppState(userSelector);
  const anonymousToken = useAppState(anonymousTokenSelector);

  if (!appUser && !anonymousToken) {
    return <>{children}</>;
  }

  return (
    <Layout navBar={<NavigationMenu />} mobileHeader={<MobileHeader />}>
      <Suspense>{children}</Suspense>
    </Layout>
  );
};

export default AppLayout;
