import React, { useEffect, useState } from 'react';

import { Button } from '@livingpackets/design-system-react';
import { DetailsSpan } from 'components/atoms/ShipmentAtoms';
import ViewShipmentLabelModal from 'components/molecules/modals/ViewShipmentLabelModal';
import ShipmentIdData from 'components/molecules/ShipmentIdData';
import { IShipment } from 'models/shipment';
import { useTranslation } from 'react-i18next';
import useAppState, { tokenSelector } from 'stores/appState/useAppState';
import styled from 'styled-components';
import { space, SpaceProps } from 'styled-system';

const Wrapper = styled.div<SpaceProps>`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  ${space}
`;

interface IShipmentInformation {
  shipment: IShipment;
}

const ShipmentInformation = ({
  shipment,
  ...rest
}: IShipmentInformation & SpaceProps) => {
  const { t } = useTranslation('shipments');
  const appToken = useAppState(tokenSelector);

  const isReturn = !!shipment.original_shipment;
  const hasReturn = !!shipment.return_shipment;

  const [labelImageBase64, setLabelImageBase64] = useState<string | null>(null);

  const [
    showShipmentLabelFullScreenModal,
    setShowShipmentLabelFullScreenModal,
  ] = useState<boolean>(false);

  useEffect(() => {
    if (shipment.label_url) {
      fetch(shipment.label_url, {
        method: 'GET',
        headers: { Authorization: `Bearer ${appToken}` },
      })
        .then((response: Response) => response.blob())
        .then((blob: Blob) => {
          let reader = new FileReader();
          reader.readAsDataURL(blob);
          reader.onloadend = function () {
            if (typeof reader.result === 'string') {
              setLabelImageBase64(reader.result);
            }
          };
        });
    }
  }, [shipment, appToken]);

  return (
    <Wrapper {...rest}>
      <DetailsSpan data-testid="current-card-headline">
        {t(
          isReturn
            ? 'returnShipment'
            : hasReturn
            ? 'initialShipment'
            : 'shipmentInfo'
        )}
      </DetailsSpan>
      <ShipmentIdData shipment={shipment} />
      <Button
        onClick={() => setShowShipmentLabelFullScreenModal(true)}
        action="secondary"
        style={{
          width: '100%',
          marginTop: '16px',
          justifyContent: 'center',
        }}
        disabled={!labelImageBase64}
      >
        {t('detail.summary.information.showLabelButton')}
      </Button>
      {labelImageBase64 && (
        <ViewShipmentLabelModal
          visible={showShipmentLabelFullScreenModal}
          onDismiss={() => setShowShipmentLabelFullScreenModal(false)}
          shipmentLabelUrl={labelImageBase64}
          hideLabelFullScreenModal={() =>
            setShowShipmentLabelFullScreenModal(false)
          }
        />
      )}
    </Wrapper>
  );
};

export default ShipmentInformation;
