import React from 'react';

import { Button } from '@livingpackets/design-system-react';
import { Stack, Text } from '@livingpackets/design-system-react-next';
import { useTranslation } from 'react-i18next';

interface IContributionRewardAvailable {
  onClickConfirmPayout?: () => void;
  isButtonDisabled?: boolean;
  isFailed?: Boolean;
}

const ContributionRewardAvailable = ({
  onClickConfirmPayout = undefined,
  isButtonDisabled = false,
}: IContributionRewardAvailable) => {
  const { t } = useTranslation('contribution');

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      margin="24px"
    >
      <Text variant="bodyTextS" color="custom.neutral.black.50">
        {t('contributionsTab.contributionsList.detail.rewardAvailable.text')}
      </Text>
      <Button
        action="primary"
        onClick={onClickConfirmPayout}
        disabled={isButtonDisabled}
        data-testid="contributionList-detail-button-confirm-payout"
      >
        {t('contributionsTab.contributionsList.detail.button.confirmPayout')}
      </Button>
    </Stack>
  );
};

export default ContributionRewardAvailable;
