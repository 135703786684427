import React from 'react';

import { Box, Divider } from '@livingpackets/design-system-react-next';

const MenuDivider = () => (
  <Box sx={{ mx: '0.5rem' }}>
    <Divider />
  </Box>
);

export default MenuDivider;
