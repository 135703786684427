import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
  createContext,
} from 'react';

import CarrierImgSelector from 'components/molecules/CarrierImgSelector';
import ImportShipmentOverview from 'components/molecules/ImportShipmentOverview';
import AddFiguralInsuranceChoiceModal from 'components/molecules/modals/AddFiguralInsuranceChoiceModal';
import AddReturnLabelChoiceModal from 'components/molecules/modals/AddReturnLabelChoiceModal';
import FiguralInsuranceFormModal from 'components/molecules/modals/FiguralInsuranceFormModal';
import FiguralInsuranceSummaryModal from 'components/molecules/modals/FiguralInsuranceSummaryModal';
import { DisabledTabsType } from 'components/organisms/ImportShipment';
import ShipmentLabelForm from 'components/organisms/ShipmentLabelForm';
import { PATHS } from 'configs';
import { Carriers } from 'configs/carriers';
import { ImportShipmentStepEnum } from 'enums/ImportShipmentEnum';
import useCreateShipment from 'hooks/useCreateShipment';
import useFiguralInsurance from 'hooks/useFiguralInsurance';
import useLpVillageService from 'hooks/useLpVillageService';
import useSessionStorage from 'hooks/useSessionStorage';
import useToastMessages from 'hooks/useToastMessages';
import { useUploadManualCrop } from 'hooks/useUploadManualCrop';
import { isEmpty } from 'lodash/fp';
import CARRIERS_LIST from 'models/carriers';
import { IFiguralInsurance } from 'models/figuralInsurance';
import {
  INITIAL_STATE_OCR_LABEL,
  IOcrLabel,
  IShipmentToImport,
} from 'models/shipment';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate } from 'react-router-dom';
import useMyPartnersStore, {
  activePartnerSelector,
} from 'stores/useMyPartnersStore';

import { ErrorCause } from '../../enums/ErrorCause';

interface ImportShipmentFlowProps {
  currentImportShipmentStep: ImportShipmentStepEnum;
  setCurrentImportShipmentStep: React.Dispatch<
    React.SetStateAction<ImportShipmentStepEnum>
  >;
  setDisabledTabs: React.Dispatch<React.SetStateAction<DisabledTabsType>>;
}

// Create context to indicate if the shipment creation is in edition
// (went to the last step then edit again the form)
export const ShipmentEditionContext = createContext<any>({
  isEditingCreationShipment: false,
  setIsEditingCreationShipment: (_: boolean) => {},
  // Context level state for useDefaut[Sender/Recipient]Checkbox
  useDefautSenderCheckbox: false,
  setUseDefautSenderCheckbox: (_: boolean) => {},
  useDefautRecipientCheckbox: false,
  setUseDefautRecipientCheckbox: (_: boolean) => {},
});

const ImportShipmentFlow = ({
  currentImportShipmentStep,
  setCurrentImportShipmentStep,
  setDisabledTabs,
}: ImportShipmentFlowProps) => {
  const { t } = useTranslation(['shipments', 'general', 'messages']);
  const [carrierCode, setCarrierCode] = useState<Carriers | undefined>(
    undefined
  );

  /* States used for the CONTEXT */
  const [isEditingCreationShipment, setIsEditingCreationShipment] =
    useState(false);
  const [useDefautSenderCheckbox, setUseDefautSenderCheckbox] = useState(null);
  const [useDefautRecipientCheckbox, setUseDefautRecipientCheckbox] =
    useState(null);
  /* END of state for the context */

  const [label, setLabel] = useState<IOcrLabel>({ ...INITIAL_STATE_OCR_LABEL });
  const [returnLabel, setReturnLabel] = useState<IOcrLabel>({
    ...INITIAL_STATE_OCR_LABEL,
  });

  const { getFiguralInsuranceDataFromLabel } = useFiguralInsurance();

  const [confirmedLabel, setConfirmedLabel] = useState<boolean>(false);

  const [showAddReturnLabelChoiceModal, setShowAddReturnLabelChoiceModal] =
    useState<boolean>(false);

  const [
    showAddFiguralInsuranceChoiceModal,
    setShowAddFiguralInsuranceChoiceModal,
  ] = useState<boolean>(false);

  const [figuralInsuranceFormModal, setFiguralInsuranceFormModal] =
    useState<boolean>(false);

  const [figuralInsuranceSummaryModal, setFiguralInsuranceSummaryModal] =
    useState<boolean>(false);

  const [figuralInsuranceData, setFiguralInsuranceData] =
    useState<IFiguralInsurance>();

  const [isShipmentLabelCreated, setIsShipmentLabelCreated] =
    useState<boolean>(false);

  const [
    isShipmentCreationButtonDisabled,
    setIsShipmentCreationButtonDisabled,
  ] = useState<boolean>(false);

  const { createOcrShipment, getShipmentPartnerImport } = useCreateShipment();
  const navigate = useNavigate();
  const activePartner = useMyPartnersStore(activePartnerSelector);

  const getApiLabel = useGetApiLabel();

  const { createPartnerParcelRequest } = useLpVillageService();

  const { resetSessionStorage } = useSessionStorage();

  const { error: toastError } = useToastMessages();

  useEffect(() => {
    carrierCode &&
      setDisabledTabs({
        isCarrierTabDisabled: false,
        isLabelTabDisabled: true,
        isShipmentDetailsTabDisabled: true,
        isAddReturnTabDisabled: true,
        isInsuranceTabDisabled: true,
        isSummaryTabDisabled: true,
      });
    confirmedLabel &&
      setDisabledTabs({
        isCarrierTabDisabled: false,
        isLabelTabDisabled: false,
        isShipmentDetailsTabDisabled: false,
        isAddReturnTabDisabled: false,
        isInsuranceTabDisabled: true,
        isSummaryTabDisabled: true,
      });
  }, [setDisabledTabs, carrierCode, confirmedLabel]);

  const carrierInfo = useMemo(
    () => CARRIERS_LIST.find(c => c.code === carrierCode) || CARRIERS_LIST[0],
    [carrierCode]
  );

  const resetShipmentOngoingLabelState = useCallback(() => {
    setLabel({ ...INITIAL_STATE_OCR_LABEL });
    setConfirmedLabel(false);
  }, [setLabel, setConfirmedLabel]);

  const resetShipmentReturnLabelState = useCallback(() => {
    setReturnLabel({
      ...INITIAL_STATE_OCR_LABEL,
      labelType: label.labelType,
    });
  }, [label, setReturnLabel]);

  const resetShipmentState = useCallback(
    (carrier?: Carriers) => {
      setCarrierCode(carrier);
      resetShipmentOngoingLabelState();
      resetShipmentReturnLabelState();
      setDisabledTabs({
        isCarrierTabDisabled: false,
        isLabelTabDisabled: true,
        isShipmentDetailsTabDisabled: true,
        isAddReturnTabDisabled: true,
        isInsuranceTabDisabled: true,
        isSummaryTabDisabled: true,
      });
    },
    [
      resetShipmentOngoingLabelState,
      resetShipmentReturnLabelState,
      setDisabledTabs,
    ]
  );

  const handleGoToOverview = useCallback(() => {
    setDisabledTabs({
      isCarrierTabDisabled: false,
      isLabelTabDisabled: false,
      isShipmentDetailsTabDisabled: false,
      isAddReturnTabDisabled: false,
      isInsuranceTabDisabled: true,
      isSummaryTabDisabled: true,
    });
    setCurrentImportShipmentStep(ImportShipmentStepEnum.ShipmentSummaryStep);
    setIsShipmentLabelCreated(true);
  }, [setDisabledTabs, setCurrentImportShipmentStep]);

  const createShipment = useCallback(async () => {
    let shipment: IShipmentToImport;

    try {
      shipment = {
        originalShipment: {
          ...(await getApiLabel(label)),
          carrierCode,
        },
        returnShipment: {
          ...(await getApiLabel(returnLabel)),
          carrierCode,
        },
      };
    } catch (error: any) {
      setIsShipmentCreationButtonDisabled(false);

      if (error.cause === ErrorCause.MANUAL_CROP_IMAGE_TOO_LARGE) {
        toastError(t('messages:manualCropImageTooLarge.message'), {
          wide: true,
        });

        return;
      }

      toastError(t('messages:manualCropImageUnknownError.message'), {
        wide: true,
      });

      return;
    }

    if (figuralInsuranceData) {
      const {
        success: labelFiguralParcelCreationSuccess,
        data: figuralInsuranceParcel,
      } = await createPartnerParcelRequest(figuralInsuranceData);

      if (labelFiguralParcelCreationSuccess) {
        shipment.originalShipment.parcelRequestId = figuralInsuranceParcel.id;
      } else {
        setIsShipmentCreationButtonDisabled(false);

        toastError(
          t('messages:createNewParcelRequestForOriginalShipmentError.message')
        );

        return;
      }
    }

    if (figuralInsuranceData && figuralInsuranceData.isGoodsReturnInsured) {
      const {
        success: labelFiguralParcelCreationSuccess,
        data: figuralInsuranceParcel,
      } = await createPartnerParcelRequest(figuralInsuranceData);

      if (labelFiguralParcelCreationSuccess) {
        shipment.returnShipment.parcelRequestId = figuralInsuranceParcel.id;
      } else {
        setIsShipmentCreationButtonDisabled(false);

        toastError(
          t('messages:createNewParcelRequestForReturnShipmentError.message')
        );

        return;
      }
    }

    const shipmentPartnerImport = getShipmentPartnerImport(shipment);

    const { success } = await createOcrShipment(shipmentPartnerImport);

    if (success) {
      resetShipmentState();
      resetSessionStorage(false, true);
      navigate(
        generatePath(PATHS.SHIPMENT_LABELS_ROOT, {
          partnerId: activePartner.id,
        })
      );
    } else {
      setIsShipmentCreationButtonDisabled(false);
    }
  }, [
    navigate,
    activePartner.id,
    resetShipmentState,
    createOcrShipment,
    label,
    returnLabel,
    carrierCode,
    getApiLabel,
    createPartnerParcelRequest,
    resetSessionStorage,
    t,
    toastError,
    figuralInsuranceData,
    getShipmentPartnerImport,
  ]);

  const autocompleteReturnLabel = useCallback(
    (ocrLabel: IOcrLabel) => {
      let initialReturnLabel: IOcrLabel = {
        senderPhoneNumber: label.recipientPhoneNumber,
        recipientPhoneNumber: label.senderPhoneNumber,
        senderAddress: {
          type: label.recipientAddress.type,
          firstName: label.recipientAddress.firstName,
          lastName: label.recipientAddress.lastName,
          company: label.recipientAddress.company,
          email: label.recipientAddress.email,
          street: label.recipientAddress.street,
          postalCode: label.recipientAddress.postalCode,
          city: label.recipientAddress.city,
          countryCode: label.recipientAddress.countryCode,
        },
        recipientAddress: {
          type: label.senderAddress.type,
          firstName: label.senderAddress.firstName,
          lastName: label.senderAddress.lastName,
          company: label.senderAddress.company,
          email: label.senderAddress.email,
          street: label.senderAddress.street,
          postalCode: label.senderAddress.postalCode,
          city: label.senderAddress.city,
          countryCode: label.senderAddress.countryCode,
        },
        weightInGrams: label.weightInGrams,
        carrierTrackingCode: '',
        referenceNumber: '',
        type: 'ocr',
        ocrId: '',
        previewUrl: '',
        labelType: label.labelType,
      };

      if (ocrLabel.type === 'ocr') {
        initialReturnLabel = {
          ...initialReturnLabel,
          ...{
            ocrId: ocrLabel.ocrId,
            previewUrl: ocrLabel.previewUrl,
          },
        };
      }

      if (ocrLabel.type === 'manualCrop') {
        initialReturnLabel = {
          ...initialReturnLabel,
          ...{
            type: 'manualCrop',
            manualCrop: ocrLabel.manualCrop,
            previewUrl: ocrLabel.previewUrl,
          },
        };
      }

      setReturnLabel(initialReturnLabel);
    },
    [setReturnLabel, label]
  );

  const handleReturnLabelChange = useCallback(
    (ocrLabel: IOcrLabel) => autocompleteReturnLabel(ocrLabel),
    [autocompleteReturnLabel]
  );

  const isShipmentHasReturnLabel = !isEmpty(returnLabel.referenceNumber);

  return (
    <ShipmentEditionContext.Provider
      value={{
        isEditingCreationShipment,
        setIsEditingCreationShipment,
        useDefautSenderCheckbox,
        setUseDefautSenderCheckbox,
        useDefautRecipientCheckbox,
        setUseDefautRecipientCheckbox,
      }}
    >
      {currentImportShipmentStep ===
        ImportShipmentStepEnum.CarrierSelectionStep && (
        <CarrierImgSelector
          selected={carrierCode}
          setSelected={resetShipmentState}
          onClickNext={() => {
            resetShipmentOngoingLabelState();
            resetShipmentReturnLabelState();
            setCurrentImportShipmentStep(
              ImportShipmentStepEnum.LabelImportStep
            );
          }}
        />
      )}
      {(currentImportShipmentStep === ImportShipmentStepEnum.LabelImportStep ||
        currentImportShipmentStep ===
          ImportShipmentStepEnum.ShipmentDetailsStep ||
        currentImportShipmentStep === ImportShipmentStepEnum.InsuranceStep) && (
        <ShipmentLabelForm
          carrierInfo={carrierInfo}
          label={label}
          onLabelChange={(ocrLabel: IOcrLabel) => setLabel(ocrLabel)}
          onCarrierChange={() =>
            setCurrentImportShipmentStep(
              ImportShipmentStepEnum.CarrierSelectionStep
            )
          }
          onSubmit={label => {
            setLabel(label);
          }}
          onLabelReUpload={() => {
            setConfirmedLabel(false);
            setCurrentImportShipmentStep(
              ImportShipmentStepEnum.LabelImportStep
            );
          }}
          currentImportShipmentStep={currentImportShipmentStep}
          onCompleteLabelForm={() => {
            if (!isShipmentLabelCreated) {
              setShowAddReturnLabelChoiceModal(true);
            } else if (!figuralInsuranceData && activePartner.secursusEnabled) {
              setShowAddFiguralInsuranceChoiceModal(true);

              setCurrentImportShipmentStep(
                ImportShipmentStepEnum.InsuranceStep
              );
            } else {
              handleGoToOverview();
            }
          }}
          onLabelSelected={() => {
            setCurrentImportShipmentStep(
              ImportShipmentStepEnum.ShipmentDetailsStep
            );
          }}
        />
      )}
      {currentImportShipmentStep ===
        ImportShipmentStepEnum.ReturnShipmentCreationStep && (
        <ShipmentLabelForm
          isReturnShipment
          carrierInfo={carrierInfo}
          label={returnLabel}
          onLabelChange={(ocrLabel: IOcrLabel) =>
            handleReturnLabelChange(ocrLabel)
          }
          onCarrierChange={() =>
            setCurrentImportShipmentStep(
              ImportShipmentStepEnum.CarrierSelectionStep
            )
          }
          onSubmit={label => {
            setReturnLabel(label);
          }}
          currentImportShipmentStep={currentImportShipmentStep}
          handleSkipReturnLabel={() => {
            resetShipmentReturnLabelState();
            handleGoToOverview();
          }}
          onCompleteLabelForm={() => {
            if (activePartner.secursusEnabled) {
              setShowAddFiguralInsuranceChoiceModal(true);
            } else {
              handleGoToOverview();
            }
          }}
        />
      )}
      {currentImportShipmentStep ===
        ImportShipmentStepEnum.ShipmentSummaryStep && (
        <ImportShipmentOverview
          label={label}
          returnLabel={returnLabel}
          carrierCode={carrierCode || Carriers.chronopost}
          onChangeReturnLabel={() => {
            setCurrentImportShipmentStep(
              ImportShipmentStepEnum.ReturnShipmentCreationStep
            );
            setIsEditingCreationShipment(true);
          }}
          onChangeOutgoingLabel={() => {
            setCurrentImportShipmentStep(
              ImportShipmentStepEnum.ShipmentDetailsStep
            );
            setIsEditingCreationShipment(true);
          }}
          onAddReturnLabel={() => {
            setCurrentImportShipmentStep(
              ImportShipmentStepEnum.ReturnShipmentCreationStep
            );
            resetShipmentReturnLabelState();
          }}
          onRemoveReturnLabel={resetShipmentReturnLabelState}
          onSubmit={() => {
            setIsShipmentCreationButtonDisabled(true);
            createShipment();
          }}
          onOpenFiguralInsuranceFormModal={() =>
            setShowAddFiguralInsuranceChoiceModal(true)
          }
          onOpenFiguralInsuranceSummaryModal={() =>
            setFiguralInsuranceSummaryModal(true)
          }
          figuralInsuranceData={figuralInsuranceData}
          isShipmentCreationButtonDisabled={isShipmentCreationButtonDisabled}
        />
      )}

      <AddReturnLabelChoiceModal
        isVisible={showAddReturnLabelChoiceModal}
        onSelectAddReturn={() => {
          setShowAddReturnLabelChoiceModal(false);
          setConfirmedLabel(false);
          setCurrentImportShipmentStep(
            ImportShipmentStepEnum.ReturnShipmentCreationStep
          );
          resetShipmentReturnLabelState();
        }}
        onSelectContinueWithoutReturn={() => {
          setShowAddReturnLabelChoiceModal(false);
          setConfirmedLabel(false);
          if (activePartner.secursusEnabled) {
            setShowAddFiguralInsuranceChoiceModal(true);
          } else {
            handleGoToOverview();
          }
        }}
      />

      <AddFiguralInsuranceChoiceModal
        isVisible={showAddFiguralInsuranceChoiceModal}
        isShipmentHasReturnLabel={isShipmentHasReturnLabel}
        isShipmentLabelCreated={isShipmentLabelCreated}
        isFiguralInsuranceCreated={figuralInsuranceData !== undefined}
        onSelectAddFiguralInsurance={() => {
          if (!figuralInsuranceData) {
            setFiguralInsuranceData(
              getFiguralInsuranceDataFromLabel(label, carrierCode)
            );

            setFiguralInsuranceFormModal(true);
          } else {
            setFiguralInsuranceData({
              ...figuralInsuranceData,
              isGoodsReturnInsured: returnLabel !== undefined,
            });

            setFiguralInsuranceSummaryModal(true);
          }

          setShowAddFiguralInsuranceChoiceModal(false);
        }}
        onSelectContinueWithoutInsurance={() => {
          if (figuralInsuranceData) {
            setFiguralInsuranceData({
              ...figuralInsuranceData,
              isGoodsReturnInsured: false,
            });
          }
          setShowAddFiguralInsuranceChoiceModal(false);
          handleGoToOverview();
        }}
      />

      {figuralInsuranceData && (
        <FiguralInsuranceFormModal
          isVisible={figuralInsuranceFormModal}
          defaultValues={figuralInsuranceData}
          isShipmentHasReturnLabel={isShipmentHasReturnLabel}
          onCancel={() => {
            setFiguralInsuranceData(undefined);
            setFiguralInsuranceFormModal(false);
            handleGoToOverview();
          }}
          onSubmit={(figuralInsuranceFormData: IFiguralInsurance) => {
            setFiguralInsuranceData({
              ...figuralInsuranceData,
              ...figuralInsuranceFormData,
            });
            setFiguralInsuranceFormModal(false);
            setFiguralInsuranceSummaryModal(true);
          }}
        />
      )}

      {figuralInsuranceData && (
        <FiguralInsuranceSummaryModal
          isVisible={figuralInsuranceSummaryModal}
          isShipmentHasReturnLabel={isShipmentHasReturnLabel}
          figuralInsuranceData={figuralInsuranceData}
          onEdit={() => {
            setFiguralInsuranceSummaryModal(false);
            setFiguralInsuranceFormModal(true);
          }}
          onConfirm={() => {
            setFiguralInsuranceSummaryModal(false);
            setFiguralInsuranceData({
              ...figuralInsuranceData,
              carrierName: carrierInfo.code,
              trackingNumber: label.carrierTrackingCode,
              isConfirmed: true,
            });
            setLabel(label);
            handleGoToOverview();
          }}
        />
      )}
    </ShipmentEditionContext.Provider>
  );
};

export default ImportShipmentFlow;

function useGetApiLabel() {
  const uploadManualCrop = useUploadManualCrop();

  return async (label: IOcrLabel) => {
    if (label.type === 'manualCrop') {
      try {
        const manualCropId = await uploadManualCrop(label.manualCrop);

        return {
          ...label,
          manualCropId,
          ocrId: undefined,
          type: undefined,
          manualCrop: undefined,
          notificationEmailSender: label.senderAddress.email,
          notificationEmailRecipient: label.recipientAddress.email,
        };
      } catch (error) {
        throw error;
      }
    }

    return {
      ...label,
      manualCrop: undefined,
      manualCropId: undefined,
      type: undefined,
      notificationEmailSender: label.senderAddress.email,
      notificationEmailRecipient: label.recipientAddress.email,
    };
  };
}
