import { useEffect, useRef } from 'react';

const useInterval = (callback: () => void, delay: any) => {
  const savedCallback = useRef<() => void>();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    const tick = () => {
      savedCallback.current && savedCallback.current();
    };

    if (delay) {
      const id = setInterval(tick, delay);

      return () => clearInterval(id);
    }
  }, [callback, delay]);

  return;
};

export default useInterval;
