import i18next from 'i18next';
import * as yup from 'yup';

export const retrieveDeliveryKeySchema = () =>
  yup.object().shape({
    deliveryKey: yup
      .string()
      .required(
        i18next.t(
          'authentication:retrieveDeliveryKey.form.deliveryKey.validation.required'
        )
      ),
  });
