import { formatISO, parse } from 'date-fns';
import i18next from 'i18next';

const INVALID_DATE = 'Invalid Date';

const GENERAL_DATE_FORMAT = 'dd/MM/yyyy';
const GERMAN_DATE_FORMAT = 'yyyy/MM/dd';

const H24_FORMAT = 'HH:mm';

// Parses date and times values from the form
export const formatDateTime = (
  date: string,
  startTime: string,
  endTime: string
) => {
  // Parse date first
  let parsedDate;
  if (i18next.language === 'de') {
    parsedDate = parse(date, GERMAN_DATE_FORMAT, new Date());
  } else {
    parsedDate = parse(date, GENERAL_DATE_FORMAT, new Date());
  }

  // Then the time
  const parsedStartTime = parse(startTime, H24_FORMAT, parsedDate);
  const parsedEndTime = parse(endTime, H24_FORMAT, parsedDate);
  parsedEndTime.setSeconds(59);

  if (
    parsedStartTime.toString() === INVALID_DATE ||
    parsedEndTime.toString() === INVALID_DATE
  ) {
    return {};
  }

  return {
    deployedAfter: formatISO(parsedStartTime),
    deployedBefore: formatISO(parsedEndTime),
  };
};
