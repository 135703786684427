import React, { useCallback } from 'react';

import { Button } from '@livingpackets/design-system-react';
import { Box, Stack } from '@livingpackets/design-system-react-next';
import InvitationPartnershipRow from 'components/atoms/InvitationPartnershipRow';
import {
  AuthenticationView,
  DescriptionTextFontStyleEnum,
} from 'components/containers/AuthenticationView';
import { PATHS } from 'configs';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import useMyPartnersStore, {
  IPartnerEntry,
  partnersSelector,
} from 'stores/useMyPartnersStore';

const InvitationCheck = () => {
  const navigate = useNavigate();
  const { t } = useTranslation('authentication');

  const partners = useMyPartnersStore(partnersSelector);

  const redirectToFinalizeAccountPage = useCallback(() => {
    navigate(PATHS.AUTHENTICATION.FINALIZE_ACCOUNT);
  }, [navigate]);

  return (
    <AuthenticationView
      title={t('invitationCheck.title')}
      description={t('invitationCheck.description')}
      descriptionFontStyle={DescriptionTextFontStyleEnum.REGULAR}
    >
      <Stack>
        <Stack marginBottom="25px">
          {partners.length !== 0 &&
            partners.map((partner: IPartnerEntry) => (
              <Box marginBottom="8px" key={partner.id}>
                <InvitationPartnershipRow partner={partner} key={partner.id} />
              </Box>
            ))}
        </Stack>
        <Button
          action="primary"
          style={{ width: '100%' }}
          onClick={() => redirectToFinalizeAccountPage()}
        >
          {t('invitationCheck.buttons.continue')}
        </Button>
      </Stack>
    </AuthenticationView>
  );
};

export default InvitationCheck;
