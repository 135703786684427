import React, { useMemo } from 'react';

import ReactTooltip from 'react-tooltip';
import styled, { DefaultTheme, ThemeProps, withTheme } from 'styled-components';

interface ITooltipStyles {
  arrowOffset?: { x?: number; y?: number };
  width?: string;
  height?: string;
  padding?: string;
  borderRadius?: string;
}

const StyledToolTip = styled(ReactTooltip)<ITooltipStyles>`
  box-shadow: 0 2px 40px 0 rgba(0, 0, 0, 0.1);
  width: ${({ width }) => width || 'unset'};
  height: ${({ height }) => height || 'unset'};
  padding: ${({ padding }) => padding || '1rem'} !important;
  border-radius: ${({ borderRadius }) => borderRadius || '0.625rem'} !important;
  opacity: 1 !important;

  &:after {
    transform: ${({ arrowOffset }) =>
      `translate(${arrowOffset?.x || 0}px, ${arrowOffset?.y || 0}px)`};
  }
`;

export interface ITooltip extends ITooltipStyles {
  children: React.ReactElement;
  uniqueId?: string;
  cardOffset?: { top?: number; left?: number };
  position?: 'bottom' | 'top' | 'left' | 'right';
  className?: string;
  content: React.ReactNode;
  effect?: 'solid' | 'float';
}

const Tooltip = ({
  children,
  uniqueId,
  position,
  arrowOffset,
  cardOffset,
  width,
  height,
  padding,
  borderRadius,
  className,
  content,
  theme,
  effect,
}: ITooltip & ThemeProps<DefaultTheme>) => {
  const toolTipId = useMemo(
    () => uniqueId || 'tooltip-' + Date.now(),
    [uniqueId]
  );

  return (
    <>
      <StyledToolTip
        id={toolTipId}
        effect={effect || 'solid'}
        backgroundColor={theme.colors.white}
        textColor={theme.colors.black[50]}
        place={position || 'bottom'}
        arrowOffset={arrowOffset || { x: cardOffset?.left, y: cardOffset?.top }}
        width={width}
        height={height}
        padding={padding}
        borderRadius={borderRadius}
        className={className}
      >
        {content}
      </StyledToolTip>

      {React.cloneElement(children, {
        'data-tip': true,
        'data-for': toolTipId,
        'data-offset': JSON.stringify(cardOffset) || '{}',
      })}
    </>
  );
};

export default withTheme(Tooltip);
