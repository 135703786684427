import { useCallback } from 'react';

import {
  UseFetchResource,
  useFetchResource,
  UseFetchResourceOptions,
} from '@livingpackets/shared-components';
import {
  GetPartnerProductsParams,
  PaginatedResponseProduct,
  useGetPartnerProducts,
} from 'hooks/useGetPartnerProducts';
import { ProductModel } from 'models/product';

export function usePartnerProducts(
  params: GetPartnerProductsParams,
  { enabled = true }: UseFetchResourceOptions = {}
): UseFetchResource<PaginatedResponseProduct<ProductModel>> {
  const getPartnerProducts = useGetPartnerProducts();

  const fetchFunction = useCallback(
    () => getPartnerProducts(params),
    [getPartnerProducts, params]
  );

  return useFetchResource(fetchFunction, { enabled });
}
