import React from 'react';

import { Text } from '@livingpackets/design-system-react-next';
import ProfileBuyAndRent from 'components/organisms/ProfileBuyAndRent';
import { useTranslation } from 'react-i18next';

const LPAccountBuyAndRent = () => {
  const { t } = useTranslation('buyAndRent');

  return (
    <>
      <div style={{ width: '100%', marginBottom: '2rem' }}>
        <Text variant="titleXL">{t('buyAndRent')}</Text>
      </div>
      <ProfileBuyAndRent />
    </>
  );
};

export default LPAccountBuyAndRent;
