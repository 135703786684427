import React, { useCallback, useState } from 'react';

import {
  ButtonV2 as Button,
  EditIcon,
  DeleteIcon,
  Grid,
  RecipientAddressIcon,
  Text,
  SenderAddressIcon,
  Stack,
  styled,
} from '@livingpackets/design-system-react-next';
import figuralLogo from 'assets/img/insurance/figural-logo.svg';
import Card from 'components/atoms/Card';
import Divider from 'components/atoms/Divider';
import DeleteShipmentReturnModal from 'components/molecules/modals/DeleteShipmentReturnModal';
import ViewShipmentLabelModal from 'components/molecules/modals/ViewShipmentLabelModal';
import { Carriers } from 'configs/carriers';
import parsePhoneNumber from 'libphonenumber-js';
import { IFiguralInsurance } from 'models/figuralInsurance';
import { IOcrLabel } from 'models/shipment';
import { useTranslation } from 'react-i18next';
import useMyPartnersStore, {
  activePartnerSelector,
} from 'stores/useMyPartnersStore';

import { Address, AddressName } from './Address';

const InsuranceFiguralLogo = styled('div', {
  shouldForwardProp: prop => prop !== 'figuralLogo',
})<{
  figuralLogo: string;
}>`
  min-width: 3.75rem;
  height: 3.25rem;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url(${({ figuralLogo }) => figuralLogo});
  margin-right: 0.5rem;
`;

export const Wrapper = styled(Card)`
  display: flex;
  background-color: ${({ theme }) => theme.palette.custom.neutral.black[2]};
  padding: 1.5rem 1.5rem 1.625rem 1.875rem;

  & + & {
    margin-top: 1rem;
  }
`;

export const IconCard = styled('div')`
  height: 2.75rem;
  width: 2.75rem;
  padding: 0.5rem;
  border-radius: 0.4375rem;
  background: ${({ theme }) => theme.palette.custom.neutral.black[4]};
`;

export const WrapperText = styled('div')`
  display: flex;
  flex-direction: column;
  margin-left: 1rem;
`;

export const WrapperAddress = styled('div')`
  display: flex;
  justify-content: space-between;
`;

export const StyledDivider = styled(Divider)`
  border-color: ${({ theme }) => theme.palette.custom.neutral.black[8]};
  margin-top: 1rem;
  margin-left: 3.5rem;
  width: calc(100% - 3.5rem);
`;

export const InfoBar = styled(Stack)`
  flex-direction: row;
  justify-content: space-between;
  margin-top: 0.7rem;
  margin-left: 3.5rem;
`;

export const Info = styled('div')`
  display: flex;
  flex-direction: column;
  height: 3.25rem;
  justify-content: space-evenly;
`;

const InfoTitle = styled(Text)``;

InfoTitle.defaultProps = {
  variant: 'titleXS',
  color: 'custom.neutral.black.50',
};

const InfoData = styled(Text)``;

InfoData.defaultProps = {
  variant: 'bodyTextS',
  color: 'custom.neutral.black.100',
};

const WrapperEditRemove = styled('div', {
  shouldForwardProp: prop => prop !== 'isOnRemoveAvailable',
})<{ isOnRemoveAvailable: boolean }>`
  display: flex;
  align-items: center;
  margin-left: ${({ isOnRemoveAvailable }) =>
    isOnRemoveAvailable ? '-3.625rem' : 0};

  > * + * {
    margin-left: 0.5rem;
  }

  height: 3rem;
`;

const ShipmentSumUpCard = ({
  label,
  carrierCode,
  onEdit,
  onRemove,
  onOpenFiguralInsuranceSummaryModal,
  figuralInsuranceData,
  isReturnLabelSummary,
}: {
  label: IOcrLabel;
  carrierCode: Carriers;
  onEdit: () => void;
  onOpenFiguralInsuranceSummaryModal: () => void;
  onRemove?: () => void;
  figuralInsuranceData?: IFiguralInsurance;
  isReturnLabelSummary: boolean;
}) => {
  const { t } = useTranslation('shipments');
  const { senderAddress, recipientAddress } = label;
  const activePartner = useMyPartnersStore(activePartnerSelector);

  const [
    showShipmentLabelFullScreenModal,
    setShowShipmentLabelFullScreenModal,
  ] = useState<boolean>(false);

  const [showDeleteShipmentReturnModal, setShowDeleteShipmentReturnModal] =
    useState<boolean>(false);

  const displayFiguralManageBlock = useCallback((): boolean => {
    if (!figuralInsuranceData) {
      return false;
    }

    if (!isReturnLabelSummary) {
      return true;
    }

    return figuralInsuranceData.isGoodsReturnInsured;
  }, [figuralInsuranceData, isReturnLabelSummary]);

  const formattedPhoneNumber = (phone?: string) => {
    if (!phone) return null;

    const parsed = parsePhoneNumber(phone);

    if (!parsed) return null;

    return (
      <Stack direction="row" alignItems="center" gap="0.1rem">
        <Text variant="titleXS">(+{parsed.countryCallingCode})</Text>
        <Text variant="bodyTextS">{parsed.nationalNumber}</Text>
      </Stack>
    );
  };

  return (
    <>
      <Wrapper>
        <Stack direction="row" justifyContent="space-between">
          <Text variant="titleXS" mb="1.375rem">
            {onRemove ? t('returnLabel') : t('outgoingLabel')}
          </Text>
          <WrapperEditRemove isOnRemoveAvailable={!!onRemove}>
            <Text
              variant="titleM"
              color="custom.primary.100"
              onClick={() => setShowShipmentLabelFullScreenModal(true)}
              mr="2rem"
              sx={{ cursor: 'pointer' }}
              data-testid={
                onRemove
                  ? 'shipmentSumUp-showLabel-return'
                  : 'shipmentSumUp-showLabel-onGoing'
              }
            >
              {t('importLabel.sumUp.showLabel')}
            </Text>
            {onRemove && (
              <Button
                variant="tertiary"
                icon={DeleteIcon}
                onClick={() => setShowDeleteShipmentReturnModal(true)}
                data-testid="delete-return"
              />
            )}
            <Button
              icon={EditIcon}
              onClick={onEdit}
              data-testid={
                onRemove
                  ? 'shipmentSumUp-edit-return'
                  : 'shipmentSumUp-edit-onGoing'
              }
            />
          </WrapperEditRemove>
        </Stack>
        <Grid container spacing="1rem">
          <Grid item container mobile={12} tablet={6} flexWrap="nowrap">
            <IconCard>
              <SenderAddressIcon />
            </IconCard>

            <Stack
              flex="1"
              ml="1rem"
              justifyContent="space-between"
              data-testid={
                onRemove
                  ? 'shipmentSumUp-address-return'
                  : 'shipmentSumUp-address-onGoing'
              }
            >
              <Stack>
                <Text variant="titleXS" color="custom.neutral.black.50">
                  {t('sender')}
                </Text>
                <Text variant="bodyTextS">
                  <AddressName address={senderAddress} />
                  <Address address={senderAddress} />
                </Text>
              </Stack>
              <Grid
                container
                justifyContent="space-between"
                mt="0.5rem"
                columnGap="0.5rem"
              >
                <Text variant="titleXS">{senderAddress.email}</Text>
                <Text variant="titleXS">
                  {formattedPhoneNumber(label.senderPhoneNumber)}
                </Text>
              </Grid>
            </Stack>
          </Grid>

          <Grid item container mobile={12} tablet={6} flexWrap="nowrap">
            <IconCard>
              <RecipientAddressIcon />
            </IconCard>
            <Stack ml="1rem" flex="1" justifyContent="space-between">
              <Stack>
                <Text variant="titleXS" color="custom.neutral.black.50">
                  {t('recipient')}
                </Text>
                <Text variant="bodyTextS">
                  <AddressName address={recipientAddress} />
                  <Address address={recipientAddress} />
                </Text>
              </Stack>
              <Grid
                container
                justifyContent="space-between"
                mt="0.5rem"
                columnGap="0.5rem"
              >
                <Text variant="titleXS">{recipientAddress.email}</Text>
                <Text variant="titleXS">
                  {formattedPhoneNumber(label.recipientPhoneNumber)}
                </Text>
              </Grid>
            </Stack>
          </Grid>
        </Grid>
        <StyledDivider direction="horizontal" />
        <InfoBar>
          {activePartner.secursusEnabled && (
            <>
              {displayFiguralManageBlock() && (
                <Info>
                  <Stack direction="row" alignItems="stretch">
                    <InsuranceFiguralLogo figuralLogo={figuralLogo} />
                    <Stack justifyContent="space-evenly">
                      <Text variant="titleXS" color="custom.neutral.black.50">
                        {t('importLabel.sumUp.card.insuredWithFigural')}
                      </Text>
                      <Text
                        variant="bodyTextS"
                        color="custom.primary.100"
                        sx={{
                          cursor: 'pointer',
                          textDecoration: 'underline',
                        }}
                        onClick={onOpenFiguralInsuranceSummaryModal}
                      >
                        {t('importLabel.sumUp.card.manageInsurance')}
                      </Text>
                    </Stack>
                  </Stack>
                </Info>
              )}
            </>
          )}
          <Info>
            <InfoTitle>{t('importLabel.sumUp.card.weight')}</InfoTitle>
            <InfoData
              data-testid={
                onRemove
                  ? 'shipmentSumUp-weightInGrams-return'
                  : 'shipmentSumUp-weightInGrams-onGoing'
              }
            >
              {label.weightInGrams
                ? t('importLabel.sumUp.card.weightData', {
                    weightInGrams: label.weightInGrams / 1000,
                  })
                : '-'}
            </InfoData>
          </Info>
          <Info>
            <InfoTitle>{t('importLabel.sumUp.card.carrierCode')}</InfoTitle>
            <InfoData
              data-testid={
                onRemove
                  ? 'shipmentSumUp-carrierCode-return'
                  : 'shipmentSumUp-carrierCode-onGoing'
              }
            >
              {t(
                `${carrierCode.toLowerCase()}` as unknown as TemplateStringsArray
              )}
            </InfoData>
          </Info>
          <Info>
            <InfoTitle>
              {t('importLabel.sumUp.card.carrierTrackingCode')}
            </InfoTitle>
            <InfoData
              data-testid={
                onRemove
                  ? 'shipmentSumUp-carrierTrackingCode-return'
                  : 'shipmentSumUp-carrierTrackingCode-onGoing'
              }
            >
              {label.carrierTrackingCode}
            </InfoData>
          </Info>
          <Info>
            <InfoTitle>{t('importLabel.sumUp.card.shipmentName')}</InfoTitle>
            <InfoData
              data-testid={
                onRemove
                  ? 'shipmentSumUp-referenceNumber-return'
                  : 'shipmentSumUp-referenceNumber-onGoing'
              }
            >
              {label.referenceNumber}
            </InfoData>
          </Info>
        </InfoBar>
      </Wrapper>
      {label.previewUrl && (
        <ViewShipmentLabelModal
          visible={showShipmentLabelFullScreenModal}
          onDismiss={() => setShowShipmentLabelFullScreenModal(false)}
          shipmentLabelUrl={label.previewUrl}
          hideLabelFullScreenModal={() =>
            setShowShipmentLabelFullScreenModal(false)
          }
        />
      )}
      {onRemove && (
        <DeleteShipmentReturnModal
          open={showDeleteShipmentReturnModal}
          onSubmit={onRemove}
          onCancel={() => setShowDeleteShipmentReturnModal(false)}
        />
      )}
    </>
  );
};

export default ShipmentSumUpCard;
