import styled, { css, CSSProperties } from 'styled-components';
import {
  flexbox,
  FlexboxProps,
  layout,
  LayoutProps,
  space,
  SpaceProps,
} from 'styled-system';

interface Spacing {
  spacing?: string;
}

interface FlexDirection {
  flexDirection?: CSSProperties['flexDirection'];
}

function getSpacingStyleForDirection(
  direction: NonNullable<FlexDirection['flexDirection']>,
  spacing: string
) {
  switch (direction) {
    case 'row':
      return css`
        margin-left: ${spacing};
      `;
    case 'row-reverse':
      return css`
        margin-right: ${spacing};
      `;
    case 'column':
      return css`
        margin-bottom: ${spacing};
      `;
    case 'column-reverse':
      return css`
        margin-top: ${spacing};
      `;
  }
}

const Row = styled.div<
  SpaceProps &
    LayoutProps &
    Omit<FlexboxProps, 'flexDirection'> &
    FlexDirection &
    Spacing
>`
  display: flex;
  flex-shrink: 0; //for safari otherwise children components are squished
  ${({ spacing = '0', flexDirection = 'row' }) =>
    spacing !== '0' &&
    css`
      > * + * {
        ${getSpacingStyleForDirection(flexDirection, spacing)}
      }
    `}
  ${space}
  ${layout}
  ${flexbox}
`;

export default Row;
