import React from 'react';

import {
  CloseIcon,
  Text,
  Stack,
} from '@livingpackets/design-system-react-next';

import Modal, { IModalWrapperType } from '../../atoms/Modal';

interface IViewProductScreenModal {
  visible: boolean;
  onDismiss: () => void;
  productScreenUrl?: string;
  hideLabelFullScreenModal: () => void;
  headerLabel: string;
}

const ViewProductScreenModal = ({
  visible,
  onDismiss,
  productScreenUrl,
  headerLabel,
}: IViewProductScreenModal) => (
  <Modal
    open={visible}
    handleClose={onDismiss}
    wrapperType={IModalWrapperType.shipmentLabel}
  >
    <Stack height="90%">
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        marginBottom="2rem"
      >
        <Text variant="titleL">{headerLabel}</Text>
        <CloseIcon onClick={() => onDismiss()} style={{ cursor: 'pointer' }} />
      </Stack>
      <img
        src={productScreenUrl}
        style={{ height: '100%', marginLeft: 'auto', marginRight: 'auto' }}
        alt="Product Screen"
      />
    </Stack>
  </Modal>
);

export default ViewProductScreenModal;
