import { useCallback } from 'react';

import useImperativeRequest from 'hooks/useImperativeRequest';
import {
  IMember,
  IMemberForm,
  IMemberPost,
  INITIAL_STATE_MEMBER,
} from 'models/user';
import useMyPartnersStore, {
  activePartnerSelector,
} from 'stores/useMyPartnersStore';
import { create } from 'zustand';

import useToastMessages from './useToastMessages';

interface IPartnerMemberStore {
  partnerMember: IMember;
  setPartnerMember: (partnerMember: IMember) => void;
}

export const usePartnerMemberStore = create<IPartnerMemberStore>(set => ({
  partnerMember: INITIAL_STATE_MEMBER,
  setPartnerMember: (partnerMember: IMember) => set({ partnerMember }),
}));

const usePartnerMemberManagement = () => {
  const [{ loading }, makeRequest] = useImperativeRequest('lpVillage');
  const { error: toastError, success: toastSuccess } = useToastMessages();
  const activePartner = useMyPartnersStore(activePartnerSelector);

  const getPartnerMember = useCallback(
    async (memberId: string) => {
      const { error, data } = await makeRequest({
        path: `api/v1/me/partners/${activePartner.id}/users/${memberId}`,
        method: 'get',
      });
      if (error) {
        toastError('messages:getPartnerMemberError');

        return { success: false, member: null };
      }

      return { success: true, partnerMember: data };
    },
    [makeRequest, toastError, activePartner]
  );

  const addMember = useCallback(
    async (member: IMemberPost) => {
      const { error, data } = await makeRequest({
        path: `api/v1/me/partners/${activePartner.id}/users`,
        method: 'post',
        body: { ...member },
      });
      if (error) {
        toastError('messages:partnerMemberCreateError.message', { wide: true });

        return { success: false };
      }

      toastSuccess('messages:partnerMemberCreateSuccess.message', {
        wide: true,
      });

      return { success: true, data };
    },
    [makeRequest, toastSuccess, toastError, activePartner.id]
  );

  const editMember = useCallback(
    async (member: IMemberPost, memberId: string) => {
      const { error, data } = await makeRequest({
        path: `api/v1/me/partners/${activePartner.id}/users/${memberId}`,
        method: 'put',
        body: { ...member },
      });
      if (error) {
        toastError('messages:partnerMemberEditError.message', { wide: true });

        return { success: false };
      }
      toastSuccess('messages:partnerMemberEditSuccess.message', { wide: true });

      return { success: true, data };
    },
    [makeRequest, toastSuccess, toastError, activePartner.id]
  );

  const deleteMember = useCallback(
    async (memberId: string) => {
      const { data, error } = await makeRequest({
        path: `api/v1/me/partners/${activePartner.id}/users/${memberId}`,
        method: 'delete',
      });

      if (error) {
        toastError('messages:partnerMemberDeleteError.message', {
          wide: true,
        });

        return { success: false };
      }
      toastSuccess('messages:partnerMemberDeleteSuccess.message', {
        wide: true,
      });

      return { success: true, data };
    },
    [activePartner.id, makeRequest, toastError, toastSuccess]
  );

  const fetchMemberRoles = useCallback(async () => {
    const { error, data } = await makeRequest({
      path: 'api/v1/me/roles',
      method: 'get',
    });

    if (error) {
      toastError('messages:partnerRoleError', { wide: true });

      return { success: false };
    }

    return { success: true, data };
  }, [makeRequest, toastError]);

  const searchMember = useCallback(
    async (memberName: string) => {
      const { error, data } = await makeRequest({
        path: `api/v1/me/partners/${activePartner.id}/members?offset=0&order=DESC&pageSize=5&sortBy=firstName&search=${memberName}`,
        method: 'get',
      });

      if (error) {
        toastError('messages:getSearchMemberError');

        return { success: false, member: null };
      }

      return { success: true, partnerMemberList: data.items };
    },
    [makeRequest, toastError, activePartner]
  );

  const getMemberPostFromMemberFormData = useCallback(
    (memberFormData: IMemberForm): IMemberPost => ({
      firstName: memberFormData.firstName,
      lastName: memberFormData.lastName,
      email: memberFormData.email,
      roleId: memberFormData.roleId,
    }),
    []
  );

  return {
    loading,
    addMember,
    getPartnerMember,
    editMember,
    deleteMember,
    fetchMemberRoles,
    searchMember,
    getMemberPostFromMemberFormData,
  };
};

export default usePartnerMemberManagement;
