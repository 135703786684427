import React from 'react';

import styled from 'styled-components';

const InfoWindowLabel = styled.span`
  color: ${({ theme }) => theme.colors.black[50]};
  display: block;
  font-size: 12px !important;
  font-family: 'Circular' !important;
`;

const InfoWindowText = styled.span`
  display: block;
  font-size: 12px !important;
  font-family: 'Circular' !important;
  max-width: 10rem;
`;

interface IGMInfoWindow {
  header: string;
  content: string;
}

const GMInfoWindowContent = ({ header, content }: IGMInfoWindow) => (
  <>
    <InfoWindowLabel>{header}</InfoWindowLabel>
    <InfoWindowText data-testid="infoWindowText">{content}</InfoWindowText>
  </>
);

export default GMInfoWindowContent;
