export enum AccountTypeEnum {
  FULL_VERSION = 'fullVersion',
  PERSONAL_ACCOUNT = 'personalAccount',
  BUSINESS_ACCOUNT = 'businessAccount',
  TRIAL_ACCOUNT = 'trialAccount',
}

export enum PartnerBusinessStatusEnum {
  UNSPECIFIED = 'UNSPECIFIED',
  PENDING = 'PENDING',
  VALIDATED = 'VALIDATED',
  BLOCKED = 'BLOCKED',
}

export enum PartnerBusinessTypeEnum {
  UNSPECIFIED = 'UNSPECIFIED',
  PERSONAL = 'PERSONAL',
  TEST = 'TEST',
  PRO = 'PRO',
}

export enum AccountRoleBusinessAccountSubRoleEnum {
  PARTNERSHIP_ADMIN = 'Partnership admin',
  SHOP_ADMIN = 'Shop admin',
  SHOP_MEMBER = 'Shop member',
}
