import React from 'react';

import { Box, Button } from '@livingpackets/design-system-react';
import { useTranslation } from 'react-i18next';

interface ImportShipmentPdfSpecificPageSelectedManuallyCroppingActionType {
  isLabelPdfMultiPage: boolean;
  handleChangePage: () => void;
  handlePdfPageSelectedCropped: () => void;
  isButtonActionDisabled: boolean;
}

const ImportShipmentPdfSpecificPageSelectedManuallyCroppingAction = ({
  isLabelPdfMultiPage,
  handleChangePage,
  handlePdfPageSelectedCropped,
  isButtonActionDisabled,
}: ImportShipmentPdfSpecificPageSelectedManuallyCroppingActionType) => {
  const { t } = useTranslation('shipments');

  return (
    <>
      <Box
        display="flex"
        justifyContent={isLabelPdfMultiPage ? 'space-between' : 'flex-end'}
        width="100%"
      >
        {isLabelPdfMultiPage && (
          <Button action="secondary" onClick={handleChangePage}>
            {t('changePage')}
          </Button>
        )}
        <Button
          action="primary"
          onClick={handlePdfPageSelectedCropped}
          isDisabled={isButtonActionDisabled}
        >
          {t('cropLabel')}
        </Button>
      </Box>
    </>
  );
};

export default ImportShipmentPdfSpecificPageSelectedManuallyCroppingAction;
