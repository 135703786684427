export const coverStatus = {
  ERROR: 0x00,
  LEFT_CLOSED_32L: 0x01,
  RIGHT_CLOSED_32L: 0x02,
  COMPLETELY_CLOSED_32L: 0x03,
  LEFT_CLOSED_2L: 0x04,
  RIGHT_CLOSED_2L: 0x05,
  COMPLETELY_CLOSED_2L: 0x06,
  OPENED: 0x07,
};

export type coverStatusKeys = keyof typeof coverStatus;
export type coverStatusValues = (typeof coverStatus)[coverStatusKeys];
