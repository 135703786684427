import {
  getInitialState,
  IPaginatedStore,
  reducer,
} from 'helpers/paginatedStoreHelpers';
import { create } from 'zustand';

import { IShipment } from '../models/shipment';

const INITIAL_STATE = getInitialState<IShipment>({
  sortBy: 'created_at',
  order: 'DESC',
  pageSize: 10,
  rowData: [],
});

const usePartnerShipmentsStore = create<IPaginatedStore<IShipment>>(set => ({
  ...INITIAL_STATE,
  dispatch: (input: any) => set(state => reducer(state, input, INITIAL_STATE)),
}));
export default usePartnerShipmentsStore;
