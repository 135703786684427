import React from 'react';

import { Auth0Provider } from '@auth0/auth0-react';
import { auth } from 'configs';
import { useNavigate } from 'react-router-dom';

export const Auth0ProviderWithNavigate = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const navigate = useNavigate();
  const onRedirectCallback = (appState: any) => {
    navigate(appState?.returnTo || window.location.pathname);
  };

  if (!(auth.domain && auth.clientId && auth.redirectUri)) {
    return null;
  }

  return (
    <Auth0Provider
      domain={auth.domain}
      clientId={auth.clientId}
      redirectUri={auth.redirectUri}
      audience={auth.audience}
      scope={auth.scope}
      cacheLocation={
        process.env.REACT_APP_AUTH_TEST === 'true' ? 'localstorage' : 'memory'
      }
      onRedirectCallback={onRedirectCallback}
    >
      {children}
    </Auth0Provider>
  );
};
