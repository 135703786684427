import React from 'react';

import {
  Box,
  Stack,
  Text,
  styled,
} from '@livingpackets/design-system-react-next';
import Row from 'components/atoms/Row';
import { DetailsSpan } from 'components/atoms/ShipmentAtoms';
import ShipmentAnonymousAddressCard from 'components/molecules/ShipmentAnonymousAddressCard';
import ShipmentAnonymousMap from 'components/molecules/ShipmentAnonymousMap';
import ShipmentAnonymousDetailCurrentStatus from 'components/molecules/shipmentDetail/ShipmentAnonymousDetailCurrentStatus';
import useShipmentDetail from 'hooks/useShipmentDetail';
import isNumber from 'lodash/isNumber';
import {
  IShipmentAnonymousData,
  ShipmentContractStateEnum,
} from 'models/shipment';
import { useTranslation } from 'react-i18next';

interface IShipmentAnonymousTracking {
  shipmentData: IShipmentAnonymousData;
  displayTrackingLink: boolean;
  onShipmentMapZoomClick: () => void;
}

const MapWrapper = styled(Stack)`
  margin-top: 13px;
`;

const Container = styled(Stack)`
  flex-direction: row;
  margin-bottom: 2rem;
`;

const LogoWrapper = styled('img')`
  height: 40px;
  width: 40px;
`;

const ShipmentAnonymousTracking = ({
  shipmentData,
  onShipmentMapZoomClick,
}: IShipmentAnonymousTracking) => {
  const { t } = useTranslation('shipments');

  const { setCurrentAnonymousTrackingData, getCarrierLogo } =
    useShipmentDetail();

  const showMap =
    isNumber(shipmentData.geo_path.coordinates[0]?.latitude) &&
    isNumber(shipmentData.geo_path.coordinates[0]?.longitude);

  const logo = getCarrierLogo(shipmentData.shipment.carrier_code);

  return (
    <>
      <Container>
        <Box width="40%" paddingRight="33px">
          <DetailsSpan>{t('detail.carrier.carrierTrackingNumber')}</DetailsSpan>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="flex-end"
          >
            <Row mt="8px" alignItems="center">
              {logo && <LogoWrapper src={logo} alt="carrier logo" />}
              {shipmentData.shipment.carrier_tracking_code && (
                <Box marginLeft="18px">
                  <Stack>
                    <Text
                      variant="titleL"
                      color="custom.neutral.black.100"
                      data-testid="carrier-tracking-code"
                    >
                      {shipmentData.shipment.carrier_tracking_code}
                    </Text>
                  </Stack>
                </Box>
              )}
            </Row>
          </Stack>
          <ShipmentAnonymousAddressCard shipmentData={shipmentData} mt="20px" />
        </Box>
        <Box width="60%">
          {showMap && (
            <>
              <DetailsSpan>{t('detail.deliveryStatus.title')}</DetailsSpan>
              <MapWrapper data-testid="mapWrapper">
                {shipmentData.timeline.events.length !== 0 && (
                  <ShipmentAnonymousDetailCurrentStatus
                    timelineEvents={shipmentData.timeline.events}
                    contractState={
                      shipmentData.shipment
                        .contract_state as ShipmentContractStateEnum
                    }
                  />
                )}
                <ShipmentAnonymousMap
                  shipmentData={shipmentData}
                  displayMapFullScreenModal={() => {
                    setCurrentAnonymousTrackingData(shipmentData.geo_path);
                    onShipmentMapZoomClick();
                  }}
                />
              </MapWrapper>
            </>
          )}
        </Box>
      </Container>
    </>
  );
};

export default ShipmentAnonymousTracking;
