import React from 'react';

import useShipmentTab, {
  ShipmentListNavTabEnum,
  ShipmentListTabEnum,
} from 'hooks/useShipmentTab';
import { useSearchParams, Navigate } from 'react-router-dom';

const DeepLinkShipmentListRoute = () => {
  const { setShipmentNavTabCurrent, setShipmentTabCurrent } = useShipmentTab();

  const [searchParams] = useSearchParams();

  const partnerId = searchParams.get('partner_id');
  const destination = searchParams.get('destination');

  if (destination === 'send') {
    setShipmentNavTabCurrent(ShipmentListNavTabEnum.ongoing);
    setShipmentTabCurrent(ShipmentListTabEnum.onGoing);
  }

  return <Navigate to={`/partner/${partnerId}/shipments`} />;
};

export default DeepLinkShipmentListRoute;
