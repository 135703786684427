import React, { useCallback } from 'react';

import {
  BatteryUndefinedIcon,
  BatteryLowIcon,
  BatteryMiddleIcon,
  BatteryFullIcon,
} from '@livingpackets/design-system-react-next';
import { useTheme } from 'styled-components';

interface IBatteryInfoIcon {
  batteryLevel: number | undefined;
  updateColor?: boolean;
}

const BatteryInfoIcon = ({
  batteryLevel,
  updateColor = true,
}: IBatteryInfoIcon) => {
  const theme = useTheme();

  const getBatteryLevelIcon = useCallback(
    (batteryLevel: number | undefined) => {
      if (!batteryLevel) {
        return <BatteryUndefinedIcon color={theme.colors.black[100]} />;
      } else if (batteryLevel <= 33) {
        return (
          <BatteryLowIcon
            color={
              updateColor ? theme.colors.red[100] : theme.colors.black[100]
            }
          />
        );
      } else if (batteryLevel <= 66) {
        return (
          <BatteryMiddleIcon
            color={
              updateColor ? theme.colors.black[100] : theme.colors.black[100]
            }
          />
        );
      } else if (batteryLevel > 66) {
        return (
          <BatteryFullIcon
            color={
              updateColor ? theme.colors.primary[100] : theme.colors.black[100]
            }
          />
        );
      }
    },
    [theme, updateColor]
  );

  return <>{getBatteryLevelIcon(batteryLevel)}</>;
};

export default BatteryInfoIcon;
