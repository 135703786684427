import { IShipment } from 'models/shipment';
import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';

export type TShipmentsSortBy = 'name' | 'createdAt' | 'updatedAt';

export interface IShipmentsStore {
  shipments: IShipment[];
  setShipments: (shipments: IShipment[]) => void;
  order: 'ASC' | 'DESC';
  setOrder: (order: 'ASC' | 'DESC') => void;
}

export const shipmentSelector = (state: IShipmentsStore) => state.shipments;
export const orderSelector = (state: IShipmentsStore) => state.order;
export const setOrderSelector = (state: IShipmentsStore) => state.setOrder;
export const setShipmentsSelector = (state: IShipmentsStore) =>
  state.setShipments;

const useShipmentsStore = create<IShipmentsStore>()(
  devtools(
    persist(
      set => ({
        shipments: [],
        setShipments: shipments => set(() => ({ shipments: shipments })),
        order: 'DESC',
        setOrder: order => set(() => ({ order: order })),
      }),
      {
        name: 'shipments',
      }
    )
  )
);

export default useShipmentsStore;
