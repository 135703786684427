import { useMutation } from '@tanstack/react-query';
import { AddressNotFoundGeocodingError } from 'core/errors/AddressNotFoundGeocodingError';
import useImperativeRequest from 'hooks/useImperativeRequest';
import { MutationConfig, queryClient } from 'lib/react-query';
import { useParams } from 'react-router-dom';
import { filterEditableValues } from 'utils/filterEditableValues';

import { Address, EditableAddressKeys } from '../../types/address';

export const createAddress =
  ({
    partnershipId,
    makeRequest,
  }: {
    partnershipId?: string;
    makeRequest: any;
  }) =>
  async (values: Partial<Address>): Promise<Address> => {
    const filteredValues = filterEditableValues({
      values,
      fields: EditableAddressKeys,
    });

    // Promise to simulate a request
    const { data, error } = await makeRequest({
      path: `api/v1/me/partners/${partnershipId}/addresses`,
      method: 'post',
      body: filteredValues,
    });

    if (!data || error) {
      if (error.response?.data?.code === AddressNotFoundGeocodingError.code) {
        throw new AddressNotFoundGeocodingError(error.response.data.message);
      }

      throw error;
    }

    return data;
  };

type UseCreateAddressOptions = {
  partnershipId?: string;
  config?: MutationConfig<ReturnType<typeof createAddress>>;
};

export const useCreateAddress = ({
  partnershipId,
  config,
}: UseCreateAddressOptions = {}) => {
  const { partnerId } = useParams();

  let id = partnershipId;
  if (!id) {
    id = partnerId;
  }

  const [, makeRequest] = useImperativeRequest('lpVillage');

  return useMutation({
    ...config,
    onSuccess: () => {
      queryClient.refetchQueries({
        queryKey: ['partnership', id, 'addresses'],
      });
    },
    mutationFn: createAddress({ partnershipId: id, makeRequest }),
  });
};
