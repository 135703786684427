import React from 'react';

import { CardBody, CardHeader, Card } from '@livingpackets/design-system-react';
import {
  ArrowLeftIcon,
  Box,
  ButtonV2 as Button,
  Stack,
  Text,
} from '@livingpackets/design-system-react-next';
import { PATHS } from 'configs';
import { DeployReturnTabs } from 'features/deploy-return';
import { useTranslation } from 'react-i18next';
import {
  useNavigate,
  useParams,
  useLocation,
  generatePath,
} from 'react-router-dom';

export const STATE_ORIGIN_PRODUCT = 'product';
export const STATE_ORIGIN_SHIPMENT = 'shipment';

export const DeployReturn = () => {
  const { t } = useTranslation('deployReturn');
  const navigate = useNavigate();

  const { shipmentId, partnerId } = useParams<{
    shipmentId: string;
    partnerId: string;
  }>();
  const location = useLocation();

  const { origin, productId } = location.state || {};

  const goToProduct = () => {
    navigate(
      generatePath(PATHS.PRODUCT.DETAIL, {
        partnerId: partnerId,
        lpUi: productId,
      })
    );
  };

  return (
    <Box>
      <Card>
        <CardHeader backgroundColor="black.100" color="white" padding={5}>
          <Stack direction="column" spacing={2}>
            <Stack direction="row" spacing={2} alignItems="center">
              <Button
                variant="tertiary"
                color="info"
                icon={ArrowLeftIcon}
                onClick={() => navigate(-1)}
              />
              <Text variant="bodyTextXS" color="custom.neutral.black.50">
                {/*  TODO : ATTENTE RETOUR DESIGN POUR HOMOGENITE */}
                {origin === STATE_ORIGIN_PRODUCT
                  ? t('productBreadcrumb')
                  : t('shipmentBreadcrumb')}
              </Text>
            </Stack>
            <Stack direction="column" spacing={0}>
              <Text variant="titleXS">{shipmentId}</Text>
              <Text variant="titleXL">{t('titlePage')}</Text>
            </Stack>
          </Stack>
        </CardHeader>
        <CardBody style={{ padding: '1.5rem' }}>
          <DeployReturnTabs />
          <Box display="flex" justifyContent="flex-end" paddingTop="1rem">
            <Button
              onClick={() => {
                origin === STATE_ORIGIN_PRODUCT ? goToProduct() : navigate(-1);
              }}
            >
              {origin === STATE_ORIGIN_PRODUCT
                ? t('ctaLabelProduct')
                : t('ctaLabelShipment')}
            </Button>
          </Box>
        </CardBody>
      </Card>
    </Box>
  );
};
