import React, { useEffect } from 'react';

import { FinancialCard, Tooltip } from '@livingpackets/design-system-react';
import {
  BuyRentIcon,
  ContributionIcon,
  TooltipIcon,
  Box,
  Text,
  styled,
  Stack,
  useTheme,
} from '@livingpackets/design-system-react-next';
import { PATHS } from 'configs';
import useGetContribution from 'hooks/useGetContribution';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import useFormatTimestamp from '../../hooks/useFormatTimestamp';
import useGetRents from '../../hooks/useGetRents';
import useBoxContractsStore, {
  boxContractSelector,
} from '../../stores/useBoxContractsStore';
import useContributionsStore, {
  contributionsSelector,
  contributionOverviewSelector,
} from '../../stores/useContributionsStore';
import Row from '../atoms/Row';
import {
  getNewsletterDateFormat,
  getSMArticleDateFormat,
} from '../commonFunctions/DateCalculation';
import BalanceCard from '../molecules/BalanceCard';
import NewsletterCardList from '../molecules/dashboard/NewsletterCardList';
import SocialMediaCardList from '../molecules/dashboard/SocialMediaCardList';
import WebsiteLinkCard from '../molecules/dashboard/WebsiteLinkCard';
import DashboardHeader from '../molecules/DashboardHeader';

const CardWrapper = styled('div')<{ width?: string }>`
  display: flex;
  z-index: 2;
  width: ${({ width }) => width};
`;
const ProfileDashboard = () => {
  const contracts = useBoxContractsStore(boxContractSelector);
  const contributions = useContributionsStore(contributionsSelector);
  const {
    t,
    i18n: { language },
  } = useTranslation(['contribution', 'buyAndRent']);
  const theme = useTheme();
  const formatTimestamp = useFormatTimestamp();
  const { rents } = useGetRents();
  const { getContributionOverview } = useGetContribution();

  const contributionOverview = useContributionsStore(
    contributionOverviewSelector
  );

  const navigate = useNavigate();

  useEffect(() => {
    getContributionOverview();
  }, [getContributionOverview]);

  return (
    <Stack>
      <DashboardHeader />
      <Row justifyContent="space-between" style={{ zIndex: 1 }}>
        {contributions.length > 0 && (
          <CardWrapper
            width={contracts.length === 0 ? '100%' : '49%'}
            style={{ zIndex: 3 }}
          >
            <FinancialCard
              handleClick={() => {
                navigate(PATHS.ACCOUNT.PROFILE);
              }}
              headerBackgroundColor="primary.140"
              titleCard={
                <>
                  <ContributionIcon size="24px" />
                  <Text variant="titleL">
                    {t('contribution:financialCard.dashboardTitle')}
                  </Text>
                </>
              }
              totalLabelInit={
                <Text style={{ textAlign: 'center' }}>
                  {t('contribution:financialCard.investmentsTotalLabel')}
                </Text>
              }
              totalLabelInitAmount={
                <Text variant="bodyTextXL">
                  {t('contribution:financialCard.investmentsTotalAmount', {
                    investmentsTotalAmount:
                      contributionOverview.investments_total_amount,
                  })}
                </Text>
              }
              totalLabelwin={
                <Box display="flex" style={{ gap: '5px' }} alignItems="center">
                  <Text style={{ textAlign: 'center' }}>
                    {t('contribution:financialCard.rewardLabel')}
                  </Text>
                  <TooltipIcon data-tooltip-id="reward-label" size="13.5px" />
                  <Tooltip
                    id="reward-label"
                    width="272px"
                    style={{ zIndex: 4 }}
                  >
                    <Text variant="bodyTextL">
                      {t('contribution:financialCard.rewardTooltip.title')}
                    </Text>
                    <Text>
                      {t('contribution:financialCard.rewardTooltip.content')}
                    </Text>
                  </Tooltip>
                </Box>
              }
              totalLabelwinAmount={
                <Text variant="bodyTextXL">
                  {t('contribution:financialCard.rewardAmount', {
                    rewardAmount: contributionOverview.reward_amount,
                  })}
                </Text>
              }
              summaryTotalLabel={
                <Text variant="titleL">
                  {t('contribution:financialCard.summaryLabel')}
                </Text>
              }
              summaryTotalAmount={
                <Text variant="titleL">
                  {t('contribution:financialCard.summaryAmount', {
                    summaryAmount:
                      contributionOverview.investments_total_amount,
                  })}
                  {}
                </Text>
              }
              summaryLabelDetail1={
                <Text variant="bodyTextL">
                  {t('contribution:financialCard.contributionNumberLabel', {
                    contributionNumber:
                      contributionOverview.contributions_number,
                  })}
                </Text>
              }
              summaryLabelDetailAmount1={
                <Text variant="bodyTextL">
                  {t('contribution:financialCard.contributionAmount', {
                    contributionAmount:
                      contributionOverview.contributions_amount,
                  })}
                </Text>
              }
              summaryLabelDetail2={
                <Text variant="bodyTextL">
                  {t('contribution:financialCard.sponsorshipsNumberLabel', {
                    sponsorshipsNumberLabel:
                      contributionOverview.sponsorships_number,
                  })}
                </Text>
              }
              summaryLabelDetailAmount2={
                <Text variant="bodyTextL">
                  {t('contribution:financialCard.sponsorshipsAmount', {
                    sponsorshipsAmount:
                      contributionOverview.sponsorships_amount,
                  })}
                </Text>
              }
              IsCompleted={false}
              IsEmpty={contributionOverview.reward_amount <= 0}
            />
          </CardWrapper>
        )}
        {contracts.length > 0 && (
          <CardWrapper
            width={contributions.length === 0 ? '100%' : '49%'}
            onClick={() => {
              navigate(PATHS.ACCOUNT.BUY_RENT);
            }}
          >
            <BalanceCard
              payoutAvailable={false}
              title={t('buyAndRent:buyAndRent')}
              icon={<BuyRentIcon color={theme.colors.white} />}
              payoutAvailability={t(
                'buyAndRent:buyNRent.balanceCard.availableFunds',
                {
                  date: rents.available_at
                    ? formatTimestamp(
                        new Date(rents.available_at),
                        getNewsletterDateFormat()
                      )
                    : null,
                  defaultValue: '',
                }
              )}
              amount={t('buyAndRent:buyNRent.balanceCard.reward', {
                reward: rents.total,
              })}
              investments={t(
                'buyAndRent:buyNRent.balanceCard.numberBoxesPurchased'
              )}
              investmentAmount={rents.product_count}
              firstLine={
                rents.shipment_count > 1
                  ? t('buyAndRent:buyNRent.balanceCard.shipmentsCount', {
                      shipmentCount: rents.shipment_count,
                      defaultValue: '',
                    })
                  : t(
                      'buyAndRent:buyNRent.balanceCard.shipmentsCountSingular',
                      {
                        shipmentCount: rents.shipment_count,
                        defaultValue: '',
                      }
                    )
              }
              initialDate={
                !!rents.activated_at &&
                t('buyAndRent:buyNRent.balanceCard.activationDate', {
                  activationDate: rents.activated_at
                    ? formatTimestamp(
                        new Date(rents.activated_at),
                        getSMArticleDateFormat(language)
                      )
                    : null,
                  defaultValue: '',
                })
              }
              secondLine={
                rents.contract_count > 1
                  ? t('buyAndRent:buyNRent.balanceCard.contractCount', {
                      contractCount: rents.contract_count,
                    })
                  : t('buyAndRent:buyNRent.balanceCard.contractCountSingular', {
                      contractCount: rents.contract_count,
                    })
              }
              cardHeaderColor={theme.colors.secondary.pantone['328C']}
              cardBalanceColor={theme.colors.primary[150]}
            />
          </CardWrapper>
        )}
        {contributions.length === 0 && contracts.length === 0 && (
          <Row alignItems="flex-end" width="100%">
            <WebsiteLinkCard />
          </Row>
        )}
      </Row>
      <Row width="100%">
        <SocialMediaCardList />
        <NewsletterCardList />
      </Row>
    </Stack>
  );
};

export default ProfileDashboard;
