import React, { useEffect, useState } from 'react';

import { Checkbox } from '@livingpackets/design-system-react';
import {
  Text,
  Box,
  styled,
  Stack,
  ButtonV2 as Button,
} from '@livingpackets/design-system-react-next';
import figuralLogo from 'assets/img/insurance/figural-logo.svg';
import Input from 'components/atoms/Input';
import TextArea from 'components/atoms/TextArea';
import CountrySelector from 'components/organisms/CountrySelector';
import useFormTemplate from 'hooks/useFormTemplate';
import { get, set } from 'lodash';
import { IFiguralInsurance } from 'models/figuralInsurance';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { figuralInsuranceSchema } from 'schemas/figuralInsuranceSchema';

import Modal, { IModalWrapperType } from '../../atoms/Modal';

const ButtonWrapper = styled('div', {
  shouldForwardProp: prop => prop !== 'isFormScrollAtBottom',
})<{ isFormScrollAtBottom: boolean }>`
  display: flex;
  justify-content: space-between;
  height: 100px;
  box-shadow: ${({ isFormScrollAtBottom }) =>
    !isFormScrollAtBottom ? '0px -8px 20px rgb(0 0 0 / 8%)' : '0px'};
  transition: all 200ms ease-in-out;
  padding: 25px;
  margin-left: -24px;
  margin-right: -24px;
  margin-bottom: -24px;
  z-index: 3;
`;

const InsuranceFiguralLogo = styled('div', {
  shouldForwardProp: prop => prop !== 'figuralLogo',
})<{
  figuralLogo: string;
}>`
  min-width: 60px;
  height: 52px;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url(${({ figuralLogo }) => figuralLogo});
  margin-right: 20px;
`;

const VStackFiguralInsuranceForm = styled(Stack)`
  margin-top: 25px;
  overflow-x: hidden;
  overflow-y: auto;
  margin-bottom: 20px;
  padding-right: 10px;

  ::-webkit-scrollbar {
    width: 4px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 20px;
    background: ${({ theme }) => theme.palette.custom.neutral.black[20]};
    background-clip: padding-box;
  }

  ::-webkit-scrollbar-track-piece {
    border-radius: 20px;
    background: ${({ theme }) => theme.palette.custom.neutral.black[8]};
  }

  ::-webkit-scrollbar-button {
    height: 100%;
    width: 2rem;
  }
`;

const FieldTitle = styled(Text)`
  margin-bottom: 8px;
`;

FieldTitle.defaultProps = {
  variant: 'titleXS',
  color: 'custom.neutral.black.50',
};

const FiguralInsuranceFormModal = ({
  isVisible,
  defaultValues,
  isShipmentHasReturnLabel,
  onCancel,
  onSubmit,
}: {
  isVisible: boolean;
  defaultValues: IFiguralInsurance;
  isShipmentHasReturnLabel: boolean;
  onCancel: () => void;
  onSubmit: (FiguralInsuranceFormData: IFiguralInsurance) => void;
}) => {
  const { t } = useTranslation('shipments');

  const [isFormScrollAtBottom, setIsFormScrollAtBottom] =
    useState<boolean>(false);

  const {
    errors,
    register,
    formState: { touchedFields, isValid },
    control,
    setValue,
    handleSubmit,
    trigger,
  } = useFormTemplate<IFiguralInsurance>({
    resolver: figuralInsuranceSchema,
  });

  useEffect(() => {
    if (defaultValues) {
      const inputConfig = {
        shouldValidate: true,
        shouldDirty: true,
        shouldTouch: true,
      };

      if (defaultValues.goodsValue) {
        setValue('goodsValue', defaultValues.goodsValue, inputConfig);
        set(touchedFields, 'goodsValue', true);
        trigger('goodsValue');
      }

      setValue(
        'departureCountryCode',
        defaultValues.departureCountryCode,
        inputConfig
      );

      setValue(
        'arrivalCountryCode',
        defaultValues.arrivalCountryCode,
        inputConfig
      );

      if (defaultValues.note) {
        setValue('note', defaultValues.note, inputConfig);
        trigger('note');
      }

      if (defaultValues.sender.firstName) {
        setValue(
          'sender.firstName',
          defaultValues.sender.firstName,
          inputConfig
        );
        set(touchedFields, 'sender.firstName', true);
        trigger('sender.firstName');
      }

      if (defaultValues.sender.lastName) {
        setValue('sender.lastName', defaultValues.sender.lastName, inputConfig);
        set(touchedFields, 'sender.lastName', true);
        trigger('sender.lastName');
      }

      if (defaultValues.sender.email) {
        setValue('sender.email', defaultValues.sender.email, inputConfig);
        set(touchedFields, 'sender.email', true);
        trigger('sender.email');
      }

      if (defaultValues.sender.address) {
        setValue('sender.address', defaultValues.sender.address, inputConfig);
        set(touchedFields, 'sender.address', true);
        trigger('sender.address');
      }

      if (defaultValues.sender.city) {
        setValue('sender.city', defaultValues.sender.city, inputConfig);
        set(touchedFields, 'sender.city', true);
        trigger('sender.city');
      }

      if (defaultValues.sender.zipCode) {
        setValue('sender.zipCode', defaultValues.sender.zipCode, inputConfig);
        set(touchedFields, 'sender.zipCode', true);
        trigger('sender.zipCode');
      }

      if (defaultValues.sender.countryCode) {
        setValue(
          'sender.countryCode',
          defaultValues.sender.countryCode,
          inputConfig
        );
      }
      setValue(
        'isGoodsReturnInsured',
        defaultValues.isGoodsReturnInsured,
        inputConfig
      );
    }
  }, [defaultValues, touchedFields, setValue, trigger, isVisible]);

  const handleScroll = (e: any) => {
    let element = e.target;

    if (
      element.scrollHeight - element.scrollTop + 0.5 ===
      element.clientHeight
    ) {
      setIsFormScrollAtBottom(true);
    } else {
      setIsFormScrollAtBottom(false);
    }
  };

  return (
    <Modal
      open={isVisible}
      handleClose={() => {}}
      wrapperType={IModalWrapperType.figuralInsuranceForm}
    >
      <>
        <Text variant="titleM">{t('modal.figuralInsuranceForm.title')}</Text>
        <Stack direction="row" marginTop="20px" alignItems="center">
          <InsuranceFiguralLogo figuralLogo={figuralLogo} />
          <Text variant="bodyTextS" sx={{ whiteSpace: 'pre' }}>
            {t('modal.figuralInsuranceForm.warning')}
          </Text>
        </Stack>
        <VStackFiguralInsuranceForm as="form" onScroll={handleScroll}>
          <Box>
            <FieldTitle>
              {t('form.figuralInsurance.title.goodsValue')}
            </FieldTitle>
            <Input
              name="goodsValue"
              label={
                get(touchedFields, 'goodsValue', false)
                  ? t('form.figuralInsurance.fields.goodsValue.placeholder')
                  : undefined
              }
              placeholder={t(
                'form.figuralInsurance.fields.goodsValue.placeholder'
              )}
              error={get(errors, 'goodsValue', false)}
              isTouched={get(touchedFields, 'goodsValue', false)}
              register={register}
              width="100%"
              onKeyPress={event => {
                if (!/\d/.test(event.key)) {
                  event.preventDefault();
                }
              }}
            />
          </Box>
          <Stack direction="row" marginBottom="16px">
            <Box width="50%" marginRight="8px">
              <FieldTitle>
                {t('form.figuralInsurance.title.departureCountryCode')}
              </FieldTitle>
              <CountrySelector
                id="departureCountryCode"
                fieldName="departureCountryCode"
                width="100%"
                control={control}
                register={register}
              />
            </Box>
            <Box width="50%" marginLeft="8px">
              <FieldTitle>
                {t('form.figuralInsurance.title.arrivalCountryCode')}
              </FieldTitle>
              <CountrySelector
                id="arrivalCountryCode"
                fieldName="arrivalCountryCode"
                width="100%"
                control={control}
                register={register}
              />
            </Box>
          </Stack>
          <Box>
            <FieldTitle>{t('form.figuralInsurance.title.note')}</FieldTitle>
            <TextArea
              name="note"
              label={
                get(touchedFields, 'note', false)
                  ? t('form.figuralInsurance.fields.note.placeholder')
                  : undefined
              }
              placeholder={t('form.figuralInsurance.fields.note.placeholder')}
              error={get(errors, 'note', false)}
              isTouched={get(touchedFields, 'note', false)}
              register={register}
              width="100%"
              height="80px"
              characterLimit={255}
            />
          </Box>
          <Box>
            <FieldTitle>{t('form.figuralInsurance.title.sender')}</FieldTitle>
            <Stack direction="row">
              <Box width="50%" marginRight="8px">
                <Input
                  name="sender.firstName"
                  label={
                    get(touchedFields, 'sender.firstName', false)
                      ? t(
                          'form.figuralInsurance.fields.sender.firstName.placeholder'
                        )
                      : undefined
                  }
                  placeholder={t(
                    'form.figuralInsurance.fields.sender.firstName.placeholder'
                  )}
                  error={get(errors, 'sender.firstName', false)}
                  isTouched={get(touchedFields, 'sender.firstName', false)}
                  register={register}
                  width="100%"
                />
              </Box>
              <Box width="50%" marginLeft="8px">
                <Input
                  name="sender.lastName"
                  label={
                    get(touchedFields, 'sender.lastName', false)
                      ? t(
                          'form.figuralInsurance.fields.sender.lastName.placeholder'
                        )
                      : undefined
                  }
                  placeholder={t(
                    'form.figuralInsurance.fields.sender.lastName.placeholder'
                  )}
                  error={get(errors, 'sender.lastName', false)}
                  isTouched={get(touchedFields, 'sender.lastName', false)}
                  register={register}
                  width="100%"
                />
              </Box>
            </Stack>
            <Input
              name="sender.email"
              label={
                get(touchedFields, 'sender.email', false)
                  ? t('form.figuralInsurance.fields.sender.email.placeholder')
                  : undefined
              }
              placeholder={t(
                'form.figuralInsurance.fields.sender.email.placeholder'
              )}
              error={get(errors, 'sender.email', false)}
              isTouched={get(touchedFields, 'sender.email', false)}
              register={register}
              width="100%"
            />
          </Box>
          <Stack>
            <FieldTitle>
              {t('form.figuralInsurance.title.senderAddress')}
            </FieldTitle>
            <Box>
              <Input
                name="sender.address"
                label={
                  get(touchedFields, 'sender.address', false)
                    ? t(
                        'form.figuralInsurance.fields.sender.address.placeholder'
                      )
                    : undefined
                }
                placeholder={t(
                  'form.figuralInsurance.fields.sender.address.placeholder'
                )}
                error={get(errors, 'sender.address', false)}
                isTouched={get(touchedFields, 'sender.address', false)}
                register={register}
                width="100%"
              />
            </Box>
            <Stack direction="row">
              <Box width="70%" marginRight="8px">
                <Input
                  name="sender.city"
                  label={
                    get(touchedFields, 'sender.city', false)
                      ? t(
                          'form.figuralInsurance.fields.sender.city.placeholder'
                        )
                      : undefined
                  }
                  placeholder={t(
                    'form.figuralInsurance.fields.sender.city.placeholder'
                  )}
                  error={get(errors, 'sender.city', false)}
                  isTouched={get(touchedFields, 'sender.city', false)}
                  register={register}
                  width="100%"
                />
              </Box>
              <Box width="30%" marginLeft="8px">
                <Input
                  name="sender.zipCode"
                  label={
                    get(touchedFields, 'sender.zipCode', false)
                      ? t(
                          'form.figuralInsurance.fields.sender.zipCode.placeholder'
                        )
                      : undefined
                  }
                  placeholder={t(
                    'form.figuralInsurance.fields.sender.zipCode.placeholder'
                  )}
                  error={get(errors, 'sender.zipCode', false)}
                  isTouched={get(touchedFields, 'sender.zipCode', false)}
                  register={register}
                  width="100%"
                />
              </Box>
            </Stack>
            <CountrySelector
              id="countryCode"
              fieldName="sender.countryCode"
              width="100%"
              control={control}
              register={register}
            />
            {isShipmentHasReturnLabel && (
              <Box marginTop="23px !important">
                <Controller
                  control={control}
                  name="isGoodsReturnInsured"
                  defaultValue={defaultValues.isGoodsReturnInsured}
                  render={({ field }) => (
                    <Checkbox
                      name={field.name}
                      value={field.value}
                      onBlur={field.onBlur}
                      onChange={field.onChange}
                      isSelected={field.value}
                    >
                      <Text
                        variant="bodyTextM"
                        color="custom.neutral.black.50"
                        sx={{ cursor: 'pointer' }}
                      >
                        {t(
                          'form.figuralInsurance.fields.isGoodsReturnInsured.label'
                        )}
                      </Text>
                    </Checkbox>
                  )}
                />
                <Text
                  variant="bodyTextXS"
                  color="custom.neutral.black.50"
                  mt="5px"
                >
                  {t('form.figuralInsurance.help')}
                </Text>
              </Box>
            )}
          </Stack>
        </VStackFiguralInsuranceForm>
        <ButtonWrapper isFormScrollAtBottom={isFormScrollAtBottom}>
          <Button
            variant="secondary"
            color={defaultValues.isConfirmed ? 'critical' : 'primary'}
            onClick={onCancel}
            data-testid="figural-insurance-form-cancel-button"
          >
            {defaultValues.isConfirmed
              ? t('modal.figuralInsuranceForm.button.delete-figural-insurance')
              : t('modal.figuralInsuranceForm.button.cancel-figural-insurance')}
          </Button>
          <Button
            disabled={!isValid}
            onClick={handleSubmit(onSubmit as any)}
            data-testid="figural-insurance-form-confirm-button"
          >
            {t('modal.figuralInsuranceForm.button.confirm')}
          </Button>
        </ButtonWrapper>
      </>
    </Modal>
  );
};

export default FiguralInsuranceFormModal;
