var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useCallback, useState } from 'react';
import axios from 'axios';
const useImperativeRequest = ({ url, token, }) => {
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState(null);
    const makeRequest = useCallback(({ path = '/', method = 'get', body, headers = {}, responseType = 'json', }) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            setLoading(true);
            const data = yield axios({
                url: `${url}${path}`,
                method,
                data: body,
                headers: Object.assign(Object.assign({}, headers), { Authorization: `Bearer ${token}` }),
                responseType,
            }).then(res => res.data);
            setError('');
            setData(data);
            setLoading(false);
            return { data: data, error: '' };
        }
        catch (err) {
            setError(err);
            console.log(err);
            setLoading(false);
            return { data: null, error: err };
        }
    }), [token, url]);
    return [{ error, loading, data }, makeRequest];
};
export default useImperativeRequest;
