import React from 'react';

import { Button } from '@livingpackets/design-system-react';
import { ShareIcon } from '@livingpackets/design-system-react-next';
import useExportShipmentLogs from 'hooks/useExportShipmentLogs';
import { IShipment } from 'models/shipment';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

const ExportShipmentLogs = ({ shipment }: { shipment: IShipment }) => {
  const theme = useTheme();
  const { loading, getCSVData } = useExportShipmentLogs();
  const { t } = useTranslation('shipments');
  const handleClick = () => getCSVData(shipment.shipment_id);

  return (
    <>
      <Button
        action="tertiary"
        color={theme.colors.black[100]}
        icon={<ShareIcon />}
        onClick={handleClick}
        isLoading={loading}
        data-testid="exportLogs"
      >
        {t('exportLogs')}
      </Button>
    </>
  );
};

export default ExportShipmentLogs;
