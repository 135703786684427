import styled from 'styled-components';
import { color, ColorProps, space, SpaceProps } from 'styled-system';

interface IDivider {
  height?: string;
  width?: string;
  direction?: 'horizontal' | 'vertical';
  borderColor?: string;
}

const Divider = styled.div<IDivider & SpaceProps & ColorProps>`
  width: ${({ direction, width }) =>
    width ? width : direction === 'vertical' ? '0' : '100%'};
  height: ${({ direction, height }) =>
    height ? height : direction === 'vertical' ? '1rem' : '0'};
  border-right: ${({ direction, width = '1px', theme, borderColor }) =>
    `${direction === 'vertical' ? width : '0'} solid ${
      borderColor || theme.colors.primary[10]
    }`};
  border-top: ${({ direction, width = '1px', theme, borderColor }) =>
    `${direction === 'vertical' ? '0' : width} solid ${
      borderColor || theme.colors.primary[10]
    }`};
  margin: ${({ direction }) =>
    direction === 'vertical' ? '0 0.125rem' : '0.125rem 0'};
  ${space};
  ${color};
`;

Divider.defaultProps = {
  direction: 'vertical',
};

export default Divider;
