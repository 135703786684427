import { useMutation } from '@tanstack/react-query';
import useImperativeRequest from 'hooks/useImperativeRequest';
import { MutationConfig } from 'lib/react-query';
import { useParams } from 'react-router-dom';

type ValuesProps = {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
};

const createContact =
  ({
    partnershipId,
    makeRequest,
  }: {
    partnershipId?: string;
    makeRequest: any;
  }) =>
  async (values: ValuesProps): Promise<any> => {
    const { data, error } = await makeRequest({
      path: `api/v1/me/partners/${partnershipId}/contacts`,
      method: 'post',
      body: values,
    });

    if (!data || error) {
      throw error;
    }

    return data;
  };

type UseCreateContactOptions = {
  partnershipId?: string;
  config?: MutationConfig<ReturnType<typeof createContact>>;
};

export const useCreateContact = ({
  partnershipId,
  config,
}: UseCreateContactOptions = {}) => {
  const { partnerId } = useParams();

  let id = partnershipId;
  if (!id) {
    id = partnerId;
  }

  const [, makeRequest] = useImperativeRequest('lpVillage');

  return useMutation({
    ...config,
    mutationFn: createContact({ partnershipId: id, makeRequest }),
  });
};
