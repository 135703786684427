import React from 'react';

import { useTranslation } from 'react-i18next';

interface ILockingStateText {
  lockState: boolean;
}

const LockingStateText = ({ lockState }: ILockingStateText) => {
  const { t } = useTranslation('products');

  return (
    <>
      {lockState
        ? t('common.lockingState.locked')
        : t('common.lockingState.unlocked')}
    </>
  );
};

export default LockingStateText;
