import get from 'lodash/get';
import { dispatch } from 'stores/appState/useAppState';

const loadUserProfile = async (
  makeRequest: any,
  auth0User: any,
  logout: any
) => {
  const { data, error } = await makeRequest({
    path: 'api/v1/me',
    method: 'get',
  });
  if (error) {
    if (error.response?.status === 400 || error.response?.status === 401) {
      logout();

      return;
    } else {
      dispatch({ type: 'SET_ERROR', payload: error });
      dispatch({ type: 'SET_API_ERROR', payload: error });
    }

    return;
  }
  if (data) {
    try {
      const profile = get(data, 'profile', {});
      dispatch({ type: 'SET_USER', payload: profile });
    } catch (err) {
      dispatch({ type: 'SET_ERROR', payload: error });
    }
  }
};

export default loadUserProfile;
