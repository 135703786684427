import React, { createContext, useMemo, useState } from 'react';

import {
  PartnershipIcon,
  NewCard as Card,
  Grid,
} from '@livingpackets/design-system-react-next';
import get from 'lodash/get';
import { useLocation } from 'react-router-dom';

import { useAddresses } from '../api/addresses/getAddresses';
import { usePartnership } from '../api/partnership/getPartnership';
import { Avatar } from '../components/commons/Avatar';
import { Layout } from '../components/Layout';
import { Error } from '../components/partnership/Error';
import RegisteredOffice from '../components/partnership/information/RegisteredOffice';
import { PartnerTabs } from '../components/partnership/PartnerTabs';
import { GreenCardHeader } from '../components/personal/GreenCardHeader';
import { AddressTypes } from '../types/address';

type AppContextValue = {
  tab: number;
};

const TABS_ENUM = {
  information: 0,
  offer: 1,
  finance: 2,
  supply_address: 3,
} as const;

type TabsValues = keyof typeof TABS_ENUM;

export const TabsContext = createContext<AppContextValue>({
  tab: TABS_ENUM.information,
});

export const WbqContext = createContext<any>(null);

export const Partnership = () => {
  const location = useLocation();

  const { data: partnership, error } = usePartnership();
  const { data: addresses } = useAddresses();

  // State for Warn Before Quit
  const [queue, setQueue] = useState({});

  const tab = location.pathname.split('/').pop() as TabsValues;

  const city = useMemo(
    () =>
      addresses?.items.find(({ addressTypes }) =>
        addressTypes?.includes(AddressTypes.MAIN)
      )?.city,
    [addresses]
  );

  if (error) return <Error />;

  return (
    <TabsContext.Provider
      value={{
        tab: get(TABS_ENUM, tab!, TABS_ENUM.information),
      }}
    >
      <WbqContext.Provider value={{ queue, setQueue }}>
        <Layout value="partnership/information">
          <Grid container direction="column" gap="2rem">
            <Grid item>
              <Card
                headerProps={{
                  variant: 'green',
                  children: (
                    <GreenCardHeader
                      avatar={
                        <Avatar>
                          <PartnershipIcon />
                        </Avatar>
                      }
                      title={partnership?.partner_id_card.client_name || ''}
                      subtitle={city || ''}
                    />
                  ),
                }}
                bodyProps={{
                  children: <PartnerTabs />,
                }}
              />
            </Grid>
            {/* Additionnal content for the information tab */}
            {TABS_ENUM[tab] === TABS_ENUM.information && <RegisteredOffice />}
          </Grid>
        </Layout>
      </WbqContext.Provider>
    </TabsContext.Provider>
  );
};
