import React from 'react';

import {
  DeleteIcon,
  Text,
  ButtonV2 as Button,
  styled,
} from '@livingpackets/design-system-react-next';
import { usePartnership } from 'features/account';
import { useTranslation } from 'react-i18next';

import Modal from '../../atoms/Modal';

const ButtonWrapper = styled('div')`
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
`;

const DeleteAddressModal = ({
  open,
  onSubmit,
  onCancel,
  loading,
  addressId,
}: {
  open: boolean;
  onSubmit: () => void;
  onCancel: () => void;
  loading: boolean;
  addressId?: string;
}) => {
  const { t } = useTranslation(['table', 'general', 'contactAddress']);

  const { data: partnership } = usePartnership();

  const hasDefaultAddress = [
    partnership?.default_sender_address_id,
    partnership?.default_recipient_address_id,
  ].includes(addressId);

  return (
    <Modal open={open} handleClose={onCancel}>
      <>
        <Text variant="titleM">
          {t('contactAddress:modal.deleteAddress.title')}
        </Text>
        <Text variant="bodyTextS" mt="2.5rem">
          {t(
            `contactAddress:modal.deleteAddress.${
              hasDefaultAddress ? 'warningWithDefaultAddress' : 'warning'
            }`
          )}
        </Text>
        <ButtonWrapper>
          <Button variant="secondary" onClick={onCancel}>
            {t('contactAddress:modal.deleteAddress.button.dismiss')}
          </Button>
          <Button
            color="critical"
            icon={DeleteIcon}
            onClick={onSubmit}
            disabled={loading}
          >
            {t('contactAddress:modal.deleteAddress.button.submit')}
          </Button>
        </ButtonWrapper>
      </>
    </Modal>
  );
};

export default DeleteAddressModal;
