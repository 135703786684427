import {
  coverStatus,
  coverStatusKeys,
  coverStatusValues,
} from '../types/CoverStatus';

export const getCoverStatusKeyByValue = (
  value: coverStatusValues
): coverStatusKeys | null => {
  const keys = Object.keys(coverStatus) as coverStatusKeys[];
  for (const key of keys) {
    if (coverStatus[key] === value) {
      return key;
    }
  }

  return null;
};
