import React, { useCallback, useEffect, useState } from 'react';

import { Button } from '@livingpackets/design-system-react';
import {
  LockOnIcon,
  AddIcon,
  Banner,
  Text,
  styled,
  useTheme,
} from '@livingpackets/design-system-react-next';
import bgBanner from 'assets/img/banner/bg-banner.svg';
import boxBannerShipment from 'assets/img/dashboard_box_shipment.png';
import Row from 'components/atoms/Row';
import HomeInfoCard from 'components/molecules/HomeInfoCard';
import HomeShipmentCard from 'components/molecules/HomeShipmentCard';
import Title from 'components/molecules/Title';
import { PATHS } from 'configs';
import {
  PartnerBusinessStatusEnum,
  PartnerBusinessTypeEnum,
} from 'enums/accountTypeEnum';
import useCurrentPartner from 'hooks/useCurrentPartner';
import useIntlFormatTimestamp from 'hooks/useIntlFormatTimestamp';
import useNavigatePartner from 'hooks/useNavigatePartner';
import useNewShipmentDisabled from 'hooks/useNewShipmentDisabled';
import { find } from 'lodash/fp';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate } from 'react-router-dom';
import useCurrentPartnerStore, {
  currentPartnerSelector,
  registrationAddressSelector,
} from 'stores/useCurrentPartnerStore';
import useMyPartnersStore, {
  activePartnerSelector,
} from 'stores/useMyPartnersStore';

const ShipmentBannerWrapper = styled(Row)`
  gap: 20px;
`;

const ShipmentBanner = styled('div', {
  shouldForwardProp: prop => prop !== 'bgImage',
})<{ bgImage: string }>`
  display: flex;
  width: 42.625rem;

  border-radius: 1.25rem;
  padding-top: 1.75rem;
  padding-left: 2.375rem;
  color: ${({ theme }) => theme.palette.custom.neutral.white};
  background: url(${props => props.bgImage}), linear-gradient(80.33deg, ${({
  theme,
}) => theme.palette.custom.primary[100]} -45.64%, ${({ theme }) =>
  theme.palette.custom.primary[140]} 92.72%);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: right;
  align-items: center;
}
`;

const ShipementBannerText = styled(Text)<{
  bgColor?: string;
  borderRadius?: string;
}>`
  background-color: ${({ bgColor }) => bgColor};
  border-radius: ${({ borderRadius }) => borderRadius};
  display: flex;
  align-items: center;
  gap: O 0.78rem;
`;

const CreateShipmentButton = styled(Button)`
  background-color: ${({ theme }) => theme.palette.custom.neutral.black[4]};
  border: 1.5px dashed ${({ theme }) => theme.palette.custom.neutral.black[20]};
  height: 10rem;
  width: 15.875rem;

  > p {
    display: flex;
    flex-direction: column;
    gap: 16px;
    color: ${({ theme }) => theme.palette.custom.neutral.black[50]};
  }

  span {
    width: 48px;
    height: 48px;
    background-color: ${({ theme }) => theme.palette.custom.primary[100]};

    color: ${({ theme }) => theme.palette.custom.neutral.white.pure};
    border-radius: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const PartnerHome = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { t } = useTranslation(['general', 'partnerSettings', 'dashboard']);

  const activePartner = useMyPartnersStore(activePartnerSelector);
  const partner = useCurrentPartnerStore(currentPartnerSelector);
  const formatTimestamp = useIntlFormatTimestamp();
  const navigateAccount = useNavigatePartner();
  const { reset, getCurrentPartner, getRegistrationAddress } =
    useCurrentPartner();

  const [displayBanner, setDisplayBanner] = useState<boolean>(false);
  const [bannerContent, setBannerContent] = useState<string>('');
  const [tagCreateShipmentMessage, setTagCreateShipmentMessage] =
    useState<string>('');
  const [update, setUpdate] = useState<number>(0);

  const manageBanner = useCallback(
    (status: PartnerBusinessStatusEnum, type: PartnerBusinessTypeEnum) => {
      if ([PartnerBusinessStatusEnum.BLOCKED].includes(status)) {
        setDisplayBanner(true);
        setBannerContent(status.toLocaleLowerCase());
      } else if (
        status === PartnerBusinessStatusEnum.PENDING &&
        type === PartnerBusinessTypeEnum.PRO &&
        find({ name: 'update:partner' }, activePartner.role.scopes)
      ) {
        setDisplayBanner(true);
        setBannerContent(status.toLocaleLowerCase());
      } else {
        setDisplayBanner(false);
        setBannerContent('');
      }
    },
    [activePartner.role.scopes]
  );

  const manageTagMessage = useCallback(
    (status: PartnerBusinessStatusEnum, type: PartnerBusinessTypeEnum) => {
      let message = '';
      if (type === PartnerBusinessTypeEnum.TEST) {
        message = t(`dashboard:createShipment.tagInformation`, {
          profil: type,
          maxOfShipements: partner.maxNbOfShipments,
        });
      } else if (status === PartnerBusinessStatusEnum.PENDING) {
        message = t(`dashboard:createShipment.tagInformation`, {
          profil: status,
          maxOfShipements: partner.maxNbOfShipments,
        });
      }

      setTagCreateShipmentMessage(message);
    },
    [partner, t]
  );

  useEffect(() => {
    getRegistrationAddress(activePartner.id);
    getCurrentPartner(activePartner.id);

    return () => {
      reset();
    };
  }, [activePartner.id, getRegistrationAddress, reset, getCurrentPartner]);

  useEffect(() => {
    manageBanner(
      partner.businessInformation.status,
      partner.businessInformation.type
    );
    manageTagMessage(
      partner.businessInformation.status,
      partner.businessInformation.type
    );
  }, [bannerContent, displayBanner, manageBanner, manageTagMessage, partner]);

  const registrationAddress = useCurrentPartnerStore(
    registrationAddressSelector
  );
  const disableNewShipment = useNewShipmentDisabled(partner);

  return (
    <div>
      {displayBanner && (
        <Banner
          bgPattern={bgBanner}
          title={t(
            `dashboard:banner.${bannerContent}.title` as unknown as TemplateStringsArray
          )}
          message={t(
            `dashboard:banner.${bannerContent}.message` as unknown as TemplateStringsArray
          )}
          ctaLabel={t('dashboard:banner.btnLabel')}
          customStyle={{ marginBottom: '3.375rem' }}
          buttonProps={{
            variant: 'secondary',
            color: 'info',
            onClick: () => navigateAccount('/account/partnership/information'),
          }}
        />
      )}
      <Title pageName={t('general:dashboard')} />
      <ShipmentBannerWrapper mb="1.5rem">
        <ShipmentBanner bgImage={boxBannerShipment}>
          <Row flexDirection="column">
            <ShipementBannerText
              variant="titleM"
              color="custom.neutral.white.pure"
            >
              {t('dashboard:createShipment.title')}
            </ShipementBannerText>
            <ShipementBannerText
              variant="titleXL"
              color="custom.neutral.white.pure"
            >
              {t('dashboard:createShipment.description')}
            </ShipementBannerText>
            {tagCreateShipmentMessage && (
              <ShipementBannerText
                variant="titleM"
                color="custom.neutral.white.pure"
                bgColor={theme.colors.green.basic50}
                borderRadius="8px"
                paddingTop="12px"
                paddingBottom="8px"
                paddingRight="10px"
                paddingLeft="14px"
              >
                <LockOnIcon />
                {tagCreateShipmentMessage}
              </ShipementBannerText>
            )}
          </Row>
        </ShipmentBanner>

        <CreateShipmentButton
          action="tertiary"
          disabled={disableNewShipment}
          onClick={() => {
            navigate(
              generatePath(PATHS.SHIPMENT.LABEL_IMPORT, {
                partnerId: activePartner.id,
              })
            );
          }}
          data-testid="partnerHome-create-shipment-button"
        >
          <Text variant="bodyTextL">
            {t('dashboard:createShipment.btnLabel')}
          </Text>
          <span>
            <AddIcon />
          </span>
        </CreateShipmentButton>
      </ShipmentBannerWrapper>
      <Row mb="1.5rem">
        <Row flexDirection="column" mr="1.5rem" width="100%">
          <Text my={4} variant="titleM">
            {t('dashboard:overviewSection.title')}
          </Text>
          <Row mb="14px">
            <Text variant="titleXS" paddingRight="2px">
              {t('dashboard:overviewSection.lastUpdate')}
            </Text>
            {update > 0 && (
              <Text variant="titleXS" color="custom.neutral.black.50">
                {formatTimestamp(update, {
                  day: 'numeric',
                  month: 'numeric',
                  year: 'numeric',
                  hour: '2-digit',
                  minute: '2-digit',
                })}
              </Text>
            )}
          </Row>
          <HomeShipmentCard setUpdate={setUpdate} />
        </Row>
      </Row>
      <Row flexDirection="column">
        <Text my={4} variant="titleM">
          {t('partnerSettings:partnerInfo')}
        </Text>
        <HomeInfoCard
          subtitle={activePartner.name}
          address={registrationAddress}
          website={partner.website}
          phoneNumber={partner.phoneNumber}
          editLink={generatePath(PATHS.SETTINGS_PARTNER_PARTNERSHIP, {
            partnerId: activePartner.id,
          })}
        />
      </Row>
    </div>
  );
};

export default PartnerHome;
