import i18next from 'i18next';
import * as yup from 'yup';

export const partnerProfileSettingsSchema = () =>
  yup.object().shape({
    firstName: yup.string().required(i18next.t('general:fieldError')),
    lastName: yup.string().required(i18next.t('general:fieldError')),
    phoneNumber: yup.string().required(i18next.t('general:fieldError')),
    preferredLanguage: yup.string().required(i18next.t('general:fieldError')),
  });
