import React, { useCallback } from 'react';

import { Button } from '@livingpackets/design-system-react';
import {
  DeleteIcon,
  Text,
  styled,
} from '@livingpackets/design-system-react-next';
import { useTranslation } from 'react-i18next';

interface IDeleteModal {
  title: string;
  ctaText: string;
  open: boolean;
  onAction: () => void;
  text?: string;
  setOpen?: (arg: boolean) => void;
  onClose?: any;
  children?: React.ReactNode;
  loading?: boolean;
}

const Mask = styled('div')`
  width: 100vw;
  height: 100vh;
  background-color: ${({ theme }) => theme.palette.custom.neutral.black[30]};
  opacity: 20%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
`;

const Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: fit-content;
  width: 35rem;
  border-radius: 20px;
  background-color: ${({ theme }) => theme.colors.white};
  padding: 2.5rem 1.5rem 1.5rem 1.5rem;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 11;
`;

const WrapperButtons = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;

const ActionModal = ({
  open,
  setOpen,
  onClose,
  onAction,
  title,
  text,
  ctaText,
  children,
  loading,
}: IDeleteModal) => {
  const { t } = useTranslation('general');
  const handleClose = useCallback(() => {
    setOpen && setOpen(false);
    onClose && onClose();
  }, [setOpen, onClose]);

  if (!open) return <></>;

  return (
    <>
      <Mask onClick={handleClose} />
      <Wrapper>
        <Text variant="titleS" mb={4}>
          {title}
        </Text>
        {text && (
          <Text variant="bodyTextS" mb="1.5rem">
            {text}
          </Text>
        )}
        {children}
        <WrapperButtons>
          <Button
            action="primary"
            icon={<DeleteIcon />}
            onClick={onAction}
            isLoading={loading}
          >
            {ctaText}
          </Button>
          <Button action="tertiary" onClick={handleClose}>
            {t('cancel')}
          </Button>
        </WrapperButtons>
      </Wrapper>
    </>
  );
};

export default ActionModal;
