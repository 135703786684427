import {
  getSMArticleDateFormat,
  getNewsletterDateFormat,
} from 'components/commonFunctions/DateCalculation';
import { NewsletterItem } from 'models/newsletter';
import { SocialMediaArticle } from 'models/socialMediaArticle';

import useFormatTimestamp from './useFormatTimestamp';
import useActiveLanguage from './useGetActiveLanguage';

export type DashboardDataElement = SocialMediaArticle | NewsletterItem;

export interface LanguageProps {
  [key: string]: {
    title: keyof DashboardDataElement;
    url: keyof DashboardDataElement;
    description?: keyof DashboardDataElement;
  };
}

const useGetDashboardData = (element: DashboardDataElement) => {
  const formatTimestamp = useFormatTimestamp();
  const activeLang = useActiveLanguage();
  const languageProps: LanguageProps = {
    fr: {
      title: 'title_fr',
      url: 'url_fr',
      description: 'description_fr',
    },
    de: {
      title: 'title_de',
      url: 'url_de',
      description: 'description_de',
    },
    en: {
      title: 'title',
      url: 'url',
      description: 'description',
    },
  };

  const { title, url, description } = languageProps[activeLang];

  const publicated_at_smArticle = formatTimestamp(
    new Date(element.publicated_at),
    getSMArticleDateFormat(activeLang)
  );

  const publicated_at_newsletter = formatTimestamp(
    new Date(element.publicated_at),
    getNewsletterDateFormat()
  );

  return {
    title: element[title],
    url: element[url],
    description:
      description && element.hasOwnProperty(description)
        ? element[description]
        : undefined,
    publicated_at_smArticle,
    publicated_at_newsletter,
  };
};

export default useGetDashboardData;
