import React, { useCallback } from 'react';

import { Button } from '@livingpackets/design-system-react';
import { Stack } from '@livingpackets/design-system-react-next';
import {
  AuthenticationView,
  DescriptionTextFontStyleEnum,
} from 'components/containers/AuthenticationView';
import { PATHS } from 'configs';
import useImperativeRequest from 'hooks/useImperativeRequest';
import useResendVerificationEmail from 'hooks/useResendVerificationEmail';
import useToastMessages from 'hooks/useToastMessages';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import useAppState, {
  dispatch,
  userSelector,
} from 'stores/appState/useAppState';

const VerifyEmail = () => {
  const appUser = useAppState(userSelector);
  const { t } = useTranslation(['general', 'authentication', 'messages']);
  const [, makeRequest] = useImperativeRequest('lpAccount');
  const navigate = useNavigate();
  const { error: errorToast, success: successToast } = useToastMessages();

  const { sendEmail, loading } = useResendVerificationEmail();

  const handleResendVerificationEmail = useCallback(async () => {
    sendEmail()
      .then(() => {
        successToast('messages:verificationSent.message', { wide: true });
      })
      .catch(() => {
        errorToast('messages:somethingWentWrong.message', { wide: true });
      });
  }, [sendEmail, successToast, errorToast]);

  const handleEmailVerifiedCheck = useCallback(async () => {
    const { data } = await makeRequest({ path: 'api/v1/me' });
    if (data?.profile?.emailVerified) {
      dispatch({ type: 'SET_USER', payload: data.profile });
      navigate(PATHS.AUTHENTICATION.RETRIEVE_DELIVERY_KEY);

      return;
    }
    errorToast('messages:needToVerifyToast.message', { wide: true });
  }, [navigate, makeRequest, errorToast]);

  return (
    <AuthenticationView
      title={t('authentication:verifyEmail.title')}
      description={
        appUser
          ? t('authentication:verifyEmail.description', {
              email: appUser.email,
            })
          : ''
      }
      descriptionFontStyle={DescriptionTextFontStyleEnum.REGULAR}
    >
      <Stack>
        <Button
          action="primary"
          onClick={() => handleResendVerificationEmail()}
          isDisabled={loading}
          style={{ width: '100%', marginBottom: '16px' }}
        >
          {t('authentication:verifyEmail.buttons.resend')}
        </Button>
        <Button
          action="tertiary"
          onClick={() => handleEmailVerifiedCheck()}
          style={{ width: '100%', marginTop: '0' }}
        >
          {t('authentication:verifyEmail.buttons.confirmVerification')}
        </Button>
      </Stack>
    </AuthenticationView>
  );
};

export default VerifyEmail;
