import React, { useCallback, useEffect } from 'react';

import { DownloadAppsView } from 'components/containers/DownloadAppsView';
import { IOS_APP_STORE_APP_LINK, PLAY_STORE_APP_LINK } from 'configs';
import useDownloadApp from 'hooks/useDownloadApp';
import { isAndroid, isIOS } from 'react-device-detect';
import { useTranslation } from 'react-i18next';

export function DownloadMobileApps() {
  const { t } = useTranslation('apps');
  const { isUserRedirectedToStorePage, setIsUserRedirectedToStorePage } =
    useDownloadApp();

  const redirectToAccountCreatedSuccessPage = useCallback(() => {
    if (isAndroid) {
      setIsUserRedirectedToStorePage(true);
      window.location.href = PLAY_STORE_APP_LINK;
    }

    if (isIOS) {
      setIsUserRedirectedToStorePage(true);
      window.location.href = IOS_APP_STORE_APP_LINK;
    }
  }, [setIsUserRedirectedToStorePage]);

  useEffect(() => {
    if (!isUserRedirectedToStorePage()) {
      redirectToAccountCreatedSuccessPage();
    }
  }, [redirectToAccountCreatedSuccessPage, isUserRedirectedToStorePage]);

  return (
    <DownloadAppsView
      title={t('downloadMobileApps.title')}
      description={t('downloadMobileApps.description')}
      stepList={[
        { stepNumber: '1', stepText: t('downloadMobileApps.text.download') },
        { stepNumber: '2', stepText: t('downloadMobileApps.text.login') },
      ]}
    />
  );
}
