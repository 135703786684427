import {
  BleProductType,
  BleProductTypeKeys,
  BleProductTypeValues,
} from '../types/bleProductType';

export const getBleProductTypeKeysValue = (
  value: BleProductTypeValues
): BleProductTypeKeys | null => {
  const keys = Object.keys(BleProductType) as BleProductTypeKeys[];
  for (const key of keys) {
    if (BleProductType[key] === value) {
      return key;
    }
  }

  return null;
};
