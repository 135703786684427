import { storeSelector } from 'helpers/paginatedStoreHelpers';
import { shallow } from 'zustand/shallow';

import usePaginatedTableData from './usePaginatedTableData';
import useContactAddressStore from '../stores/useContactAddressStore';
import useMyPartnersStore, {
  activePartnerSelector,
} from '../stores/useMyPartnersStore';

const useGetPartnerContacts = () => {
  const activePartner = useMyPartnersStore(activePartnerSelector);

  const { pageSize, offset, sortBy, order, dispatch, total, searchTerm } =
    useContactAddressStore(storeSelector, shallow);

  const { onSortingChange, onPaginationChange, loading, refetch } =
    usePaginatedTableData({
      params: {
        path: `api/v1/me/partners/${activePartner.id}/contacts`,
        method: 'get',
      },
      service: 'lpVillage',
      dispatch,
      queryParams: {
        pageSize,
        offset,
        sortBy,
        order,
        search: searchTerm ? searchTerm : undefined,
      },
    });

  return {
    loading,
    onSortingChange,
    onPaginationChange,
    refetch,
    total,
    searchTerm,
  };
};

export default useGetPartnerContacts;
