import React, { useEffect } from 'react';

import { Grid, PhoneNumber } from '@livingpackets/design-system-react-next';
import Input from 'components/atoms/Input';
import useFormTemplate from 'hooks/useFormTemplate';
import { set } from 'lodash';
import get from 'lodash/get';
import { IContact, IContactInformationForm } from 'models/contactAddress';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { contactBasicInformationSchema } from 'schemas/contactAddressSchema';
import useMyPartnersStore, {
  activePartnerSelector,
} from 'stores/useMyPartnersStore';

const inputConfig = {
  shouldValidate: true,
  shouldDirty: true,
  shouldTouch: true,
};

interface IContactInformationType {
  defaultValues?: IContact;
  onValuesChanged: (values: IContactInformationForm) => void;
  isFormValidChanged: (isFormValid: boolean) => void;
  triggerResetForm?: boolean;
}

const ContactForm = ({
  defaultValues,
  onValuesChanged,
  isFormValidChanged,
  triggerResetForm,
}: IContactInformationType) => {
  const { t } = useTranslation(['contactAddress', 'profileSettings']);

  const { smsEnabled } = useMyPartnersStore(activePartnerSelector);

  const {
    errors,
    register,
    formState: { touchedFields, isValid },
    getValues,
    setValue,
    reset,
    control,
  } = useFormTemplate<IContactInformationForm>({
    resolver: contactBasicInformationSchema({ smsEnabled }),
    defaultValues,
  });

  useEffect(() => {
    if (defaultValues) {
      setValue('firstName', defaultValues.firstName, inputConfig);
      setValue('lastName', defaultValues.lastName, inputConfig);
      setValue('email', defaultValues.email, inputConfig);
      setValue('phoneNumber', defaultValues.phoneNumber, inputConfig);

      // Manually set the fields as touched
      set(touchedFields, `firstName`, true);
      set(touchedFields, `lastName`, true);
      set(touchedFields, `email`, true);
      set(touchedFields, `phoneNumber`, true);

      onValuesChanged(getValues() as IContactInformationForm);
    }
  }, [defaultValues, onValuesChanged, touchedFields, setValue, getValues]);

  useEffect(() => {
    isFormValidChanged(isValid);
  }, [isValid, isFormValidChanged]);

  useEffect(() => {
    if (triggerResetForm) {
      reset();
    }
  }, [triggerResetForm, reset]);

  const handleInputChange = (event?: React.ChangeEvent<HTMLInputElement>) => {
    onValuesChanged(getValues() as IContactInformationForm);
  };

  return (
    <Grid container component="form" columnSpacing={2}>
      <Grid item mobile={12} tablet={6}>
        <Input
          name="firstName"
          label={
            get(touchedFields, 'firstName', false)
              ? t('contactAddress:form.firstName.placeholder')
              : undefined
          }
          placeholder={t('contactAddress:form.firstName.placeholder')}
          error={errors.firstName}
          isTouched={get(touchedFields, 'firstName', false)}
          register={register}
          onChange={handleInputChange}
          width="100%"
        />
      </Grid>
      <Grid item mobile={12} tablet={6}>
        <Input
          name="lastName"
          label={
            get(touchedFields, 'lastName', false)
              ? t('contactAddress:form.lastName.placeholder')
              : undefined
          }
          placeholder={t('contactAddress:form.lastName.placeholder')}
          error={get(errors, 'lastName', false)}
          isTouched={get(touchedFields, 'lastName', false)}
          register={register}
          onChange={handleInputChange}
          width="100%"
        />
      </Grid>
      <Grid item mobile={12} tablet={6}>
        <Input
          name="email"
          label={
            get(touchedFields, 'email', false)
              ? t('contactAddress:form.email.placeholder')
              : undefined
          }
          placeholder={t('contactAddress:form.email.placeholder')}
          error={get(errors, 'email', false)}
          isTouched={get(touchedFields, 'email', false)}
          register={register}
          onChange={handleInputChange}
          width="100%"
        />
      </Grid>

      <Grid item mobile={12} tablet={6}>
        <Controller
          name="phoneNumber"
          control={control}
          render={({ field, fieldState }) => (
            <PhoneNumber
              label={t('contactAddress:form.phoneNumber.placeholder')}
              defaultCountryCode="FR"
              error={fieldState.invalid}
              helperText={fieldState.error?.message}
              {...field}
              onChange={value => {
                field.onChange(value);
                handleInputChange();
              }}
            />
          )}
        />
      </Grid>
    </Grid>
  );
};

export default ContactForm;
