import React, { useCallback, useState } from 'react';

import { ArrowLeftIcon } from '@livingpackets/design-system-react-next';
import { Marker } from '@react-google-maps/api';
import Map from 'components/molecules/Map';
import styled, { useTheme } from 'styled-components';

import ShowroomImage from '../../assets/img/showrooms/map_showroom@10x.png';
import InfoPointImage from '../../assets/img/showrooms/map_tooltip@10x.png';
import { centers, defaultCenter } from '../organisms/ProfileShowroom';

const IconWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 0.75rem;
  z-index: 1;
  position: absolute;
  left: 2rem;
  top: 2rem;
`;
const ShowroomMap = ({ center, zoom, onClick, onMarkerClick }: any) => {
  const { colors } = useTheme();

  const showroomIconWidth = 70;
  const showroomIconHeight = 74;
  const infoPointIconWidth = 70;
  const infoPointIconHeight = 70;
  const [, setMap] = useState(null);
  const [isMapLoaded, setIsMapLoaded] = useState<boolean>(false);

  const updateIsMapLoaded = useCallback(
    (isMapLoadedInfo: boolean, mapInstance: any) => {
      setIsMapLoaded(isMapLoadedInfo);
      setMap(mapInstance);
    },
    []
  );

  return (
    <Map
      isFullScreen={true}
      fullscreenControl={false}
      streetViewControl={false}
      minZoom={2}
      maxZoom={20}
      zoom={zoom}
      center={center}
      isMapLoadedAction={updateIsMapLoaded}
      width="67%"
      height="41.825rem"
      bounds={[
        { id: 'showroom', position: centers[0] },
        { id: 'infoPoint1', position: centers[1] },
        { id: 'infoPoint2', position: centers[2] },
      ]}
    >
      <>
        {isMapLoaded && window.google && (
          <>
            {center !== defaultCenter ? (
              <IconWrapper onClick={onClick}>
                <ArrowLeftIcon color={colors.primary[100]} cursor="pointer" />
              </IconWrapper>
            ) : (
              <></>
            )}
            <Marker
              icon={{
                url: ShowroomImage,
                scaledSize: new google.maps.Size(
                  showroomIconWidth,
                  showroomIconHeight
                ),
              }}
              position={centers[0]}
              onClick={() => onMarkerClick(0)}
            />
            <Marker
              icon={{
                url: InfoPointImage,
                scaledSize: new google.maps.Size(
                  infoPointIconWidth,
                  infoPointIconHeight
                ),
              }}
              position={centers[1]}
              onClick={() => onMarkerClick(1)}
            />
            <Marker
              icon={{
                url: InfoPointImage,
                scaledSize: new google.maps.Size(
                  infoPointIconWidth,
                  infoPointIconHeight
                ),
              }}
              position={centers[2]}
              onClick={() => onMarkerClick(2)}
            />
          </>
        )}
      </>
    </Map>
  );
};

export default ShowroomMap;
