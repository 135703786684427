import React, { useCallback, useMemo, useState } from 'react';

import {
  Text,
  Box,
  EmptyState,
  AddIcon,
  BulletWrongIcon,
  ChevronDownIcon,
  SearchIcon,
  ButtonV2,
  ArrowIcon,
  Stack,
  styled,
  useTheme,
} from '@livingpackets/design-system-react-next';
import { usePagination } from '@livingpackets/shared-components';
import Input from 'components/atoms/Input';
import Title from 'components/molecules/Title';
import PartnerMembersTable from 'components/organisms/PartnerMembersTable';
import { PATHS } from 'configs';
import { PartnerAccountSearchFieldEnum } from 'enums/partnerAccountEnum';
import useApiHelper from 'helpers/apiHelper';
import useFormTemplate from 'hooks/useFormTemplate';
import { GetPartnerAccountsParams } from 'hooks/useGetPartnerAccounts';
import { usePartnerAccounts } from 'hooks/usePartnerAccounts';
import { debounce } from 'lodash';
import get from 'lodash/get';
import { ApiFilterModel, ApiFilterTypeEnum } from 'models/api';
import {
  INITIAL_STATE_PARTNER_ACCOUNT_SEARCH_FORM,
  IPartnerAccountSearchForm,
} from 'models/user';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate } from 'react-router-dom';
import { partnerAccountSearchSchema } from 'schemas/memberSchema';
import { shallow } from 'zustand/shallow';

import { storeSelector } from '../../../helpers/paginatedStoreHelpers';
import useMyPartnersStore, {
  activePartnerSelector,
} from '../../../stores/useMyPartnersStore';
import usePartnerMemberManagementStore from '../../../stores/usePartnerMemberManagementStore';

const { USER_MANAGEMENT } = PATHS;

const WrapperHeader = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 1rem;
`;

const InputContainer = styled('div')`
  position: relative;
  width: 100%;
`;

const InputSearch = styled(Input)<{
  searchFieldHighLighted: boolean;
}>`
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-color: ${({ theme, searchFieldHighLighted }) =>
    searchFieldHighLighted ? theme.colors.primary[100] : theme.colors.white};
  transition: 200ms;
  border-style: solid solid solid none;
`;

const SearchFieldIconContainer = styled(Box)`
  transition: 200ms;
  margin-left: 0;

  height: 25px;
  width: 25px;
`;

const SearchFieldSelector = styled(Box)<{
  searchFieldHighLighted: boolean;
}>`
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
  border-width: 1px;
  border-style: solid none solid solid;
  border-color: ${({ theme, searchFieldHighLighted }) =>
    searchFieldHighLighted
      ? theme.palette.custom.primary[100]
      : theme.palette.custom.neutral.white.pure};
  background-color: ${({ theme }) => theme.palette.custom.neutral.white.pure};
  transition: 200ms;
  height: 50px;
  width: 188px;
  min-width: 188px;
  padding-left: 12px;
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
  z-index: 1;
  padding-right: 5px;

  &:before {
    content: '';
    position: absolute;
    right: 0;
    height: 32px;
    width: 1px;
    background-color: ${({ theme }) => theme.palette.custom.neutral.black[20]};
    margin-top: auto;
    margin-bottom: auto;
    top: 50%;
    bottom: 50%;
  }
`;

const PartnerAccountSearchFieldListContainer = styled(Box)`
  border-bottom-left-radius: 14px;
  border-bottom-right-radius: 14px;
  border-width: 1px;
  border-style: solid none solid solid;
  border-color: ${({ theme }) => theme.palette.custom.neutral.black[10]};
  background-color: ${({ theme }) => theme.palette.custom.neutral.white.pure};
  position: absolute;
  left: 0;
  top: 50px;
  width: 100%;
  padding-top: 12px;
  margin-top: -12px;
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.1);
`;

const PartnerAccountSearchFieldContainer = styled(Box)`
  padding: 10px;
  cursor: pointer;
  transition: 200ms;

  &:hover {
    background-color: ${({ theme }) => theme.colors.primary[10]};
  }

  &:last-child {
    border-bottom-left-radius: 14px;
    border-bottom-right-radius: 14px;
  }
`;

const InputIconContainer = styled('div')<{
  displaySearchMagnifierIcon: boolean;
}>`
  position: absolute;
  right: 12px;
  top: ${({ displaySearchMagnifierIcon }) =>
    displaySearchMagnifierIcon ? '12px' : '15px'};
`;

const ClearButton = styled('button')`
  cursor: pointer;
  border: none;
  background: none;
`;

ClearButton.defaultProps = {
  children: <BulletWrongIcon size="1rem" />,
  type: 'button',
};

const PartnerMemberManagementOverview = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  const activePartner = useMyPartnersStore(activePartnerSelector);
  const { t } = useTranslation(['general', 'userManagement']);
  const [
    partnerAccountSearchFieldSelectedForDisplay,
    setPartnerAccountSearchFieldSelectedForDisplay,
  ] = useState<PartnerAccountSearchFieldEnum>(
    PartnerAccountSearchFieldEnum.LAST_NAME
  );

  const [
    partnerAccountSearchFieldListDisplayed,
    setPartnerAccountSearchFieldListDisplayed,
  ] = useState<boolean>(false);

  const [searchFieldHighLighted, setSearchFieldHighLighted] =
    useState<boolean>(false);

  const { getFilterStringFromGroupList } = useApiHelper();

  const partnerAccountSearchFieldList = [
    PartnerAccountSearchFieldEnum.LAST_NAME,
    PartnerAccountSearchFieldEnum.FIRST_NAME,
    PartnerAccountSearchFieldEnum.EMAIL,
  ];

  const [partnerAccountSearch, setPartnerAccountSearch] = useState<{
    searchTerm: string;
    field: PartnerAccountSearchFieldEnum;
  }>({
    searchTerm: '',
    field: PartnerAccountSearchFieldEnum.LAST_NAME,
  });

  const getSearchParamsInformation = useCallback(():
    | ApiFilterModel
    | undefined => {
    let search = undefined;
    if (partnerAccountSearch.searchTerm !== '') {
      switch (partnerAccountSearch.field) {
        case PartnerAccountSearchFieldEnum.LAST_NAME:
          search = {
            fieldName: 'last_name',
            fieldData: partnerAccountSearch.searchTerm,
            filterType: ApiFilterTypeEnum.INCLUDE,
          };
          break;
        case PartnerAccountSearchFieldEnum.FIRST_NAME:
          search = {
            fieldName: 'first_name',
            fieldData: partnerAccountSearch.searchTerm,
            filterType: ApiFilterTypeEnum.INCLUDE,
          };
          break;
        case PartnerAccountSearchFieldEnum.EMAIL:
          search = {
            fieldName: 'email',
            fieldData: partnerAccountSearch.searchTerm,
            filterType: ApiFilterTypeEnum.INCLUDE,
          };
          break;
      }
    }

    return search;
  }, [partnerAccountSearch]);

  const { params: paginationParams, setParams: setPaginationParams } =
    usePagination({
      pageSize: 6,
      orderDirection: 'DESC',
      orderColumn: 'updated_at',
      page: 1,
    });

  const partnerAccountsParams: GetPartnerAccountsParams = useMemo(() => {
    const search = getSearchParamsInformation();

    return {
      partnerId: activePartner.id,
      ...paginationParams,
      search: search
        ? getFilterStringFromGroupList([
            {
              list: [search],
            },
          ])
        : undefined,
    };
  }, [
    getSearchParamsInformation,
    activePartner.id,
    paginationParams,
    getFilterStringFromGroupList,
  ]);

  const fetchPartnerAccounts = usePartnerAccounts(partnerAccountsParams);
  const { rowData, total } = usePartnerMemberManagementStore(
    storeSelector,
    shallow
  );

  const [displaySearchMagnifierIcon, setDisplaySearchMagnifierIcon] =
    useState<boolean>(true);

  const {
    errors,
    register,
    formState: { touchedFields },
    getValues,
    reset,
  } = useFormTemplate<IPartnerAccountSearchForm>({
    resolver: partnerAccountSearchSchema,
    defaultValues: INITIAL_STATE_PARTNER_ACCOUNT_SEARCH_FORM,
  });

  const handleInputChange = () => {
    const formValues = getValues();
    const partnerAccountData = formValues.partnerAccountData;
    setDisplaySearchMagnifierIcon(!partnerAccountData);
    debouncedPartnerAccountSearch(partnerAccountData);
  };

  const triggerUpdateTerm = useCallback(
    (term: string) => {
      if (paginationParams.page !== 1) {
        setPaginationParams({ page: 1 });
      }

      setPartnerAccountSearch({
        searchTerm: term,
        field: partnerAccountSearchFieldSelectedForDisplay,
      });
    },
    [
      paginationParams,
      setPaginationParams,
      partnerAccountSearchFieldSelectedForDisplay,
      setPartnerAccountSearch,
    ]
  );

  const debouncedPartnerAccountSearch = debounce((value: string) => {
    if (value !== partnerAccountSearch.searchTerm) {
      if (value !== '') {
        triggerUpdateTerm(value);
      } else {
        handleClearSearch(false);
      }
    }
  }, 1500);

  const handleClearSearch = (resetFieldSearchField = true) => {
    if (paginationParams.page !== 1) {
      setPaginationParams({ page: 1 });
    }
    setDisplaySearchMagnifierIcon(true);

    if (resetFieldSearchField) {
      setPartnerAccountSearchFieldSelectedForDisplay(
        PartnerAccountSearchFieldEnum.LAST_NAME
      );

      setPartnerAccountSearch({
        searchTerm: '',
        field: PartnerAccountSearchFieldEnum.LAST_NAME,
      });
    } else {
      setPartnerAccountSearch({
        searchTerm: '',
        field: partnerAccountSearchFieldSelectedForDisplay,
      });
    }

    reset();
  };

  const redirectToCreateMemberPage = useCallback(() => {
    navigate(
      generatePath(USER_MANAGEMENT.CREATE, {
        partnerId: activePartner.id,
      })
    );
  }, [navigate, activePartner]);

  const redirectToUpdateMemberPage = useCallback(
    (userId: string) => {
      navigate(
        generatePath(USER_MANAGEMENT.EDIT, {
          partnerId: activePartner.id,
          memberId: userId,
        })
      );
    },
    [navigate, activePartner]
  );

  return (
    <Stack sx={{ height: '100%' }}>
      <Title pageName={t('userManagement:pageListTitle')} />
      <WrapperHeader>
        <Stack flex={1} gap="1.25rem">
          <Text variant="titleM">{t('userManagement:list.title')}</Text>
          <Stack direction="row" justifyContent="flex-end" zIndex={5}>
            <Box
              width="100%"
              marginRight={
                fetchPartnerAccounts.state === 'success' && total !== 0
                  ? '32px'
                  : 0
              }
            >
              <Stack direction="row">
                <Stack sx={{ position: 'relative' }}>
                  <SearchFieldSelector
                    searchFieldHighLighted={searchFieldHighLighted}
                    tabIndex={0}
                    onFocus={() => {
                      setPartnerAccountSearchFieldListDisplayed(true);
                      setSearchFieldHighLighted(true);
                    }}
                    onBlur={() => {
                      // Wait a short amount time in order avoid the field selection problem
                      setTimeout(function () {
                        if (partnerAccountSearchFieldListDisplayed) {
                          setPartnerAccountSearchFieldListDisplayed(false);
                        }

                        if (searchFieldHighLighted) {
                          setSearchFieldHighLighted(false);
                        }
                      }, 500);
                    }}
                    data-testid="partner-account-search-field-selector"
                  >
                    <Stack
                      flex={1}
                      direction="row"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Text variant="bodyTextM" color="custom.neutral.black.50">
                        {t(
                          `userManagement:list.partnerAccountSearchField.${partnerAccountSearchFieldSelectedForDisplay}`
                        )}
                      </Text>
                      <SearchFieldIconContainer>
                        <ChevronDownIcon
                          color={
                            partnerAccountSearchFieldListDisplayed
                              ? theme.palette.custom.primary[100]
                              : theme.palette.custom.neutral.black[50]
                          }
                        />
                      </SearchFieldIconContainer>
                    </Stack>
                  </SearchFieldSelector>
                  {partnerAccountSearchFieldListDisplayed && (
                    <PartnerAccountSearchFieldListContainer>
                      {partnerAccountSearchFieldList.map(
                        (
                          partnerAccountSearchField: PartnerAccountSearchFieldEnum
                        ) => (
                          <PartnerAccountSearchFieldContainer
                            onClick={() => {
                              setPartnerAccountSearchFieldSelectedForDisplay(
                                partnerAccountSearchField
                              );

                              if (partnerAccountSearch.searchTerm) {
                                setPartnerAccountSearch({
                                  ...partnerAccountSearch,
                                  field: partnerAccountSearchField,
                                });
                              }

                              setSearchFieldHighLighted(false);
                              setPartnerAccountSearchFieldListDisplayed(false);
                            }}
                            key={partnerAccountSearchField}
                            data-testid={
                              'partner-account-search-field-' +
                              partnerAccountSearchField
                            }
                          >
                            <Text
                              variant="bodyTextM"
                              color="custom.neutral.black.90"
                            >
                              {t(
                                `userManagement:list.partnerAccountSearchField.${partnerAccountSearchField}`
                              )}
                            </Text>
                          </PartnerAccountSearchFieldContainer>
                        )
                      )}
                    </PartnerAccountSearchFieldListContainer>
                  )}
                </Stack>
                <InputContainer style={{ marginLeft: 0 }}>
                  <InputSearch
                    id="partner-account-search-input"
                    name="partnerAccountData"
                    placeholder={t(
                      'userManagement:list.inputSearch.placeholder'
                    )}
                    error={get(errors, 'partnerAccountData', false)}
                    isTouched={get(touchedFields, 'partnerAccountData', false)}
                    register={register}
                    onChange={handleInputChange}
                    width="100%"
                    searchFieldHighLighted={searchFieldHighLighted}
                    onFocus={() => setSearchFieldHighLighted(true)}
                    onBlur={() => setSearchFieldHighLighted(false)}
                    style={{ borderStyle: 'solid solid solid none' }}
                    data-testid="partner-account-search-input"
                  />
                  <InputIconContainer
                    displaySearchMagnifierIcon={displaySearchMagnifierIcon}
                  >
                    {displaySearchMagnifierIcon ? (
                      <SearchIcon color={theme.colors.primary[100]} />
                    ) : (
                      <ClearButton
                        aria-label={t('general:clearSearch')}
                        title={t('general:clearSearch')}
                        onClick={() => handleClearSearch(false)}
                      />
                    )}
                  </InputIconContainer>
                </InputContainer>
              </Stack>
            </Box>
            {fetchPartnerAccounts.state === 'success' &&
              fetchPartnerAccounts.resource.total !== '0' && (
                <Box>
                  <ButtonV2
                    icon={AddIcon}
                    onClick={() => redirectToCreateMemberPage()}
                    data-testid="partner-account-redirect-create-member-button"
                    sx={{ textWrap: 'nowrap' }}
                  >
                    {t('userManagement:list.addNewMemberBtn')}
                  </ButtonV2>
                </Box>
              )}
          </Stack>
        </Stack>
      </WrapperHeader>
      <PartnerMembersTable
        partnerAccounts={rowData}
        total={total}
        partnerAccountsParams={partnerAccountsParams}
        pagination={paginationParams}
        onPaginationChange={setPaginationParams}
        onMemberEdit={user => redirectToUpdateMemberPage(user.account_id)}
        membersLoading={fetchPartnerAccounts.state !== 'success'}
      />
      {fetchPartnerAccounts.state === 'success' &&
        fetchPartnerAccounts.resource.total === '0' && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
            }}
          >
            {partnerAccountSearch.searchTerm === '' ? (
              <EmptyState type="Contact">
                <Text variant="titleM" color="custom.neutral.black.50">
                  {t('userManagement:list.emptyState.emptyMessage')}
                </Text>
                <Text variant="bodyTextL" color="custom.neutral.black.50">
                  {t('userManagement:list.emptyState.emptySubMessage')}
                </Text>
                <ButtonV2
                  icon={ArrowIcon}
                  sx={{ marginTop: '1.625rem' }}
                  data-testid="partner-account-redirect-create-member-button"
                  onClick={() => redirectToCreateMemberPage()}
                >
                  {t('userManagement:list.emptyState.cta')}
                </ButtonV2>
              </EmptyState>
            ) : (
              <EmptyState type="Contact">
                <Text variant="titleM" color="custom.neutral.black.50">
                  {t('userManagement:list.noResult.emptyMessage')}
                </Text>
                <Text variant="bodyTextL" color="custom.neutral.black.50">
                  {t('userManagement:list.noResult.emptySubMessage')}
                </Text>
                <ButtonV2
                  icon={ArrowIcon}
                  sx={{ marginTop: '1.625rem' }}
                  data-testid="partner-account-clear-search-button"
                  onClick={() => handleClearSearch(false)}
                >
                  {t('userManagement:list.noResult.cta')}
                </ButtonV2>
              </EmptyState>
            )}
          </Box>
        )}
    </Stack>
  );
};

export default PartnerMemberManagementOverview;
