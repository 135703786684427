import { useCallback } from 'react';

const isUserRedirectedToStorePageKey = 'isUserRedirectedToStorePageKey';

const useDownloadApp = () => {
  const isUserRedirectedToStorePage = useCallback(
    (): boolean =>
      sessionStorage.getItem(isUserRedirectedToStorePageKey) === 'true',
    []
  );

  const setIsUserRedirectedToStorePage = useCallback(
    (isUserRedirectedToStorePage: boolean) => {
      sessionStorage.setItem(
        isUserRedirectedToStorePageKey,
        String(isUserRedirectedToStorePage)
      );
    },
    []
  );

  return {
    isUserRedirectedToStorePage,
    setIsUserRedirectedToStorePage,
  };
};

export default useDownloadApp;
