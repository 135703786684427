import { useCallback } from 'react';

import { AUTH0_CONNECTION_NAME } from 'configs';
import { HttpRequestMethod } from 'enums/httpRequest';
import useImperativeRequest from 'hooks/useImperativeRequest';
import useToastMessages from 'hooks/useToastMessages';
import useAppState, { userSelector } from 'stores/appState/useAppState';

const useSendResetPasswordEmail = () => {
  const [{ loading }, makeRequest] = useImperativeRequest('auth');
  const { success: successToast, error: errorToast } = useToastMessages();
  const appUser = useAppState(userSelector);
  const email = appUser?.email;

  const sendResetPasswordEmail = useCallback(async () => {
    const onFailure = () => {
      errorToast('messages:somethingWentWrong.message');
    };

    const onSuccess = () => {
      successToast('messages:resetPasswordSent.message');
    };

    if (!email) {
      onFailure();

      return;
    }

    const { error } = await makeRequest({
      path: 'dbconnections/change_password',
      method: HttpRequestMethod.POST,
      body: {
        client_id: process.env.REACT_APP_AUTH_CLIENT_ID,
        connection: AUTH0_CONNECTION_NAME,
        email,
      },
    });

    if (error) {
      onFailure();

      return;
    }

    onSuccess();
  }, [email, makeRequest, errorToast, successToast]);

  return { sendResetPasswordEmail, loading };
};

export default useSendResetPasswordEmail;
