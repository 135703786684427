import React from 'react';

import { Tooltip } from '@livingpackets/design-system-react';
import { Text, Stack } from '@livingpackets/design-system-react-next';
import { useTranslation } from 'react-i18next';

const TooltipModifyBankInformation = () => {
  const { t } = useTranslation('contribution');

  return (
    <Tooltip
      noArrow
      clickable
      id="modify-bank-information-tooltip"
      place="bottom"
      width="275px"
      height="auto"
      isOpen={undefined}
      style={{ zIndex: 1, opacity: 1, height: 'auto' }}
    >
      <Stack>
        <Text variant="titleM" color="custom.neutral.black.100" mb=".625rem">
          {t(
            'contributionsTab.contributionsList.info.modifyBankInformation.title'
          )}
        </Text>
        <Text variant="bodyTextS" color="custom.neutral.black.100">
          {t(
            'contributionsTab.contributionsList.info.modifyBankInformation.action'
          )}
        </Text>
      </Stack>
    </Tooltip>
  );
};

export default TooltipModifyBankInformation;
