import { useCallback } from 'react';

import useImperativeRequest from 'hooks/useImperativeRequest';
import useContributionsStore, {
  setContributionOverviewSelector,
} from 'stores/useContributionsStore';

const useGetContribution = () => {
  const [{ loading }, makeRequest] = useImperativeRequest('lpAccountV3');
  const setContributionOverview = useContributionsStore(
    setContributionOverviewSelector
  );

  const getContributionOverview = useCallback(async () => {
    const { data, error } = await makeRequest({
      path: `/me/contributions/overview`,
      method: 'get',
    });

    if (error) {
      return { sucess: false };
    }

    if (data) {
      setContributionOverview(data);

      return { sucess: true };
    }
  }, [makeRequest, setContributionOverview]);

  return { loading, getContributionOverview };
};

export default useGetContribution;
