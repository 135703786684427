import React, { useCallback } from 'react';

import { useAuth0 } from '@auth0/auth0-react';
import {
  Text,
  ButtonV2 as Button,
  Stack,
} from '@livingpackets/design-system-react-next';
import {
  AuthenticationView,
  LpLogoSizeEnum,
} from 'components/containers/AuthenticationView';
import { PATHS } from 'configs';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const LandingPage = () => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation('authentication');
  const { loginWithRedirect } = useAuth0();

  const redirectToRetrieveDeliveryKeyPage = useCallback(() => {
    navigate(PATHS.AUTHENTICATION.RETRIEVE_DELIVERY_KEY);
  }, [navigate]);

  return (
    <AuthenticationView lpLogoSize={LpLogoSizeEnum.BIG}>
      <Stack>
        <Text variant="titleM" mt="1.5625rem" mb="3.125rem">
          {t('landing.title')}
        </Text>
        <Button
          onClick={() =>
            loginWithRedirect({
              redirectUri: window.location.origin + PATHS.ROOT,
              screen_hint: 'login',
              ui_locales: i18n.language,
            })
          }
          sx={{ width: '374px', marginBottom: '16px' }}
          data-testid="landing-page-log-in-btn"
        >
          {t('landing.buttons.logIn')}
        </Button>
        <Button
          variant="secondary"
          onClick={() =>
            loginWithRedirect({
              redirectUri: window.location.origin + PATHS.ROOT,
              screen_hint: 'signup',
              ui_locales: i18n.language,
            })
          }
          sx={{ width: '374px', marginTop: '0' }}
          data-testid="landing-page-sign-up-btn"
        >
          {t('landing.buttons.signUp')}
        </Button>
        <Stack direction="row" mt="2rem" justifyContent="center">
          <Text variant="titleM" color="custom.neutral.black.50">
            {t('landing.text.gotDeliveryKey')}
          </Text>
          &nbsp;
          <Text
            variant="titleM"
            color="custom.primary.100"
            sx={{ cursor: 'pointer' }}
            onClick={() => redirectToRetrieveDeliveryKeyPage()}
            data-testid="landing-page-delivery-key-link"
          >
            {t('landing.text.gotDeliveryKeyTextLink')}
          </Text>
        </Stack>
      </Stack>
    </AuthenticationView>
  );
};

export default LandingPage;
