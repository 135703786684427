var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import styled from "styled-components";
import React from "react";
const SVG = styled.svg `
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  transform-origin: center center;
`;
const Wrapper = styled.div `
  display: inline-flex;
  align-items: center;
  cursor: ${({ cursor }) => (cursor ? cursor : "inherit")};
  pointer-events: ${({ pe }) => (pe ? pe : "auto")};
  padding: ${({ pad }) => pad || "0"};
  transform: ${({ transform }) => transform || "initial"};
`;
const SVGWrapper = (props) => {
    const { cursor, pe, pad, onClick, wrapperProps, transform } = props, rest = __rest(props, ["cursor", "pe", "pad", "onClick", "wrapperProps", "transform"]);
    return (React.createElement(Wrapper, { cursor: cursor, pe: pe, pad: pad, transform: transform, onClick: onClick, style: wrapperProps },
        React.createElement(SVG, Object.assign({}, rest))));
};
export default SVGWrapper;
