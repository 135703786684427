import { useMutation } from '@tanstack/react-query';
import useImperativeRequest from 'hooks/useImperativeRequest';
import { queryClient } from 'lib/react-query';
import { useParams } from 'react-router-dom';

const deleteAddress =
  ({
    partnershipId,
    makeRequest,
  }: {
    partnershipId?: string;
    makeRequest: any;
  }) =>
  async (id: string): Promise<void> => {
    const { error } = await makeRequest({
      path: `api/v1/me/partners/${partnershipId}/addresses/${id}`,
      method: 'delete',
    });

    if (error) {
      throw new Error(error);
    }
  };

type UseDeleteAddressOptions = {
  partnershipId?: string;
};

export const useDeleteAddress = ({
  partnershipId,
}: UseDeleteAddressOptions = {}) => {
  const { partnerId } = useParams();

  let id = partnershipId;
  if (!id) {
    id = partnerId;
  }

  const [, makeRequest] = useImperativeRequest('lpVillage');

  return useMutation({
    onSuccess: () => {
      queryClient.refetchQueries({
        queryKey: ['partnership', id, 'addresses'],
      });
    },
    mutationFn: deleteAddress({ partnershipId: id, makeRequest }),
  });
};
