import { useCallback } from 'react';

import { useFetchResource } from '@livingpackets/shared-components';

import { useGetBoxContractStats } from './useGetBoxContractStats';

export function useBoxContractStats(partnerId?: string | undefined) {
  const getBoxContractsStats = useGetBoxContractStats();

  const fetchFunction = useCallback(
    () => getBoxContractsStats({ partnerId }),
    [getBoxContractsStats, partnerId]
  );

  return useFetchResource(fetchFunction);
}
