export const bytesToHex = (bytes: Uint8Array): string =>
  Array.from(bytes)
    .map(byte => byte.toString(16).padStart(2, '0'))
    .join('');

export const uuidFormat = (input: string): string => {
  const uuidRegex: RegExp = /^[0-9a-fA-F]{32}$/;

  if (uuidRegex.test(input)) {
    return `${input.slice(0, 8)}-${input.slice(8, 12)}-${input.slice(
      12,
      16
    )}-${input.slice(16, 20)}-${input.slice(20)}`;
  }

  return '';
};
