import i18next from 'i18next';
import * as yup from 'yup';

export const profileSettingsSchema = () =>
  yup.object().shape({
    firstName: yup.string().required(i18next.t('general:fieldError')),
    lastName: yup.string().required(i18next.t('general:fieldError')),
    phoneNumber: yup.string().required(i18next.t('general:fieldError')),
    preferredLanguage: yup.string().required(i18next.t('general:fieldError')),
    street: yup.string().required(i18next.t('general:fieldError')),
    houseNumber: yup.string().required(i18next.t('general:fieldError')),
    postalCode: yup.string().required(i18next.t('general:fieldError')),
    city: yup.string().required(i18next.t('general:fieldError')),
    countryCode: yup.string().required(i18next.t('general:fieldError')),
    company: yup.string(),
  });
