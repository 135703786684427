import { styled } from '@livingpackets/design-system-react-next';
import {
  background,
  BackgroundProps,
  layout,
  LayoutProps,
  space,
  SpaceProps,
} from 'styled-system';

const TextSkeleton = styled('span')<LayoutProps & SpaceProps & BackgroundProps>`
  display: inline-block;
  width: 80%;
  height: 1rem;
  background: ${({ theme }) => theme.palette.custom.neutral.black[4]};
  ${layout}
  ${space}
  ${background}
`;

export default TextSkeleton;
