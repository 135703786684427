export enum Carriers {
  ups = 'UPS',
  fedex = 'FEDEX',
  tnt = 'TNTExpress',
  chronopost = 'CHRONOPOST',
  colissimo = 'COLISSIMO',
  dhl = 'DHL',
  miist = 'MIIST',
  other = 'Other',
}

// Keep order intact
export const ENABLED_CARRIERS: Carriers[] = [
  Carriers.ups,
  Carriers.fedex,
  Carriers.tnt,
  Carriers.chronopost,
  Carriers.colissimo,
  Carriers.dhl,
  Carriers.miist,
  Carriers.other,
];
