import React from 'react';

import { Grid } from '@livingpackets/design-system-react-next';
import NonFullWidthWrapper from 'components/atoms/NonFullWidthWrapper';
import ProfileSettingsForm from 'components/molecules/ProfileSettingsForm';
import ResetPasswordForm from 'components/molecules/ResetPasswordForm';
import useGetPrimaryAddress from 'hooks/useGetPrimaryAddress';
import { INITIAL_STATE_LPACCOUNT_ADDRESS } from 'models/address';
import useMyAddressesStore, {
  primaryAddressSelector,
} from 'stores/useMyAddressesStore';

const ProfileSettings = () => {
  const { loading } = useGetPrimaryAddress();
  const primaryAddress = useMyAddressesStore(primaryAddressSelector);

  if (loading) {
    return null;
  }

  return (
    <NonFullWidthWrapper>
      <Grid
        container
        alignItems="flex-start"
        spacing="1.5rem"
        flexWrap={{ mobile: 'wrap', tablet: 'nowrap' }}
      >
        <Grid item mobile={12} tablet="auto">
          <ProfileSettingsForm
            initialState={primaryAddress || INITIAL_STATE_LPACCOUNT_ADDRESS}
          />
        </Grid>
        <Grid item mobile={12} tablet="auto">
          <ResetPasswordForm />
        </Grid>
      </Grid>
    </NonFullWidthWrapper>
  );
};

export default ProfileSettings;
