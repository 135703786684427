import React from 'react';

import { Button } from '@livingpackets/design-system-react';
import { useTranslation } from 'react-i18next';

interface ImportShipmentWaitingForFileActionType {
  onCarrierChange: () => void;
  handlePdfPageSelected: () => void;
  showChangeCarrier: boolean;
}

const ImportShipmentPdfPageSelectionAction = ({
  onCarrierChange,
  handlePdfPageSelected,
  showChangeCarrier,
}: ImportShipmentWaitingForFileActionType) => {
  const { t } = useTranslation('shipments');

  return (
    <>
      {showChangeCarrier && (
        <Button action="secondary" onClick={onCarrierChange}>
          {t('changeCarrier')}
        </Button>
      )}
      <Button action="primary" onClick={handlePdfPageSelected}>
        {t('selectPdfPage')}
      </Button>
    </>
  );
};

export default ImportShipmentPdfPageSelectionAction;
