import React from 'react';

import { CanDeleteModal } from './Modal/CanDelete';
import { CannotDeleteModal } from './Modal/CannotDelete';
import { ErrorGetMeDeleteAccountAvailable } from './Modal/ErrorGetMeDeleteAccountAvailable';
import { LoadingModal } from './Modal/Loading';
import { useMeDeleteAccountAvailable } from '../../../api/personal/getMeDeleteAccountAvailable';

export const DeleteModal = ({ open, setOpen }: any) => {
  const {
    data: canDeleteAccount,
    isPending,
    isError,
    refetch,
  } = useMeDeleteAccountAvailable({
    enabled: open,
  });

  const renderModal = () => {
    const props = { open, setOpen };

    if (isPending) {
      return <LoadingModal {...props} />;
    }

    if (isError) {
      return <ErrorGetMeDeleteAccountAvailable {...props} refetch={refetch} />;
    }

    if (canDeleteAccount) {
      return <CanDeleteModal {...props} />;
    }

    return <CannotDeleteModal {...props} />;
  };

  return renderModal();
};
