import React from 'react';

import { Stack, Text } from '@livingpackets/design-system-react-next';
import EventInformationLimitDisplayBlock from 'components/atoms/shipment/Timeline/EventInformationLimitDisplayBlock';
import { ShipmentTimelineThresholdTypeEnum } from 'enums/ShipmentTimelineEnum';
import useIntlFormatTimestamp from 'hooks/useIntlFormatTimestamp';
import useShipmentDetail from 'hooks/useShipmentDetail';
import {
  IShipmentTimelineEntry,
  IShipmentTimelineEntryMonoInfo,
} from 'models/shipment';
import { useTranslation } from 'react-i18next';

interface IEventShockBlockType {
  event: IShipmentTimelineEntry;
}

const EventShockBlock = ({ event }: IEventShockBlockType) => {
  const { t } = useTranslation('shipments');
  const formatTimestamp = useIntlFormatTimestamp();
  const { getAlertThreshold } = useShipmentDetail();

  return (
    <Stack
      direction="row"
      marginTop="11px !important"
      justifyContent="space-between"
    >
      <Stack direction="row">
        <Stack>
          <Text variant="bodyTextXS" marginBottom="8px">
            {t('detail.timeline.information.status')}
          </Text>
          <Text
            variant="bodyTextXS"
            marginBottom="8px"
            sx={{ whiteSpace: 'pre' }}
          >
            {t('detail.timeline.information.startOfAlert')}
          </Text>
        </Stack>
        <Stack marginLeft="10px !important">
          <Text variant="bodyTextXS" marginBottom="8px">
            {t('detail.timeline.information.shock.oneTime')}
          </Text>
          <Text
            variant="bodyTextXS"
            marginBottom="8px"
            sx={{ whiteSpace: 'pre' }}
          >
            {formatTimestamp(event.createdAt, {
              day: 'numeric',
              month: 'long',
              year: undefined,
              hour: '2-digit',
              minute: '2-digit',
            })}
          </Text>
        </Stack>
      </Stack>
      <Stack direction="row" justifyContent="flex-end" marginRight="64px">
        <EventInformationLimitDisplayBlock
          thresholdType={ShipmentTimelineThresholdTypeEnum.MAX}
          thresholdText={t(
            'detail.timeline.information.shock.thresholdLimitMax',
            {
              value: getAlertThreshold(event),
            }
          )}
          currentText={t('detail.timeline.information.shock.valueReached', {
            value: (event.payload as IShipmentTimelineEntryMonoInfo).value,
          })}
        />
      </Stack>
    </Stack>
  );
};

export default EventShockBlock;
