import React, { useCallback, useState } from 'react';

import {
  ShareIcon,
  Button,
  ModalV2 as Modal,
} from '@livingpackets/design-system-react-next';
import { IShipment } from 'models/shipment';
import { useTranslation } from 'react-i18next';
import useLayoutStore, { setPersistPanelSelector } from 'stores/useLayoutStore';

import { TrackingLinkForm } from './TrackingLinkForm';

interface IShareTrackingLink {
  shipmentId: string;
  shipment: IShipment;
}

export const ShareTrackingLink = ({
  shipmentId,
  shipment,
}: IShareTrackingLink) => {
  const { t } = useTranslation('shipments');

  const [modalOpen, setModalOpen] = useState(false);
  const [showSearchContact, setShowSearchContact] = useState<boolean>(false);
  const setPersistPanel = useLayoutStore(setPersistPanelSelector);

  const handleClose = useCallback(() => {
    setModalOpen(false);
    setPersistPanel(false);
    setShowSearchContact(false);
  }, [setPersistPanel]);

  const handleOpen = useCallback(() => {
    setModalOpen(true);
    setPersistPanel(true);
  }, [setPersistPanel]);

  return (
    <>
      <Button
        icon={<ShareIcon />}
        onClick={handleOpen}
        action="primary"
        data-testid="open-share-tracking"
      >
        {shipment.opening_code
          ? t('detail.tab.shareTrackingCodeButton')
          : t('detail.tab.shareTrackingLinkButton')}
      </Button>

      <Modal
        hideCloseIcon={!showSearchContact}
        open={modalOpen}
        onDismiss={() => setShowSearchContact(false)}
        setOpen={setModalOpen}
        maxWidth="sm"
        sx={{
          '.MuiDialog-paper': {
            padding: 0,
          },
        }}
      >
        <TrackingLinkForm
          shipment={shipment}
          shipmentId={shipmentId}
          handleClose={handleClose}
          displaySearchContact={setShowSearchContact}
        />
      </Modal>
    </>
  );
};
