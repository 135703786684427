import React, { useEffect, useState } from 'react';

import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
} from '@livingpackets/design-system-react';
import {
  Box,
  Divider,
  Grid,
  Stack,
  Text,
} from '@livingpackets/design-system-react-next';
import ShipmentAnonymousMapFullScreenModal from 'components/molecules/modals/ShipmentAnonymousMapFullScreenModal';
import ShipmentAnonymousDetails from 'components/organisms/ShipmentAnonymousDetails';
import { ContactUs } from 'features/account';
import { useRedirectToRetrieveKeyPage, Support } from 'features/shipments';
import usePublic from 'hooks/usePublic';
import { useShipmentAddByTrackingCode } from 'hooks/useShipmentAddByTrackingCode';
import useShipmentAnonymousDetail from 'hooks/useShipmentAnonymousDetail';
import { IShipmentAnonymousData } from 'models/shipment';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import useAppState, {
  anonymousTokenSelector,
  dispatch,
  userSelector,
} from 'stores/appState/useAppState';
import styled, { DefaultTheme, ThemeProps, withTheme } from 'styled-components';

const CardShipmentDetail = styled(Card)`
  width: 956px;

  @media (max-width: ${({ theme }) => theme.mediaBreakpoints.md}) {
    width: auto;
  }
`;

const CardShipmentHeader = styled(CardHeader)`
  background-color: ${({ theme }) => theme.colors.black[100]};
  color: ${({ theme }) => theme.colors.white};
  border-radius: 20px 20px 0 0;
  padding-top: 33px;
  padding-left: 24px;
  padding-bottom: 34px;
  margin-bottom: -15px;
`;

const PartnerShipmentsAnonymousDetail = ({
  theme,
}: ThemeProps<DefaultTheme>) => {
  const { t } = useTranslation([
    'shipments',
    'general',
    'tabs',
    'profileSettings',
  ]);
  const appUser = useAppState(userSelector);

  const navigate = useNavigate();
  const anonymousToken = useAppState(anonymousTokenSelector);
  const { trackingCode } = useParams<{ trackingCode: string }>();

  const { getShipmentAnonymousDetail } = useShipmentAnonymousDetail();
  const { postSignup } = usePublic();
  const shipmentAddByTrackingCode = useShipmentAddByTrackingCode();

  const [shipmentData, setShipmentData] = useState<IShipmentAnonymousData>();

  const [isShipmentPageFullyLoaded, setIsShipmentPageFullyLoaded] =
    useState<boolean>(false);

  const [showShipmentMapFullScreenModal, setShowShipmentMapFullScreenModal] =
    useState<boolean>(false);

  const redirectToRetrieveKeyPage = useRedirectToRetrieveKeyPage();

  const isSpecificPartner = [
    process.env.REACT_APP_LOUIS_VUITTON_PARTNER_ID,
    process.env.REACT_APP_LOUIS_VUITTON_COMPANY_PARTNER_ID,
  ].includes(shipmentData?.shipment.partner_id);

  useEffect(() => {
    postSignup().then(({ success, tokenResponse }) => {
      if (!success) {
        redirectToRetrieveKeyPage();
      } else {
        if (tokenResponse) {
          dispatch({
            type: 'SET_ANONYMOUS_TOKEN',
            payload: tokenResponse.accessToken,
          });
        }
      }
    });
  }, [postSignup, navigate, redirectToRetrieveKeyPage]);

  useEffect(() => {
    if (!shipmentData && anonymousToken && trackingCode) {
      shipmentAddByTrackingCode(trackingCode).then(
        async ({ success: successShipment, shipment }) => {
          if (!successShipment) {
            redirectToRetrieveKeyPage();
          } else {
            if (shipment?.id) {
              await getShipmentAnonymousDetail(shipment?.id).then(
                ({ success: successShipmentData, shipmentData }) => {
                  if (!successShipmentData) {
                    redirectToRetrieveKeyPage();
                  } else {
                    if (shipmentData) {
                      setShipmentData(shipmentData);
                      setIsShipmentPageFullyLoaded(true);
                    }
                  }
                }
              );
            }
          }
        }
      );
    }
  }, [
    navigate,
    anonymousToken,
    shipmentAddByTrackingCode,
    shipmentData,
    trackingCode,
    appUser,
    getShipmentAnonymousDetail,
    redirectToRetrieveKeyPage,
  ]);

  return isShipmentPageFullyLoaded ? (
    <>
      <CardShipmentDetail>
        <React.Fragment key=".0">
          <CardShipmentHeader>
            <Text variant="titleXL" mt="7px" color="custom.neutral.white.pure">
              {process.env.REACT_APP_LOUIS_VUITTON_PARTNER_ID ===
              shipmentData?.shipment.partner_id
                ? t('shipments:anonymous.mobile.titleView')
                : t('shipments:detail.title')}
            </Text>
          </CardShipmentHeader>
          <CardBody paddingLeft="40px" paddingRight="32px" paddingTop="13px">
            {shipmentData && (
              <ShipmentAnonymousDetails
                shipmentData={shipmentData}
                onShipmentMapZoomClick={() =>
                  setShowShipmentMapFullScreenModal(true)
                }
              />
            )}
          </CardBody>
          <CardFooter backgroundColor="transparent">
            <Stack
              direction="row"
              justifyContent="space-between"
              marginTop={3}
            ></Stack>
          </CardFooter>
        </React.Fragment>
      </CardShipmentDetail>
      <Box width="59.75rem" paddingY="1.5rem">
        <Divider />
        <Grid container direction="column" gap=".875rem" paddingTop="1.5rem">
          <Grid item>
            <Text variant="titleL" color="custom.neutral.black.50">
              {t('profileSettings:needHelp')}
            </Text>
          </Grid>

          {isSpecificPartner ? (
            <Grid item mobile={12} tablet={6}>
              <Support partnerId={shipmentData?.shipment.partner_id} />
            </Grid>
          ) : (
            <Grid item mobile={12} tablet={6}>
              <ContactUs />
            </Grid>
          )}
        </Grid>
      </Box>
      {shipmentData && (
        <ShipmentAnonymousMapFullScreenModal
          visible={showShipmentMapFullScreenModal}
          onDismiss={() => setShowShipmentMapFullScreenModal(false)}
          shipmentData={shipmentData}
          hideMapFullScreenModal={() =>
            setShowShipmentMapFullScreenModal(false)
          }
        />
      )}
    </>
  ) : (
    <></>
  );
};

export default withTheme(PartnerShipmentsAnonymousDetail);
