import useImperativeRequest from './useImperativeRequest';
import useToastMessages from './useToastMessages';
import { RewardStatusEnum } from '../enums/contributionEnum';
import { HttpRequestMethod } from '../enums/httpRequest';

const useUpdateMeContributionRewardStatus = () => {
  const [{ loading, error }, makeRequest] = useImperativeRequest('lpAccountV3');
  const { error: toastError, success: toastSuccess } = useToastMessages();

  const updateContributionRewardStatus = async (
    contributionId: string,
    rewardStatus: RewardStatusEnum
  ) => {
    const { data, error } = await makeRequest({
      path: `/me/contributions/${contributionId}/reward_status`,
      method: HttpRequestMethod.PATCH,
      body: { reward_status: rewardStatus },
    });

    if (error) {
      toastError('messages:updateContributionRewardStatusError.message', {
        wide: true,
      });

      return { success: false };
    }
    toastSuccess('messages:updateContributionRewardStatusSuccess.message', {
      wide: true,
    });

    return { success: true, data };
  };

  return { updateContributionRewardStatus, loading, error };
};

export default useUpdateMeContributionRewardStatus;
