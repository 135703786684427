import { isBoolean } from 'lodash';
import { get, set } from 'lodash/fp';

/**
 * Filters the editable values from an input object based on the specified fields.
 * @param {Object} input - The input object containing the values to filter.
 * @param {string[]} fields - The fields to filter from the input object.
 * @param {boolean} [removeEmptyValues=false] - Whether to remove empty values from the filtered result.
 * @returns {Object} - The filtered values object.
 */
export const filterEditableValues = ({
  values,
  fields,
  removeEmptyValues = false,
}: {
  values: { [key: string]: any };
  fields: string[];
  removeEmptyValues?: boolean;
}): { [key: string]: any } => {
  let filteredValues: any = {};
  fields.forEach((field: string) => {
    const value = get(field, values);

    if (removeEmptyValues && value === '') {
      return;
    }

    filteredValues = set(field, value, filteredValues);
  });

  return filteredValues;
};

/**
 * Filters and returns the changed values between the initial values and the current values.
 * If a function is given as an argument, an error is thrown.
 * If a value is updated, it is included in the returned object.
 * If a value is not updated, it is excluded from the returned object.
 * @param {any} initialValues - The initial values object.
 * @param {any} values - The current values object.
 * @returns {Object} - The object containing the changed values.
 * @throws {Error} - If a function is given as an argument.
 */
export const filterChangedValues = ({
  initialValues,
  values,
}: {
  initialValues: any;
  values: any;
}) => {
  if (isFunction(initialValues) || isFunction(values)) {
    throw new Error('Invalid argument. Function given, object expected.');
  }

  if (isValue(initialValues) || isValue(values)) {
    const compare = compareValues(initialValues, values);

    // Only catch UPDATED values
    if (['VALUE_UPDATED'].includes(compare)) {
      if (isBoolean(values)) {
        return values;
      }

      return values || '';
    }

    return;
  }

  var diff: { [key: string]: any } = {};
  for (var key in initialValues) {
    if (isFunction(initialValues[key])) {
      continue;
    }

    var value2 = undefined;
    if (values[key] !== undefined) {
      value2 = values[key];
    }

    const response = filterChangedValues({
      initialValues: initialValues[key],
      values: value2,
    });

    if (response !== undefined) {
      diff[key] = response;
    }
  }
  for (var key2 in values) {
    if (isFunction(values[key2]) || diff[key2] !== undefined) {
      continue;
    }

    const response = filterChangedValues({
      initialValues: undefined,
      values: values[key2],
    });

    if (response !== undefined) {
      diff[key2] = response;
    }
  }

  return diff;
};

const compareValues = (value1: any, value2: any) => {
  if (value1 === value2) {
    return 'VALUE_UNCHANGED';
  }
  if (
    isDate(value1) &&
    isDate(value2) &&
    value1.getTime() === value2.getTime()
  ) {
    return 'VALUE_UNCHANGED';
  }
  if (value1 === undefined) {
    return 'VALUE_CREATED';
  }
  if (value2 === undefined) {
    return 'VALUE_DELETED';
  }

  return 'VALUE_UPDATED';
};

const isFunction = (x: Function) =>
  Object.prototype.toString.call(x) === '[object Function]';
const isArray = (x: Array<any>) =>
  Object.prototype.toString.call(x) === '[object Array]';
const isDate = (x: Date) =>
  Object.prototype.toString.call(x) === '[object Date]';
const isObject = (x: { [key: string]: any }) =>
  Object.prototype.toString.call(x) === '[object Object]';
const isValue = (x: any) => !isObject(x) && !isArray(x);

/**
 * Filters the values of an object based on the provided form keys
 * and returns a new object with only the allowed and changed values.
 *
 * @template T - The type of the object containing the values.
 * @param {Object} options - The options for filtering the values.
 * @param {T} options.initialValues - The initial values object.
 * @param {T} options.values - The current values object.
 * @param {string[]} options.formKeys - The keys representing the allowed fields.
 * @returns {Partial<T>} - A new object with only the allowed and changed values.
 */
export const filterValues = <T extends { [key: string]: any }>({
  initialValues,
  values,
  formKeys,
}: {
  initialValues: T;
  values: T;
  formKeys: string[];
}): Partial<T> => {
  // Keep only allowed fields
  let filteredValues = filterEditableValues({
    values,
    fields: formKeys,
  });

  // Keep only changed values
  filteredValues = filterChangedValues({
    initialValues,
    values: filteredValues,
  });

  return filteredValues as Partial<T>;
};
