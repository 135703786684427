import { useCallback } from 'react';

import {
  UseFetchResource,
  useFetchResource,
  UseFetchResourceOptions,
} from '@livingpackets/shared-components';
import {
  GetPartnerAccountsParams,
  PaginatedResponsePartnerAccount,
  useGetPartnerAccounts,
} from 'hooks/useGetPartnerAccounts';
import { IPartnerAccount } from 'models/user';

export function usePartnerAccounts(
  params: GetPartnerAccountsParams,
  { enabled = true }: UseFetchResourceOptions = {}
): UseFetchResource<PaginatedResponsePartnerAccount<IPartnerAccount>> & any {
  const getPartnerAccounts = useGetPartnerAccounts();

  const fetchFunction = useCallback(
    () => getPartnerAccounts(params),
    [getPartnerAccounts, params]
  );

  const fetchResource = useFetchResource(fetchFunction, { enabled });

  return {
    ...fetchResource,
    refetch: fetchFunction,
  };
}
