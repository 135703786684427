import i18next from 'i18next';
import * as yup from 'yup';

import { firstName, language, lastName, phoneNumber } from './common';

export const accountPersonalBasicInformation = () =>
  yup.object().shape({
    first_name: firstName.required(i18next.t('forms:firstName.required')),
    last_name: lastName.required(i18next.t('forms:lastName.required')),
    phone_number: phoneNumber(),
    language: language,
  });
