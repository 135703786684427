import React, { useCallback, useEffect, useState } from 'react';

import {
  ShipmentCompletedIcon,
  ShipmentPurchasedIcon,
  ShipmentOngoingIcon,
  Paper,
  Text,
  Grid,
  Stack,
} from '@livingpackets/design-system-react-next';
import { ShipmentDirection } from 'components/organisms/PartnerShipmentsTable';
import useImperativeRequest from 'hooks/useImperativeRequest';
import useNavigatePartner from 'hooks/useNavigatePartner';
import useShipmentList from 'hooks/useShipmentList';
import {
  ShipmentListNavTabEnum,
  ShipmentListTabEnum,
} from 'hooks/useShipmentTab';
import useToastMessages from 'hooks/useToastMessages';
import { ShipmentContractState } from 'models/shipment';
import { useTranslation } from 'react-i18next';
import useMyPartnersStore, {
  activePartnerSelector,
} from 'stores/useMyPartnersStore';
import styled, { DefaultTheme, withTheme } from 'styled-components';

const SHIPMENT_STATES = [
  'PURCHASED',
  'DEPLOYED',
  'TRANSFERRED',
  'ARRIVED',
  'ARCHIVED',
] as ShipmentContractState[];

const INITIAL_TOTALS = {
  DRAFT: 0,
  PURCHASED: 0,
  TRANSFERRED: 0,
  DEPLOYED: 0,
  ARRIVED: 0,
  ARCHIVED: 0,
};

const PaperWithHover = styled(Paper)`
  &:hover {
    cursor: pointer;
  }
`;
const OverviewCardHeader = styled.div`
  display: flex;
  gap: 0.64rem;
  padding-bottom: 1.14rem;
`;
const OverviewCardBody = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: baseline;
`;

const HomeShipmentCard = ({
  theme,
  setUpdate,
}: {
  theme: DefaultTheme;
  setUpdate?: any;
}) => {
  const { t } = useTranslation(['general', 'shipments', 'dashboard']);

  const navigatePartner = useNavigatePartner();
  const activePartner = useMyPartnersStore(activePartnerSelector);

  const [, makeRequest] = useImperativeRequest('shipment');
  const { error: toastError } = useToastMessages();
  const [total, setTotal] = useState<typeof INITIAL_TOTALS>();

  const { isShipmentRowCompactInList } = useShipmentList();

  const getShipmentTotals = useCallback(
    async (contractState: ShipmentContractState) => {
      let direction: ShipmentDirection | undefined = undefined;

      if (isShipmentRowCompactInList(contractState)) {
        direction = 'original';
      }

      const url = 'api/v2/shipments';

      const parameterList = [];

      if (direction) {
        parameterList.push(`direction=${direction}`);
      }

      if (contractState !== 'ARCHIVED') {
        parameterList.push(`contractState=${contractState}`);
      }

      parameterList.push(
        `isArchived=${contractState === 'ARCHIVED' ? 'true' : 'false'}`
      );

      parameterList.push('sortBy=updatedAt');
      parameterList.push('order=DESC');
      parameterList.push('pageSize=1');
      parameterList.push(`partnerId=${activePartner.id}`);

      const { data, error } = await makeRequest({
        path: `${url}?${parameterList.join('&')}`,
        method: 'get',
      });

      if (error) {
        toastError('messages:getShipmentTotals.message');

        return { data: [], contractState };
      }

      return { data, contractState };
    },
    [makeRequest, toastError, activePartner, isShipmentRowCompactInList]
  );

  useEffect(() => {
    Promise.all(SHIPMENT_STATES.map(type => getShipmentTotals(type))).then(
      results => {
        let lastUpdate = 0;
        results.forEach(res => {
          if (res.data.items && res.data.items[0]?.updatedAt > lastUpdate) {
            lastUpdate = res.data.items[0]?.updatedAt;
          }
        });

        setUpdate(lastUpdate);

        const totals = results.reduce(
          (tot, res) => ({
            ...tot,
            [res.contractState]: res.data.total,
          }),
          INITIAL_TOTALS
        );

        setTotal(totals);
      }
    );
  }, [getShipmentTotals, setUpdate]);

  return (
    <Grid container direction="row" gap="1.25rem" flexWrap="nowrap">
      <Grid item mobile={4}>
        <PaperWithHover
          data-testid="shipment-card-labels"
          onClick={() => {
            navigatePartner(`/shipments`, {
              state: {
                navtab: ShipmentListNavTabEnum.labels,
                tab: ShipmentListTabEnum.labelPurchased,
              },
            });
          }}
        >
          <Stack>
            <OverviewCardHeader>
              <ShipmentPurchasedIcon color={theme.colors.primary[100]} />
              <Text variant="bodyTextL">
                {t('dashboard:overviewSection.inPreparationCardTitle')}
              </Text>
            </OverviewCardHeader>
            <OverviewCardBody>
              {total && (
                <>
                  <Text variant="bodyTextL">
                    {total.PURCHASED + total.TRANSFERRED || '0'}
                  </Text>
                  <Text variant="bodyTextS">
                    {t('dashboard:overviewSection.inPreparationCardLabel')}
                  </Text>
                </>
              )}
            </OverviewCardBody>
          </Stack>
        </PaperWithHover>
      </Grid>
      <Grid item mobile={4}>
        <PaperWithHover
          data-testid="shipment-card-in-transit"
          onClick={() => {
            navigatePartner(`/shipments`, {
              state: {
                navtab: ShipmentListNavTabEnum.ongoing,
                tab: ShipmentListTabEnum.onGoing,
              },
            });
          }}
        >
          <Stack>
            <OverviewCardHeader>
              <ShipmentOngoingIcon color={theme.colors.primary[100]} />
              <Text variant="bodyTextL">
                {t('dashboard:overviewSection.inTransitCardTitle')}
              </Text>
            </OverviewCardHeader>
            <OverviewCardBody>
              {total && (
                <>
                  <Text variant="bodyTextL">{total.DEPLOYED || '0'}</Text>
                  <Text variant="bodyTextS">
                    {t('dashboard:overviewSection.inTransitCardLabel')}
                  </Text>
                </>
              )}
            </OverviewCardBody>
          </Stack>
        </PaperWithHover>
      </Grid>
      <Grid item mobile={4}>
        <PaperWithHover
          data-testid="shipment-card-delivered"
          onClick={() => {
            navigatePartner(`/shipments`, {
              state: {
                navtab: ShipmentListNavTabEnum.completed,
                tab: ShipmentListTabEnum.finished,
              },
            });
          }}
        >
          <Stack>
            <OverviewCardHeader>
              <ShipmentCompletedIcon color={theme.colors.primary[100]} />
              <Text variant="bodyTextL">
                {t('dashboard:overviewSection.deliveredCardTitle')}
              </Text>
            </OverviewCardHeader>
            <OverviewCardBody>
              {total && (
                <>
                  <Text variant="bodyTextL">
                    {total.ARRIVED + total.ARCHIVED || '0'}
                  </Text>
                  <Text variant="bodyTextS">
                    {t('dashboard:overviewSection.deliveredCardLabel')}
                  </Text>
                </>
              )}
            </OverviewCardBody>
          </Stack>
        </PaperWithHover>
      </Grid>
    </Grid>
  );
};

export default withTheme(HomeShipmentCard);
