import { useCallback } from 'react';

import accounting from 'accounting-js';
import isInteger from 'lodash/isInteger';
import round from 'lodash/round';
import { useTranslation } from 'react-i18next';

interface ICurrencyOptions {
  symbol?: string;
  decimal?: string;
  thousand?: string;
  precision?: number;
  format?: string;
}

export const localiseCurrency = (
  number: number,
  language: string,
  options?: ICurrencyOptions
) => {
  switch (language) {
    case 'en':
      return accounting.formatMoney(number, {
        symbol: ' €',
        decimal: '.',
        thousand: '',
        precision: 2,
        format: '%v%s',
        ...options,
      });
    case 'fr':
      return accounting.formatMoney(number, {
        symbol: ' €',
        decimal: ',',
        thousand: '',
        precision: 2,
        format: '%v%s',
        ...options,
      });
    case 'de':
      return accounting.formatMoney(number, {
        symbol: ' €',
        decimal: ',',
        thousand: '',
        precision: 2,
        format: '%v%s',
        ...options,
      });
    default:
      return accounting.formatMoney(number, {
        symbol: ' €',
        decimal: '.',
        thousand: ' ',
        precision: 2,
        format: '%v%s',
        ...options,
      });
  }
};

interface IMonetaryAmountConverter {
  amount: number;
  language: string;
  options?: ICurrencyOptions;
}

export const monetaryAmountConverter = ({
  amount,
  language,
  options,
}: IMonetaryAmountConverter) =>
  isInteger(round(amount, 2))
    ? localiseCurrency(amount, language, {
        precision: 0,
        ...options,
      })
    : localiseCurrency(amount, language, options);

export const useMonetaryAmountConverter = () => {
  const {
    i18n: { language },
  } = useTranslation();

  const convert = useCallback(
    (amount: number, options?: ICurrencyOptions) =>
      monetaryAmountConverter({ amount, language, options }),
    [language]
  );

  return convert;
};
