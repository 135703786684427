import { useCallback, useEffect } from 'react';

import { TAPIService } from 'configs';
import { Action } from 'helpers/paginatedStoreHelpers';
import useRemoteData from 'hooks/useRemoteData';
import { IUseRemoteDataV2 } from 'hooks/useRemoteDataV2';
import useToastMessages from 'hooks/useToastMessages';
import get from 'lodash/get';
import queryString from 'query-string';

interface IUsePaginatedTableData<T> {
  params: IUseRemoteDataV2;
  queryParams: {
    pageSize: number;
    sortBy: string;
    offset: number;
    order: 'ASC' | 'DESC';
    [key: string]: any;
  };
  dispatch: (args: Action<T>) => void;
  queryParamsOptions?: any;
  service: TAPIService;
  onError?: (error: any) => void;
}

const usePaginatedTableData = <T>({
  params,
  dispatch,
  queryParams,
  queryParamsOptions,
  service,
  onError,
}: IUsePaginatedTableData<T>) => {
  const { error: errorToast } = useToastMessages();

  const withQueryParams = {
    ...params,
    path: `${params.path}?${queryString.stringify(
      queryParams,
      queryParamsOptions
    )}`,
  };

  const { error, loading, data, refetch } = useRemoteData(
    withQueryParams,
    service
  );

  useEffect(() => {
    dispatch({ type: 'updateError', args: { error } });
    if (error) {
      onError
        ? onError(error)
        : errorToast('messages:somethingWentWrong.message', { wide: true });
    }
  }, [dispatch, error, onError, errorToast]);

  useEffect(() => {
    dispatch({ type: 'updateLoading', args: { loading } });
  }, [dispatch, loading]);

  useEffect(() => {
    if (data) {
      dispatch({
        type: 'updateRowData',
        args: { rowData: get(data, 'items', []) },
      });
      dispatch({
        type: 'updateTotal',
        args: { total: get(data, 'total', 0) },
      });
      dispatch({
        type: 'updateCount',
        args: { count: get(data, 'count', 0) },
      });

      return;
    }
    dispatch({ type: 'updateRowData', args: { rowData: [] } });
  }, [dispatch, data]);

  const onPaginationChange = useCallback(
    (input: { offset: number; pageSize: number }) => {
      dispatch({
        type: 'updatePagination',
        args: {
          offset: input.offset,
          pageSize: input.pageSize,
        },
      });
    },
    [dispatch]
  );

  const onSortingChange = useCallback(
    (input: { sortBy: any; order: 'ASC' | 'DESC' }) => {
      dispatch({
        type: 'updateSorting',
        args: {
          sortBy: input.sortBy,
          order: input.order,
        },
      });
    },
    [dispatch]
  );

  return {
    error,
    loading,
    data,
    onPaginationChange,
    onSortingChange,
    refetch,
  };
};

export default usePaginatedTableData;
