import React from 'react';

import Col from 'components/atoms/Col';
import Input from 'components/atoms/Input';
import Row from 'components/atoms/Row';
import CountrySelector from 'components/organisms/CountrySelector';
import get from 'lodash/get';
import { IAddressForm } from 'models/address';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const StyledRow = styled(Row)`
  align-items: baseline;
`;

const AddressForm = ({
  errors,
  touched,
  register,
  control,
  isDisabled,
  rowPadding = '0 1.5rem',
}: IAddressForm & any) => {
  const { t, i18n } = useTranslation(['address', 'general']);
  const language = i18n.language;
  const isNumberBeforeStreet = language !== 'de';

  return (
    <>
      <StyledRow p={rowPadding}>
        <Col pr="1rem">
          <Input
            mt="0.3rem"
            label={t('address:companyName.label')}
            name="company"
            placeholder={t('general:optional')}
            error={get(errors, 'company', false)}
            isTouched={get(touched, 'company', false)}
            register={register}
            disabled={isDisabled}
            width="36rem"
          />
        </Col>
      </StyledRow>

      <StyledRow p={rowPadding}>
        <Col pr="1rem">
          <Row p={0} spacing="1rem">
            {isNumberBeforeStreet ? (
              <>
                <Input
                  name="houseNumber"
                  label={t('address:houseNumber.label')}
                  key="houseNumber"
                  error={get(errors, 'houseNumber', false)}
                  isTouched={get(touched, 'houseNumber', false)}
                  register={register}
                  width="7.75rem"
                  disabled={isDisabled}
                />
                <Input
                  name="street"
                  label={t('address:street.label')}
                  key="street"
                  error={get(errors, 'street', false)}
                  isTouched={get(touched, 'street', false)}
                  register={register}
                  width="27.25rem"
                  disabled={isDisabled}
                />
              </>
            ) : (
              <>
                <Input
                  name="street"
                  label={t('address:street.label')}
                  key="street"
                  error={get(errors, 'street', false)}
                  isTouched={get(touched, 'street', false)}
                  register={register}
                  width="27.25rem"
                  disabled={isDisabled}
                />
                <Input
                  name="houseNumber"
                  label={t('address:houseNumber.label')}
                  key="houseNumber"
                  error={get(errors, 'houseNumber', false)}
                  isTouched={get(touched, 'houseNumber', false)}
                  register={register}
                  width="7.75rem"
                  disabled={isDisabled}
                />
              </>
            )}
          </Row>
        </Col>
      </StyledRow>
      <StyledRow p={rowPadding}>
        <Row p={0} spacing="1rem">
          <Input
            name="postalCode"
            label={t('address:postalCode.label')}
            error={get(errors, 'postalCode', false)}
            isTouched={get(touched, 'postalCode', false)}
            register={register}
            disabled={isDisabled}
            width="7.75rem"
          />
          <Input
            label={t('address:city.label')}
            name="city"
            error={get(errors, 'city', false)}
            isTouched={get(touched, 'city', false)}
            register={register}
            disabled={isDisabled}
            width="13.125rem"
          />
          <CountrySelector
            control={control}
            isDisabled={isDisabled}
            register={register}
            width="13.125rem"
          />
        </Row>
      </StyledRow>
    </>
  );
};

export default AddressForm;
