export enum ShipmentTimelineEventTypeEnum {
  UNKNOWN = 'UNKNOWN',
  ALERT_TEMPERATURE_EXTERNAL = 'ALERT_TEMPERATURE_EXTERNAL',
  ALERT_TEMPERATURE_INTERNAL = 'ALERT_TEMPERATURE_INTERNAL',
  ALERT_SHOCK = 'ALERT_SHOCK',
  ALERT_FALL = 'ALERT_FALL',
  ALERT_PRESSURE = 'ALERT_PRESSURE',
  ALERT_HUMIDITY_EXTERNAL = 'ALERT_HUMIDITY_EXTERNAL',
  ALERT_HUMIDITY_INTERNAL = 'ALERT_HUMIDITY_INTERNAL',
  ALERT_BOX_VIOLATION = 'ALERT_BOX_VIOLATION',
  DEPLOYED = 'DEPLOYED',
  ARRIVED = 'ARRIVED',
  ALERT_BATTERY_LEVEL = 'ALERT_BATTERY_LEVEL',
  ALERT_BOX_CODE = 'ALERT_BOX_CODE',
}

export enum ShipmentTimelineThresholdTypeEnum {
  MAX = 'max',
  MIN = 'min',
}

export enum ShipmentAnonymousTimelineThresholdTypeEnum {
  MAX = 'THRESHOLD_TYPE_MAX',
  MIN = 'THRESHOLD_TYPE_MIN',
}
