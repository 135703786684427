import React from 'react';

import PartnerBoxContracts from 'components/organisms/PartnerBoxContracts';
import { Route, Routes } from 'react-router-dom';

const PartnerBuyAndRent = () => (
  <Routes>
    <Route index element={<PartnerBoxContracts />} />
  </Routes>
);

export default PartnerBuyAndRent;
