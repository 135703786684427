import React from 'react';

import {
  Box,
  ButtonV2 as Button,
  Stack,
  Text,
  styled,
} from '@livingpackets/design-system-react-next';
import figuralLogo from 'assets/img/insurance/figural-logo.svg';
import useCountryCodesWrapper from 'hooks/useCountryCodeWrapper';
import { IFiguralInsurance } from 'models/figuralInsurance';
import { useTranslation } from 'react-i18next';

import Modal from '../../atoms/Modal';

const InsuranceFiguralLogo = styled('div')<{
  figuralLogo: string;
}>`
  min-width: 60px;
  height: 52px;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url(${({ figuralLogo }) => figuralLogo});
  margin-right: 8px;
`;

const SummaryContainer = styled(Stack)`
  margin-top: 30px;
`;

const SummaryInformationContainer = styled(Stack)`
  padding: 20px;
  margin-top: 15px;
  background-color: ${({ theme }) => theme.palette.custom.neutral.black[2]};
  border-radius: ${({ theme }) => theme.borderRadius.sm};
`;

const ValueTitle = styled(Text)`
  margin-bottom: 7px;
`;

ValueTitle.defaultProps = {
  variant: 'titleXS',
  color: 'custom.neutral.black.50',
};

const ValueData = styled(Text)`
  margin-bottom: 23px;
`;

ValueData.defaultProps = {
  variant: 'bodyTextS',
  color: 'custom.neutral.black.90',
};

const ButtonWrapper = styled('div')`
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
`;

const FiguralInsuranceSummaryModal = ({
  isVisible,
  isShipmentHasReturnLabel,
  figuralInsuranceData,
  onEdit,
  onConfirm,
}: {
  isVisible: boolean;
  isShipmentHasReturnLabel: boolean;
  figuralInsuranceData: IFiguralInsurance;
  onEdit: () => void;
  onConfirm: () => void;
}) => {
  const { t } = useTranslation('shipments');

  const [, getCountryNameFromCode] = useCountryCodesWrapper();

  return (
    <Modal open={isVisible} handleClose={() => {}}>
      <Stack>
        <Text variant="titleM">{t('modal.figuralInsuranceSummary.title')}</Text>
        <Stack direction="row" marginTop="20px !important" alignItems="center">
          <InsuranceFiguralLogo figuralLogo={figuralLogo} />
          <Text variant="bodyTextS">
            {t('modal.figuralInsuranceSummary.text')}
          </Text>
        </Stack>
        <SummaryContainer>
          <Text variant="titleXS" color="custom.neutral.black.50">
            {t('modal.figuralInsuranceSummary.blockSummaryTitle')}
          </Text>
          <SummaryInformationContainer direction="row">
            <Stack width="50%">
              <Box>
                <ValueTitle>
                  {t('modal.figuralInsuranceSummary.valuesTitle.value')}
                </ValueTitle>
                <ValueData>{figuralInsuranceData.goodsValue}</ValueData>
              </Box>
              <Box>
                <ValueTitle>
                  {t(
                    'modal.figuralInsuranceSummary.valuesTitle.departureCountry'
                  )}
                </ValueTitle>
                <ValueData>
                  {getCountryNameFromCode(
                    figuralInsuranceData.departureCountryCode
                  )}
                </ValueData>
              </Box>
              <Box>
                <ValueTitle>
                  {t(
                    'modal.figuralInsuranceSummary.valuesTitle.arrivalCountry'
                  )}
                </ValueTitle>
                <ValueData>
                  {getCountryNameFromCode(
                    figuralInsuranceData.arrivalCountryCode
                  )}
                </ValueData>
              </Box>
              <Box>
                <ValueTitle>
                  {t('modal.figuralInsuranceSummary.valuesTitle.note')}
                </ValueTitle>
                <ValueData
                  style={{
                    whiteSpace: 'break-spaces',
                    lineHeight: '15px',
                  }}
                >
                  {figuralInsuranceData.note}
                </ValueData>
              </Box>
            </Stack>
            <Stack width="50%">
              {isShipmentHasReturnLabel && (
                <Box>
                  <ValueTitle>
                    {t(
                      'modal.figuralInsuranceSummary.valuesTitle.returnShipment'
                    )}
                  </ValueTitle>
                  <ValueData>
                    {figuralInsuranceData.isGoodsReturnInsured
                      ? t(
                          'modal.figuralInsuranceSummary.valuesData.returnShipment.insured'
                        )
                      : t(
                          'modal.figuralInsuranceSummary.valuesData.returnShipment.notInsured'
                        )}
                  </ValueData>
                </Box>
              )}
              <Box>
                <ValueTitle>
                  {t('modal.figuralInsuranceSummary.valuesTitle.sender')}
                </ValueTitle>
                <ValueData>
                  {figuralInsuranceData.sender.firstName +
                    ' ' +
                    figuralInsuranceData.sender.lastName}
                </ValueData>
              </Box>
              <Box>
                <ValueTitle>
                  {t('modal.figuralInsuranceSummary.valuesTitle.email')}
                </ValueTitle>
                <ValueData>{figuralInsuranceData.sender.email}</ValueData>
              </Box>
              <Box>
                <ValueTitle>
                  {t('modal.figuralInsuranceSummary.valuesTitle.senderAddress')}
                </ValueTitle>
                <ValueData style={{ whiteSpace: 'break-spaces' }}>
                  {t('modal.figuralInsuranceSummary.valuesData.senderAddress', {
                    address: figuralInsuranceData.sender.address,
                    zipCode: figuralInsuranceData.sender.zipCode,
                    city: figuralInsuranceData.sender.city,
                    country: getCountryNameFromCode(
                      figuralInsuranceData.sender.countryCode
                    ),
                  })}
                </ValueData>
              </Box>
            </Stack>
          </SummaryInformationContainer>
        </SummaryContainer>
        <ButtonWrapper>
          <Button
            variant="secondary"
            onClick={onEdit}
            data-testid="figural-insurance-summary-edit-button"
          >
            {t('modal.figuralInsuranceSummary.button.edit')}
          </Button>
          <Button
            onClick={onConfirm}
            data-testid="figural-insurance-summary-confirm-button"
          >
            {t('modal.figuralInsuranceSummary.button.confirm')}
          </Button>
        </ButtonWrapper>
      </Stack>
    </Modal>
  );
};

export default FiguralInsuranceSummaryModal;
