import React, { useEffect, useState } from 'react';

import {
  Box,
  Divider,
  Grid,
  Text,
  WarningIcon,
  Stack,
  styled,
  useTheme,
} from '@livingpackets/design-system-react-next';
import { GetOnAppStore } from 'components/atoms/GetOnAppStore';
import { GetOnGooglePlay } from 'components/atoms/GetOnGooglePlay';
import ShipmentAnonymousAddressCard from 'components/molecules/ShipmentAnonymousAddressCard';
import ShipmentAnonymousInformation from 'components/molecules/ShipmentAnonymousInformation';
import SmallTabNavigation from 'components/molecules/SmallTabNavigation';
import { TrackingInfoCard } from 'features/shipments';
import { ShipmentDetail } from 'features/shipments';
import {
  IShipment,
  IShipmentAnonymousData,
  ITrackingHistoryResponse,
} from 'models/shipment';
import { useTranslation } from 'react-i18next';

import ShipmentAnonymousTracking from './ShipmentAnonymousTracking';
import {
  CONTRACT_STATE_SHOW_HISTORY,
  ShipmentTabEnum,
} from './ShipmentDetails';

const Wrapper = styled('div')`
  position: relative;
  background-color: ${({ theme }) => theme.colors.white};
  height: 100%;

  overflow-x: auto;

  ::-webkit-scrollbar-track {
    border: 1px solid #f6f6f6;
    padding: 2px 0;
    background-color: #f6f6f6;
    border-radius: 2px;
  }

  ::-webkit-scrollbar {
    width: 4px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background-color: #cccccc;
  }
`;

export const ShipmentWrapperContainer = styled('div')`
  height: inherit;
  overflow: hidden;
`;

export const ShipmentDetailNavigationContainer = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: nowrap;
  align-content: flex-start;
  justify-content: space-between;
  height: 48px;

  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background-color: white;

  z-index: 2;

  margin-bottom: 50px;
`;

interface ShipmentAnonymousDetailsProps {
  shipmentData: IShipmentAnonymousData;
  onShipmentMapZoomClick: () => void;
  onShowRelated?: (relatedShipment: IShipment) => void;
  onCurrentTrackingDataUpdate?: (
    currentTrackingData?: ITrackingHistoryResponse
  ) => void;
}

const ShipmentAnonymousDetails = ({
  shipmentData,
  onShipmentMapZoomClick,
}: ShipmentAnonymousDetailsProps) => {
  const { t } = useTranslation('shipments');
  const theme = useTheme();

  const shouldShowHistory = CONTRACT_STATE_SHOW_HISTORY.includes(
    shipmentData.shipment.contract_state
  );
  const [activeKey, setActiveKey] = useState<number>(
    shouldShowHistory ? ShipmentTabEnum.HISTORY : ShipmentTabEnum.SUMMARY
  );

  const isSpecificPartner = [
    process.env.REACT_APP_LOUIS_VUITTON_PARTNER_ID,
    process.env.REACT_APP_LOUIS_VUITTON_COMPANY_PARTNER_ID,
  ].includes(shipmentData.shipment.partner_id);

  useEffect(() => {
    if (!shouldShowHistory && activeKey === ShipmentTabEnum.HISTORY) {
      setActiveKey(ShipmentTabEnum.SUMMARY);
    }
  }, [shouldShowHistory, activeKey]);

  return (
    <>
      <ShipmentWrapperContainer>
        <Wrapper>
          {/* TODO: next update banner component and add new color in DS */}
          <Grid
            container
            sx={{
              background: theme.palette.custom.secondary.yellow[10],
              borderRadius: '1.125rem',
              textAlign: 'center',
              paddingBottom: '.875rem',
              paddingTop: '.875rem',
              paddingRight: '1.5rem',
              paddingLeft: '1.5rem',
            }}
          >
            <Grid item container>
              <Text
                variant="titleM"
                color="custom.secondary.yellow.100"
                pb=".625rem"
              >
                {t('anonymous.info.downloadMessageTitle')}
              </Text>
              <Grid item container direction="row" alignItems="center">
                <WarningIcon
                  style={{ marginRight: '.875rem' }}
                  color={theme.palette.custom.secondary.yellow[100]}
                />
                <Text variant="bodyTextM" color="custom.secondary.yellow.100">
                  {isSpecificPartner
                    ? t('anonymous.mobile.navigatorError.message')
                    : t('anonymous.info.downloadMessage')}
                </Text>
                {!isSpecificPartner && (
                  <Divider
                    orientation="horizontal"
                    sx={{
                      width: '100%',
                      marginTop: '.875rem',
                      marginBottom: '.875rem',
                    }}
                  />
                )}
              </Grid>
              {!isSpecificPartner && (
                <Grid
                  item
                  container
                  justifyContent="flex-end"
                  alignItems="center"
                  gap=".5rem"
                >
                  <Text
                    variant="titleXS"
                    sx={{ paddingRight: '1rem' }}
                    color="custom.secondary.yellow.100"
                  >
                    {t('anonymous.info.downloadMessageAction')}
                  </Text>
                  <GetOnAppStore />
                  <GetOnGooglePlay />
                </Grid>
              )}
            </Grid>
          </Grid>

          {isSpecificPartner ? (
            <ShipmentDetail curruentShipmentData={shipmentData} />
          ) : (
            <>
              <ShipmentDetailNavigationContainer>
                <SmallTabNavigation
                  activeKey={activeKey}
                  setActiveKey={setActiveKey}
                  options={[
                    {
                      key: ShipmentTabEnum.HISTORY,
                      label: t('detail.tab.history'),
                      isDisabled: !shouldShowHistory,
                    },
                    {
                      key: ShipmentTabEnum.SUMMARY,
                      label: t('detail.tab.summary'),
                    },
                  ]}
                />
              </ShipmentDetailNavigationContainer>
              {activeKey === ShipmentTabEnum.HISTORY && (
                <ShipmentAnonymousTracking
                  shipmentData={shipmentData}
                  displayTrackingLink={false}
                  onShipmentMapZoomClick={onShipmentMapZoomClick}
                />
              )}
              {activeKey === ShipmentTabEnum.SUMMARY && (
                <>
                  <Stack direction="row">
                    <Box sx={{ width: '40%', paddingRight: '33px' }}>
                      <ShipmentAnonymousInformation
                        shipmentData={shipmentData}
                      />
                    </Box>
                    <Box width="60%">
                      <ShipmentAnonymousAddressCard
                        shipmentData={shipmentData}
                        blockDirection="row"
                      />
                      <TrackingInfoCard
                        shipment={shipmentData.shipment}
                        recipientEmail={
                          shipmentData.shipment.recipient.notification_email
                        }
                        shipmentCarrierCode={shipmentData.shipment.carrier_code}
                        carrierTrackingCode={
                          shipmentData.shipment.carrier_tracking_code
                        }
                        isShipmentAnonymous={true}
                      />
                    </Box>
                  </Stack>
                </>
              )}
            </>
          )}
        </Wrapper>
      </ShipmentWrapperContainer>
    </>
  );
};

export default ShipmentAnonymousDetails;
