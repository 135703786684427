import React from 'react';

import { Button } from '@livingpackets/design-system-react';
import {
  WalletIcon,
  Text,
  styled,
  useTheme,
  Box,
  Stack,
} from '@livingpackets/design-system-react-next';
import { useTranslation } from 'react-i18next';

const ContributionDetailInProgress = styled('div')`
  background-color: ${({ theme }) => theme.palette.custom.neutral.white.pure};
  border-radius: 20px;
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.08);
  padding: 12px 14px;
`;

const WalletIconContainer = styled(Box)`
  height: 48px;
  width: 48px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px;
`;

const WalletFailIconContainer = styled(WalletIconContainer)`
  background-color: ${({ theme }) => theme.palette.custom.error.red[10]};
`;

interface IContributionErrorToFix {
  onClickEdtAccountInformation: () => void;
  isWarningMode?: Boolean;
}

const ContributionErrorToFix = ({
  onClickEdtAccountInformation,
  isWarningMode = false,
}: IContributionErrorToFix) => {
  const { t } = useTranslation('contribution');
  const theme = useTheme();

  return (
    <ContributionDetailInProgress>
      <Stack direction="row" alignItems="center">
        <Stack direction="row" alignItems="center">
          <WalletFailIconContainer>
            <WalletIcon color={theme.palette.custom.error.red[100]} />
          </WalletFailIconContainer>
          <Stack paddingLeft="24px">
            <Text variant="titleXS" color="custom.error.red.100">
              {t(
                'contributionsTab.contributionsList.detail.failureToPay.title'
              )}
            </Text>
            <Text variant="bodyTextS" color="custom.error.red.100">
              {t('contributionsTab.contributionsList.detail.failureToPay.text')}
            </Text>
          </Stack>
        </Stack>
        {!isWarningMode && (
          <Button
            action="primary"
            state="critical"
            onClick={onClickEdtAccountInformation}
            data-testid="contributionList-detail-button-edit-account-information"
          >
            {t(
              'contributionsTab.contributionsList.detail.button.editAccountInformation'
            )}
          </Button>
        )}
      </Stack>
    </ContributionDetailInProgress>
  );
};

export default ContributionErrorToFix;
