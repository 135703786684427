import { useEffect } from 'react';

import { IUseImperativeRequest } from '@livingpackets/shared-components';
import { UserBusinessTypeEnum } from 'enums/UserBusinessTypeEnum';
import getPartnerRoles from 'helpers/getPartnerRoles';
import useImperativeRequest from 'hooks/useImperativeRequest';
import LoadingKeys from 'models/loadingKeys';
import { IPartner } from 'models/partner';
import { partnersNameIdSchema } from 'schemas/partnerSchema';
import useAppState, {
  dispatch,
  tokenSelector,
  userSelector,
} from 'stores/appState/useAppState';
import useMyPartnersStore, {
  IPartnerEntry,
  PartnerTypeEnum,
  setActivePartnerSelector,
  setPartnersSelector,
} from 'stores/useMyPartnersStore';

const loadingDispatch = (state: boolean) =>
  dispatch({
    type: 'SET_LOADING',
    payload: { key: LoadingKeys.MyPartnerships, state },
  });

export const parsePartners = (
  partners: IPartner[]
): Omit<IPartnerEntry, 'role'>[] =>
  partners.map((partner: IPartner) => ({
    id: partner.id,
    name: partner.name,
    type: partner.internal
      ? PartnerTypeEnum.INTERNAL
      : PartnerTypeEnum.EXTERNAL,
    secursusEnabled: partner.secursusEnabled,
    createdAt: partner.createdAt,
    businessInformation: partner.businessInformation,
    fwUpdateEnabled: partner.fwUpdateEnabled,
    remoteControlEnabled: partner.remoteControlEnabled,
    smsEnabled: partner.smsEnabled,
    carriers: partner.carriers,
  }));

export const getMyPartnerships = async (
  makeRequest: (input: IUseImperativeRequest) => any
) => {
  const { data, error } = await makeRequest({
    path: 'api/v1/me/partners?sortBy=createdAt',
    method: 'get',
  });

  if (error) {
    throw error;
  }
  if (!data) {
    throw new Error('Partners request returned no data');
  }

  if (data.items.length > 0) {
    await partnersNameIdSchema.validate(data.items);
  }

  return parsePartners(data.items);
};

export const getMyRoles = async ({
  appToken,
  setPartners,
  partners,
}: {
  appToken: string;
  partners: Omit<IPartnerEntry, 'role'>[];
  setPartners: (partners: IPartnerEntry[]) => void;
}) => {
  const roles = await Promise.all(
    partners.map(partner => getPartnerRoles(partner.id, appToken))
  );

  const partnersWithRole = partners.reduce<IPartnerEntry[]>(
    (filtered, partner, index) => {
      const roleEntry = roles[index];
      if (!roleEntry.data || roleEntry.error) {
        //TODO: add sentry exception
        console.error('Unable to get roles of partner ' + partner.id);

        return filtered;
      }

      return [
        ...filtered,
        {
          ...partner,
          role: roleEntry.data,
        },
      ];
    },
    []
  );

  setPartners(partnersWithRole);

  return partnersWithRole;
};

interface IGetMyPartnershipsAndRoles {
  appToken: string;
  businessType: UserBusinessTypeEnum;
  makeRequest: (input: IUseImperativeRequest) => any;
  setPartners: (partners: IPartnerEntry[]) => void;
  setActivePartner: (partner: IPartnerEntry) => void;
}

export const getMyPartnershipsAndRoles = async ({
  appToken,
  businessType,
  makeRequest,
  setPartners,
  setActivePartner,
}: IGetMyPartnershipsAndRoles) => {
  loadingDispatch(true);
  try {
    const data = await getMyPartnerships(makeRequest);
    if (data.length === 0) {
      setPartners([]);

      return;
    }

    const partners: IPartnerEntry[] = await getMyRoles({
      appToken,
      partners: data,
      setPartners,
    });

    if (businessType === UserBusinessTypeEnum.BUSINESS) {
      setActivePartner(partners[0]);
    }
  } catch (e: any) {
    dispatch({ type: 'SET_ERROR', payload: e });
  } finally {
    loadingDispatch(false);
  }
};

const useMyPartnerships = () => {
  const [, makeRequest] = useImperativeRequest('lpVillage');
  const appToken = useAppState(tokenSelector);
  const appUser = useAppState(userSelector);
  const setPartners = useMyPartnersStore(setPartnersSelector);
  const setActivePartner = useMyPartnersStore(setActivePartnerSelector);

  useEffect(() => {
    // since this is called on initial load,
    // once we have a token, it's safe to fire the request
    if (appToken && appUser?.businessType) {
      getMyPartnershipsAndRoles({
        appToken,
        businessType: appUser?.businessType,
        makeRequest,
        setPartners,
        setActivePartner,
      });
    }
  }, [
    appToken,
    appUser?.businessType,
    makeRequest,
    setActivePartner,
    setPartners,
  ]);
};

export default useMyPartnerships;
