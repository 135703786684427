import React from 'react';

import {
  Box,
  NoneIcon,
  Stack,
  styled,
  Text,
} from '@livingpackets/design-system-react-next';
import alertGaugeReverse from 'assets/img/shipment/timeline/alert-gauge-reverse.png';
import alertGaugeReverse2x from 'assets/img/shipment/timeline/alert-gauge-reverse@2x.png';
import alertGaugeReverse3x from 'assets/img/shipment/timeline/alert-gauge-reverse@3x.png';
import alertGauge from 'assets/img/shipment/timeline/alert-gauge.png';
import alertGauge2x from 'assets/img/shipment/timeline/alert-gauge@2x.png';
import alertGauge3x from 'assets/img/shipment/timeline/alert-gauge@3x.png';
import {
  ShipmentAnonymousTimelineThresholdTypeEnum,
  ShipmentTimelineThresholdTypeEnum,
} from 'enums/ShipmentTimelineEnum';

const EventInformationLimitContainer = styled('div')`
  width: 120px;
  height: 74px;
  padding: 8px 3px 8px 10px;
  background-color: ${({ theme }) => theme.palette.custom.neutral.black[2]};
  border-radius: ${({ theme }) => theme.borderRadius.sm};
`;

const EventInformationLimitData = styled(Stack)`
  justify-content: space-between;
  align-items: flex-end;
  height: 54px;
  width: 90%;
  padding-right: 5px;
`;

const ThresholdTextContainer = styled(Text)`
  white-space: nowrap;
`;

ThresholdTextContainer.defaultProps = {
  variant: 'bodyTextS',
  color: 'custom.neutral.black.100',
};

const AlertTextContainer = styled(Text)`
  white-space: nowrap;
`;

AlertTextContainer.defaultProps = {
  variant: 'bodyTextS',
  color: 'custom.error.red.100',
};

const EventInformationLimitGauge = styled('div', {
  shouldForwardProp: prop =>
    prop !== 'alertGauge' && prop !== 'alertGauge2x' && prop !== 'alertGauge3x',
})<{
  alertGauge: string;
  alertGauge2x: string;
  alertGauge3x: string;
}>`
  width: 6px;
  height: 54px;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url(${({ alertGauge }) => alertGauge});
  background-image: -webkit-image-set(
    url(${alertGauge2x}) 1x,
    url(${alertGauge3x}) 2x
  );
`;

const EventInformationLimitGaugeReverse = styled('div', {
  shouldForwardProp: prop =>
    prop !== 'alertGaugeReverse' &&
    prop !== 'alertGaugeReverse2x' &&
    prop !== 'alertGaugeReverse3x',
})<{
  alertGaugeReverse: string;
  alertGaugeReverse2x: string;
  alertGaugeReverse3x: string;
}>`
  width: 6px;
  height: 54px;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url(${({ alertGaugeReverse }) => alertGaugeReverse});
  background-image: -webkit-image-set(
    url(${alertGaugeReverse2x}) 1x,
    url(${alertGaugeReverse3x}) 2x
  );
`;

interface IEventInformationLimitBlockType {
  thresholdType:
    | ShipmentTimelineThresholdTypeEnum
    | ShipmentAnonymousTimelineThresholdTypeEnum;
  thresholdText: string;
  currentText: string;
}

const EventInformationLimitDisplayBlock = ({
  thresholdType,
  thresholdText,
  currentText,
}: IEventInformationLimitBlockType) => (
  <EventInformationLimitContainer>
    <Stack direction="row">
      <EventInformationLimitData>
        {thresholdType === ShipmentTimelineThresholdTypeEnum.MAX ||
        thresholdType === ShipmentAnonymousTimelineThresholdTypeEnum.MAX ? (
          <AlertTextContainer>{currentText}</AlertTextContainer>
        ) : (
          <Box marginTop="0 !important" height="14px">
            <NoneIcon size="14px" />
          </Box>
        )}
        <ThresholdTextContainer>{thresholdText}</ThresholdTextContainer>
        {thresholdType === ShipmentTimelineThresholdTypeEnum.MIN ||
        thresholdType === ShipmentAnonymousTimelineThresholdTypeEnum.MIN ? (
          <AlertTextContainer>{currentText}</AlertTextContainer>
        ) : (
          <Box marginTop="0 !important" height="14px">
            <NoneIcon size="14px" />
          </Box>
        )}
      </EventInformationLimitData>
      {thresholdType === ShipmentTimelineThresholdTypeEnum.MAX ||
        (thresholdType === ShipmentAnonymousTimelineThresholdTypeEnum.MAX && (
          <EventInformationLimitGauge
            alertGauge={alertGauge}
            alertGauge2x={alertGauge2x}
            alertGauge3x={alertGauge3x}
          />
        ))}
      {thresholdType === ShipmentTimelineThresholdTypeEnum.MIN ||
        (thresholdType === ShipmentAnonymousTimelineThresholdTypeEnum.MIN && (
          <EventInformationLimitGaugeReverse
            alertGaugeReverse={alertGaugeReverse}
            alertGaugeReverse2x={alertGaugeReverse2x}
            alertGaugeReverse3x={alertGaugeReverse3x}
          />
        ))}
    </Stack>
  </EventInformationLimitContainer>
);

export default EventInformationLimitDisplayBlock;
