import React from 'react';

import {
  ChevronUpIcon,
  Text,
  styled,
  Stack,
} from '@livingpackets/design-system-react-next';
import { useSpring, config, animated } from 'react-spring';
import { Transition } from 'react-transition-group';
import { TransitionStatus } from 'react-transition-group/Transition';

const ImportShipmentConfirmInformationSectionContainer = styled(Stack)`
  background-color: ${({ theme }) => theme.palette.custom.neutral.white.pure};
  border-radius: 16px;
  padding: 24px 32px;
  margin-bottom: 24px;
`;

const NumberTextContainer = styled(Text)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 14px;
  height: 14px;
  background-color: ${({ theme }) => theme.palette.custom.primary[100]};
  border-radius: 50%;
  color: ${({ theme }) => theme.palette.custom.neutral.white.pure};
`;

NumberTextContainer.defaultProps = {
  variant: 'bodyTextXS',
};

interface ImportShipmentConfirmInformationSectionType {
  number: string;
  title: string;
  subTitle?: string;
  isOpen: boolean;
  children: React.ReactNode;
  heightOpen: string;
  onClick: () => void;
  isDisabled: boolean;
}

const ImportShipmentConfirmInformationSection = ({
  number,
  title,
  subTitle,
  isOpen,
  children,
  heightOpen,
  onClick,
  isDisabled,
}: ImportShipmentConfirmInformationSectionType) => {
  const childrenDisplayStyle = useSpring({
    config: { ...config.default },
    marginTop: 0,
    opacity: isOpen ? 1 : 0,
    height: isOpen ? heightOpen : '0px',
  });

  return (
    <ImportShipmentConfirmInformationSectionContainer>
      <Stack
        direction="row"
        style={{ cursor: isDisabled ? 'default' : 'pointer' }}
        onClick={() => {
          if (!isDisabled) {
            onClick();
          }
        }}
      >
        <Stack direction="row" alignItems="center">
          <NumberTextContainer>{number}</NumberTextContainer>
          <Text variant="titleL" pl="9px">
            {title}
          </Text>
        </Stack>
        <ChevronUpIcon
          style={{
            transitionProperty: 'transform',
            transitionDuration: '200ms',
            transform: !isOpen ? 'rotate(-0.5turn)' : 'initial',
          }}
        />
      </Stack>
      <Transition in={isOpen} timeout={500}>
        {(state: TransitionStatus) => (
          <animated.div
            style={{
              ...childrenDisplayStyle,
              display:
                state === 'entering' ||
                state === 'entered' ||
                state === 'exiting'
                  ? 'block'
                  : 'none',
            }}
          >
            {subTitle && (
              <Text
                variant="titleXS"
                paddingTop="8px"
                paddingBottom="12px"
                color="custom.neutral.black.50"
              >
                {subTitle}
              </Text>
            )}
            {children}
          </animated.div>
        )}
      </Transition>
    </ImportShipmentConfirmInformationSectionContainer>
  );
};

export default ImportShipmentConfirmInformationSection;
