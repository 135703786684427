import React from 'react';

import getOnGooglePlayDe from 'assets/google-play-de.svg';
import getOnGooglePlayEn from 'assets/google-play-en.svg';
import getOnGooglePlayFr from 'assets/google-play-fr.svg';
import { PLAY_STORE_APP_LINK } from 'configs';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const StyledA = styled.a`
  display: block;
`;

export function GetOnGooglePlay() {
  const { i18n } = useTranslation();

  const currentLanguage = i18n.language;

  const badgeByLanguage =
    currentLanguage === 'fr'
      ? getOnGooglePlayFr
      : currentLanguage === 'en'
      ? getOnGooglePlayEn
      : getOnGooglePlayDe;

  return (
    <StyledA href={PLAY_STORE_APP_LINK} data-testid="get-on-google-play">
      <img src={badgeByLanguage} alt="Get it on Google Play" />
    </StyledA>
  );
}
