import React from 'react';

import PartnerInChargeProducts from 'components/organisms/PartnerInChargeProducts';
import PartnerProductsDetail from 'components/views/lpVillage/PartnerProductsDetail';
import { ROUTE_PATHS } from 'configs';
import { Route, Routes } from 'react-router-dom';

const PartnerProducts = () => (
  <Routes>
    <Route index element={<PartnerInChargeProducts />} />
    <Route path={ROUTE_PATHS.PARTNER.CHILDREN.PRODUCT.CHILDREN.LPUI.ROOT}>
      <Route
        path={
          ROUTE_PATHS.PARTNER.CHILDREN.PRODUCT.CHILDREN.LPUI.CHILDREN.DETAIL
            .ROOT
        }
        element={<PartnerProductsDetail />}
      />
    </Route>
  </Routes>
);

export default PartnerProducts;
