import { useCallback } from 'react';

import useImperativeRequest from 'hooks/useImperativeRequest';
import useToastMessages from 'hooks/useToastMessages';

const useEditShipment = () => {
  const [{ loading: deleteLoading }, makeDeleteRequest] =
    useImperativeRequest('shipment');
  const [{ loading: archiveLoading }, makeArchiveRequest] =
    useImperativeRequest('shipment');
  const { error: toastError, success: toastSuccess } = useToastMessages();

  const editShipment = useCallback(
    async ({
      shipmentId,
      makeRequest,
      method,
      body = {},
      errorMsg,
      successMsg,
    }: {
      shipmentId: string;
      makeRequest: any;
      method: any;
      body?: any;
      errorMsg: string;
      successMsg: string;
    }) => {
      const { error } = await makeRequest({
        path: `api/v2/shipments/${shipmentId}`,
        method,
        body,
      });
      if (error) {
        toastError(errorMsg, { wide: true });

        return { success: false };
      }
      toastSuccess(successMsg, { wide: true });

      return { success: true };
    },
    [toastError, toastSuccess]
  );

  const deleteShipment = (shipmentId: string) =>
    editShipment({
      shipmentId,
      makeRequest: makeDeleteRequest,
      method: 'DELETE',
      errorMsg: 'messages:shipmentDeleteError.message',
      successMsg: 'messages:shipmentDeleteSuccess.message',
    });

  const archiveShipment = (shipmentId: string) =>
    editShipment({
      shipmentId,
      makeRequest: makeArchiveRequest,
      method: 'PATCH',
      errorMsg: 'messages:shipmentArchiveError.message',
      successMsg: 'messages:shipmentArchiveSuccess.message',
      body: { isArchived: true },
    });

  return { deleteLoading, deleteShipment, archiveLoading, archiveShipment };
};

export default useEditShipment;
