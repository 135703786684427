import React, { useEffect } from 'react';

import useToastMessages from 'hooks/useToastMessages';
import { Navigate, useSearchParams } from 'react-router-dom';

export const SEARCH_PARAMS_KEY = 'q';
export const SEARCH_PARAMS_DELETE_ACCOUNT = 'deleteAccount';

/**
 * This component detects (through URL search params) can compute action based on search params after logout
 * Use `q` as key for search params
 * Cases:
 *  - [Delete account] if search params contains q=deleteAccount, show toast message
 */
const RedirectToLanding = (props: any) => {
  const [searchParams] = useSearchParams();
  const { success: toastSuccess } = useToastMessages();

  useEffect(() => {
    if (searchParams.get(SEARCH_PARAMS_KEY) === SEARCH_PARAMS_DELETE_ACCOUNT) {
      toastSuccess({
        title: 'account:personal.deletion.notificationSuccess.title',
        msg: 'account:personal.deletion.notificationSuccess.message',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <Navigate {...props} />;
};

export default RedirectToLanding;
