import { useCallback } from 'react';

import useImperativeRequest from 'hooks/useImperativeRequest';
import useToastMessages from 'hooks/useToastMessages';
import useAppState, { userSelector } from 'stores/appState/useAppState';

const useResendVerificationEmail = () => {
  const [{ loading }, makeRequest] = useImperativeRequest('lpAccount');
  const { error: errorToast } = useToastMessages();
  const appUser = useAppState(userSelector);
  const email = appUser?.email;

  const sendEmail = useCallback(async () => {
    if (!email) {
      errorToast('messages:somethingWentWrong.message', { wide: true });

      return;
    }

    await makeRequest({
      path: 'api/v1/me/verification-email',
      method: 'post',
      body: {
        email,
      },
    });
  }, [email, makeRequest, errorToast]);

  return { sendEmail, loading };
};

export default useResendVerificationEmail;
