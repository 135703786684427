import React from 'react';

import {
  AccountIcon,
  Grid,
  NavTabs,
  PartnershipIcon,
  Text,
} from '@livingpackets/design-system-react-next';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import useNavigatePartner from 'hooks/useNavigatePartner';
import { useTranslation } from 'react-i18next';

import { PARTNER_ACCOUNT_ROOT } from '../routes';

interface Props {
  value: 'personal' | 'partnership/information';
  children: React.ReactNode;
  hideTabs?: boolean;
}

export const Layout = ({ value, children, hideTabs }: Props) => {
  const navigate = useNavigatePartner();
  const { t } = useTranslation(['account']);

  const handleChange = (_: React.MouseEvent<HTMLElement>, value: string) => {
    if (!value) return;

    navigate(`${PARTNER_ACCOUNT_ROOT}/${value}`);
  };

  return (
    <Grid container direction="column">
      {/* Header */}
      <Text variant="titleXXL" mt="1.75rem">
        {t('account:title')}
      </Text>
      {/* Tabs selection */}
      {!hideTabs && (
        <Grid item mt=".875rem" mb="2.1875rem">
          <NavTabs>
            <ToggleButtonGroup exclusive value={value} onChange={handleChange}>
              <ToggleButton value="personal">
                <AccountIcon /> {t('account:tabs.personal')}
              </ToggleButton>
              <ToggleButton value="partnership/information">
                <PartnershipIcon /> {t('account:tabs.partnership')}
              </ToggleButton>
            </ToggleButtonGroup>
          </NavTabs>
        </Grid>
      )}
      {/* Account form cards */}
      <Grid item mt={hideTabs ? '2.125rem' : undefined}>
        {children}
      </Grid>
    </Grid>
  );
};
