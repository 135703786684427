import React from 'react';

import { DetailsSpan } from 'components/atoms/ShipmentAtoms';
import ShipmentAnonymousIdData from 'components/molecules/ShipmentAnonymousIdData';
import { IShipmentAnonymousData } from 'models/shipment';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { space, SpaceProps } from 'styled-system';

const Wrapper = styled.div<SpaceProps>`
  display: flex;
  flex-direction: column;
  ${space}
`;

interface IShipmentInformation {
  shipmentData: IShipmentAnonymousData;
}

const ShipmentAnonymousInformation = ({
  shipmentData,
  ...rest
}: IShipmentInformation & SpaceProps) => {
  const { t } = useTranslation('shipments');

  return (
    <Wrapper {...rest}>
      <DetailsSpan>{t('detail.summary.information.title')}</DetailsSpan>
      <ShipmentAnonymousIdData shipmentData={shipmentData} />
    </Wrapper>
  );
};

export default ShipmentAnonymousInformation;
