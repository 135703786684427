import { useCallback } from 'react';

import {
  Paginated,
  useFetchResource,
  UseFetchResourceOptions,
} from '@livingpackets/shared-components';
import { IShipment } from 'models/shipment';

import {
  GetUserShipmentsParams,
  useGetUserShipments,
} from './useGetUserShipments';
import useToastMessages from './useToastMessages';
import useShipmentsStore, {
  setShipmentsSelector,
} from '../stores/useShipmentStore';

export function useShipments(
  params: GetUserShipmentsParams,
  { enabled = true }: UseFetchResourceOptions = {}
): Paginated<IShipment> | any {
  const getUserShipments = useGetUserShipments();
  const { error: errorToast } = useToastMessages();
  const setShipments = useShipmentsStore(setShipmentsSelector);
  const fetchFunction = useCallback(
    () => getUserShipments(params),
    [getUserShipments, params]
  );
  const data = useFetchResource(fetchFunction, { enabled });
  if (data.state === 'success') {
    setShipments(data.resource.items);

    return data.resource.items;
  } else if (data.state === 'error') {
    return errorToast('shipments:shipmentsFetchError');
  }
}
