import React from 'react';

import AppLayout from 'components/containers/layout/AppLayout';
import PartnerShipmentsAnonymousDetail from 'components/views/lpVillage/PartnerShipmentsAnonymousDetail';
import useIsMobile from 'hooks/useIsMobile';

import { ShipmentDetail } from '../components/ShipmentDetail';

export const AnonymousShipmentDetails = () => {
  const { isMobile } = useIsMobile();

  return (
    <>
      {isMobile ? (
        <ShipmentDetail />
      ) : (
        <AppLayout>
          <PartnerShipmentsAnonymousDetail />
        </AppLayout>
      )}
    </>
  );
};
