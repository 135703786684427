import React from 'react';

import styled, { DefaultTheme } from 'styled-components';
import { SpaceProps, space } from 'styled-system';

export type TStatus = 'yellow' | 'primary' | 'red' | 'grey';

interface IStatusLabel extends SpaceProps {
  status: TStatus;
  children?: React.ReactNode;
}

const getColorBg = (status: TStatus, theme: DefaultTheme) => {
  switch (status) {
    case 'yellow':
      return theme.colors.yellow[10];
    case 'primary':
      return theme.colors.primary[10];
    case 'red':
      return theme.colors.red[10];
    case 'grey':
      return theme.colors.black[4];
  }
};

const getColor = (status: TStatus, theme: DefaultTheme) => {
  switch (status) {
    case 'yellow':
      return theme.colors.yellow[100];
    case 'primary':
      return theme.colors.primary[100];
    case 'red':
      return theme.colors.red[100];
    case 'grey':
      return theme.colors.black[20];
  }
};

const StatusTypo = styled.p<SpaceProps & { textTransform?: 'uppercase' }>`
  ${({ theme }) => theme.fontStyles.bodyTextXS}
  text-transform: uppercase;
  ${space}
`;

const StyledDiv = styled.div<IStatusLabel>`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  height: 29px;
  width: fit-content;
  background-color: ${({ theme, status }) => getColorBg(status, theme)};
  color: ${({ theme, status }) => getColor(status, theme)};
  border-radius: 100px;
  ${space};
`;

const StatusLabel = ({ status, children, ...rest }: IStatusLabel) => (
  <StyledDiv status={status} {...rest}>
    <StatusTypo>{children}</StatusTypo>
  </StyledDiv>
);

export default StatusLabel;
