import { useCallback } from 'react';

import { useApiClient } from './useApiClient';
import { ErrorCause } from '../enums/ErrorCause';

type UploadManualCropResponse = string;
type UploadManualCrop = (image: Blob) => Promise<UploadManualCropResponse>;

export function useUploadManualCrop(): UploadManualCrop {
  const shipment = useApiClient('shipment');

  const uploadManualCrop = useCallback(
    async (image: Blob) => {
      let bodyFormData = new FormData();
      bodyFormData.append('file', image || '');

      const uploadResponse = await shipment.post(
        'api/v2/manual-crops/upload',
        bodyFormData,
        {
          headers: { 'Content-Type': 'multipart/form-data' },
        }
      );

      if (uploadResponse.status === 413) {
        throw new Error('The image is too large for the server', {
          cause: ErrorCause.MANUAL_CROP_IMAGE_TOO_LARGE,
        });
      }

      if (uploadResponse.status !== 201) {
        throw new Error('Error uploading crop image', {
          cause: ErrorCause.MANUAL_CROP_IMAGE_UNKNOWN_ERROR,
        });
      }

      return uploadResponse.data.id;
    },
    [shipment]
  );

  return uploadManualCrop;
}
