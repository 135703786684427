import React from 'react';

import { Box, Button } from '@livingpackets/design-system-react';
import { useTranslation } from 'react-i18next';

interface ImportShipmentManuallyCroppingActionType {
  handlePdfPageSelectedCropped: () => void;
  isButtonActionDisabled: boolean;
}

const ImportShipmentManuallyCroppingAction = ({
  handlePdfPageSelectedCropped,
  isButtonActionDisabled,
}: ImportShipmentManuallyCroppingActionType) => {
  const { t } = useTranslation('shipments');

  return (
    <>
      <Box display="flex" justifyContent="flex-end" width="100%">
        <Button
          action="primary"
          onClick={handlePdfPageSelectedCropped}
          isDisabled={isButtonActionDisabled}
        >
          {t('cropLabel')}
        </Button>
      </Box>
    </>
  );
};
export default ImportShipmentManuallyCroppingAction;
