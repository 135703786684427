import { useCallback } from 'react';

import { ShipmentContractStateEnum } from '../models/shipment';

export enum ShipmentListNavTabEnum {
  labels = 'labels',
  ongoing = 'ongoing',
  completed = 'completed',
}

export enum ShipmentListTabEnum {
  labelPurchased = 'label_purchased',
  labelTransferred = 'label_transferred',
  onGoing = 'on_going',
  return = 'return',
  finished = 'finished',
  archived = 'archived',
}

const shipmentListNavTabKey = 'shipmentListNavTab';
const ShipmentListNavTabDefaultValue = ShipmentListNavTabEnum.labels;

const shipmentListTabKey = 'shipmentListTab';
const ShipmentListTabDefaultValue = ShipmentListTabEnum.labelPurchased;

const useShipmentTab = () => {
  const getShipmentNavTabCurrent = (): ShipmentListNavTabEnum =>
    sessionStorage.getItem(shipmentListNavTabKey)
      ? (sessionStorage.getItem(
          shipmentListNavTabKey
        ) as ShipmentListNavTabEnum)
      : ShipmentListNavTabDefaultValue;

  const setShipmentNavTabCurrent = (
    shipmentListNavTab: ShipmentListNavTabEnum
  ) => {
    sessionStorage.setItem(shipmentListNavTabKey, shipmentListNavTab);
  };

  const getShipmentTabCurrent = useCallback(
    (): ShipmentListTabEnum =>
      sessionStorage.getItem(shipmentListTabKey)
        ? (sessionStorage.getItem(shipmentListTabKey) as ShipmentListTabEnum)
        : ShipmentListTabDefaultValue,
    []
  );

  const setShipmentTabCurrent = useCallback(
    (shipmentListTab: ShipmentListTabEnum) => {
      sessionStorage.setItem(shipmentListTabKey, shipmentListTab);
    },
    []
  );

  const resetShipmentNavTab = useCallback(() => {
    sessionStorage.setItem(
      shipmentListNavTabKey,
      ShipmentListNavTabDefaultValue
    );
    sessionStorage.setItem(shipmentListTabKey, ShipmentListTabDefaultValue);
  }, []);

  const resetShipmentListNavTab = useCallback(() => {
    sessionStorage.setItem(
      shipmentListNavTabKey,
      ShipmentListNavTabDefaultValue
    );
    sessionStorage.setItem(shipmentListTabKey, ShipmentListTabDefaultValue);
  }, []);

  const getCurrentContractStateFromCurrentTab =
    useCallback((): ShipmentContractStateEnum => {
      const currentTab = getShipmentTabCurrent();
      let contractState;

      switch (currentTab) {
        case ShipmentListTabEnum.labelPurchased:
          contractState = ShipmentContractStateEnum.PURCHASED;
          break;
        case ShipmentListTabEnum.labelTransferred:
          contractState = ShipmentContractStateEnum.TRANSFERRED;
          break;
        case ShipmentListTabEnum.onGoing:
          contractState = ShipmentContractStateEnum.DEPLOYED;
          break;
        case ShipmentListTabEnum.return:
          contractState = ShipmentContractStateEnum.DEPLOYED;
          break;
        case ShipmentListTabEnum.finished:
          contractState = ShipmentContractStateEnum.ARRIVED;
          break;
        case ShipmentListTabEnum.archived:
          contractState = ShipmentContractStateEnum.ARRIVED;
          break;
      }

      return contractState;
    }, [getShipmentTabCurrent]);

  return {
    getShipmentNavTabCurrent,
    setShipmentNavTabCurrent,
    getShipmentTabCurrent,
    setShipmentTabCurrent,
    resetShipmentNavTab,
    resetShipmentListNavTab,
    getCurrentContractStateFromCurrentTab,
  };
};

export default useShipmentTab;
