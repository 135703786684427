import React, { useCallback, useEffect, useState } from 'react';

import { Box, Stack } from '@livingpackets/design-system-react-next';
import Input from 'components/atoms/Input';
import ContactAddressTypeSelector from 'components/organisms/ContactAddressTypeSelector';
import CountrySelector from 'components/organisms/CountrySelector';
import getFieldNameWithPrefix from 'helpers/getFieldNameWithPrefix';
import useFormTemplate from 'hooks/useFormTemplate';
import { get, set } from 'lodash';
import {
  ContactAddressType,
  IContactAddress,
  IContactAddressForm,
  INITIAL_STATE_CONTACT_ADDRESS_FORM,
} from 'models/contactAddress';
import { useTranslation } from 'react-i18next';
import { contactAddressSchema } from 'schemas/contactAddressSchema';

import { DefaultAddresses } from './DefaultAddresses';

interface IContactAddressType {
  defaultValues?: IContactAddress;
  onValuesChanged: (values: IContactAddressForm) => void;
  isFormValidChanged: (isFormValid: boolean) => void;
  prefix?: string;
  isDismissTriggered?: boolean;
}

const ContactAddressForm = ({
  defaultValues,
  onValuesChanged,
  isFormValidChanged,
  prefix = undefined,
  isDismissTriggered,
}: IContactAddressType) => {
  const { t } = useTranslation('contactAddress');

  const {
    errors,
    register,
    formState: { touchedFields, isValid },
    getValues,
    setValue,
    watch,
    control,
    reset,
    trigger,
  } = useFormTemplate<IContactAddressForm>({
    resolver: contactAddressSchema,
    defaultValues: INITIAL_STATE_CONTACT_ADDRESS_FORM,
  });

  const [hiddenAddressType, setHiddenAddressType] =
    useState<ContactAddressType>(ContactAddressType.professional);

  useEffect(() => {
    if (defaultValues) {
      const inputConfig = {
        shouldValidate: true,
        shouldDirty: true,
        shouldTouch: true,
      };

      const defaultValueAddressType = defaultValues.type
        ? defaultValues.type
        : ContactAddressType.professional;

      setValue(
        getFieldNameWithPrefix(prefix, 'type') as keyof IContactAddressForm,
        defaultValueAddressType,
        inputConfig
      );

      setHiddenAddressType(defaultValueAddressType);

      setValue('city', defaultValues.city, inputConfig);
      setValue('zipCode', defaultValues.postalCode, inputConfig);
      setValue('addressInfo', defaultValues.street, inputConfig);
      setValue('countryCode', defaultValues.countryCode, inputConfig);
      setValue(
        'companyName',
        defaultValues.company ? defaultValues.company : '',
        inputConfig
      );

      // Manually set the fields as touched
      set(touchedFields, `type`, true);
      set(touchedFields, `city`, true);
      set(touchedFields, `zipCode`, true);
      set(touchedFields, `address`, true);
      set(touchedFields, `countryCode`, true);

      if (defaultValues.company) {
        set(touchedFields, `companyName`, true);
      }

      onValuesChanged(getValues() as IContactAddressForm);

      trigger();
    } else {
      const inputConfig = {
        shouldValidate: false,
        shouldDirty: false,
        shouldTouch: false,
      };

      setValue(
        getFieldNameWithPrefix(prefix, 'type') as keyof IContactAddressForm,
        ContactAddressType.professional,
        inputConfig
      );

      setHiddenAddressType(ContactAddressType.professional);

      setValue(
        getFieldNameWithPrefix(prefix, 'city') as keyof IContactAddressForm,
        '',
        inputConfig
      );
      setValue(
        getFieldNameWithPrefix(prefix, 'zipCode') as keyof IContactAddressForm,
        '',
        inputConfig
      );
      setValue(
        getFieldNameWithPrefix(
          prefix,
          'addressInfo'
        ) as keyof IContactAddressForm,
        '',
        inputConfig
      );
      setValue(
        getFieldNameWithPrefix(
          prefix,
          'countryCode'
        ) as keyof IContactAddressForm,
        '',
        inputConfig
      );
      setValue(
        getFieldNameWithPrefix(
          prefix,
          'companyName'
        ) as keyof IContactAddressForm,
        '',
        inputConfig
      );
    }
  }, [
    defaultValues,
    trigger,
    setValue,
    getValues,
    touchedFields,
    onValuesChanged,
    prefix,
  ]);

  useEffect(() => {
    isFormValidChanged(isValid);
  }, [isValid, isFormValidChanged]);

  const handleInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const values = getValues();

      if (prefix && event.currentTarget) {
        if (
          event.currentTarget.name === getFieldNameWithPrefix(prefix, 'type')
        ) {
          values.type = event.currentTarget.value as ContactAddressType;
        }
      }

      onValuesChanged(values as IContactAddressForm);
    },
    [getValues, onValuesChanged, prefix]
  );

  const handleInputTypeChange = useCallback(
    (addressType: ContactAddressType | undefined) => {
      const addressTypeData = addressType
        ? addressType
        : ContactAddressType.professional;

      setHiddenAddressType(addressTypeData);

      setValue('type', addressTypeData);
      trigger('type');

      // Remove the company name when you select the personal type
      if (addressTypeData === ContactAddressType.personal) {
        setValue(
          'companyName',
          INITIAL_STATE_CONTACT_ADDRESS_FORM.companyName,
          {
            shouldValidate: true,
            shouldDirty: true,
          }
        );

        set(touchedFields, `companyName`, true);
      }

      onValuesChanged(getValues() as IContactAddressForm);
    },
    [setValue, trigger, onValuesChanged, getValues, touchedFields]
  );

  useEffect(() => {
    if (isDismissTriggered) {
      reset();
    }
  }, [isDismissTriggered, reset]);

  return (
    <Stack>
      <Input
        type="hidden"
        name="type"
        register={register}
        height="0"
        value={hiddenAddressType}
      />
      <Stack direction="row" pl="0.3125rem" pb="1rem">
        <ContactAddressTypeSelector
          prefix={prefix}
          register={register}
          fieldName="type"
          watch={watch}
          watchUsePrefix={false}
          onValueChanged={handleInputTypeChange}
        />
      </Stack>
      <Stack direction="row" gap="1rem">
        <Box width="50%">
          <Input
            name="companyName"
            label={
              get(touchedFields, 'companyName', false)
                ? t('form.companyName.placeholder')
                : undefined
            }
            placeholder={t('form.companyName.placeholder')}
            error={get(errors, 'companyName', false)}
            isTouched={get(touchedFields, 'companyName', false)}
            register={register}
            disabled={watch('type') === ContactAddressType.personal}
            onChange={handleInputChange}
            width="100%"
          />
        </Box>
        <Box width="50%">
          <Input
            name="addressInfo"
            label={
              get(touchedFields, 'addressInfo', false)
                ? t('form.address.placeholder')
                : undefined
            }
            placeholder={t('form.address.placeholder')}
            error={get(errors, 'addressInfo', false)}
            isTouched={get(touchedFields, 'addressInfo', false)}
            register={register}
            onChange={handleInputChange}
            width="100%"
          />
        </Box>
      </Stack>
      <Stack direction="row" gap="1rem">
        <Box width="50%">
          <Input
            name="city"
            label={
              get(touchedFields, 'city', false)
                ? t('form.city.placeholder')
                : undefined
            }
            placeholder={t('form.city.placeholder')}
            error={get(errors, 'city', false)}
            isTouched={get(touchedFields, 'city', false)}
            register={register}
            onChange={handleInputChange}
            width="100%"
          />
        </Box>
        <Box width="50%">
          <Input
            name="zipCode"
            label={
              get(touchedFields, 'zipCode', false)
                ? t('form.zipCode.placeholder')
                : undefined
            }
            placeholder={t('form.zipCode.placeholder')}
            error={get(errors, 'zipCode', false)}
            isTouched={get(touchedFields, 'zipCode', false)}
            register={register}
            onChange={handleInputChange}
            width="100%"
          />
        </Box>
      </Stack>
      <Stack direction="row" gap="1rem">
        <Box width="50%">
          <CountrySelector
            id={
              prefix
                ? `${prefix.replace('.', '_')}countryCodeSelector`
                : 'countryCodeSelector'
            }
            width="100%"
            control={control}
            onChange={handleInputChange}
            register={register}
          />
        </Box>
        <Box width="50%" />
      </Stack>
      <DefaultAddresses
        addressId={defaultValues?.id || null}
        isDismissTriggered={isDismissTriggered}
        prefix={prefix}
      />
    </Stack>
  );
};

export default ContactAddressForm;
