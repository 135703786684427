import React, { useCallback } from 'react';

import { Stack } from '@livingpackets/design-system-react-next';
import getFieldNameWithPrefix from 'helpers/getFieldNameWithPrefix';
import { ContactAddressType } from 'models/contactAddress';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const AddressTypeChoiceLabelContainer = styled.label`
  display: block;
  cursor: pointer;
  margin-right: 18px;

  &:hover {
    input {
      & + span {
        &:before {
          transform: scale(0.92);
        }

        &:after {
          transform: scale(0.74);
        }
      }

      &:checked + span {
        &:after {
          transform: scale(0.4);
        }
      }
    }
  }
`;

const AddressTypeChoiceInput = styled.input`
  display: none;

  & + span {
    line-height: 20px;
    height: 20px;
    padding-left: 20px;
    display: block;
    position: relative;

    &:not(:empty) {
      padding-left: 28px;
    }

    &:before,
    &:after {
      content: '';
      width: 20px;
      height: 20px;
      display: block;
      border-radius: 50%;
      left: 0;
      top: 0;
      position: absolute;
    }

    &:before {
      background: ${({ theme }) => theme.colors.black[10]};
      transition: background 0.2s ease,
        transform 0.4s cubic-bezier(0.175, 0.885, 0.32, 2);
    }

    &:after {
      background: ${({ theme }) => theme.colors.white};
      transform: scale(0.78);
      transition: transform 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.4);
    }
  }

  &:checked + span {
    &:before {
      transform: scale(1.04);
      background: ${({ theme }) => theme.colors.primary[100]};
    }

    &:after {
      transform: scale(0.4);
      transition: transform 0.3s ease;
    }
  }
`;

const AddressTypeChoiceLabel = styled.span`
  color: ${({ theme }) => theme.colors.black[50]};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  margin-top: 8px;
  cursor: pointer;

  &:hover {
    input {
      & + span {
        &:before {
          transform: scale(0.92);
        }

        &:after {
          transform: scale(0.74);
        }
      }

      &:checked + span {
        &:after {
          transform: scale(0.4);
        }
      }
    }
  }
`;

export interface IContactAddressTypeSelector {
  prefix?: string;
  register: any;
  onValueChanged?: (addressType: ContactAddressType | undefined) => void;
  fieldName?: string;
  watch: any;
  watchUsePrefix?: boolean;
}

const ContactAddressTypeSelector = ({
  prefix,
  register,
  onValueChanged,
  fieldName = 'type',
  watch,
  watchUsePrefix = true,
}: IContactAddressTypeSelector) => {
  const { t } = useTranslation(['contactAddress']);

  const handleInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      let value = undefined;

      if (prefix && event.currentTarget) {
        if (
          event.currentTarget.name === getFieldNameWithPrefix(prefix, fieldName)
        ) {
          value = event.currentTarget.value as ContactAddressType;
        }
      } else if (!prefix) {
        value = event.currentTarget.value as ContactAddressType;
      }

      if (onValueChanged) {
        onValueChanged(value);
      }
    },
    [onValueChanged, prefix, fieldName]
  );

  return (
    <Stack direction="row" paddingLeft="5px">
      <AddressTypeChoiceLabelContainer
        data-testid={
          prefix
            ? prefix + 'addressTypeLabel-' + ContactAddressType.professional
            : 'addressTypeLabel-' + ContactAddressType.professional
        }
      >
        <AddressTypeChoiceInput
          name={getFieldNameWithPrefix(prefix, fieldName)}
          type="radio"
          value={ContactAddressType.professional}
          width="20px"
          height="20px"
          checked={
            watch(
              getFieldNameWithPrefix(watchUsePrefix ? prefix : '', fieldName)
            ) === ContactAddressType.professional
          }
          {...register(getFieldNameWithPrefix(prefix, fieldName))}
          onChange={handleInputChange}
        />
        <AddressTypeChoiceLabel>
          {t('contactAddress:form.type.choices.professional')}
        </AddressTypeChoiceLabel>
      </AddressTypeChoiceLabelContainer>
      <AddressTypeChoiceLabelContainer
        data-testid={
          prefix
            ? prefix + 'addressTypeLabel-' + ContactAddressType.personal
            : 'addressTypeLabel-' + ContactAddressType.personal
        }
      >
        <AddressTypeChoiceInput
          name={getFieldNameWithPrefix(prefix, fieldName)}
          type="radio"
          value={ContactAddressType.personal}
          width="20px"
          height="20px"
          checked={
            watch(
              getFieldNameWithPrefix(watchUsePrefix ? prefix : '', fieldName)
            ) === ContactAddressType.personal
          }
          {...register(getFieldNameWithPrefix(prefix, fieldName))}
          onChange={handleInputChange}
        />
        <AddressTypeChoiceLabel>
          {t('contactAddress:form.type.choices.personal')}
        </AddressTypeChoiceLabel>
      </AddressTypeChoiceLabelContainer>
    </Stack>
  );
};

export default ContactAddressTypeSelector;
