import { create } from 'zustand';

import { NewsletterItem } from '../models/newsletter';

export interface INewslettersStore {
  newsletters: NewsletterItem[];
  setNewsletters: (newsletters: NewsletterItem[]) => void;
}

export const newsletterSelector = (state: INewslettersStore) =>
  state.newsletters;
export const setNewsletterSelector = (state: INewslettersStore) =>
  state.setNewsletters;

const useNewsletterStore = create<INewslettersStore>(set => ({
  newsletters: [],
  setNewsletters: newsletters => set(() => ({ newsletters: newsletters })),
}));

export default useNewsletterStore;
