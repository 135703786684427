import React, { useCallback } from 'react';

import { Tooltip } from '@livingpackets/design-system-react';
import { Text, Stack, useTheme } from '@livingpackets/design-system-react-next';
import { useTranslation } from 'react-i18next';

const TooltipBankAccountInformation = () => {
  const { t } = useTranslation('contribution');
  const theme = useTheme();

  const crispHandleClick = useCallback(() => {
    if ((window as any).$crisp) {
      const CRISP = (window as any).$crisp;

      CRISP.push(['safe', true]);
      CRISP.push(['do', 'chat:open']);
    }
  }, []);

  return (
    <Tooltip
      noArrow
      clickable
      id="bank-account-information-tooltip"
      place="bottom"
      width="275px"
      height="auto"
      isOpen={undefined}
      style={{ zIndex: 1, opacity: 1, height: 'auto' }}
      offset={20}
    >
      <Stack>
        <Text variant="titleM" color="custom.neutral.black.100" mb=".625rem">
          {t(
            'contributionsTab.contributionsList.info.bankAccountInformation.title'
          )}
        </Text>
        <Text variant="bodyTextS" color="custom.neutral.black.100">
          {t(
            'contributionsTab.contributionsList.info.bankAccountInformation.action'
          )}
          <span
            style={{
              color: theme.palette.custom.primary['100'],
              marginLeft: '3px',
              cursor: 'pointer',
            }}
            onClick={() => crispHandleClick()}
          >
            {t(
              'contributionsTab.contributionsList.info.bankAccountInformation.cxTeam'
            )}
          </span>
        </Text>
      </Stack>
    </Tooltip>
  );
};

export default TooltipBankAccountInformation;
