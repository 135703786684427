import React, { useEffect, useMemo } from 'react';

import { usePagination } from '@livingpackets/shared-components';
import NonFullWidthWrapper from 'components/atoms/NonFullWidthWrapper';
import BoxContractsList from 'components/molecules/BoxContractsList';
import BuyAndRentBalance from 'components/molecules/BuyAndRentBalance';
import Title from 'components/molecules/Title';
import { useBoxContracts } from 'hooks/useBoxContracts';
import { useBoxContractStats } from 'hooks/useBoxContractStats';
import useCurrentPartner from 'hooks/useCurrentPartner';
import { useTranslation } from 'react-i18next';
import useCurrentPartnerStore, {
  registrationAddressSelector,
} from 'stores/useCurrentPartnerStore';
import useMyPartnersStore, {
  activePartnerSelector,
} from 'stores/useMyPartnersStore';

const PartnerBoxContracts = () => {
  const activePartner = useMyPartnersStore(activePartnerSelector);
  const { t } = useTranslation('general');
  const { reset, getCurrentPartner, getRegistrationAddress } =
    useCurrentPartner();
  const { params: paginationParams, setParams: setPaginationParams } =
    usePagination({
      page: 1,
      pageSize: 5,
      orderColumn: 'paymentReceivedAt',
      orderDirection: 'DESC',
    });
  const boxContractsParams = useMemo(
    () => ({
      ...paginationParams,
      partnerId: activePartner.id,
    }),
    [paginationParams, activePartner.id]
  );
  const boxContractsFetch = useBoxContracts(boxContractsParams);
  const boxContractStats = useBoxContractStats(activePartner.id);

  useEffect(() => {
    getRegistrationAddress(activePartner.id);
    getCurrentPartner(activePartner.id);

    return () => {
      reset();
    };
  }, [activePartner.id, getRegistrationAddress, reset, getCurrentPartner]);

  const registrationAddress = useCurrentPartnerStore(
    registrationAddressSelector
  );

  return (
    <>
      <Title pageName={t('contracts')} />
      <NonFullWidthWrapper>
        <BuyAndRentBalance
          isPartner
          address={registrationAddress}
          stats={boxContractStats}
        />
        {boxContractsFetch.state === 'success' &&
          boxContractsFetch.resource.items && (
            <BoxContractsList
              isPartner
              contracts={boxContractsFetch.resource.items}
              pagination={paginationParams}
              onOrderChange={setPaginationParams}
              onPaginationChange={setPaginationParams}
            />
          )}
      </NonFullWidthWrapper>
    </>
  );
};

export default PartnerBoxContracts;
