import { useCallback } from 'react';
import { useState } from 'react';
const DEFAULT_OPTIONS = { enabled: true };
export function useMutation(fetchFunction) {
    const [state, setState] = useState({
        state: 'idle',
    });
    const execute = useCallback((...args) => {
        setState({ state: 'loading' });
        return fetchFunction(...args)
            .then(resource => {
            setState({ state: 'success', resource });
            return resource;
        })
            .catch(error => {
            setState({ state: 'error', error });
            throw error;
        });
    }, [fetchFunction]);
    return Object.assign(Object.assign({}, state), { execute });
}
