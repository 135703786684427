import React, { useEffect, useMemo } from 'react';

import {
  Stack,
  Box,
  Text,
  styled,
} from '@livingpackets/design-system-react-next';
import Dropdown from 'components/atoms/Dropdown';
import Input from 'components/atoms/Input';
import useFormTemplate from 'hooks/useFormTemplate';
import { set } from 'lodash';
import get from 'lodash/get';
import { IMemberForm, INITIAL_STATE_MEMBER_FORM, IMember } from 'models/user';
import { useTranslation } from 'react-i18next';
import { memberSchema } from 'schemas/memberSchema';
import useMyPartnersStore, {
  activePartnerSelector,
} from 'stores/useMyPartnersStore';

interface IMemberType {
  defaultValues?: IMember;
  onValuesChanged: (values: IMemberForm) => void;
  isFormValidChanged: (isFormValid: boolean) => void;
  isEmailInputDisabled?: boolean;
}

const VStackMemberForm = styled(Stack)`
  width: 50%;

  @media (max-width: ${({ theme }) => theme.mediaBreakpoints.md}) {
    width: 100%;
  }
`;

const MemberForm = ({
  defaultValues,
  onValuesChanged,
  isFormValidChanged,
  isEmailInputDisabled = false,
}: IMemberType) => {
  const { t } = useTranslation('userManagement');
  const activePartner = useMyPartnersStore(activePartnerSelector);

  const {
    errors,
    register,
    formState: { touchedFields, isValid },
    getValues,
    setValue,
    watch,
    trigger,
  } = useFormTemplate<IMemberForm>({
    resolver: memberSchema,
    defaultValues: INITIAL_STATE_MEMBER_FORM,
  });

  const roleValue = watch('roleId');

  useEffect(() => {
    if (defaultValues) {
      const inputConfig = {
        shouldValidate: true,
        shouldDirty: true,
        shouldTouch: true,
      };

      setValue('firstName', defaultValues.firstName, inputConfig);
      setValue('lastName', defaultValues.lastName, inputConfig);
      setValue('email', defaultValues.email, inputConfig);
      setValue('roleId', defaultValues.role.id, inputConfig);

      // Manually set the fields as touched
      set(touchedFields, `firstName`, true);
      set(touchedFields, `lastName`, true);
      set(touchedFields, `email`, true);
      set(touchedFields, `roleId`, true);

      onValuesChanged(getValues() as IMemberForm);
    }
  }, [defaultValues, onValuesChanged, touchedFields, setValue, getValues]);

  useEffect(() => {
    isFormValidChanged(isValid);
  }, [isValid, isFormValidChanged]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onValuesChanged(getValues() as IMemberForm);
  };

  const usableMemberRoleOptions: Array<{
    value: string | number;
    label: string;
  }> = useMemo(() => {
    const roleList = [];

    if (process.env.REACT_APP_PARTNER_USER_ROLE_BASIC_ID) {
      roleList.push({
        value: process.env.REACT_APP_PARTNER_USER_ROLE_BASIC_ID,
        label: t('form.role.choice.basic'),
      });
    }

    if (process.env.REACT_APP_PARTNER_USER_ROLE_PARTNER_ADMIN_ID) {
      roleList.push({
        value: process.env.REACT_APP_PARTNER_USER_ROLE_PARTNER_ADMIN_ID,
        label: t('form.role.choice.partnerAdmin'),
      });
    }

    return roleList;
  }, [t]);

  const handleRoleChange = async (option: { label: string; value: any }) => {
    setValue('roleId', option.value);
    await trigger('roleId');
    onValuesChanged(getValues() as IMemberForm);
  };

  return (
    <VStackMemberForm as="form">
      <Text variant="titleM">{t('create.block.basicInformation.title')}</Text>
      <Text
        variant="titleXS"
        color="custom.neutral.black.50"
        mt="8px"
        mb="20px"
      >
        {t('create.block.basicInformation.subTitle')}
      </Text>
      <Box>
        <Input
          type="hidden"
          name="partnerType"
          register={register}
          height="0"
          value={activePartner.type}
        />
        <Input
          name="firstName"
          label={
            get(touchedFields, 'firstName', false)
              ? t('form.firstName.placeholder')
              : undefined
          }
          placeholder={t('form.firstName.placeholder')}
          error={errors.firstName}
          isTouched={get(touchedFields, 'firstName', false)}
          register={register}
          onChange={handleInputChange}
          width="100%"
        />
      </Box>
      <Box>
        <Input
          name="lastName"
          label={
            get(touchedFields, 'lastName', false)
              ? t('form.lastName.placeholder')
              : undefined
          }
          placeholder={t('form.lastName.placeholder')}
          error={errors.lastName}
          isTouched={get(touchedFields, 'lastName', false)}
          register={register}
          onChange={handleInputChange}
          width="100%"
        />
      </Box>
      <Box>
        <Input
          name="email"
          label={
            get(touchedFields, 'email', false)
              ? t('form.email.placeholder')
              : undefined
          }
          placeholder={t('form.email.placeholder')}
          error={errors.email}
          isTouched={get(touchedFields, 'email', false)}
          register={register}
          onChange={handleInputChange}
          width="100%"
          disabled={isEmailInputDisabled}
        />
      </Box>
      <Text variant="titleM" mt="20px">
        {t('create.block.role.title')}
      </Text>
      <Text
        variant="titleXS"
        color="custom.neutral.black.50"
        mt="8px"
        mb="20px"
      >
        {t('create.block.role.subTitle')}
      </Text>
      <Input
        type="hidden"
        name="roleId"
        register={register}
        height="0"
        value={roleValue}
        mt="0"
      />
      <Dropdown
        id="roleId"
        options={usableMemberRoleOptions}
        label={t('form.role.placeholder')}
        value={
          usableMemberRoleOptions
            .filter(r => r.value !== undefined)
            .find(r => r.value === roleValue) || null
        }
        width="100%"
        onChange={handleRoleChange}
      />
    </VStackMemberForm>
  );
};

export default MemberForm;
