import React from 'react';

import { Backdrop } from '@livingpackets/design-system-react-next';
import whiteDotsLoader from 'assets/lotties/white-dots-loader.json';
import Lottie from 'react-lottie';

export const LoadingModal = ({
  open,
  setOpen,
}: {
  open: boolean;
  setOpen: (value: boolean) => void;
}) => (
  <Backdrop open={open} onClick={() => setOpen(false)}>
    <Lottie
      style={{
        width: '8rem',
        height: '8rem',
        color: 'white',
      }}
      isClickToPauseDisabled
      options={{
        loop: true,
        autoplay: true,
        animationData: whiteDotsLoader,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice',
        },
      }}
    />
  </Backdrop>
);
