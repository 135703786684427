import React from 'react';

import { useRoutes } from 'react-router-dom';

import { AnonymousShipmentDetails } from './AnonymousShipmentDetails';
import { Delivery } from './Delivery';

export const SHIPMENT_ANONYMOUS_ROOT =
  '/shipments/:trackingCode/anonymous-detail/*';

export const SHIPMENT_ANONYMOUS_SHORT_LINK = '/s/:trackingCode/*';

const routes = [
  // Routes
  {
    path: '',
    element: <AnonymousShipmentDetails />,
  },
  { path: '/delivery', element: <Delivery /> },
];

export const ShipmentAnonymous = () => {
  const element = useRoutes(routes);

  return <>{element}</>;
};
