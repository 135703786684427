import { useCallback } from 'react';

import useImperativeRequest from 'hooks/useImperativeRequest';

import useToastMessages from './useToastMessages';
import { HttpRequestMethod } from '../enums/httpRequest';
import { ILPAccountAddress } from '../models/address';
import { IBillingAddressForm } from '../models/billingAddress';

const useMeAddresses = () => {
  const [{ loading }, makeRequest] = useImperativeRequest('lpAccount');
  const { error: toastError, success: toastSuccess } = useToastMessages();

  const getBillingAddressList = useCallback(async () => {
    const { error, data } = await makeRequest({
      path: `api/v1/me/addresses`,
      method: HttpRequestMethod.GET,
    });

    if (error) {
      toastError('messages:getBillingAddressError.message', {
        wide: true,
      });

      return { success: false, data: null };
    }

    return { success: true, data };
  }, [makeRequest, toastError]);

  const createBillingAddress = useCallback(
    async (values: any) => {
      const { error, data } = await makeRequest({
        path: `api/v1/me/addresses`,
        method: HttpRequestMethod.POST,
        body: { ...values, types: ['billing'] },
      });

      if (error) {
        return { success: false, data: null };
      }

      return { success: true, data };
    },
    [makeRequest]
  );

  const updateAddress = useCallback(
    async (addressData: ILPAccountAddress) => {
      const { error, data } = await makeRequest({
        path: `api/v1/me/addresses`,
        method: HttpRequestMethod.PUT,
        body: addressData,
      });

      if (error) {
        return { success: false };
      }

      return { success: true, data };
    },
    [makeRequest]
  );

  const deleteAddress = useCallback(
    async (id: string) => {
      const { error } = await makeRequest({
        path: `api/v1/me/addresses/remove`,
        method: HttpRequestMethod.PUT,
        body: { id },
      });

      if (error) {
        toastError('messages:deleteBillingAddressError.message', {
          wide: true,
        });

        return { success: false };
      }
      toastSuccess('messages:deleteBillingAddressSuccess.message', {
        wide: true,
      });

      return { success: true };
    },
    [makeRequest, toastError, toastSuccess]
  );

  const getAccountAddressFromBillingAddressForm = useCallback(
    (billingAddressFormData: IBillingAddressForm) => ({
      firstName: billingAddressFormData.firstName,
      lastName: billingAddressFormData.lastName,
      company: billingAddressFormData.companyName
        ? billingAddressFormData.companyName
        : '',
      city: billingAddressFormData.city,
      street: billingAddressFormData.address,
      postalCode: billingAddressFormData.zipCode,
      countryCode: billingAddressFormData.countryCode,
    }),
    []
  );

  const getBillingAddressFormFromAccountAddress = useCallback(
    (currentBillingAddress: ILPAccountAddress): IBillingAddressForm => ({
      firstName: currentBillingAddress.firstName,
      lastName: currentBillingAddress.lastName,
      companyName: currentBillingAddress.company,
      city: currentBillingAddress.city,
      address: currentBillingAddress.street,
      zipCode: currentBillingAddress.postalCode,
      countryCode: currentBillingAddress.countryCode,
    }),
    []
  );

  const formatIBAN = (iban: string): string => {
    const visibleDigits = 4;
    const maskedIBAN =
      iban.slice(0, -visibleDigits).replace(/./g, '*') +
      iban.slice(-visibleDigits);

    return maskedIBAN.replace(/(.{4})/g, '$1 ');
  };

  return {
    loading,
    getBillingAddressList,
    createBillingAddress,
    updateAddress,
    deleteAddress,
    getAccountAddressFromBillingAddressForm,
    getBillingAddressFormFromAccountAddress,
    formatIBAN,
  };
};

export default useMeAddresses;
