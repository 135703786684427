import { ProductArticleCodeEnum, ProductTypeEnum } from 'enums/ProductEnum';
import {
  articleCodeBoxList,
  articleCodeBoxProList,
  articleCodeTabletList,
} from 'models/product';

export const getProductType = (articleCode?: string) => {
  if (!articleCode) {
    return ProductTypeEnum.UNKNOWN;
  }

  if (articleCodeBoxList.includes(articleCode as ProductArticleCodeEnum)) {
    return ProductTypeEnum.BOX;
  }

  if (articleCodeBoxProList.includes(articleCode as ProductArticleCodeEnum)) {
    return ProductTypeEnum.BOX_PRO;
  }

  if (articleCodeTabletList.includes(articleCode as ProductArticleCodeEnum)) {
    return ProductTypeEnum.TABLET;
  }

  return ProductTypeEnum.UNKNOWN;
};
