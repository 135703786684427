export interface Offer {
  contract_information: {
    client_code: string;
    contact: {
      email: string;
      name: string;
      phone: string;
    };
    contract_id: string;
    cost_per_month: number;
    cost_per_shipment: number;
    end_time: string;
    start_time: string;
    type: string;
  };
  created_at: string;
  description: string;
  name: string;
  paas_configuration: {
    has_geo_path: boolean;
    has_remote_control: boolean;
    has_timeline: boolean;
    product_communication_frequency: number;
    product_sensors: {
      humidity: {
        is_activated: boolean;
        notifications: {
          is_email_activated: boolean;
        };
      };
      intrusion: {
        is_activated: boolean;
        notifications: {
          is_email_activated: boolean;
        };
      };
      is_adjustable: boolean;
      pressure: {
        is_activated: boolean;
        notifications: {
          is_email_activated: boolean;
        };
      };
      shock: {
        is_activated: boolean;
        notifications: {
          is_email_activated: boolean;
        };
      };
      temperature: {
        is_activated: boolean;
        notifications: {
          is_email_activated: boolean;
        };
      };
    };
    version: string;
  };
  sensor_configuration: {
    humidity_threshold: {
      percent_max: number;
      percent_min: number;
    };
    shock_threshold: {
      height: number;
    };
    temperature_threshold: {
      celsius_max: number;
      celsius_min: number;
    };
  };
  updated_at: string;
}

export const INITIAL_STATE_OFFER: Offer = {
  contract_information: {
    client_code: '',
    contact: {
      name: '',
      email: '',
      phone: '',
    },
    contract_id: '',
    cost_per_shipment: 0,
    cost_per_month: 0,
    start_time: new Date().toISOString(),
    end_time: new Date().toISOString(),
    type: 'OFFER_TYPE_UNSPECIFIED',
  },
  created_at: new Date().toISOString(),
  updated_at: new Date().toISOString(),
  description: '',
  name: '',
  paas_configuration: {
    has_geo_path: false,
    has_remote_control: false,
    has_timeline: false,
    product_communication_frequency: 0,
    product_sensors: {
      humidity: {
        is_activated: false,
        notifications: {
          is_email_activated: false,
        },
      },
      intrusion: {
        is_activated: false,
        notifications: {
          is_email_activated: false,
        },
      },
      is_adjustable: false,
      pressure: {
        is_activated: false,
        notifications: {
          is_email_activated: false,
        },
      },
      shock: {
        is_activated: false,
        notifications: {
          is_email_activated: false,
        },
      },
      temperature: {
        is_activated: false,
        notifications: {
          is_email_activated: false,
        },
      },
    },
    version: '',
  },
  sensor_configuration: {
    humidity_threshold: {
      percent_max: 0,
      percent_min: 0,
    },
    shock_threshold: {
      height: 0,
    },
    temperature_threshold: {
      celsius_max: 0,
      celsius_min: 0,
    },
  },
};
