import React from 'react';

import { Text, Stack, styled } from '@livingpackets/design-system-react-next';
import { IOS_APP_STORE_APP_LINK, PLAY_STORE_APP_LINK } from 'configs';
import { useTranslation } from 'react-i18next';

import AppStoreDe1x from '../../assets/img/badgeStore/appStore/appStore-de.png';
import AppStoreDe2x from '../../assets/img/badgeStore/appStore/appStore-de@2x.png';
import AppStoreDe3x from '../../assets/img/badgeStore/appStore/appStore-de@3x.png';
import AppStoreEn1x from '../../assets/img/badgeStore/appStore/appStore-en.png';
import AppStoreEn2x from '../../assets/img/badgeStore/appStore/appStore-en@2x.png';
import AppStoreEn3x from '../../assets/img/badgeStore/appStore/appStore-en@3x.png';
import AppStoreFr1x from '../../assets/img/badgeStore/appStore/appStore-fr.png';
import AppStoreFr2x from '../../assets/img/badgeStore/appStore/appStore-fr@2x.png';
import AppStoreFr3x from '../../assets/img/badgeStore/appStore/appStore-fr@3x.png';
import GooglePlayDe1x from '../../assets/img/badgeStore/googlePlay/googlePlay-de.png';
import GooglePlayDe2x from '../../assets/img/badgeStore/googlePlay/googlePlay-de@2x.png';
import GooglePlayDe3x from '../../assets/img/badgeStore/googlePlay/googlePlay-de@3x.png';
import GooglePlayEn1x from '../../assets/img/badgeStore/googlePlay/googlePlay-en.png';
import GooglePlayEn2x from '../../assets/img/badgeStore/googlePlay/googlePlay-en@2x.png';
import GooglePlayEn3x from '../../assets/img/badgeStore/googlePlay/googlePlay-en@3x.png';
import GooglePlayFr1x from '../../assets/img/badgeStore/googlePlay/googlePlay-fr.png';
import GooglePlayFr2x from '../../assets/img/badgeStore/googlePlay/googlePlay-fr@2x.png';
import GooglePlayFr3x from '../../assets/img/badgeStore/googlePlay/googlePlay-fr@3x.png';
import useAppState, { userSelector } from '../../stores/appState/useAppState';

const HeaderWrapper = styled(Stack)`
  flex-direction: row;
  align-items: flex-end;
  margin-bottom: 15px;
`;

const TitleWrapper = styled(Stack)`
  flex: 2;
  flex-direction: column;
`;

const IconsWrapper = styled(Stack)`
  flex-direction: row;
  flex: 1;
  justify-content: flex-end;
`;

const BadgePlayStore = styled('img')`
  margin-left: 0.554rem;
`;

interface BadgeLanguage {
  appStore1x: string;
  appStore2x: string;
  appStore3x: string;
  googlePlay1x: string;
  googlePlay2x: string;
  googlePlay3x: string;
}

interface BadgeOptions {
  [key: string]: BadgeLanguage;
}

const badges: BadgeOptions = {
  en: {
    appStore1x: AppStoreEn1x,
    appStore2x: AppStoreEn2x,
    appStore3x: AppStoreEn3x,
    googlePlay1x: GooglePlayEn1x,
    googlePlay2x: GooglePlayEn2x,
    googlePlay3x: GooglePlayEn3x,
  },
  fr: {
    appStore1x: AppStoreFr1x,
    appStore2x: AppStoreFr2x,
    appStore3x: AppStoreFr3x,
    googlePlay1x: GooglePlayFr1x,
    googlePlay2x: GooglePlayFr2x,
    googlePlay3x: GooglePlayFr3x,
  },
  de: {
    appStore1x: AppStoreDe1x,
    appStore2x: AppStoreDe2x,
    appStore3x: AppStoreDe3x,
    googlePlay1x: GooglePlayDe1x,
    googlePlay2x: GooglePlayDe2x,
    googlePlay3x: GooglePlayDe3x,
  },
};

const DashboardHeader = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation('dashboard');

  const appUser = useAppState(userSelector);

  const renderBadges = () => {
    const {
      appStore1x,
      appStore2x,
      appStore3x,
      googlePlay1x,
      googlePlay2x,
      googlePlay3x,
    } = badges[language];

    return (
      <IconsWrapper alignItems="flex-end">
        <a href={IOS_APP_STORE_APP_LINK} data-testid="get-on-app-store">
          <img
            src={appStore1x}
            srcSet={`${appStore2x} 2x, ${appStore3x} 3x`}
            alt="Download on the App Store"
          />
        </a>
        <a href={PLAY_STORE_APP_LINK} data-testid="get-on-google-play">
          <BadgePlayStore
            src={googlePlay1x}
            srcSet={`${googlePlay2x} 2x, ${googlePlay3x} 3x`}
            alt="GET IT ON Google Play"
          />
        </a>
      </IconsWrapper>
    );
  };

  return (
    <HeaderWrapper>
      <TitleWrapper>
        <Stack direction="row">
          <Text color="custom.primary.140" variant="titleXXL">
            {t('pageTitle')}
          </Text>
          <Text color="custom.neutral.black.90" variant="titleXXL" ml="0.3rem">
            {appUser?.firstName},
          </Text>
        </Stack>
        <Text color="custom.neutral.black.90" variant="bodyTextL">
          {t('pageSubtitle')}
        </Text>
      </TitleWrapper>
      {renderBadges()}
    </HeaderWrapper>
  );
};

export default DashboardHeader;
