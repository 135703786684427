import React from 'react';

import { Text, Box, Stack } from '@livingpackets/design-system-react-next';
import { DetailsCard, DetailsSpan } from 'components/atoms/ShipmentAtoms';
import { ProvidedWeightTooltip } from 'components/molecules/ShipmentWeightTooltips';
import useIntlFormatTimestamp from 'hooks/useIntlFormatTimestamp';
import { IShipmentAnonymousData } from 'models/shipment';
import { useTranslation } from 'react-i18next';

import CopyToClipboard from '../atoms/CopyToClipboard';

const DataValue = ({
  label,
  labelHelp,
  value,
  icon,
  dataTestId,
  displayCopyToClipboardButton,
  ...rest
}: {
  label: string;
  labelHelp?: string;
  value: string | number | null;
  icon?: React.ReactNode;
  dataTestId?: string;
  displayCopyToClipboardButton?: boolean;
}) => (
  <Stack>
    <Stack direction="row" alignItems="center" justifyContent="space-between">
      <Stack>
        <Stack direction="row" alignItems="center">
          <Stack direction="row">
            <DetailsSpan>{label}</DetailsSpan>
            {labelHelp && (
              <Text variant="bodyTextXS" color="custom.neutral.black.50">
                {labelHelp}
              </Text>
            )}
          </Stack>
          {icon}
        </Stack>
        <Text
          variant="bodyTextS"
          color="custom.neutral.black.100"
          mt="4px"
          data-testid={dataTestId}
          {...rest}
        >
          {value || '- - - - -'}
        </Text>
      </Stack>
      {displayCopyToClipboardButton && value && (
        <CopyToClipboard
          data={typeof value === 'number' ? value.toString() : value}
        />
      )}
    </Stack>
  </Stack>
);

interface IShipmentAnonymousIdData {
  shipmentData: IShipmentAnonymousData;
  isLinked?: boolean;
}

const toKg = (weight: number) => (weight / 1000).toString() + 'kg';

const ShipmentAnonymousIdData = ({
  shipmentData,
  isLinked = false,
}: IShipmentAnonymousIdData) => {
  const { t } = useTranslation('shipments');
  const formatTimestamp = useIntlFormatTimestamp();

  return (
    <DetailsCard
      padding="20px"
      mt="12px"
      data-testid="shipment-id-card"
      data-testvalue={isLinked ? 'linked' : 'current'}
    >
      <Stack>
        <DataValue
          dataTestId="shipment-importDate-card"
          label={t('detail.summary.information.importDate')}
          value={formatTimestamp(shipmentData.shipment.created_at, {
            day: 'numeric',
            month: 'long',
            year: 'numeric',
            hour: undefined,
            minute: undefined,
          })}
          displayCopyToClipboardButton={false}
        />
        {shipmentData.shipment.shipment_id && (
          <Box marginTop="24px !important">
            <DataValue
              displayCopyToClipboardButton
              label={t('detail.summary.information.shipmentId')}
              value={shipmentData.shipment.shipment_id}
            />
          </Box>
        )}
        {shipmentData.shipment.product.lp_ui && (
          <Box marginTop="24px !important">
            <DataValue
              displayCopyToClipboardButton
              dataTestId="shipment-lpui-card"
              label={t('detail.summary.information.lpUi')}
              labelHelp={t('detail.summary.information.lpUiHelp')}
              value={shipmentData.shipment.product.lp_ui}
            />
          </Box>
        )}
        {shipmentData.shipment.contract_state !== 'DRAFT' && (
          <Box marginTop="24px !important">
            <DataValue
              label={t('detail.summary.information.providedWeight')}
              dataTestId="providedWeight"
              value={
                shipmentData.shipment.weight_in_grams
                  ? toKg(shipmentData.shipment.weight_in_grams)
                  : '--'
              }
              icon={<ProvidedWeightTooltip />}
            />
          </Box>
        )}
      </Stack>
    </DetailsCard>
  );
};

export default ShipmentAnonymousIdData;
