import React from 'react';

import LPAccountRouter from 'components/containers/LPAccountRouter';
import PartnerRouter from 'components/containers/PartnerRouter';
import PrivateRoute from 'components/containers/PrivateRoute';
import Error403 from 'components/views/lpAccount/Error403';
import Error404 from 'components/views/lpAccount/Error404';
import PartnerShipmentsAnonymousDetail from 'components/views/lpVillage/PartnerShipmentsAnonymousDetail';
import { PATHS, ROUTE_PATHS } from 'configs';
import { UserBusinessTypeEnum } from 'enums/UserBusinessTypeEnum';
import { Navigate, Route, Routes } from 'react-router-dom';
import useAppState, { userSelector } from 'stores/appState/useAppState';
import useMyPartnersStore, {
  activePartnerSelector,
} from 'stores/useMyPartnersStore';

const MainRouter = () => {
  const currentUser = useAppState(userSelector);
  const currentPartner = useMyPartnersStore(activePartnerSelector);

  return (
    <Routes>
      <Route
        path={ROUTE_PATHS.ACCOUNT.ROOT}
        element={
          <PrivateRoute
            component={<LPAccountRouter />}
            isAvailableForCurrentUser={
              currentUser?.businessType === UserBusinessTypeEnum.PERSONAL
            }
          />
        }
      />

      <Route
        path={ROUTE_PATHS.PARTNER.ROOT}
        element={
          <PrivateRoute
            component={<PartnerRouter />}
            isAvailableForCurrentUser={
              currentUser?.businessType === UserBusinessTypeEnum.BUSINESS
            }
          />
        }
      />

      {currentUser?.businessType === UserBusinessTypeEnum.PERSONAL && (
        <Route
          index
          element={<Navigate to={{ pathname: PATHS.ACCOUNT_ROOT }} />}
        />
      )}

      {currentUser?.businessType === UserBusinessTypeEnum.BUSINESS &&
        currentPartner && (
          <Route
            index
            element={<Navigate to={`/partner/${currentPartner.id}`} />}
          />
        )}

      {currentUser?.businessType === UserBusinessTypeEnum.UNKNOWN && (
        <Route
          index
          element={
            <Navigate
              to={{ pathname: PATHS.AUTHENTICATION.ACCOUNT_TYPE_CHOICE }}
            />
          }
        />
      )}
      {/* TODO : route shipments */}
      <Route path={ROUTE_PATHS.SHIPMENTS.ROOT}>
        <Route path={ROUTE_PATHS.SHIPMENTS.CHILDREN.TRACKING_CODE.ROOT}>
          <Route
            path={
              ROUTE_PATHS.SHIPMENTS.CHILDREN.TRACKING_CODE.CHILDREN
                .ANONYMOUS_DETAIL.ROOT
            }
            element={<PartnerShipmentsAnonymousDetail />}
          />
        </Route>
      </Route>
      <Route
        path={PATHS.OPEN_APP}
        element={<Navigate to={{ pathname: PATHS.LANDING }} />}
      />
      <Route path={PATHS.PAGE_403} element={<Error403 />} />
      <Route path={PATHS.PAGE_404} element={<Error404 />} />
      <Route element={<Navigate to={{ pathname: PATHS.ACCOUNT_ROOT }} />} />
      <Route path="*" element={<Error404 />} />
    </Routes>
  );
};

export default MainRouter;
