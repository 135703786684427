import * as yup from 'yup';

export const scopeSchema = yup.object().shape({
  id: yup.string().required(),
  name: yup.string().required(),
});

export const roleSchema = yup.object().shape({
  id: yup.string().required(),
  name: yup.string().required(),
  scopes: yup.array().of(scopeSchema),
});

export const rolesSchema = yup.array().of(roleSchema).required();
