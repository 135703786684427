import React from 'react';

import {
  ArrowRightIcon,
  ButtonV2 as Button,
  Grid,
  Text,
  ModalV2 as Modal,
  Stack,
  styled,
} from '@livingpackets/design-system-react-next';
import { usePartnership } from 'features/account';
import { intersection, isEmpty } from 'lodash/fp';
import { IContact } from 'models/contactAddress';
import { useTranslation } from 'react-i18next';

import NameCard from '../../atoms/NameCard';

const ContactAddressWrapper = styled('div')`
  display: flex;
  justify-content: space-between;
  padding: 8px 12px;
  background-color: ${({ theme }) => theme.palette.custom.error.red[10]};
  border-radius: 12px;
  align-items: center;
`;

const ContactAddressEntry = ({
  contactAddress,
}: {
  contactAddress: IContact;
}) => (
  <ContactAddressWrapper>
    <Stack direction="row" alignItems="center" gap=".75rem">
      <NameCard
        firstName={contactAddress.firstName}
        lastName={contactAddress.lastName}
        email={contactAddress.email}
      />
      <Stack direction="row" alignItems="center" gap="1.5rem">
        <Text variant="titleXS">{contactAddress.firstName}</Text>
        <Text variant="titleXS">{contactAddress.lastName}</Text>
        <Text variant="titleXS">{contactAddress.email}</Text>
      </Stack>
    </Stack>
  </ContactAddressWrapper>
);

const DeleteContactAddressModal = ({
  open,
  onSubmit,
  onCancel,
  entry,
  loading,
}: {
  open: boolean;
  onSubmit: () => void;
  onCancel: () => void;
  entry: IContact;
  loading: boolean;
}) => {
  const { t } = useTranslation(['table', 'general', 'contactAddress']);

  const { data: partnership } = usePartnership();

  const entryAddressesIds = entry?.addresses.map(address => address.id);

  const hasDefaultAddress = !isEmpty(
    intersection(entryAddressesIds, [
      partnership?.default_sender_address_id,
      partnership?.default_recipient_address_id,
    ])
  );

  return (
    <Modal open={open} setOpen={onCancel} maxWidth="560px">
      <>
        <Text variant="titleM" mb="2.375rem">
          {t('contactAddress:modal.deleteContactAddress.title')}
        </Text>
        {entry && <ContactAddressEntry contactAddress={entry} />}
        <Text variant="bodyTextS" mt="1.1875rem">
          {t(
            `contactAddress:modal.deleteContactAddress.${
              hasDefaultAddress ? 'warningWithDefaultAddress' : 'warning'
            }`
          )}
        </Text>
        <Grid container justifyContent="space-between" mt="2rem">
          <Button variant="secondary" onClick={onCancel}>
            {t('contactAddress:modal.deleteContactAddress.button.dismiss')}
          </Button>
          <Button
            variant="primary"
            color="critical"
            icon={ArrowRightIcon}
            onClick={onSubmit}
            disabled={loading}
            sx={{ '& .MuiButton-icon': { color: 'black' } }}
            data-testid="delete-contact-button"
          >
            {t('contactAddress:modal.deleteContactAddress.button.submit')}
          </Button>
        </Grid>
      </>
    </Modal>
  );
};

export default DeleteContactAddressModal;
