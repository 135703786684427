import { useCountryCodes } from '@livingpackets/shared-components';
import { useTranslation } from 'react-i18next';

const LOCAL_ENDPOINT = process.env.PUBLIC_URL + '/countryCodeData.json';

const useCountryCodesWrapper = () => {
  const {
    i18n: { language },
  } = useTranslation();

  return useCountryCodes({
    includeTranslations: true,
    language: language,
    localCountryCodesPath: LOCAL_ENDPOINT,
  });
};

export default useCountryCodesWrapper;
