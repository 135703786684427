export interface ApiFilterModel {
  fieldName: string;
  fieldData: string;
  nextFieldSeparator?: ApiFilterOperatorEnum;
  filterType: ApiFilterTypeEnum;
}

export interface ApiFilterGroupModel {
  list: Array<ApiFilterModel>;
  nextGroupSeparator?: ApiFilterOperatorEnum;
}

export enum ApiFilterOperatorEnum {
  AND = 'AND',
  OR = 'OR',
}

export enum ApiFilterTypeEnum {
  EQUAL = '=',
  INCLUDE = '%>',
}
