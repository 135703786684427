import i18next from 'i18next';
import * as yup from 'yup';

import { phoneNumber } from './common';

export const finalizeAccountSchema = () =>
  yup.object().shape({
    firstName: yup
      .string()
      .required(
        i18next.t(
          'authentication:finalizeAccount.form.firstName.validation.required'
        )
      ),
    lastName: yup
      .string()
      .required(
        i18next.t(
          'authentication:finalizeAccount.form.lastName.validation.required'
        )
      ),
    phoneNumber: phoneNumber({ required: true }),
    isAgreedToTOS: yup.boolean().oneOf([true], 'Field must be checked'),
  });
