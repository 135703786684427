import React from 'react';

import { Text, styled } from '@livingpackets/design-system-react-next';
import Row from 'components/atoms/Row';

import useGetDashboardData from '../../../hooks/useGetDashboardData';
import { SocialMediaArticle } from '../../../models/socialMediaArticle';
const SocialMediaDiv = styled('div')`
  background-color: ${({ theme }) => theme.colors.white};
  display: flex;
  width: 100%;
  height: 5rem;
  border-radius: 14px;
  padding: 1rem;
  margin-bottom: 0.7rem;
`;

const SocialMediaIcon = styled('div', {
  shouldForwardProp: prop => prop !== 'url',
})<{ url: string }>`
  background-image: url(${({ url }) => url});
  background-size: contain;
  width: 70%;
  height: 70%;
  display: inline-block;
  background-repeat: no-repeat;
  align-self: center;
`;

const TitleLink = styled('a')`
  color: ${({ theme }) => theme.palette.custom.neutral.black[100]};
  text-decoration: none;
`;

interface SocialMediaArticleItem {
  article: SocialMediaArticle;
}
const SocialMediaCard = ({ article }: SocialMediaArticleItem) => {
  const { title, url, description, publicated_at_smArticle } =
    useGetDashboardData(article);

  return (
    <SocialMediaDiv>
      <Row width="10%">
        <SocialMediaIcon url={article.url_icon} />
      </Row>
      <Row justifyContent="center" flexDirection="column" width="70%">
        <Text variant="bodyTextM">
          <TitleLink target="_blank" href={url}>
            {title}
          </TitleLink>
        </Text>
        <Text
          variant="bodyTextS"
          style={{
            marginTop: '0.1rem',
            lineHeight: '1rem',
          }}
        >
          {description}
        </Text>
      </Row>
      <Row alignItems="center" width="20%" justifyContent="flex-end">
        <Text variant="bodyTextS" color="custom.neutral.black.50">
          {publicated_at_smArticle}
        </Text>
      </Row>
    </SocialMediaDiv>
  );
};

export default SocialMediaCard;
