import React, { useContext } from 'react';

import {
  Box,
  Divider,
  Stack,
  TabsV2 as Tabs,
} from '@livingpackets/design-system-react-next';
import { PARTNER_PREFIX_ROOT } from 'components/containers/PartnerRouter';
import { useTranslation } from 'react-i18next';
import { Link, Outlet, useParams } from 'react-router-dom';

import { PARTNER_ACCOUNT_ROOT } from '../../routes';
import { TabsContext } from '../../routes/Partnership';

export const PartnerTabs = () => {
  const { t } = useTranslation(['general', 'profileSettings', 'account']);
  const { tab } = useContext(TabsContext);

  const { partnerId } = useParams<{ partnerId: string }>();

  const base_url =
    PARTNER_PREFIX_ROOT +
    '/' +
    partnerId +
    PARTNER_ACCOUNT_ROOT +
    '/partnership';

  const tabs = [
    {
      to: base_url + '/information',
      label: t('account:partnership.tabs.information'),
      'data-testid': 'information-tab',
    },
    {
      to: base_url + '/offer',
      label: t('account:partnership.tabs.offer'),
      'data-testid': 'offer-tab',
    },
    {
      to: base_url + '/finance',
      label: t('account:partnership.tabs.finance'),
      'data-testid': 'finance-tab',
    },
    {
      to: base_url + '/supply_address',
      label: t('account:partnership.tabs.supplyAddress'),
      'data-testid': 'supply-address-tab',
    },
  ];

  return (
    <Box>
      <Stack mb=".75rem">
        <Tabs tabs={tabs} value={tab} component={Link} />
        <Divider orientation="horizontal" />
      </Stack>
      <Outlet />
    </Box>
  );
};
