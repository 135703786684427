import { UserBusinessTypeEnum } from 'enums/UserBusinessTypeEnum';
import { EMPTY_ROLE, IRole } from 'models/role';
import { PartnerTypeEnum } from 'stores/useMyPartnersStore';

export interface IUser {
  id: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  createdAt: number;
  updatedAt: number;
  emailVerified: boolean;
  preferredLanguage: PreferredLanguage;
  businessType: UserBusinessTypeEnum;
  onBoardingWebDone?: boolean;
}

export enum Language {
  en = 'english',
  fr = 'french',
  de = 'german',
}

export type PreferredLanguage = keyof typeof Language;

export interface IMember extends IUser {
  role: IRole;
  accountId: string;
}

export interface IPartnerAccount {
  account_id: string;
  created_at: number;
  created_by: string;
  email: string;
  first_name: string;
  last_name: string;
  partner_id: string;
  role: IRole;
  updated_at: number;
}

export interface IMemberPost {
  email: string;
  firstName: string;
  lastName: string;
  roleId: string;
}

export const INITIAL_STATE_MEMBER = {
  id: '',
  firstName: '',
  lastName: '',
  phoneNumber: '',
  email: '',
  createdAt: 0,
  updatedAt: 0,
  emailVerified: false,
  preferredLanguage: '' as PreferredLanguage,
  role: EMPTY_ROLE,
  accountId: '',
  businessType: UserBusinessTypeEnum.UNKNOWN,
};

export interface IPartnerAccountSearchForm {
  partnerAccountData: string;
}

export const INITIAL_STATE_PARTNER_ACCOUNT_SEARCH_FORM: IPartnerAccountSearchForm =
  {
    partnerAccountData: '',
  };

export const INITIAL_STATE_MEMBER_FORM: IMemberForm = {
  firstName: '',
  lastName: '',
  email: '',
  roleId: '',
  partnerType: PartnerTypeEnum.UNKNOWN,
};

export interface IMemberForm {
  firstName: string;
  lastName: string;
  email: string;
  roleId: string;
  partnerType: PartnerTypeEnum;
}
