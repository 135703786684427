import styled from 'styled-components';
import {
  color,
  ColorProps,
  flexbox,
  FlexboxProps,
  layout,
  LayoutProps,
  shadow,
  ShadowProps,
  space,
  SpaceProps,
} from 'styled-system';

const Card = styled.div<
  SpaceProps & ShadowProps & LayoutProps & ColorProps & FlexboxProps
>`
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 20px;
  padding: 2.5rem 0.5rem;
  width: 100%;
  overflow-y: auto;
  ${space}
  ${shadow}
  ${layout}
  ${color}
  ${flexbox}
`;

export default Card;
