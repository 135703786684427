import {
  ALLOWED_LANGS,
  REACT_APP_I18N_TRANSLATION_DEBUG_ACTIVATED,
  REACT_APP_I18N_LNG_LOCAL_STORAGE_KEY,
} from 'configs';
import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import CamelCase from 'lodash/camelCase';
import { initReactI18next } from 'react-i18next';

export const allNamespaces = [
  'address',
  'apps',
  'auth0',
  'authentication',
  'buyAndRent',
  'contactAddress',
  'contribution',
  'dashboard',
  'errorPages',
  'onboarding',
  'general',
  'messages',
  'navigation',
  'partnerApiKeys',
  'partnerManagement',
  'partnerSettings',
  'products',
  'profileSettings',
  'shipments',
  'shops',
  'showroom',
  'table',
  'tabs',
  'userManagement',
  'accountInformation',
  'remoteControl',
  'account',
  'forms',
];

function setupI18n() {
  i18next
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
      fallbackLng: 'en',
      debug: REACT_APP_I18N_TRANSLATION_DEBUG_ACTIVATED,
      supportedLngs: ALLOWED_LANGS,
      lowerCaseLng: true,
      detection: {
        lookupLocalStorage: REACT_APP_I18N_LNG_LOCAL_STORAGE_KEY,
        convertDetectedLanguage: lng => lng.substring(0, 2),
      },
      interpolation: {
        escapeValue: false,
        formatSeparator: ',',
      },
      returnNull: false,
      react: {
        useSuspense: true,
        transSupportBasicHtmlNodes: true,
        transKeepBasicHtmlNodesFor: ['br', 'strong', 'i'],
      },
      // Loads all namespaces at first loading to avoid some flickering effect due to <Suspense />
      // in src/components/containers/layout/AppLayout.tsx:22
      ns: allNamespaces,
      defaultNS: 'general',
    });

  // @ts-ignore
  i18next.services.formatter.add('camelcase', (value, lng, options) =>
    CamelCase(value)
  );
}

export default setupI18n;
