import { useEffect, useMemo, useRef } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Mode } from 'react-hook-form';
import { DefaultValues } from 'react-hook-form/dist/types/form';
import { useTranslation } from 'react-i18next';

interface IFormTemplate<T> {
  resolver?: any;
  defaultValues?: DefaultValues<T>;
  formValidationMode?: Mode;
}

const useFormTemplate = <T = any>({
  defaultValues,
  resolver,
  formValidationMode = 'onBlur',
}: IFormTemplate<T>) => {
  const { t, i18n } = useTranslation(['general', 'shipments']);

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    getValues,
    trigger,
    control,
    setError,
    clearErrors,
    formState: {
      touchedFields,
      errors,
      isDirty,
      isValid,
      isSubmitSuccessful,
      isSubmitted,
      isSubmitting,
    },
    reset,
    resetField,
  } = useForm({
    resolver: resolver && yupResolver(resolver(t)),
    mode: formValidationMode,
    reValidateMode: 'onChange',
    defaultValues: defaultValues,
  });

  const currentLang = useRef<string>(i18n.language);
  useEffect(() => {
    if (i18n.language !== currentLang.current) {
      Object.keys(errors).forEach(async fieldName => {
        await trigger(fieldName as any);
      });
      currentLang.current = i18n.language;
    }
  }, [errors, i18n.language, trigger]);

  const disableSubmit = useMemo(() => !isDirty || !isValid, [isDirty, isValid]);

  return {
    formState: {
      touchedFields,
      isDirty,
      isValid,
      isSubmitSuccessful,
      isSubmitted,
      isSubmitting,
    },
    errors,
    register,
    control,
    reset,
    resetField,
    handleSubmit,
    watch,
    setValue,
    getValues,
    trigger,
    setError,
    clearErrors,
    disableSubmit,
  };
};

export default useFormTemplate;
