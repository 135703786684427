import {
  IContribution,
  INITIAL_CONTRIBUTION_OVERVIEW,
  IcontributionOverview,
} from 'models/contribution';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

import { Co2Savings, INITIAL_CO2Savings } from '../models/co2Savings';

interface IContributionsStore {
  contributionOverview: IcontributionOverview;
  contributions: IContribution[];
  setContributions: (contributions: IContribution[]) => void;
  setContributionOverview: (
    contributionOverview: IcontributionOverview
  ) => void;
  order: 'ASC' | 'DESC';
  setOrder: (order: 'ASC' | 'DESC') => void;
  co2Savings: Co2Savings;
  setCO2Savings: (savings: Co2Savings) => void;
}

const useContributionsStore = create<IContributionsStore>()(
  devtools(
    set => ({
      contributionOverview: INITIAL_CONTRIBUTION_OVERVIEW,
      contributions: [],
      setContributions: contributions =>
        set(() => ({ contributions: contributions })),
      order: 'DESC',
      setOrder: order => set(() => ({ order: order })),
      setContributionOverview: contributionOverview => {
        set({ contributionOverview });
      },
      co2Savings: INITIAL_CO2Savings,
      setCO2Savings: savings => set(() => ({ co2Savings: savings })),
    }),
    {
      name: 'contributions',
    }
  )
);

export const contributionsSelector = (state: IContributionsStore) =>
  state.contributions;
export const setContributionsSelector = (state: IContributionsStore) =>
  state.setContributions;

export const orderSelector = (state: IContributionsStore) => state.order;
export const setOrderSelector = (state: IContributionsStore) => state.setOrder;
export const co2SavingsSelector = (state: IContributionsStore) =>
  state.co2Savings;
export const setCO2SavingsSelector = (state: IContributionsStore) =>
  state.setCO2Savings;

export const contributionOverviewSelector = (state: IContributionsStore) =>
  state.contributionOverview;

export const setContributionOverviewSelector = (state: IContributionsStore) =>
  state.setContributionOverview;

export default useContributionsStore;
