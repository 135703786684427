import { useCallback, useState } from 'react';
export function usePagination({ page = 1, pageSize = 100, orderDirection = 'DESC', orderColumn = 'updatedAt', } = {}) {
    const [params, setParams] = useState({
        page,
        pageSize,
        orderColumn,
        orderDirection,
    });
    const setPartialParams = useCallback((newParams) => setParams(prevParams => (Object.assign(Object.assign({}, prevParams), newParams))), [setParams]);
    return {
        params,
        setParams: setPartialParams,
    };
}
