import { useTranslation } from 'react-i18next';

const useActiveLanguage = () => {
  const {
    i18n: { language },
  } = useTranslation();

  return language;
};

export default useActiveLanguage;
