export interface IFinalizeAccountForm {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  isAgreedToTOS: boolean;
}

export const INITIAL_STATE_FINALIZE_ACCOUNT_FORM: IFinalizeAccountForm = {
  firstName: '',
  lastName: '',
  phoneNumber: '',
  isAgreedToTOS: false,
};
