import { useCallback } from 'react';

import {
  UseFetchResource,
  useFetchResource,
  UseFetchResourceOptions,
} from '@livingpackets/shared-components';
import { IShipment } from 'models/shipment';

import {
  GetPartnerShipmentsParams,
  PaginatedResponse,
  useGetPartnerShipments,
} from './useGetPartnerShipments';

export function usePartnerShipments(
  params: GetPartnerShipmentsParams,
  { enabled = true }: UseFetchResourceOptions = {}
): UseFetchResource<PaginatedResponse<IShipment>> {
  const getPartnerShipments = useGetPartnerShipments();

  const fetchFunction = useCallback(
    () => getPartnerShipments(params),
    [getPartnerShipments, params]
  );

  return useFetchResource(fetchFunction, { enabled });
}
