import React from 'react';

import { Stack, Text } from '@livingpackets/design-system-react-next';
import { useTranslation } from 'react-i18next';

export const AddressBookButtonTooltip = React.memo(() => {
  const { t } = useTranslation('shipments');

  return (
    <Stack gap="0.5rem">
      <Text variant="titleM" color="custom.neutral.black.100">
        {t('form.addressBookTooltip.title')}
      </Text>
      <Text variant="bodyTextS" color="custom.neutral.black.100">
        {t('form.addressBookTooltip.description')}
      </Text>
    </Stack>
  );
});

AddressBookButtonTooltip.displayName = 'AddressBookButtonTooltip';
