import React, { useCallback, useMemo, useState } from 'react';

import {
  ContactIcon,
  CloseIcon,
  Button,
  Text,
  Stack,
  NewTextField as TextField,
  PhoneNumber,
  ButtonV2,
  ArrowIcon,
  Select,
  Box,
  Grid,
} from '@livingpackets/design-system-react-next';
import SearchContactNameForm from 'components/molecules/Form/SearchContactNameForm';
import useFormTemplate from 'hooks/useFormTemplate';
import { TFunction } from 'i18next';
import { IContact } from 'models/contactAddress';
import { IShipment } from 'models/shipment';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSpring, animated } from 'react-spring';
import { phoneNumber } from 'schemas/common';
import useMyPartnersStore, {
  activePartnerSelector,
} from 'stores/useMyPartnersStore';
import { object, string } from 'yup';

import { useShareTrackingCode } from '../../api/shareTrackingCode';
import { IShareLinkForm, TrackingRoleType } from '../../types/trackingCode';

export const TrackingLinkForm = ({
  handleClose,
  shipmentId,
  shipment,
  displaySearchContact,
}: {
  handleClose: () => void;
  shipmentId: string;
  shipment: IShipment;
  displaySearchContact: any;
}) => {
  const { t } = useTranslation(['shipments', 'general']);
  const { mutate: sendTrackingCode } = useShareTrackingCode({
    shipmentId: shipmentId,
  });
  const [displaySearchContactFormBlock, setDisplaySearchContactFormBlock] =
    useState<boolean>(false);

  const [displaySearchContactForm, setDisplaySearchContactForm] =
    useState<boolean>(false);
  const activePartner = useMyPartnersStore(activePartnerSelector);

  const shareLinkSchema = (t: TFunction<'general'>) =>
    object().shape({
      role: string().required(t('fieldError')),
      firstName: string().required(t('fieldError')),
      lastName: string().required(t('fieldError')),
      email: string().email(t('emailFieldError')).required(t('fieldError')),
      ...(activePartner.smsEnabled
        ? {
            phoneNumber: string()
              .required(t('fieldError'))
              .concat(phoneNumber()),
          }
        : {}),
    });

  const LANG_OPTIONS = useMemo(
    () => [
      { value: 'EN', label: t('general:english') },
      { value: 'FR', label: t('general:french') },
      { value: 'DE', label: t('general:german') },
    ],
    [t]
  );

  const languageByDefault = (countryCode: string = 'EN') => {
    switch (countryCode) {
      case 'FR':
        return 'FR';
      case 'DE' || 'GE':
        return 'DE';
      default:
        return 'EN';
    }
  };

  const { handleSubmit, disableSubmit, control, setValue } =
    useFormTemplate<IShareLinkForm>({
      resolver: shareLinkSchema,
      formValidationMode: 'onTouched',
      defaultValues: {
        role: TrackingRoleType.additionalTracker,
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        lang: languageByDefault(shipment.recipient.address.country_code),
      },
    });

  const onSubmit = useCallback(
    (values: any) => {
      sendTrackingCode(values);
      handleClose();
    },
    [handleClose, sendTrackingCode]
  );

  const autofillContactForm = useCallback(
    (contact: IContact) => {
      const inputConfig = {
        shouldValidate: true,
        shouldDirty: true,
        shouldTouch: true,
      };

      setValue('firstName', contact.firstName, inputConfig);
      setValue('lastName', contact.lastName, inputConfig);
      setValue('email', contact.email, inputConfig);
      setValue('phoneNumber', contact.phoneNumber, inputConfig);

      setDisplaySearchContactForm(false);
    },
    [setValue]
  );

  const propsAddressBookIcon = useSpring({
    to: { opacity: 0 },
    from: { opacity: 1 },
    reverse: !displaySearchContactForm,
  });

  const propsSearchContactForm = useSpring({
    to: { opacity: 0, width: '100%', height: '0', marginBottom: '0' },
    from: {
      opacity: 1,
      width: '100%',
      height: '3.125rem',
      marginBottom: '.625rem',
    },
    reverse: displaySearchContactForm,
  });

  return (
    <Stack
      marginTop="1.625rem"
      marginBottom="2.5rem"
      marginLeft="1.5rem"
      marginRight="1.5rem"
      gap=".75rem"
    >
      <Stack
        justifyContent="space-between"
        marginBottom="2.875rem"
        flexDirection="row"
        width={!displaySearchContactForm ? '100%' : '95%'}
      >
        <Text variant="titleM">
          {shipment.opening_code
            ? t('shipments:shareTracking.shareCodeTitle')
            : t('shipments:shareTracking.shareLinkTitle')}
        </Text>
        {!displaySearchContactForm && (
          <animated.div style={propsAddressBookIcon}>
            <Button
              action="primary"
              isIconOnly={true}
              icon={<ContactIcon />}
              onClick={() => {
                setDisplaySearchContactFormBlock(true);
                setDisplaySearchContactForm(true);
                displaySearchContact(true);
              }}
              style={{
                cursor: displaySearchContactForm ? 'initial' : 'pointer',
              }}
            />
          </animated.div>
        )}
      </Stack>

      <Stack>
        {displaySearchContactFormBlock && (
          <animated.div style={propsSearchContactForm}>
            <Stack
              alignItems="center"
              position="relative"
              zIndex={2}
              paddingRight="3rem"
            >
              <Box width="100%">
                <SearchContactNameForm
                  onContactSelect={autofillContactForm}
                  isFormDisplayed={displaySearchContactForm}
                  onDismiss={() => {}}
                />
              </Box>
              <Box
                position="absolute"
                top="13px"
                right="5px"
                onClick={() => {
                  setDisplaySearchContactForm(false);
                  displaySearchContact(false);
                }}
                style={{ cursor: 'pointer' }}
              >
                <CloseIcon />
              </Box>
            </Stack>
          </animated.div>
        )}
      </Stack>
      <Grid container spacing={1}>
        <Grid item mobile={12} tablet={6}>
          {/* FIRST_NAME */}
          <Controller
            name="firstName"
            control={control}
            render={({ field, fieldState }) => (
              <TextField
                required
                label={t('shipments:shareTracking.placeholderFirstName')}
                error={fieldState.invalid}
                helperText={fieldState.error?.message}
                {...field}
              />
            )}
          />
        </Grid>
        <Grid item mobile={12} tablet={6}>
          {/* LAST_NAME */}
          <Controller
            name="lastName"
            control={control}
            render={({ field, fieldState }) => (
              <TextField
                required
                label={t('shipments:shareTracking.placeholderLastName')}
                error={fieldState.invalid}
                helperText={fieldState.error?.message}
                {...field}
              />
            )}
          />
        </Grid>
        <Grid item mobile={12} spacing={2} marginTop=".25rem">
          {/* EMAIL */}
          <Controller
            name="email"
            control={control}
            render={({ field, fieldState }) => (
              <TextField
                required
                label={t('shipments:shareTracking.placeholderEmail')}
                error={fieldState.invalid}
                helperText={fieldState.error?.message}
                {...field}
              />
            )}
          />
        </Grid>
        {activePartner.smsEnabled && (
          <Grid item mobile={12} marginTop=".25rem">
            {/* PHONE_NUMBER */}
            <Controller
              name="phoneNumber"
              control={control}
              render={({ field, fieldState }) => (
                <PhoneNumber
                  required
                  label={t('shipments:shareTracking.placeholderPhoneNumber')}
                  error={fieldState.invalid}
                  defaultCountryCode={
                    shipment.recipient.address.country_code === 'EN'
                      ? 'GB'
                      : shipment.recipient.address.country_code
                  }
                  helperText={fieldState.error?.message}
                  {...field}
                />
              )}
            />
          </Grid>
        )}
        <Grid item mobile={12} marginTop=".25rem">
          {/* LANG */}
          <Controller
            name="lang"
            control={control}
            render={({ field, fieldState }) => (
              <Select
                required
                label={t('shipments:shareTracking.placeholderLanguage')}
                options={LANG_OPTIONS}
                error={fieldState.invalid}
                helperText={fieldState.error?.message}
                {...field}
              />
            )}
          />
        </Grid>
      </Grid>
      <Grid container justifyContent="space-between" paddingTop="59px">
        <Grid item>
          <ButtonV2 variant="secondary" color="primary" onClick={handleClose}>
            {t('shipments:shareTracking.cancel')}
          </ButtonV2>
        </Grid>
        <Grid item>
          <ButtonV2
            icon={ArrowIcon}
            disabled={disableSubmit}
            onClick={handleSubmit(onSubmit)}
            variant="primary"
            color="primary"
            data-testid="open-share-tracking"
          >
            {shipment.opening_code
              ? t('shipments:shareTracking.ctaCode')
              : t('shipments:shareTracking.ctaLink')}
          </ButtonV2>
        </Grid>
      </Grid>
    </Stack>
  );
};
