import {
  PartnerBusinessStatusEnum,
  PartnerBusinessTypeEnum,
} from 'enums/accountTypeEnum';

export interface IPartner {
  archived: boolean;
  createdAt: number;
  createdBy: string;
  demoEnabled: boolean;
  description: string;
  id: string;
  internal: boolean;
  maxNbOfShipments?: number;
  nbOfShipments: number;
  name: string;
  phoneNumber: string;
  servicePool: string;
  services: IService[];
  thumbnail: string;
  type: string;
  updatedAt: number;
  website: string;
  secursusEnabled: boolean;
  businessInformation: {
    status: PartnerBusinessStatusEnum;
    type: PartnerBusinessTypeEnum;
  };
  fwUpdateEnabled: boolean;
  remoteControlEnabled: boolean;
  smsEnabled: boolean;
  carriers: string[];
}

export interface IService {
  id: string;
  name: string;
  type: string;
  createdBy: string;
  createdAt: number;
  updatedAt: number;
}

export const INITIAL_STATE_PARTNER = {
  archived: false,
  createdAt: 0,
  createdBy: '',
  demoEnabled: true,
  description: '',
  id: '',
  internal: true,
  nbOfShipments: 0,
  name: '',
  phoneNumber: '',
  servicePool: '',
  services: [],
  thumbnail: '',
  type: '',
  updatedAt: 0,
  website: '',
  secursusEnabled: false,
  businessInformation: {
    status: PartnerBusinessStatusEnum.UNSPECIFIED,
    type: PartnerBusinessTypeEnum.UNSPECIFIED,
  },
  fwUpdateEnabled: false,
  remoteControlEnabled: false,
  smsEnabled: false,
  carriers: [],
};
