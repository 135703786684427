import {
  useMediaQuery,
  useTheme,
} from '@livingpackets/design-system-react-next';

/**
 * @returns {Object} Information about the responsive layout
 */
const useIsMobile = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('laptop'));
  const isLaptop = !isMobile;

  return { isMobile, isLaptop };
};

export default useIsMobile;
