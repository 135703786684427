import {
  lockStatus,
  lockStatusKeys,
  lockStatusValues,
} from '../types/lockStatus';

export const getLockStatusKeyByValue = (
  value: lockStatusValues
): lockStatusKeys | null => {
  const keys = Object.keys(lockStatus) as lockStatusKeys[];
  for (const key of keys) {
    if (lockStatus[key] === value) {
      return key;
    }
  }

  return null;
};
