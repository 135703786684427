import React from 'react';

import { Button } from '@livingpackets/design-system-react';
import { ImportShipmentStepEnum } from 'enums/ImportShipmentEnum';
import { useTranslation } from 'react-i18next';

interface ImportShipmentWaitingForFileActionType {
  onCarrierChange: () => void;
  handleSkipReturnLabel?: () => void;
  currentImportShipmentStep: ImportShipmentStepEnum;
  showChangeCarrier: boolean;
}

const ImportShipmentWaitingForFileAction = ({
  onCarrierChange,
  handleSkipReturnLabel,
  currentImportShipmentStep,
  showChangeCarrier,
}: ImportShipmentWaitingForFileActionType) => {
  const { t } = useTranslation(['shipments', 'general']);

  return (
    <>
      {showChangeCarrier && (
        <Button
          action="secondary"
          onClick={onCarrierChange}
          data-testid="shipmentLabel-changeCarrier"
        >
          {t('shipments:changeCarrier')}
        </Button>
      )}
      {currentImportShipmentStep ===
        ImportShipmentStepEnum.ReturnShipmentCreationStep && (
        <Button action="primary" onClick={handleSkipReturnLabel}>
          {t('general:skip')}
        </Button>
      )}
    </>
  );
};

export default ImportShipmentWaitingForFileAction;
