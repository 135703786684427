import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Button, CardHeader } from '@livingpackets/design-system-react';
import { Text, styled } from '@livingpackets/design-system-react-next';
import Col from 'components/atoms/Col';
import Dropdown from 'components/atoms/Dropdown';
import Input from 'components/atoms/Input';
import LastUpdatedAt from 'components/atoms/LastUpdatedAt';
import Row from 'components/atoms/Row';
import Spacer from 'components/atoms/Spacer';
import AddressForm from 'components/molecules/AddressForm';
import { UserBusinessTypeEnum } from 'enums/UserBusinessTypeEnum';
import useFormTemplate from 'hooks/useFormTemplate';
import useProfileSettings from 'hooks/useProfileSettings';
import get from 'lodash/get';
import pick from 'lodash/pick';
import { ILPAccountAddress } from 'models/address';
import { Language, PreferredLanguage } from 'models/user';
import { FieldValues, SubmitHandler } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { partnerProfileSettingsSchema } from 'schemas/partnerProfileSettingsSchema';
import { profileSettingsSchema } from 'schemas/profileSettingsSchema';
import useAppState, { userSelector } from 'stores/appState/useAppState';

import Card from '../atoms/Card';

export interface FormInputs {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  company?: string;
  street?: string;
  houseNumber?: string;
  city?: string;
  postalCode?: string;
  countryCode?: string;
  preferredLanguage: PreferredLanguage;
}

const AddressFormWrapper = styled('div')`
  margin-top: 0.1rem;
`;

const ButtonWrapper = styled(Row)`
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  width: 100%;
`;

const ProfileSettingsForm = ({
  initialState,
}: {
  initialState: ILPAccountAddress;
}) => {
  const { t } = useTranslation(['address', 'profileSettings', 'general']);
  const appUser = useAppState(userSelector);

  const { loading, onSubmit } = useProfileSettings();

  const preferredLanguageValues = useMemo(
    () =>
      Object.keys(Language).map(k => ({
        value: k,
        label: t(`general:${Language[k as PreferredLanguage]}`),
      })),
    [t]
  );

  const {
    errors,
    register,
    handleSubmit,
    formState: { touchedFields },
    reset,
    control,
    disableSubmit,
    watch,
    setValue,
    trigger,
  } = useFormTemplate<FormInputs>({
    resolver:
      appUser?.businessType === UserBusinessTypeEnum.PERSONAL
        ? profileSettingsSchema
        : partnerProfileSettingsSchema,
  });
  const [isBusinessProfil, setIsBusinessProfil] = useState<boolean>(false);
  const [lastUpdatedAt, setLastUpdatedAt] = useState<any>(null);
  const preferredLanguageValue = watch('preferredLanguage');

  const resetValues = useCallback(() => {
    const adresseInfo = !isBusinessProfil
      ? pick(initialState, [
          'company',
          'city',
          'street',
          'houseNumber',
          'postalCode',
          'countryCode',
        ])
      : {};

    reset({
      ...adresseInfo,
      firstName: appUser?.firstName || '',
      lastName: appUser?.lastName || '',
      phoneNumber: appUser?.phoneNumber || '',
      preferredLanguage: appUser?.preferredLanguage || undefined,
    });
  }, [
    appUser?.firstName,
    appUser?.lastName,
    appUser?.phoneNumber,
    appUser?.preferredLanguage,
    initialState,
    isBusinessProfil,
    reset,
  ]);

  const checkProfil = useCallback(() => {
    const isBusiness = appUser?.businessType !== UserBusinessTypeEnum.PERSONAL;
    setIsBusinessProfil(isBusiness);

    const updatedAt = isBusiness
      ? appUser?.updatedAt || 0
      : Math.max(appUser?.updatedAt || 0, initialState?.updatedAt || 0);

    setLastUpdatedAt(updatedAt);
  }, [appUser?.businessType, appUser?.updatedAt, initialState?.updatedAt]);

  useEffect(() => {
    checkProfil();
    resetValues();
  }, [checkProfil, resetValues]);

  useEffect(() => {
    register('preferredLanguage');
  }, [register]);

  const onCancel = useCallback(() => resetValues(), [resetValues]);

  const handleLanguageChange = async (option: {
    label: string;
    value: PreferredLanguage;
  }) => {
    setValue('preferredLanguage', option.value, { shouldDirty: true });
    await trigger('preferredLanguage');
  };

  const onSubmitForm: SubmitHandler<FieldValues> = (data: FieldValues) => {
    onSubmit({
      firstName: data.firstName,
      lastName: data.lastName,
      phoneNumber: data.phoneNumber,
      company: data.company,
      street: data.street,
      houseNumber: data.houseNumber,
      city: data.city,
      postalCode: data.postalCode,
      countryCode: data.countryCode,
      preferredLanguage: data.preferredLanguage,
    });
  };

  return (
    <Card padding="32px">
      <CardHeader
        paddingBottom="48px"
        display="flex"
        justifyContent="space-between"
      >
        <Text variant="titleM">{t('profileSettings:basicInfo')}</Text>
        {lastUpdatedAt && (
          <LastUpdatedAt m="0 0 0.188rem 0.9rem" timestamp={lastUpdatedAt} />
        )}
      </CardHeader>

      <Row>
        <Col pr="1rem">
          <Input
            name="firstName"
            label={t('address:firstName.label')}
            register={register}
            error={get(errors, 'firstName', false)}
            isTouched={get(touchedFields, 'firstName', false)}
          />
        </Col>
        <Col>
          <Input
            name="lastName"
            label={t('address:lastName.label')}
            register={register}
            error={get(errors, 'lastName', false)}
            isTouched={get(touchedFields, 'lastName', false)}
          />
        </Col>
      </Row>
      <Row mt="0.3rem">
        <Col pr="1rem">
          <Input
            name="phoneNumber"
            label={t('profileSettings:phoneNumber')}
            register={register}
            error={get(errors, 'phoneNumber', false)}
            isTouched={get(touchedFields, 'phoneNumber', false)}
          />
        </Col>
        <Col>
          <Input
            name="email"
            label={t('profileSettings:email')}
            value={appUser?.email || ''}
            disabled={true}
            register={register}
          />
        </Col>
      </Row>
      <Row>
        <Col pr="1rem">
          <Dropdown
            name="preferredLanguage"
            label={t('profileSettings:preferredLanguage')}
            value={
              preferredLanguageValues.find(
                l => l.value === preferredLanguageValue
              ) || null
            }
            options={preferredLanguageValues}
            onChange={handleLanguageChange}
          />
        </Col>
      </Row>
      {!isBusinessProfil && (
        <>
          <Spacer y="1rem" />
          <Text variant="bodyTextS" color="custom.neutral.black.50">
            {t('address')}
          </Text>
          <AddressFormWrapper>
            <AddressForm
              errors={errors}
              touched={touchedFields}
              register={register}
              rowPadding="0"
              control={control}
            />
          </AddressFormWrapper>
        </>
      )}
      <ButtonWrapper paddingTop={isBusinessProfil ? '202px' : '90px'}>
        <Button
          action="secondary"
          onClick={onCancel}
          disabled={disableSubmit || loading}
        >
          {t('general:dismiss')}
        </Button>
        <Button
          action="primary"
          disabled={disableSubmit}
          onClick={handleSubmit(onSubmitForm)}
          isLoading={loading}
        >
          {t('general:save')}
        </Button>
      </ButtonWrapper>
    </Card>
  );
};

export default ProfileSettingsForm;
