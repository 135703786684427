import { IActivePartnerStore } from 'stores/useMyPartnersStore';

export const updatePartnerNameHelper: (input: {
  partnerId: string;
  name: string;
  state: IActivePartnerStore;
}) => IActivePartnerStore = ({ partnerId, name, state }) => {
  const partnersCopy = state.partners.slice();
  const partner = state.partners.find(x => x.id === partnerId);

  if (!partner) {
    //TODO: improve error handling
    console.error('Error: That partner doesnt exist on the state!');

    return state;
  }
  const partnerIndex = partnersCopy.indexOf(partner);
  const newEntry = {
    ...partner,
    name: name,
  };
  partnersCopy[partnerIndex] = newEntry;
  if (state.activePartner?.id === partnerId) {
    return { ...state, partners: partnersCopy, activePartner: newEntry };
  }

  return { ...state, partners: partnersCopy };
};
