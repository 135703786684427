import React, { useCallback, useEffect, useState } from 'react';

import {
  Button,
  IBANForm,
  SWIFTForm,
} from '@livingpackets/design-system-react';
import { IIbanForm } from '@livingpackets/design-system-react/src/components/IBANForm/IBANForm';
import { ISwiftForm } from '@livingpackets/design-system-react/src/components/SWIFTForm/SWIFTForm';
import {
  BankIcon,
  TooltipIcon,
  Box,
  Text,
  Stack,
} from '@livingpackets/design-system-react-next';
import BankErrorErrorToFix from 'components/atoms/BankErrorToFix';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

import { TransactionMethodTypeEnum } from '../../enums/transactionMethodTypeEnum';
import useMeWallet from '../../hooks/useMeWallet';
import useToastMessages from '../../hooks/useToastMessages';
import { TransactionMethod } from '../../models/Wallet';
import BankAccountDetail from '../molecules/accountInformation/BankAccountDetail';
import ChangeBankAccountModal from '../molecules/modals/ChangeBankAccountModal';
import TooltipBankAccountInformation from '../molecules/tooltip/TooltipBankAccountInformation';

enum BankAccountTypeEnum {
  iban = 'iban',
  swift = 'swift',
}

export interface ProfileInformationBankAccountPros {
  triggerBillingAddressCheck: () => void;
}

const BankAccountIbanInitialState: IIbanForm = {
  bankName: '',
  beneficiaryName: '',
  bicCode: '',
  ibanCode: '',
};

const BankAccountSwiftInitialState: ISwiftForm = {
  bankName: '',
  beneficiaryName: '',
  swiftCode: '',
  bankAccountNumber: '',
};

const ProfileInformationBankAccount = ({
  triggerBillingAddressCheck,
}: ProfileInformationBankAccountPros) => {
  const { t } = useTranslation(['accountInformation', 'messages']);
  const { error: toastError, success: toastSuccess } = useToastMessages();
  const theme = useTheme();

  const isSwiftFormAvailable = false;

  const [currentBankAccount, setCurrentBankAccount] =
    useState<TransactionMethod>();

  const [isGetBankAccountDone, setIsGetBankAccountDone] =
    useState<boolean>(false);

  const [displayBankAccountForm, setDisplayBankAccountForm] =
    useState<boolean>(false);

  const [displayChangeBankAccountModal, setDisplayChangeBankAccountModal] =
    useState<boolean>(false);

  const [currentBankAccountTypeSelected] = useState<BankAccountTypeEnum>(
    BankAccountTypeEnum.iban
  );

  const [bankAccountIbanForm, setBankAccountIbanForm] = useState<IIbanForm>(
    BankAccountIbanInitialState
  );

  const [bankAccountSwiftForm, setBankAccountSwiftForm] = useState<ISwiftForm>(
    BankAccountSwiftInitialState
  );

  const {
    getWalletList,
    createWallet,
    deleteWallet,
    getWireFromBankAccountIbanForm,
    getWireFromBankAccountSwiftForm,
  } = useMeWallet();

  useEffect(() => {
    getWalletList().then(({ success, data }) => {
      if (data) {
        const transactionMethod = data.wallet.transaction_methods.find(
          (transactionMethod: TransactionMethod) =>
            transactionMethod.type ===
            TransactionMethodTypeEnum.TRANSACTION_METHOD_TYPE_WIRE
        );

        if (transactionMethod) {
          setCurrentBankAccount(transactionMethod);
        }
      }

      setIsGetBankAccountDone(true);
    });
  }, [getWalletList]);

  const isIbanFormValid = useCallback(
    (): boolean =>
      bankAccountIbanForm.bankNameValid === true &&
      bankAccountIbanForm.beneficiaryNameValid === true &&
      bankAccountIbanForm.ibanValid === true &&
      bankAccountIbanForm.bicValid === true,
    [bankAccountIbanForm]
  );

  const isSwiftFormValid = useCallback(
    (): boolean =>
      bankAccountSwiftForm.bankNameValid === true &&
      bankAccountSwiftForm.beneficiaryNameValid === true &&
      //bankAccountSwiftForm.swiftCodeValid === true &&
      bankAccountSwiftForm.bankAccountNumberValid === true,
    [bankAccountSwiftForm]
  );

  const resetBankAccountIbanForm = useCallback(() => {
    setBankAccountIbanForm(BankAccountIbanInitialState);
  }, []);

  const resetBankAccountSwiftForm = useCallback(() => {
    setBankAccountSwiftForm(BankAccountSwiftInitialState);
  }, []);

  const handleFormSubmit = useCallback(() => {
    let wire;

    if (currentBankAccountTypeSelected === BankAccountTypeEnum.iban) {
      if (!isIbanFormValid() || !bankAccountIbanForm) {
        return;
      }

      wire = getWireFromBankAccountIbanForm(bankAccountIbanForm);
    }

    if (currentBankAccountTypeSelected === BankAccountTypeEnum.swift) {
      if (!isSwiftFormValid() || !bankAccountSwiftForm) {
        return;
      }

      wire = getWireFromBankAccountSwiftForm(bankAccountSwiftForm);
    }

    createWallet(wire).then(response => {
      if (response.success) {
        toastSuccess('messages:createBankAccountSuccess.message', {
          wide: true,
        });

        setDisplayBankAccountForm(false);
        setCurrentBankAccount(response.data.transaction_method);
        triggerBillingAddressCheck();

        if (currentBankAccountTypeSelected === BankAccountTypeEnum.iban) {
          resetBankAccountIbanForm();
        }

        if (currentBankAccountTypeSelected === BankAccountTypeEnum.swift) {
          resetBankAccountSwiftForm();
        }
      } else {
        toastError('messages:createBankAccountError.message', {
          wide: true,
        });
      }
    });
  }, [
    currentBankAccountTypeSelected,
    createWallet,
    isIbanFormValid,
    bankAccountIbanForm,
    getWireFromBankAccountIbanForm,
    isSwiftFormValid,
    bankAccountSwiftForm,
    getWireFromBankAccountSwiftForm,
    toastSuccess,
    triggerBillingAddressCheck,
    resetBankAccountIbanForm,
    resetBankAccountSwiftForm,
    toastError,
  ]);

  const handleDeleteBankAccount = useCallback(() => {
    if (!currentBankAccount) {
      return;
    }

    deleteWallet(currentBankAccount.id).then(response => {
      if (response.success) {
        toastSuccess('messages:deleteBankAccountSuccess.message', {
          wide: true,
        });

        setCurrentBankAccount(undefined);
      } else {
        toastError('messages:deleteBankAccountError.message', {
          wide: true,
        });
      }
    });
  }, [currentBankAccount, deleteWallet, toastSuccess, toastError]);

  return (
    <Stack>
      <Stack direction="row">
        <Stack width="100%">
          <Stack direction="row" alignItems="center">
            <BankIcon />
            <Text variant="titleL" ml=".75rem">
              {t('accountInformation:emptyState.bankAccount.title')}
            </Text>
            {(displayBankAccountForm || currentBankAccount) && (
              <>
                <Box
                  data-tooltip-id="bank-account-information-tooltip"
                  style={{
                    cursor: 'pointer',
                  }}
                  marginLeft="12px !important"
                  height="13px"
                >
                  <TooltipIcon color={theme.colors.black[50]} size="13px" />
                </Box>
                <TooltipBankAccountInformation />
              </>
            )}
          </Stack>

          {(currentBankAccount?.state as any) ===
            'TRANSACTION_METHOD_STATE_NOT_VALID' && (
            <Box paddingTop="2rem" paddingBottom="0.5rem">
              <BankErrorErrorToFix />
            </Box>
          )}
        </Stack>
      </Stack>
      {isGetBankAccountDone && (
        <>
          {currentBankAccount ? (
            <Stack>
              <Box marginTop="32px !important" marginBottom="32px">
                <BankAccountDetail transactionMethod={currentBankAccount} />
              </Box>
              <Box display="flex" justifyContent="flex-end">
                <Button
                  action="tertiary"
                  onClick={() => setDisplayChangeBankAccountModal(true)}
                  data-testid="accountInformation-button-change-bank-account"
                >
                  {t(
                    'accountInformation:bankAccountDetail.button.changeBankAccount'
                  )}
                </Button>
              </Box>
            </Stack>
          ) : (
            <>
              <Text variant="bodyTextS" my="1.125rem">
                {t('accountInformation:emptyState.bankAccount.description')}
              </Text>
              <Box>
                {displayBankAccountForm ? (
                  <>
                    {currentBankAccountTypeSelected ===
                      BankAccountTypeEnum.iban && (
                      <Stack>
                        <IBANForm
                          id="bank-account-iban-form"
                          ibanData={bankAccountIbanForm}
                          setIbanData={setBankAccountIbanForm}
                          ibanFormPlaceholder={{
                            bankNameLabel: t(
                              'accountInformation:form.bankAccount.fields.iban.bankName.placeholder'
                            ),
                            beneficiaryLabel: t(
                              'accountInformation:form.bankAccount.fields.iban.beneficiaryName.placeholder'
                            ),
                            ibanCodeLabel: t(
                              'accountInformation:form.bankAccount.fields.iban.ibanCode.placeholder'
                            ),
                            bicCodeLabel: t(
                              'accountInformation:form.bankAccount.fields.iban.bicCode.placeholder'
                            ),
                          }}
                        >
                          <></>
                        </IBANForm>
                        <Stack
                          direction="row"
                          marginTop="32px !important"
                          justifyContent="space-between"
                        >
                          <Button
                            action="secondary"
                            onClick={() => {
                              setDisplayBankAccountForm(false);
                              resetBankAccountIbanForm();
                            }}
                            data-testid="bank-account-iban-form-cancel-button"
                            style={{ minWidth: '180px' }}
                          >
                            {t(
                              'accountInformation:form.bankAccount.buttons.iban.cancel'
                            )}
                          </Button>
                          <Button
                            action="primary"
                            isDisabled={!isIbanFormValid()}
                            data-testid="bank-account-iban-form-save-button"
                            onClick={() => handleFormSubmit()}
                            style={{ minWidth: '180px' }}
                          >
                            {t(
                              'accountInformation:form.bankAccount.buttons.iban.save'
                            )}
                          </Button>
                        </Stack>
                      </Stack>
                    )}
                    {currentBankAccountTypeSelected ===
                      BankAccountTypeEnum.swift &&
                      isSwiftFormAvailable && (
                        <Stack>
                          <SWIFTForm
                            id="bank-account-swift-form"
                            swiftData={bankAccountSwiftForm}
                            setSwiftData={setBankAccountSwiftForm}
                            swiftPlaceholders={{
                              bankNameLabel: t(
                                'accountInformation:form.bankAccount.fields.swift.bankName.placeholder'
                              ),
                              beneficiaryLabel: t(
                                'accountInformation:form.bankAccount.fields.swift.beneficiary.placeholder'
                              ),
                              swiftCodeLabel: t(
                                'accountInformation:form.bankAccount.fields.swift.swiftCode.placeholder'
                              ),
                              bankAccountNumberLabel: t(
                                'accountInformation:form.bankAccount.fields.swift.bankAccountNumber.placeholder'
                              ),
                            }}
                          >
                            <></>
                          </SWIFTForm>
                          <Stack
                            direction="row"
                            marginTop="32px !important"
                            justifyContent="space-between"
                          >
                            <Button
                              action="secondary"
                              onClick={() => {
                                setDisplayBankAccountForm(false);
                                resetBankAccountSwiftForm();
                              }}
                              data-testid="bank-account-swift-form-cancel-button"
                              style={{ minWidth: '180px' }}
                            >
                              {t(
                                'accountInformation:form.bankAccount.buttons.swift.cancel'
                              )}
                            </Button>
                            <Button
                              action="primary"
                              isDisabled={!isSwiftFormValid()}
                              data-testid="billing-address-form-save-button"
                              onClick={() => handleFormSubmit()}
                              style={{ minWidth: '180px' }}
                            >
                              {t(
                                'accountInformation:form.bankAccount.buttons.swift.save'
                              )}
                            </Button>
                          </Stack>
                        </Stack>
                      )}
                  </>
                ) : (
                  <Box display="flex" justifyContent="flex-end">
                    <Button
                      action="tertiary"
                      onClick={() => setDisplayBankAccountForm(true)}
                      disabled={!isGetBankAccountDone}
                      data-testid="accountInformation-button-add-bank-account"
                    >
                      {t(
                        'accountInformation:emptyState.bankAccount.button.addBankAccount'
                      )}
                    </Button>
                  </Box>
                )}
              </Box>
            </>
          )}
        </>
      )}
      <ChangeBankAccountModal
        open={displayChangeBankAccountModal}
        onCancelClick={() => setDisplayChangeBankAccountModal(false)}
        onChangeClick={() => {
          setDisplayChangeBankAccountModal(false);
          handleDeleteBankAccount();
        }}
      />
    </Stack>
  );
};
export default ProfileInformationBankAccount;
