import React from 'react';

import { ChevronRightIcon } from '@livingpackets/design-system-react-next';
import Row from 'components/atoms/Row';
import Tooltip from 'components/atoms/Tooltip';
import styled, { useTheme } from 'styled-components';

interface ISmallTabNavigation {
  options: {
    key: any;
    label: string;
    isDisabled?: boolean;
    disabledTooltip?: string;
  }[];
  activeKey?: any;
  setActiveKey: (key: any) => void;
  withSeparator?: boolean;
}

const StyledSpan = styled.span<{ isActive: boolean; isDisabled?: boolean }>`
  ${({ theme }) => theme.fontStyles.titleXS}
  text-align: center;
  cursor: pointer;
  color: ${({ theme, isActive, isDisabled }) => {
    if (isActive) {
      return theme.colors.primary[100];
    }

    return isDisabled ? theme.colors.black[20] : theme.colors.black[50];
  }};
  margin-right: 21px;
  padding-bottom: 12px;
  pointer-events: ${({ isDisabled }) => (isDisabled ? 'none' : 'auto')};
  position: relative;

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    display: block;
    border-radius: 20px;
    height: 2px;
    width: 100%;
    background-color: ${({ theme, isActive }) =>
      isActive ? theme.colors.primary[100] : theme.colors.transparent};
    transition: all 200ms ease-in-out;
  }
`;

const WrapperSeparator = styled.div`
  display: inline-flex;
  align-items: flex-end;
  vertical-align: middle;
  margin-right: 22px;
`;

const SmallTabNavigation = ({
  activeKey,
  setActiveKey,
  options,
  withSeparator = false,
}: ISmallTabNavigation) => {
  const theme = useTheme();

  return (
    <div>
      {options.map((option, i) =>
        option.isDisabled && option.disabledTooltip ? (
          <Tooltip
            content={option.disabledTooltip}
            position="top"
            key={option.key}
          >
            <Row display="inline-flex">
              <StyledSpan isDisabled={true} isActive={false}>
                {option.label}
              </StyledSpan>
            </Row>
          </Tooltip>
        ) : (
          <span key={option.key} data-testid={`shipment-tab-${i}`}>
            <StyledSpan
              onClick={() => setActiveKey(option.key)}
              isActive={option.key === activeKey}
              isDisabled={option.isDisabled}
              data-testid={
                option.isDisabled
                  ? `shipment-tab-${i}-disabled`
                  : `shipment-tab-${i}-enabled`
              }
            >
              {option.label}
            </StyledSpan>
            {withSeparator && i < options.length - 1 && (
              <WrapperSeparator>
                <ChevronRightIcon size="20px" color={theme.colors.black[50]} />
              </WrapperSeparator>
            )}
          </span>
        )
      )}
    </div>
  );
};

export default SmallTabNavigation;
