import { useCallback } from 'react';

import useImperativeAnonymousRequest from 'hooks/useImperativeAnonymousRequest';
import { IShipment } from 'models/shipment';

export function useShipmentAddByTrackingCode() {
  const [, makeRequest] = useImperativeAnonymousRequest('shipment');
  /*  const [, makeRequest] = useImperativeAnonymousRequest('shipmentV3'); */

  return useCallback(
    async (trackingCode: string) => {
      const { error, data } = await makeRequest({
        path: 'api/v2/shipments/add/by-tracking-code',
        method: 'POST',
        body: { trackingCode: trackingCode },
      });
      /*  TODO : MIGRATE 
     const { error, data } = await makeRequest({
        path: '/me/shipments/add-by-tracking-code',
        method: 'POST',
        body: { tracking_code: trackingCode },
      }); */
      if (error) {
        return { success: false, shipment: null };
      }

      return { success: true, shipment: data.shipment as IShipment };
    },
    [makeRequest]
  );
}
