import React from 'react';

import { IContactAddress } from 'models/contactAddress';
import styled from 'styled-components';

import useCountryCodesWrapper from '../../../hooks/useCountryCodeWrapper';

const ContactCompanyName = styled.p`
  color: ${({ theme }) => theme.colors.black[90]};
  ${({ theme }) => theme.fontStyles.titleXS}
  margin-bottom: 5px;
`;

const ContactAddressInfoText = styled(ContactCompanyName)`
  color: ${({ theme }) => theme.colors.black[90]};
  ${({ theme }) => theme.fontStyles.bodyTextS}
  margin-bottom: 0;
  white-space: nowrap;
`;

interface IContactAddressInfo {
  contactAddress: IContactAddress;
}

const ContactAddressInfo = ({ contactAddress }: IContactAddressInfo) => {
  const [, getCountryNameFromCode] = useCountryCodesWrapper();

  function getAddressFirstLine() {
    return contactAddress.houseNumber + ' ' + contactAddress.street + ',';
  }

  function getAddressSecondLine() {
    return (
      contactAddress.postalCode +
      ' ' +
      contactAddress.city +
      ', ' +
      getCountryNameFromCode(contactAddress.countryCode)
    );
  }

  return (
    <>
      {ContactCompanyName && (
        <ContactCompanyName>{contactAddress.company}</ContactCompanyName>
      )}
      <ContactAddressInfoText>{getAddressFirstLine()}</ContactAddressInfoText>
      <ContactAddressInfoText>{getAddressSecondLine()}</ContactAddressInfoText>
    </>
  );
};

export default ContactAddressInfo;
