import React, { useCallback } from 'react';

import { Button } from '@livingpackets/design-system-react';
import {
  Text,
  styled,
  useTheme,
} from '@livingpackets/design-system-react-next';
import Card from 'components/atoms/Card';
import NameCard from 'components/atoms/NameCard';
import Row from 'components/atoms/Row';
import { IBaseAddress } from 'models/address';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import useAppState, { userSelector } from 'stores/appState/useAppState';

import { Address } from './Address';

interface IAccountInfoCard {
  address: IBaseAddress;
  subtitle: string;
  website: string;
  phoneNumber: string;
  editLink: string;
}

const Wrapper = styled(Card)`
  padding: 1.5rem;
  width: 100%;
`;

const InfomationWrapper = styled(Row)`
  gap: 1.729rem;
`;

const AddressWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  font-size: 0.75rem;
  justify-content: center;
`;

const HomeInfoCard = ({
  address,
  subtitle,
  website,
  phoneNumber,
  editLink,
}: IAccountInfoCard) => {
  const navigate = useNavigate();
  const { t } = useTranslation('general');
  const theme = useTheme();

  const appUser = useAppState(userSelector);

  const redirectToSettingsAccount = useCallback(() => {
    navigate(editLink);
  }, [navigate, editLink]);

  return (
    <Wrapper>
      <Row justifyContent="space-between">
        <InfomationWrapper data-testid="partnerHome-homeInfoCard-nameCard">
          <Row
            paddingRight="1.727rem"
            style={{
              borderRight: `0.0625rem solid ${theme.palette.custom.neutral.black[20]}`,
            }}
          >
            <NameCard
              firstName={appUser?.firstName || ''}
              lastName={appUser?.lastName || ''}
              email={appUser?.email || ''}
              width="2.5rem"
              height="2.5rem"
              mr="1rem"
            />
            <Row justifyContent="center" flexDirection="column">
              <Text variant="titleS" pb="0.5rem">
                {appUser?.firstName}&nbsp;{appUser?.lastName}
              </Text>
              <Text
                variant="bodyTextS"
                color="custom.neutral.black.50"
                sx={{ lineHeight: '0.875rem' }}
              >
                {subtitle}
              </Text>
            </Row>
          </Row>

          {address && address.street && (
            <AddressWrapper>
              <Address address={address} countryInline />
            </AddressWrapper>
          )}
          <Row
            flexDirection="column"
            justifyContent="center"
            style={{ gap: '0.5rem' }}
          >
            <Text variant="bodyTextS">{website} </Text>
            <Text variant="bodyTextS">{phoneNumber}</Text>
          </Row>
        </InfomationWrapper>
        <Button
          action="tertiary"
          onClick={() => redirectToSettingsAccount()}
          data-testid="partnerHome-homeInfoCard-button"
        >
          {t('edit')}
        </Button>
      </Row>
    </Wrapper>
  );
};

export default HomeInfoCard;
