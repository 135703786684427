import { useState } from 'react';

import { LP_ACCOUNT_ROUTES } from 'components/containers/LPAccountRouter';
import { PARTNER_ROUTES } from 'components/containers/PartnerRouter';
import { PATHS } from 'configs';
import { NavRouteContextEnum } from 'enums/navRouteContextEnum';
import { UserBusinessTypeEnum } from 'enums/UserBusinessTypeEnum';
import useNavRouteMatch from 'hooks/useNavRouteMatch';
import { IService } from 'models/partner';
import { IRouteEntry } from 'models/routing';
import { generatePath } from 'react-router-dom';
import useAppState, { userSelector } from 'stores/appState/useAppState';
import useContributionsStore, {
  contributionsSelector,
} from 'stores/useContributionsStore';
import useLayoutStore, {
  openNavSelector,
  setOpenNavSelector,
} from 'stores/useLayoutStore';
import useMyPartnersStore, {
  IPartnerEntry,
  partnersSelector,
} from 'stores/useMyPartnersStore';

const userHasRequiredScopes = (
  route: IRouteEntry,
  usersCurrentPartnerScopes: string[]
) => {
  if (!route.requiredScopes) {
    return true;
  }

  return route.requiredScopes.every(scope =>
    usersCurrentPartnerScopes.includes(scope)
  );
};

const partnerHasRequiredServices = (
  route: IRouteEntry,
  services?: IService[]
) => {
  if (!route.requiredServices) {
    return true;
  }

  if (!services) {
    return false;
  }

  return route.requiredServices.every(service =>
    services.map(s => s.name).includes(service)
  );
};

const routesReducer = (
  array: IRouteEntry[],
  partnerId: string | undefined,
  usersPartners: IPartnerEntry[],
  currentPartnerServices?: IService[]
) => {
  const filteredRoutes = array.filter(x => !!x.labelI18nKey);
  if (partnerId) {
    const currentPartner = usersPartners.find(x => x.id === partnerId);
    // if there is no partner on the store, don't show a subnav
    if (!currentPartner) {
      //TODO: capture sentry/instana exception
      return [];
    }
    const usersCurrentPartnerScopes = currentPartner.role.scopes.map(
      scope => scope.name
    );
    // for partner routes, we also need to check if user has required scopes
    // on the partnership
    const routesWithScopes = filteredRoutes.filter(route =>
      userHasRequiredScopes(route, usersCurrentPartnerScopes)
    );

    //for partner routes we also need to check partner has required services
    const routesWithServices = routesWithScopes.filter(route =>
      partnerHasRequiredServices(route, currentPartnerServices)
    );

    // then generate the paths
    return routesWithServices.map((element: IRouteEntry) => ({
      ...element,
      path: generatePath(element.path, { partnerId: partnerId }),
    }));
  }

  return filteredRoutes;
};

const getRoutes = (
  context: NavRouteContextEnum | null,
  partnerId: string | undefined,
  usersPartners: IPartnerEntry[],
  currentPartnerServices?: IService[]
) => {
  switch (context) {
    case NavRouteContextEnum.LP_ACCOUNT:
      return routesReducer(LP_ACCOUNT_ROUTES, partnerId, usersPartners);
    case NavRouteContextEnum.PARTNER:
      return routesReducer(
        PARTNER_ROUTES,
        partnerId,
        usersPartners,
        currentPartnerServices
      );
    default:
      return [];
  }
};

const useNavigationMenu = () => {
  const { context, partnerId } = useNavRouteMatch();
  const usersPartners = useMyPartnersStore(partnersSelector);
  const contributions = useContributionsStore(contributionsSelector);
  const currentUser = useAppState(userSelector);
  const openNav = useLayoutStore(openNavSelector);
  const setOpenNav = useLayoutStore(setOpenNavSelector);

  const [partnerServices] = useState<IService[]>([]);

  const menuRoutes = getRoutes(
    context,
    partnerId,
    usersPartners,
    partnerServices
  ).filter(
    element => !(element.path === PATHS.PROFILE && contributions.length === 0)
  );

  const isShipmentAnonymousPage =
    context === NavRouteContextEnum.SHIPMENT_ANONYMOUS_DETAIL;

  const showKoalendar =
    currentUser?.businessType !== UserBusinessTypeEnum.PERSONAL &&
    context !== NavRouteContextEnum.SHIPMENT_ANONYMOUS_DETAIL;

  return {
    openNav,
    setOpenNav,
    menuRoutes,
    isShipmentAnonymousPage,
    showKoalendar,
  };
};

export default useNavigationMenu;
