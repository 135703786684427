import React from 'react';

import {
  WalletIcon,
  Text,
  Box,
  useTheme,
  styled,
  Stack,
} from '@livingpackets/design-system-react-next';
import { useTranslation } from 'react-i18next';

const BankankDetailInProgress = styled('div')`
  background-color: ${({ theme }) => theme.palette.custom.neutral.white.pure};
  border-radius: 20px;
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.08);
  padding: 12px 14px;
`;

const WalletIconContainer = styled(Box)`
  height: 48px;
  width: 48px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px;
`;

const WalletFailIconContainer = styled(WalletIconContainer)`
  background-color: ${({ theme }) => theme.palette.custom.error.red[10]};
`;

const BankErrorErrorToFix = () => {
  const { t } = useTranslation('accountInformation');
  const theme = useTheme();

  return (
    <BankankDetailInProgress>
      <Stack direction="row" alignItems="center">
        <WalletFailIconContainer>
          <WalletIcon color={theme.palette.custom.error.red[100]} />
        </WalletFailIconContainer>
        <Stack paddingLeft="24px">
          <Text variant="titleXS" color="custom.error.red.100">
            {t('error.title')}
          </Text>
          <Text variant="bodyTextS" color="custom.error.red.100">
            {t('error.text')}
          </Text>
        </Stack>
      </Stack>
    </BankankDetailInProgress>
  );
};

/* Your bank information seems to be wrong. Please change your bank account. */
export default BankErrorErrorToFix;
