import React from 'react';

import {
  EcologyIcon,
  Text,
  styled,
} from '@livingpackets/design-system-react-next';
import { useTranslation } from 'react-i18next';

const CO2CardWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.palette.custom.neutral.white.pure};
  border-radius: 1.25rem;
  padding: 0 1rem 3rem 1rem;
  height: 100%;
`;
const IconWrapper = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.625rem;
  background-color: ${({ theme }) => theme.palette.custom.primary[15]};
  margin-right: 1rem;
  padding: 0.4rem 0.625rem;
`;
const GlobalEstimationAmount = styled('div')`
  display: flex;
  justify-content: center;
  margin-top: 2.438rem;
  width: 100%;
`;
const TitleWrapper = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 3.75rem;
`;
const EstimatedCarbonValuesWrapper = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.438rem;
  padding: 0.5rem;
  background-color: ${({ theme }) => theme.palette.custom.primary[15]};
  width: 100%;
  margin-top: 1.813rem;
`;
const EstimatedCO2 = ({ co2Savings }: number | any) => {
  const { t } = useTranslation(['contribution', 'general']);

  return (
    <CO2CardWrapper>
      <TitleWrapper>
        <IconWrapper>
          <EcologyIcon style={{ marginTop: '0.313rem' }} />
        </IconWrapper>
        <Text variant="titleM" mt="0.5rem">
          {t('contribution:contributionsTab.co2estimation.co2Estimation')}
        </Text>
      </TitleWrapper>
      <GlobalEstimationAmount>
        <Text color="custom.primary.140" variant="titleXXL">
          {t('general:co2estimation.globalEstimatedCarbon', {
            globalEstimatedCarbon: co2Savings,
          })}
        </Text>
      </GlobalEstimationAmount>
      <EstimatedCarbonValuesWrapper>
        <Text variant="titleS">
          {t('contribution:contributionsTab.co2estimation.subtitle')}
        </Text>
      </EstimatedCarbonValuesWrapper>
    </CO2CardWrapper>
  );
};

export default EstimatedCO2;
