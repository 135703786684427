import React from 'react';

import { Collapse } from '@livingpackets/design-system-react-next';
import useIsMobile from 'hooks/useIsMobile';
import useLayoutStore, { openNavSelector } from 'stores/useLayoutStore';

import LangSwitch from './LangSwitch';

const LANGUAGES = ['en', 'fr', 'de'];

const Languages = () => {
  const { isMobile } = useIsMobile();

  const openNav = useLayoutStore(openNavSelector);

  const langSwitch = <LangSwitch languages={LANGUAGES} />;

  // No animation for mobile menu
  if (isMobile) {
    return langSwitch;
  }

  return (
    <Collapse in={openNav} orientation="horizontal" collapsedSize={40}>
      {langSwitch}
    </Collapse>
  );
};

export default Languages;
