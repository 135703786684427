import React from 'react';

import styled from 'styled-components';

interface ITabs {
  children: React.ReactNode[];
}

const Container = styled.div`
  display: flex;
  cursor: pointer;
  background-color: ${({ theme }) => theme.colors.black[8]};
  border-radius: 7px;
  padding: 2px;
  width: fit-content;
  margin-bottom: 22px;
`;

const NavTabs = ({ children }: ITabs) => <Container>{children}</Container>;

export default NavTabs;
