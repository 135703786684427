import { useQuery } from '@tanstack/react-query';
import useImperativeRequest from 'hooks/useImperativeRequest';
import { ExtractFnReturnType, QueryConfig } from 'lib/react-query';
import { useParams } from 'react-router-dom';

import { Address } from '../../types/address';

export type AddressesResponse = {
  count: string;
  items: Address[];
};

export const getAddresses = async ({
  partnershipId,
  makeRequest,
}: {
  partnershipId?: string;
  makeRequest: any;
}): Promise<AddressesResponse> => {
  const { data, error } = await makeRequest({
    path: `api/v1/me/partners/${partnershipId}/addresses`,
  });

  if (error) {
    throw new Error(error);
  }

  return data;
};

type QueryFnType = typeof getAddresses;

type UseAddressOptions = {
  partnershipId?: string;
  config?: QueryConfig<QueryFnType>;
};

export const useAddresses = ({
  partnershipId,
  config,
}: UseAddressOptions = {}) => {
  const { partnerId } = useParams();

  let id = partnershipId;
  if (!id) {
    id = partnerId;
  }

  const [, makeRequest] = useImperativeRequest('lpVillage');

  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: ['partnership', id, 'addresses'],
    queryFn: () => getAddresses({ partnershipId: id, makeRequest }),
  });
};
