import { useCallback } from 'react';

import { PATHS } from 'configs';
import useFiguralInsurance from 'hooks/useFiguralInsurance';
import useToastMessages from 'hooks/useToastMessages';
import { queryClient } from 'lib/react-query';
import { useTranslation } from 'react-i18next';
import { generatePath } from 'react-router-dom';
import useAppState, { userSelector } from 'stores/appState/useAppState';

import { usePartnership } from './getPartnership';

/**
 * Allow to activate or deactivate insurance
 * @returns {activateInsurance, deactivateInsurance}
 */
export const useInsurance = () => {
  const { error: toastError, success: toastSuccess } = useToastMessages();
  const {
    i18n: { language },
  } = useTranslation();

  const appUser = useAppState(userSelector);
  const { data: partnership } = usePartnership();
  const { disableFiguralForPartner } = useFiguralInsurance();

  const activateInsurance = useCallback(() => {
    if (!appUser || !partnership) return;

    const queryParams = new URLSearchParams();
    queryParams.set(
      'redirect_uri',
      window.location.origin +
        generatePath(PATHS.SETTINGS.INSURANCE_ACTIVATION_SUCCESS, {
          partnerId: partnership.id,
        })
    );
    queryParams.set('email', appUser.email);
    queryParams.set('lang', ['en', 'fr'].includes(language) ? language : 'en');
    queryParams.set(
      'mode',
      process.env.REACT_APP_FIGURAL_MODE
        ? process.env.REACT_APP_FIGURAL_MODE
        : 'test'
    );
    queryParams.set('rel', 'livingpackets');

    window.location.href = `${PATHS.FIGURAL_AUTHORIZE_PAGE}${decodeURIComponent(
      queryParams.toString()
    )}`;
  }, [appUser, partnership, language]);

  const deactivateInsurance = () => {
    if (!partnership) {
      return;
    }

    disableFiguralForPartner(partnership.id)
      .then(() => {
        toastSuccess('messages:disableFiguralForPartnerSuccess.message', {
          wide: true,
        });

        // Update partnership data refetch Query
        queryClient.refetchQueries({
          queryKey: ['partnership', partnership.id],
        });
      })
      .catch(() => {
        toastError('messages:disableFiguralForPartnerError.message', {
          wide: true,
        });
      });
  };

  return { activateInsurance, deactivateInsurance };
};
