import {
  CommandControlStatus,
  CommandControlStatusKeys,
  CommandControlStatusValues,
} from '../types/commandControlStatus';

export const getKeyByValue = (
  value: CommandControlStatusValues
): CommandControlStatusKeys | null => {
  const keys = Object.keys(CommandControlStatus) as CommandControlStatusKeys[];
  for (const key of keys) {
    if (CommandControlStatus[key] === value) {
      return key;
    }
  }

  return null;
};
