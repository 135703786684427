import React from 'react';

import useNavRouteMatch from 'hooks/useNavRouteMatch';
import { Helmet } from 'react-helmet';

const HelmetTitle = () => {
  const { context } = useNavRouteMatch();

  const title =
    context === 'partner'
      ? 'LP | Partnership'
      : context === 'shipmentAnonymousDetail'
      ? 'Delivery'
      : 'LP | Private account';

  return (
    <Helmet>
      <title>{title}</title>
    </Helmet>
  );
};

export default HelmetTitle;
