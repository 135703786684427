import React from 'react';

import {
  CloseIcon,
  Text,
  Stack,
} from '@livingpackets/design-system-react-next';
import { useTranslation } from 'react-i18next';

import Modal, { IModalWrapperType } from '../../atoms/Modal';

interface IViewShipmentLabelFullScreenModal {
  visible: boolean;
  onDismiss: () => void;
  shipmentLabelUrl?: string;
  hideLabelFullScreenModal: () => void;
}

const ViewShipmentLabelModal = ({
  visible,
  onDismiss,
  shipmentLabelUrl,
}: IViewShipmentLabelFullScreenModal) => {
  const { t } = useTranslation('shipments');

  return (
    <Modal
      open={visible}
      handleClose={onDismiss}
      wrapperType={IModalWrapperType.shipmentLabel}
    >
      <Stack height="90%">
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          marginBottom="32px"
        >
          <Text variant="titleL">{t('modal.displayLabel.title')}</Text>
          <CloseIcon
            onClick={() => onDismiss()}
            style={{ cursor: 'pointer' }}
          />
        </Stack>
        <img
          src={shipmentLabelUrl}
          style={{ height: '100%', marginLeft: 'auto', marginRight: 'auto' }}
          alt="Shipment Label"
        />
      </Stack>
    </Modal>
  );
};

export default ViewShipmentLabelModal;
