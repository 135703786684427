import React, { useEffect, useRef, useState } from 'react';

import {
  CardBody,
  CardFooter,
  CardHeader,
  Card,
} from '@livingpackets/design-system-react';
import {
  Alert,
  Box,
  Stack,
  ButtonV2 as Button,
  Text,
  useTheme,
  LampOnIcon,
  Grid,
  Chip,
} from '@livingpackets/design-system-react-next';
import {
  DisconnectedIcon,
  ReceiveIcon,
  LinkIcon,
  LockOffIcon,
  LockOnIcon,
  ArrowLeftIcon,
  RefreshIcon,
} from '@livingpackets/design-system-react-next';
import TheBoxRC1ADE from 'assets/img/remote/lotties/de/rc-the-box-1a-de.json';
import TheBoxRC1BDE from 'assets/img/remote/lotties/de/rc-the-box-1b-de.json';
import TheBoxRC2ADE from 'assets/img/remote/lotties/de/rc-the-box-2a-de.json';
import TheBoxRC2BDE from 'assets/img/remote/lotties/de/rc-the-box-2b-de.json';
import TheBoxRC3DE from 'assets/img/remote/lotties/de/rc-the-box-3-de.json';
import TheBoxPRORC1ADE from 'assets/img/remote/lotties/de/rc-the-box-pro-1a-de.json';
import TheBoxPRORC1BDE from 'assets/img/remote/lotties/de/rc-the-box-pro-1b-de.json';
import TheBoxPRORC2ADE from 'assets/img/remote/lotties/de/rc-the-box-pro-2a-de.json';
import TheBoxPRORC2BDE from 'assets/img/remote/lotties/de/rc-the-box-pro-2b-de.json';
import TheBoxPRORC3DE from 'assets/img/remote/lotties/de/rc-the-box-pro-3-de.json';
import TheTabletRC1ADE from 'assets/img/remote/lotties/de/rc-the-tablet-1a-de.json';
import TheTabletRC1BDE from 'assets/img/remote/lotties/de/rc-the-tablet-1b-de.json';
import TheTabletRC2ADE from 'assets/img/remote/lotties/de/rc-the-tablet-2a-de.json';
import TheTabletRC2BDE from 'assets/img/remote/lotties/de/rc-the-tablet-2b-de.json';
import TheTabletRC3DE from 'assets/img/remote/lotties/de/rc-the-tablet-3-de.json';
import TheBoxRC1AEN from 'assets/img/remote/lotties/en/rc-the-box-1a-en.json';
import TheBoxRC1BEN from 'assets/img/remote/lotties/en/rc-the-box-1b-en.json';
import TheBoxRC2AEN from 'assets/img/remote/lotties/en/rc-the-box-2a-en.json';
import TheBoxRC2BEN from 'assets/img/remote/lotties/en/rc-the-box-2b-en.json';
import TheBoxRC3EN from 'assets/img/remote/lotties/en/rc-the-box-3-en.json';
import TheBoxPRORC1AEN from 'assets/img/remote/lotties/en/rc-the-box-pro-1a-en.json';
import TheBoxPRORC1BEN from 'assets/img/remote/lotties/en/rc-the-box-pro-1b-en.json';
import TheBoxPRORC2AEN from 'assets/img/remote/lotties/en/rc-the-box-pro-2a-en.json';
import TheBoxPRORC2BEN from 'assets/img/remote/lotties/en/rc-the-box-pro-2b-en.json';
import TheBoxPRORC3EN from 'assets/img/remote/lotties/en/rc-the-box-pro-3-en.json';
import TheTabletRC1AEN from 'assets/img/remote/lotties/en/rc-the-tablet-1a-en.json';
import TheTabletRC1BEN from 'assets/img/remote/lotties/en/rc-the-tablet-1b-en.json';
import TheTabletRC2AEN from 'assets/img/remote/lotties/en/rc-the-tablet-2a-en.json';
import TheTabletRC2BEN from 'assets/img/remote/lotties/en/rc-the-tablet-2b-en.json';
import TheTabletRC3EN from 'assets/img/remote/lotties/en/rc-the-tablet-3-en.json';
import TheBoxRC1AFR from 'assets/img/remote/lotties/fr/rc-the-box-1a-fr.json';
import TheBoxRC1BFR from 'assets/img/remote/lotties/fr/rc-the-box-1b-fr.json';
import TheBoxRC2AFR from 'assets/img/remote/lotties/fr/rc-the-box-2a-fr.json';
import TheBoxRC2BFR from 'assets/img/remote/lotties/fr/rc-the-box-2b-fr.json';
import TheBoxRC3FR from 'assets/img/remote/lotties/fr/rc-the-box-3-fr.json';
import TheBoxPRORC1AFR from 'assets/img/remote/lotties/fr/rc-the-box-pro-1a-fr.json';
import TheBoxPRORC1BFR from 'assets/img/remote/lotties/fr/rc-the-box-pro-1b-fr.json';
import TheBoxPRORC2AFR from 'assets/img/remote/lotties/fr/rc-the-box-pro-2a-fr.json';
import TheBoxPRORC2BFR from 'assets/img/remote/lotties/fr/rc-the-box-pro-2b-fr.json';
import TheBoxPRORC3FR from 'assets/img/remote/lotties/fr/rc-the-box-pro-3-fr.json';
import TheTabletRC1AFR from 'assets/img/remote/lotties/fr/rc-the-tablet-1a-fr.json';
import TheTabletRC1BFR from 'assets/img/remote/lotties/fr/rc-the-tablet-1b-fr.json';
import TheTabletRC2AFR from 'assets/img/remote/lotties/fr/rc-the-tablet-2a-fr.json';
import TheTabletRC2BFR from 'assets/img/remote/lotties/fr/rc-the-tablet-2b-fr.json';
import TheTabletRC3FR from 'assets/img/remote/lotties/fr/rc-the-tablet-3-fr.json';
import { PATHS } from 'configs';
import { ProductArticleCodeEnum } from 'enums/ProductEnum';
import { DeployReturnTabs } from 'features/deploy-return';
import { getProperLpui } from 'hooks/useProductDetail';
import useShipmentDetail from 'hooks/useShipmentDetail';
import { articleCodeBoxProList, articleCodeTabletList } from 'models/product';
import { IShipment } from 'models/shipment';
import { Trans, useTranslation } from 'react-i18next';
import Lottie from 'react-lottie';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import useMyPartnersStore, {
  activePartnerSelector,
} from 'stores/useMyPartnersStore';
import { getProductType } from 'utils/product';

import useRemoteControl, {
  REMOTE_COMMAND_STATUS_ERROR,
  REMOTE_COMMAND_STATUS_WAITING,
} from '../api/useRemoteControl';
import RemoteControlLoader from '../component/RemoteControlLoader';
import RemoteInstructionMessage from '../component/RemoteInstructionMessage';
import TooltipStartTips from '../component/TooltipStartTips';

const MIN_RETRY_TIME = 12;

const cnxTimeOut: any[] = [];

enum ProductLabel {
  box = 'THE BOX',
  boxPro = 'THE BOX PRO',
  tablet = 'THE TABLET',
}

export const RemoteControl = () => {
  const { t, i18n } = useTranslation('remoteControl');
  const navigate = useNavigate();
  const theme = useTheme();

  const activePartner = useMyPartnersStore(activePartnerSelector);

  const [currentStep, setCurrentStep] = useState<number>(0);
  const [isConnected, setIsConnected] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isFinish, setIsFinish] = useState<boolean>(false);
  const [hasReturn, setHasReturn] = useState<boolean>(false);
  const [displayRetryCTA, setDisplayRetryCTA] = useState<boolean>(false);
  const [, setReLoaded] = useState<boolean>(false);
  const [errorLoaderMessage, setErrorLoaderMessage] = useState<boolean>(false);
  const [cnxMessageLoader, setCnxMessageLoader] =
    useState<string>('loaderMessage');
  const [retryTime, setRetryTime] = useState(24); // 24 x 5000ms = 2min

  const { shipmentId } = useParams<{ shipmentId: string }>();
  const { partnerId } = useParams<{ partnerId: string }>();
  const { getShipmentDetail } = useShipmentDetail();
  const [shipmentDetail, setShipmentDetail] = useState<IShipment>();
  const [currentProduct, setCurrentProduct] = useState<ProductLabel>(
    ProductLabel.box
  );
  const { getProductConnectivity, sendRemoteCommand, getRemoteCommand } =
    useRemoteControl();

  const remoteCardBodyRef = useRef<HTMLDivElement | null>(null);
  const remoteCardFooterRef = useRef<HTMLDivElement | null>(null);

  let tryCall = 0;
  let beforeRetry = 0;

  const currentLanguage = i18n.language;

  const getConnection = async () => {
    setIsLoading(true);
    tryCall++;
    beforeRetry++;

    if (partnerId && shipmentDetail) {
      const data = await getProductConnectivity(
        partnerId,
        shipmentDetail?.product.lp_ui
      );

      if (data && data.is_direct_mode && data.is_connected) {
        setCurrentStep(currentStep + 1);
        setIsConnected(true);
        setIsLoading(false);
      } else {
        // == try for 2 min
        if (tryCall < retryTime) {
          // == waiting 30s and authorize retry
          if (beforeRetry === 6) {
            setDisplayRetryCTA(true);
            setCnxMessageLoader('loaderMessageBigTime');
          }

          cnxTimeOut.push(
            setTimeout(() => {
              getConnection();
            }, 5000)
          );
        } else {
          setReLoaded(false);
          //Add usestate for show error message
          setErrorLoaderMessage(true);
        }
      }
    }
  };

  const getRemoteCommandStatus = async (
    lpUi: string,
    partnerId: string,
    id: string
  ) => {
    const cmdResult = await getRemoteCommand(lpUi, partnerId, id);
    if (cmdResult) {
      if (REMOTE_COMMAND_STATUS_WAITING.includes(cmdResult.status)) {
        cnxTimeOut.push(
          setTimeout(() => {
            getRemoteCommandStatus(lpUi, partnerId, id);
          }, 2000)
        );
      } else if (REMOTE_COMMAND_STATUS_ERROR.includes(cmdResult.status)) {
        // TODO: show error message
      } else {
        setIsLoading(false);
        setCurrentStep(currentStep + 1);
        setIsFinish(true);
      }
    }
  };

  const getConfirmDelivery = async () => {
    setIsLoading(true);

    if (partnerId && shipmentDetail) {
      const data = await getProductConnectivity(
        partnerId,
        shipmentDetail?.product.lp_ui
      );

      if (data?.is_direct_mode && data?.is_connected) {
        const result = await sendRemoteCommand(
          partnerId,
          shipmentDetail.shipment_id,
          shipmentDetail.product.lp_ui,
          'COMMAND_TYPE_RECEIVED'
        );

        if (result?.status) {
          cnxTimeOut.push(
            setTimeout(() => {
              getRemoteCommandStatus(result.lp_ui, partnerId, result.id);
            }, 3000)
          );
        }
      } else {
        setDisplayRetryCTA(true);
        setErrorLoaderMessage(true);
        setIsConnected(false);
        setCurrentStep(currentStep - 1);
      }
    }
  };

  const getProductRender = (step: number) => {
    // arry of assets
    const productAssets: any = {
      en: {
        TheBoxPRORC2B: TheBoxPRORC2BEN,
        TheBoxPRORC1B: TheBoxPRORC1BEN,
        TheBoxPRORC2A: TheBoxPRORC2AEN,
        TheBoxPRORC1A: TheBoxPRORC1AEN,
        TheBoxPRORC3: TheBoxPRORC3EN,
        TheTabletRC2B: TheTabletRC2BEN,
        TheTabletRC1B: TheTabletRC1BEN,
        TheTabletRC2A: TheTabletRC2AEN,
        TheTabletRC3: TheTabletRC3EN,
        TheTabletRC1A: TheTabletRC1AEN,
        TheBoxRC2B: TheBoxRC2BEN,
        TheBoxRC1B: TheBoxRC1BEN,
        TheBoxRC2A: TheBoxRC2AEN,
        TheBoxRC3: TheBoxRC3EN,
        TheBoxRC1A: TheBoxRC1AEN,
      },
      fr: {
        TheBoxPRORC2B: TheBoxPRORC2BFR,
        TheBoxPRORC1B: TheBoxPRORC1BFR,
        TheBoxPRORC2A: TheBoxPRORC2AFR,
        TheBoxPRORC1A: TheBoxPRORC1AFR,
        TheBoxPRORC3: TheBoxPRORC3FR,
        TheTabletRC2B: TheTabletRC2BFR,
        TheTabletRC1B: TheTabletRC1BFR,
        TheTabletRC2A: TheTabletRC2AFR,
        TheTabletRC3: TheTabletRC3FR,
        TheTabletRC1A: TheTabletRC1AFR,
        TheBoxRC2B: TheBoxRC2BFR,
        TheBoxRC1B: TheBoxRC1BFR,
        TheBoxRC2A: TheBoxRC2AFR,
        TheBoxRC3: TheBoxRC3FR,
        TheBoxRC1A: TheBoxRC1AFR,
      },
      de: {
        TheBoxPRORC2B: TheBoxPRORC2BDE,
        TheBoxPRORC1B: TheBoxPRORC1BDE,
        TheBoxPRORC2A: TheBoxPRORC2ADE,
        TheBoxPRORC1A: TheBoxPRORC1ADE,
        TheBoxPRORC3: TheBoxPRORC3DE,
        TheTabletRC2B: TheTabletRC2BDE,
        TheTabletRC1B: TheTabletRC1BDE,
        TheTabletRC2A: TheTabletRC2ADE,
        TheTabletRC3: TheTabletRC3DE,
        TheTabletRC1A: TheTabletRC1ADE,
        TheBoxRC2B: TheBoxRC2BDE,
        TheBoxRC1B: TheBoxRC1BDE,
        TheBoxRC2A: TheBoxRC2ADE,
        TheBoxRC3: TheBoxRC3DE,
        TheBoxRC1A: TheBoxRC1ADE,
      },
    };

    if (
      articleCodeBoxProList.includes(
        shipmentDetail?.product.article_code as ProductArticleCodeEnum
      )
    ) {
      if (isLoading) {
        return step === 1
          ? productAssets[currentLanguage].TheBoxPRORC2B
          : productAssets[currentLanguage].TheBoxPRORC1B;
      }

      return step === 1
        ? productAssets[currentLanguage].TheBoxPRORC2A
        : step === 2
        ? productAssets[currentLanguage].TheBoxPRORC3
        : productAssets[currentLanguage].TheBoxPRORC1A;
    }

    if (
      articleCodeTabletList.includes(
        shipmentDetail?.product.article_code as ProductArticleCodeEnum
      )
    ) {
      if (isLoading) {
        return step === 1
          ? productAssets[currentLanguage].TheTabletRC2B
          : productAssets[currentLanguage].TheTabletRC1B;
      }

      return step === 1
        ? productAssets[currentLanguage].TheTabletRC2A
        : step === 2
        ? productAssets[currentLanguage].TheTabletRC3
        : productAssets[currentLanguage].TheTabletRC1A;
    }

    if (isLoading) {
      return step === 1
        ? productAssets[currentLanguage].TheBoxRC2B
        : productAssets[currentLanguage].TheBoxRC1B;
    }

    return step === 1
      ? productAssets[currentLanguage].TheBoxRC2A
      : step === 2
      ? productAssets[currentLanguage].TheBoxRC3
      : productAssets[currentLanguage].TheBoxRC1A;
  };

  const redirectToShipmentDetail = () => {
    navigate(
      generatePath(PATHS.SHIPMENT.DETAIL, {
        partnerId: activePartner.id,
        shipmentId: shipmentId,
      })
    );
  };

  const REMOTE_PROCESS_FLOW = [
    {
      step: 1,
      title: t('stepInit.title'),
      message: (
        <Trans
          t={t}
          i18nKey="stepInit.message"
          values={{ product: currentProduct }}
        />
      ),
      btnRollbackLabel: t('stepInit.btnRollbackLabel'),
      btnContinueLabel: t('stepInit.btnContinueLabel'),
      btnContinueAction: () => {
        getConnection();
      },
      btnCancelAction: () => navigate(-1),
      loaderTitle: t('stepInit.loaderTitle', { product: currentProduct }),
      loaderMessage: t('stepInit.loaderMessage', { product: currentProduct }),
      loaderMessageBigTime: t('stepInit.loaderMessageBigTime'),
      loaderMessageAfterRetry: t('stepInit.loaderMessageAfterRetry'),
      animateRessource: getProductRender(currentStep),
    },
    {
      step: 2,
      title: t('stepReceive.title'),
      message: t('stepReceive.message'),
      btnRollbackLabel: t('stepInit.btnRollbackLabel'),
      btnContinueLabel: t('stepReceive.btnContinueLabel'),
      btnContinueAction: () => {
        getConfirmDelivery();
      },
      btnCancelAction: () => navigate(-1),
      loaderTitle: t('stepReceive.loaderTitle'),
      loaderMessage: t('stepReceive.loaderMessage'),
      animateRessource: getProductRender(currentStep),
    },
    {
      step: 3,
      title: t('stepFinish.title'),
      btnRollbackLabel: '',
      btnContinueLabel: t('stepFinish.btnContinueLabel'),
      btnContinueAction: () => redirectToShipmentDetail(),
      btnCancelAction: () => {},
      animateRessource: getProductRender(currentStep),
      loaderTitle: '',
      loaderMessage: '',
      message: hasReturn ? (
        <Trans
          t={t}
          i18nKey="stepFinish.messageReturn"
          components={[
            <span
              style={{
                fontWeight: 500,
                color: theme.palette.custom.neutral.black[100],
              }}
              key={0}
            />,
          ]}
        />
      ) : (
        <Trans
          ns="remoteControl"
          i18nKey="stepFinish.message"
          components={[
            <span
              style={{
                fontWeight: 500,
                color: theme.palette.custom.neutral.black[100],
              }}
              key={0}
            />,
          ]}
        />
      ),
    },
    {
      title: t('stepFinish.title'),
      btnRollbackLabel: '',
      btnContinueLabel: t('stepFinish.btnContinueLabel'),
      btnContinueAction: () => redirectToShipmentDetail(),
      btnCancelAction: () => {},
      animateRessource: getProductRender(currentStep),
      loaderTitle: '',
      loaderMessage: '',
      message: hasReturn
        ? t('stepFinish.messageReturn')
        : t('stepFinish.message'),
    },
  ];

  const clearAllTimeout = () => {
    cnxTimeOut.forEach(timeout => {
      clearTimeout(timeout);
    });
    // Empty the array
    cnxTimeOut.length = 0;
  };

  const shouldDisplayBreadCrumb = (step: number, isLoading: boolean) => {
    if (step > 1) {
      return false;
    }
    if (step === 1 && isLoading) {
      return false;
    }

    return true;
  };

  useEffect(() => {
    if (shipmentId) {
      getShipmentDetail(activePartner.id, shipmentId).then(
        ({ success, shipment }) => {
          setShipmentDetail(shipment);

          const productType: string = getProductType(
            shipment?.product.article_code
          );

          setCurrentProduct(
            ProductLabel[productType as keyof typeof ProductLabel]
          );

          if (shipment?.return_shipment_id) {
            setHasReturn(true);
          }
        }
      );
    }

    return () => {
      clearAllTimeout();
    };
  }, [activePartner.id, getShipmentDetail, shipmentId]);

  return (
    <>
      <Box>
        <Card>
          <CardHeader backgroundColor="black.100" color="white" padding={5}>
            <Stack direction="column" spacing={2}>
              <Stack direction="row" spacing={2} alignItems="center">
                {shouldDisplayBreadCrumb(currentStep, isLoading) && (
                  <>
                    <Button
                      variant="tertiary"
                      color="info"
                      icon={ArrowLeftIcon}
                      onClick={() => navigate(-1)}
                    />
                    <Text variant="bodyTextXS" color="custom.neutral.black.50">
                      {t('breadcrumb')}
                    </Text>
                  </>
                )}
              </Stack>

              <Stack direction="column" spacing={0}>
                <Text variant="titleXS">
                  {getProperLpui(shipmentDetail?.product?.lp_ui)}
                </Text>
                <Text variant="titleXL">{t('titlePage')}</Text>
              </Stack>
            </Stack>
          </CardHeader>

          {shipmentDetail && (
            <div ref={remoteCardBodyRef}>
              <CardBody
                style={{ minHeight: '18.75rem', padding: '1.5rem' }}
                className="remote-card-body"
              >
                {currentStep === 3 ? (
                  <DeployReturnTabs />
                ) : (
                  <Box>
                    <Box
                      display="flex"
                      justifyContent={
                        errorLoaderMessage ? 'space-between' : 'center'
                      }
                      alignItems="flex-start"
                      minHeight="7.1875rem"
                      position="relative"
                    >
                      {isLoading ? (
                        <>
                          {errorLoaderMessage ? (
                            <Box
                              sx={{
                                width: '100%',
                                flexGrow: 1,
                                marginRight: '5.75rem',
                              }}
                            >
                              <Alert state="error">
                                <Text variant="bodyTextS">
                                  {t('stepInit.connectionFail')}
                                </Text>
                              </Alert>
                            </Box>
                          ) : (
                            <RemoteControlLoader
                              title={
                                REMOTE_PROCESS_FLOW[currentStep].loaderTitle
                              }
                              message={
                                (REMOTE_PROCESS_FLOW[currentStep] as any)[
                                  cnxMessageLoader
                                ]
                              }
                            />
                          )}
                          {displayRetryCTA && retryTime > MIN_RETRY_TIME && (
                            <Button
                              variant="secondary"
                              {...{
                                sx: { position: 'absolute', right: 0, top: 0 },
                              }}
                              onClick={() => {
                                // waiting 1 min after refresh;
                                setRetryTime(MIN_RETRY_TIME);
                                setCnxMessageLoader('loaderMessageAfterRetry');
                                setDisplayRetryCTA(false);
                                setErrorLoaderMessage(false);
                                clearAllTimeout();
                                getConnection();
                              }}
                              icon={RefreshIcon}
                              disabled={retryTime === MIN_RETRY_TIME}
                            />
                          )}
                        </>
                      ) : (
                        <RemoteInstructionMessage
                          message={REMOTE_PROCESS_FLOW[currentStep].message}
                          title={REMOTE_PROCESS_FLOW[currentStep].title}
                          stepIndex={REMOTE_PROCESS_FLOW[currentStep].step}
                        />
                      )}
                    </Box>

                    <Box
                      sx={{
                        borderRadius: '1.5rem',
                        background: theme.palette.custom.neutral.black[2],
                        marginTop: '.875rem',
                      }}
                    >
                      {currentStep === 0 && !isLoading && (
                        <Stack
                          direction="row"
                          gap="0.625rem"
                          width="100%"
                          justifyContent="end"
                          paddingTop="1.5rem"
                          paddingRight="1.5rem"
                          alignItems="center"
                        >
                          <TooltipStartTips productType={currentProduct} />
                        </Stack>
                      )}

                      <Box
                        justifyContent="center"
                        alignItems="center"
                        paddingTop=".875rem"
                        paddingLeft=".875rem"
                        paddingBottom="2rem"
                      >
                        {/* TIPS LOADING */}
                        {currentStep === 1 && !isLoading && (
                          <span
                            style={{
                              display: 'inline-flex',
                              background:
                                theme.palette.custom.neutral.white.pure,
                              borderRadius: '.625rem',
                              flexDirection: 'row',
                              alignItems: 'center',
                              padding: '.75rem 1rem',
                            }}
                          >
                            <LampOnIcon
                              color={theme.palette.custom.secondary.yellow[100]}
                            />
                            <Text
                              variant="titleS"
                              sx={{
                                color:
                                  theme.palette.custom.secondary.yellow[100],
                              }}
                            >
                              {t('stepReceive.warningMessage')}
                            </Text>
                          </span>
                        )}
                        {/* ANIMATION ASSET */}
                        <Lottie
                          width="80%"
                          height="80%"
                          options={{
                            loop: true,
                            autoplay: true,
                            animationData:
                              REMOTE_PROCESS_FLOW[currentStep].animateRessource,

                            rendererSettings: {
                              preserveAspectRatio: 'xMidYMid slice',
                            },
                          }}
                        />

                        {/* CHIP STATUS */}
                        <Box
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                          gap=".875rem"
                        >
                          <Box
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                            justifyContent="center"
                          >
                            <Chip
                              state={isConnected ? 'default' : 'idle'}
                              {...{
                                sx: {
                                  paddingLeft: '1rem',
                                  paddingRight: '1rem',
                                  color: isConnected
                                    ? theme.palette.custom.primary[100]
                                    : theme.palette.custom.neutral.black[50],
                                },
                              }}
                              type="tags"
                              variant="titleS"
                              icon={
                                isConnected ? (
                                  <LinkIcon
                                    color={theme.palette.custom.primary[100]}
                                  />
                                ) : (
                                  <DisconnectedIcon
                                    color={
                                      theme.palette.custom.neutral.black[50]
                                    }
                                  />
                                )
                              }
                              label={
                                isConnected
                                  ? t('status.connected')
                                  : t('status.disconnected')
                              }
                            />
                          </Box>

                          <Box
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                            justifyContent="center"
                          >
                            <Chip
                              type="tags"
                              label={
                                isFinish
                                  ? t('status.unlocked')
                                  : t('status.locked')
                              }
                              variant="titleS"
                              state={isFinish ? 'default' : 'idle'}
                              {...{
                                sx: {
                                  paddingLeft: '1rem',
                                  paddingRight: '1rem',
                                  color: isFinish
                                    ? theme.palette.custom.primary[100]
                                    : theme.palette.custom.neutral.black[50],
                                },
                              }}
                              icon={
                                isFinish ? (
                                  <LockOffIcon
                                    color={theme.palette.custom.primary[100]}
                                  />
                                ) : (
                                  <LockOnIcon
                                    color={
                                      theme.palette.custom.neutral.black[50]
                                    }
                                  />
                                )
                              }
                            />
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                )}
                {isFinish && (
                  <Text
                    sx={{
                      textAlign: 'center',
                      paddingTop: '.875rem',
                      paddingBottom: '.875rem',
                    }}
                    variant="titleS"
                    color={theme.palette.custom.neutral.black[50]}
                  >
                    {t('stepFinish.bottomMessage')}
                  </Text>
                )}
              </CardBody>
            </div>
          )}
          <div ref={remoteCardFooterRef}>
            <CardFooter
              backgroundColor="transparent"
              className="remote-card-footer"
              style={{ paddingBottom: '1.5rem' }}
            >
              <Grid container direction="row" justifyContent="space-between">
                <Grid item mobile={6}>
                  {hasReturn && isFinish && currentStep === 2 && (
                    <Button
                      variant="secondary"
                      onClick={() => {
                        setCurrentStep(currentStep + 1);
                      }}
                      icon={ReceiveIcon}
                    >
                      {t('returnCTALabel')}
                    </Button>
                  )}

                  {REMOTE_PROCESS_FLOW[currentStep].btnRollbackLabel &&
                    !isFinish &&
                    !errorLoaderMessage &&
                    !(isLoading && currentStep === 1) && (
                      <Button
                        variant="tertiary"
                        onClick={
                          REMOTE_PROCESS_FLOW[currentStep].btnCancelAction
                        }
                      >
                        {REMOTE_PROCESS_FLOW[currentStep].btnRollbackLabel}
                      </Button>
                    )}
                </Grid>
                <Grid item mobile={6}>
                  <Stack alignItems="flex-end">
                    {errorLoaderMessage ? (
                      <Button
                        variant="primary"
                        onClick={() => navigate(-1)}
                        color="critical"
                      >
                        {t('quitCTAlabel')}
                      </Button>
                    ) : (
                      !isLoading && (
                        <Button
                          data-testid="remoteCTAContiniue"
                          variant="primary"
                          onClick={
                            REMOTE_PROCESS_FLOW[currentStep].btnContinueAction
                          }
                          disabled={isLoading}
                        >
                          {REMOTE_PROCESS_FLOW[currentStep].btnContinueLabel}
                        </Button>
                      )
                    )}
                  </Stack>
                </Grid>
              </Grid>
            </CardFooter>
          </div>
        </Card>
      </Box>
    </>
  );
};
