import React from 'react';

import {
  ButtonV2 as Button,
  DeleteIcon,
  DotsIcon,
  QuickLaunch,
} from '@livingpackets/design-system-react-next';
import { useTranslation } from 'react-i18next';

import { DeleteModal } from '../../../components/commons/delete';

export const MoreActionsMenu = () => {
  const { t } = useTranslation('account');

  const [openDeleteModal, setOpenDeleteModal] = React.useState(false);

  const handleDelete = () => {
    setOpenDeleteModal(true);
  };

  return (
    <>
      <QuickLaunch
        rootElement={({ handleClick }) => (
          <Button
            data-testid="more-actions-button"
            onClick={handleClick}
            icon={DotsIcon}
            variant="tertiary"
            sx={{
              background: 'transparent !important',
              color: 'custom.neutral.white.pure',
              '&:hover': {
                color: 'custom.neutral.white.pure',
              },
            }}
          />
        )}
        elements={[
          {
            'data-testid': 'delete-account-button',
            onClick: handleDelete,
            color: 'error',
            children: (
              <>
                <DeleteIcon /> {t('personal.deletion.buttonLabel')}
              </>
            ),
          },
        ]}
      />
      <DeleteModal open={openDeleteModal} setOpen={setOpenDeleteModal} />
    </>
  );
};
