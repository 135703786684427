import React from 'react';

import { ROUTE_PATHS } from 'configs';
import { Route, Routes } from 'react-router-dom';

import PartnerContactAddressAdd from '../../molecules/contactAddress/PartnerContactAddressAdd';
import PartnerContactAddressEdit from '../../molecules/contactAddress/PartnerContactAddressEdit';
import PartnerContactAddressOverview from '../../molecules/contactAddress/PartnerContactAddressOverview';

const PartnerContactAddressManagement = () => (
  <Routes>
    <Route index element={<PartnerContactAddressOverview />} />
    <Route
      path={ROUTE_PATHS.PARTNER.CHILDREN.CONTACT_ADDRESS.CHILDREN.CREATE.ROOT}
      element={<PartnerContactAddressAdd />}
    />
    <Route path={ROUTE_PATHS.PARTNER.CHILDREN.CONTACT_ADDRESS.CHILDREN.ID.ROOT}>
      <Route
        path={
          ROUTE_PATHS.PARTNER.CHILDREN.CONTACT_ADDRESS.CHILDREN.ID.CHILDREN.EDIT
            .ROOT
        }
        element={<PartnerContactAddressEdit />}
      />
    </Route>
  </Routes>
);

export default PartnerContactAddressManagement;
