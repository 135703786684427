import React from 'react';

import { Text, Stack, styled } from '@livingpackets/design-system-react-next';
import { useTranslation } from 'react-i18next';

import useMeAddresses from '../../../hooks/useMeAddresses';
import { TransactionMethod } from '../../../models/Wallet';

interface IBankAccountDetail {
  transactionMethod: TransactionMethod;
}

export const TextValueName = styled(Text)`
  margin-bottom: 16px;
`;

TextValueName.defaultProps = {
  variant: 'titleXS',
  color: 'custom.neutral.black100',
};

export const TextValue = styled(Text)`
  margin-bottom: 16px;
`;

TextValue.defaultProps = {
  variant: 'titleXS',
  color: 'custom.neutral.black.50',
};

const BankAccountDetail = ({ transactionMethod }: IBankAccountDetail) => {
  const { t } = useTranslation('accountInformation');

  const { formatIBAN } = useMeAddresses();

  return (
    <Stack direction="row">
      <Stack mr="0.9375rem">
        <TextValueName>{t('bankAccountDetail.data.bankName')}</TextValueName>
        <TextValueName>{t('bankAccountDetail.data.name')}</TextValueName>
        {transactionMethod.wire.iban && (
          <TextValueName>{t('bankAccountDetail.data.iban')}</TextValueName>
        )}
        {transactionMethod.wire.bic && (
          <TextValueName>{t('bankAccountDetail.data.bic')}</TextValueName>
        )}
        {transactionMethod.wire.swift_code && (
          <TextValueName>{t('bankAccountDetail.data.swiftCode')}</TextValueName>
        )}
        {transactionMethod.wire.bank_account_number && (
          <TextValueName>
            {t('bankAccountDetail.data.bankAccountNumber')}
          </TextValueName>
        )}
      </Stack>
      <Stack>
        <TextValue>{transactionMethod.wire.bank_title}</TextValue>
        <TextValue>{transactionMethod.wire.beneficiary_name}</TextValue>
        {transactionMethod.wire.iban && (
          <TextValue>{formatIBAN(transactionMethod.wire.iban)}</TextValue>
        )}
        {transactionMethod.wire.bic && (
          <TextValue>{transactionMethod.wire.bic}</TextValue>
        )}
        {transactionMethod.wire.swift_code && (
          <TextValue>{transactionMethod.wire.swift_code}</TextValue>
        )}
        {transactionMethod.wire.bank_account_number && (
          <TextValue>{transactionMethod.wire.bank_account_number}</TextValue>
        )}
      </Stack>
    </Stack>
  );
};

export default BankAccountDetail;
