import { useCallback } from 'react';

import { INITIAL_STATE_OFFER, Offer } from 'features/account';
import useImperativeRequest from 'hooks/useImperativeRequest';
import useMyPartnersStore, {
  activePartnerSelector,
} from 'stores/useMyPartnersStore';
import { create } from 'zustand';

import useToastMessages from './useToastMessages';

interface IPartnerOfferStore {
  offer: Offer;
  setOffer: (offer: Offer) => void;
}

export const usePartnerOfferStore = create<IPartnerOfferStore>(set => ({
  offer: INITIAL_STATE_OFFER,
  setOffer: (offer: Offer) => set({ offer }),
}));

const useGetPartnerOffer = () => {
  const [, makeRequest] = useImperativeRequest('deviceV3');
  const { error: toastError } = useToastMessages();
  const activePartner = useMyPartnersStore(activePartnerSelector);

  const getCurrentOffer = useCallback(async () => {
    const { error, data } = await makeRequest({
      path: `me/partners/${activePartner.id}/offer`,
      method: 'get',
    });
    if (error) {
      toastError('messages:getPartnerOfferError');

      return { success: false, offer: null };
    }

    if (data.contract_information.start_time) {
      data.contract_information.start_time =
        typeof data.contract_information.start_time === 'string'
          ? new Date(data.contract_information.start_time).getTime() / 1000
          : data.contract_information.start_time;
    }

    if (data.contract_information.end_time) {
      data.contract_information.end_time =
        typeof data.contract_information.end_time === 'string'
          ? new Date(data.contract_information.end_time).getTime() / 1000
          : data.contract_information.end_time;
    }

    if (data.created_at) {
      data.created_at =
        typeof data.created_at === 'string'
          ? new Date(data.created_at).getTime() / 1000
          : data.created_at;
    }

    if (data.updated_at) {
      data.updated_at =
        typeof data.updated_at === 'string'
          ? new Date(data.updated_at).getTime() / 1000
          : data.updated_at;
    }

    return { success: true, offer: data };
  }, [makeRequest, toastError, activePartner]);

  return {
    getCurrentOffer,
  };
};

export default useGetPartnerOffer;
