import React from 'react';

import { Text, styled } from '@livingpackets/design-system-react-next';
import Row from 'components/atoms/Row';
import { IBoxContract } from 'models/boxContract';
import { useTranslation } from 'react-i18next';

import useCountryCodesWrapper from '../../hooks/useCountryCodeWrapper';
import useGetPrimaryAddress from '../../hooks/useGetPrimaryAddress';
import useMyAddressesStore, {
  primaryAddressSelector,
} from '../../stores/useMyAddressesStore';

const Wrapper = styled('div')`
  display: flex;
  padding: 1rem 1.25rem;
  background-color: ${({ theme }) => theme.palette.custom.neutral.black[2]};
  width: 100%;
  border-radius: 0.875rem;
  margin-top: 1rem;
  margin-bottom: 1.5rem;
`;
const BoxContractDetailCard = ({
  boxContract,
}: {
  boxContract: IBoxContract;
  isPartner?: boolean;
}) => {
  const { t } = useTranslation('buyAndRent');

  useGetPrimaryAddress();
  const primaryAddress = useMyAddressesStore(primaryAddressSelector);
  const [, getCountryNameFromCode] = useCountryCodesWrapper();

  return (
    <Wrapper>
      <Row flexDirection="column" width="40%">
        <Text color="custom.neutral.black.50" marginBottom="1rem">
          {t('buyNRent.contracts.contractNumber')}
        </Text>
        <Text variant="bodyTextS" marginBottom="1rem">
          {boxContract.id}
        </Text>
      </Row>
      <Row flexDirection="column" width="35%">
        <Text color="custom.neutral.black.50" marginBottom="1rem">
          {t('buyNRent.contracts.customerAddress')}
        </Text>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            height: '5rem',
          }}
        >
          {primaryAddress != null ? (
            <>
              <Text variant="bodyTextS">
                {primaryAddress.firstName} &nbsp;
                {primaryAddress.lastName}
              </Text>
              <Text variant="bodyTextS">{primaryAddress.street}</Text>
              <Text
                sx={{
                  display: primaryAddress.street2 !== '' ? 'initial' : 'none',
                }}
                variant="bodyTextS"
              >
                {primaryAddress.street2}
              </Text>
              <Text variant="bodyTextS">
                {primaryAddress.postalCode} &nbsp;
                {primaryAddress.city}
              </Text>
              <Text variant="bodyTextS">
                {primaryAddress.countryCode !== ''
                  ? getCountryNameFromCode(primaryAddress.countryCode)
                  : ''}
              </Text>
            </>
          ) : null}
        </div>
      </Row>
      <Row flexDirection="column">
        <Text color="custom.neutral.black.50" marginBottom="1rem">
          {t('buyNRent.contracts.paymentId')}
        </Text>
        <Text variant="bodyTextS">{boxContract.paymentUniqueId}</Text>
      </Row>
    </Wrapper>
  );
};

export default BoxContractDetailCard;
