import i18next from 'i18next';
import { ContactAddressType } from 'models/contactAddress';
import * as yup from 'yup';

import { phoneNumber } from './common';

export const contactBasicInformationSchema =
  ({ smsEnabled = false }: { smsEnabled?: boolean } = {}) =>
  () =>
    yup.object().shape({
      firstName: yup
        .string()
        .required(
          i18next.t('contactAddress:form.firstName.validation.required')
        ),
      lastName: yup
        .string()
        .required(
          i18next.t('contactAddress:form.lastName.validation.required')
        ),
      email: yup
        .string()
        .required(i18next.t('contactAddress:form.email.validation.required'))
        .email(i18next.t('contactAddress:form.email.validation.email')),
      phoneNumber: phoneNumber(),
    });

export const contactAddressSchema = () =>
  yup.object().shape({
    type: yup.string(),
    companyName: yup.string().when('type', {
      is: (value: any) => value === ContactAddressType.professional,
      then: (schema: any) =>
        yup
          .string()
          .min(
            1,
            i18next.t('contactAddress:form.companyName.validation.required')
          )
          .required(
            i18next.t('contactAddress:form.companyName.validation.required')
          ),
    }),
    city: yup
      .string()
      .required(i18next.t('contactAddress:form.city.validation.required')),
    addressInfo: yup
      .string()
      .required(i18next.t('contactAddress:form.address.validation.required')),
    zipCode: yup
      .string()
      .required(i18next.t('contactAddress:form.zipCode.validation.required')),
    countryCode: yup
      .string()
      .required(i18next.t('contactAddress:form.country.validation.required')),
  });

export const contactSearchSchema = () =>
  yup.object().shape({
    contactName: yup.string(),
  });
