import React from 'react';

import { theme } from '@livingpackets/design-system-react';
import {
  BoxOpenedIcon,
  BoxPressureIcon,
  TemperatureIcon,
  HumidityIcon,
  ShockIcon,
} from '@livingpackets/design-system-react-next';
import AlertBadge from 'components/atoms/AlertBadge';
import DeliveredBadge from 'components/atoms/DeliveredBadge';
import ReadyForTransitBadge from 'components/atoms/ReadyForTransitBadge';
import {
  IShipmentAnonymousTimelineEvent,
  IShipmentTimelineEntry,
} from 'models/shipment';
import { DefaultTheme } from 'styled-components';

export type TStateEventIconType = 'circle' | 'badge' | 'alert';

export interface IParsedEvent {
  eventName: TAcceptedEvents;
  iconType: TStateEventIconType;
  Icon: React.FC<any>;
  date: number;
  alertInfo?: {
    value: string;
    unit: string;
  };
  stateSubtitle?: boolean;
}

type TAcceptedStateEvents =
  | 'INVALID'
  | 'DRAFT'
  | 'PURCHASED'
  | 'DEPLOYED'
  | 'ARRIVED'
  | 'ERROR'
  | 'PRE_TRANSIT'
  | 'IN_TRANSIT'
  | 'OUT_FOR_DELIVERY'
  | 'AVAILABLE_FOR_PICKUP'
  | 'DELIVERED'
  | 'RETURN_TO_SENDER'
  | 'CANCELLED'
  | 'FAILURE'
  | 'CARRIER_NOTIFICATION'
  | 'DELAYED'
  | 'DESTROYED'
  | 'BOX_LOCKED'
  | 'BOX_UNLOCKED'
  | 'CONFIRMED_TRANSMISSION'
  | 'CONFIRMED_DELIVERY'
  | 'RESET';

type TAcceptedBoxAlerts =
  | 'ALERT_BOX_VIOLATION'
  | 'ALERT_HUMIDITY'
  | 'ALERT_TEMPERATURE'
  | 'ALERT_SHOCK'
  | 'ALERT_PRESSURE';

export const SHIPMENT_STATE_EVENTS: {
  name: TAcceptedStateEvents;
  iconType: TStateEventIconType;
  Icon: any;
  stateSubtitle?: boolean;
}[] = [
  {
    name: 'DEPLOYED',
    iconType: 'badge',
    Icon: ReadyForTransitBadge,
    stateSubtitle: true,
  },
  {
    name: 'ARRIVED',
    iconType: 'badge',
    Icon: DeliveredBadge,
    stateSubtitle: true,
  },
];

export const SHIPMENT_BOX_ALERTS: {
  name: TAcceptedBoxAlerts;
  iconType: TStateEventIconType;
  Icon: any;
  payload?: {
    key: string;
    unit: string;
    valueTransform?: (input: number) => number;
  };
}[] = [
  {
    name: 'ALERT_TEMPERATURE',
    iconType: 'alert',
    Icon: () => (
      <AlertBadge>
        <TemperatureIcon color={theme.colors.white} />
      </AlertBadge>
    ),
    payload: {
      key: 'temperature',
      unit: '°C',
      valueTransform: (temp: number) => temp / 10,
    },
  },
  {
    name: 'ALERT_BOX_VIOLATION',
    iconType: 'alert',
    Icon: () => (
      <AlertBadge>
        <BoxOpenedIcon color={theme.colors.white} />
      </AlertBadge>
    ),
  },
  {
    name: 'ALERT_HUMIDITY',
    iconType: 'alert',
    Icon: () => (
      <AlertBadge>
        <HumidityIcon color={theme.colors.white} />
      </AlertBadge>
    ),
    payload: {
      key: 'humidity',
      unit: '%',
      valueTransform: (humidity: number) => humidity / 10,
    },
  },
  {
    name: 'ALERT_SHOCK',
    iconType: 'alert',
    Icon: () => (
      <AlertBadge>
        <ShockIcon color={theme.colors.white} />
      </AlertBadge>
    ),
  },
  {
    name: 'ALERT_PRESSURE',
    iconType: 'alert',
    Icon: () => (
      <AlertBadge>
        <BoxPressureIcon color={theme.colors.white} />
      </AlertBadge>
    ),
    payload: {
      key: 'pressure',
      unit: 'hpa',
    },
  },
];

export type TAcceptedEvents = TAcceptedStateEvents | TAcceptedBoxAlerts;

const MAX_EVENTS = 5;

export const eventsReducer = (
  fullList: boolean,
  timelineItems: IShipmentTimelineEntry[]
) => {
  const nEvents = timelineItems.length;
  const showMore = nEvents > MAX_EVENTS;

  if (showMore) {
    return {
      nEvents,
      showMore,
      events: fullList ? timelineItems : timelineItems.slice(0, MAX_EVENTS),
    };
  }

  return { nEvents, showMore, events: timelineItems };
};

export const eventsAnonymousReducer = (
  fullList: boolean,
  timelineEvents: IShipmentAnonymousTimelineEvent[]
) => {
  const nEvents = timelineEvents.length;
  const showMore = nEvents > MAX_EVENTS;

  if (showMore) {
    return {
      nEvents,
      showMore,
      events: fullList ? timelineEvents : timelineEvents.slice(0, MAX_EVENTS),
    };
  }

  return { nEvents, showMore, events: timelineEvents };
};

export const getLineBg = (
  position:
    | 'top'
    | 'topAlert'
    | 'secondLast'
    | 'last'
    | 'middle'
    | 'lastShowMore',
  theme: DefaultTheme
) => {
  switch (position) {
    case 'top':
      return `linear-gradient(to bottom, ${theme.colors.primary[100]}, ${theme.colors.primary[100]})`;
    case 'topAlert':
      return `linear-gradient(to bottom, rgba(37, 182, 118, 0.3), ${theme.colors.primary[100]})`;
    case 'secondLast':
      return `linear-gradient(to bottom, ${theme.colors.primary[100]}, rgba(37, 182, 118, 0.3))`;
    case 'last':
      return 'linear-gradient(to bottom, rgba(37, 182, 118, 0.3), rgba(37, 182, 118, 0))';
    case 'lastShowMore':
      return 'linear-gradient(to bottom, rgba(37, 182, 118, 0.3), rgba(37, 182, 118, 0))';
    default:
      return theme.colors.primary[100];
  }
};

export const getLinePosition = (
  showMore: boolean,
  nEvents: number,
  fullList: boolean,
  index: number
) => {
  if (showMore && !fullList) {
    if (index === nEvents - 1) {
      return 'last';
    }
    if (index === nEvents - 2) {
      return 'secondLast';
    }
  }

  return 'middle';
};
