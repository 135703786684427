import { useCallback } from 'react';
import { useEffect, useState } from 'react';
const DEFAULT_OPTIONS = { enabled: true };
export function useFetchResource(fetchFunction, { enabled = true } = {}) {
    const [state, setState] = useState({
        state: 'idle',
    });
    const startFetch = useCallback(() => {
        setState({ state: 'loading' });
        fetchFunction()
            .then(resource => setState({ state: 'success', resource }))
            .catch(error => setState({ state: 'error', error }));
    }, [fetchFunction]);
    const invalidate = startFetch;
    useEffect(() => {
        if (enabled)
            startFetch();
    }, [enabled, startFetch]);
    return Object.assign(Object.assign({}, state), { invalidate });
}
