import React from 'react';

import { DeployReturn } from './DeployReturn';

export const shipmentDeployReturnRoutes = [
  {
    path: 'deploy-return',
    element: <DeployReturn />,
  },
];
