import React from 'react';

import { Text } from '@livingpackets/design-system-react-next';
import greenDotsLoader from 'assets/lotties/green-dots-loader.json';
import { useTranslation } from 'react-i18next';
import Lottie from 'react-lottie';
import styled from 'styled-components';

const Wrapper = styled.div`
  width: 100%;
  height: auto;
  min-height: 28rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const WrapperIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 52px;
  width: 52px;
  border-radius: 8px;
  margin-right: 12px;
  background-color: ${({ theme }) => theme.colors.white};
`;

const ShipmentProcessing = () => {
  const { t } = useTranslation('shipments');

  return (
    <Wrapper>
      <WrapperIcon>
        <Lottie
          options={{
            loop: true,
            autoplay: true,
            animationData: greenDotsLoader,
            rendererSettings: {
              preserveAspectRatio: 'xMidYMid slice',
            },
          }}
        />
      </WrapperIcon>
      <Text variant="titleS">{t('processingFile')}</Text>
    </Wrapper>
  );
};

export default ShipmentProcessing;
