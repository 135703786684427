import React from 'react';

import { Text } from '@livingpackets/design-system-react-next';
import { useTranslation } from 'react-i18next';

import ProfileShowroom from '../../organisms/ProfileShowroom';

const LPAccountShowroom = () => {
  const { t } = useTranslation('showroom');

  return (
    <>
      <div style={{ width: '100%', marginBottom: '2rem' }}>
        <Text variant="titleXL">{t('showrooms.title')}</Text>
      </div>
      <ProfileShowroom />
    </>
  );
};

export default LPAccountShowroom;
