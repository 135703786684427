import React from 'react';

import { TruckIcon } from '@livingpackets/design-system-react-next';

import { AddressTypes } from '../../../types/address';
import AddressManagement from '../../commons/addresses';

export const Supply = () => (
  <AddressManagement
    addressType={AddressTypes.DELIVERY}
    headerIcon={TruckIcon}
  />
);
