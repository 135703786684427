import React from 'react';

import { PATHS } from 'configs';
import { isMobile } from 'react-device-detect';
import { Navigate } from 'react-router-dom';

export function MobileTrap({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element {
  if (isMobile) {
    return <Navigate to={PATHS.DOWNLOAD_MOBILE_APPS} />;
  }

  return <>{children}</>;
}
