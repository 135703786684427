import React from 'react';

import {
  AddIcon,
  Text,
  ButtonV2 as Button,
  Stack,
  Box,
  styled,
} from '@livingpackets/design-system-react-next';
import figuralLogo from 'assets/img/insurance/figural-logo.svg';
import { useTranslation } from 'react-i18next';

import Modal from '../../atoms/Modal';

const InsuranceFiguralLogo = styled('div', {
  shouldForwardProp: prop => prop !== 'figuralLogo',
})<{
  figuralLogo: string;
}>`
  min-width: 60px;
  height: 52px;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url(${({ figuralLogo }) => figuralLogo});
`;

const AddFiguralInsuranceChoiceModal = ({
  isVisible,
  isShipmentHasReturnLabel,
  isShipmentLabelCreated,
  isFiguralInsuranceCreated,
  onSelectAddFiguralInsurance,
  onSelectContinueWithoutInsurance,
}: {
  isVisible: boolean;
  isShipmentHasReturnLabel: boolean;
  isShipmentLabelCreated: boolean;
  isFiguralInsuranceCreated: boolean;
  onSelectAddFiguralInsurance: () => void;
  onSelectContinueWithoutInsurance: () => void;
}) => {
  const { t } = useTranslation('shipments');

  const getSupportPageLink = () => (
    <a
      href={t('modal.addFiguralInsuranceChoice.warning.supportWebsiteUrl')}
      target="_blank"
      rel="noreferrer"
      style={{ display: 'inline-block' }}
    >
      <Text
        variant="bodyTextS"
        color="custom.primary.100"
        sx={{ textDecoration: 'underline' }}
      >
        {t('modal.addFiguralInsuranceChoice.warning.supportWebsite')}
      </Text>
    </a>
  );

  return (
    <Modal open={isVisible} handleClose={() => {}}>
      <>
        <Text variant="titleM">
          {isFiguralInsuranceCreated &&
          isShipmentLabelCreated &&
          isShipmentHasReturnLabel
            ? t('modal.addFiguralInsuranceChoice.titleShipmentReturn')
            : t('modal.addFiguralInsuranceChoice.title')}
        </Text>
        <Stack direction="row" mt="1.5625rem" alignItems="center">
          <InsuranceFiguralLogo figuralLogo={figuralLogo} />
          <Box>
            <Text
              variant="bodyTextS"
              sx={{
                lineHeight: '20px',
                display: 'inline',
                whiteSpace: 'break-spaces',
              }}
            >
              {t('modal.addFiguralInsuranceChoice.warning.text')}
            </Text>
            {getSupportPageLink()}
          </Box>
        </Stack>
        <Stack direction="row" justifyContent="space-between" mt="2rem">
          <Button
            variant="secondary"
            onClick={onSelectContinueWithoutInsurance}
            data-testid="continue-without-insurance-button"
          >
            {t(
              'modal.addFiguralInsuranceChoice.button.continue-without-insurance'
            )}
          </Button>
          <Button
            icon={AddIcon}
            onClick={onSelectAddFiguralInsurance}
            data-testid="add-figural-insurance-button"
          >
            {isFiguralInsuranceCreated &&
            isShipmentLabelCreated &&
            isShipmentHasReturnLabel
              ? t(
                  'modal.addFiguralInsuranceChoice.button.add-insurance-to-the-shipment-return'
                )
              : t('modal.addFiguralInsuranceChoice.button.add-insurance')}
          </Button>
        </Stack>
      </>
    </Modal>
  );
};

export default AddFiguralInsuranceChoiceModal;
