import React from 'react';

import { Box } from '@livingpackets/design-system-react-next';

import KoalendarContactRequestBlock from './footer/KoalendarContactRequestBlock';
import Languages from './footer/Languages';

interface FooterProps {
  showKoalendar?: boolean;
}

const Footer = ({ showKoalendar = false }: FooterProps) => (
  <Box
    sx={{ padding: '0.625rem' }}
    display="flex"
    flexDirection="column"
    justifyContent="center"
    alignItems="center"
  >
    {showKoalendar && <KoalendarContactRequestBlock />}
    <Languages />
  </Box>
);

export default Footer;
