import React, { useEffect, useMemo, useState } from 'react';

import {
  Box,
  Stack,
  Text,
  LockOnIcon,
  LockOffIcon,
  Chip,
  Grid,
  ButtonV2 as Button,
} from '@livingpackets/design-system-react-next';
import posterBackMovie from 'assets/img/shipment/video_back.png';
import posterReceiveMovie from 'assets/img/shipment/video_receive.png';
import safariIcon from 'assets/safari.svg';
import { REACT_APP_APP_CLIP_BUNDLE } from 'configs';
import { ProductTypeEnum } from 'enums/ProductEnum';
import usePublic from 'hooks/usePublic';
import { useShipmentAddByTrackingCode } from 'hooks/useShipmentAddByTrackingCode';
import useShipmentAnonymousDetail from 'hooks/useShipmentAnonymousDetail';
import useShipmentDetail from 'hooks/useShipmentDetail';
import {
  IShipmentAnonymousData,
  ShipmentContractStateEnum,
} from 'models/shipment';
import {
  isAndroid,
  isChrome,
  isIOS,
  isMobile,
  isSafari,
} from 'react-device-detect';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import useAppState, {
  anonymousTokenSelector,
  dispatch,
} from 'stores/appState/useAppState';
import { getProductType } from 'utils/product';

import { BrowserWarning } from './BrowserWarning';
import { ShipmentDetailMapAdresses } from './ShipmentDetailMapAdresses';
import { Support } from './support/Support';
import { VideoCard } from './VideoCard';
import { useRedirectToRetrieveKeyPage } from '../hook/useRedirectToRetrieveKeyPage';

const LPvideos = {
  receive: {
    poster: posterReceiveMovie,
    movie:
      'https://assets.livingpackets.com/LV/UnBOXing_LV-Réception_de_votre_commande.mp4',
  },
  back: {
    poster: posterBackMovie,
    movie:
      'https://assets.livingpackets.com/LV/UnBOXing_LV-Retour_d_article.mp4',
  },
};

export const ShipmentDetail = ({
  curruentShipmentData,
}: {
  curruentShipmentData?: IShipmentAnonymousData;
}) => {
  const { t } = useTranslation(['shipments', 'general']);

  const navigate = useNavigate();

  const { trackingCode } = useParams<{ trackingCode: string }>();
  const anonymousToken = useAppState(anonymousTokenSelector);
  const { getShipmentAnonymousDetail } = useShipmentAnonymousDetail();
  const shipmentAddByTrackingCode = useShipmentAddByTrackingCode();
  const [shipmentData, setShipmentData] = useState<
    IShipmentAnonymousData | undefined
  >(curruentShipmentData);
  const [openWarningModal, setOpenWarningModal] = useState<boolean>(false);

  const isSpecificPartner = [
    process.env.REACT_APP_LOUIS_VUITTON_PARTNER_ID,
    process.env.REACT_APP_LOUIS_VUITTON_COMPANY_PARTNER_ID,
  ].includes(shipmentData?.shipment.partner_id);

  const redirectToRetrieveKeyPage = useRedirectToRetrieveKeyPage();

  const { postSignup } = usePublic();

  useEffect(() => {
    postSignup().then(({ success, tokenResponse }: any) => {
      if (success && tokenResponse) {
        dispatch({
          type: 'SET_ANONYMOUS_TOKEN',
          payload: tokenResponse.accessToken,
        });
      }
    });
  }, [postSignup]);

  useEffect(() => {
    if (!shipmentData && anonymousToken && trackingCode) {
      const getAnonymousShipment = async () => {
        const { success: successShipment, shipment } =
          await shipmentAddByTrackingCode(trackingCode);

        if (!successShipment) {
          redirectToRetrieveKeyPage();
        } else {
          if (shipment?.id) {
            const { shipmentData } = await getShipmentAnonymousDetail(
              shipment?.id
            );

            if (shipmentData) {
              if (
                shipmentData?.shipment.contract_state === 'PURCHASED' ||
                shipmentData?.shipment.contract_state === 'TRANSFERRED'
              ) {
                navigate('/404');

                return;
              }
              setShipmentData(shipmentData);
            } else {
              navigate('/404');
            }
          }
        }
      };

      getAnonymousShipment();
    }
  }, [
    anonymousToken,
    getShipmentAnonymousDetail,
    navigate,
    shipmentAddByTrackingCode,
    shipmentData,
    trackingCode,
    redirectToRetrieveKeyPage,
  ]);

  useEffect(() => {
    if (shipmentData) {
      const btnConfirmDelivery =
        document.getElementsByClassName('confirmDelivery')[0]; // Simulate click on CTA Confirm Delivery
      if (isIOS && isSafari && btnConfirmDelivery) {
        setTimeout(() => {
          (btnConfirmDelivery as any).click();
        }, 500);
      }
    }

    if ((isIOS && !isSafari) || (isAndroid && !isChrome)) {
      setOpenWarningModal(true);
    }
  }, [shipmentData]);

  const isArrived =
    shipmentData?.shipment.contract_state === ShipmentContractStateEnum.ARRIVED;

  const { getCarrierLogo } = useShipmentDetail();

  const logo = useMemo(() => {
    if (shipmentData) {
      return getCarrierLogo(shipmentData.shipment.carrier_code);
    }
  }, [getCarrierLogo, shipmentData]);

  const constractState = () =>
    isArrived
      ? t('shipments:anonymous.mobile.contractState.delivered')
      : t('shipments:anonymous.mobile.contractState.ongoing');

  const lockStatus = () => {
    const productType = getProductType(
      shipmentData?.shipment.product.article_code
    );

    return isArrived
      ? t('shipments:anonymous.mobile.lockStatus.unlock')
      : ProductTypeEnum.TABLET !== productType
      ? t('shipments:anonymous.mobile.lockStatus.lock')
      : ' N/A';
  };

  const videoCards = [
    {
      title: t('shipments:anonymous.mobile.titleCardReceive'),
      videoSource: LPvideos.receive.movie,
      description: t('shipments:anonymous.mobile.descriptionCardReceive'),
      poster: LPvideos.receive.poster,
    },
    {
      title: t('shipments:anonymous.mobile.titleCardReturn'),
      videoSource: LPvideos.back.movie,
      description: t('shipments:anonymous.mobile.descriptionCardReturn'),
      poster: LPvideos.back.poster,
    },
  ];

  const handleClickDelivery = () => {
    if (isIOS && isSafari) {
      window.location.href = `https://appclip.apple.com/id?p=${REACT_APP_APP_CLIP_BUNDLE}&delivery_key=${trackingCode}`;
    } else {
      navigate('delivery');
    }
  };

  return (
    /* CONTAINER */

    <Stack
      sx={{
        background: isMobile
          ? 'custom.neutral.black.3'
          : 'custom.neutral.white.pure',
        overflowY: isMobile ? 'scroll' : 'auto',
      }}
      gap="1.5rem"
      height="100%"
      pb={isMobile ? '6rem' : 'none'}
    >
      {/* HEADER BLOCK */}
      <Box
        pt="1.125rem"
        pb="1rem"
        px={isMobile ? '1rem' : 'none'}
        bgcolor="custom.neutral.white.pure"
      >
        {isMobile && (
          <Text variant="titleM" py=".5625rem" mb="1rem">
            {t('shipments:anonymous.mobile.titleView')}
          </Text>
        )}

        {/* TAG */}
        <Stack gap=".5rem" direction="row" paddingX="1rem">
          <Chip label={constractState()} state="default" type="tags" />
          <Chip
            label={lockStatus()}
            type="tags"
            icon={
              isArrived ? (
                <LockOffIcon style={{ height: '1.5rem', width: '1.5rem' }} />
              ) : (
                <LockOnIcon style={{ height: '1.5rem', width: '1.5rem' }} />
              )
            }
            state="default"
          />
        </Stack>
      </Box>

      {/* BODY CONTENT */}
      {isMobile && ((isIOS && !isSafari) || (isAndroid && !isChrome)) && (
        <Stack
          p=".875rem"
          mx="1rem"
          borderRadius="0.75rem"
          sx={{
            backgroundColor: 'custom.secondary.yellow.10',
          }}
        >
          <Text
            variant="titleL"
            pb="0.625rem"
            textAlign="start"
            color="custom.secondary.yellow.100"
          >
            {t(
              'shipments:anonymous.mobile.navigatorError.warningDeviceTitleMessage'
            )}
          </Text>
          <Stack direction="row" spacing={1} alignItems="center">
            <img
              src={safariIcon}
              alt="safari Icon"
              style={{
                width: '2.5rem',
                height: '2.5rem',
              }}
            />

            <Text variant="bodyTextL">
              <Trans
                t={t}
                i18nKey="shipments:anonymous.mobile.navigatorError.warningDeviceMessage"
                components={{
                  1: (
                    <span
                      style={{
                        color: 'custom.secondary.yellow.100',
                        fontSize: '1rem',
                        fontWeight: 'bold',
                      }}
                    />
                  ),
                }}
                values={{
                  browser: isIOS
                    ? t('general:browserSafari')
                    : t('general:browserChrome'),
                }}
              />
            </Text>
          </Stack>
        </Stack>
      )}
      {/* Video BLOCK */}
      {isSpecificPartner ? (
        <Grid container spacing={2}>
          {videoCards.map((videoCard, index) => (
            <Grid item key={index} mobile={12} tablet={6}>
              <VideoCard dataSource={videoCard} />
            </Grid>
          ))}
        </Grid>
      ) : (
        <ShipmentDetailMapAdresses shipmentData={shipmentData} logo={logo} />
      )}
      {/* SUPPORT BLOCK Mobile version*/}
      {isMobile && <Support partnerId={shipmentData?.shipment.partner_id} />}

      {isMobile && shipmentData?.shipment.contract_state === 'DEPLOYED' && (
        <Stack
          p="1rem"
          position="fixed"
          bottom="0"
          width="100%"
          sx={{
            backgroundColor: 'custom.neutral.white.pure',
          }}
        >
          <Button
            fullWidth
            className="confirmDelivery"
            onClick={handleClickDelivery}
            disabled={(isIOS && !isSafari) || (isAndroid && !isChrome)}
          >
            {isIOS
              ? t('shipments:anonymous.mobile.cta.btnConfirmDeliveryStart')
              : t('shipments:anonymous.mobile.cta.btnConfirmDelivery')}
          </Button>
        </Stack>
      )}

      <BrowserWarning
        openWarningModal={openWarningModal}
        handleClick={() => {
          setOpenWarningModal(!openWarningModal);
        }}
      />
    </Stack>
  );
};
