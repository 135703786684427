import React, { useContext, useEffect, useMemo, useState } from 'react';

import {
  ContactIcon,
  Grid,
  PhoneNumber,
  Stack,
  Text,
  ButtonV2 as Button,
  Box,
  Switch,
  NewTooltip as Tooltip,
} from '@livingpackets/design-system-react-next';
import { ShipmentEditionContext } from 'components/organisms/ImportShipmentFlow';
import { normalizeKey } from 'helpers/i18n';
import { isEqual } from 'lodash/fp';
import get from 'lodash/get';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { phoneNumber } from 'schemas/common';
import { shipmentLabelAddressSchema } from 'schemas/shipmentSchema';

import { AddressBookButtonTooltip } from './AddressBookTooltipButton';
import { SaveToAddressBook } from './SaveToAddressBook';
import { ContactAddressType } from '../../../models/contactAddress';
import Input from '../../atoms/Input';
import SearchContactAddressModal from '../../molecules/modals/SearchContactAddressModal';
import ContactAddressTypeSelector from '../../organisms/ContactAddressTypeSelector';
import CountrySelector from '../../organisms/CountrySelector';

const AddressForm = ({
  defaultContact,
  isSender,
  isDefaultAddressChecked,
  setIsDefaultAddressChecked,
  hasDefaultAddress,
  loadDefaultAddress,
  resetShippingAddress,
  getFieldNameWithPrefix,
  resetField,
  prefix,
  register,
  watch,
  control,
  errors,
  touchedFields,
  senderAddress,
  recipientAddress,
  senderPhoneNumber,
  recipientPhoneNumber,
  smsEnabled,
  handleOpenSearchContactAddressModal,
  showSearchContactAddressModal,
  setShowSearchContactAddressModal,
  handleSelectAddress,
  handleSelectContact,
  handleInputTypeChange,
}: any) => {
  const { t } = useTranslation(['shipments', 'address', 'contactAddress']);

  const values = watch();

  const { isEditingCreationShipment } = useContext(ShipmentEditionContext);

  const [saveToAddressBookIsChecked, setSaveToAddressBookIsChecked] =
    useState(false);

  const isAddressValid = useMemo(() => {
    const address = values[isSender ? 'senderAddress' : 'recipientAddress'];
    const phoneNb =
      values[isSender ? 'senderPhoneNumber' : 'recipientPhoneNumber'];

    let isAddressValid = false;
    try {
      shipmentLabelAddressSchema().validateSync(address);

      isAddressValid = true;
    } catch (_) {}

    let isPhoneNumberValid = smsEnabled ? false : true;
    try {
      phoneNumber().validateSync(phoneNb);

      isPhoneNumberValid = true;
    } catch (_) {}

    return isAddressValid && isPhoneNumberValid;
  }, [values, isSender, smsEnabled]);

  const baseValues = values[isSender ? 'senderAddress' : 'recipientAddress'];

  // Set to false the default address if any changes happens in the form
  // (compare the new values with the values from the default address)
  useEffect(() => {
    const {
      city,
      company,
      countryCode,
      email,
      firstName,
      lastName,
      postalCode,
      street,
      type,
    } = baseValues;

    const possiblyModifiedContact = {
      city,
      company,
      countryCode,
      email,
      firstName,
      lastName,
      postalCode,
      street,
      type,
      phoneNumber: isSender
        ? values.senderPhoneNumber
        : values.recipientPhoneNumber,
    };

    // Only uncheck if values are different from the default contact
    if (!isEqual(possiblyModifiedContact, defaultContact)) {
      if (defaultContact === undefined) return;
      // Uncheck "Use default address"
      setIsDefaultAddressChecked(false);

      // Uncheck "Save this address"
      setSaveToAddressBookIsChecked(false);
    } else {
      // ReCheck "Use default address" if form got same state as default contact
      setIsDefaultAddressChecked(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    baseValues.city,
    baseValues.company,
    baseValues.countryCode,
    baseValues.email,
    baseValues.firstName,
    baseValues.lastName,
    baseValues.postalCode,
    baseValues.street,
    baseValues.type,
    values.senderPhoneNumber,
    values.recipientPhoneNumber,
    defaultContact,
    isEditingCreationShipment,
  ]);

  const defaultAddressSwitch = (
    <Switch
      label={t(
        normalizeKey(
          `shipments:form.${isSender ? 'sender' : 'recipient'}.useDefault`
        )
      )}
      checked={isDefaultAddressChecked}
      disabled={!hasDefaultAddress}
      onChange={event => {
        if (event.target.checked) {
          setIsDefaultAddressChecked(true);
          loadDefaultAddress();
        } else {
          setIsDefaultAddressChecked(false);
          // Clear the form
          resetShippingAddress(
            resetField,
            getFieldNameWithPrefix,
            isSender,
            prefix
          );
        }
      }}
    />
  );

  return (
    <>
      {/* Use default address + Address Book Button */}
      <Stack
        direction="row"
        justifyContent="space-between"
        gap="1rem"
        mb="1rem"
      >
        <Box
          display="flex"
          alignItems="center"
          flexGrow={1}
          bgcolor="custom.neutral.black.2"
          sx={{
            borderRadius: '0.5rem',
            padding: '4px 14px',
            opacity: hasDefaultAddress ? '100%' : '40%',
          }}
        >
          {hasDefaultAddress ? (
            defaultAddressSwitch
          ) : (
            <Tooltip
              title={
                <Stack gap="0.5rem">
                  <Text variant="titleM">
                    {t(
                      normalizeKey(
                        `shipments:form.${
                          isSender ? 'sender' : 'recipient'
                        }.tooltip.title`
                      )
                    )}
                  </Text>
                  <Text variant="bodyTextS">
                    {t(
                      normalizeKey(
                        `shipments:form.${
                          isSender ? 'sender' : 'recipient'
                        }.tooltip.description`
                      )
                    )}
                  </Text>
                </Stack>
              }
            >
              {defaultAddressSwitch}
            </Tooltip>
          )}
        </Box>
        {/* Search contact in the modal with the address book */}
        <Tooltip
          sx={{ maxWidth: '14.4375rem' }}
          title={<AddressBookButtonTooltip />}
        >
          <Button
            variant="primary"
            icon={ContactIcon}
            onClick={handleOpenSearchContactAddressModal}
            data-testid={
              isSender
                ? 'open-contact-address-modal-sender-button'
                : 'open-contact-address-modal-recipient-button'
            }
          />
        </Tooltip>
      </Stack>
      {/* 
          Begining of the form
         */}
      <ContactAddressTypeSelector
        prefix={prefix}
        register={register}
        fieldName="type"
        watch={watch}
        onValueChanged={handleInputTypeChange}
      />
      <Input
        required
        name={getFieldNameWithPrefix(prefix, 'firstName')}
        label={
          get(touchedFields, getFieldNameWithPrefix(prefix, 'firstName'), false)
            ? t('address:firstName.label')
            : undefined
        }
        placeholder={t('address:firstName.placeholder')}
        error={get(errors, getFieldNameWithPrefix(prefix, 'firstName'), false)}
        isTouched={get(
          touchedFields,
          getFieldNameWithPrefix(prefix, 'firstName'),
          false
        )}
        register={register}
        mt="0.3rem"
        width="100%"
      />
      <Input
        required
        label={
          get(touchedFields, getFieldNameWithPrefix(prefix, 'lastName'), false)
            ? t('address:lastName.label')
            : undefined
        }
        placeholder={t('address:lastName.placeholder')}
        name={getFieldNameWithPrefix(prefix, 'lastName')}
        error={get(errors, getFieldNameWithPrefix(prefix, 'lastName'), false)}
        isTouched={get(
          touchedFields,
          getFieldNameWithPrefix(prefix, 'lastName'),
          false
        )}
        register={register}
        width="100%"
      />
      <Input
        required
        mt="0.3rem"
        label={
          get(touchedFields, getFieldNameWithPrefix(prefix, 'company'), false)
            ? t('address:companyName.label')
            : undefined
        }
        placeholder={t('address:companyName.placeholder')}
        name={getFieldNameWithPrefix(prefix, 'company')}
        error={get(errors, getFieldNameWithPrefix(prefix, 'company'), false)}
        isTouched={get(
          touchedFields,
          getFieldNameWithPrefix(prefix, 'company'),
          false
        )}
        disabled={
          watch(getFieldNameWithPrefix(prefix, 'type')) ===
          ContactAddressType.personal
        }
        register={register}
        width="100%"
      />
      <Input
        required
        mt="0.3rem"
        label={
          get(touchedFields, getFieldNameWithPrefix(prefix, 'email'), false)
            ? t('address:notificationEmail.label')
            : undefined
        }
        placeholder={t('address:notificationEmail.placeholder')}
        name={getFieldNameWithPrefix(prefix, 'email')}
        error={get(errors, getFieldNameWithPrefix(prefix, 'email'), false)}
        isTouched={get(
          touchedFields,
          getFieldNameWithPrefix(prefix, 'email'),
          false
        )}
        register={register}
        width="100%"
      />
      {/* Special PhoneNumber for smsEnabled ONLY */}
      {smsEnabled && (
        <Controller
          name={isSender ? 'senderPhoneNumber' : 'recipientPhoneNumber'}
          control={control}
          render={({ field, fieldState }) => (
            <PhoneNumber
              fullWidth
              label={t('contactAddress:form.phoneNumber.placeholder')}
              defaultCountryCode="FR"
              error={fieldState.invalid}
              helperText={fieldState.error?.message}
              {...field}
            />
          )}
        />
      )}
      <br />
      <Text variant="bodyTextS" color="custom.neutral.black.50" mb="12px">
        {isSender
          ? t('shipments:senderAddress')
          : t('shipments:recipientAddress')}
      </Text>
      <Input
        required
        label={
          get(touchedFields, getFieldNameWithPrefix(prefix, 'street'), false)
            ? t('address:street.label')
            : undefined
        }
        placeholder={t('address:street.placeholder')}
        name={getFieldNameWithPrefix(prefix, 'street')}
        error={get(errors, getFieldNameWithPrefix(prefix, 'street'), false)}
        isTouched={get(
          touchedFields,
          getFieldNameWithPrefix(prefix, 'street'),
          false
        )}
        register={register}
        width="100%"
      />
      <Grid container spacing="0.5rem">
        <Grid item mobile={8}>
          <Input
            required
            label={
              get(touchedFields, getFieldNameWithPrefix(prefix, 'city'), false)
                ? t('address:city.label')
                : undefined
            }
            placeholder={t('address:city.placeholder')}
            name={getFieldNameWithPrefix(prefix, 'city')}
            error={get(errors, getFieldNameWithPrefix(prefix, 'city'), false)}
            isTouched={get(
              touchedFields,
              getFieldNameWithPrefix(prefix, 'city'),
              false
            )}
            register={register}
            width="100%"
          />
        </Grid>
        <Grid item mobile={4}>
          <Input
            required
            label={
              get(
                touchedFields,
                getFieldNameWithPrefix(prefix, 'postalCode'),
                false
              )
                ? t('address:postalCode.label')
                : undefined
            }
            placeholder={t('address:postalCode.placeholder')}
            name={getFieldNameWithPrefix(prefix, 'postalCode')}
            error={get(
              errors,
              getFieldNameWithPrefix(prefix, 'postalCode'),
              false
            )}
            isTouched={get(
              touchedFields,
              getFieldNameWithPrefix(prefix, 'postalCode'),
              false
            )}
            register={register}
            width="100%"
          />
        </Grid>
      </Grid>
      <CountrySelector
        id={
          prefix
            ? `${prefix.replace('.', '_')}countryCodeSelector`
            : 'countryCodeSelector'
        }
        prefix={prefix}
        control={control}
        rules={{ required: true }}
        width="100%"
        register={register}
      />
      <SaveToAddressBook
        disabled={isDefaultAddressChecked || !isAddressValid}
        isSender={isSender}
        contact={
          isSender
            ? { ...senderAddress, phoneNumber: senderPhoneNumber }
            : { ...recipientAddress, phoneNumber: recipientPhoneNumber }
        }
        onSave={isDefaultAddress => {
          if (isDefaultAddress) {
            setIsDefaultAddressChecked(true);
          }
        }}
        isChecked={saveToAddressBookIsChecked}
        setIsChecked={setSaveToAddressBookIsChecked}
      />
      <SearchContactAddressModal
        visible={showSearchContactAddressModal}
        onDismiss={() => setShowSearchContactAddressModal(false)}
        cardHeaderSubTitleText={
          isSender
            ? t('shipments:form.sender.modalSubTitle')
            : t('shipments:form.recipient.modalSubTitle')
        }
        cardHeaderValidButtonText={
          isSender
            ? t('shipments:form.sender.validButton')
            : t('shipments:form.recipient.validButton')
        }
        onAddressSelect={handleSelectAddress}
        onContactSelect={handleSelectContact}
      />
    </>
  );
};

export default AddressForm;
