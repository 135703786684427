import React from 'react';

import { EmptyState, Text } from '@livingpackets/design-system-react-next';
import { useTranslation } from 'react-i18next';

export const ErrorEmptyState = () => {
  const { t } = useTranslation('account');

  return (
    <EmptyState type="Results">
      <>
        <Text variant="titleM" color="custom.neutral.black.50">
          {t('error.title')}
        </Text>
        <Text variant="bodyTextL" color="custom.neutral.black.50">
          {t('error.message')}
        </Text>
      </>
    </EmptyState>
  );
};
