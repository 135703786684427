import React from 'react';

import { ShipmentTimelineEventTypeEnum } from 'enums/ShipmentTimelineEnum';
import { getLineBg, getLinePosition } from 'helpers/shipmentEventsHelpers';
import useShipmentDetail from 'hooks/useShipmentDetail';
import { IShipmentTimelineEntry } from 'models/shipment';
import styled, { DefaultTheme, ThemeProps, withTheme } from 'styled-components';

export interface ITimeLineIcon {
  index: number;
  event: IShipmentTimelineEntry;
  nEvents: number;
  showLastLine?: (index: number) => boolean;
  showMore: boolean;
  fullList: boolean;
}

const Line = styled.div<{
  bg: string;
  top?: boolean;
  lineMiddleSize?: boolean;
  reverse?: boolean;
}>`
  width: 2px;
  height: ${({ lineMiddleSize }) => (lineMiddleSize ? '50%' : '100%')};
  background: ${({ bg }) => bg};
  margin-left: auto;
  margin-right: auto;
  position: absolute;
  left: 7px;
  ${props =>
    props.reverse &&
    `
    bottom: 0;
    `};

  ${props =>
    !props.reverse &&
    `
        top: 0;
    `};
`;

const Dot = styled.div`
  height: 16px;
  width: 16px;
  border: 5px solid ${({ theme }) => theme.colors.white};
  border-radius: 50%;
  margin-left: auto;
  margin-right: auto;
`;

const RedDot = styled(Dot)`
  background: ${({ theme }) => theme.colors.red[100]};
`;

const GreenDot = styled(Dot)`
  background: ${({ theme }) => theme.colors.primary[100]};
`;

const WhiteDot = styled(Dot)<{ reverse?: boolean }>`
  background: ${({ theme }) => theme.colors.white};
  border: 1.5px solid ${({ theme }) => theme.colors.primary[100]};
  width: 12px;
  height: 12px;
  margin-left: 2px;
  margin-top: 6px;

  ${props =>
    !props.reverse &&
    `
      margin-left: 2px;
    `};
`;

const GreyDot = styled(Dot)`
  background: ${({ theme }) => theme.colors.black[50]};
`;

const LineContainer = styled.div`
  height: 100%;
`;

const DotContainer = styled.div`
  position: absolute;
  top: 33px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
`;

const TimeLineDot = ({
  index,
  event,
  theme,
  showMore,
  fullList,
  nEvents,
}: ITimeLineIcon & ThemeProps<DefaultTheme>) => {
  const { isShipmentTimelineItemAlert, isAlertClosed } = useShipmentDetail();

  return (
    <div style={{ height: '100%' }}>
      {nEvents > 1 && (
        <LineContainer>
          {isShipmentTimelineItemAlert(event) && index === 0 && (
            <Line
              bg={getLineBg('topAlert', theme)}
              top={true}
              data-testid="topLine"
            />
          )}
          {isShipmentTimelineItemAlert(event) && index !== 0 && (
            <Line
              bg={getLineBg(
                getLinePosition(showMore, nEvents, fullList, index),
                theme
              )}
              top={true}
              data-testid="midLine"
            />
          )}
          {!isShipmentTimelineItemAlert(event) && (
            <>
              <Line
                bg={getLineBg('top', theme)}
                lineMiddleSize={true}
                reverse={index === 0}
                data-testid={
                  index === 0
                    ? 'topLine'
                    : index + 1 === nEvents
                    ? 'bottomLine'
                    : 'midLine'
                }
              />
            </>
          )}
        </LineContainer>
      )}
      <DotContainer>
        {isShipmentTimelineItemAlert(event) && (
          <>
            {event.eventType ===
            ShipmentTimelineEventTypeEnum.ALERT_BOX_CODE ? (
              <RedDot data-testid="eventIcon" data-testvalue="redDot" />
            ) : isAlertClosed(event) ? (
              <GreyDot data-testid="eventIcon" data-testvalue="greyDot" />
            ) : (
              <RedDot data-testid="eventIcon" data-testvalue="redDot" />
            )}
          </>
        )}
        {!isShipmentTimelineItemAlert(event) &&
          (index === 0 || index + 1 === nEvents) && (
            <div style={{ zIndex: 1 }}>
              <WhiteDot
                data-testid="eventIcon"
                data-testvalue="whiteDot"
                reverse={index === 0}
              />
            </div>
          )}
        {!isShipmentTimelineItemAlert(event) &&
          index !== 0 &&
          index + 1 !== nEvents && (
            <div style={{ zIndex: 1 }}>
              <GreenDot data-testid="eventIcon" data-testvalue="greenDot" />
            </div>
          )}
      </DotContainer>
    </div>
  );
};

export default withTheme(TimeLineDot);
