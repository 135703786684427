import { useImperativeRequestHook } from '@livingpackets/shared-components';
import { APIServices, TAPIService } from 'configs';
import useAppState, { tokenSelector } from 'stores/appState/useAppState';

const useImperativeRequest = (service: TAPIService) => {
  const token = useAppState(tokenSelector);

  return useImperativeRequestHook({ url: APIServices[service], token: token });
};

export default useImperativeRequest;
