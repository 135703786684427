import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  *, *:before, *:after {
    box-sizing: border-box;
  }
  html {
    height: 100%;
  }
  body {
    ${({ theme }) => theme.fontStyles.bodyTextM}
    color: ${({ theme }) => theme.colors.black[100]};
    background: ${({ theme }) => theme.colors.white};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    height: auto;
    margin: 0;
    min-height: 100%;
    position: relative;
    @media only screen and (min-width: 768px) {
      overflow: hidden;
    }
  }

  img {
    max-width: 100%;
    height: auto;
  }
  a {
    text-decoration: none;
  }
  ul {
    list-style: none;
    padding-left: 0;
  }
  #root {
  overflow-wrap: break-word;
  overflow: hidden;
  }
  .WSNowrap {
    white-space: nowrap;
  }

  * {
    font-family: inherit;
  }
`;

export default GlobalStyle;
