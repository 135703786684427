import React from 'react';

import ShipmentMap from 'components/molecules/ShipmentMap';
import { IShipment, ITrackingHistoryResponse } from 'models/shipment';

import Modal, { IModalWrapperType } from '../../atoms/Modal';

interface IViewShipmentMapFullScreenModal {
  visible: boolean;
  onDismiss: () => void;
  shipment?: IShipment;
  currenTrackingData?: ITrackingHistoryResponse;
  hideMapFullScreenModal: () => void;
}

const ShipmentMapFullScreenModal = ({
  visible,
  onDismiss,
  shipment,
  currenTrackingData,
  hideMapFullScreenModal,
}: IViewShipmentMapFullScreenModal) => (
  <Modal
    open={visible}
    handleClose={onDismiss}
    wrapperType={IModalWrapperType.fullScreen}
  >
    {currenTrackingData && shipment && (
      <ShipmentMap
        trackingData={currenTrackingData}
        shipment={shipment}
        isFullScreen={true}
        hideMapFullScreenModal={hideMapFullScreenModal}
      />
    )}
  </Modal>
);

export default ShipmentMapFullScreenModal;
