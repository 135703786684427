import * as pdfjs from 'pdfjs-dist';

export async function pdfToDataUrl(sourcePdf: string): Promise<string[]> {
  // Loaded via <script> tag, create shortcut to access PDF.js exports.

  // The workerSrc property shall be specified.
  pdfjs.GlobalWorkerOptions.workerSrc =
    'https://cdn.jsdelivr.net/npm/pdfjs-dist@3.7.107/build/pdf.worker.js';

  // Using DocumentInitParameters object to load binary data.
  try {
    let previewUrls: string[] = [];
    const pdf = await pdfjs.getDocument({ data: sourcePdf }).promise;
    const canvas = document.createElement('canvas');
    for (let i = 1; i <= pdf.numPages; i++) {
      // Fetch page
      const page = await pdf.getPage(i);

      const longSide = Math.max(page.view[2], page.view[3]);

      // Ensure the long side is at least 2250px. This aims to get the
      // label short side to be at least 900px, assuming label takes 2/5 of the
      // long side.
      let outputScale = (2.5 * longSide * window.devicePixelRatio) / 900;

      if (outputScale > 4) {
        outputScale = 4;
      }

      const viewport = page.getViewport({ scale: outputScale });

      // Prepare canvas using PDF page dimensions
      const context = canvas.getContext('2d') as CanvasRenderingContext2D;
      canvas.height = viewport.height;
      canvas.width = viewport.width;

      // Render PDF page into canvas context
      const renderContext = {
        canvasContext: context,
        viewport: viewport,
      };
      await page.render(renderContext).promise;
      previewUrls.push(canvas.toDataURL());
    }
    canvas.remove();

    return previewUrls;
  } catch (reason) {
    // PDF loading error
    console.error(reason);
    throw reason;
  }
}
