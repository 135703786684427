import { useMutation } from '@tanstack/react-query';
import useImperativeRequest from 'hooks/useImperativeRequest';
import useToastMessages from 'hooks/useToastMessages';
import { MutationConfig, queryClient } from 'lib/react-query';
import { filterValues } from 'utils/filterEditableValues';

import { Partnership } from '../../types/partnership';

export type FormValues = {
  description?: string;
  internal?: boolean;
  partner_id_card: {
    country_code?: string;
    vat_number?: string;
    company_id?: string;
    email?: string;
    website?: string;
  };
  phone_number?: string;
};

export const formKeys = [
  'description',
  'internal',
  'partner_id_card.country_code',
  'partner_id_card.vat_number',
  'partner_id_card.company_id',
  'partner_id_card.email',
  'partner_id_card.website',
  'phone_number',
];

const updatePartnership =
  ({
    initialValues,
    makeRequest,
  }: {
    initialValues?: FormValues;
    makeRequest: any;
  }) =>
  async ({
    values,
    partnershipId,
  }: {
    values: Partial<FormValues>;
    partnershipId: string;
  }): Promise<Partnership> => {
    let filteredValues: Partial<FormValues> = values;
    if (initialValues) {
      filteredValues = filterValues<Partial<FormValues>>({
        initialValues,
        values,
        formKeys,
      });
    }

    const { data, error } = await makeRequest({
      path: `me/partners/${partnershipId}`,
      method: 'patch',
      body: filteredValues,
    });

    if (!data || error) {
      throw new Error(error);
    }

    return data;
  };

type UseUpdatePartnershipOptions = {
  initialValues?: FormValues;
  showSuccessToast?: boolean;
  showErrorsToast?: boolean;
  config?: MutationConfig<ReturnType<typeof updatePartnership>>;
};

export const useUpdatePartnership = ({
  initialValues,
  showSuccessToast = true,
  showErrorsToast = true,
  config,
}: UseUpdatePartnershipOptions = {}) => {
  const { error: toastError, success: toastSuccess } = useToastMessages();
  const [, makeRequest] = useImperativeRequest('lpVillageV3');

  return useMutation({
    onError: () => {
      if (showErrorsToast) toastError('messages:updateProfileError.message');
    },
    onSuccess: data => {
      queryClient.setQueryData(['partnership', data.id], data);
      if (showSuccessToast)
        toastSuccess('messages:updateProfileSuccess.message');
    },
    ...config,
    mutationFn: updatePartnership({
      initialValues,
      makeRequest,
    }),
  });
};
