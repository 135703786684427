import i18next from 'i18next';
import * as yup from 'yup';

export const partnershipInformationSchema = () =>
  yup.object().shape({
    companyName: yup
      .string()
      .required(
        i18next.t(
          'authentication:addPartnershipInformation.form.companyName.validation.required'
        )
      ),
  });
