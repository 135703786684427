import { useCallback, useEffect, useState } from 'react';

import useImperativeRequest from 'hooks/useImperativeRequest';

type Path = { longitude: number; latitude: number }[];
type GMapPath = { lat: number; lng: number }[];

const formatToGMapsPath = (path: Path): GMapPath =>
  path.map(c => ({ lat: c.latitude, lng: c.longitude }));

const useGetShipmentGeoPath = (shipmentId: string) => {
  const [{ loading }, makeRequestTrackingV3] =
    useImperativeRequest('trackingV3');
  const [geoPath, setGeoPath] = useState<GMapPath>([]);

  const getGeoPath = useCallback(async () => {
    const { error, data } = await makeRequestTrackingV3({
      path: `me/shipments/${shipmentId}/geo-path`,
      method: 'GET',
    });

    if (error) {
      throw new Error('Error getting geo path');
    }

    return formatToGMapsPath(data.coordinates);
  }, [shipmentId, makeRequestTrackingV3]);

  useEffect(() => {
    getGeoPath().then(resp => setGeoPath(resp));
  }, [getGeoPath]);

  return { loading, geoPath };
};
export default useGetShipmentGeoPath;
