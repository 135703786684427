import React from 'react';

import {
  SenderAddressIcon,
  RecipientAddressIcon,
  Stack,
} from '@livingpackets/design-system-react-next';
import { DetailsCard, DetailsSpan } from 'components/atoms/ShipmentAtoms';
import {
  AddressAnonymous,
  AddressNameAnonymous,
} from 'components/molecules/AddressAnonymous';
// @ts-ignore
import { FlexDirectionProperty } from 'csstype';
import { IShipmentAnonymousData } from 'models/shipment';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { flexbox, FlexboxProps, space, SpaceProps } from 'styled-system';

const Wrapper = styled.div<SpaceProps>`
  display: flex;
  flex-direction: column;
  ${space}
`;

interface IShipmentAddress {
  shipmentData: IShipmentAnonymousData;
  titleTransKey?: string;
  blockDirection?: FlexDirectionProperty;
}

const Row = styled.div<FlexboxProps & SpaceProps>`
  display: flex;
  ${flexbox}
  ${space}
`;

const IconCard = styled.div`
  height: 45px;
  width: 45px;
  min-width: 45px;
  border-radius: ${({ theme }) => theme.borderRadii.xs};
  background: ${({ theme }) => theme.colors.black[4]};
  margin-right: 16px;
  text-align: center;
  line-height: 60px;
`;

const ShipmentAnonymousAddressCard = ({
  shipmentData,
  titleTransKey,
  blockDirection = 'column',
  ...rest
}: IShipmentAddress & SpaceProps) => {
  const { t } = useTranslation('shipments');

  return (
    <Wrapper {...rest}>
      <DetailsSpan>
        <Trans t={t}>
          {titleTransKey ? titleTransKey : 'detail.address.title'}
        </Trans>
      </DetailsSpan>
      <DetailsCard
        padding="20px"
        mt="12px"
        fontSize="12px"
        lineHeight="19px"
        style={{ borderRadius: '10px', flexDirection: blockDirection }}
      >
        <Row data-testid="shipment-sender-adress">
          <IconCard>
            <SenderAddressIcon />
          </IconCard>
          <Stack>
            <DetailsSpan>{t('detail.address.sender')}</DetailsSpan>
            <AddressNameAnonymous
              address={shipmentData.shipment.sender.address}
            />
            <AddressAnonymous
              address={shipmentData.shipment.sender.address}
              inline
            />
          </Stack>
        </Row>
        <Row
          mt={blockDirection === 'column' ? '20px' : 0}
          data-testid="shipment-recipient-adress"
        >
          <IconCard>
            <RecipientAddressIcon />
          </IconCard>
          <Stack>
            <DetailsSpan>{t('detail.address.recipient')}</DetailsSpan>
            <AddressNameAnonymous
              address={shipmentData.shipment.recipient.address}
            />
            <AddressAnonymous
              address={shipmentData.shipment.recipient.address}
              inline
            />
          </Stack>
        </Row>
      </DetailsCard>
    </Wrapper>
  );
};

export default ShipmentAnonymousAddressCard;
