import React from 'react';

import { Text } from '@livingpackets/design-system-react-next';

interface ITitle {
  pageName: string;
}

const Title = ({ pageName }: ITitle) => (
  <Text
    variant="titleXXL"
    color="custom.neutral.black.100"
    marginTop="5px"
    marginBottom="30px"
  >
    {pageName}
  </Text>
);

export default Title;
