import i18next from 'i18next';
import * as yup from 'yup';

export const billingAddressSchema = () => {
  const baseTransKey = 'form.billingAddress.fields.';

  return yup.object().shape({
    firstName: yup
      .string()
      .required(
        i18next.t(
          `accountInformation:${baseTransKey}firstName.validation.required`
        )
      ),
    lastName: yup
      .string()
      .required(
        i18next.t(
          `accountInformation:${baseTransKey}lastName.validation.required`
        )
      ),
    companyName: yup.string(),
    city: yup
      .string()
      .required(
        i18next.t(`accountInformation:${baseTransKey}city.validation.required`)
      ),
    address: yup
      .string()
      .required(
        i18next.t(
          `accountInformation:${baseTransKey}address.validation.required`
        )
      ),
    zipCode: yup
      .string()
      .required(
        i18next.t(
          `accountInformation:${baseTransKey}zipCode.validation.required`
        )
      ),
    countryCode: yup
      .string()
      .required(
        i18next.t(
          `accountInformation:${baseTransKey}countryCode.validation.required`
        )
      ),
  });
};
