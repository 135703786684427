export enum RewardStatusEnum {
  UNSPECIFIED = 'REWARD_STATUS_UNSPECIFIED',
  NOT_AVAILABLE = 'REWARD_STATUS_NOT_AVAILABLE',
  AVAILABLE = 'REWARD_STATUS_AVAILABLE',
  IN_PROGRESS = 'REWARD_STATUS_IN_PROGRESS',
  IN_FAILURE_TO_PAY = 'REWARD_STATUS_IN_FAILURE_TO_PAY',
  PAID_OUT = 'REWARD_STATUS_PAID_OUT',
}

export enum StateEnum {
  UNSPECIFIED = 'CONTRIBUTION_STATE_UNSPECIFIED',
  ACTIVE = 'CONTRIBUTION_STATE_ACTIVE',
  REFUNDED = 'CONTRIBUTION_STATE_REFUNDED',
}

export enum TypeEnum {
  UNSPECIFIED = 'CONTRIBUTION_TYPE_UNSPECIFIED',
  CONTRIBUTION = 'CONTRIBUTION_TYPE_CONTRIBUTION',
  SPONSORSHIP = 'CONTRIBUTION_TYPE_SPONSORSHIP',
}

export enum PaymentMethodEnum {
  NONE = 'NONE',
  CREDIT_CARD = 'CREDIT_CARD',
  WIRE = 'WIRE',
  SOFORT = 'SOFORT',
  GIROPAY = 'GIROPAY',
}

export enum RewardTransactionMethodEnum {
  TRANSACTION_METHOD_TYPE_UNSPECIFIED = 'TRANSACTION_METHOD_TYPE_UNSPECIFIED',
  TRANSACTION_METHOD_TYPE_WIRE = 'TRANSACTION_METHOD_TYPE_WIRE',
}
