import React from 'react';

import { useAuth0 } from '@auth0/auth0-react';
import {
  ExitIcon,
  NavigationMenu as Menu,
} from '@livingpackets/design-system-react-next';
import { useTranslation } from 'react-i18next';

const LogoutButton = ({ open }: { open: boolean }) => {
  const { t } = useTranslation('navigation');
  const { logout } = useAuth0();

  const handleLogout = () => {
    logout({
      returnTo: window.location.origin,
    });
  };

  return (
    <Menu.Item
      icon={ExitIcon}
      open={open}
      onClick={handleLogout}
      data-testid="side-nav-logout"
    >
      {t('logout')}
    </Menu.Item>
  );
};

export default LogoutButton;
