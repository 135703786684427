import { getBleProductTypeKeysValue } from './bleProductType';
import { bytesToHex, uuidFormat } from './helpers';
import { COVER_STATE, LOCK_STATE } from '../model/states';
import { BleProductTypeValues } from '../types/bleProductType';

export interface BoxInfo {
  version: string;
  boxId: string;
  boxCoverState: string;
  boxLockState: string;
  shipmentId: string;
  productType: string | number;
}

const range = (start: any, end: any) =>
  Array.from({ length: end - start + 1 }, (v, k) => k + start);

const FIRMWARE_VERSION_RANGE = range(4, 6);
const UNKNOWN = 'Unknown';

export const boxInfoDecode = (data: DataView): BoxInfo | undefined => {
  if (data.byteLength === 48) {
    let BOX_ID_RANGE: number[];
    let SHIPMENT_ID_RANGE: number[];
    let COVER_STATUS_INDEX: number;
    let LOCK_STATUS_INDEX: number;
    let PRODUCT_TYPE_INDEX: number;

    /* NOT USE FOR THIS MOMENT
      
      let INTERNAL_TEMPERATURE_RANGE: number[];
      let INTERNAL_HUMIDITY_RANGE: number[];
      let EXTERNAL_TEMPERATURE_RANGE: number[];
      let EXTERNAL_HUMIDITY_RANGE: number[];
      let PRESSURE_RANGE: number[];
      let BATTERY_LEVEL_INDEX: number;
      let BATTERY_STATUS_INDEX: number;
      let SHIPMENT_STATUS_INDEX: number;
      */

    const array = new Uint8Array(data.buffer);

    const version = FIRMWARE_VERSION_RANGE.reverse()
      .map(v => new Uint8Array(data.buffer, v, 1))
      .join('.');

    if (version.startsWith('3.2')) {
      BOX_ID_RANGE = range(7, 26); // BoxID / ThingName [20 byte] - ASCII 20 chars
      SHIPMENT_ID_RANGE = range(27, 42); // ShipmentId [16 byte] - uuid128 bits
      /*   INTERNAL_TEMPERATURE_RANGE = range(43, 44); // Temp, internal [2 byte] - tenth of celsius degree
        INTERNAL_HUMIDITY_RANGE = range(45, 46); // Humidity, internal [2 byte] - tenth of percent
        EXTERNAL_TEMPERATURE_RANGE = range(47, 48); // Temp, external [2 byte] - tenth of celsius degree
        EXTERNAL_HUMIDITY_RANGE = range(49, 50); // Humidity, external [2 byte] - tenth of percent
        PRESSURE_RANGE = range(51, 52); // Pressure [2 byte] -  in hPa
        BATTERY_LEVEL_INDEX = 53; // Battery Level [1 byte] - percent
        BATTERY_STATUS_INDEX = 54; // Battery Status [1 byte] - Boolean not charging / charging
        SHIPMENT_STATUS_INDEX = 55; // Contract Status [1 byte] - Boolean not activated / activated 
        */
      COVER_STATUS_INDEX = 56; // Cover Status [1 byte]
      LOCK_STATUS_INDEX = 57; // Lock Status [1 byte]
      PRODUCT_TYPE_INDEX = 58; // Product type
    } else {
      BOX_ID_RANGE = range(7, 15); // BoxID / ThingName [9 byte] - HEX
      SHIPMENT_ID_RANGE = range(16, 31); // ShipmentId [16 byte] - uuid128 bits
      /*   INTERNAL_TEMPERATURE_RANGE = range(32, 33); // Temp, internal [2 byte] - tenth of celsius degree
        INTERNAL_HUMIDITY_RANGE = range(34, 35); // Humidity, internal [2 byte] - tenth of percent
        EXTERNAL_TEMPERATURE_RANGE = range(36, 37); // Temp, external [2 byte] - tenth of celsius degree
        EXTERNAL_HUMIDITY_RANGE = range(38, 39); // Humidity, external [2 byte] - tenth of percent
        PRESSURE_RANGE = range(40, 41); // Pressure [2 byte] -  in hPa
        BATTERY_LEVEL_INDEX = 42; // Battery Level [1 byte] - percent
        BATTERY_STATUS_INDEX = 43; // Battery Status [1 byte] - Boolean not charging / charging
        SHIPMENT_STATUS_INDEX = 44; // Contract Status [1 byte] - Boolean not activated / activated */
      COVER_STATUS_INDEX = 45; // Cover Status [1 byte]
      LOCK_STATUS_INDEX = 46; // Lock Status [1 byte]
      PRODUCT_TYPE_INDEX = 47; // Product type
    }

    const boxId =
      'sn' +
      BOX_ID_RANGE.map(v =>
        bytesToHex(new Uint8Array(data.buffer, v, 1)).toUpperCase()
      ).join('');

    let shipmentId = uuidFormat(
      SHIPMENT_ID_RANGE.map(v =>
        bytesToHex(new Uint8Array(data.buffer, v, 1))
      ).join('')
    );

    let boxCoverState = UNKNOWN;
    if (array[COVER_STATUS_INDEX] < COVER_STATE.length) {
      boxCoverState = COVER_STATE[array[COVER_STATUS_INDEX]];
    }

    let boxLockState = UNKNOWN;
    if (array[LOCK_STATUS_INDEX] < LOCK_STATE.length) {
      boxLockState = LOCK_STATE[array[LOCK_STATUS_INDEX]];
    }

    const productType =
      getBleProductTypeKeysValue(
        bytesToHex(
          new Uint8Array(data.buffer, PRODUCT_TYPE_INDEX, 1)
        ) as BleProductTypeValues
      ) || 0;

    return {
      version,
      boxId,
      boxCoverState,
      boxLockState,
      shipmentId,
      productType,
    };
  }
};
