import React from 'react';
import { useCallback } from 'react';

import { useAuth0 } from '@auth0/auth0-react';
import { Button, Checkbox } from '@livingpackets/design-system-react';
import {
  PhoneNumber,
  useTheme,
  Text,
  Box,
  Stack,
} from '@livingpackets/design-system-react-next';
import Input from 'components/atoms/Input';
import {
  AuthenticationView,
  DescriptionTextFontStyleEnum,
} from 'components/containers/AuthenticationView';
import { PATHS } from 'configs';
import { AccountTypeEnum } from 'enums/accountTypeEnum';
import useCustomer from 'hooks/useCustomer';
import useFormTemplate from 'hooks/useFormTemplate';
import { set } from 'lodash';
import get from 'lodash/get';
import {
  IFinalizeAccountForm,
  INITIAL_STATE_FINALIZE_ACCOUNT_FORM,
} from 'models/finalizeAccount';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { finalizeAccountSchema } from 'schemas/finalizeAccountSchema';
import useAppState, { userSelector } from 'stores/appState/useAppState';
import useMyPartnersStore, {
  partnersSelector,
} from 'stores/useMyPartnersStore';

const FinalizeAccount = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { t } = useTranslation('authentication');
  const { logout } = useAuth0();

  const appUser = useAppState(userSelector);
  const partners = useMyPartnersStore(partnersSelector);
  const { editCurrentUser } = useCustomer();

  const {
    errors,
    register,
    formState: { touchedFields, isValid },
    getValues,
    control,
  } = useFormTemplate<IFinalizeAccountForm>({
    resolver: finalizeAccountSchema,
    defaultValues: {
      ...INITIAL_STATE_FINALIZE_ACCOUNT_FORM,
      firstName: appUser?.firstName,
      lastName: appUser?.lastName,
      phoneNumber: appUser?.phoneNumber,
    },
    formValidationMode: 'onTouched',
  });

  if (appUser?.firstName) {
    set(touchedFields, 'firstName', true);
  }

  if (appUser?.lastName) {
    set(touchedFields, 'lastName', true);
  }

  if (appUser?.phoneNumber) {
    set(touchedFields, 'phoneNumber', true);
  }

  const redirectToAccountCreatedSuccessPage = useCallback(() => {
    if (partners.length) {
      navigate(PATHS.AUTHENTICATION.ACCOUNT_CREATED_SUCCESS, {
        state: { accountType: AccountTypeEnum.FULL_VERSION },
      });
    } else {
      navigate(PATHS.AUTHENTICATION.ACCOUNT_TYPE_CHOICE);
    }
  }, [navigate, partners.length]);

  const redirectToLandingPage = useCallback(() => {
    logout({
      returnTo: window.location.origin,
    });
  }, [logout]);

  const submitHandler = useCallback(
    (e: any) => {
      e.preventDefault();

      const formValues = getValues();

      editCurrentUser(
        {
          firstName: formValues.firstName,
          lastName: formValues.lastName,
          phoneNumber: formValues.phoneNumber,
        },
        false
      ).then(() => redirectToAccountCreatedSuccessPage());
    },
    [getValues, editCurrentUser, redirectToAccountCreatedSuccessPage]
  );

  const getTermsOfUseLink = () => (
    <a
      href={t('finalizeAccount.form.isAgreedToTOS.termsOfUseWebsiteUrl')}
      style={{
        textDecoration: 'underline',
        textDecorationColor: theme.palette.custom.primary[100],
      }}
    >
      <Text variant="bodyTextS" color="custom.primary.100">
        {t('finalizeAccount.form.isAgreedToTOS.termsOfUseWebsite')}
      </Text>
    </a>
  );

  return (
    <AuthenticationView
      title={t('finalizeAccount.title')}
      description={t('finalizeAccount.description')}
      descriptionFontStyle={DescriptionTextFontStyleEnum.REGULAR}
    >
      <Stack
        component="form"
        onSubmit={submitHandler}
        data-testid="finalize-account-container"
        marginTop="50px"
      >
        <Input
          name="firstName"
          label={
            get(touchedFields, 'firstName', false)
              ? t('finalizeAccount.form.firstName.placeholder')
              : undefined
          }
          placeholder={t('finalizeAccount.form.firstName.placeholder')}
          error={get(errors, 'firstName', false)}
          isTouched={get(touchedFields, 'firstName', false)}
          register={register}
          width="100%"
        />
        <Input
          name="lastName"
          label={
            get(touchedFields, 'lastName', false)
              ? t('finalizeAccount.form.lastName.placeholder')
              : undefined
          }
          placeholder={t('finalizeAccount.form.lastName.placeholder')}
          error={get(errors, 'lastName', false)}
          isTouched={get(touchedFields, 'lastName', false)}
          register={register}
          width="100%"
        />
        <Controller
          control={control}
          name="phoneNumber"
          render={({ field, fieldState: { error, invalid } }) => (
            <PhoneNumber
              defaultCountryCode="FR"
              label={t('finalizeAccount.form.phoneNumber.placeholder')}
              error={invalid}
              helperText={error?.message}
              {...field}
            />
          )}
        />
        <Box
          marginTop="10px !important"
          marginBottom="30px !important"
          data-testid="form-field-checkbox-is-agreed-to-tos"
        >
          <Controller
            control={control}
            name="isAgreedToTOS"
            render={({ field }) => (
              <Checkbox
                name={field.name}
                value={field.value}
                onBlur={field.onBlur}
                onChange={field.onChange}
                isSelected={field.value}
              >
                <Stack direction="row" alignItems="center">
                  <Text
                    variant="bodyTextM"
                    color="custom.neutral.black.50"
                    sx={{ cursor: 'pointer' }}
                  >
                    {t('finalizeAccount.form.isAgreedToTOS.label')}
                  </Text>
                  {getTermsOfUseLink()}
                </Stack>
              </Checkbox>
            )}
          />
        </Box>
        <Button
          id="submitBtn"
          action="primary"
          type="submit"
          isDisabled={!isValid}
          style={{ width: '100%', marginBottom: '16px' }}
        >
          {t('finalizeAccount.buttons.confirm')}
        </Button>
        <Button
          action="secondary"
          onClick={() => redirectToLandingPage()}
          style={{ width: '100%', marginTop: '0' }}
        >
          {t('finalizeAccount.buttons.cancel')}
        </Button>
      </Stack>
    </AuthenticationView>
  );
};
export default FinalizeAccount;
