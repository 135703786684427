import React from 'react';

import { Button } from '@livingpackets/design-system-react';
import { UploadIcon, Box, Text } from '@livingpackets/design-system-react-next';
import ImportShipmentStepTextBlock from 'components/atoms/importShipment/ImportShipmentStepTextBlock';
import { LabelCropStateNameEnum } from 'components/organisms/ShipmentLabelForm';
import { CarrierInfo } from 'models/carriers';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';

const Wrapper = styled.div<{ isShipmentFormDisplayed: boolean }>`
  display: flex;
  flex-direction: column;
  flex: ${({ isShipmentFormDisplayed }) =>
    isShipmentFormDisplayed ? '1 50%' : '1 100%'};
  background-color: ${({ theme }) => theme.colors.black[2]};
  border-radius: 20px;
  height: inherit;
`;

const HeaderWrapper = styled.div`
  padding-top: 32px;
  padding-left: 40px;
  padding-right: 40px;
  display: flex;
  justify-content: space-between;
  height: inherit;
`;

const LogoWrapper = styled.img`
  height: 2.5rem;
  width: 2.5rem;
  margin-right: 0.6875rem;
`;

const WrapperMain = styled.div<{
  isShipmentFormDisplayed: boolean;
  isConfirmInformationStepDisplayed: boolean;
}>`
  width: ${({ isShipmentFormDisplayed }) =>
    isShipmentFormDisplayed ? '100%' : '100%'};
  height: 100%;
  margin-top: 28px;
  overflow-y: visible;
  display: flex;
  flex-direction: column;
  justify-content: ${({ isConfirmInformationStepDisplayed }) =>
    isConfirmInformationStepDisplayed ? 'flex-start' : 'flex-end'};
  align-items: ${({ isShipmentFormDisplayed }) =>
    isShipmentFormDisplayed ? 'center' : 'stretch'};
  flex-wrap: nowrap;
`;

const HeaderTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const HeaderTextTitle = styled(Text)`
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
`;

HeaderTextTitle.defaultProps = {
  variant: 'titleM',
  color: 'custom.neutral.black.100',
};

const HeaderTextSubTitle = styled(Text)`
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  white-space: nowrap;
`;

HeaderTextSubTitle.defaultProps = {
  variant: 'titleXS',
  color: 'custom.neutral.black.50',
};

const FooterWrapper = styled(Box)`
  padding: 30px 44px 32px 44px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
`;

interface ShipmentLabelContainerProps {
  children: React.ReactNode;
  carrierInfo: CarrierInfo;
  actions?: React.ReactNode;
  labelCropState: string;
  handleChangePage: () => void;
  handleLabelReUpload: () => void;
}

export function ShipmentLabelContainer({
  children,
  carrierInfo,
  actions,
  labelCropState,
  handleChangePage,
  handleLabelReUpload,
}: ShipmentLabelContainerProps) {
  const { t } = useTranslation(['general', 'shipments']);

  function displayShipmentForm(): boolean {
    return (
      labelCropState === LabelCropStateNameEnum.shipmentCreationFormDisplay ||
      labelCropState === LabelCropStateNameEnum.showingAutoCropResult
    );
  }

  function displayFixedPage(): boolean {
    return (
      displayShipmentForm() ||
      labelCropState === LabelCropStateNameEnum.manuallyCropping ||
      labelCropState === LabelCropStateNameEnum.showingManualCropResult
    );
  }

  function displaySelectCarrier(): boolean {
    return (
      labelCropState === LabelCropStateNameEnum.waitingForFile ||
      labelCropState === LabelCropStateNameEnum.processingFile ||
      labelCropState === LabelCropStateNameEnum.autoCropping
    );
  }

  function displayConfirmInformationStep(): boolean {
    return (
      displayShipmentForm() ||
      labelCropState === LabelCropStateNameEnum.showingManualCropResult
    );
  }

  return (
    <Wrapper isShipmentFormDisplayed={displayShipmentForm()}>
      <Box display="flex" width="100%" flexDirection="column">
        <HeaderWrapper>
          {displayConfirmInformationStep() && (
            <Box
              display="flex"
              alignItems="flex-start"
              flexDirection="row"
              justifyContent="space-between"
              width="100%"
            >
              <HeaderTextWrapper>
                <HeaderTextTitle>
                  {t(
                    'shipments:importLabel.header.steps.confirmInformation.title'
                  )}
                </HeaderTextTitle>
                <HeaderTextSubTitle>
                  {t(
                    'shipments:importLabel.header.steps.confirmInformation.subTitle'
                  )}
                </HeaderTextSubTitle>
              </HeaderTextWrapper>
            </Box>
          )}
          {!displayShipmentForm() && (
            <Box
              display="flex"
              alignItems="flex-start"
              flexDirection="row"
              justifyContent="space-between"
              width="100%"
            >
              {(labelCropState === LabelCropStateNameEnum.waitingForFile ||
                labelCropState === LabelCropStateNameEnum.processingFile ||
                labelCropState === LabelCropStateNameEnum.autoCropping) && (
                <ImportShipmentStepTextBlock
                  number="2"
                  title={t(
                    'shipments:importLabel.header.steps.waitingForFile.title'
                  )}
                  subTitle={t(
                    'shipments:importLabel.header.steps.waitingForFile.subTitle'
                  )}
                />
              )}
              {labelCropState === LabelCropStateNameEnum.pdfPageSelection && (
                <>
                  <ImportShipmentStepTextBlock
                    number="3"
                    title={t(
                      'shipments:importLabel.header.steps.choosePdfPage.title'
                    )}
                    subTitle={t(
                      'shipments:importLabel.header.steps.choosePdfPage.subTitle'
                    )}
                  />
                  <Button
                    action="tertiary"
                    onClick={handleLabelReUpload}
                    icon={<UploadIcon />}
                  >
                    {t('general:reupload')}
                  </Button>
                </>
              )}
              {labelCropState ===
                LabelCropStateNameEnum.pdfSpecificPageSelectedManuallyCropping && (
                <>
                  <ImportShipmentStepTextBlock
                    number="4"
                    title={t(
                      'shipments:importLabel.header.steps.cropPdfPage.title'
                    )}
                    subTitle={t(
                      'shipments:importLabel.header.steps.cropPdfPage.subTitle'
                    )}
                  />
                  <Button
                    action="tertiary"
                    onClick={handleLabelReUpload}
                    icon={<UploadIcon />}
                  >
                    {t('general:reupload')}
                  </Button>
                </>
              )}
              {labelCropState === LabelCropStateNameEnum.manuallyCropping && (
                <>
                  <ImportShipmentStepTextBlock
                    number="4"
                    title={t(
                      'shipments:importLabel.header.steps.cropPdfPage.title'
                    )}
                    subTitle={t(
                      'shipments:importLabel.header.steps.cropPdfPage.subTitle'
                    )}
                  />
                  <Button
                    action="tertiary"
                    onClick={handleLabelReUpload}
                    icon={<UploadIcon />}
                  >
                    {t('general:reupload')}
                  </Button>
                </>
              )}
              {displaySelectCarrier() && (
                <Box display="flex" alignItems="center">
                  <LogoWrapper src={carrierInfo.logo} />
                  <HeaderTextWrapper>
                    <Text variant="titleXS">
                      {t('shipments:selectedCarrier')}
                    </Text>
                    <Text
                      variant="bodyTextS"
                      data-testid="shipmentLabel-carrierInfo-name"
                    >
                      <Trans t={t}>
                        {`shipments:${carrierInfo.code.toLowerCase()}`}
                      </Trans>
                    </Text>
                  </HeaderTextWrapper>
                </Box>
              )}
            </Box>
          )}
          {labelCropState === LabelCropStateNameEnum.showingAutoCropResult && (
            <Box display="flex" justifyContent="flex-end" width="100%">
              <Button
                action="tertiary"
                onClick={handleLabelReUpload}
                icon={<UploadIcon />}
              >
                {t('general:reupload')}
              </Button>
            </Box>
          )}
          {labelCropState ===
            LabelCropStateNameEnum.shipmentCreationFormDisplay && (
            <Box display="flex" justifyContent="flex-end" width="100%">
              <Button
                action="tertiary"
                onClick={handleLabelReUpload}
                icon={<UploadIcon />}
              >
                {t('general:reupload')}
              </Button>
            </Box>
          )}
        </HeaderWrapper>
        <WrapperMain
          isShipmentFormDisplayed={displayFixedPage()}
          isConfirmInformationStepDisplayed={displayConfirmInformationStep()}
        >
          {children}
        </WrapperMain>
        <FooterWrapper>{actions}</FooterWrapper>
      </Box>
    </Wrapper>
  );
}
