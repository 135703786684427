import { getApiServices, TApiEnv } from 'configs/helpers';
import { KoalendarIframeInfo } from 'models/koalendarIframeInfo';

import packageInfo from '../../package.json';

export const REACT_APP_WEBAPP_VERSION = packageInfo.version;

export const REACT_APP_REDIRECT_URL =
  process.env.REACT_APP_REDIRECT_URL || process.env.PUBLIC_URL;

export const APIServices = getApiServices(
  process.env.REACT_APP_API_ENV as TApiEnv
);

export const REACT_APP_FIGURAL_ACCOUNT_ASSOCIATION_BLOCK_DISPLAY =
  process.env.REACT_APP_FIGURAL_ACCOUNT_ASSOCIATION_BLOCK_DISPLAY === 'true';

export const REACT_APP_TRANSACTIONS_BLOCK_DISPLAY =
  process.env.REACT_APP_TRANSACTIONS_BLOCK_DISPLAY === 'true';

export const REACT_APP_I18N_TRANSLATION_DEBUG_ACTIVATED =
  process.env.REACT_APP_I18N_TRANSLATION_DEBUG_ACTIVATED === 'true';

export const REACT_APP_KOALENDAR_IFRAME_INFO = [
  { locale: 'en', url: process.env.REACT_APP_KOALENDAR_IFRAME_INFO_EN },
  { locale: 'fr', url: process.env.REACT_APP_KOALENDAR_IFRAME_INFO_FR },
] as KoalendarIframeInfo[];

export type TAPIService = keyof typeof APIServices;

export enum Roles {
  PARTNER_ADMIN = 'Partner Admin',
}

export const AUTH0_CONNECTION_NAME = 'customdb';

export const REACT_APP_IS_DEEP_LINK_ACTIVATED =
  process.env.REACT_APP_IS_DEEP_LINK_ACTIVATED === 'true';

export const REACT_APP_GOOGLE_MAPS_API_KEY =
  process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

export const REACT_APP_GOOGLE_MAPS_API_VERSION =
  process.env.REACT_APP_GOOGLE_MAPS_API_VERSION;

export const REACT_APP_APP_CLIP_BUNDLE = process.env.REACT_APP_APP_CLIP_BUNDLE;

export const PATHS = {
  ROOT: '/',
  PARTNER_ROOT: '/partner/:partnerId',
  DASHBOARD: '/partner/:partnerId/dashboard',
  CONTACT_ADDRESS: {
    LIST: '/partner/:partnerId/contact-address-management',
    CREATE: '/partner/:partnerId/contact-address-management/create',
    EDIT: '/partner/:partnerId/contact-address-management/:contactId/edit',
  },
  SHOP_MANAGEMENT: {
    LIST: '/partner/:partnerId/shop-management',
  },
  BOX_CONTRACTS: {
    LIST: '/partner/:partnerId/box-contracts',
  },
  SHIPMENT: {
    LIST: '/partner/:partnerId/shipments',
    LABEL_IMPORT: '/partner/:partnerId/shipments/labels/import',
    DETAIL: '/partner/:partnerId/shipments/:shipmentId/detail',
    REMOTE_CONTROL: '/partner/:partnerId/shipments/:shipmentId/remote',
    DEPLOY_RETURN: '/partner/:partnerId/shipments/:shipmentId/deploy-return',
    DETAIL_WITH_ALERT:
      '/partner/:partnerId/shipments/:shipmentId/alert/:alertId',
    ANONYMOUS_DETAIL: '/shipments/:trackingCode/anonymous-detail',
  },
  PRODUCT: {
    LIST: '/partner/:partnerId/products',
    DETAIL: '/partner/:partnerId/products/:lpUi/detail',
  },
  USER_MANAGEMENT: {
    LIST: '/partner/:partnerId/management',
    CREATE: '/partner/:partnerId/management/create',
    EDIT: '/partner/:partnerId/management/:memberId/edit',
  },
  CONTACT_ADDRESS_MANAGEMENT: {
    LIST: '/partner/:partnerId/contact-address-management',
  },
  SETTINGS: {
    INSURANCE_ACTIVATION_SUCCESS:
      '/partner/:partnerId/settings/figural-activation-success',
  },
  API_KEYS: {
    LIST: '/partner/:partnerId/api-keys',
  },
  AUTHENTICATION: {
    LANDING: '/landing',
    RETRIEVE_DELIVERY_KEY: '/retrieve-delivery-key',
    INVITATION_CHECK: '/invitation-check',
    FINALIZE_ACCOUNT: '/finalize-account',
    ACCOUNT_TYPE_CHOICE: '/account-type-choice',
    ACCOUNT_CREATED_SUCCESS: '/account-created-success',
    ADD_PARTNERSHIP_INFORMATION: '/add-partnership-information',
  },
  SHIPMENT_LABELS_ROOT: '/partner/:partnerId/shipments',
  HELP: '/lpaccount/help',
  PROFILE: '/lpaccount/profile',
  SETTINGS_PARTNER_PARTNERSHIP: '/partner/:partnerId/account/partnership',
  ACCOUNT_ROOT: '/lpaccount',
  ACCOUNT: {
    ROOT: '/lpaccount',
    DASHBOARD: '/lpaccount/dashboard',
    PROFILE: '/lpaccount/profile',
    BUY_RENT: '/lpaccount/buynrent',
    NOTIFICATIONS: '/lpaccount/notifications',
    INFORMATION: '/lpaccount/information',
    SHOWROOMS: '/lpaccount/showrooms',
    HELP: '/lpaccount/help',
    SETTINGS: '/lpaccount/settings',
    SHIPMENTS: '/lpaccount/shipments',
    PRODUCTS: '/lpaccount/products',
    CONTACT_ADDRESS_MANAGEMENT: '/lpaccount/contact-address-management',
  },
  VERIFY_EMAIL: '/verify-email',
  ACCEPT_TOS: '/accept-tos',
  DOWNLOAD_MOBILE_APPS: '/download-apps',
  OPEN_APP: '/open-app',
  LANDING: '/landing',
  INVITATION_CHECK: '/invitation-check',
  FIGURAL_AUTHORIZE_PAGE: 'https://api.secursus.com/authorize?',
  FIGURAL_MONITORY_OUR_CLAIM_PAGE:
    'https://www.monitoryourclaim.com/auth/:secursusId/:email',
  PAGE_404: '/404',
  PAGE_403: '/403',
  DEEP_LINK: {
    SHIPMENT_LIST: '/partners',
    SHIPMENT_DETAIL: '/shipments/:shipmentId',
  },
  REMOTE_CONTROL: {
    ROOT: 'remote-control/*',
  },
};

export const ROUTE_PATHS = {
  ROOT: '/',
  AUTHENTICATION: {
    LANDING: {
      ROOT: 'landing',
    },
    RETRIEVE_DELIVERY_KEY: {
      ROOT: 'retrieve-delivery-key',
    },
    INVITATION_CHECK: {
      ROOT: 'invitation-check',
    },
    FINALIZE_ACCOUNT: {
      ROOT: 'finalize-account',
    },
    ACCOUNT_TYPE_CHOICE: {
      ROOT: 'account-type-choice',
    },
    ACCOUNT_CREATED_SUCCESS: {
      ROOT: 'account-created-success',
    },
    ADD_PARTNERSHIP_INFORMATION: {
      ROOT: 'add-partnership-information',
    },
  },
  PARTNER: {
    ROOT: 'partner/:partnerId/*',
    CHILDREN: {
      BOX_CONTRACTS: {
        ROOT: 'box-contracts/*',
      },
      DASHBOARD: {
        ROOT: 'dashboard/*',
      },
      CONTACT_ADDRESS: {
        ROOT: 'contact-address-management/*',
        CHILDREN: {
          CREATE: {
            ROOT: 'create/*',
          },
          ID: {
            ROOT: ':contactId/*',
            CHILDREN: {
              EDIT: {
                ROOT: 'edit',
              },
            },
          },
        },
      },
      SHOP_MANAGEMENT: {
        ROOT: 'shop-management/*',
        CHILDREN: {
          ID: {
            ROOT: ':shopId',
          },
        },
      },
      SHIPMENTS: {
        ROOT: 'shipments/*',
        CHILDREN: {
          LABELS: {
            ROOT: 'labels/*',
            CHILDREN: {
              IMPORT: {
                ROOT: 'import',
              },
            },
          },
          ID: {
            ROOT: ':shipmentId/*',
            CHILDREN: {
              DETAIL: {
                ROOT: 'detail/*',
              },
              DETAIL_WITH_ALERT: {
                ROOT: 'alert/*',
                CHILDREN: {
                  ID: {
                    ROOT: ':alertId',
                  },
                },
              },
              REMOTE_CONTROL: {
                ROOT: 'remote/*',
              },
              DEPLOY_RETURN: {
                ROOT: 'deploy-return/*',
              },
            },
          },
        },
      },
      PRODUCT: {
        ROOT: 'products/*',
        CHILDREN: {
          LPUI: {
            ROOT: ':lpUi/*',
            CHILDREN: {
              DETAIL: {
                ROOT: 'detail',
              },
            },
          },
        },
      },
      USER_MANAGEMENT: {
        ROOT: 'management/*',
        CHILDREN: {
          CREATE: {
            ROOT: 'create',
          },
          ID: {
            ROOT: ':memberId/*',
            CHILDREN: {
              EDIT: {
                ROOT: 'edit',
              },
            },
          },
        },
      },
      CONTACT_ADDRESS_MANAGEMENT: {
        ROOT: 'contact-address-management/*',
      },
      SETTINGS: {
        ROOT: 'settings/*',
        CHILDREN: {
          INSURANCE_ACTIVATION_SUCCESS: {
            ROOT: 'figural-activation-success',
          },
        },
      },
      API_KEYS: {
        ROOT: 'api-keys',
      },
    },
  },
  SHIPMENTS: {
    ROOT: 'shipments/*',
    CHILDREN: {
      TRACKING_CODE: {
        ROOT: ':trackingCode/*',
        CHILDREN: {
          ANONYMOUS_DETAIL: {
            ROOT: 'anonymous-detail',
          },
        },
      },
    },
  },
  ACCOUNT: {
    ROOT: 'lpaccount/*',
    CHILDREN: {
      DASHBOARD: {
        ROOT: 'dashboard',
      },
      SETTINGS: {
        ROOT: 'settings',
      },
      HELP: {
        ROOT: 'help',
      },
      PROFILE: {
        ROOT: 'profile',
      },
      BUY_RENT: {
        ROOT: 'buynrent',
      },
      NOTIFICATIONS: {
        ROOT: 'notifications',
      },
      INFORMATION: {
        ROOT: 'information',
      },
      SHOWROOMS: {
        ROOT: 'showrooms',
      },
      SHIPMENTS: {
        ROOT: 'shipments',
      },
      PRODUCTS: {
        ROOT: 'products',
      },
      CONTACT_ADDRESS_MANAGEMENT: {
        ROOT: 'contact-address-management',
      },
    },
  },
  VERIFY_EMAIL: {
    ROOT: 'verify-email',
  },
  ACCEPT_TOS: {
    ROOT: 'accept-tos',
  },
  DOWNLOAD_MOBILE_APPS: {
    ROOT: 'download-apps',
  },
  OPEN_APP: {
    ROOT: 'open-app',
  },
  PAGE_404: {
    ROOT: '404',
  },
  PAGE_403: {
    ROOT: '403',
  },
  DEEP_LINK: {
    SHIPMENT: {
      ROOT: 'shipments/*',
      CHILDREN: {
        ID: {
          ROOT: ':shipmentId/*',
          TIMELINE: {
            ROOT: 'timeline',
          },
        },
      },
    },
  },
};

export const ALLOWED_LANGS = ['fr', 'en', 'de'];

export const PHONE_NUMBER_PREFIX_LIST = [
  { lang: 'fr', prefix: '+33' },
  { lang: 'en', prefix: '+44' },
  {
    lang: 'de',
    prefix: '+49',
  },
];

export const REACT_APP_I18N_LNG_LOCAL_STORAGE_KEY = 'i18nextLng';

export const IOS_APP_STORE_APP_LINK =
  'https://apps.apple.com/app/livingpackets-partners/id1551535410';
export const PLAY_STORE_APP_LINK =
  'https://play.google.com/store/apps/details?id=com.livingpackets.partner';

export const SIDENAV_CONFIG = {
  CLOSED_WIDTH_IN_PX: 82,
  OPEN_WIDTH_IN_PX: 240,
  DIFF_OPEN_CLOSED_IN_PX: 242 - 80,
  BADGE_WIDTH_IN_REM: 3.125,
  BUTTON_WIDTH_IN_REM: 15.125 - 1.125,
};
