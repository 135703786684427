import { useCallback, useEffect, useState } from 'react';

import axios, { RawAxiosRequestConfig } from 'axios';

export interface IUseRemoteDataV2 extends RawAxiosRequestConfig {
  path: string;
  body?: { [key: string]: string };
  schema?: any | null;
  reducer?: any | null;
}

interface IUseRemoteDataV2Ext extends IUseRemoteDataV2 {
  tokenAndApiUrl: { apiUrl: string; token: string };
}

const CancelToken = axios.CancelToken;
const CANCELLED_MESSAGE = 'Effect unmount';

const useRemoteDataV2 = ({
  tokenAndApiUrl,
  path = '/',
  method = 'get',
  body,
  responseType = 'json',
  schema = null,
  reducer = null,
}: IUseRemoteDataV2Ext) => {
  const [error, setError] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<any>(null);
  const { apiUrl, token } = tokenAndApiUrl;
  const getData = useCallback(() => {
    const source = CancelToken.source();
    setLoading(true);
    const promise = axios({
      url: `${apiUrl}${path}`,
      method,
      data: body,
      responseType,
      headers: { Authorization: `Bearer ${token}` },
      cancelToken: source.token,
    })
      .then(response => {
        setLoading(false);
        if (!schema) {
          return response.data;
        }
        try {
          return schema.validate(response.data);
        } catch (err) {
          setError(err);
        }
      })
      .then(validated => {
        if (!reducer) {
          return validated;
        }
        try {
          return reducer(validated);
        } catch (err) {
          setError(err);
        }
      })
      .then(finalData => {
        setLoading(false);
        setData(finalData);
      })
      .catch(err => {
        setLoading(false);
        if (err.message === CANCELLED_MESSAGE) return;
        setError(err);
      });

    return { promise, cancel: () => source.cancel(CANCELLED_MESSAGE) };
  }, [token, body, method, path, reducer, schema, responseType, apiUrl]);

  useEffect(() => {
    if (token) {
      const { cancel } = getData();

      return cancel;
    }
  }, [getData, token]);

  return { error, loading, data, refetch: () => getData().promise };
};

export default useRemoteDataV2;
