import { useCallback } from 'react';

import { IBoxContract } from 'models/boxContract';

import { useApiClient } from './useApiClient';
import { PaginatedResponse } from './useGetPartnerShipments';

export interface GetBoxContractStatsParams {
  partnerId?: string;
}

export interface BoxContractStats {
  boxCount: number;
  contractCount: number;
  lastUpdate: number;
  totalEarnedCents: number;
}

type GetBoxContractStats = (
  params: GetBoxContractStatsParams
) => Promise<BoxContractStats>;

export function useGetBoxContractStats(): GetBoxContractStats {
  const shipment = useApiClient('lpAccount');

  const getBoxContractStats = useCallback(
    ({ partnerId }: GetBoxContractStatsParams) =>
      shipment
        .get<PaginatedResponse<IBoxContract>>(
          partnerId
            ? `api/v1/me/partners/${partnerId}/box-contracts`
            : 'api/v1/me/box-contracts',
          {
            params: {
              offset: 0,
              pageSize: 100,
              sortBy: 'paymentReceivedAt',
              order: 'DESC',
            },
          }
        )
        .then(response => response.data)
        .then(response =>
          response.items.reduce(
            (stats, contract) => ({
              boxCount: stats.boxCount + contract.numOfBox,
              contractCount: stats.contractCount + 1,
              totalEarnedCents:
                stats.totalEarnedCents + contract.totalAmountInCents,
              lastUpdate: Math.max(stats.lastUpdate, contract.createdAt),
            }),
            {
              boxCount: 0,
              contractCount: 0,
              totalEarnedCents: 0,
              lastUpdate: 0,
            }
          )
        ),
    [shipment]
  );

  return getBoxContractStats;
}
