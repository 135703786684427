import React from 'react';

import { Grid, Text } from '@livingpackets/design-system-react-next';

interface Props {
  avatar?: string | React.ReactNode;
  title?: string;
  subtitle?: string;
  moreActions?: React.ReactNode;
}

export const GreenCardHeader = ({
  avatar,
  title,
  subtitle,
  moreActions,
}: Props) => (
  <Grid container justifyContent="space-between">
    {/* Left Part */}
    <Grid item>
      <Grid container gap=".625rem">
        <Grid item>{avatar}</Grid>
        <Grid item>
          <Grid container direction="column" gap=".0125rem">
            {!!title && (
              <Text color="custom.neutral.white.pure" variant="titleXL">
                {title}
              </Text>
            )}
            {!!subtitle && (
              <Text color="custom.neutral.white.pure" variant="bodyTextM">
                {subtitle}
              </Text>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
    {/* Right Part */}
    {moreActions && <Grid item>{moreActions}</Grid>}
  </Grid>
);
