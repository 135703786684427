import {
  PaymentMethodEnum,
  RewardStatusEnum,
  RewardTransactionMethodEnum,
  StateEnum,
  TypeEnum,
} from 'enums/contributionEnum';

export interface IContribution {
  id: string;
  contributed_at: number;
  offer_duration_in_year: number;
  payment_amount: number;
  payment_id: string;
  payment_method: PaymentMethodEnum;
  reward_amount?: number;
  reward_estimated_date?: number;
  reward_rate: 0;
  reward_receipt_id?: string;
  reward_status: RewardStatusEnum;
  rewarded_at: number;
  state: StateEnum;
  type: TypeEnum;
  reward_transaction_method?: RewardTransactionMethodEnum;
}

export type PaymentCurrency = 'EUR' | 'USD';

export type TPaymentStatus =
  | 'rejected'
  | 'cancelled'
  | 'pending'
  | 'completed'
  | 'refund_requested'
  | 'refund_initiated'
  | 'refunded'
  | 'unknown';

export type TRewardStatus =
  | 'not_available'
  | 'available'
  | 'waiting_bank_info'
  | 'requested'
  | 'sent'
  | 'received'
  | 'in progress';

export type TPaymentMethod =
  | 'CREDIT_CARD'
  | 'STRIPE_SOFORT'
  | 'STRIPE_GIROPAY'
  | 'WIRE'
  | 'UNKNOWN'
  | 'REWARD'
  | '';

export type TContributionState =
  | 'REFUND_INITIATED'
  | 'REFUND_REQUESTED'
  | 'REQUESTED'
  | 'ACTIVE'
  | 'CANCELLED'
  | 'REFUNDED';

export const activeContributionStatus: TPaymentStatus[] = [
  'pending',
  'completed',
  'refunded',
  'refund_initiated',
  'refund_requested',
];

export interface IcontributionOverview {
  contributions_amount: number;
  contributions_number?: number;
  investments_total_amount: number;
  reward_amount: number;
  sponsorships_number: number;
  sponsorships_amount: number;
}

export const INITIAL_CONTRIBUTION_OVERVIEW = {
  contributions_amount: 0,
  contributions_number: undefined,
  investments_total_amount: 0,
  reward_amount: 0,
  sponsorships_number: 0,
  sponsorships_amount: 0,
};
