var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useCallback, useEffect, useMemo } from 'react';
import { create } from 'zustand';
import get from 'lodash/get';
import find from 'lodash/find';
import { object, string } from 'yup';
const NO_TRANS_ENDPOINT = 'https://restcountries.eu/rest/v2/all?fields=name;alpha2Code';
const WITH_TRANS_ENDPOINT = 'https://restcountries.eu/rest/v2/all?fields=name;alpha2Code;translations';
const countryCodeSchema = object().shape({
    name: string().required(),
    alpha2Code: string().required(),
    translations: object().notRequired(),
});
const useCountryCodesStore = create(set => ({
    countryCodes: { data: [], error: '', loading: true },
    setCountryCodes: (countryCodes) => set(state => (Object.assign(Object.assign({}, state), { countryCodes }))),
}));
const useCountryCodes = ({ includeTranslations, language, localCountryCodesPath, } = {}) => {
    const countryCodes = useCountryCodesStore((state) => state.countryCodes);
    const setCountryCodes = useCountryCodesStore((state) => state.setCountryCodes);
    const getCountryNameFromCode = useCallback((code) => {
        const country = find(countryCodes === null || countryCodes === void 0 ? void 0 : countryCodes.data, (x) => x.alpha2Code === code);
        if (!country) {
            return '';
        }
        const englishName = get(country, 'name', '');
        if (language === 'en') {
            return englishName;
        }
        if (includeTranslations && language) {
            return get(country, `translations.${language}`, englishName);
        }
        return englishName;
    }, [countryCodes, includeTranslations, language]);
    const countryCodesFilePath = useMemo(() => {
        if (localCountryCodesPath) {
            return localCountryCodesPath;
        }
        return includeTranslations ? WITH_TRANS_ENDPOINT : NO_TRANS_ENDPOINT;
    }, [localCountryCodesPath, includeTranslations]);
    useEffect(() => {
        if (countryCodes.data &&
            countryCodes.data.length === 0 &&
            !countryCodes.error) {
            fetch(countryCodesFilePath)
                .then(response => response.json())
                .then((data) => __awaiter(void 0, void 0, void 0, function* () {
                try {
                    yield countryCodeSchema.validate(data[0]);
                    return data;
                }
                catch (err) {
                    setCountryCodes((prev) => (Object.assign(Object.assign({}, prev), { loading: false, error: err.toString() })));
                }
            }))
                .then(validated => setCountryCodes({ loading: false, data: validated }))
                .catch(error => {
                setCountryCodes((prev) => (Object.assign(Object.assign({}, prev), { loading: false, error: error.toString() })));
            });
        }
    }, [
        setCountryCodes,
        countryCodes,
        includeTranslations,
        countryCodesFilePath,
    ]);
    return [countryCodes, getCountryNameFromCode];
};
export default useCountryCodes;
