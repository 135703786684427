import useImperativeAnonymousRequest from 'hooks/useImperativeAnonymousRequest';

export const useUpdateContractState = () => {
  const [, makeRequest] = useImperativeAnonymousRequest('shipmentV3');

  const updateContractState = async (
    shipmentId: string,
    commandType: string,
    contratState: string,
    LpUi: string
  ): Promise<ArrayBuffer | undefined> => {
    const { data, error } = await makeRequest({
      path: `/me/shipments/${shipmentId}/contract-state`,
      method: 'PATCH',
      body: {
        command_type: commandType,
        contract_state: contratState,
        lp_ui: LpUi,
      },
    });

    if (error) {
      console.error('ERROR => ', error);

      return;
    }

    if (data) {
      return data;
    }
  };

  return { updateContractState };
};
