var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useContext, useEffect, useState } from 'react';
import { createAuth0Client } from '@auth0/auth0-spa-js';
const DEFAULT_REDIRECT_CALLBACK = (appState) => window.history.replaceState({}, document.title, window.location.pathname);
export const Auth0Context = React.createContext(null);
export const useAuth0 = () => useContext(Auth0Context);
const Auth0Provider = (_a) => {
    var { children, onRedirectCallback = DEFAULT_REDIRECT_CALLBACK } = _a, initOptions = __rest(_a, ["children", "onRedirectCallback"]);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [user, setUser] = useState();
    const [auth0Client, setAuth0] = useState();
    const [loading, setLoading] = useState(true);
    const [popupOpen, setPopupOpen] = useState(false);
    const [error, setError] = useState(null);
    useEffect(() => {
        const initAuth0 = () => __awaiter(void 0, void 0, void 0, function* () {
            try {
                const auth0FromHook = yield createAuth0Client(Object.assign({}, initOptions));
                setAuth0(auth0FromHook);
                if (window.location.search.includes('code=') &&
                    window.location.search.includes('state=')) {
                    const { appState } = yield auth0FromHook.handleRedirectCallback();
                    onRedirectCallback(appState);
                }
                const isAuthenticated = yield auth0FromHook.isAuthenticated();
                setIsAuthenticated(isAuthenticated);
                if (isAuthenticated) {
                    const user = yield auth0FromHook.getUser();
                    setUser(user);
                }
                setLoading(false);
            }
            catch (err) {
                setError(err);
                setLoading(false);
            }
        });
        initAuth0();
        // eslint-disable-next-line
    }, []);
    const getToken = () => __awaiter(void 0, void 0, void 0, function* () {
        const { audience, scope } = initOptions;
        try {
            // @ts-ignore
            return yield auth0Client.getTokenSilently({
                audience,
                scope,
            });
        }
        catch (err) {
            try {
                // @ts-ignore
                return yield auth0Client.getTokenWithPopup({
                    audience,
                    scope,
                });
            }
            catch (err) {
                setError(err);
                return '';
            }
        }
    });
    const loginWithPopup = (params = {}) => __awaiter(void 0, void 0, void 0, function* () {
        setPopupOpen(true);
        try {
            // @ts-ignore
            yield auth0Client.loginWithPopup(params);
        }
        catch (error) {
            setError(error);
        }
        finally {
            setPopupOpen(false);
        }
        try {
            // @ts-ignore
            const user = yield auth0Client.getUser();
            setIsAuthenticated(true);
            setUser(user);
        }
        catch (error) {
            setError(error);
        }
    });
    const handleRedirectCallback = () => __awaiter(void 0, void 0, void 0, function* () {
        setLoading(true);
        try {
            // @ts-ignore
            yield auth0Client.handleRedirectCallback();
            // @ts-ignore
            const user = yield auth0Client.getUser();
            setLoading(false);
            setIsAuthenticated(true);
            setUser(user);
        }
        catch (err) {
            setLoading(false);
            setError(err);
        }
    });
    const getUser = (...p) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            // @ts-ignore
            return yield auth0Client.getUser(...p);
        }
        catch (error) {
            setError(error);
        }
    });
    const getIdTokenClaims = (...p) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            // @ts-ignore
            return yield auth0Client.getIdTokenClaims(...p);
        }
        catch (error) {
            setError(error);
        }
    });
    const loginWithRedirect = (...p) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            // @ts-ignore
            return yield auth0Client.loginWithRedirect(...p);
        }
        catch (error) {
            setError(error);
        }
    });
    // @ts-ignore
    const logout = (...p) => __awaiter(void 0, void 0, void 0, function* () { return yield auth0Client.logout(...p); });
    return (React.createElement(Auth0Context.Provider, { value: {
            error,
            isAuthenticated,
            user,
            loading,
            popupOpen,
            loginWithPopup,
            handleRedirectCallback,
            getToken,
            getUser,
            getIdTokenClaims,
            loginWithRedirect,
            logout,
            setError,
        } }, children));
};
export default Auth0Provider;
