/**
 * Partnership
 */
export interface Partnership {
  archived: boolean;
  business_information: {
    status: PartnerBusinessStatusEnumKeys;
    type: PartnerBusinessTypeEnumKeys;
  };
  created_at: string;
  demo_enabled: boolean;
  description: string;
  fw_update_enabled: boolean;
  id: string;
  internal: boolean;
  max_nb_of_shipments: number;
  /**
   * @deprecated Duplicated of `partner_id_card.client_name`
   */
  name: string;
  nb_of_shipments: number;
  partner_id_card: {
    client_id: string;
    client_name: string;
    company_id: string;
    country_code: string;
    email: string;
    internal_sales_name: string;
    vat_number: string;
    website: string;
  };
  phone_number: string;
  remote_control_enabled: boolean;
  secursus_enabled: boolean;
  updated_at: string;
  /**
   * @deprecated Duplicated of `partner_id_card.website`
   */
  website: string;
  default_sender_address_id?: string;
  default_recipient_address_id?: string;
  contract_id?: string;
}

/**
 * Miscellanous
 */

export const PartnerBusinessStatusEnum = {
  UNSPECIFIED: 'PARTNER_BUSINESS_STATUS_UNSPECIFIED',
  PENDING: 'PARTNER_BUSINESS_STATUS_PENDING',
  VALIDATED: 'PARTNER_BUSINESS_STATUS_VALIDATED',
  BLOCKED: 'PARTNER_BUSINESS_STATUS_BLOCKED',
} as const;

export type PartnerBusinessStatusEnumKeys =
  (typeof PartnerBusinessStatusEnum)[keyof typeof PartnerBusinessStatusEnum];

export const PartnerBusinessTypeEnum = {
  UNSPECIFIED: 'PARTNER_BUSINESS_TYPE_UNSPECIFIED',
  PERSONAL: 'PARTNER_BUSINESS_TYPE_PERSONAL',
  TEST: 'PARTNER_BUSINESS_TYPE_TEST',
  PRO: 'PARTNER_BUSINESS_TYPE_PRO',
} as const;

export type PartnerBusinessTypeEnumKeys =
  (typeof PartnerBusinessTypeEnum)[keyof typeof PartnerBusinessTypeEnum];
