import React from 'react';

import { Text } from '@livingpackets/design-system-react-next';
import { useTranslation } from 'react-i18next';

const EventBoxCode = () => {
  const { t } = useTranslation('shipments');

  return (
    <Text
      variant="bodyTextXS"
      sx={{ marginTop: '0.6rem', color: 'custom.error.red.100' }}
    >
      {t('detail.timeline.information.boxCode.allowedToUnlock')}
    </Text>
  );
};

export default EventBoxCode;
