import React from 'react';

import { Text, styled } from '@livingpackets/design-system-react-next';
import { layout, LayoutProps } from 'styled-system';

export interface ITab extends LayoutProps {
  icon: React.ReactChild;
  children: React.ReactNode;
  selected: boolean;
  onClick: any;
  testId?: string;
}

const StyledTab = styled('div')<LayoutProps & Pick<ITab, 'selected'>>`
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: ${({ theme, selected }) =>
    selected ? theme.palette.custom.primary[100] : 'none'};
  border-radius: 7px;
  padding: 10px;
  color: ${({ selected, theme }) =>
    selected
      ? theme.palette.custom.neutral.white.pure
      : theme.palette.custom.neutral.black[50]};
  ${layout};
  transition: all 100ms ease;
`;

const NavTab = ({
  height = '38px',
  selected,
  icon,
  onClick,
  children,
  testId,
}: ITab) => (
  <StyledTab
    height={height}
    selected={selected}
    onClick={onClick}
    data-testid={testId}
  >
    {icon}
    <Text variant="bodyTextL" marginLeft="6px">
      {children}
    </Text>
  </StyledTab>
);

export default NavTab;
