import { useCallback } from 'react';

import { PaginationParams } from '@livingpackets/shared-components';
import { useApiClient } from 'hooks/useApiClient';
import { IRole } from 'models/role';

export interface PaginatedResponse<Type> {
  items: Type[];
  total: number;
}

export interface PaginatedResponsePartnerRoles<Type> {
  items: Type[];
  count: number;
  total: number;
}

export interface GetPartnerRolesParams extends Partial<PaginationParams> {
  partnerId: string;
}

type GetPartnerRoles = (
  params: GetPartnerRolesParams
) => Promise<PaginatedResponsePartnerRoles<IRole>>;

const useGetPartnerRoles = (): GetPartnerRoles => {
  const lpVillage = useApiClient('lpVillage');

  return useCallback(
    ({
      page = 1,
      pageSize = 100,
      orderColumn = 'createdAt',
      orderDirection = 'DESC',
      partnerId,
    }: GetPartnerRolesParams) =>
      lpVillage
        .get<PaginatedResponse<IRole>>(`/api/v1/me/partners/${partnerId}/roles`)
        .then(response => {
          if (!('total' in response.data)) {
            (response.data as any).total = 0;
          }

          return {
            items: response.data.items,
            count: response.data.items.length,
            total: response.data.total,
          };
        }),
    [lpVillage]
  );
};

export default useGetPartnerRoles;
