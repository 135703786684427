import React from 'react';

import styled from 'styled-components';

const Dot = styled.div<{ placement: number; textArea?: boolean }>`
  position: absolute;
  width: 0.125rem;
  height: 0.125rem;
  border-radius: 0.0625rem;
  background-color: ${({ theme }) => theme.colors.primary[100]};
  right: ${({ placement }) => `calc(.5rem + ${placement * 0.2}rem)`};
  top: ${({ textArea }) => (textArea ? '80%' : '55%')};
  animation: ${({ placement, textArea }) =>
    `${textArea ? 'moveTextArea' : 'move'} .8s linear ${
      placement * 0.2
    }s infinite`};
  @keyframes move {
    0% {
      top: 55%;
    }
    50% {
      top: 45%;
    }
    100% {
      top: 55%;
    }
  }
  @keyframes moveTextArea {
    0% {
      top: 80%;
    }
    50% {
      top: 75%;
    }
    100% {
      top: 80%;
    }
  }
`;

const Dots = ({ textArea = false }: { textArea?: boolean }) => (
  <>
    <Dot placement={0} textArea={textArea} />
    <Dot placement={1} textArea={textArea} />
    <Dot placement={2} textArea={textArea} />
  </>
);

export default Dots;
