import React from 'react';

import { Stack, Text } from '@livingpackets/design-system-react-next';
import { useTranslation } from 'react-i18next';

interface Props {
  date: string;
}

export const LastUpdateAt = ({ date }: Props) => {
  const { t } = useTranslation(['profileSettings']);

  return (
    <Stack direction="row" gap="0.5rem" alignItems="center">
      <Text variant="bodyTextXS" sx={{ fontWeight: 500 }}>
        {t('profileSettings:lastUpdate')}
      </Text>
      <Text
        data-cy="updated-at"
        variant="bodyTextXS"
        color="custom.neutral.black.50"
      >
        {date}
      </Text>
    </Stack>
  );
};
