import { getKeyByValue } from './commandControlStatus';
import { getCoverStatusKeyByValue } from './converStatus';
import { getLockStatusKeyByValue } from './lockstatus';

export interface boxControl {
  boxCommandStatus: any;
  boxCoverState: any;
  boxLockState: any;
}

const BOX_CMD_BYTE_LENGTH = 3;

export const boxControlDecode = (
  data: DataView | undefined
): boxControl | undefined => {
  let boxCommandStatus;
  let boxCoverState;
  let boxLockState;

  if (data?.byteLength === BOX_CMD_BYTE_LENGTH) {
    const array = new Uint8Array(data.buffer, 0, 3);

    boxCommandStatus = getKeyByValue(array[0] as any);
    boxCoverState = getCoverStatusKeyByValue(array[1]);
    boxLockState = getLockStatusKeyByValue(array[2]);
  }

  return { boxCommandStatus, boxCoverState, boxLockState };
};
