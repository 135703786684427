import React from 'react';

import { styled, Theme } from '@livingpackets/design-system-react-next';
import AccountTypeIcon from 'components/atoms/AccountTypeIcon';
import { PartnerBusinessStatusEnum } from 'enums/accountTypeEnum';
import { IPartner } from 'models/partner';
import { animated } from 'react-spring';
import { IPartnerEntry } from 'stores/useMyPartnersStore';

const getBg = (
  theme: Theme,
  isActive: boolean,
  isOpen: boolean,
  isPartnerProPending: boolean
) => {
  if (isPartnerProPending) {
    return theme.palette.custom.neutral.black[50];
  }

  if (!isActive) {
    return theme.palette.custom.neutral.black[100];
  }

  return isOpen
    ? theme.palette.custom.primary[120]
    : theme.palette.custom.primary[100];
};

const Container = styled(animated.div, {
  shouldForwardProp: prop =>
    prop !== '$isOpen' &&
    prop !== '$isActive' &&
    prop !== '$isPartnerProPending',
})<{
  $isOpen: boolean;
  $isActive: boolean;
  $isPartnerProPending: boolean;
}>`
  background: ${({ $isOpen, theme, $isActive, $isPartnerProPending }) =>
    getBg(theme, $isActive, $isOpen, $isPartnerProPending)};
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.palette.custom.neutral.black[8]};
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  min-width: 40px;
  height: 40px;

  &:hover {
    background: ${({ theme, $isActive }) =>
      $isActive
        ? theme.palette.custom.primary[120]
        : theme.palette.custom.neutral.black[100]};
  }
`;

interface IBadge {
  isOpen: boolean;
  isActive: boolean;
  partner?: IPartner | IPartnerEntry;
}

const Badge = ({ isOpen, isActive, partner }: IBadge) => (
  <Container
    $isOpen={isOpen}
    $isActive={isActive}
    $isPartnerProPending={
      partner?.businessInformation.status === PartnerBusinessStatusEnum.PENDING
    }
  >
    <AccountTypeIcon partner={partner} />
  </Container>
);

export default Badge;
