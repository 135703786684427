import React, { useCallback, useEffect, useMemo, useState } from 'react';

import {
  DeleteIcon,
  DotsIcon,
  EditIcon,
  Text,
} from '@livingpackets/design-system-react-next';
import { PaginationParams } from '@livingpackets/shared-components';
import NameCardSkeleton from 'components/atoms/loadingSkeletons/NameCardSkeleton';
import NameCard from 'components/atoms/NameCard';
import StatusLabel from 'components/atoms/StatusLabel';
import { TableDesignTypeEnum } from 'components/atoms/TableComponents';
import DeletePartnerMemberModal from 'components/molecules/modals/DeletePartnerMemberModal';
import ReactTableTable, { TColumn } from 'components/molecules/ReactTableTable';
import { LpVillageScopes } from 'configs';
import { storeSelector } from 'helpers/paginatedStoreHelpers';
import usePartnerMemberManagement from 'hooks/useParternMemberMangement';
import { IShipment } from 'models/shipment';
import { IPartnerAccount } from 'models/user';
import { useTranslation } from 'react-i18next';
import { Row } from 'react-table';
import usePartnerMemberManagementStore from 'stores/usePartnerMemberManagementStore';
import styled from 'styled-components';
import { shallow } from 'zustand/shallow';

import { GetPartnerAccountsParams } from '../../hooks/useGetPartnerAccounts';
import useHasUserScopes from '../../hooks/useHasUserScopes';
import { usePartnerAccounts } from '../../hooks/usePartnerAccounts';

const IconWrap = styled.div<{ visible: boolean }>`
  display: ${({ visible }) => (visible ? 'initial' : 'none')};
  cursor: pointer;
`;

const IconsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const RowDivider = styled.div`
  height: 52px;
  width: 1px;
  background-color: ${({ theme }) => theme.colors.black[10]};
`;

const NameCardContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

interface IPartnerMembersTable {
  partnerAccounts: IPartnerAccount[];
  membersLoading: boolean;
  total: number;
  activePartnerAccount?: IPartnerAccount;
  pagination: PaginationParams;
  onPaginationChange: (params: Partial<PaginationParams>) => void;
  onMemberEdit: (user: IPartnerAccount) => void;
  partnerAccountsParams: GetPartnerAccountsParams;
}

const PartnerMembersTable = ({
  partnerAccounts,
  activePartnerAccount,
  pagination,
  onPaginationChange,
  onMemberEdit,
  total,
  partnerAccountsParams,
  membersLoading,
}: IPartnerMembersTable) => {
  const { t } = useTranslation(['general', 'userManagement']);

  const { deleteMember, loading: deletionInProgress } =
    usePartnerMemberManagement();

  const [activeRowEntry, setActiveRowEntry] = useState<IPartnerAccount>();

  const canDeletePartnerMember = useHasUserScopes([
    LpVillageScopes.deletePartnerUser,
  ]);
  const canEditPartnerMember = useHasUserScopes([
    LpVillageScopes.updatePartnerUser,
  ]);

  const { dispatch } = usePartnerMemberManagementStore(storeSelector, shallow);

  const [activeEntry] = useState<IPartnerAccount>();
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);

  useEffect(() => () => dispatch({ type: 'reset' }), [dispatch]);

  useEffect(() => {
    if (!showDeleteModal) {
      setActiveRowEntry(undefined);
    }
  }, [setActiveRowEntry, showDeleteModal]);
  const { refetch } = usePartnerAccounts(partnerAccountsParams);
  const handleDelete = useCallback(async () => {
    const { success } = await deleteMember(activeRowEntry?.account_id!);
    if (success) {
      dispatch({
        type: 'removePartnerMemberEntry',
        args: { accountId: activeRowEntry?.account_id! },
      });
      refetch();
      setShowDeleteModal(false);
    }
  }, [deleteMember, activeRowEntry, setShowDeleteModal, dispatch, refetch]);

  const handleDeleteClick = useCallback(
    (member: IPartnerAccount) => {
      setActiveRowEntry(member);
      setShowDeleteModal(true);
    },
    [setActiveRowEntry]
  );

  // @ts-ignore
  const columns: TColumn<IPartnerAccount>[] = useMemo(
    () => [
      {
        Header: '',
        id: 'icon',
        width: '80px',
        displayHasText: false,
        Cell: ({ row }: { row: Row<IPartnerAccount> }) => (
          <NameCardContainer>
            <NameCard
              firstName={row.original.first_name}
              lastName={row.original.last_name}
              email={row.original.email}
            />
          </NameCardContainer>
        ),
        align: 'center',
        skeleton: <NameCardSkeleton />,
      },
      {
        Header: '',
        displayHasText: false,
        id: 'nameCardDivider',
        Cell: ({ row }: { row: Row<IShipment> }) => <RowDivider />,
        width: '1px',
      },
      {
        Header: t('userManagement:list.header.lastName'),
        displayHasText: true,
        accessor: 'last_name',
        headerEmphasis: true,
        sortable: true,
        Cell: ({ row }: { row: Row<IPartnerAccount> }) => (
          <Text variant="titleXS">{row.original.last_name}</Text>
        ),
      },
      {
        Header: t('userManagement:list.header.firstName'),
        displayHasText: true,
        accessor: 'first_name',
        cellEmphasis: 'high',
        headerEmphasis: true,
        sortable: true,
        Cell: ({ row }: { row: Row<IPartnerAccount> }) => (
          <Text variant="titleXS">{row.original.first_name}</Text>
        ),
      },
      {
        Header: t('userManagement:list.header.email'),
        displayHasText: true,
        accessor: 'email',
        width: '20rem',
        headerEmphasis: true,
        sortable: true,
        Cell: ({ row }: { row: Row<IPartnerAccount> }) => (
          <Text variant="titleXS">{row.original.email}</Text>
        ),
      },
      {
        Header: t('userManagement:list.header.role'),
        displayHasText: true,
        accessor: 'role',
        dataTestId: 'member-role',
        headerEmphasis: true,
        sortable: true,
        Cell: ({ row }: { row: Row<IPartnerAccount> }) => (
          <Text variant="titleXS">{row.original.role.name}</Text>
        ),
      },
      {
        Header: !membersLoading ? (
          <StatusLabel status="primary">
            {t('general:list.total', {
              number: !membersLoading ? total : 0,
            })}
          </StatusLabel>
        ) : (
          <></>
        ),
        id: 'actions',
        width: '80px',
        displayHasText: false,
        Cell: ({
          row,
          isHovered,
        }: {
          row: Row<IPartnerAccount>;
          isHovered: boolean;
        }) => (
          <IconsWrapper>
            {isHovered && (
              <>
                <IconWrap
                  onClick={_ => onMemberEdit(row.original)}
                  visible={canEditPartnerMember}
                >
                  <EditIcon />
                </IconWrap>
                <IconWrap
                  onClick={_ => handleDeleteClick(row.original)}
                  visible={canDeletePartnerMember}
                >
                  <DeleteIcon />
                </IconWrap>
              </>
            )}
            {!isHovered && <DotsIcon />}
          </IconsWrapper>
        ),
        skeleton: <div />,
      },
    ],
    [
      t,
      canEditPartnerMember,
      canDeletePartnerMember,
      onMemberEdit,
      handleDeleteClick,
      total,
      membersLoading,
    ]
  );

  const handlePaginationChange = useCallback(
    ({ pageSize, offset }: { offset: number; pageSize: number }): void =>
      onPaginationChange({ page: 1 + offset / pageSize, pageSize }),
    [onPaginationChange]
  );

  const handleSortingChange = useCallback(
    ({ order, sortBy }: { sortBy: string; order: 'ASC' | 'DESC' }): void =>
      onPaginationChange({ orderColumn: sortBy, orderDirection: order }),
    [onPaginationChange]
  );

  return (
    <>
      <ReactTableTable<IPartnerAccount>
        dataTestId="partner-users-table"
        columns={columns}
        activeRowId={activePartnerAccount?.account_id}
        data={partnerAccounts}
        pagination={{
          pageSize: pagination.pageSize,
          offset: (pagination.page - 1) * pagination.pageSize,
          total: total,
        }}
        sorting={{
          sortBy: pagination.orderColumn,
          order: pagination.orderDirection,
        }}
        onPaginationChange={handlePaginationChange}
        onSortingChange={handleSortingChange}
        tableDesignType={TableDesignTypeEnum.shipmentListTable}
        rowHeight="80px"
        loading={membersLoading}
        style={{ height: 'initial', minHeight: 0 }}
      />
      <DeletePartnerMemberModal
        open={showDeleteModal}
        onSubmit={handleDelete}
        onCancel={() => setShowDeleteModal(false)}
        entry={activeEntry!}
        loading={deletionInProgress}
      />
    </>
  );
};

export default PartnerMembersTable;
