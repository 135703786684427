import React from 'react';

import * as Sentry from '@sentry/react';
import {
  REACT_APP_WEBAPP_VERSION,
  SENTRY_DEBUG,
  SENTRY_DSN,
  SENTRY_ENABLED,
  SENTRY_ENVIRONMENT,
} from 'configs';
import {
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
} from 'react-router-dom';

const initSentry = () => {
  Sentry.init({
    debug: SENTRY_DEBUG,
    dsn: SENTRY_DSN,
    enabled: SENTRY_ENABLED,
    environment: SENTRY_ENVIRONMENT,
    ignoreErrors: ['Non-Error exception captured'],
    release: REACT_APP_WEBAPP_VERSION,
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          React.useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes
        ),
      }),
    ],
    tracesSampleRate: 1.0,
  });
};

export default initSentry;
