import React from 'react';

import { Button } from '@livingpackets/design-system-react';
import {
  ArrowRightIcon,
  Box,
  Text,
  Stack,
  styled,
} from '@livingpackets/design-system-react-next';
import ShipmentSumUpCard from 'components/molecules/ShipmentSumUpCard';
import ShipmentSumUpSkeleton from 'components/molecules/ShipmentSumUpSkeleton';
import { Carriers } from 'configs/carriers';
import { IFiguralInsurance } from 'models/figuralInsurance';
import { IOcrLabel } from 'models/shipment';
import { useTranslation } from 'react-i18next';

const Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  padding-top: 35px;
  padding-left: 16px;
  padding-right: 16px;
`;

const WrapperCreate = styled(Stack, {
  shouldForwardProp: prop => prop !== 'isReturnLabelCreated',
})<{ isReturnLabelCreated: boolean }>`
  flex-direction: row;
  justify-content: ${({ isReturnLabelCreated }) =>
    isReturnLabelCreated ? 'flex-end' : 'space-between'};
  align-items: center;
  padding-top: 32px;
`;

interface ImportShipmentOverviewProps {
  label: IOcrLabel;
  returnLabel: IOcrLabel;
  carrierCode: Carriers;
  onChangeOutgoingLabel: () => void;
  onChangeReturnLabel: () => void;
  onAddReturnLabel: () => void;
  onRemoveReturnLabel: () => void;
  onSubmit: () => void;
  onOpenFiguralInsuranceFormModal: () => void;
  onOpenFiguralInsuranceSummaryModal: () => void;
  figuralInsuranceData?: IFiguralInsurance;
  isShipmentCreationButtonDisabled: boolean;
}

const ImportShipmentOverview = ({
  label,
  returnLabel,
  carrierCode,
  onChangeOutgoingLabel,
  onChangeReturnLabel,
  onAddReturnLabel,
  onRemoveReturnLabel,
  onSubmit,
  onOpenFiguralInsuranceFormModal,
  onOpenFiguralInsuranceSummaryModal,
  figuralInsuranceData,
  isShipmentCreationButtonDisabled,
}: ImportShipmentOverviewProps) => {
  const { t } = useTranslation('shipments');

  return (
    <Wrapper>
      <Stack pb="0.375rem">
        <Text variant="titleS" color="custom.neutral.black.100">
          {t('importLabel.sumUp.title')}
        </Text>
        <Text
          variant="bodyTextM"
          color="custom.neutral.black.100"
          mt=".125rem"
          mb={3}
        >
          {t('importLabel.sumUp.subTitle')}
        </Text>
      </Stack>
      <ShipmentSumUpCard
        onEdit={onChangeOutgoingLabel}
        carrierCode={carrierCode}
        label={label}
        onOpenFiguralInsuranceSummaryModal={onOpenFiguralInsuranceSummaryModal}
        figuralInsuranceData={figuralInsuranceData}
        isReturnLabelSummary={false}
      />
      {returnLabel.referenceNumber ? (
        <ShipmentSumUpCard
          onEdit={onChangeReturnLabel}
          carrierCode={carrierCode}
          label={returnLabel}
          onRemove={onRemoveReturnLabel}
          onOpenFiguralInsuranceSummaryModal={
            onOpenFiguralInsuranceSummaryModal
          }
          figuralInsuranceData={figuralInsuranceData}
          isReturnLabelSummary={true}
        />
      ) : (
        <ShipmentSumUpSkeleton onClickAddReturn={onAddReturnLabel} />
      )}
      <WrapperCreate
        isReturnLabelCreated={returnLabel.referenceNumber !== undefined}
      >
        {!returnLabel.referenceNumber && (
          <Text
            variant="bodyTextM"
            color="custom.neutral.black.100"
            sx={{ width: '70%' }}
          >
            {t('importLabel.sumUp.warningMissingReturnLabel')}
          </Text>
        )}
        <Box style={{ minWidth: '210px', textAlign: 'right' }}>
          <Button
            data-testid="create-shipment"
            action="primary"
            icon={<ArrowRightIcon />}
            onClick={onSubmit}
            disabled={isShipmentCreationButtonDisabled}
          >
            {t('createShipment')}
          </Button>
        </Box>
      </WrapperCreate>
    </Wrapper>
  );
};

export default ImportShipmentOverview;
