import React from 'react';

import {
  Box,
  BuyRentIcon,
  Text,
  styled,
  useTheme,
} from '@livingpackets/design-system-react-next';
import { usePagination } from '@livingpackets/shared-components';
import Row from 'components/atoms/Row';
import BoxContractsList from 'components/molecules/BoxContractsList';
import { REACT_APP_TRANSACTIONS_BLOCK_DISPLAY } from 'configs';
import useBoxContract from 'hooks/useBoxContract';
import useGetPrimaryAddress from 'hooks/useGetPrimaryAddress';
import { useShipments } from 'hooks/useShipments';
import { useUserShipments } from 'hooks/useUserShipments';
import { useTranslation } from 'react-i18next';

import useFormatTimestamp from '../../hooks/useFormatTimestamp';
import useGetRents from '../../hooks/useGetRents';
import useBoxContractsStore, {
  boxContractSelector,
} from '../../stores/useBoxContractsStore';
import {
  getNewsletterDateFormat,
  getSMArticleDateFormat,
} from '../commonFunctions/DateCalculation';
import BalanceCard from '../molecules/BalanceCard';
import BuyAndRentBox from '../molecules/BuyAndRentBox';
import TransactionsList from '../molecules/TransactionsList';

const Wrapper = styled('div')<{ display?: string }>`
  display: ${({ display }) => display};
`;

const TabsWrapper = styled('div')`
  display: flex;
  margin-top: 2rem;
  width: 100%;
  border-bottom: 1px solid
    ${({ theme }) => theme.palette.custom.neutral.black[10]};
`;
const TabWrapper = styled('div')`
  margin-left: 3rem;
  height: 2rem;
`;
const ProfileBuyAndRent = () => {
  const { params: paginationParams, setParams: setPaginationParams } =
    usePagination({
      page: 1,
      pageSize: 5,
      orderDirection: 'ASC',
      orderColumn: 'receivedAt',
    });
  const { params: mapPaginationParams } = usePagination({
    page: 1,
    pageSize: 100,
    orderDirection: 'ASC',
    orderColumn: 'receivedAt',
  });
  const {
    i18n: { language },
  } = useTranslation();
  useBoxContract();
  useGetPrimaryAddress();
  const fetchShipments = useUserShipments(paginationParams);
  useShipments(mapPaginationParams);

  const contracts = useBoxContractsStore(boxContractSelector);
  const { t } = useTranslation('buyAndRent');
  const { rents } = useGetRents();
  const theme = useTheme();
  const formatTimestamp = useFormatTimestamp();

  return (
    <Row flexDirection="column">
      <Wrapper display="flex">
        <Box sx={{ width: '70%' }}>
          <BalanceCard
            payoutAvailable={false}
            title={t('buyAndRent')}
            icon={<BuyRentIcon color={theme.colors.white} />}
            amount={t('buyNRent.balanceCard.reward', {
              reward: rents.total,
            })}
            payoutAvailability={t(
              'buyAndRent:buyNRent.balanceCard.availableFunds',
              {
                date: rents.available_at
                  ? formatTimestamp(
                      new Date(rents.available_at),
                      getNewsletterDateFormat()
                    )
                  : null,
                defaultValue: '',
              }
            )}
            investments={t('buyNRent.balanceCard.numberBoxesPurchased')}
            investmentAmount={rents.product_count}
            firstLine={
              rents.shipment_count > 1
                ? t('buyNRent.balanceCard.shipmentsCount', {
                    shipmentCount: rents.shipment_count,
                  })
                : t('buyNRent.balanceCard.shipmentsCountSingular', {
                    shipmentCount: rents.shipment_count,
                  })
            }
            initialDate={
              !!rents.activated_at &&
              t('buyAndRent:buyNRent.balanceCard.activationDate', {
                activationDate: rents.activated_at
                  ? formatTimestamp(
                      new Date(rents.activated_at),
                      getSMArticleDateFormat(language)
                    )
                  : null,
                defaultValue: '',
              })
            }
            secondLine={
              rents.contract_count > 1
                ? t('buyNRent.balanceCard.contractCount', {
                    contractCount: rents.contract_count,
                  })
                : t('buyNRent.balanceCard.contractCountSingular', {
                    contractCount: rents.contract_count,
                  })
            }
            cardHeaderColor={theme.colors.secondary.pantone['328C']}
            cardBalanceColor={theme.colors.primary[150]}
          />
        </Box>
        <BuyAndRentBox />
      </Wrapper>
      {contracts.length !== 0 && (
        <Wrapper>
          <TabsWrapper>
            <TabWrapper>
              <Text variant="titleM" color="custom.primary.100">
                {t('contracts')}
              </Text>
            </TabWrapper>
          </TabsWrapper>
          <BoxContractsList
            contracts={contracts}
            pagination={paginationParams}
            onOrderChange={setPaginationParams}
            onPaginationChange={setPaginationParams}
          />
          {REACT_APP_TRANSACTIONS_BLOCK_DISPLAY && (
            <TransactionsList
              shipments={fetchShipments}
              pagination={paginationParams}
              onOrderChange={setPaginationParams}
              onPaginationChange={setPaginationParams}
            />
          )}
        </Wrapper>
      )}
    </Row>
  );
};
export default ProfileBuyAndRent;
