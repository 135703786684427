import React, { useMemo } from 'react';

import { Box } from '@livingpackets/design-system-react-next';
import PrivateRoute from 'components/containers/PrivateRoute';
import { find } from 'lodash/fp';
import { Navigate, useRoutes } from 'react-router-dom';
import useMyPartnersStore, {
  activePartnerSelector,
} from 'stores/useMyPartnersStore';

import { Partnership } from './Partnership';
import { Personal } from './Personal';
import { Finance } from '../components/partnership/finance/Finance';
import { Information } from '../components/partnership/information/Information';
import { Offer } from '../components/partnership/offer';
import { Supply } from '../components/partnership/supply/Supply';

/**
 * Base URL: /partner/:partnerId/account/*
 */
export const PARTNER_ACCOUNT_ROOT = '/account';

export const PartnerAccount = () => {
  const { role } = useMyPartnersStore(activePartnerSelector);

  const canUpdatePartner = useMemo(
    () => !!find({ name: 'update:partner' }, role.scopes),
    [role.scopes]
  );

  const routes = [
    // Root
    { path: '', element: <Navigate to="personal" replace /> },
    // Routes
    { path: '/personal', element: <Personal /> },
    {
      path: '/personal/*',
      element: <Navigate to="." replace />,
    },
    {
      path: '/partnership',
      element: (
        <PrivateRoute
          component={<Partnership />}
          isAvailableForCurrentUser={canUpdatePartner}
        />
      ),
      children: [
        { index: true, element: <Navigate to="information" replace /> },
        { path: 'information', element: <Information /> },
        { path: 'offer', element: <Offer /> },
        { path: 'finance', element: <Finance /> },
        { path: 'supply_address', element: <Supply /> },
        { path: '*', element: <Navigate to="." replace /> },
      ],
    },
    // Catch-all => Redirect to root
    { path: '*', element: <Navigate to="." /> },
  ];

  const element = useRoutes(routes);

  // Hide tabs if user cannot update partner
  if (!canUpdatePartner) return <Personal hideTabs />;

  return <Box pb="1rem">{element}</Box>;
};
