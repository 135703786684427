export interface IScope {
  createdAt: number;
  createdBy: string;
  description: string;
  id: string;
  name: string;
  updatedAt: number;
}

export interface IRole {
  createdAt: number;
  createdBy: string;
  description: string;
  id: string;
  name: string;
  scopes: IScope[];
  type: string;
  updatedAt: number;
}

export const EMPTY_ROLE: IRole = {
  createdAt: 0,
  createdBy: '',
  description: '',
  id: '',
  name: '',
  scopes: [],
  type: '',
  updatedAt: 0,
};
