import React from 'react';

import {
  ButtonV2 as Button,
  Text,
  styled,
} from '@livingpackets/design-system-react-next';
import { useTranslation } from 'react-i18next';

import imageName from '../../../assets/img/boxes/BoxFamilyPresentation.png';
import imageName2x from '../../../assets/img/boxes/BoxFamilyPresentation_2x.png';
import imageName3x from '../../../assets/img/boxes/BoxFamilyPresentation_3x.png';

const WebsiteDiv = styled('div')`
  background-color: ${({ theme }) => theme.palette.custom.primary[100]};
  display: flex;
  align-items: center;
  width: 100%;
  height: 12rem;
  border-radius: 20px;
  z-index: 1;
`;

const ImageDiv = styled('div', {
  shouldForwardProp: prop =>
    prop !== 'imageName' && prop !== 'imageName2x' && prop !== 'imageName3x',
})<{
  imageName: string;
  imageName2x: string;
  imageName3x: string;
}>`
  width: 40%;
  height: 78%;
  background-image: url(${({ imageName }) => imageName});
  background-image: -webkit-image-set
    (
      url(${({ imageName2x }) => imageName2x}) 1x,
      url(${({ imageName3x }) => imageName3x}) 2x;
    );
  background-size: contain;
  background-repeat: no-repeat;
  align-self: center;
  justify-self: flex-start;
`;

const WebsiteLinkCard = () => {
  const { t } = useTranslation(['contribution', 'general']);

  return (
    <WebsiteDiv>
      <ImageDiv
        imageName={imageName}
        imageName2x={imageName2x}
        imageName3x={imageName3x}
      />
      <div>
        <Text variant="titleXL" color="custom.neutral.white.pure" mb="1rem">
          {t('contribution:contributionsTab.websiteLink.discover')}
        </Text>
        <Button
          variant="secondary"
          onClick={() =>
            window.open(
              t('general:websiteUrl.url'),
              '_blank',
              'noopener noreferrer'
            )
          }
        >
          {t('contribution:contributionsTab.websiteLink.redirectToWebsite')}
        </Button>
      </div>
    </WebsiteDiv>
  );
};
export default WebsiteLinkCard;
