import { useQuery } from '@tanstack/react-query';
import useImperativeRequest from 'hooks/useImperativeRequest';
import { ExtractFnReturnType, QueryConfig } from 'lib/react-query';
import useMyPartnersStore, {
  IPartnerEntry,
  activePartnerSelector,
} from 'stores/useMyPartnersStore';

import { ITrackingCode } from '../types/trackingCode';

export const getTrackingCodes = async ({
  makeRequest,
  shipmentId,
  activePartner,
}: {
  makeRequest: any;
  shipmentId: string;
  activePartner: IPartnerEntry;
}): Promise<ITrackingCode[]> => {
  const { data, error } = await makeRequest({
    path: `api/v2/partners/${activePartner.id}/shipments/${shipmentId}/tracking-codes`,
  });
  if (error) {
    throw new Error(error);
  }

  return data.trackingCodes;
};

type QueryFnType = typeof getTrackingCodes;

type UseTrackingCodeOptions = {
  shipmentId: string;
  config?: QueryConfig<QueryFnType>;
};

export const useGetTrackingCodes = ({
  shipmentId,
  config,
}: UseTrackingCodeOptions) => {
  const activePartner = useMyPartnersStore(activePartnerSelector);
  const [, makeRequest] = useImperativeRequest('shipment');

  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: ['trackingCodes', shipmentId],
    queryFn: () => getTrackingCodes({ makeRequest, shipmentId, activePartner }),
  });
};
