import React, { useCallback, useEffect, useMemo } from 'react';

import {
  styled,
  ChevronDownIcon,
  useTheme,
  Collapse,
  EditIcon,
} from '@livingpackets/design-system-react-next';
import useHeader from 'components/containers/layout/hooks/useHeader';
import {
  PartnerBusinessStatusEnum,
  PartnerBusinessTypeEnum,
} from 'enums/accountTypeEnum';
import { AccountSelectionDisplayMode } from 'enums/layout';
import { UserBusinessTypeEnum } from 'enums/UserBusinessTypeEnum';
import useNavRouteMatch from 'hooks/useNavRouteMatch';
import { IPartner } from 'models/partner';
import { useMatch } from 'react-router-dom';
import { animated, config, useSpring } from 'react-spring';
import useAppState, { userSelector } from 'stores/appState/useAppState';
import useCurrentPartnerStore, {
  currentPartnerSelector,
} from 'stores/useCurrentPartnerStore';
import useLayoutStore, {
  openAccountSelectionSelector,
  setOpenAccountSelectionSelector,
  setOpenNavSelector,
} from 'stores/useLayoutStore';
import useMyPartnersStore, {
  partnersSelector,
} from 'stores/useMyPartnersStore';

import LPAccountLink from './LPAccountLink';
import PartnerLink from './PartnerLink';

const EditIconContainer = styled(animated.div)`
  position: absolute;
  top: 0.2rem;
  right: 0.1rem;
  cursor: pointer;
  z-index: 2;
`;

const Wrapper = styled(animated.div, {
  shouldForwardProp: prop => prop !== '$isSelectable',
})<{ $isSelectable: boolean }>`
  text-align: start;
  padding: 0;
  background-color: inherit;
  border: none;
  cursor: ${({ $isSelectable }) => ($isSelectable ? 'pointer' : 'initial')};
`;

const ButtonsContainer = styled(animated.div, {
  shouldForwardProp: prop => prop !== '$open',
})<{ $open: boolean }>`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  background-color: ${({ theme, $open }) =>
    $open ? theme.palette.custom.neutral.white.pure : 'inherit'};
  transition: border 300ms ease;
  border-radius: 0.75rem;
  border: 0.25rem solid transparent;
  margin: 0.25rem;

  &:hover {
    border: 0.25rem solid rgb(247, 247, 247);
  }
`;

const ScrollContainer = styled(animated.div)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  overflow-y: auto;
  max-height: 21rem;
  padding-bottom: 1.25rem;

  ::-webkit-scrollbar {
    width: 0.25rem;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 1.25rem;
    background: ${({ theme }) => theme.palette.custom.neutral.black[20]};
  }

  ::-webkit-scrollbar-button {
    height: 0.5rem;
  }
`;

const ScrollContainerDivider = styled('div')`
  display: flex;
  align-items: center;
  width: 85%;
  height: 0.0625rem;
  background-color: ${({ theme }) => theme.palette.custom.neutral.black[8]};
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0.625rem;
`;

const ChevronContainer = styled(animated.div, {
  shouldForwardProp: prop => prop !== 'chevronContainerBgColor',
})<{
  chevronContainerBgColor: string;
}>`
  width: 105%;
  background-color: ${({ chevronContainerBgColor }) => chevronContainerBgColor};
  margin-left: 0 !important;
  display: flex;
  justify-content: center;
  border-radius: 0 0 0.5rem 0.5rem;
  margin-bottom: -0.25rem;
`;

interface IpartnershipSelectorProps {
  open: boolean;
}

const PartnershipSelector = ({ open }: IpartnershipSelectorProps) => {
  const theme = useTheme();

  const { context, partnerId } = useNavRouteMatch();
  const partners = useMyPartnersStore(partnersSelector);
  const setOpenNav = useLayoutStore(setOpenNavSelector);
  const currentUser = useAppState(userSelector);
  const currentPartner: IPartner = useCurrentPartnerStore(
    currentPartnerSelector
  );
  const isUserActive = context === 'lpaccount';
  const isPartnerActive = useCallback(
    (id: string) => context === 'partner' && partnerId === id,
    [context, partnerId]
  );

  const { redirectToSettingsPage, animatedEditIconStyles } = useHeader();

  const isAccountSelectionOpen = useLayoutStore(openAccountSelectionSelector);
  const setOpenAccountSelection = useLayoutStore(
    setOpenAccountSelectionSelector
  );

  const onChange = (triggerOpenAccountSelection: boolean = false) => {
    if (!open) {
      setOpenNav(true);

      if (triggerOpenAccountSelection) {
        setOpenAccountSelection(true);
      }
    } else {
      if (triggerOpenAccountSelection) {
        setOpenAccountSelection(!isAccountSelectionOpen);
      }
    }
  };

  useEffect(() => {
    if (!open) {
      setOpenAccountSelection(false);
    }
  }, [open, setOpenAccountSelection]);

  const activeAccount = useMemo(() => {
    if (isUserActive) {
      return <LPAccountLink currentPartner={currentPartner} />;
    }
    const partner = partners.find(partner => isPartnerActive(partner.id));
    if (partner) {
      return (
        <PartnerLink
          partner={partner}
          canBeSelected={partners.length > 1}
          displayMode={AccountSelectionDisplayMode.BIG}
        />
      );
    }
  }, [currentPartner, isPartnerActive, isUserActive, partners]);

  const getChevronContainerBgColor = useCallback(() => {
    if (!currentPartner) {
      return theme.colors.transparent;
    }

    const businessInformation = currentPartner.businessInformation;

    if (
      businessInformation.type === PartnerBusinessTypeEnum.PRO &&
      businessInformation.status === PartnerBusinessStatusEnum.PENDING
    ) {
      return theme.palette.custom.neutral.black[4];
    }

    if (businessInformation.type === PartnerBusinessTypeEnum.TEST) {
      return theme.colors.secondary.blue[10];
    }

    return theme.colors.transparent;
  }, [currentPartner, theme]);

  const wrapperStyles = useSpring({
    config: config.default,
    from: {
      width: '4.0625rem',
      marginLeft: '-0.75rem',
    },
    to: {
      width: open ? '13.75rem' : '4.0625rem',
      marginLeft: open ? '-0.75rem' : '-1.0625rem',
    },
  });

  const buttonContainerStyles = useSpring({
    config: config.default,
    from: {
      boxShadow: '0 0 0 rgba(0, 0, 0, 0.08)',
    },
    to: {
      boxShadow: open
        ? '0 .25rem 1rem rgba(0, 0, 0, 0.08)'
        : '0 0 0 rgba(0, 0, 0, 0.08)',
    },
  });

  const chevronContainerStyles = useSpring({
    config: config.default,
    from: { opacity: 0, display: 'none' },
    to: {
      opacity: open ? 1 : 0,
      display: open ? 'flex' : 'none',
    },
  });

  const partnerListWithoutCurrentPartner = partners.filter(
    partner => !isPartnerActive(partner.id)
  );

  const matchPartnerRoute = useMatch('/partner/:partnerId/*');

  // If partner 404, then open the partner selector when opening the navigation menu
  useEffect(() => {
    if (
      currentUser?.businessType === UserBusinessTypeEnum.BUSINESS &&
      !matchPartnerRoute &&
      open
    ) {
      setOpenAccountSelection(true);
    }
  }, [open, currentUser, matchPartnerRoute, setOpenAccountSelection]);

  if (
    currentUser?.businessType === UserBusinessTypeEnum.PERSONAL ||
    currentUser?.businessType === UserBusinessTypeEnum.UNKNOWN
  ) {
    return (
      <Wrapper style={wrapperStyles} $isSelectable={!open}>
        <ButtonsContainer
          $open={isAccountSelectionOpen}
          style={buttonContainerStyles}
        >
          {activeAccount}
        </ButtonsContainer>
      </Wrapper>
    );
  }

  if (currentUser?.businessType === UserBusinessTypeEnum.BUSINESS) {
    if (partners.length === 1) {
      return (
        <Wrapper
          onClick={() => onChange(false)}
          style={wrapperStyles}
          $isSelectable={!open}
        >
          <ButtonsContainer
            $open={isAccountSelectionOpen}
            style={buttonContainerStyles}
          >
            {activeAccount}
          </ButtonsContainer>
        </Wrapper>
      );
    }

    return (
      <Wrapper
        $isSelectable
        onClick={() => onChange(true)}
        style={wrapperStyles}
      >
        <ButtonsContainer
          $open={isAccountSelectionOpen}
          style={buttonContainerStyles}
        >
          {matchPartnerRoute && (
            <EditIconContainer
              style={animatedEditIconStyles}
              onClick={redirectToSettingsPage}
              data-testid="menu-sideNavigation-editIcon"
            >
              <EditIcon />
            </EditIconContainer>
          )}
          {activeAccount}
          <Collapse in={isAccountSelectionOpen} sx={{ width: '100%' }}>
            <ScrollContainerDivider />
            <ScrollContainer>
              {partnerListWithoutCurrentPartner.map(partner => (
                <PartnerLink
                  key={partner.id}
                  partner={partner}
                  displayMode={AccountSelectionDisplayMode.SHORT}
                />
              ))}
            </ScrollContainer>
          </Collapse>
          <ChevronContainer
            style={chevronContainerStyles}
            chevronContainerBgColor={getChevronContainerBgColor()}
          >
            <ChevronDownIcon
              color={theme.colors.black[100]}
              style={{
                transform: isAccountSelectionOpen
                  ? 'rotate(180deg)'
                  : 'rotate(0deg)',
                transition: '300ms',
                minWidth: '1.5rem',
              }}
            />
          </ChevronContainer>
        </ButtonsContainer>
      </Wrapper>
    );
  }

  return <></>;
};

export default PartnershipSelector;
