import React, { useCallback, useState } from 'react';

import {
  ChevronDownIcon,
  Text,
  styled,
  useTheme,
} from '@livingpackets/design-system-react-next';
import {
  OrderDirection,
  PaginationParams,
} from '@livingpackets/shared-components';
import Divider from 'components/atoms/Divider';
import BoxContractListItem from 'components/molecules/BoxContractListItem';
import { IBoxContract } from 'models/boxContract';
import { useTranslation } from 'react-i18next';
import { space } from 'styled-system';

import useBoxContract from '../../hooks/useBoxContract';
import useBoxContractsStore, {
  orderSelector,
  setOrderSelector,
} from '../../stores/useBoxContractsStore';
import Row from '../atoms/Row';

const Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  margin-top: 3rem;
  margin-bottom: 1.406rem;
  ${space}
`;
const HeaderWrapper = styled('div')`
  display: flex;
  width: 100%;
  margin-bottom: 0.75rem;
`;

const StyledSpan = styled(Text)``;

StyledSpan.defaultProps = {
  variant: 'titleS',
  color: 'custom.neutral.black.50',
};

const SortWrapper = styled('div')`
  color: ${({ theme }) => theme.palette.custom.neutral.black[50]};
  background: none;
  border: none;
  display: inline-flex;
  align-items: center;
  ${space}
`;

interface BoxContractListProps {
  isPartner?: boolean;
  contracts?: IBoxContract[];
  pagination: PaginationParams;
  onOrderChange: (order: {
    orderColumn: string;
    orderDirection: OrderDirection;
  }) => void;
  onPaginationChange: (pagination: { page: number }) => void;
}

function BoxContractsList({
  isPartner = false,
  contracts = [],
}: BoxContractListProps) {
  const { t } = useTranslation('buyAndRent');
  const theme = useTheme();
  const order = useBoxContractsStore(orderSelector);
  const setOrder = useBoxContractsStore(setOrderSelector);
  useBoxContract();
  const [sorted, setSorted] = useState(false);
  const setASC = useCallback(() => {
    contracts.sort(
      (a: { totalAmountInCents: number }, b: { totalAmountInCents: number }) =>
        a.totalAmountInCents > b.totalAmountInCents ? 1 : -1
    );
  }, [contracts]);
  const setDESC = useCallback(() => {
    contracts.sort(
      (a: { totalAmountInCents: number }, b: { totalAmountInCents: number }) =>
        b.totalAmountInCents > a.totalAmountInCents ? 1 : -1
    );
  }, [contracts]);
  const handleAmountSort = useCallback(() => {
    setSorted(prevState => !prevState);
    if (sorted) {
      setDESC();
    } else {
      setASC();
    }
  }, [sorted, setSorted, setASC, setDESC]);

  const handleSort = useCallback(() => {
    if (order === 'ASC') {
      setOrder('DESC');

      return;
    } else {
      setOrder('ASC');
    }
  }, [order, setOrder]);

  const renderRows = () =>
    contracts.map((contract: IBoxContract) => (
      <BoxContractListItem
        key={contract.id}
        isPartner={isPartner}
        boxContract={contract}
      />
    ));

  return (
    <Wrapper>
      <HeaderWrapper>
        <Row width="21%">
          <SortWrapper onClick={handleSort}>
            <StyledSpan
              style={{
                marginRight: '0.5rem',
                display: 'flex',
                marginLeft: '2rem',
              }}
            >
              {t('buyNRent.contracts.purchasedDate')}
            </StyledSpan>
            <ChevronDownIcon
              color={theme.colors.primary[100]}
              style={{
                transform: order === 'DESC' ? 'rotate(180deg)' : 'rotate(0deg)',
                transition: '500ms',
              }}
            />
          </SortWrapper>
        </Row>
        <Row width="20%" alignItems="center">
          <StyledSpan>{t('buyNRent.contracts.paymentMethod')}</StyledSpan>
        </Row>
        <Row width="17%">
          <SortWrapper>
            <StyledSpan>{t('buyNRent.contracts.bought')}</StyledSpan>
          </SortWrapper>
        </Row>
        <Row width="20%">
          <SortWrapper onClick={handleAmountSort}>
            <StyledSpan style={{ marginRight: '0.5rem' }}>
              {t('buyNRent.contracts.amount')}
            </StyledSpan>
            <ChevronDownIcon
              color={theme.colors.primary[100]}
              style={{
                transform: !sorted ? 'rotate(180deg)' : 'rotate(0deg)',
                transition: '500ms',
              }}
            />
          </SortWrapper>
        </Row>
        <Row alignItems="center">
          <StyledSpan>{t('buyNRent.contracts.contractsPDF')}</StyledSpan>
        </Row>
      </HeaderWrapper>
      <Divider direction="horizontal" />
      {renderRows()}
    </Wrapper>
  );
}

export default BoxContractsList;
