import React from 'react';

import {
  ShipmentArchiveIcon,
  Text,
  ButtonV2 as Button,
  Stack,
  ModalV2 as Modal,
  Box,
} from '@livingpackets/design-system-react-next';
import { useTranslation } from 'react-i18next';

const ArchiveShipmentModal = ({
  open,
  onSubmit,
  onCancel,
  loading,
}: {
  open: boolean;
  onSubmit: () => void;
  onCancel: () => void;
  loading: boolean;
}) => {
  const { t } = useTranslation('shipments');

  return (
    <Modal open={open} setOpen={onCancel}>
      <Box
        sx={theme => ({
          [theme.breakpoints.up('tablet')]: {
            minWidth: '34.9375rem',
          },
        })}
      >
        <Text variant="titleM">{t('modal.archiveShipment.title')}</Text>
        <Text variant="bodyTextS" mt="1.5625rem">
          {t('modal.archiveShipment.warning')}
        </Text>
        <Stack direction="row" justifyContent="space-between" mt="2rem">
          <Button variant="secondary" onClick={onCancel}>
            {t('modal.archiveShipment.button.dismiss')}
          </Button>
          <Button
            icon={ShipmentArchiveIcon}
            onClick={onSubmit}
            disabled={loading}
            data-testid="archive-shipment-button"
          >
            {t('modal.archiveShipment.button.submit')}
          </Button>
        </Stack>
      </Box>
    </Modal>
  );
};

export default ArchiveShipmentModal;
