import * as Sentry from '@sentry/react';

export enum ErrorTypes {
  appError = 'AppError',
  auth0Error = 'Auth0Error',
}

interface IOptions {
  type?: ErrorTypes;
}

export const captureError = (error: any, options?: IOptions) => {
  ineum(
    'reportError',
    error,
    options?.type ? { meta: { type: options.type } } : undefined
  );
  Sentry.captureException(error);
};
