import React from 'react';

import MemberForm from 'components/molecules/Form/MemberForm';
import { IMemberForm, IMember } from 'models/user';

interface IMemberType {
  onValuesChanged: (values: IMemberForm) => void;
  isFormValidChanged: (isFormValid: boolean) => void;
  defaultValues: IMember;
}

const PartnerMemberEditForm = ({
  onValuesChanged,
  isFormValidChanged,
  defaultValues,
}: IMemberType) => (
  <MemberForm
    defaultValues={defaultValues}
    onValuesChanged={onValuesChanged}
    isFormValidChanged={isFormValidChanged}
    isEmailInputDisabled={true}
  />
);

export default PartnerMemberEditForm;
