import React from 'react';

import { Text, Stack, styled } from '@livingpackets/design-system-react-next';

const HeaderTextWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const HeaderTextNumber = styled(Text)`
  width: 14px;
  height: 14px;
  background-color: ${({ theme }) => theme.palette.custom.primary[100]};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
`;

HeaderTextNumber.defaultProps = {
  variant: 'bodyTextXS',
  color: 'custom.neutral.white.pure',
};

const HeaderTextTitle = styled(Text)`
  margin-left: 0;
`;

HeaderTextTitle.defaultProps = {
  variant: 'titleM',
  color: 'custom.neutral.black.100',
};

const HeaderTextSubTitle = styled(Text)``;

HeaderTextSubTitle.defaultProps = {
  variant: 'titleXS',
  color: 'custom.neutral.black.50',
};

interface ImportShipmentStepTextBlockType {
  title: string;
  subTitle: string;
  number?: string;
}

const ImportShipmentStepTextBlock = ({
  title,
  subTitle,
  number,
}: ImportShipmentStepTextBlockType) => (
  <HeaderTextWrapper>
    <Stack direction="row" marginBottom="3px" alignItems="center">
      {number && <HeaderTextNumber>{number}</HeaderTextNumber>}
      <HeaderTextTitle>{title}</HeaderTextTitle>
    </Stack>
    <HeaderTextSubTitle>{subTitle}</HeaderTextSubTitle>
  </HeaderTextWrapper>
);

export default ImportShipmentStepTextBlock;
