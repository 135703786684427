import { getShopRoles, TApiEnv } from 'configs/helpers';

export const ShopRoles = getShopRoles(process.env.REACT_APP_API_ENV as TApiEnv);

export enum LpVillageServices {
  buyAndRent = 'BuyAndRent',
}

export enum LpVillageScopes {
  readPartner = 'read:partner',
  updatePartner = 'update:partner',

  createPartnerAddress = 'create:partner_address',
  readPartnerAddress = 'read:partner_address',
  updatePartnerAddress = 'update:partner_address',
  deletePartnerAddress = 'delete:partner_address',

  addPartnerUser = 'add:partner_user',
  readPartnerUser = 'read:partner_user',
  updatePartnerUser = 'update:partner_user',
  deletePartnerUser = 'delete:partner_user',

  createShop = 'create:shop',
  readShop = 'read:shop',
  updateShop = 'update:shop',

  addShopUser = 'add:shop_user',
  readShopUser = 'read:shop_user',
  updateShopUser = 'update:shop_user',
  deleteShopUser = 'delete:shop_user',

  readRole = 'read:role',
  readAllRoles = 'read:all_roles',

  readApiKey = 'read:apikey',
  rotateApiKey = 'rotate:apikey',
  deleteApiKey = 'delete:apikey',

  readBoxContract = 'read:box-contracts',

  createContact = 'create:contact',
  readContact = 'read:contact',
  readContactUser = 'read:contact_user',

  deletePartnerContact = 'delete:partner_contact',
  updatePartnerContact = 'update:partner_contact',

  readRemoteCommand = 'read:remote-command',
}
