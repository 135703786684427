import React, { useCallback, useEffect, useState } from 'react';

import { Card, CardBody, CardHeader } from '@livingpackets/design-system-react';
import {
  ArrowLeftIcon,
  ButtonV2 as Button,
  Stack,
  Text,
} from '@livingpackets/design-system-react-next';
import ProductDetails from 'components/organisms/ProductDetails';
import { PATHS } from 'configs';
import { ProductTypeEnum } from 'enums/ProductEnum';
import useProductDetail, { useProductStore } from 'hooks/useProductDetail';
import useShipmentDetail from 'hooks/useShipmentDetail';
import { ProductModel } from 'models/product';
import { IShipment } from 'models/shipment';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import useMyPartnersStore, {
  activePartnerSelector,
} from 'stores/useMyPartnersStore';
import styled, { DefaultTheme, ThemeProps, withTheme } from 'styled-components';
const CardBoxDetail = styled(Card)`
  width: 956px;

  @media (max-width: ${({ theme }) => theme.mediaBreakpoints.md}) {
    width: auto;
  }
`;
const CardBoxHeader = styled(CardHeader)`
  background-color: ${({ theme }) => theme.colors.black[100]};
  color: ${({ theme }) => theme.colors.white};
  border-radius: 20px 20px 0 0;
  padding: 25px;
  margin-bottom: -15px;
`;

const PartnerProductsDetail = ({ theme }: ThemeProps<DefaultTheme>) => {
  const { t } = useTranslation(['products', 'general', 'tabs']);

  const navigate = useNavigate();
  const activePartner = useMyPartnersStore(activePartnerSelector);
  const { lpUi } = useParams<{ lpUi: string }>();

  const { getProductDetail, getProperLpui } = useProductDetail();
  const { getShipmentDetail } = useShipmentDetail();

  const [productData, setProductData] = useState<ProductModel>();
  const [ShipmentDetail, setShipmentDetail] = useState<IShipment>();

  const initProductData = useCallback(
    (product: ProductModel) => {
      setProductData(product);
    },
    [setProductData]
  );

  const setProduct = useProductStore(
    (state: { setProduct: any }) => state.setProduct
  );

  useEffect(() => {
    if (!productData && lpUi) {
      getProductDetail(activePartner.id, lpUi).then(({ success, product }) => {
        if (!success || !product) {
          navigate(PATHS.PAGE_404);
        } else {
          initProductData(product);
          if (product.last_shipment_id) {
            getShipmentDetail(activePartner.id, product.last_shipment_id).then(
              ({ success, shipment }) => {
                if (success) {
                  setShipmentDetail(shipment);
                }
              }
            );
          }
        }
      });
    }

    return () => setProduct();
  }, [
    getProductDetail,
    activePartner,
    lpUi,
    navigate,
    setProduct,
    productData,
    initProductData,
    getShipmentDetail,
  ]);

  const backToBoxList = useCallback(() => {
    navigate(
      generatePath(PATHS.PRODUCT.LIST, {
        partnerId: activePartner.id,
      })
    );
  }, [navigate, activePartner]);

  const getProductDetailTitleText = useCallback(
    (product: ProductModel) => {
      if (product.productType === ProductTypeEnum.BOX) {
        return t('products:detail.title.box');
      }

      if (product.productType === ProductTypeEnum.BOX_PRO) {
        return t('products:detail.title.boxPro');
      }
      if (product.productType === ProductTypeEnum.TABLET) {
        return t('products:detail.title.tablet');
      }

      return '';
    },
    [t]
  );

  return (
    <>
      <CardBoxDetail>
        <React.Fragment key=".0">
          <CardBoxHeader>
            <Stack direction="row" alignItems="center" spacing=".75rem">
              <Button
                variant="tertiary"
                icon={ArrowLeftIcon}
                onClick={backToBoxList}
              />
              <Text variant="bodyTextXS" color="custom.neutral.black.50">
                {t('products:detail.overview')}
              </Text>
            </Stack>
            {productData && (
              <>
                <Text
                  variant="titleXS"
                  mt="1rem"
                  color="custom.neutral.white.pure"
                >
                  {getProperLpui(productData.lp_ui)}
                </Text>
                <Text
                  variant="titleL"
                  mt="10px"
                  mb="15px"
                  color="custom.neutral.white.pure"
                >
                  {getProductDetailTitleText(productData)}
                </Text>
              </>
            )}
          </CardBoxHeader>
          <CardBody paddingLeft="32px" paddingRight="32px" paddingTop="36px">
            {productData && (
              <ProductDetails
                product={productData}
                shipmentDetail={ShipmentDetail}
              />
            )}
          </CardBody>
        </React.Fragment>
      </CardBoxDetail>
    </>
  );
};

export default withTheme(PartnerProductsDetail);
