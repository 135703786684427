import React, { useState } from 'react';

import { Box } from '@livingpackets/design-system-react';
import {
  ButtonV2,
  ChevronLeftIcon,
  ChevronRightIcon,
  Grid,
  Stepper,
  Text,
  styled,
} from '@livingpackets/design-system-react-next';
import halfCircleTopGrey from 'assets/half-circle-top-grey.svg';
import { useTranslation } from 'react-i18next';

const BoxGradient = styled(Box)`
  position: absolute;
  background: linear-gradient(180deg, rgba(244, 244, 244, 0) 0%, #f4f4f4 65%);
  height: 5rem;
  bottom: -0.0625rem;
  left: 1rem;
  right: 0;
  width: 100%;
`;

const StepperOnboarding = styled(Stepper)`
  background-color: 'none';
`;

const VideoOnboarding = styled('video')`
  max-width: 100%;
  max-height: 60vh;
`;

const ButtonNext = styled(ButtonV2)`
  &.MuiButtonBase-root {
    margin: 0;
  }
`;

const ButtonPrevious = styled(ButtonV2)`
  &.MuiButtonBase-root {
    margin: 0 0.875rem 0 0;
  }
`;

const ButtonSkip = styled(ButtonV2)`
  &.MuiButtonBase-root {
    margin: 0 2.5rem 0 0;
  }
`;

const HalfCircleTopGrey = styled('img')`
  position: absolute;
  top: 0%;
  right: 0%;
`;

export type StepContentProps = {
  setStepsOnboarding: React.Dispatch<React.SetStateAction<number>>;
  closeOnboarding: () => void;
  stepsData: any;
};

const StepContent = ({
  setStepsOnboarding,
  closeOnboarding,
  stepsData,
}: StepContentProps) => {
  const { t } = useTranslation('onboarding');

  const NB_STEPS = stepsData.length;

  const [stepIndex, setStepIndex] = useState<number>(0);

  const currentStep = stepsData[stepIndex];

  const nextButton = () => {
    if (stepIndex < NB_STEPS - 1) {
      setStepIndex(prev => prev + 1);
    } else if (stepIndex === NB_STEPS - 1) {
      closeOnboarding();
    }
  };

  const previousButton = () => {
    if (stepIndex > 0) {
      setStepIndex(prev => prev - 1);
    } else if (stepIndex === 0) {
      setStepsOnboarding(0);
    }
  };

  return (
    <Grid
      item
      container
      justifyContent="center"
      alignItems="center"
      tablet={8.25}
      flexWrap="nowrap"
      paddingBottom="2.5rem"
      paddingLeft="7rem"
      paddingRight="7rem"
      paddingTop="5rem"
    >
      <HalfCircleTopGrey src={halfCircleTopGrey} alt="half-circle" />
      <Grid
        container
        item
        direction="column"
        justifyContent="space-between"
        alignItems="center"
        maxHeight="51rem"
        maxWidth="55.625rem"
        spacing="2rem"
        flexWrap="nowrap"
        zIndex="100000"
      >
        <Grid item sx={{ position: 'relative', paddingLeft: '0' }}>
          {/* Need to 'display: none' non active steps video to preload them */}
          {stepsData.map(({ video }: any, index: number) => (
            <VideoOnboarding
              key={video}
              autoPlay
              loop
              style={{ display: stepIndex === index ? 'block' : 'none' }}
            >
              <source src={video} type="video/mp4" />
            </VideoOnboarding>
          ))}

          <BoxGradient />
        </Grid>

        <Grid item container direction="column">
          <Grid item>
            <Text variant="titleXXL">{currentStep.title}</Text>
          </Grid>
          <Grid item minHeight="3rem">
            <Text variant="bodyTextL">{currentStep.text}</Text>
          </Grid>
        </Grid>

        <Grid item width="100%">
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            gap=".875rem"
          >
            <Grid item>
              <StepperOnboarding
                steps={4}
                activeStep={stepIndex}
                variant="dots"
                position="static"
              />
            </Grid>
            <Grid item>
              <Grid container>
                <ButtonSkip
                  color="primary"
                  variant="flat"
                  sx={{
                    opacity: stepIndex >= 0 && stepIndex <= 2 ? '1' : '0',
                  }}
                  onClick={closeOnboarding}
                >
                  {t('skipOnboarding')}
                </ButtonSkip>
                <ButtonPrevious
                  variant="secondary"
                  icon={ChevronLeftIcon}
                  onClick={() => previousButton()}
                  disabled={stepIndex === 0}
                />
                <ButtonNext
                  icon={ChevronRightIcon}
                  onClick={() => nextButton()}
                  data-testid="next-button"
                >
                  {stepIndex === NB_STEPS - 1 ? t('letsGo') : ''}
                </ButtonNext>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default StepContent;
