import React, { useCallback, useContext, useEffect, useState } from 'react';

import { Stack, Box, ButtonV2 } from '@livingpackets/design-system-react-next';
import CreateShipmentAddressFormTemplate from 'components/molecules/importShipment/CreateShipmentAddressFormTemplate';
import { ShipmentEditionContext } from 'components/organisms/ImportShipmentFlow';
import { usePartnership } from 'features/account';
import useFormTemplate from 'hooks/useFormTemplate';
import { set } from 'lodash';
import {
  IOcrLabel,
  ShipmentAddressData,
  ShipmentAddressAndContactData,
} from 'models/shipment';
import { FieldValues, SubmitHandler } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { shipmentAddressInformationSchema } from 'schemas/shipmentSchema';
import useMyPartnersStore, {
  activePartnerSelector,
} from 'stores/useMyPartnersStore';
import styled from 'styled-components';

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-top: 25px;
`;

export interface ShipmentAddressFormProps {
  label: IOcrLabel;
  onSubmit: ({
    phoneNumbers,
    shipmentAddressData,
  }: {
    phoneNumbers: {
      senderPhoneNumber: string;
      recipientPhoneNumber: string;
    };
    shipmentAddressData: ShipmentAddressData;
  }) => void | Promise<void>;
  isReturnShipment?: boolean;
}

const ShipmentAddressForm = ({
  label,
  onSubmit,
  isReturnShipment = false,
}: ShipmentAddressFormProps) => {
  const { t } = useTranslation(['shipments', 'general', 'profileSettings']);

  const { data: partnership } = usePartnership();

  // BEGIN Handle show ReadOnly address or the form
  const hasBothDefaultAddress =
    !!partnership?.default_sender_address_id &&
    !!partnership?.default_recipient_address_id;

  const [showReadOnlyAddress, setShowReadOnlyAddress] = useState(false);

  const { isEditingCreationShipment } = useContext(ShipmentEditionContext);

  useEffect(() => {
    if (isEditingCreationShipment) return;

    setShowReadOnlyAddress(hasBothDefaultAddress);
  }, [hasBothDefaultAddress, isEditingCreationShipment]);
  // END Handle show ReadOnly address or the form

  const { smsEnabled } = useMyPartnersStore(activePartnerSelector);

  const [displaySenderFormGreenCheckIcon, setDisplaySenderFormGreenCheckIcon] =
    useState<boolean>(false);

  const [
    displayRecipientFormGreenCheckIcon,
    setDisplayRecipientFormGreenCheckIcon,
  ] = useState<boolean>(false);

  const {
    handleSubmit,
    formState: { touchedFields, isValid },
    setValue,
    getValues,
    trigger,
    register,
    errors,
    watch,
    control,
    reset,
    resetField,
  } = useFormTemplate<ShipmentAddressAndContactData>({
    resolver: shipmentAddressInformationSchema({
      smsEnabled,
    }),
    defaultValues: {
      senderAddress: label.senderAddress,
      recipientAddress: label.recipientAddress,
      // Only for partnership with smsEnabled
      senderPhoneNumber: label.senderPhoneNumber || '',
      recipientPhoneNumber: label.recipientPhoneNumber || '',
    },
  });

  const updateFormTouched = useCallback(
    (label: IOcrLabel) => {
      // Manually set touched for fields

      if (label.senderAddress.firstName) {
        set(touchedFields, 'senderAddress.firstName', true);
      }

      if (label.senderAddress.lastName) {
        set(touchedFields, 'senderAddress.lastName', true);
      }

      if (label.senderAddress.company) {
        set(touchedFields, 'senderAddress.company', true);
      }

      if (label.senderAddress.email) {
        set(touchedFields, 'notificationEmailSender', true);
      }

      if (label.senderAddress.street) {
        set(touchedFields, 'senderAddress.street', true);
      }

      if (label.senderAddress.city) {
        set(touchedFields, 'senderAddress.city', true);
      }

      if (label.senderAddress.postalCode) {
        set(touchedFields, 'senderAddress.postalCode', true);
      }

      if (label.recipientAddress.firstName) {
        set(touchedFields, 'recipientAddress.firstName', true);
      }

      if (label.recipientAddress.lastName) {
        set(touchedFields, 'recipientAddress.lastName', true);
      }

      if (label.recipientAddress.company) {
        set(touchedFields, 'recipientAddress.company', true);
      }

      if (label.recipientAddress.street) {
        set(touchedFields, 'recipientAddress.street', true);
      }

      if (label.recipientAddress.city) {
        set(touchedFields, 'recipientAddress.city', true);
      }

      if (label.recipientAddress.postalCode) {
        set(touchedFields, 'recipientAddress.postalCode', true);
      }

      if (label.recipientAddress.email) {
        set(touchedFields, 'notificationEmailRecipient', true);
      }

      if (label.senderPhoneNumber) {
        set(touchedFields, 'senderPhoneNumber', true);
      }

      if (label.recipientPhoneNumber) {
        set(touchedFields, 'recipientPhoneNumber', true);
      }
    },
    [touchedFields]
  );

  useEffect(() => {
    reset({ ...label }, { keepTouched: true });
    updateFormTouched(label);
  }, [reset, label, updateFormTouched]);

  useEffect(() => {
    const formValues = getValues() as IOcrLabel;

    if (formValues.senderAddress) {
      setDisplaySenderFormGreenCheckIcon(
        formValues.senderAddress.firstName !== '' &&
          formValues.senderAddress.lastName !== '' &&
          formValues.senderAddress.street !== '' &&
          formValues.senderAddress.postalCode !== '' &&
          formValues.senderAddress.city !== '' &&
          formValues.senderAddress.email !== '' &&
          !errors.hasOwnProperty('senderAddress')
      );
    }

    if (formValues.recipientAddress) {
      setDisplayRecipientFormGreenCheckIcon(
        formValues.recipientAddress.firstName !== '' &&
          formValues.recipientAddress.lastName !== '' &&
          formValues.recipientAddress.street !== '' &&
          formValues.recipientAddress.postalCode !== '' &&
          formValues.recipientAddress.city !== '' &&
          formValues.recipientAddress.email !== '' &&
          !errors.hasOwnProperty('recipientAddress')
      );
    }
  }, [watch, errors, getValues]);

  const onSubmitForm: SubmitHandler<FieldValues> = (data: FieldValues) => {
    onSubmit({
      phoneNumbers: {
        senderPhoneNumber: data.senderPhoneNumber,
        recipientPhoneNumber: data.recipientPhoneNumber,
      },
      shipmentAddressData: {
        senderAddress: data.senderAddress,
        recipientAddress: data.recipientAddress,
      },
    });
  };

  return (
    <Box component="form" onSubmit={handleSubmit(onSubmitForm)} mt="16px">
      <Stack>
        <Stack direction="row" justifyContent="space-between">
          <Stack marginRight="20px" width="100%">
            <CreateShipmentAddressFormTemplate
              isReturnShipment={isReturnShipment}
              partnership={partnership}
              prefix="senderAddress."
              smsEnabled={smsEnabled}
              isSender={true}
              title={t('shipments:form.sender.title')}
              setValue={setValue}
              trigger={trigger}
              touchedFields={touchedFields}
              register={register}
              errors={errors}
              watch={watch}
              control={control}
              resetField={resetField}
              displayFormGreenCheckIcon={displaySenderFormGreenCheckIcon}
              showReadOnlyAddress={
                isReturnShipment ? false : showReadOnlyAddress
              }
              setShowReadOnlyAddress={setShowReadOnlyAddress}
            />
          </Stack>

          <Stack marginLeft="20px" width="100%">
            <CreateShipmentAddressFormTemplate
              isReturnShipment={isReturnShipment}
              partnership={partnership}
              prefix="recipientAddress."
              smsEnabled={smsEnabled}
              isSender={false}
              title={t('shipments:form.recipient.title')}
              setValue={setValue}
              trigger={trigger}
              touchedFields={touchedFields}
              register={register}
              errors={errors}
              watch={watch}
              control={control}
              resetField={resetField}
              displayFormGreenCheckIcon={displayRecipientFormGreenCheckIcon}
              showReadOnlyAddress={
                isReturnShipment ? false : showReadOnlyAddress
              }
              setShowReadOnlyAddress={setShowReadOnlyAddress}
            />
          </Stack>
        </Stack>
        <ButtonsWrapper>
          <ButtonV2
            data-testid="import-shipment-address-confirm"
            variant="primary"
            disabled={!isValid}
            sx={{ width: '192px' }}
            {...{ id: 'submitBtn', type: 'submit' }}
          >
            {t('shipments:form.importInformation.button.confirm')}
          </ButtonV2>
        </ButtonsWrapper>
      </Stack>
    </Box>
  );
};

export default ShipmentAddressForm;
