import React, { useCallback, useEffect, useState } from 'react';

import {
  AirPressureIcon,
  HumidityIcon,
  NoneIcon,
  Stack,
  TemperatureIcon,
  Box,
  Text,
  ButtonV2 as Button,
  styled,
} from '@livingpackets/design-system-react-next';
import boxProShowcaseImg from 'assets/img/shipment/product/box-pro-showcase.png';
import boxProShowcaseImg2x from 'assets/img/shipment/product/box-pro-showcase@2x.png';
import boxProShowcaseImg3x from 'assets/img/shipment/product/box-pro-showcase@3x.png';
import boxShowcaseImg from 'assets/img/shipment/product/box-showcase.png';
import boxShowcaseImg2x from 'assets/img/shipment/product/box-showcase@2x.png';
import boxShowcaseImg3x from 'assets/img/shipment/product/box-showcase@3x.png';
import tabletShowcaseImg from 'assets/img/shipment/product/tablet-showcase.png';
import tabletShowcaseImg2x from 'assets/img/shipment/product/tablet-showcase@2x.png';
import tabletShowcaseImg3x from 'assets/img/shipment/product/tablet-showcase@3x.png';
import Col from 'components/atoms/Col';
import TextSkeleton from 'components/atoms/loadingSkeletons/TextSkeleton';
import Row from 'components/atoms/Row';
import { DetailsCard } from 'components/atoms/ShipmentAtoms';
import BatteryInfoIcon from 'components/molecules/box/BatteryInfoIcon';
import { PATHS } from 'configs';
import { ProductTypeEnum } from 'enums/ProductEnum';
import { Offer } from 'features/account';
import { STATE_ORIGIN_SHIPMENT } from 'features/deploy-return';
import {
  TooltipRemoteControl,
  TooltipReturnProduct,
} from 'features/remoteControl';
import useIntlFormatTimestamp from 'hooks/useIntlFormatTimestamp';
import useProductDetail from 'hooks/useProductDetail';
import { ProductModel } from 'models/product';
import { IShipment } from 'models/shipment';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate } from 'react-router-dom';
import useMyPartnersStore, {
  activePartnerSelector,
} from 'stores/useMyPartnersStore';

const ShipmentUpdateInformationSpan = styled(Text)``;

ShipmentUpdateInformationSpan.defaultProps = {
  variant: 'bodyTextXS',
  color: 'custom.neutral.black.50',
};

const ShipmentInformationImageCol = styled(Col)`
  width: 35%;

  @media (max-width: ${({ theme }) => theme.mediaBreakpoints.sm}) {
    width: 0;
    display: none;
  }
`;

const ProductShowcaseImgContainer = styled('div', {
  shouldForwardProp: prop =>
    prop !== 'productShowcaseImg' &&
    prop !== 'productShowcaseImg2x' &&
    prop !== 'productShowcaseImg3x',
})<{
  productShowcaseImg: string;
  productShowcaseImg2x: string;
  productShowcaseImg3x: string;
}>`
  height: 100%;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  background-image: url(${({ productShowcaseImg }) => productShowcaseImg});
  background-image: -webkit-image-set(
    url(${({ productShowcaseImg2x }) => productShowcaseImg2x}) 1x,
    url(${({ productShowcaseImg3x }) => productShowcaseImg3x}) 2x
  );
`;

const ShipmentInformationDataCol = styled(Col)`
  width: 65%;
  margin-top: 1.25rem;

  @media (max-width: ${({ theme }) => theme.mediaBreakpoints.sm}) {
    width: 100%;
  }
`;

const BoxSpan = styled(Text)`
  margin-left: 0.625rem;
`;

BoxSpan.defaultProps = {
  variant: 'titleS',
  color: 'custom.neutral.black.100',
};

const ValueSpan = styled(Text, {
  shouldForwardProp: prop => prop !== 'isAlert',
})<{
  isAlert: boolean;
}>`
  color: ${({ theme, isAlert }) =>
    isAlert ? theme.colors.red[100] : theme.colors.primary[100]};
`;

ValueSpan.defaultProps = {
  variant: 'bodyTextM',
};

interface IShipmentProduct {
  shipment: IShipment;
  offerData: Offer;
  productArticleCode?: string;
  productLpui?: string;
}

const ShipmentProduct = ({
  shipment,
  offerData,
  productArticleCode,
  productLpui,
}: IShipmentProduct) => {
  const { t } = useTranslation(['shipments', 'products']);
  const navigate = useNavigate();
  const activePartner = useMyPartnersStore(activePartnerSelector);
  const formatTimestamp = useIntlFormatTimestamp();

  const { getProductDetail, getProductType } = useProductDetail();

  const [isBatteryLevelAlert, setIsBatteryLevelAlert] =
    useState<boolean>(false);
  const [isAirPressureAlert, setIsAirPressureAlert] = useState<boolean>(false);
  const [isTemperatureAlert, setIsTemperatureAlert] = useState<boolean>(false);
  const [isHumidityAlert, setIsHumidityAlert] = useState<boolean>(false);

  const [productData, setProductData] = useState<ProductModel | null>(null);

  useEffect(() => {
    if (shipment.product_state && offerData) {
      setIsBatteryLevelAlert(false);
      setIsAirPressureAlert(false);

      if (shipment.product_state.temperature_internal !== undefined) {
        setIsTemperatureAlert(
          shipment.product_state.temperature_internal >
            offerData.sensor_configuration.temperature_threshold.celsius_max ||
            shipment.product_state.temperature_internal <
              offerData.sensor_configuration.temperature_threshold.celsius_min
        );
      }

      if (shipment.product_state.humidity_internal !== undefined) {
        setIsHumidityAlert(
          shipment.product_state.humidity_internal >
            offerData.sensor_configuration.humidity_threshold.percent_max ||
            shipment.product_state.humidity_internal <
              offerData.sensor_configuration.humidity_threshold.percent_min
        );
      }
    }
  }, [shipment, offerData]);

  const redirectToBoxDetail = useCallback(() => {
    navigate(
      generatePath(PATHS.PRODUCT.DETAIL, {
        partnerId: activePartner.id,
        lpUi: productLpui,
      })
    );
  }, [navigate, activePartner.id, productLpui]);

  useEffect(() => {
    if (productLpui) {
      getProductDetail(activePartner.id, productLpui, false).then(
        ({ success, product }) => {
          if (!success || !product) {
            setProductData(null);
          } else {
            setProductData(product);
          }
        }
      );

      return () => setProductData(null);
    }
  }, [getProductDetail, activePartner, navigate, productLpui]);

  const getProductShowcaseImgContainer = useCallback(
    (productArticleCode?: string) => {
      let showcaseImg = boxShowcaseImg;
      let showcaseImg2x = boxShowcaseImg2x;
      let showcaseImg3x = boxShowcaseImg3x;

      const productType = productArticleCode
        ? getProductType(productArticleCode)
        : null;

      if (productType === ProductTypeEnum.BOX_PRO) {
        showcaseImg = boxProShowcaseImg;
        showcaseImg2x = boxProShowcaseImg2x;
        showcaseImg3x = boxProShowcaseImg3x;
      }
      if (productType === ProductTypeEnum.TABLET) {
        showcaseImg = tabletShowcaseImg;
        showcaseImg2x = tabletShowcaseImg2x;
        showcaseImg3x = tabletShowcaseImg3x;
      }

      return (
        <ProductShowcaseImgContainer
          productShowcaseImg={showcaseImg}
          productShowcaseImg2x={showcaseImg2x}
          productShowcaseImg3x={showcaseImg3x}
        />
      );
    },
    [getProductType]
  );

  const handleClickDeployReturn = () => {
    navigate(
      generatePath(PATHS.SHIPMENT.DEPLOY_RETURN, {
        partnerId: activePartner.id,
        shipmentId: shipment.shipment_id,
      }),
      { state: { origin: STATE_ORIGIN_SHIPMENT } }
    );
  };

  return (
    <>
      <DetailsCard padding="1.25rem" pt="1rem" mt=".9375rem">
        {shipment.product_state?.last_update && (
          <Box>
            <Text variant="bodyTextXS" color="custom.neutral.black.100">
              {t('shipments:detail.boxInformation.lastUpdate', {
                lastUpdateDate: formatTimestamp(
                  shipment.product_state.last_update
                ),
              })}
            </Text>
          </Box>
        )}
        <Row alignItems="stretch" ml="-1.25rem">
          {shipment.product_state ? (
            <>
              <ShipmentInformationImageCol>
                {getProductShowcaseImgContainer(productArticleCode)}
              </ShipmentInformationImageCol>
              <ShipmentInformationDataCol
                alignItems="stretch"
                flexDirection="row"
                marginBottom="auto"
              >
                <Col flexDirection="column">
                  <Row flexDirection="row">
                    <Col flex="1 10%" flexDirection="row">
                      <BatteryInfoIcon
                        batteryLevel={shipment.product_state.battery_level}
                        updateColor={false}
                      />
                    </Col>
                    <Col flex="1 90%" justifyContent="space-around">
                      <Row
                        flexDirection="row"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Col>
                          <BoxSpan>{t('shipments:battery')}</BoxSpan>
                        </Col>
                        <Col ml="18px">
                          {shipment.product_state?.battery_level !==
                          undefined ? (
                            <ValueSpan
                              data-testid="batteryLevel"
                              data-testdata={
                                shipment.product_state?.battery_level
                              }
                              isAlert={isBatteryLevelAlert}
                            >
                              {t('products:common.batteryLevel', {
                                batteryLevel:
                                  shipment.product_state?.battery_level,
                              })}
                            </ValueSpan>
                          ) : (
                            <Box data-testid="batteryLevelEmpty">
                              <NoneIcon />
                            </Box>
                          )}
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row flexDirection="row" mt=".5rem">
                    <Col flex="1 10%" flexDirection="row">
                      <AirPressureIcon />
                    </Col>
                    <Col flex="1 90%" justifyContent="space-around">
                      <Row
                        flexDirection="row"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Col>
                          <BoxSpan>{t('shipments:pressure')}</BoxSpan>
                        </Col>
                        <Col ml="1.125rem">
                          {shipment.product_state?.pressure !== undefined ? (
                            <ValueSpan
                              data-testid="pressure"
                              data-testdata={shipment.product_state?.pressure}
                              isAlert={isAirPressureAlert}
                            >
                              {t('products:common.airPressureLevel', {
                                airPressureLevel:
                                  shipment.product_state?.pressure,
                              })}
                            </ValueSpan>
                          ) : (
                            <Box data-testid="pressureEmpty">
                              <NoneIcon />
                            </Box>
                          )}
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row flexDirection="row" mt=".5rem">
                    <Col flex="1 10%" flexDirection="row">
                      <TemperatureIcon />
                    </Col>
                    <Col flex="1 90%" justifyContent="space-around">
                      <Row
                        flexDirection="row"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Col>
                          <BoxSpan>{t('shipments:temp')}</BoxSpan>
                        </Col>
                        <Col ml="1.125rem">
                          {shipment.product_state?.temperature_internal !==
                          undefined ? (
                            <ValueSpan
                              data-testid="internalTemp"
                              data-testdata={
                                shipment.product_state?.temperature_internal
                              }
                              isAlert={isTemperatureAlert}
                            >
                              {t('products:common.temperatureLevel', {
                                temperatureLevel:
                                  shipment.product_state?.temperature_internal,
                              })}
                            </ValueSpan>
                          ) : (
                            <Box data-testid="internalTempEmpty">
                              <NoneIcon />
                            </Box>
                          )}
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row flexDirection="row" mt=".5rem">
                    <Col flex="1 10%" flexDirection="row">
                      <HumidityIcon />
                    </Col>
                    <Col flex="1 90%" justifyContent="space-around">
                      <Row
                        flexDirection="row"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Col>
                          <BoxSpan>{t('shipments:humidity')}</BoxSpan>
                        </Col>
                        <Col ml="1.125rem">
                          {shipment.product_state?.humidity_internal !==
                          undefined ? (
                            <ValueSpan
                              data-testid="internalHumidity"
                              data-testdata={
                                shipment.product_state?.humidity_internal
                              }
                              isAlert={isHumidityAlert}
                            >
                              {t('products:common.humidityLevel', {
                                humidityLevel:
                                  shipment.product_state?.humidity_internal,
                              })}
                            </ValueSpan>
                          ) : (
                            <Box data-testid="internalHumidityEmpty">
                              <NoneIcon />
                            </Box>
                          )}
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </ShipmentInformationDataCol>
            </>
          ) : (
            <TextSkeleton
              style={{
                width: '100%',
                height: '7.5rem',
                marginLeft: '1.25rem',
                marginTop: '1.25rem',
              }}
            />
          )}
        </Row>
        <Row flexDirection="column" marginTop="1.5625rem">
          <ShipmentUpdateInformationSpan>
            {t('shipments:lastUpdateInformation')}
          </ShipmentUpdateInformationSpan>
        </Row>
      </DetailsCard>
      {productData && (
        <Stack
          direction="row"
          justifyContent="space-between"
          gap=".625rem"
          paddingTop=".625rem"
        >
          <Button
            fullWidth={
              !(
                (shipment.contract_state === 'DEPLOYED' &&
                  activePartner.remoteControlEnabled) ||
                (shipment?.contract_state === 'ARRIVED' &&
                  shipment?.return_shipment_id &&
                  shipment.return_shipment?.contract_state === 'TRANSFERRED')
              )
            }
            variant="secondary"
            onClick={() => redirectToBoxDetail()}
          >
            {t('shipments:detail.boxInformation.redirectToBoxDetail')}
          </Button>
          {/* CTA REMOTE CONTROL */}
          {shipment.contract_state === 'DEPLOYED' &&
            activePartner.remoteControlEnabled && (
              <TooltipRemoteControl shipmentId={shipment.shipment_id} />
            )}

          {shipment?.contract_state === 'ARRIVED' &&
            shipment?.return_shipment_id &&
            shipment.return_shipment?.contract_state === 'TRANSFERRED' && (
              <>
                <TooltipReturnProduct onClick={handleClickDeployReturn} />
              </>
            )}
        </Stack>
      )}
    </>
  );
};

export default ShipmentProduct;
