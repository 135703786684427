import React from 'react';

import { ShipmentPreShipmentIcon } from '@livingpackets/design-system-react-next';
import styled, { useTheme } from 'styled-components';

const Badge = styled.div`
  height: 48px;
  width: 48px;
  background: ${({ theme }) => theme.colors.primary[10]};
  border-radius: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 14px;
`;

const ReadyForTransitBadge = () => {
  const theme = useTheme();

  return (
    <Badge>
      <ShipmentPreShipmentIcon color={theme.colors.primary[100]} />
    </Badge>
  );
};

export default ReadyForTransitBadge;
