import { PathMatch } from '@remix-run/router';
import { PATHS } from 'configs';
import { NavRouteContextEnum } from 'enums/navRouteContextEnum';
import { useMatch } from 'react-router-dom';
import useMyPartnersStore, {
  IPartnerEntry,
  partnersSelector,
} from 'stores/useMyPartnersStore';

const getContext: (
  lpAccountMatch: PathMatch | null,
  partnerMatch: PathMatch<'partnerId'> | null,
  shipmentAnonymousDetailMatch: PathMatch<'trackingCode'> | null,
  partners: IPartnerEntry[]
) => NavRouteContextEnum | null = (
  lpAccountMatch,
  partnerMatch,
  shipmentAnonymousDetailMatch,
  partners
) => {
  if (
    (lpAccountMatch && partnerMatch && shipmentAnonymousDetailMatch) ||
    (!lpAccountMatch && !partnerMatch && !shipmentAnonymousDetailMatch)
  ) {
    return null;
  }
  if (lpAccountMatch) {
    return NavRouteContextEnum.LP_ACCOUNT;
  }

  if (shipmentAnonymousDetailMatch) {
    return NavRouteContextEnum.SHIPMENT_ANONYMOUS_DETAIL;
  }

  const partnerId = partnerMatch?.params.partnerId;
  const partner = partners.find(x => x.id === partnerId);
  if (!partner) {
    return null;
  }

  return NavRouteContextEnum.PARTNER;
};

const useNavRouteMatch = () => {
  const lpAccountMatch = useMatch({ path: PATHS.ACCOUNT_ROOT + '/*' });
  const partnerMatch = useMatch({ path: PATHS.PARTNER_ROOT + '/*' });
  const shipmentAnonymousDetailMatch = useMatch({
    path: PATHS.SHIPMENT.ANONYMOUS_DETAIL,
  });

  const partnerId = partnerMatch?.params.partnerId;
  const partners = useMyPartnersStore(partnersSelector);

  return {
    context: getContext(
      lpAccountMatch,
      partnerMatch,
      shipmentAnonymousDetailMatch,
      partners
    ),
    partnerId: partnerId,
  };
};

export default useNavRouteMatch;
