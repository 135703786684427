import * as pdfjs from 'pdfjs-dist';

export async function getPdfNumberPage(sourcePdf: string): Promise<number> {
  pdfjs.GlobalWorkerOptions.workerSrc =
    'https://cdn.jsdelivr.net/npm/pdfjs-dist@3.7.107/build/pdf.worker.js';

  try {
    const pdf = await pdfjs.getDocument({ data: sourcePdf }).promise;

    return pdf.numPages;
  } catch (reason) {
    // PDF loading error
    console.error(reason);
    throw reason;
  }
}

export async function getPdfSpecificPageBlob(
  sourcePdf: string,
  pdfPageNumber: number
) {
  pdfjs.GlobalWorkerOptions.workerSrc =
    'https://cdn.jsdelivr.net/npm/pdfjs-dist@3.7.107/build/pdf.worker.js';

  try {
    const pdf = await pdfjs.getDocument({ data: sourcePdf }).promise;

    const page = await pdf.getPage(pdfPageNumber);

    // Fetch page
    const longSide = Math.max(page.view[2], page.view[3]);

    // Ensure the long side is at least 2250px. This aims to get the
    // label short side to be at least 900px, assuming label takes 2/5 of the
    // long side.
    let outputScale = (2.5 * longSide * window.devicePixelRatio) / 900;

    if (outputScale > 4) {
      outputScale = 4;
    }

    const viewport = page.getViewport({ scale: outputScale });

    // Prepare canvas using PDF page dimensions
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d') as CanvasRenderingContext2D;
    canvas.height = viewport.height;
    canvas.width = viewport.width;

    // Render PDF page into canvas context
    const renderContext = {
      canvasContext: context,
      viewport: viewport,
    };
    await page.render(renderContext).promise;

    return await fetch(canvas.toDataURL()).then(it => it.blob());
  } catch (reason) {
    // PDF loading error
    console.error(reason);
    throw reason;
  }
}

export async function getPdfSpecificPageImageUrl(
  sourcePdf: string,
  pdfPageNumber: number
) {
  pdfjs.GlobalWorkerOptions.workerSrc =
    'https://cdn.jsdelivr.net/npm/pdfjs-dist@3.7.107/build/pdf.worker.js';

  try {
    const pdf = await pdfjs.getDocument({ data: sourcePdf }).promise;
    for (let i = 1; i <= pdf.numPages; i++) {
      // Fetch page
      const page = await pdf.getPage(i);
      const longSide = Math.max(page.view[2], page.view[3]);

      // Ensure the long side is at least 2250px. This aims to get the
      // label short side to be at least 900px, assuming label takes 2/5 of the
      // long side.
      let outputScale = (2.5 * longSide * window.devicePixelRatio) / 900;

      if (outputScale > 4) {
        outputScale = 4;
      }

      const viewport = page.getViewport({ scale: outputScale });

      // Prepare canvas using PDF page dimensions
      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d') as CanvasRenderingContext2D;
      canvas.height = viewport.height;
      canvas.width = viewport.width;

      // Render PDF page into canvas context
      const renderContext = {
        canvasContext: context,
        viewport: viewport,
      };
      await page.render(renderContext).promise;

      if (i === pdfPageNumber) {
        return canvas.toDataURL();
      }
    }

    return '';
  } catch (reason) {
    // PDF loading error
    console.error(reason);
    throw reason;
  }
}

export async function getImageOrientation(imageUrl: string) {
  return handleLoadImage(imageUrl).then(img => {
    // @ts-ignore
    const naturalWidth = img.naturalWidth;
    // @ts-ignore
    const naturalHeight = img.naturalHeight;

    return naturalWidth > naturalHeight ? 'landscape' : 'portrait';
  });
}

async function handleLoadImage(url: string) {
  try {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => resolve(img);
      img.onerror = reject;
      img.src = url;
    });
  } catch (error) {
    console.error('Error loading image:', error);
  }
}
