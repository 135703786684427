import i18next from 'i18next';
import * as yup from 'yup';

const partnerDetailsSchema = () =>
  yup.object().shape({
    website: yup.string().required(i18next.t('general:fieldError')),
    description: yup.string().required(i18next.t('general:fieldError')),
    phoneNumber: yup.string().required(i18next.t('general:fieldError')),
  });

export const partnerRegistrationAddressSchema = () =>
  yup.object().shape({
    company: yup.string(),
    street: yup.string().required(i18next.t('general:fieldError')),
    houseNumber: yup.string().required(i18next.t('general:fieldError')),
    city: yup.string().required(i18next.t('general:fieldError')),
    postalCode: yup.string().required(i18next.t('general:fieldError')),
  });

export const partnerBillingAddressSchema = () =>
  yup.object().shape({
    firstName: yup.string(),
    lastName: yup.string(),
    company: yup.string(),
    street: yup.string().required(i18next.t('general:fieldError')),
    houseNumber: yup.string().max(8, ({ max }) =>
      i18next.t('general:maxLengthExceededError', {
        fieldName: i18next.t('general:houseNumber'),
        max,
      })
    ),
    city: yup.string().required(i18next.t('general:fieldError')),
    postalCode: yup.string().required(i18next.t('general:fieldError')),
    countryCode: yup.string().required(i18next.t('general:fieldError')),
  });

export const purchaseLabelSchema = () =>
  yup.object().shape({
    senderAddress: partnerBillingAddressSchema(),
    recipientAddress: partnerBillingAddressSchema(),
    weightInKilograms: yup
      .number()
      .typeError(i18next.t('general:notANumber'))
      .required(i18next.t('general:fieldError'))
      .positive(i18next.t('general:weightFieldError'))
      .max(6, i18next.t('general:weightBoundsError')),
  });

export const partnerSenderAddressSchema = () =>
  yup.object().shape({
    firstName: yup.string().required(i18next.t('general:fieldError')),
    lastName: yup.string().required(i18next.t('general:fieldError')),
    company: yup.string(),
    street: yup.string().required(i18next.t('general:fieldError')),
    city: yup.string().required(i18next.t('general:fieldError')),
    postalCode: yup.string().required(i18next.t('general:fieldError')),
    countryCode: yup.string().required(i18next.t('general:fieldError')),
  });

const partnerNameIdSchema = yup.object().shape({
  name: yup.string().required(),
  id: yup.string().required(),
});

export const partnersNameIdSchema = yup
  .array()
  .of(partnerNameIdSchema)
  .required();

export default partnerDetailsSchema;
