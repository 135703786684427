import { CRISP_WEBSITE_ID } from 'configs';

export const loadCrisp = () => {
  //CRISP CHAT
  //@ts-ignore
  window.$crisp = [];
  //@ts-ignore
  window.CRISP_WEBSITE_ID = CRISP_WEBSITE_ID;

  (function () {
    let d = document;
    let s = d.createElement('script');

    s.src = 'https://client.crisp.chat/l.js';
    //@ts-ignore
    s.async = 1;
    d.getElementsByTagName('head')[0].appendChild(s);
  })();
};
