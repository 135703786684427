import React from 'react';

import {
  ButtonV2 as Button,
  Grid,
  Paper,
  SecurityIcon,
  NewTextField as TextField,
} from '@livingpackets/design-system-react-next';
import useSendResetPasswordEmail from 'hooks/useSendResetPasswordEmail';
import { useTranslation } from 'react-i18next';

export const Security = () => {
  const { t } = useTranslation(['profileSettings']);
  const { sendResetPasswordEmail, loading } = useSendResetPasswordEmail();

  return (
    <Paper
      headerIcon={SecurityIcon}
      headerTitle={t('profileSettings:security')}
      headerSubtitle={t('profileSettings:changePassword')}
    >
      <Grid container gap="1rem" justifyContent="flex-end">
        <Grid item flexGrow={1}>
          <TextField
            fullWidth
            label={t('profileSettings:passwordHiddenPart')}
            disabled
          />
        </Grid>
        <Grid item>
          <Button
            fullWidth
            onClick={sendResetPasswordEmail}
            disabled={loading}
            data-testid="reset-pw-button"
          >
            {t('profileSettings:resetLink')}
          </Button>
        </Grid>
      </Grid>
    </Paper>
  );
};
