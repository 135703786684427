import React from 'react';

import styled from 'styled-components';

const Badge = styled.div`
  height: 2rem;
  width: 2rem;
  background: ${({ theme }) => theme.colors.red[100]};
  border-radius: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const AlertBadge = ({ children }: { children: React.ReactNode }) => (
  <Badge>{children}</Badge>
);

export default AlertBadge;
