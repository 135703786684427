import React, { useCallback, useEffect, useState } from 'react';

import { Button, Alert } from '@livingpackets/design-system-react';
import { Box, Stack } from '@livingpackets/design-system-react-next';
import Input from 'components/atoms/Input';
import { AuthenticationView } from 'components/containers/AuthenticationView';
import { PATHS } from 'configs';
import { createBrowserHistory } from 'history';
import useFormTemplate from 'hooks/useFormTemplate';
import usePublic from 'hooks/usePublic';
import { useShipmentAddByTrackingCode } from 'hooks/useShipmentAddByTrackingCode';
import get from 'lodash/get';
import {
  IDeliveryKeyForm,
  INITIAL_STATE_DELIVERY_KEY_FORM,
} from 'models/deliveryKey';
import { IShipment } from 'models/shipment';
import { useTranslation } from 'react-i18next';
import { generatePath, useLocation, useNavigate } from 'react-router-dom';
import { useSpring, animated } from 'react-spring';
import { retrieveDeliveryKeySchema } from 'schemas/retrieveDeliveryKeySchema';
import useAppState, {
  anonymousTokenSelector,
  dispatch,
} from 'stores/appState/useAppState';

import DeliveryKeyExpiredWarningModal from '../../molecules/modals/DeliveryKeyExpiredWarningModal';

const RetrieveDeliveryKeyPage = () => {
  const navigate = useNavigate();
  let location = useLocation();
  let history = createBrowserHistory();
  const pageInfo = location.state || {};
  const displayDeliveryKeyExpiredWarningOnPageLoad =
    pageInfo.displayDeliveryKeyExpiredWarningOnPageLoad
      ? pageInfo.displayDeliveryKeyExpiredWarningOnPageLoad
      : false;

  history.replace(PATHS.AUTHENTICATION.RETRIEVE_DELIVERY_KEY, {});

  const { t } = useTranslation('authentication');
  const getShipmentAddByTrackingCode = useShipmentAddByTrackingCode();
  const anonymousToken = useAppState(anonymousTokenSelector);
  const { postSignup } = usePublic();

  const [trackingCode, setTrackingCode] = useState<string>();
  const [shipmentData, setShipmentData] = useState<IShipment | null>();
  const [isErrorMessageDisplayed, setIsErrorMessageDisplayed] =
    useState<boolean>(false);

  const [
    displayDeliveryKeyExpiredWarning,
    setDisplayDeliveryKeyExpiredWarning,
  ] = useState<boolean>(displayDeliveryKeyExpiredWarningOnPageLoad);

  const props = useSpring({
    to: { height: '0', opacity: 0 },
    from: { height: '64px', opacity: 1 },
    reverse: isErrorMessageDisplayed,
  });

  useEffect(() => {
    postSignup().then(({ success, tokenResponse }) => {
      if (!success) {
        navigate(PATHS.PAGE_404);
      } else {
        if (tokenResponse) {
          dispatch({
            type: 'SET_ANONYMOUS_TOKEN',
            payload: tokenResponse.accessToken,
          });
        }
      }
    });
  }, [postSignup, navigate]);

  useEffect(() => {
    if (!shipmentData && anonymousToken) {
      if (trackingCode) {
        getShipmentAddByTrackingCode(trackingCode).then(
          async ({ success: successShipment, shipment }) => {
            if (!successShipment) {
              setIsErrorMessageDisplayed(true);
            } else {
              setShipmentData(shipment);
              navigate(
                generatePath(PATHS.SHIPMENT.ANONYMOUS_DETAIL, {
                  trackingCode: trackingCode,
                })
              );
            }
          }
        );
      }
    }
  }, [
    navigate,
    anonymousToken,
    getShipmentAddByTrackingCode,
    shipmentData,
    trackingCode,
  ]);

  const {
    errors,
    register,
    formState: { touchedFields, isValid },
    getValues,
  } = useFormTemplate<IDeliveryKeyForm>({
    resolver: retrieveDeliveryKeySchema,
    defaultValues: INITIAL_STATE_DELIVERY_KEY_FORM,
  });

  const redirectToLandingPage = useCallback(() => {
    navigate(PATHS.AUTHENTICATION.LANDING);
  }, [navigate]);

  const submitHandler = useCallback(
    (e: any) => {
      e.preventDefault();

      const formValues = getValues();
      const deliveryKey = formValues.deliveryKey;
      setTrackingCode(deliveryKey);
    },
    [getValues]
  );

  return (
    <>
      <AuthenticationView
        title={t('retrieveDeliveryKey.title')}
        description={t('retrieveDeliveryKey.description')}
      >
        <Stack
          component="form"
          onSubmit={submitHandler}
          data-testid="retrieve-delivery-key-container"
        >
          {trackingCode && (
            <animated.div style={props}>
              <Box>
                <Alert
                  state="error"
                  text={t(
                    'retrieveDeliveryKey.form.deliveryKey.validation.invalid'
                  )}
                />
              </Box>
            </animated.div>
          )}
          <Input
            name="deliveryKey"
            label={
              get(touchedFields, 'deliveryKey', false)
                ? t('retrieveDeliveryKey.form.deliveryKey.placeholder')
                : undefined
            }
            placeholder={t('retrieveDeliveryKey.form.deliveryKey.placeholder')}
            error={get(errors, 'deliveryKey', false) || isErrorMessageDisplayed}
            isTouched={get(touchedFields, 'deliveryKey', false)}
            register={register}
            onChange={() => setIsErrorMessageDisplayed(false)}
            width="100%"
            displayErrorMessage={false}
            displayChooseIcon={false}
          />
          <Button
            id="submitBtn"
            action="primary"
            type="submit"
            isDisabled={!isValid}
            style={{ width: '100%', marginBottom: '16px' }}
          >
            {t('retrieveDeliveryKey.buttons.confirm')}
          </Button>
          <Button
            action="secondary"
            onClick={() => redirectToLandingPage()}
            style={{ width: '100%', marginTop: '0' }}
          >
            {t('retrieveDeliveryKey.buttons.cancel')}
          </Button>
        </Stack>
      </AuthenticationView>
      <DeliveryKeyExpiredWarningModal
        isVisible={displayDeliveryKeyExpiredWarning}
        onDismiss={() => setDisplayDeliveryKeyExpiredWarning(false)}
      />
    </>
  );
};

export default RetrieveDeliveryKeyPage;
