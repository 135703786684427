export enum MessageType {
  Error = 'error',
  Success = 'success',
  Info = 'info',
}

export type ToastMessage = {
  title?: string;
  msg?: string;
  cancel?: boolean;
  close?: boolean;
  type: MessageType;
  render?: React.FC<{ closeModal?: (e: React.MouseEvent) => void }>;
};
