import React from 'react';

import { Text } from '@livingpackets/design-system-react-next';
import { useTranslation } from 'react-i18next';

interface IStepShower {
  step: number;
  totalStep: number;
}

const StepShower = ({ step, totalStep }: IStepShower) => {
  const { t } = useTranslation('general');

  return (
    <Text variant="titleM" alignSelf="center" mt="1rem">
      {t('pagination.stepShower', {
        step: step,
        totalStep: totalStep,
      })}
    </Text>
  );
};

export default StepShower;
