import i18next from 'i18next';
import * as yup from 'yup';

export const figuralInsuranceSchema = () => {
  const baseTransKey = 'form.figuralInsurance.fields.';

  return yup.object().shape({
    goodsValue: yup
      .number()
      .typeError(
        i18next.t(`shipments:${baseTransKey}goodsValue.validation.type`)
      )
      .required(
        i18next.t(`shipments:${baseTransKey}goodsValue.validation.positive`)
      )
      .positive(
        i18next.t(`shipments:${baseTransKey}goodsValue.validation.positive`)
      ),
    departureCountryCode: yup.string().required(),
    arrivalCountryCode: yup.string().required(),
    note: yup.string(),
    isGoodsReturnInsured: yup.boolean(),
    sender: figuralInsuranceSenderSchema(),
  });
};

export const figuralInsuranceSenderSchema = () => {
  const baseTransKey = 'form.figuralInsurance.fields.sender.';

  return yup.object().shape({
    firstName: yup
      .string()
      .required(
        i18next.t(`shipments:${baseTransKey}firstName.validation.required`)
      ),
    lastName: yup
      .string()
      .required(
        i18next.t(`shipments:${baseTransKey}lastName.validation.required`)
      ),
    email: yup
      .string()
      .required(
        i18next.t(`shipments:${baseTransKey}email.validation.required`)
      ),
    address: yup
      .string()
      .required(
        i18next.t(`shipments:${baseTransKey}address.validation.required`)
      ),
    city: yup
      .string()
      .required(i18next.t(`shipments:${baseTransKey}city.validation.required`)),
    zipCode: yup
      .string()
      .required(
        i18next.t(`shipments:${baseTransKey}zipCode.validation.required`)
      ),
    countryCode: yup.string().required(),
  });
};
