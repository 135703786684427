import {
  getInitialState,
  IPaginatedStore,
  reducer,
} from 'helpers/paginatedStoreHelpers';
import { IContact } from 'models/contactAddress';
import { create } from 'zustand';

const INITIAL_STATE = getInitialState<IContact>({
  sortBy: 'firstName',
  order: 'DESC',
  pageSize: 10,
});

const useContactAddressStore = create<IPaginatedStore<IContact>>(set => ({
  ...INITIAL_STATE,
  dispatch: (input: any) => set(state => reducer(state, input, INITIAL_STATE)),
}));
export default useContactAddressStore;
