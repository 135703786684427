import React, { useCallback, useEffect, useState } from 'react';

import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
} from '@livingpackets/design-system-react';
import {
  ArrowLeftIcon,
  ButtonV2 as Button,
  Text,
  Stack,
  styled,
} from '@livingpackets/design-system-react-next';
import { PATHS } from 'configs';
import { usePartnership, useUpdatePartnership } from 'features/account';
import { isEmpty } from 'lodash/fp';
import {
  IContact,
  IContactAddress,
  IContactForm,
  INITIAL_STATE_CONTACT_ADDRESS_COMPLETE_FORM,
} from 'models/contactAddress';
import { useTranslation } from 'react-i18next';
import {
  generatePath,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';

import { DefaultAddressesContext } from './PartnerContactAddressAdd';
import useContactAddress, {
  useContactAddressStore,
} from '../../../hooks/useContactAddress';
import useMyPartnersStore, {
  activePartnerSelector,
} from '../../../stores/useMyPartnersStore';
import ContactAddressEditForm from '../Form/ContactAddressEditForm';

const CardContact = styled(Card)`
  width: 100%;

  @media (max-width: ${({ theme }) => theme.mediaBreakpoints.md}) {
    width: auto;
  }
`;

const PartnerContactAddressEdit = () => {
  const navigate = useNavigate();
  let location = useLocation();
  const { t } = useTranslation(['general', 'contactAddress']);
  const { contactId } = useParams();

  const activePartner = useMyPartnersStore(activePartnerSelector);
  const {
    loading,
    editContact,
    editAddress,
    addAddressToContact,
    getContactAddressPostFromContactAddressFormData,
    getContactAddress,
  } = useContactAddress(activePartner.id);

  const pageInfo = location.state || {};
  const displayAddAddressFormOnPageLoad =
    pageInfo.displayAddAddressFormOnPageLoad
      ? pageInfo.displayAddAddressFormOnPageLoad
      : false;

  const [isDismissTriggered, setIsDismissTriggered] = useState<boolean>(false);
  const setContactAddress = useContactAddressStore(
    (state: { setContactAddress: any }) => state.setContactAddress
  );

  const [isContactFormValid, setIsContactFormValid] = useState<boolean>(true);
  const [contactAddressData, setContactAddressData] = useState<IContact>();
  const [selectedAddress, setSelectedAddress] = useState<IContactAddress>();
  const [displayContactInformationForm, setDisplayContactInformationForm] =
    useState<boolean>(false);
  const [displayAddressForm, setDisplayAddressForm] = useState<boolean>(
    displayAddAddressFormOnPageLoad
  );

  const [contactData, setContactData] = useState<IContactForm>(
    INITIAL_STATE_CONTACT_ADDRESS_COMPLETE_FORM
  );

  const { data: partnership } = usePartnership();
  const { mutateAsync: updatePartnership } = useUpdatePartnership({
    showSuccessToast: false,
  });

  const [defaultSenderAddress, setDefaultSenderAddress] = useState<
    string | undefined
  >();
  const [defaultRecipientAddress, setDefaultRecipientAddress] = useState<
    string | undefined
  >();

  const backToContactList = useCallback(() => {
    navigate(
      generatePath(PATHS.CONTACT_ADDRESS.LIST, {
        partnerId: activePartner.id,
      })
    );
  }, [navigate, activePartner]);

  useEffect(() => {
    if (contactId) {
      getContactAddress(contactId).then(({ success, contactAddress }) => {
        if (!success) {
          navigate(PATHS.PAGE_404);
        } else {
          setContactAddressData(contactAddress);
        }
      });

      return () => setContactAddress();
    }
  }, [getContactAddress, contactId, setContactAddress, navigate]);

  const onSubmit = useCallback(async () => {
    let successUpdateContact;
    let successAddAddress;
    let successUpdateAddress;

    if (isContactFormValid && displayContactInformationForm && contactId) {
      const { success } = await editContact(
        contactData.basicInformation,
        contactId
      );

      successUpdateContact = success;
    }

    if (displayAddressForm && contactId) {
      const address = getContactAddressPostFromContactAddressFormData(
        contactData.basicInformation,
        contactData.address
      );

      if (selectedAddress) {
        const { success } = await editAddress(
          address,
          contactId,
          selectedAddress.id
        );

        successAddAddress = success;
      } else {
        const { success } = await addAddressToContact(address, contactId);

        successUpdateContact = success;
      }
    }

    // Update default sender/recipient if any
    let newDefaultAddresses: any = {};
    if (defaultSenderAddress !== undefined)
      newDefaultAddresses['default_sender_address_id'] = defaultSenderAddress;
    if (defaultRecipientAddress !== undefined)
      newDefaultAddresses['default_recipient_address_id'] =
        defaultRecipientAddress;

    if (!isEmpty(newDefaultAddresses)) {
      try {
        updatePartnership({
          values: newDefaultAddresses,
          partnershipId: partnership!.id,
        });
      } catch (_) {
        // TODO: Toast error ?
      }
    }

    if (successUpdateContact || successAddAddress || successUpdateAddress) {
      backToContactList();
    }
  }, [
    editContact,
    contactData,
    contactId,
    isContactFormValid,
    displayContactInformationForm,
    displayAddressForm,
    addAddressToContact,
    editAddress,
    selectedAddress,
    backToContactList,
    getContactAddressPostFromContactAddressFormData,
    partnership,
    updatePartnership,
    defaultSenderAddress,
    defaultRecipientAddress,
  ]);

  const handleDismiss = useCallback(() => {
    if (isDismissTriggered) {
      backToContactList();
    } else {
      setIsDismissTriggered(true);

      setDefaultSenderAddress(undefined);
      setDefaultRecipientAddress(undefined);
    }
  }, [isDismissTriggered, backToContactList]);

  const handleInformationBlockClick = useCallback(() => {
    setDisplayContactInformationForm(true);
    setIsDismissTriggered(false);
  }, []);

  const handleAddressBlockClick = useCallback((address?: IContactAddress) => {
    setSelectedAddress(address);
    setDisplayAddressForm(true);
    setIsDismissTriggered(false);
  }, []);

  const handleOnContactValuesChanged = useCallback((values: IContactForm) => {
    setContactData(values);
  }, []);

  const handleOnFormValidChanged = useCallback((isFormValid: boolean) => {
    setIsContactFormValid(isFormValid);
  }, []);

  const handleAddressDelete = useCallback(
    (addressId: string) => {
      if (contactAddressData) {
        let addressList = contactAddressData.addresses;
        addressList = addressList.filter(
          (address: IContactAddress) => address.id !== addressId
        );

        contactAddressData.addresses = addressList;
        setContactAddressData(contactAddressData);
        setIsDismissTriggered(true);
      }
    },
    [contactAddressData, setContactAddressData, setIsDismissTriggered]
  );

  return (
    <CardContact>
      <React.Fragment key=".0">
        <CardHeader backgroundColor="black.100" color="white" padding={5}>
          <Stack direction="row" alignItems="center" spacing=".75rem">
            <Button
              variant="tertiary"
              icon={ArrowLeftIcon}
              onClick={backToContactList}
            />

            {contactAddressData && (
              <Text variant="bodyTextXS">
                {t('contactAddress:edit.breadcrumb', {
                  contactName:
                    contactAddressData.firstName +
                    ' ' +
                    contactAddressData.lastName,
                })}
              </Text>
            )}
          </Stack>
          <Text variant="titleXS" mt={6}>
            {t('contactAddress:create.title')}
          </Text>
          {contactAddressData && (
            <Text variant="titleM">
              {contactAddressData.firstName + ' ' + contactAddressData.lastName}
            </Text>
          )}
        </CardHeader>
        <CardBody paddingLeft="32px" paddingRight="32px" paddingTop="36px">
          {contactAddressData && (
            <DefaultAddressesContext.Provider
              value={{
                setDefaultSenderAddress,
                setDefaultRecipientAddress,
              }}
            >
              <ContactAddressEditForm
                contactInformation={contactAddressData}
                contactAddresses={contactAddressData.addresses}
                isDismissTriggered={isDismissTriggered}
                onContactBasicInformationBlockClick={
                  handleInformationBlockClick
                }
                onContactAddressBlockClick={handleAddressBlockClick}
                onValuesChanged={handleOnContactValuesChanged}
                isFormValidChanged={handleOnFormValidChanged}
                displayAddAddressFormOnLoad={displayAddAddressFormOnPageLoad}
                onAddressDelete={handleAddressDelete}
              />
            </DefaultAddressesContext.Provider>
          )}
        </CardBody>
        <CardFooter backgroundColor="transparent">
          <Stack direction="row" justifyContent="space-between" marginTop={3}>
            <Button
              data-testid="dismissBtn"
              variant="secondary"
              onClick={handleDismiss}
              disabled={loading}
            >
              {t('contactAddress:form.dismiss.label')}
            </Button>
            <Button
              data-testid="submitBtn"
              variant="primary"
              disabled={!isContactFormValid || loading}
              onClick={onSubmit}
            >
              {t('contactAddress:form.submit.label')}
            </Button>
          </Stack>
        </CardFooter>
      </React.Fragment>
    </CardContact>
  );
};

export default PartnerContactAddressEdit;
