import React from 'react';

import {
  NewTooltip as Tooltip,
  Text,
  LampOnIcon,
  useTheme,
  ButtonV2 as Button,
} from '@livingpackets/design-system-react-next';
import { useTranslation } from 'react-i18next';

const TooltipStartTips = ({ productType }: { productType: string }) => {
  const { t } = useTranslation('remoteControl');
  const theme = useTheme();

  return (
    <Tooltip
      arrow
      id="start-tips-tooltip"
      placement="left"
      enterDelay={200}
      title={
        <>
          <Text variant="titleM" margin="0.5rem">
            {t('startingTips.title')}
          </Text>
          <Text variant="bodyTextS" margin="0.5rem">
            {t('startingTips.message', { product: productType })}
          </Text>
        </>
      }
      sx={{ '& .MuiTooltip-tooltip': { maxWidth: '34.5rem' } }}
      slotProps={{
        popper: {
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, -8],
              },
            },
          ],
        },
      }}
    >
      <Button
        variant="tertiary"
        color="info"
        icon={() => (
          <LampOnIcon color={theme.palette.custom.neutral.black[20]} />
        )}
        sx={{
          '.MuiButton-endIcon': {
            backgroundColor: theme.palette.custom.neutral.black[2],
          },
        }}
      >
        <Text
          variant="titleS"
          color={theme.palette.custom.neutral.black[20]}
          sx={{ padding: '.875rem !important' }}
        >
          {t('startingTips.label')}
        </Text>
      </Button>
    </Tooltip>
  );
};

export default TooltipStartTips;
