import React from 'react';

import {
  Paper,
  PartnershipIcon,
} from '@livingpackets/design-system-react-next';
import useIntlFormatTimestamp from 'hooks/useIntlFormatTimestamp';
import { useTranslation } from 'react-i18next';

import IdentificationForm from './IdentificationForm';
import { usePartnership } from '../../../api/partnership/getPartnership';
import { Alerts } from '../../commons/Alerts';
import { LastUpdateAt } from '../../commons/LastUpdateAt';
import { Loading } from '../../commons/Loading';

export const Information = () => {
  const { t } = useTranslation('account');
  const format = useIntlFormatTimestamp();

  const { isPending, error, data: partnership } = usePartnership();

  if (isPending && !partnership) return <Loading />;

  // Global error screen handled by parent component
  if (error) return <></>;

  return (
    <>
      <Alerts />
      <Paper
        headerIcon={PartnershipIcon}
        headerTitle={t('partnership.information.title')}
        headerInfo={<LastUpdateAt date={format(partnership.updated_at)} />}
        sx={{ p: 0, pt: '1.5rem', gap: '1rem' }}
      >
        <IdentificationForm partnership={partnership} />
      </Paper>
    </>
  );
};
