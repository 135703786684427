import styled from 'styled-components';
import {
  ColorPropsOverride,
  SpaceProps,
  TypographyProps,
  color,
  space,
  typography,
} from 'styled-system';

const PBig = styled.p<
  ColorPropsOverride & Pick<TypographyProps, 'textAlign'> & SpaceProps
>`
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  font-weight: 450;
  ${color}
  ${space}
  ${typography}
`;

export default PBig;
