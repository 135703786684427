import React, { useCallback, useState } from 'react';

import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
} from '@livingpackets/design-system-react';
import {
  ArrowLeftIcon,
  ButtonV2 as Button,
  Text,
  Stack,
} from '@livingpackets/design-system-react-next';
import PartnerMemberCreateForm from 'components/molecules/Form/PartnerMemberCreateForm';
import { PATHS } from 'configs';
import usePartnerMemberManagement from 'hooks/useParternMemberMangement';
import { IMemberForm, INITIAL_STATE_MEMBER_FORM } from 'models/user';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import useMyPartnersStore, {
  activePartnerSelector,
} from '../../../stores/useMyPartnersStore';

const CardContact = styled(Card)`
  width: 808px;

  @media (max-width: ${({ theme }) => theme.mediaBreakpoints.md}) {
    width: auto;
  }
`;

const PartnerContactAddressAdd = () => {
  const { t } = useTranslation(['general', 'userManagement', 'messages']);
  const activePartner = useMyPartnersStore(activePartnerSelector);
  const { loading, addMember, getMemberPostFromMemberFormData } =
    usePartnerMemberManagement();
  const navigate = useNavigate();

  const [isPartnerMemberFormValid, setIsPartnerMemberFormValid] =
    useState<boolean>(false);

  const [memberData, setMemberData] = useState<IMemberForm>(
    INITIAL_STATE_MEMBER_FORM
  );

  const onSubmit = useCallback(async () => {
    const contact = getMemberPostFromMemberFormData(memberData);

    const { success: addMemberSuccess } = await addMember(contact);

    if (addMemberSuccess) {
      navigate(
        generatePath(PATHS.USER_MANAGEMENT.LIST, {
          partnerId: activePartner.id,
        })
      );
    }
  }, [
    addMember,
    memberData,
    activePartner,
    navigate,
    getMemberPostFromMemberFormData,
  ]);

  const backToMemberList = useCallback(() => {
    navigate(
      generatePath(PATHS.USER_MANAGEMENT.LIST, {
        partnerId: activePartner.id,
      })
    );
  }, [navigate, activePartner]);

  const handleOnMemberValuesChanged = useCallback((values: IMemberForm) => {
    setMemberData(values);
  }, []);

  const handleOnFormValidChanged = useCallback((isFormValid: boolean) => {
    setIsPartnerMemberFormValid(isFormValid);
  }, []);

  return (
    <CardContact>
      <React.Fragment key=".0">
        <CardHeader backgroundColor="black.100" color="white" padding={5}>
          <Stack direction="row" alignItems="center" spacing=".75rem">
            <Button
              variant="tertiary"
              icon={ArrowLeftIcon}
              onClick={backToMemberList}
            />
            <Text variant="bodyTextXS">
              {t('userManagement:create.breadcrumb')}
            </Text>
          </Stack>
          <Text variant="titleXS" mt="1rem">
            {t('userManagement:create.title')}
          </Text>
          <Text variant="titleXL">{t('userManagement:create.subTitle')}</Text>
        </CardHeader>
        <CardBody paddingLeft="32px" paddingRight="32px" paddingTop="36px">
          <PartnerMemberCreateForm
            onValuesChanged={handleOnMemberValuesChanged}
            isFormValidChanged={handleOnFormValidChanged}
          />
        </CardBody>
        <CardFooter
          backgroundColor="transparent"
          paddingLeft="32px"
          paddingRight="32px"
        >
          <Stack direction="row" justifyContent="flex-end" marginTop={3}>
            <Button
              data-testid="submitBtn"
              disabled={!isPartnerMemberFormValid || loading}
              onClick={onSubmit}
            >
              {t('userManagement:form.submit.label')}
            </Button>
          </Stack>
        </CardFooter>
      </React.Fragment>
    </CardContact>
  );
};

export default PartnerContactAddressAdd;
