import React from 'react';

import {
  Text,
  ButtonV2 as Button,
  styled,
} from '@livingpackets/design-system-react-next';
import { useTranslation } from 'react-i18next';

import Modal from '../../atoms/Modal';

const ButtonWrapper = styled('div')`
  display: flex;
  justify-content: flex-end;
  margin-top: 32px;
`;

const BillingAddressCheckModal = ({
  isVisible,
  onCancelClick,
}: {
  isVisible: boolean;
  onCancelClick: () => void;
}) => {
  const { t } = useTranslation('accountInformation');

  return (
    <Modal open={isVisible} handleClose={onCancelClick}>
      <Text variant="titleM">{t('modal.billingAddressCheck.title')}</Text>
      <Text variant="bodyTextS" mt="1.5625rem">
        {t('modal.billingAddressCheck.warning')}
      </Text>
      <ButtonWrapper>
        <Button
          onClick={onCancelClick}
          data-testid="modal.billingAddressCheck.button.ok"
        >
          {t('modal.billingAddressCheck.button.ok')}
        </Button>
      </ButtonWrapper>
    </Modal>
  );
};

export default BillingAddressCheckModal;
