import React from 'react';

import {
  ArrowRightIcon,
  Text,
  styled,
  useTheme,
} from '@livingpackets/design-system-react-next';
import Divider from 'components/atoms/Divider';
import { IShipment } from 'models/shipment';
import { useTranslation } from 'react-i18next';

import useIntlFormatTimestamp from '../../hooks/useIntlFormatTimestamp';
import Row from '../atoms/Row';

const Wrapper = styled('div')`
  display: flex;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  border-radius: 14px;
`;
const DetailedWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  width: 59rem;
  height: 5rem;
  background-color: #ffffff;
  border-radius: 14px;
  margin-bottom: 0.5rem;
  padding: 2rem 3rem 2rem 3rem;
`;

const TransactionListItem = ({ shipment }: { shipment: IShipment }) => {
  const { t } = useTranslation('buyAndRent');
  const costPerShipment = shipment?.costPerShipment
    ? (shipment.costPerShipment * 30) / 100
    : 0;
  const theme = useTheme();
  const intlFormatTimestamp = useIntlFormatTimestamp();

  return (
    <>
      <DetailedWrapper style={{ height: '5rem' }}>
        <Wrapper>
          <Row width="12%">
            <Text variant="titleXS">
              {intlFormatTimestamp(shipment.receivedAt, {
                day: 'numeric',
                month: 'short',
                year: 'numeric',
                hour: undefined,
                minute: undefined,
              })}
            </Text>
          </Row>
          <Divider
            marginTop="-1rem"
            marginRight="6rem"
            marginLeft="0.5rem"
            direction="vertical"
            borderColor={theme.colors.black[10]}
            height="3.25rem"
          />
          <Row width="17%">
            <Text variant="titleXS">{shipment.sender.address.city}</Text>
          </Row>
          <Row width="11%">
            <ArrowRightIcon color="#00BF6F" />
          </Row>
          <Row width="28%">
            <Text variant="titleXS">{shipment.recipient.address.city}</Text>
          </Row>
          <Row
            width="15%"
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              marginRight: '2.8rem',
            }}
          >
            <Text variant="titleXS">
              {t('buyNRent.shipments.costPerShipment', {
                costPerShipment: costPerShipment,
              })}
            </Text>
          </Row>
        </Wrapper>
      </DetailedWrapper>
      <Divider direction="horizontal" />
    </>
  );
};

export default TransactionListItem;
