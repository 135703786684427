import React from 'react';

import { Text } from '@livingpackets/design-system-react-next';
import Auth0Badge from 'assets/auth0logo.png';
import Row from 'components/atoms/Row';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const Auth0Logo = styled.img`
  height: 1.4rem;
`;

const SecuredByAuth0 = () => {
  const { t } = useTranslation('general');

  return (
    <Row justifyContent="center" alignItems="center">
      <Text variant="bodyTextS" mr={2}>
        {t('securedBy')}
      </Text>
      <a href="https://auth0.com" target="_blank" rel="noreferrer noopener">
        <Auth0Logo src={Auth0Badge} alt="Auth0 Logo" />
      </a>
    </Row>
  );
};

export default SecuredByAuth0;
