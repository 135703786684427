import { parsePhoneNumber } from 'libphonenumber-js';
import { isEmpty } from 'lodash/fp';

/**
 * Format a phone number to a human readable format
 * @param phoneNumber (E164 format)  +33689684502
 * @returns formatted phone number (+33) 6 89 68 45 02
 */
export const formatPhoneNumber = (phoneNumber: string) => {
  if (isEmpty(phoneNumber)) return;

  const formattedPhone = parsePhoneNumber(phoneNumber).formatInternational();

  // Find the first space and insert a closing parenthesis using replace + add manually the opening one
  return `(${formattedPhone.replace(' ', ') ')}`;
};
