export enum ImportShipmentStepEnum {
  CarrierSelectionStep = 'carrierSelectionStep',
  LabelImportStep = 'LabelImportStep',
  ShipmentDetailsStep = 'shipmentDetailsStep',
  ReturnShipmentCreationStep = 'returnShipmentCreationStep',
  InsuranceStep = 'insuranceStep',
  ShipmentSummaryStep = 'shipmentSummaryStep',
}

export enum ImportShipmentAddressFormTypeEnum {
  sender = 'sender',
  recipient = 'recipient',
}
