import styled from 'styled-components';
import { space, SpaceProps } from 'styled-system';

const NameCardSkeleton = styled.div<SpaceProps>`
  height: 2rem;
  width: 2rem;
  border-radius: 0.438rem;
  background: ${({ theme }) => theme.colors.black[4]};
  ${space}
`;

export default NameCardSkeleton;
