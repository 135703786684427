import {
  getInitialState,
  IPaginatedStore,
  reducer,
} from 'helpers/paginatedStoreHelpers';
import { IPartnerAccount } from 'models/user';
import { create } from 'zustand';

const INITIAL_STATE = getInitialState<IPartnerAccount>({
  sortBy: 'created_at',
  order: 'DESC',
  pageSize: 10,
  rowData: [],
});

const usePartnerMemberManagementStore = create<
  IPaginatedStore<IPartnerAccount>
>(set => ({
  ...INITIAL_STATE,
  dispatch: (input: any) => set(state => reducer(state, input, INITIAL_STATE)),
}));
export default usePartnerMemberManagementStore;
