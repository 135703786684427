import { useCallback } from 'react';

import {
  Paginated,
  UseFetchResource,
  useFetchResource,
  UseFetchResourceOptions,
} from '@livingpackets/shared-components';
import { IShipment } from 'models/shipment';

import {
  GetUserShipmentsParams,
  useGetUserShipments,
} from './useGetUserShipments';

export function useUserShipments(
  params: GetUserShipmentsParams,
  { enabled = true }: UseFetchResourceOptions = {}
): UseFetchResource<Paginated<IShipment>> {
  const getUserShipments = useGetUserShipments();

  const fetchFunction = useCallback(
    () => getUserShipments(params),
    [getUserShipments, params]
  );

  return useFetchResource(fetchFunction, { enabled });
}
