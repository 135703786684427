import { Carriers } from 'configs/carriers';
import { ShipmentLabelTypeEnum } from 'enums/ShipmentLabelTypeEnum';
import {
  ShipmentAnonymousTimelineThresholdTypeEnum,
  ShipmentTimelineEventTypeEnum,
  ShipmentTimelineThresholdTypeEnum,
} from 'enums/ShipmentTimelineEnum';
import {
  CREATE_SHIPMENT_ADDRESS_DEFAULT,
  IBaseAddress,
  IShipmentAddress,
} from 'models/address';
import { ContactAddressType } from 'models/contactAddress';
import {
  IFiguralCreatePartnerParcelResponse,
  IFiguralInsurance,
} from 'models/figuralInsurance';
import { ProductStateModel } from 'models/product';
import { Control, DeepMap } from 'react-hook-form';
import { SpaceProps } from 'styled-system';

export interface ICustomer {
  accountId: string;
  address: IShipmentAddress;
  id: string;
  notificationEmail: string;
  notification_email?: string;
  phone_number?: string;
}

export type TCarrierCodes =
  | 'Invalid'
  | 'CHRONOPOST'
  | 'DHL'
  | 'DHLExpress'
  | 'DPD'
  | 'HERMES'
  | 'LPCarrier'
  | 'FEDEX'
  | 'UPS'
  | 'TNTExpress'
  | 'MIIST'
  | 'COLISSIMO'
  | 'Other';

export interface ICarrierLabel {
  code: TCarrierCodes;
  url: string;
}

export interface IQuote {
  currency: string;
  priceInCents: number;
  carrierService: string;
  carrierCode: TCarrierCodes;
  id: string;
  error?: any;
}

export interface IQuoteModified {
  currency: string;
  priceInCents: number;
  carrierService: string;
  carrierCode: string;
  id: string;
}

export interface ICarrierServices {
  carrierCode: TCarrierCodes;
  services: string[];
}

export interface ICarrierService {
  carrierCode: TCarrierCodes;
  service: string;
}

export type TServiceLevel = 0 | 1 | 2 | 3 | 4;

export interface IServiceLevelQuote {
  currency: string;
  error?: string;
  id: string;
  priceInCents: number;
  serviceLevel: TServiceLevel;
}

export interface ILabelDetailsForm extends SpaceProps {
  errors: DeepMap<any, true>;
  touched: DeepMap<any, true>;
  register: any;
}

export type TContractState =
  | ''
  | 'DRAFT'
  | 'PURCHASED'
  | 'DEPLOYED'
  | 'ARRIVED'
  | 'ARCHIVED'
  | 'ERROR';

export interface ISenderRecipientForm extends SpaceProps {
  isSender?: boolean;
  prefix?: string;
  errors: DeepMap<any, true>;
  touched: DeepMap<any, true>;
  register: any;
  control: Control;
  defaultValues?: IBaseAddress;
  defaultContactEmail?: string;
  setValue: (
    name: string,
    value: string | undefined,
    config?: {} | undefined
  ) => void;
  trigger: (name?: string | string[] | undefined) => Promise<boolean>;
  watch: any;
}

export interface IPackageDetailsForm extends SpaceProps {
  prefix?: string;
  errors: DeepMap<any, true>;
  touched: DeepMap<any, true>;
  register: any;
}

export type DeliveryStatus =
  | 'NONE'
  | 'INVALID'
  | 'PRE_TRANSIT'
  | 'IN_TRANSIT'
  | 'OUT_FOR_DELIVERY'
  | 'AVAILABLE_FOR_PICKUP'
  | 'DELIVERED'
  | 'RETURN_TO_SENDER'
  | 'CANCELLED'
  | 'DESTROYED'
  | 'FAILURE'
  | 'ERROR';

export type ShipmentContractState =
  | 'INVALID'
  | 'DRAFT'
  | 'PURCHASED'
  | 'TRANSFERRED'
  | 'DEPLOYED'
  | 'ARRIVED'
  | 'ERROR'
  | 'CANCELLED'
  | 'ARCHIVED';

export enum ShipmentContractStateEnum {
  INVALID = 'INVALID',
  DRAFT = 'DRAFT',
  PURCHASED = 'PURCHASED',
  TRANSFERRED = 'TRANSFERRED',
  DEPLOYED = 'DEPLOYED',
  ARRIVED = 'ARRIVED',
  CANCELLED = 'CANCELLED',
  ERROR = 'ERROR',
}

export enum DeliveryStatusEnum {
  NONE = 'NONE',
  INVALID = 'INVALID',
  PRE_TRANSIT = 'PRE_TRANSIT',
  IN_TRANSIT = 'IN_TRANSIT',
  OUT_FOR_DELIVERY = 'OUT_FOR_DELIVERY',
  AVAILABLE_FOR_PICKUP = 'AVAILABLE_FOR_PICKUP',
  DELIVERED = 'DELIVERED',
  RETURN_TO_SENDER = 'RETURN_TO_SENDER',
  CANCELLED = 'CANCELLED',
  DESTROYED = 'DESTROYED',
  FAILURE = 'FAILURE',
  ERROR = 'ERROR',
}

export enum ShipmentSearchFieldEnum {
  SHIPMENT_NAME = 'shipmentName',
  CARRIER_TRACKING_CODE = 'carrierTrackingCode',
  RECIPIENT = 'recipient',
  SENDER = 'sender',
}

export type DeliveryEvent =
  | 'CARRIER_NOTIFICATION'
  | 'DELAYED'
  | 'BOX_LOCKED'
  | 'BOX_UNLOCKED'
  | 'CONFIRMED_TRANSMISSION'
  | 'CONFIRMED_DELIVERY'
  | 'RESET';

export interface IShipment {
  receivedAt: number;
  product: IShipmentBox;
  boxWeightInGrams?: number;
  /**
   * @deprecated The property will change in V3
   */
  carrierLabel: ICarrierLabel;
  label_url: string;
  /**
   * @deprecated The property will change in V3
   */
  carrierTrackingCode?: string;
  carrier_tracking_code: string;
  carrier_code: TCarrierCodes;
  /**
   * @deprecated The property will change in V3
   */
  contractState?: ShipmentContractState;
  contract_state: ShipmentContractState;
  /**
   * @deprecated The property will change in V3
   */
  createdAt?: number;
  created_at: number;
  createdBy: string;
  deliveryStatus: DeliveryStatus;
  deliveryTime: 'ON_TIME' | 'DELAYED';
  shipment_id: string;
  id?: string;
  isArchived: boolean;
  labelWeightInGrams?: number;
  /**
   * @deprecated The property will change in V3
   */
  originalShipment: IShipment | null;
  original_shipment: IShipment | null;
  /**
   * @deprecated The property will change in V3
   */
  originalShipmentId?: string;
  original_shipment_id?: string;
  partnerId?: string;
  quote?: IQuote;
  recipient: ICustomer;
  /**
   * @deprecated The property will change in V3
   */
  reference?: string;
  shipment_name?: string;
  /**
   * @deprecated The property will change in V3
   */
  returnShipment: IShipment | null;
  return_shipment: IShipment | null;
  /**
   * @deprecated The property will change in V3
   */
  returnShipmentId?: string;
  return_shipment_id?: string;
  sender: ICustomer;
  serviceLevel: TServiceLevel;
  serviceLevelQuote: IServiceLevelQuote;
  shipmentType: 'INVALID' | 'C_TO_C' | 'PARTNER' | 'TEST';
  updatedAt: number;
  versionHash: string;
  weight_in_grams?: number;
  weightInGrams?: number;
  productOwnerId?: string;
  costPerShipment?: number;
  deployedAt: number;
  secursusParcelId?: string;
  secursus_parcel_id?: string;
  parcelRequestId: string;
  parcel_request_id: string;
  partnerParcelRequestData?: IFiguralCreatePartnerParcelResponse;
  partnerParcelData?: IFiguralCreatePartnerParcelResponse;
  product_state?: ProductStateModel;
  hasAnonymousTracking?: boolean;
  opening_code?: string;
}

export interface IShipmentGeoPath {
  shipment_id: string;
  lp_ui: string;
  coordinates: IShipmentGeoPathCoordinates[];
}

export interface IShipmentGeoPathCoordinates {
  accuracy: number;
  in_airport_area: boolean;
  latitude: number;
  longitude: number;
  place_id: string;
  timestamp: number;
}

export interface IShipmentProductState {
  battery_level: number;
  humidity_external: number;
  humidity_internal: number;
  pressure: number;
  temperature_external: number;
  temperature_internal: number;
  last_update: number;
  article_code: string;
}

export interface IShipmentAnonymous {
  carrier_code: TCarrierCodes;
  recipient: IShipmentAccount;
  sender: IShipmentAccount;
  shipment_id: string;
  shipment_name: string;
  weight_in_grams: number;
  contract_state: ShipmentContractState;
  carrier_tracking_code: string;
  created_at: number;
  product: IShipmentProduct;
}

interface IShipmentProduct {
  article_code: string;
  article_embedded_code: number;
  lp_ui: string;
}

export interface IShipmentAccount {
  account_id: string;
  address: IShipmentAccountAddress;
  address_id: string;
  notification_email: string;
  phone_number: string;
}

export interface IShipmentAccountAddress {
  city: string;
  company: string;
  country_code: string;
  display_name: string;
  first_name: string;
  house_number: number;
  last_name: string;
  postal_code: string;
  state: string;
  street: string;
  street2: string;
  position: { latitude: number; longitude: number };
}

interface IShipmentAnonymousTimeline {
  events: IShipmentAnonymousTimelineEvent[];
}

export interface IShipmentAnonymousTimelineEvent {
  box_violation: {
    position: IShipmentAnonymousTimelineEventPosition;
    value: {
      cover_open: boolean;
      key_present: boolean;
      light_sensor_on: boolean;
    };
  };
  opened_at: number;
  closed_at?: number;
  event_source: string;
  event_type: ShipmentTimelineEventTypeEnum;
  humidity_internal: {
    min: number;
    max: number;
    position: [IShipmentAnonymousTimelineEventPosition];
    threshold: 0;
    threshold_type: ShipmentAnonymousTimelineThresholdTypeEnum;
    values: IShipmentAnonymousTimelineEventValue[];
  };
  humidity_external: {
    min: number;
    max: number;
    position: [IShipmentAnonymousTimelineEventPosition];
    threshold: 0;
    threshold_type: ShipmentAnonymousTimelineThresholdTypeEnum;
    values: IShipmentAnonymousTimelineEventValue[];
  };
  pressure: {
    min: number;
    max: number;
    position: [IShipmentAnonymousTimelineEventPosition];
    threshold: number;
    threshold_type: ShipmentAnonymousTimelineThresholdTypeEnum;
    values: IShipmentAnonymousTimelineEventValue[];
  };
  shipment: {};
  shock: {
    position: IShipmentAnonymousTimelineEventPosition;
    threshold: number;
    threshold_type: ShipmentAnonymousTimelineThresholdTypeEnum;
    value: number;
  };
  temperature_internal: {
    min: number;
    max: number;
    position: [IShipmentAnonymousTimelineEventPosition];
    threshold: number;
    threshold_type: ShipmentAnonymousTimelineThresholdTypeEnum;
    values: IShipmentAnonymousTimelineEventValue[];
  };
  temperature_external: {
    min: number;
    max: number;
    position: [IShipmentAnonymousTimelineEventPosition];
    threshold: number;
    threshold_type: ShipmentAnonymousTimelineThresholdTypeEnum;
    values: IShipmentAnonymousTimelineEventValue[];
  };
  battery_level: {
    min: number;
    max: number;
    position: [IShipmentAnonymousTimelineEventPosition];
    threshold: number;
    threshold_type: ShipmentAnonymousTimelineThresholdTypeEnum;
    values: IShipmentAnonymousTimelineEventValue[];
  };
}

export interface IShipmentAnonymousTimelineEventValue {
  ts: number;
  value: number;
}

export interface IShipmentAnonymousTimelineEventPosition {
  lat: number;
  lon: number;
  time: number;
}

export interface IShipmentAnonymousData {
  geo_path: IShipmentGeoPath;
  product_state: IShipmentProductState;
  shipment: IShipmentAnonymous;
  timeline: IShipmentAnonymousTimeline;
}

export interface IShipmentGeoPath {
  shipment_id: string;
  lp_ui: string;
  coordinates: IShipmentGeoPathCoordinates[];
}

export interface IShipmentGeoPathCoordinates {
  accuracy: number;
  in_airport_area: boolean;
  latitude: number;
  longitude: number;
  place_id: string;
  timestamp: number;
}

export interface IShipmentProductState {
  battery_level: number;
  humidity: number;
  pressure: number;
  temperature: number;
  last_known_state: number;
  article_code: string;
}

export interface IShipmentAnonymous {
  carrier_code: TCarrierCodes;
  recipient: IShipmentAccount;
  sender: IShipmentAccount;
  shipment_id: string;
  shipment_name: string;
  weight_in_grams: number;
  contract_state: ShipmentContractState;
  carrier_tracking_code: string;
  created_at: number;
  partner_id: string;
}

export interface IShipmentAccount {
  account_id: string;
  address: IShipmentAccountAddress;
  address_id: string;
  notification_email: string;
  phone_number: string;
}

export interface IShipmentAccountAddress {
  city: string;
  company: string;
  country_code: string;
  display_name: string;
  first_name: string;
  house_number: number;
  last_name: string;
  postal_code: string;
  state: string;
  street: string;
  street2: string;
  position: { latitude: number; longitude: number };
}

export interface IShipmentBox {
  id: string;
  bleMacAddress: string;
  thingName?: string;
  lp_ui: string;
  article_code?: string;
  articleEmbeddedCode?: number;
  ownerId?: string;
}

export type TAlert = { timestamp: number } & (
  | {
      type: 'ALERT_HUMIDITY';
      data: {
        humidity: number;
      };
    }
  | {
      type: 'ALERT_SHOCK';
      data: {};
    }
  | {
      type: 'ALERT_PRESSURE';
      data: {
        pressure: number;
      };
    }
  | {
      type: 'ALERT_TEMPERATURE';
      data: {
        temperature: number;
      };
    }
  | {
      type: 'ALERT_BOX_VIOLATION';
      data: {
        switchOn: boolean;
        lightOn: boolean;
      };
    }
  | {
      type: 'ALERT_BATTERY_LEVEL';
      data: {
        level: number;
      };
    }
);

interface ICoordinate {
  latitude: number;
  longitude: number;
  placeId?: string;
  timestamp: number;
}

interface ILastKnownState {
  acceleration: number;
  humidityExternal: number;
  humidityInternal: number;
  pressure: number;
  temperatureExternal: number;
  temperatureInternal: number;
  timestamp: number;
  remainingTime: number;
  batteryLevel: number;
}

export interface ITrackingHistoryResponse {
  alerts: TAlert[];
  lastKnownLocation?: ICoordinate;
  lastKnownState?: ILastKnownState;
  rawPath: ICoordinate[];
  roadPath: ICoordinate[];
  shipmentId: string;
}

export interface ILabel {
  senderAddress: IBaseAddress;
  recipientAddress: IBaseAddress;
  weightInGrams?: number;
  carrierCode: string;
  carrierTrackingCode: string;
  referenceNumber: string;
  notificationEmailSender: string;
  notificationEmailRecipient: string;
  labelImage: string;
  secursusInsurance?: IFiguralInsurance;
}

export type IOcrLabel =
  | {
      senderAddress: ICreateShipmentAddressForm;
      recipientAddress: ICreateShipmentAddressForm;
      weightInGrams?: number;
      carrierTrackingCode: string;
      referenceNumber: string;
      previewUrl?: string;
      parcelRequestId?: string;
      labelType?: ShipmentLabelTypeEnum;
      senderPhoneNumber?: string;
      recipientPhoneNumber?: string;
    } & (
      | { type: 'ocr'; ocrId: string }
      | { type: 'manualCrop'; manualCrop: Blob }
    );

export interface IOcrRequest {
  ocrRequestId: string;
  originalOcrId: string;
  returnOcrId: string;
}

export interface OcrResultSuccess {
  s3PreviewUrl: string;
  s3ExtractedLabelUrl: string;
  status: 'COMPLETED';
}

export type OcrResult =
  | OcrResultSuccess
  | { status: 'PENDING' | 'IDLE' | 'ERROR' };

export interface IShipmentOcr {
  document?: File;
  returnDocument?: File;
  carrierCode?: string;
  returnCarrierCode?: string;
  pageNumber?: number;
  returnPageNumber?: number;
  isThermal?: boolean;
}

export const INITIAL_STATE_SHIPMENT = {
  senderAddress: {
    firstName: '',
    lastName: '',
    company: '',
    street: '',
    city: '',
    postalCode: '',
    country: '',
    notificationEmail: '',
  },
  recipientAddress: {
    firstName: '',
    lastName: '',
    company: '',
    street: '',
    city: '',
    postalCode: '',
    country: '',
    notificationEmail: '',
  },
  weightInGrams: '',
  notificationEmailSender: '',
  notificationEmailRecipient: '',
  referenceNumber: '',
  carrierTrackingCode: '',
  carrierCode: '',
};

export interface IShipmentPurchase {
  senderAddress: {
    firstName: string;
    lastName: string;
    company: string;
    street: string;
    city: string;
    postalCode: string;
    countryCode: string;
    notificationEmail: string;
    id: string;
  };
  recipientAddress: {
    firstName: string;
    lastName: string;
    company: string;
    street: string;
    city: string;
    postalCode: string;
    country: string;
    notificationEmail: string;
  };
  weightInGram?: string;
  weightInKilograms: string;
  notificationEmailSender: string;
  notificationEmailRecipient: string;
  referenceNumber: string;
  carrierTrackingCode: string;
  carrierCode: string;
}

export interface IStateEvent {
  id: string;
  eventName: DeliveryStatus | ShipmentContractState | DeliveryEvent;
  eventType: 'CONTRACT' | 'CONFIRMATION' | 'PAYMENT' | 'DELIVERY_STATUS';
  date: number;
  eventCause: 'SENDER' | 'RECIPIENT' | 'CARRIER' | 'BOX';
  payload: any;
}

export interface IStateEventResponse {
  count: number;
  total: number;
  shipmentId: string;
  events: IStateEvent[] | null;
}

export const INITIAL_STATE_OCR_LABEL: IOcrLabel = {
  senderAddress: CREATE_SHIPMENT_ADDRESS_DEFAULT,
  recipientAddress: CREATE_SHIPMENT_ADDRESS_DEFAULT,
  weightInGrams: undefined,
  carrierTrackingCode: '',
  referenceNumber: '',
  type: 'ocr',
  ocrId: '',
  previewUrl: '',
  labelType: ShipmentLabelTypeEnum.PDF,
  senderPhoneNumber: '',
  recipientPhoneNumber: '',
};

export interface IShipmentSearchForm {
  shipmentData: string;
  date: string;
  startTime: string;
  endTime: string;
}

export const INITIAL_STATE_SHIPMENT_SEARCH_FORM: IShipmentSearchForm = {
  shipmentData: '',
  date: '',
  startTime: '',
  endTime: '',
};

export const FAKE_SHIPMENT: IShipment = {
  receivedAt: 0,
  labelWeightInGrams: 1,
  shipment_id: '6e6d1c7d-2136-4c3c-bd6c-6078d645feac',
  created_at: 1607359879,
  isArchived: false,
  updatedAt: 1607359942,
  partnerId: '',
  createdBy: '5e9c581967e8740c1edde868',
  product: {
    lp_ui: '95ebe459-4553-423b-af7d-4326987a1127',
    id: '95ebe459-4553-423b-af7d-4326987a1127',
    bleMacAddress: '',
    thingName: '0c1187f0-a2ae-4590-a40f-98cc99075977',
  },
  sender: {
    id: '655a4063-d23c-49be-9a01-36f65f949498',
    accountId: '',
    address: {
      type: ContactAddressType.professional,
      id: 'e795deb9-42d3-4f13-a33c-67d47230fe34',
      originalAddressId: '',
      displayName: '',
      company: '',
      firstName: 'John',
      lastName: 'Oliver',
      street: 'Forster Strasse',
      street2: '',
      postalCode: '10999',
      city: 'Berlin',
      state: '',
      countryCode: 'DE',
      position: { longitude: 15.427201, latitude: 53.49569 },
      createdAt: 1607359879,
      updatedAt: 1607359879,
      createdBy: '5e9c581967e8740c1edde868',
      versionHash: 'b9b9ffcd-9f83-4033-b0dd-be7158ac4ba6',
    },
    notificationEmail: '',
  },
  recipient: {
    id: '6b5f0c72-83fb-4a22-aab3-1c465d1ef18f',
    accountId: '',
    address: {
      type: ContactAddressType.professional,
      id: '085c1d78-ad11-4cf3-b317-056285f4b818',
      originalAddressId: '',
      displayName: '',
      company: 'Telekom',
      firstName: 'Oliver',
      lastName: 'Doe',
      street: 'Wiener Str.',
      street2: 'EG',
      postalCode: '10999',
      city: 'Berlin',
      state: '',
      countryCode: 'DE',
      position: { longitude: 12.427201, latitude: 52.49569 },
      createdAt: 1607359879,
      updatedAt: 1607359879,
      createdBy: '5e9c581967e8740c1edde868',
      versionHash: '64521989-cf66-4fad-945d-a06934c0c29e',
    },
    notificationEmail: '',
  },
  contract_state: 'ARRIVED',
  reference: '39293929392',
  shipmentType: 'TEST',
  deliveryStatus: 'NONE',
  serviceLevel: 2,
  carrier_code: 'DPD',
  quote: {
    id: 'e6454ed9-f216-445e-85a6-f4911f931ffb',
    carrierCode: 'DPD',
    carrierService: 'standard',
    priceInCents: 0,
    currency: 'EUR',
  },
  serviceLevelQuote: {
    id: '2712d51d-06ca-4070-97fa-e6bb0f80cb60',
    serviceLevel: 2,
    currency: 'EUR',
    priceInCents: 0,
  },
  carrierLabel: {
    code: 'DPD',
    url: 'https://api.test.livingpackets.com/shipment/api/v2/shipments/fdd00647-97c4-4f0c-b3db-d1f11a712cc1/elabel.png',
  },
  label_url:
    'https://api.test.livingpackets.com/shipment/api/v2/shipments/fdd00647-97c4-4f0c-b3db-d1f11a712cc1/elabel.png',
  carrier_tracking_code: 'ABCD1234',
  weightInGrams: 3000,
  versionHash: '95f697dd-5bfc-4090-9081-7cc26cc24fcd',
  deliveryTime: 'ON_TIME',
  originalShipment: null,
  original_shipment: null,
  returnShipment: null,
  return_shipment: null,
  productOwnerId: 'blabla',
  costPerShipment: 2,
  deployedAt: 0,
  parcelRequestId: '',
  parcel_request_id: '',
};

export const FAKE_TRACKING_DATE: ITrackingHistoryResponse = {
  shipmentId: 'a7e94818-d0e7-48c4-b8ee-6d01764766ee',
  lastKnownLocation: {
    longitude: 13.406935,
    latitude: 52.505859,
    timestamp: 1609947975,
  },
  alerts: [
    {
      type: 'ALERT_HUMIDITY',
      data: {
        humidity: 99,
      },
      timestamp: 1609947977,
    },
    {
      type: 'ALERT_SHOCK',
      data: {},
      timestamp: 1609948500,
    },
    {
      type: 'ALERT_TEMPERATURE',
      data: {
        temperature: 12,
      },
      timestamp: 1609949000,
    },
    {
      type: 'ALERT_BOX_VIOLATION',
      timestamp: 1609946000,
      data: { switchOn: true, lightOn: true },
    },
  ],
  roadPath: [],
  rawPath: [
    {
      latitude: 13.399986,
      longitude: 52.509487,
      timestamp: 1609947972,
    },
    {
      latitude: 13.398518,
      longitude: 52.507378,
      timestamp: 1609947973,
    },
    {
      latitude: 13.401323,
      longitude: 52.506271,
      timestamp: 1609947974,
    },
    {
      latitude: 13.406935,
      longitude: 52.505859,
      timestamp: 1609947975,
    },
  ],
  lastKnownState: {
    acceleration: 0,
    remainingTime: 2,
    batteryLevel: 5,
    temperatureInternal: 1,
    humidityInternal: 2,
    temperatureExternal: 3,
    humidityExternal: 4,
    pressure: 5,
    timestamp: 1609947970,
  },
};

export const FAKE_STATE_EVENTS: IStateEventResponse = {
  count: 8,
  total: 8,
  shipmentId: '93ae71ae-5df8-406e-916d-149dfe81a5ea',
  events: [
    {
      id: 'd4c4dabb-4d0f-4ccb-baff-7f803ad21d44',
      eventName: 'PURCHASED',
      eventType: 'CONTRACT',
      date: 1610098727,
      eventCause: 'SENDER',
      payload: null,
    },
    {
      id: '6d517dfb-d158-4d8e-9650-a4c78173a1ae',
      eventName: 'PRE_TRANSIT',
      eventType: 'DELIVERY_STATUS',
      date: 1610098732,
      eventCause: 'CARRIER',
      payload: null,
    },
    {
      id: 'aa1c3012-18d3-474a-a108-a9638592d2bf',
      eventName: 'DEPLOYED',
      eventType: 'CONTRACT',
      date: 1610098732,
      eventCause: 'CARRIER',
      payload: null,
    },
    {
      id: '5d1b1f99-87b3-4af7-8fff-7684f4d63af6',
      eventName: 'IN_TRANSIT',
      eventType: 'DELIVERY_STATUS',
      date: 1610098735,
      eventCause: 'CARRIER',
      payload: null,
    },
    {
      id: 'd33b3c08-6612-40fa-bbf0-eb0bb833acf6',
      eventName: 'OUT_FOR_DELIVERY',
      eventType: 'DELIVERY_STATUS',
      date: 1610098737,
      eventCause: 'CARRIER',
      payload: null,
    },
    {
      id: 'f4798934-ea60-4e97-a10f-5fc01bc12929',
      eventName: 'AVAILABLE_FOR_PICKUP',
      eventType: 'DELIVERY_STATUS',
      date: 1610098739,
      eventCause: 'CARRIER',
      payload: null,
    },
    {
      id: 'ebe3f6a8-c668-477d-810d-c512c58deceb',
      eventName: 'DELIVERED',
      eventType: 'DELIVERY_STATUS',
      date: 1610098742,
      eventCause: 'CARRIER',
      payload: null,
    },
    {
      id: '0a6e486f-13f2-4734-9b9d-09b176fe8840',
      eventName: 'ARRIVED',
      eventType: 'CONTRACT',
      date: 1610098742,
      eventCause: 'CARRIER',
      payload: null,
    },
  ],
};

// Shipment Detail

export interface IShipmentNameForm {
  name: string;
}

export const INITIAL_STATE_SHIPMENT_NAME_FORM: IShipmentNameForm = {
  name: '',
};

// Shipment Timeline

export interface IShipmentTimelineResponse {
  count: number;
  items: IShipmentTimelineEntry[];
  total: number;
}

export interface IShipmentTimelineEntry {
  eventID: string;
  closedAt?: number;
  createdAt: number;
  eventSource: string;
  eventType: ShipmentTimelineEventTypeEnum;
  payload:
    | IShipmentTimelineEntryPayloadBoxViolation
    | IShipmentTimelineEntryMonoInfo
    | IShipmentTimelineEntryMultiInfo
    | null;
}

export interface IShipmentTimelineEntryPayloadBoxViolation {
  position: IShipmentTimelineEntryPosition | null;
  value: {
    coverOpen: boolean;
    keyPresent: boolean;
    lightSensorOn: boolean;
  };
}

export interface IShipmentTimelineEntryMonoInfo {
  position: IShipmentTimelineEntryPosition | null;
  threshold: number;
  thresholdType: ShipmentTimelineThresholdTypeEnum;
  value: number;
}

export interface IShipmentTimelineEntryMultiInfo {
  positions: IShipmentTimelineEntryPosition[];
  threshold: number;
  thresholdType: ShipmentTimelineThresholdTypeEnum;
  values: IShipmentTimelineEntryValueItem[];
}

export interface IShipmentTimelineEntryPosition {
  lat: number;
  lon: number;
  ts: number;
}

export interface IShipmentTimelineEntryValueItem {
  ts: number;
  value: number;
}

export interface ICreateShipmentAddressForm {
  type: ContactAddressType;
  firstName: string;
  lastName: string;
  company: string;
  email: string;
  street: string;
  city: string;
  postalCode: string;
  countryCode: string;
}

export type ShipmentParcelData = {
  weightInGrams?: number;
  carrierTrackingCode: string;
  referenceNumber: string;
};

export type ShipmentAddressData = {
  senderAddress: ICreateShipmentAddressForm;
  recipientAddress: ICreateShipmentAddressForm;
};

export type ShipmentAddressAndContactData = {
  senderPhoneNumber?: string;
  recipientPhoneNumber?: string;
  senderAddress: ICreateShipmentAddressForm;
  recipientAddress: ICreateShipmentAddressForm;
};

export type ShipmentData = {
  senderAddress: ICreateShipmentAddressForm;
  previewUrl?: string;
  labelType?: ShipmentLabelTypeEnum;
  carrierTrackingCode: string;
  notificationEmailSender: string;
  type: undefined;
  manualCrop: undefined;
  manualCropId: string | undefined;
  ocrId: undefined | string;
  referenceNumber: string;
  carrierCode: Carriers | undefined;
  weightInGrams?: number;
  parcelRequestId?: string;
  recipientAddress: ICreateShipmentAddressForm;
  notificationEmailRecipient: string;
  senderPhoneNumber?: string;
  recipientPhoneNumber?: string;
};

export type IShipmentToImport = {
  originalShipment: ShipmentData;
  returnShipment: ShipmentData;
};

export type CreateShipmentPartnerImportOcRequest = {
  originalShipment: LabelDataImport;
  returnShipment?: LabelDataImport;
};

export type LabelDataImport = {
  weightInGrams?: number;
  carrierCode?: Carriers;
  carrierTrackingCode: string;
  labelWeightInGrams?: number;
  manualCrop?: string;
  manualCropId?: string;
  notificationEmailRecipient: string;
  notificationEmailSender: string;
  ocrId?: string;
  type?: string;
  parcelRequestId?: string;
  recipientAddress: CreateAddressRequest;
  referenceNumber: string;
  senderAddress: CreateAddressRequest;
  senderPhoneNumber?: string;
  recipientPhoneNumber?: string;
};

export type CreateAddressRequest = {
  city: string;
  company: string;
  countryCode: string;
  displayName?: string;
  firstName: string;
  houseNumber: string;
  lastName: string;
  postalCode: string;
  state?: string;
  street: string;
  street2: string;
};
