import React from 'react';

import styled from 'styled-components';

interface ISpacerX {
  x: string;
}

interface ISpacerY {
  y: string;
}

type ISpacer = ISpacerX | ISpacerY;

const Div = styled.div<ISpacerY>`
  height: ${({ y }) => y || '0'};
`;

const Span = styled.span<ISpacerX>`
  display: inline-block;
  width: ${({ x }) => x || '0'};
`;

const Spacer: React.FC<ISpacer> = props =>
  'x' in props ? <Span x={props.x} /> : <Div y={props.y} />;

export default Spacer;
