import { useCallback } from 'react';

import { saveAs } from 'file-saver';
import useImperativeRequest from 'hooks/useImperativeRequest';
import useToastMessages from 'hooks/useToastMessages';
import queryString from 'query-string';

const useExportShipmentLogs = () => {
  const [{ loading }, makeRequest] = useImperativeRequest('shipment');
  const { error: toastError } = useToastMessages();

  const getCSVData = useCallback(
    async (shipmentId: string) => {
      const query = queryString.stringify({
        format: 'csv',
        sortBy: 'createdAt',
        order: 'DESC',
        pageSize: 100,
        offset: 0,
      });
      const { data, error } = await makeRequest({
        path: `api/v2/shipments/${shipmentId}/log?${query}`,
      });
      if (!data || error) {
        toastError('messages:exportLogsError.message');

        return;
      }
      try {
        const csvData = new Blob([data], { type: 'text/csv;charset=utf-8;' });
        saveAs(csvData, `export_${shipmentId}.csv`);
      } catch (error) {
        toastError('messages:exportLogsError.message');

        return;
      }

      return;
    },
    [makeRequest, toastError]
  );

  return { loading, getCSVData };
};

export default useExportShipmentLogs;
