import * as Sentry from '@sentry/react';

export const SentryCaptureException = (error: any, appError: any) => {
  if (error) {
    Sentry.captureException(error);
  }
  if (appError) {
    Sentry.captureException(appError);
  }
};
