import styled from 'styled-components';
import {
  flexbox,
  FlexboxProps,
  layout,
  LayoutProps,
  space,
  SpaceProps,
} from 'styled-system';

const Col = styled.div<SpaceProps & LayoutProps & FlexboxProps>`
  display: flex;
  flex-direction: column;
  ${space}
  ${layout}
  ${flexbox}
`;

export default Col;
