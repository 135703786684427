import React from 'react';

import {
  Text,
  Box,
  ButtonV2 as Button,
  Stack,
  styled,
  ModalV2 as Modal,
} from '@livingpackets/design-system-react-next';
import livingPacketsLogo from 'assets/img/authentication/lp-logo.png';
import livingPacketsLogo2x from 'assets/img/authentication/lp-logo@2x.png';
import livingPacketsLogo3x from 'assets/img/authentication/lp-logo@3x.png';
import { useTranslation } from 'react-i18next';

const LivingPacketsLogo = styled('div', {
  shouldForwardProp: prop =>
    prop !== 'livingPacketsLogo' &&
    prop !== 'livingPacketsLogo2x' &&
    prop !== 'livingPacketsLogo3x',
})<{
  livingPacketsLogo: string;
  livingPacketsLogo2x: string;
  livingPacketsLogo3x: string;
}>`
  width: 40px;
  height: 40px;
  margin-left: auto;
  margin-right: auto;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url(${({ livingPacketsLogo }) => livingPacketsLogo});
  background-image: -webkit-image-set(
    url(${livingPacketsLogo2x}) 1x,
    url(${livingPacketsLogo3x}) 2x
  );
`;

const DeliveryKeyExpiredWarningModal = ({
  isVisible,
  onDismiss,
}: {
  isVisible: boolean;
  onDismiss: () => void;
}) => {
  const { t } = useTranslation('authentication');

  return (
    <Modal open={isVisible} setOpen={onDismiss}>
      <>
        <Stack
          direction="row"
          alignItems="flex-start"
          justifyContent="space-between"
          marginBottom=".625rem"
          marginTop=".3125rem"
          marginLeft="1.875rem"
        >
          <LivingPacketsLogo
            livingPacketsLogo={livingPacketsLogo}
            livingPacketsLogo2x={livingPacketsLogo2x}
            livingPacketsLogo3x={livingPacketsLogo3x}
            data-testid="lp-logo-delivery-key-expired-warning-modal"
          />
        </Stack>
        <Box display="flex" justifyContent="center">
          <Stack maxWidth="30rem" alignItems="center">
            <Text variant="titleXL" mb="1.875rem">
              {t('modal.displayDeliveryKeyExpiredWarning.title')}
            </Text>
            <Text variant="bodyTextL" sx={{ textAlign: 'center' }}>
              {t('modal.displayDeliveryKeyExpiredWarning.warning')}
            </Text>
          </Stack>
        </Box>
        <Box display="flex" justifyContent="flex-end" marginTop="35px">
          <Button
            onClick={() => {
              onDismiss();
            }}
            data-testid="delivery-key-warning-modal-quit-button"
          >
            {t('modal.displayDeliveryKeyExpiredWarning.button.dismiss')}
          </Button>
        </Box>
      </>
    </Modal>
  );
};

export default DeliveryKeyExpiredWarningModal;
