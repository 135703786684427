import React, { useCallback, useEffect, useState } from 'react';

import {
  Box,
  ModalV2 as Modal,
  ButtonV2 as Button,
  Stack,
  Text,
  ConnectionFailed,
  BoxInterrogation,
  SavIcon,
  ProblemCircleIcon,
} from '@livingpackets/design-system-react-next';
import usePublic from 'hooks/usePublic';
import { useShipmentAddByTrackingCode } from 'hooks/useShipmentAddByTrackingCode';
import useShipmentAnonymousDetail from 'hooks/useShipmentAnonymousDetail';
import { IShipmentAnonymousData } from 'models/shipment';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import useAppState, {
  anonymousTokenSelector,
  dispatch,
} from 'stores/appState/useAppState';

import BLEDelivery from '../components/BLEDelivery';
import BLEModal from '../components/BLEModal';
import { useBoxConnector } from '../hook/useBoxConnector';
import { useRedirectToRetrieveKeyPage } from '../hook/useRedirectToRetrieveKeyPage';
import { CommandControlStatus } from '../types/commandControlStatus';
import { lockStatus } from '../types/lockStatus';
import { getBleProductTypeKeysValue } from '../utils/bleProductType';
import { getKeyByValue } from '../utils/commandControlStatus';
import { getLockStatusKeyByValue } from '../utils/lockstatus';

export const anonymousShipmentDetail =
  '/shipments/:trackingCode/anonymous-detail/delivery';

const LONG_TIMEOUT = 20000;
let boxCommandTimeOutID: any;

const ERROR_TYPE_MODAL = {
  WARNING: 'warning',
  DANGER: 'danger',
};

export const Delivery = () => {
  const { t } = useTranslation('shipments');
  const { trackingCode } = useParams<{ trackingCode: string }>();

  const shipmentAddByTrackingCode = useShipmentAddByTrackingCode();
  const { getShipmentAnonymousDetail } = useShipmentAnonymousDetail();
  const { postSignup } = usePublic();
  const anonymousToken = useAppState(anonymousTokenSelector);
  const redirectToRetrieveKeyPage = useRedirectToRetrieveKeyPage();

  const navigate = useNavigate();

  const {
    scanForDevice,
    connectToDevice,
    getBoxInfo,
    getCommand,
    launchCommand,
    pacthContractState,
    disconnectDevice,
  } = useBoxConnector();
  const [shipmentData, setShipmentData] = useState<IShipmentAnonymousData>();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isConfirm, setIsConfirm] = useState<boolean>(false);
  const [isLostConnexion, setIsLostConnexion] = useState<boolean>(false);
  const [articleCode, setArticleCode] = useState<string>('');
  const [error, setError] = useState<any>({ type: '', message: '' });
  const [boxCommandControl, setBoxCommandControl] = useState<any>({});
  const [prevBoxCommandControl, setPrevBoxCommandControl] = useState<any>({});
  const [boxInfo, setBoxInfo] = useState<any>({});
  const [device, setDevice] = useState<any>({});
  const [maxRetry, setMaxRetry] = useState<number>(1);

  const [open, setOpen] = useState<boolean>(false);

  const handleClickScan = async () => {
    const device = await scanForDevice();

    const server = await connectToDevice(device);

    if (!device?.isCancel && !server) {
      setIsLostConnexion(true);
      setError({
        type:
          maxRetry >= 2 ? ERROR_TYPE_MODAL.DANGER : ERROR_TYPE_MODAL.WARNING,
        message: t('anonymous.error.lostConnection'),
      });
      setOpen(true);

      return;
    }

    if (shipmentData) {
      setDevice(device);
      setIsLoading(true);
      // read box info

      const info: any = await getBoxInfo(
        device,
        shipmentData?.shipment.shipment_id
      );

      if (info) {
        if (
          info.shipmentId !== shipmentData?.shipment.shipment_id ||
          info.boxId !== shipmentData.shipment.product.lp_ui
        ) {
          // Display modal Danger`
          setError({
            type: ERROR_TYPE_MODAL.DANGER,
            message: t('anonymous.error.badProduct'),
          });
          setOpen(true);

          return;
        }
        setBoxInfo(info);

        const { command } = await getCommand(info.boxId, info.shipmentId);

        if (command) {
          await launchCommand(device, command, setBoxCommandControl);
          boxCommandTimeOutID = setTimeout(() => {
            setError({
              type:
                maxRetry >= 2
                  ? ERROR_TYPE_MODAL.DANGER
                  : ERROR_TYPE_MODAL.WARNING,
              message: t('anonymous.error.badCommmand'),
            });
            setOpen(true);
          }, LONG_TIMEOUT);
        } else {
          setError({
            type:
              maxRetry >= 2
                ? ERROR_TYPE_MODAL.DANGER
                : ERROR_TYPE_MODAL.WARNING,
            message: t('anonymous.error.badCommmand'),
          });
          setOpen(true);
        }
      } else {
        setError({
          type: ERROR_TYPE_MODAL.DANGER,
          message: t('anonymous.error.productConnectionFailed'),
        });
        setOpen(true);
      }
    } else {
      await disconnectDevice(device);
      setIsLostConnexion(true);
      setError({
        type: ERROR_TYPE_MODAL.DANGER,
        message: t('anonymous.error.lostConnection'),
      });
      setOpen(true);
    }
  };

  const manageIconModal = useCallback(() => {
    switch (error.type) {
      case ERROR_TYPE_MODAL.WARNING:
        return <BoxInterrogation />;

      case ERROR_TYPE_MODAL.DANGER:
        if (isLostConnexion) {
          return <ConnectionFailed style={{ color: 'red' }} />;
        }
        if (maxRetry >= 2) return <SavIcon style={{ color: 'red' }} />;

        return <ProblemCircleIcon style={{ color: 'red' }} />;
    }
  }, [error.type, isLostConnexion, maxRetry]);

  useEffect(() => {
    const fetchAnonymSignup = async () => {
      const { success, tokenResponse } = await postSignup();

      if (success && tokenResponse) {
        dispatch({
          type: 'SET_ANONYMOUS_TOKEN',
          payload: tokenResponse.accessToken,
        });
      }
    };
    fetchAnonymSignup();
  }, [postSignup]);

  useEffect(() => {
    if (!shipmentData && anonymousToken && trackingCode) {
      const getAnonymousShipment = async () => {
        const { success: successShipment, shipment } =
          await shipmentAddByTrackingCode(trackingCode);

        if (!successShipment) {
          redirectToRetrieveKeyPage();
        } else {
          if (shipment?.id) {
            const { success: successShipmentData, shipmentData } =
              await getShipmentAnonymousDetail(shipment?.id);
            if (!successShipmentData) {
            } else {
              if (shipmentData) {
                setShipmentData(shipmentData);
                setArticleCode(shipmentData.shipment.product.article_code);
              }
            }
          }
        }
      };

      getAnonymousShipment();
    }
  }, [
    anonymousToken,
    getShipmentAnonymousDetail,
    shipmentAddByTrackingCode,
    shipmentData,
    trackingCode,
    redirectToRetrieveKeyPage,
  ]);

  useEffect(() => {
    const finishDelivery = async () => {
      // Test lock state if not a tablet holder
      const unlockStatus =
        boxInfo.productType !== getBleProductTypeKeysValue('20')
          ? boxCommandControl.boxLockState ===
            getLockStatusKeyByValue(lockStatus.UNLOCKED)
          : true;

      if (
        boxCommandControl.boxCommandStatus ===
          getKeyByValue(CommandControlStatus.CMD_LOCK_OK) &&
        unlockStatus
      ) {
        clearTimeout(boxCommandTimeOutID);
        const resp = await pacthContractState(
          boxInfo.shipmentId,
          boxInfo.boxId
        );

        if (resp) {
          setIsConfirm(true);
          await disconnectDevice(device);
        } else {
          setError({
            type:
              maxRetry >= 2
                ? ERROR_TYPE_MODAL.DANGER
                : ERROR_TYPE_MODAL.WARNING,
            message: t('anonymous.error.badCommmand'),
          });
          setOpen(true);
        }
      } else {
        setError({
          type:
            maxRetry >= 2 ? ERROR_TYPE_MODAL.DANGER : ERROR_TYPE_MODAL.WARNING,
          message: t('anonymous.error.badCommmand'),
        });
        setOpen(true);
      }
    };

    // Compare previous and current boxControlCommand
    if (
      boxCommandControl.boxLockState !== prevBoxCommandControl.boxLockState ||
      boxCommandControl.boxCommandStatus !==
        prevBoxCommandControl.boxCommandStatus
    ) {
      finishDelivery();
    }

    // Update data
    setPrevBoxCommandControl(boxCommandControl);
  }, [
    boxCommandControl,
    disconnectDevice,
    pacthContractState,
    prevBoxCommandControl,
    t,
    setIsConfirm,
    setError,
    setOpen,
    boxInfo,
    device,
    maxRetry,
  ]);

  return (
    <>
      {isLoading ? (
        <BLEDelivery isDelivered={isConfirm} articleCode={articleCode} />
      ) : (
        <BLEModal handleClickScan={handleClickScan} />
      )}
      <Modal open={open} setOpen={setOpen} sx={{ padding: '1rem' }}>
        <>
          <Box
            display="flex"
            flexDirection="column"
            gap=".5rem"
            alignItems="center"
          >
            {manageIconModal()}

            <Text variant="titleL">
              {error.type === ERROR_TYPE_MODAL.WARNING
                ? t('anonymous.error.titleWarning')
                : isLostConnexion
                ? t('anonymous.error.titleConnection')
                : t('anonymous.error.titleError')}
            </Text>
            <Text variant="bodyTextL">{error?.message}</Text>
          </Box>
          {error.type === ERROR_TYPE_MODAL.WARNING ? (
            <Stack
              display="flex"
              flexWrap="nowrap"
              flexDirection="row"
              justifyContent="space-around"
              paddingTop="2rem"
              gap="1rem"
            >
              <Button
                variant="tertiary"
                color="warning"
                sx={{ width: '50%' }}
                onClick={() => setOpen(false)}
              >
                {t('anonymous.mobile.cta.btnCancel')}
              </Button>
              <Button
                variant="primary"
                color="warning"
                sx={{ width: '50%' }}
                onClick={() => {
                  setMaxRetry(maxRetry + 1);
                  setOpen(false);
                  scanForDevice();
                }}
              >
                {t('anonymous.mobile.cta.tryAgain')}
              </Button>
            </Stack>
          ) : (
            <Stack paddingTop="2rem">
              <Button
                variant="primary"
                color="critical"
                onClick={async () => {
                  await disconnectDevice(device);
                  navigate(-1);
                }}
              >
                {t('anonymous.mobile.cta.ok')}
              </Button>
            </Stack>
          )}
        </>
      </Modal>
    </>
  );
};
