import { useQuery } from '@tanstack/react-query';
import useImperativeRequest from 'hooks/useImperativeRequest';
import { ExtractFnReturnType, QueryConfig } from 'lib/react-query';

import { User } from '../../types/user';

export const getMeQueryKey = ['user', 'me'];

export const getMe = async (makeRequest: any): Promise<User> => {
  const { data, error } = await makeRequest({
    path: `/me`,
  });

  if (error) {
    throw new Error(error);
  }

  return data;
};

type QueryFnType = typeof getMe;

type UseMeOptions = {
  config?: QueryConfig<QueryFnType>;
};

export const useMe = ({ config }: UseMeOptions = {}) => {
  const [, makeRequest] = useImperativeRequest('lpAccountV3');

  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: getMeQueryKey,
    queryFn: () => getMe(makeRequest),
  });
};
