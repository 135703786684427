import { useCallback } from 'react';

import { PaginationParams, Paginated } from '@livingpackets/shared-components';

import { useApiClient } from './useApiClient';
import { ShipmentDirection } from '../components/organisms/PartnerShipmentsTable';
import { IShipment, ShipmentContractState } from '../models/shipment';

export interface PaginatedResponse<Type> {
  items: Type[];
  count: number;
  total: number;
}

export interface GetUserShipmentsParams extends Partial<PaginationParams> {
  direction?: ShipmentDirection;
  contractState?: ShipmentContractState[];
  isArchived?: boolean;
  reference?: string;
  recipientLastName?: string;
  recipientEmail?: string;
  senderLastName?: string;
  senderEmail?: string;
  search?: string;
  offset?: number;
}

type GetUserShipments = (
  params: GetUserShipmentsParams
) => Promise<Paginated<IShipment>>;

export function useGetUserShipments(): GetUserShipments {
  const shipment = useApiClient('shipment');
  const getShipments = useCallback(
    ({
      page = 1,
      offset,
      pageSize = 100,
      orderColumn = 'receivedAt',
      orderDirection = 'DESC',
      direction,
      contractState,
      isArchived,
      reference,
      recipientEmail,
      recipientLastName,
      senderLastName,
      senderEmail,
      search,
    }: GetUserShipmentsParams) =>
      shipment
        .get<PaginatedResponse<IShipment>>('api/v2/me/products/shipments', {
          params: {
            offset: (page - 1) * pageSize,
            pageSize,
            sortBy: orderColumn,
            order: orderDirection,
            direction,
            contractState,
            isArchived,
            reference,
            recipientEmail,
            recipientLastName,
            senderEmail,
            senderLastName,
            search,
          },
        })
        .then(response => ({
          items: response.data.items,
          page,
          pageSize,
          orderColumn,
          orderDirection,
          totalPages: Math.ceil(response.data.total / pageSize),
        })),
    [shipment]
  );

  return getShipments;
}
