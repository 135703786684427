import React from 'react';

import {
  ButtonV2 as Button,
  Grid,
  Text,
} from '@livingpackets/design-system-react-next';
import { normalizeKey } from 'helpers/i18n';
import { useTranslation } from 'react-i18next';

export const ConfirmDefaultAddressModal = ({
  type,
  onCancel,
  onConfirm,
}: any) => {
  const { t } = useTranslation(['general', 'contactAddress']);

  const trads: any = t(
    normalizeKey(`contactAddress:defaultAddress.${type}.modal`),
    {
      returnObjects: true,
    }
  );

  return (
    <Grid container direction="column" gap="2rem">
      <Grid item mobile={12}>
        <Grid container direction="column" gap=".25rem">
          <Text variant="titleM">{trads.title}</Text>
        </Grid>
      </Grid>
      <Grid item mobile={12}>
        <Text variant="bodyTextM">{trads.description}</Text>
        <Text variant="bodyTextM">
          {t('contactAddress:defaultAddress.modalSubDescription')}
        </Text>
      </Grid>
      <Grid container justifyContent="space-between">
        <Button
          variant="secondary"
          onClick={onCancel}
          data-testid="default-address-cancel-button"
        >
          {t('general:Cancel')}
        </Button>
        <Button
          data-testid="default-address-confirm-button"
          variant="primary"
          onClick={onConfirm}
        >
          {t('general:confirm')}
        </Button>
      </Grid>
    </Grid>
  );
};
