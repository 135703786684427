import { useMutation } from '@tanstack/react-query';
import { AddressNotFoundGeocodingError } from 'core/errors/AddressNotFoundGeocodingError';
import useImperativeRequest from 'hooks/useImperativeRequest';
import { MutationConfig, queryClient } from 'lib/react-query';
import { useParams } from 'react-router-dom';
import {
  filterChangedValues,
  filterEditableValues,
} from 'utils/filterEditableValues';

import { Address, EditableAddressKeys } from '../../types/address';

/**
 * Based on the given updated values,
 * it filters the values that are editable and changed from the initial values.
 * + some specific logic for the address type MAIN
 */
const filterValues = ({
  initialValues,
  values,
}: {
  initialValues: Partial<Address>;
  values: Partial<Address>;
}): Partial<Address> => {
  let filterKeys = EditableAddressKeys;

  // If address of type MAIN, then remove some unnecessary fields
  if (values.addressTypes?.includes('MAIN')) {
    filterKeys = EditableAddressKeys.filter(key => !['company'].includes(key));
  }

  filterKeys = EditableAddressKeys.filter(
    key => !['addressTypes'].includes(key)
  );

  // Keep only allowed fields
  let filteredValues = filterEditableValues({
    values,
    fields: filterKeys,
  });

  // Keep only changed values
  filteredValues = filterChangedValues({
    initialValues,
    values: filteredValues,
  });

  return filteredValues;
};

export const updateAddress =
  ({
    initialValues,
    partnershipId,
    makeRequest,
  }: {
    initialValues: Partial<Address>;
    partnershipId?: string;
    makeRequest: any;
  }) =>
  async (values: Partial<Address>): Promise<Address> => {
    const filteredValues = filterValues({ initialValues, values });

    const { data, error } = await makeRequest({
      path: `api/v1/me/partners/${partnershipId}/addresses/${values.id}`,
      method: 'patch',
      body: filteredValues,
    });

    if (!data || error) {
      if (error.response?.data?.code === AddressNotFoundGeocodingError.code) {
        throw new AddressNotFoundGeocodingError(error.response.data.message);
      }

      throw error;
    }

    return data;
  };

type UseUpdateAddressOptions = {
  initialValues: Partial<Address>;
  partnershipId?: string;
  config?: MutationConfig<ReturnType<typeof updateAddress>>;
};

export const useUpdateAddress = ({
  initialValues,
  partnershipId,
  config,
}: UseUpdateAddressOptions) => {
  const { partnerId } = useParams();

  let id = partnershipId;
  if (!id) {
    id = partnerId;
  }

  const [, makeRequest] = useImperativeRequest('lpVillage');

  return useMutation({
    ...config,
    onSuccess: () => {
      queryClient.refetchQueries({
        queryKey: ['partnership', id, 'addresses'],
      });
    },
    mutationFn: updateAddress({
      initialValues,
      partnershipId: id,
      makeRequest,
    }),
  });
};
