import React from 'react';

import {
  ButtonV2,
  Grid,
  ModalV2,
  HexagoneExclamationMarkWarning,
  Text,
} from '@livingpackets/design-system-react-next';
import { useTranslation } from 'react-i18next';

type Props = {
  open: boolean;
  setOpen: (value: boolean) => void;
  refetch: () => void;
};

export const ErrorGetMeDeleteAccountAvailable = ({
  open,
  setOpen,
  refetch,
}: Props) => {
  const { t } = useTranslation('account');

  return (
    <ModalV2 open={open} setOpen={setOpen} hideCloseIcon>
      <Grid
        container
        direction="column"
        sx={{
          height: '100%',
          maxWidth: { mobile: '100%', tablet: '23.375rem' },
          justifyContent: 'space-around',
        }}
      >
        <Grid container direction="column" alignItems="center" gap="1rem">
          <HexagoneExclamationMarkWarning />
          <Text variant="titleL" align="center">
            {t('personal.deletion.getMeDeleteAccountAvailableError.title')}
          </Text>
          <Text
            variant="bodyTextM"
            color="custom.neutral.black.90"
            align="center"
          >
            {t('personal.deletion.getMeDeleteAccountAvailableError.message')}
          </Text>
          <Grid container justifyContent="space-between" mt="1rem">
            <ButtonV2
              variant="flat"
              color="warning"
              onClick={() => setOpen(false)}
            >
              {t('personal.deletion.cancelButton')}
            </ButtonV2>
            <ButtonV2 color="warning" onClick={refetch}>
              {t('personal.deletion.retryButton')}
            </ButtonV2>
          </Grid>
        </Grid>
      </Grid>
    </ModalV2>
  );
};
