import { useCallback } from 'react';

export enum AccountInformationTabEnum {
  account = 'account',
  financial = 'financial',
}

const accountInformationTabKey = 'accountInformationTab';
const accountInformationTabDefaultValue = AccountInformationTabEnum.account;

const useAccountInformationTab = () => {
  const getAccountInformationTabCurrent = useCallback(
    (): AccountInformationTabEnum =>
      sessionStorage.getItem(accountInformationTabKey)
        ? (sessionStorage.getItem(
            accountInformationTabKey
          ) as AccountInformationTabEnum)
        : accountInformationTabDefaultValue,
    []
  );

  const setShipmentNavTabCurrent = useCallback(
    (accountInformationTab: AccountInformationTabEnum) => {
      sessionStorage.setItem(accountInformationTabKey, accountInformationTab);
    },
    []
  );

  const resetShipmentNavTab = useCallback(() => {
    sessionStorage.setItem(
      accountInformationTabKey,
      accountInformationTabDefaultValue
    );
  }, []);

  return {
    getAccountInformationTabCurrent,
    setShipmentNavTabCurrent,
    resetShipmentNavTab,
  };
};

export default useAccountInformationTab;
