import { useCallback } from 'react';

import { ShipmentTimelineEventTypeEnum } from 'enums/ShipmentTimelineEnum';
import useImperativeRequest from 'hooks/useImperativeRequest';
import { isEmpty } from 'lodash/fp';
import {
  IShipmentTimelineEntry,
  IShipmentTimelineResponse,
  ITrackingHistoryResponse,
} from 'models/shipment';
import queryString from 'query-string';

const useGetShipmentTracking = (shipmentId: string) => {
  const [, makeTimelineRequest] = useImperativeRequest('shipment');
  const [, makeTrackingRequest] = useImperativeRequest('tracking');

  /**
   * Get All the shipment timeline events using recursive function
   */
  const getShipmentTimeline = useCallback(
    async (
      initialShipments: IShipmentTimelineEntry[] = [],
      pageSize: number = 50,
      offset: number = 0
    ): Promise<any> => {
      const queryParams = queryString.stringify({
        pageSize,
        offset: offset * pageSize,
        sortBy: 'createdAt',
        order: 'DESC',
      });

      const shipments = await makeTimelineRequest({
        path: `api/v2/shipments/${shipmentId}/timeline?${queryParams}`,
      });

      const {
        data: { total, items },
        error,
      } = shipments;

      if (error) {
        return { data: null, error: true };
      }

      const allShipments = [
        ...initialShipments,
        ...(isEmpty(items) ? [] : items),
      ];

      // If we have all the shipments or the total is less than the page size
      if (allShipments.length === total || total <= pageSize) {
        return {
          data: {
            items: allShipments,
            total: allShipments.length,
            count: allShipments.length,
          },
          error: false,
        };
      }

      return getShipmentTimeline(allShipments, pageSize, offset + 1);
    },
    [makeTimelineRequest, shipmentId]
  );

  const getShipmentTrackingHistory = useCallback(
    async () =>
      await makeTrackingRequest({
        path: `api/v2/shipments/${shipmentId}/tracking-history`,
      }),
    [makeTrackingRequest, shipmentId]
  );

  const loadData = useCallback(async (): Promise<{
    success: boolean;
    trackingHistory?: ITrackingHistoryResponse;
    timelineEvents?: IShipmentTimelineResponse;
  }> => {
    const [trackingHistory, timelineEvents] = await Promise.all([
      getShipmentTrackingHistory(),
      getShipmentTimeline(),
    ]);

    if (
      trackingHistory.error ||
      timelineEvents.error ||
      !trackingHistory.data ||
      !timelineEvents.data
    ) {
      return { success: false };
    }

    timelineEvents.data.items = timelineEvents.data.items.map(
      (event: IShipmentTimelineEntry) => {
        // Force remove close information for non stop alert
        if (
          event.eventType ===
            ShipmentTimelineEventTypeEnum.ALERT_BATTERY_LEVEL ||
          event.eventType ===
            ShipmentTimelineEventTypeEnum.ALERT_BOX_VIOLATION ||
          event.eventType === ShipmentTimelineEventTypeEnum.ALERT_SHOCK
        ) {
          event.closedAt = undefined;
        } else if (event.closedAt === 0) {
          event.closedAt = undefined;
        }

        // For the moment, the shock alert is in fact a fall alert
        if (event.eventType === ShipmentTimelineEventTypeEnum.ALERT_SHOCK) {
          event.eventType = ShipmentTimelineEventTypeEnum.ALERT_FALL;
        }

        return event;
      }
    );

    return {
      success: true,
      trackingHistory: trackingHistory.data,
      timelineEvents: timelineEvents.data,
    };
  }, [getShipmentTimeline, getShipmentTrackingHistory]);

  return {
    loadData,
  };
};

export default useGetShipmentTracking;
