import React from 'react';

import { Text } from '@livingpackets/design-system-react-next';
import Row from 'components/atoms/Row';
import { useTranslation } from 'react-i18next';

import NewsletterCard from './NewsletterCard';
import useNewsletters from '../../../hooks/useNewsletters';
import useNewsletterStore, {
  newsletterSelector,
} from '../../../stores/useNewslettersStore';

const NewsletterCardList = () => {
  const { t } = useTranslation('dashboard');

  const { loading } = useNewsletters();
  const newsletters = useNewsletterStore(newsletterSelector);

  return (
    <Row marginTop="3rem" marginLeft="2rem" flexDirection="column" width="45%">
      <Row
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '95%',
        }}
      >
        <Text variant="titleM" mb="1rem">
          {t('newsletterList.title')}
        </Text>
        <Text color="custom.neutral.black.50" mb="1rem">
          {t('newsletterList.newsletterNumbers')}
        </Text>
      </Row>
      {!loading &&
        newsletters.map((newsletter, key) => (
          <NewsletterCard key={key} newsletter={newsletter} />
        ))}
    </Row>
  );
};
export default NewsletterCardList;
