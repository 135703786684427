import debounce from 'lodash/debounce';
import { create } from 'zustand';

interface ILayoutStore {
  openNav: boolean;
  setOpenNav: (openNav: boolean) => void;
  openAccountSelection: boolean;
  setOpenAccountSelection: (openAccountSelection: boolean) => void;
  openPanel: boolean;
  setOpenPanel: (openPanel: boolean, panelWidth?: number) => void;
  persistPanel: boolean;
  setPersistPanel: (persistPanel: boolean) => void;
}

const useLayoutStore = create<ILayoutStore>(set => ({
  openNav: false,
  setOpenNav: (openNav: boolean) => set(state => ({ openNav })),
  openAccountSelection: false,
  setOpenAccountSelection: (openAccountSelection: boolean) =>
    set(state => ({ openAccountSelection })),
  openPanel: false,
  setOpenPanel: debounce(
    (openPanel: boolean, panelWidth?: number) =>
      set(state => ({ openPanel, panelWidth: panelWidth || 392 })),
    10
  ),
  persistPanel: false,
  setPersistPanel: (persistPanel: boolean) => set(state => ({ persistPanel })),
}));

export const openNavSelector = (state: ILayoutStore) => state.openNav;
export const setOpenNavSelector = (state: ILayoutStore) => state.setOpenNav;
export const openAccountSelectionSelector = (state: ILayoutStore) =>
  state.openAccountSelection;
export const setOpenAccountSelectionSelector = (state: ILayoutStore) =>
  state.setOpenAccountSelection;

// ça déplace le contenu principal...
// TODO: plus utile, à supprimer ?????
export const openPanelSelector = (state: ILayoutStore) => state.openPanel;
export const setOpenPanelSelector = (state: ILayoutStore) => state.setOpenPanel;
export const persistPanelSelector = (state: ILayoutStore) => state.persistPanel;
export const setPersistPanelSelector = (state: ILayoutStore) =>
  state.setPersistPanel;

export default useLayoutStore;
