import { useCallback } from 'react';

import { PaginationParams } from '@livingpackets/shared-components';
import useGetPartnerRoles from 'hooks/useGetPartnerRoles';
import { parsePartners } from 'hooks/useMyPartnerships';
import { IPartnerEntry } from 'stores/useMyPartnersStore';

import { useApiClient } from './useApiClient';

export interface PaginatedResponse<Type> {
  items: Type[];
  total: number;
}

export interface PaginatedResponsePartner<Type> {
  items: Type[];
  count: number;
  total: number;
}

export interface GetPartnerPartnersParams extends Partial<PaginationParams> {
  search?: string;
}

type GetPartnerPartners = (
  params: GetPartnerPartnersParams
) => Promise<PaginatedResponsePartner<any>>;

export function useGetPartnerList(): GetPartnerPartners {
  const lpVillage = useApiClient('lpVillage');
  const getPartnerRoles = useGetPartnerRoles();

  return useCallback(
    ({
      page = 1,
      pageSize = 100,
      orderColumn = 'createdAt',
      orderDirection = 'DESC',
    }: GetPartnerPartnersParams) =>
      lpVillage
        .get<PaginatedResponse<any>>('/api/v1/me/partners', {
          params: {
            offset: (page - 1) * pageSize,
            pageSize,
            sortBy: orderColumn,
            order: orderDirection,
          },
        })
        .then(async response => {
          if (!('total' in response.data)) {
            (response.data as any).total = 0;
          }

          if (response.data.total !== 0) {
            response.data.items = parsePartners(response.data.items);

            response.data.items = await Promise.all(
              response.data.items.map(async (partner: IPartnerEntry) => {
                const response = await getPartnerRoles({
                  partnerId: partner.id,
                });

                partner.role = response.items[0];

                return partner;
              })
            );
          }

          return {
            items: response.data.items,
            count: response.data.items.length,
            total: response.data.total,
          };
        }),
    [getPartnerRoles, lpVillage]
  );
}
