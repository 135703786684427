import React, { useState } from 'react';

import {
  Box,
  ButtonV2 as Button,
  Collapse,
  Grid,
  Text,
  styled,
  CalendarIcon,
} from '@livingpackets/design-system-react-next';
import ViewKoalendarContactRequestModal from 'components/molecules/modals/ViewKoalendarContactRequestModal';
import useIsMobile from 'hooks/useIsMobile';
import { useTranslation } from 'react-i18next';
import useLayoutStore, { openNavSelector } from 'stores/useLayoutStore';

const Container = styled('div', {
  shouldForwardProp: prop => prop !== 'openNav',
})<{ openNav: boolean }>`
  display: flex;
  align-items: center;
  overflow: hidden;
  gap: 0.5rem;
  box-shadow: ${({ openNav }) =>
    openNav ? '0 .25rem 1rem rgb(0 0 0 / 8%)' : 'initial'};
  padding: 0.875rem 0.5625rem;
  margin-left: ${({ openNav }) => (openNav ? '0' : '-0.5625rem')};
  border-radius: 10px;
  transition: all 100ms ease;
  cursor: pointer;
`;

const KoalendarContactRequestBlock = () => {
  const { t } = useTranslation('navigation');
  const { isMobile } = useIsMobile();

  const openNav = useLayoutStore(openNavSelector);

  const [showModal, setShowModal] = useState<boolean>(false);

  return (
    <Collapse
      in={isMobile || openNav}
      orientation="horizontal"
      collapsedSize={40}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          marginBottom: '1.625rem',
        }}
      >
        <Container
          openNav={isMobile || openNav}
          onClick={() => setShowModal(true)}
        >
          <Button icon={CalendarIcon} size="small" />
          <Grid container direction="column">
            <Grid item>
              <Text variant="titleS" sx={{ whiteSpace: 'nowrap' }}>
                {t('koalendarContextRequest.title')}
              </Text>
            </Grid>
            <Grid item>
              <Text variant="bodyTextS" sx={{ whiteSpace: 'nowrap' }}>
                {t('koalendarContextRequest.subTitle')}
              </Text>
            </Grid>
          </Grid>
        </Container>
        <ViewKoalendarContactRequestModal
          visible={showModal}
          onDismiss={() => setShowModal(false)}
        />
      </Box>
    </Collapse>
  );
};

export default KoalendarContactRequestBlock;
