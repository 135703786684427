import { de, enGB, fr } from 'date-fns/locale';

export const getLocale = (language: string): Locale => {
  switch (language) {
    case 'fr':
      return fr;
    case 'de':
      return de;
    default:
      return enGB;
  }
};
