import React from 'react';

import { Box, Text } from '@livingpackets/design-system-react-next';

interface Props {
  children?: React.ReactNode | string;
  src?: string;
}

/**
 * Basic component (will be improved later) to display an avatar
 * Move in design system when ready ?
 */
export const Avatar = ({ children, src }: Props) => {
  let content = (
    <Text variant="titleM" color="custom.neutral.white.pure">
      {children}
    </Text>
  );

  if (src) {
    content = (
      <img style={{ borderRadius: '.5rem' }} src={src} alt="Partner avatar" />
    );
  }

  return (
    <Box
      sx={{
        width: '3.5rem',
        height: '3.5rem',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        bgcolor: src ? 'primary.main' : 'custom.neutral.black.90',
        borderRadius: '.5rem',
      }}
    >
      {content}
    </Box>
  );
};
