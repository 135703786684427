import React from 'react';

import { BillingAddressIcon } from '@livingpackets/design-system-react-next';

import { AddressTypes } from '../../../types/address';
import AddressManagement from '../../commons/addresses';

export const Finance = () => (
  <AddressManagement
    addressType={AddressTypes.BILLING}
    headerIcon={BillingAddressIcon}
  />
);
