import React, { Suspense } from 'react';

import ScaleOut from 'components/atoms/ScaleOut';
import FixedLoader from 'components/molecules/FixedLoader';

import DashboardV2App from './DashboardV2App';
import useTracking from './hooks/useTracking';

if (!process.env.REACT_APP_INSTANA_KEY) {
  // Don't send anything
  ineum('ignoreUrls', [/.*/i]);
} else {
  ineum('key', process.env.REACT_APP_INSTANA_KEY);
}

const App = () => {
  useTracking();

  return (
    <Suspense fallback={<FixedLoader ovBg="#fff" loader={<ScaleOut />} />}>
      <DashboardV2App />
    </Suspense>
  );
};

export default App;
