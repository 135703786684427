import React from 'react';

import {
  PartnerBusinessStatusEnum,
  PartnerBusinessTypeEnum,
} from 'enums/accountTypeEnum';
import { IPartner } from 'models/partner';

const useNewShipmentDisabled = (partner: IPartner) => {
  const disabled = React.useMemo(() => {
    // Allow PRO & VALIDATED account to create new shipments
    if (
      partner.businessInformation.type === PartnerBusinessTypeEnum.PRO &&
      partner.businessInformation.status === PartnerBusinessStatusEnum.VALIDATED
    )
      return false;

    // Disable BLOCKED account
    if (
      partner.businessInformation.status === PartnerBusinessStatusEnum.BLOCKED
    )
      return true;

    // Disable when limit reached
    if (partner.nbOfShipments >= (partner.maxNbOfShipments || 0)) {
      return true;
    }
  }, [partner]);

  return disabled;
};

export default useNewShipmentDisabled;
