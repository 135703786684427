import React from 'react';

import { ShipmentCompletedIcon } from '@livingpackets/design-system-react-next';
import styled from 'styled-components';

const Badge = styled.div`
  height: 2rem;
  width: 2rem;
  color: ${({ theme }) => theme.colors.white};
  background: ${({ theme }) => theme.colors.primary[100]};
  border-radius: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const DeliveredBadge = () => (
  <Badge>
    <ShipmentCompletedIcon />
  </Badge>
);

export default DeliveredBadge;
