import React from 'react';

import {
  AddIcon,
  Text,
  ButtonV2 as Button,
  Stack,
} from '@livingpackets/design-system-react-next';
import { useTranslation } from 'react-i18next';

import Modal from '../../atoms/Modal';

const AddReturnLabelChoiceModal = ({
  isVisible,
  onSelectAddReturn,
  onSelectContinueWithoutReturn,
}: {
  isVisible: boolean;
  onSelectAddReturn: () => void;
  onSelectContinueWithoutReturn: () => void;
}) => {
  const { t } = useTranslation('shipments');

  return (
    <Modal open={isVisible} handleClose={() => {}}>
      <>
        <Text variant="titleM">{t('modal.addReturnLabelChoice.title')}</Text>
        <Text variant="bodyTextS" mt="25px">
          {t('modal.addReturnLabelChoice.warning')}
        </Text>
        <Stack direction="row" justifyContent="space-between" mt="2rem">
          <Button
            variant="secondary"
            onClick={onSelectContinueWithoutReturn}
            data-testid="continue-without-return-button"
          >
            {t('modal.addReturnLabelChoice.button.continue-without-return')}
          </Button>
          <Button
            icon={AddIcon}
            onClick={onSelectAddReturn}
            data-testid="add-return-button"
          >
            {t('modal.addReturnLabelChoice.button.add-return')}
          </Button>
        </Stack>
      </>
    </Modal>
  );
};

export default AddReturnLabelChoiceModal;
