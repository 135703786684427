import React from 'react';

import { RemoteControl } from './RemoteControl';

export const shipmentRemoteControlRoutes = [
  {
    path: 'remote',
    element: <RemoteControl />,
  },
];
