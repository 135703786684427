import React, { useState } from 'react';

import { Row } from 'react-table';
import styled from 'styled-components';

export enum TableDesignTypeEnum {
  default = 'default',
  shipmentListTable = 'shipmentListTable',
  productListTable = 'productListTable',
  contributionListTable = 'contributionListTable',
}

export const Wrapper = styled.div<{
  tableDesignType?: TableDesignTypeEnum;
}>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  padding: 1.5rem;
  background: ${({ theme }) => theme.colors.white};
  width: 100%;
  height: 100%;
  ${props =>
    props.tableDesignType === TableDesignTypeEnum.shipmentListTable &&
    `
    background-color: transparent;
    padding: 0;
    table {
      thead {
        tr {
          th {
            background-color: ${props.theme.colors.black[3]};
            border: 0;
            
          }
        }
      }
      tbody {
        tr {
              background-color: ${props.theme.colors.white};
              border-radius: 14px;
        }
      }
    }
    }
    `};
  ${props =>
    props.tableDesignType === TableDesignTypeEnum.productListTable &&
    `
    background-color: transparent;
    padding: 0;
    table {
      thead {
        tr {
          th {
            background-color:  ${props.theme.colors.black[3]} ;
            border: 0;
          }
        }
      }
      tbody {
        tr {
              background-color: ${props.theme.colors.white};
              height: 80px;
        }
      }
    }
    }
    `};
  ${props =>
    props.tableDesignType === TableDesignTypeEnum.contributionListTable &&
    `
    background-color: transparent;
    padding: 0;
    table {
      thead {
        tr {
          th {
            background-color: ${props.theme.colors.black[3]};
            border: 0;
          }
        }
      }
      tbody 
        tr {
              background-color: ${props.theme.colors.white};
              height: 80px;
        }
      }
    }
    }
    `};
`;

export const TableWrapper = styled.div<{ maxHeight: string }>`
  overflow: hidden;
  overflow-y: scroll;
  max-height: ${({ maxHeight }) => maxHeight};
`;

export const StyledTable = styled.table`
  width: 100%;
  table-layout: fixed;
  border: none;
  border-spacing: 0 8px;
  border-collapse: separate;

  tr {
    :last-child {
      td {
        border-bottom: 0;
      }
    }
  }
`;

export const Header = styled.th<{ headerEmphasis?: boolean; canSort: boolean }>`
  text-align: left;
  padding: 0 1rem 1rem 1rem;
  border-bottom: 2px solid ${({ theme }) => theme.colors.black[2]};
  color: ${({ theme, headerEmphasis }) =>
    !!headerEmphasis ? theme.colors.black[100] : theme.colors.black[50]};
  font-size: 12px;
  font-weight: ${({ headerEmphasis }) => (headerEmphasis ? 500 : 450)};
  cursor: ${({ canSort }) => (canSort ? `pointer` : `auto`)};
  background: ${({ theme }) => theme.colors.white};
  position: sticky;
  top: 0;
  z-index: 2;
`;

export interface IStyledTd {
  cellEmphasis?: 'high' | 'low';
  align: 'left' | 'right' | 'center';
  hasClickable: boolean;
  tableDesignType?: TableDesignTypeEnum;
  isExpanded: boolean;
}

const StyledTd = styled.td<IStyledTd>`
  ${({ theme }) => theme.fontStyles.bodyTextS}
  height: 100%;
  cursor: ${({ hasClickable }) => (hasClickable ? 'auto' : 'inherit')};
  text-align: ${({ align }) => align};

  ${props =>
    props.tableDesignType === TableDesignTypeEnum.default &&
    `
    padding: 1rem;
    `};

  ${props =>
    props.tableDesignType === TableDesignTypeEnum.shipmentListTable &&
    `
    padding: 8px;
    `};

  ${props =>
    props.tableDesignType === TableDesignTypeEnum.productListTable &&
    `
    padding: 8px;
    `};

  ${props =>
    props.tableDesignType === TableDesignTypeEnum.contributionListTable &&
    `
    padding: 8px;
    `};

  vertical-align: middle;
  font-weight: ${({ cellEmphasis }) => (cellEmphasis === 'high' ? 500 : 400)};
  color: ${({ theme, cellEmphasis }) =>
    cellEmphasis === 'low' ? theme.colors.black[50] : theme.colors.black[100]};
`;

const StyledRow = styled.tr<{
  emphasized: boolean;
  clickable: boolean;
  height?: string;
  tableDesignType?: TableDesignTypeEnum;
  isExpanded: boolean;
}>`
  height: ${({ height }) => (height ? `height` : `4rem`)};
  cursor: ${({ clickable }) => (clickable ? `pointer` : `auto`)};
  background: ${({ emphasized, theme }) =>
    emphasized ? theme.colors.primary[5] : `none`};

  ${props =>
    props.isExpanded &&
    `
      td:first-child {
        border-bottom-left-radius: 0 !important;
      }
      td:last-child {
        border-bottom-right-radius: 0 !important;
      }
    `};

  ${props =>
    props.tableDesignType === TableDesignTypeEnum.default &&
    `
      td:first-child {
        border-top-left-radius: 0.75rem;
        border-bottom-left-radius: 0.75rem;
      }
      td:last-child {
        border-bottom-right-radius: 0.75rem;
        border-top-right-radius: 0.75rem;
      }
    `};

  ${props =>
    props.tableDesignType === TableDesignTypeEnum.shipmentListTable &&
    `
      td:first-child {
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
      }
      td:last-child {
        border-bottom-right-radius: 10px;
        border-top-right-radius: 10px;
      }
    `};

  ${props =>
    props.tableDesignType === TableDesignTypeEnum.productListTable &&
    `
      td:first-child {
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
      }
      td:last-child {
        border-bottom-right-radius: 10px;
        border-top-right-radius: 10px;
      }
    `};

  ${props =>
    props.tableDesignType === TableDesignTypeEnum.contributionListTable &&
    `
      td:first-child {
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
      }
      td:last-child {
        border-bottom-right-radius: 10px;
        border-top-right-radius: 10px;
      }
    `};

  transition: all 500ms ease-out;

  &:hover {
    background-color: ${({ theme }) => theme.colors.primary[5]};
  }
`;

export const TableRow = ({
  row,
  activeRowId,
  onRowClick,
  tableDesignType = TableDesignTypeEnum.default,
  rowHeight,
  dataTestId,
  isExpanded,
}: {
  row: Row<any>;
  activeRowId?: string;
  onRowClick?: (row: any) => any;
  tableDesignType?: TableDesignTypeEnum;
  rowHeight?: string;
  dataTestId?: string;
  isExpanded: boolean;
}) => {
  const [hover, setHover] = useState(false);

  return (
    <StyledRow
      {...row.getRowProps()}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      emphasized={hover || (!!activeRowId && activeRowId === row.original.id)}
      clickable={!!onRowClick}
      onClick={onRowClick ? () => onRowClick(row.original) : () => {}}
      height={rowHeight}
      tableDesignType={tableDesignType}
      data-testid={dataTestId}
      isExpanded={isExpanded}
    >
      {row.cells.map((cell: any, index: number) => (
        <StyledTd
          key={index}
          {...cell.getCellProps()}
          cellEmphasis={cell.column.cellEmphasis}
          align={cell.column.align || 'left'}
          onClick={event => cell.column.hasClickable && event.stopPropagation()}
          hasClickable={!!cell.column.hasClickable}
          data-testid={cell.column.dataTestId}
          tableDesignType={tableDesignType}
          isExpanded={isExpanded}
        >
          {cell.render('Cell', { isHovered: hover })}
        </StyledTd>
      ))}
    </StyledRow>
  );
};
