import React from 'react';

import { useSearchParams, useParams, Navigate } from 'react-router-dom';

const DeepLinkShipmentDetailRoute = (props: any) => {
  const [searchParams] = useSearchParams();

  const { shipmentId } = useParams();
  const partnerId = new URLSearchParams(searchParams).get('partner_id');
  const deliveryKey = new URLSearchParams(searchParams).get('delivery_key');
  const alertId = new URLSearchParams(searchParams).get('alert_id');

  if (deliveryKey) {
    return (
      <Navigate to={`/shipments/${deliveryKey}/anonymous-detail`} replace />
    );
  }

  if (alertId) {
    return (
      <Navigate
        to={`/partner/${partnerId}/shipments/${shipmentId}/alert/${alertId}`}
        replace
      />
    );
  }

  return (
    <Navigate
      to={`/partner/${partnerId}/shipments/${shipmentId}/detail`}
      replace
    />
  );
};
export default DeepLinkShipmentDetailRoute;
