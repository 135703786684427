import { useCallback, useEffect, useState } from 'react';

import useImperativeRequest from 'hooks/useImperativeRequest';
import { dispatch } from 'stores/appState/useAppState';
import useMyAddressesStore, {
  setPrimaryAddressSelector,
} from 'stores/useMyAddressesStore';

const useGetPrimaryAddress = () => {
  const [, makeRequest] = useImperativeRequest('lpAccount');
  const setPrimaryAddress = useMyAddressesStore(setPrimaryAddressSelector);

  const [loading, setLoading] = useState(true);

  const getPrimaryAddress = useCallback(async () => {
    setLoading(true);
    const { data, error } = await makeRequest({
      path: 'api/v1/me/addresses?isPrimary=true',
    });
    if (error) {
      dispatch({ type: 'SET_ERROR', payload: error });
      setLoading(false);

      return;
    }
    if (data.items && data.items.length > 0) {
      setPrimaryAddress(data.items[0]);
    }
    setLoading(false);
  }, [makeRequest, setPrimaryAddress]);

  useEffect(() => {
    getPrimaryAddress();
  }, [getPrimaryAddress]);

  return { loading };
};

export default useGetPrimaryAddress;
