import React from 'react';

import useGetPrimaryAddress from 'hooks/useGetPrimaryAddress';

import useBoxContract from '../../../hooks/useBoxContract';
import ProfileDashboard from '../../organisms/ProfileDashboard';

const LPAccountHome = () => {
  useGetPrimaryAddress();
  useBoxContract();

  return <ProfileDashboard />;
};

export default LPAccountHome;
